import {
	Component,
	HostBinding,
	HostListener,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../notification/notification.service';
import { TawkService } from '../services/tawk.service';
import { UserService } from '../services/user.service';
import { NavStateService } from './nav-state.service';

@Component({
	selector: 'app-shell',
	templateUrl: './app-shell.component.html',

	providers: [NavStateService, TawkService],
})
export class AppShellComponent implements OnInit, OnDestroy {
	navOpen = false;
	onDestroy$ = new Subject<void>();
	acceptSnack: any;

	@HostBinding('class.nav-toggled') navClass = false;

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.navState.value) return;
		if (event.target.innerWidth < 770) {
			this.navState.setHideState(true);
		}
	}

	constructor(
		private navState: NavStateService,
		private tawk: TawkService,
		private notification: NotificationService,
		private userService: UserService
	) {}

	ngOnInit(): void {
		this.tawk.SetChatVisibility(true);
		this.navState
			.pipe(takeUntil(this.onDestroy$))
			.subscribe({ next: (state) => (this.navClass = state) });

		this.acceptTerms();
	}

	acceptTerms() {
		this.userService.getCurrentUser().then((user) => {
			if (!user.acceptCookies) {
				this.acceptSnack = this.notification.acceptTerms();

				this.acceptSnack.afterDismissed().subscribe((res) => {
					if (res.dismissedByAction) {
						this.userService.acceptTerms().subscribe();
					}
				});
			}
		});
	}

	toogle() {
		this.navOpen = !this.navOpen;
	}

	ngOnDestroy() {
		if (this.acceptSnack) {
			this.acceptSnack.dismiss();
		}
		this.onDestroy$.next();
	}
}

export const ng2AppShell = {
	name: 'appShell',
	def: downgradeComponent({ component: AppShellComponent }),
};
