<div class="content-wrapper view">
	<div class="container-fluid">
		<!-- ------------------------- Navbar ------------------------- -->
		<nav class="ga-navbar">
			<div class="navbar-content">
				<h3>
					<a (click)="backTo()"> {{ "kpi.analysis" | transloco }} </a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<a (click)="backTo()"> {{ "kpi.title" | transloco }} </a>

					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<span *ngIf="!isEdit" class="navbar-content__breadcrumb-page">
						{{ "kpi.new" | transloco }}
					</span>
					<span *ngIf="isEdit" class="navbar-content__breadcrumb-page">
						{{ "kpi.edit" | transloco }}
					</span>
				</h3>
			</div>
		</nav>

		<!-- ------------------------- Título ------------------------- -->
		<div class="description">
			<div class="description__texts">
				<span class="description__texts__title">{{
					!isEdit ? "Novo indicador" : "Editar indicador"
				}}</span>
				<span class="description__texts__subtitle">{{
					"kpi.desc" | transloco
				}}</span>
			</div>
		</div>
		<form [formGroup]="form" focusInvalidInput (ngSubmit)="onSubmit()">
			<div class="row col-lg-12 module-group">
				<h3 class="basic-data__title col-lg-12">
					{{ "kpi.indicator" | transloco }}
				</h3>
				<div class="row">
					<!-- Organização -->
					<div class="form-group col-lg-4">
						<autocomplete-orgs
							[idSelected]="organization"
							[isEnabled]="isOrgEnable"
							(orgEmitter)="orgSelected($event)"
							[isRequired]="true"
						></autocomplete-orgs>
					</div>
					<div class="form-group col-lg-4">
						<ga-selector
							[title]="'alerts.label.sources' | transloco"
							[placeholder]="'meter-page.placeholder.select' | transloco"
							[groups]="groupDataSources"
							[groupSelect]="true"
							[isMultiple]="true"
							[isSearch]="true"
							[disabled]="form.controls['dataSources'].disabled"
							[required]="true"
							[showError]="
								!form.controls['dataSources'].valid &&
								form.controls['dataSources'].touched &&
								form.controls['dataSources'].enabled
							"
							formControlName="dataSources"
							name="dataSources"
						>
						</ga-selector>
					</div>
					<div class="row col-lg-12">
						<ng-container formArrayName="indicators">
							<div
								class="row"
								*ngFor="
									let indicator of indicatorsFormArray.controls;
									let i = index
								"
								[formGroupName]="i"
							>
								<div class="form-group col-lg-4">
									<ga-selector
										[title]="'kpi.table.metric' | transloco"
										[options]="optMetrics"
										[placeholder]="'global.select' | transloco"
										[disabled]="
											indicatorsFormArray.controls[i].get('metric')?.disabled ??
											true
										"
										formControlName="metric"
										name="metric"
										[required]="true"
										[showError]="
											indicatorsFormArray.controls[i].get('metric')?.invalid &&
											indicatorsFormArray.controls[i].get('metric')?.touched
										"
									>
									</ga-selector>
								</div>
								<div class="form-group col-lg-3">
									<ga-input
										[title]="'kpi.table.goal' | transloco"
										[placeholder]="'0'"
										[type]="'number'"
										[rAddon]="
											getMetricAddon(
												indicatorsFormArray.controls[i].get('metric')?.value
											)
										"
										formControlName="goal"
										name="goal"
									></ga-input>
								</div>
								<div class="form-group col-sm-2 flex-down">
									<button
										type="button"
										(click)="removeContact(i)"
										class="btn trash"
									>
										<i class="fa-regular fa-trash"></i>
									</button>
								</div>
							</div>
							<div class="addition-btn-div" *ngIf="!isEdit">
								<button
									class="add-contact"
									type="button"
									(click)="addIndicator()"
								>
									{{ "kpi.add" | transloco }}
								</button>
							</div>
						</ng-container>
					</div>
					<div class="row col-lg-12 info">
						<p *ngIf="!isEdit">
							{{ "kpi.info1" | transloco }}
							<span class="marked">{{ "kpi.info2" | transloco }}</span
							>.
						</p>
						<p>
							<span class="bold">{{ "kpi.important" | transloco }}</span>
							{{ "kpi.important-info" | transloco }}
						</p>
					</div>
					<div class="row col-lg-12">
						<!----------- Botões --------------->
						<hr class="separator" />
						<div class="button-group flex-row">
							<div
								class="btn-toolbar"
								role="toolbar"
								style="text-align: center"
							>
								<div class="btn-group" role="group">
									<button
										class="btn btn-default"
										type="button"
										(click)="backTo()"
									>
										{{ "appliance.btn.cancel" | transloco }}
									</button>
								</div>
								<div class="btn-group" role="group">
									<button
										class="btn btn-primary btn-color-ga"
										type="submit"
										[disabled]="!form.valid"
									>
										{{ "appliance.btn.save" | transloco }}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
