import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { map } from 'rxjs/operators';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'root' })
export class RoleService {
	constructor(private http: HttpClient) {}

	/* deprecated */
	getRoles(params) {
		return this.http
			.get(`${environment.backendUrl}/api/roles`, { params: params })
			.toPromise()
			.then((res) => res || [])
			.catch((error) => {
				return new Error(error);
			});
	}

	$getRoles(params) {
		return this.http
			.get(`${environment.backendUrl}/api/roles`, {
				params: params,
			})
			.pipe(map((roles) => roles || []));
	}

	getOrganizationRoles(organizationId) {
		return this.http
			.get(`${environment.backendUrl}/api/roleOrganizations`, {
				params: { organizationId: organizationId },
			})
			.toPromise()
			.then((res) => res || [])
			.catch((error: any) => {
				return new Error(error);
			});
	}

	createRoleOrganization(body) {
		return this.http
			.post(`${environment.backendUrl}/api/roleOrganizations`, body)
			.toPromise()
			.then((res) => res)
			.catch((error: any) => {
				return new Error(error);
			});
	}

	deleteRoleOrganization(roleOrgId) {
		return this.http
			.delete(`${environment.backendUrl}/api/roleOrganizations/${roleOrgId}`)
			.toPromise()
			.then((res) => res)
			.catch((error: any) => {
				return new Error(error);
			});
	}
}

export const ng2RoleService = {
	name: RoleService.name,
	def: downgradeInjectable(RoleService),
};
