// my-loader.component.ts
import { Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { LoaderService } from './loader.service';

@Component({
	selector: 'app-my-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
})
export class GlobalLoadingComponent {
	@Input() lightMode = false;
	@Input() loading = false;
	@Input() relative = false;

	constructor(private loaderService: LoaderService) {
		this.loaderService.isLoading.subscribe((v) => {
			this.loading = v;
		});
	}
}

export const ng2GlobalLoadingComponent = {
	name: 'globalLoading',
	def: downgradeComponent({
		component: GlobalLoadingComponent,
		propagateDigest: false,
	}),
};
