export enum Mode {
	MANUAL = 'manual',
	AUTOMATIC = 'automatic',
}

export enum ManualConsumption {
	BASELINE = 'baseLine',
	REAL = 'real',
}

export type ManualConsumptionType = 'baseLine' | 'real';

export interface EconomyProject {
	id?: number;
	name: string;
	description: string;
	startDate: string;
	organization?: number;
	installations: Installation[];
	measurementMode: Mode;
	files: File[];
}

export interface Installation {
	id?: number;
	installNumber: string;
	startDate: string;
	street: string;
	streetNumber: string;
	county: string;
	state: string;
	distributor: any;
	tariff: any;
	data: InstallationData[];
	totalEconomy: any;
	dataSource?: number;
	before: any;
	after: any;
}

export interface File {
	id?: number;
	fileName: string;
	type: string;
}
export interface InstallationData {
	id?: number | null;
	referenceYear: number;
	consumption: any[];
	baseline: boolean;
	installation?: number | null;
}

export interface ProjectConsumptionDetail {
	consumption: number;
	date: string;
	value: number | null;
}

export interface ManualConsumptions {
	baseline: InstallationData[] | undefined;
	real: InstallationData[] | undefined;
	installIndex: number;
}
