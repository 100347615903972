<label *ngIf="title" class="title">
	<span class="mr-5">{{ title }}</span>
	<span *ngIf="required" class="required mr-5">* </span>
	<ga-helper
		*ngIf="tooltip"
		[text]="tooltip"
		[icon]="'fa-solid fa-circle-info'"
		[iconSize]="12"
		[iconColor]="'#A3A3A3'"
		[tooltipLocale]="'above'"
	></ga-helper>
</label>
<div class="flex-row">
	<div
		class="color-box"
		(click)="toggleDropdown()"
		#ignoredInput
		[(colorPicker)]="color"
		[style.background]="color"
		[(cpToggle)]="isSecondModalOpen"
		[cpIgnoredElements]="[ignoredInput]"
		(colorPickerChange)="changeColor()"
		[cpOutputFormat]="'hex'"
		[cpSaveClickOutside]="false"
		[cpCancelButton]="true"
		[cpWidth]="'200px'"
		[cpHeight]="'auto'"
		[cpOKButton]="true"
		[cpCancelButtonText]="'Cancelar'"
		[cpOKButtonText]="'Salvar'"
		[cpOKButtonClass]="'btn buttons btn-color-ga'"
		[cpCancelButtonClass]="'btn btn-default buttons'"
		[cpAlphaChannel]="'disabled'"
	>
		<i *ngIf="showIcon" class="fa fa-regular fa-eye-dropper"></i>
	</div>
	<ng-container *ngIf="hasInput">
		<div class="ml-10">
			<ga-input
				class="picker-control"
				[placeholder]="'#000'"
				[lText]="'#'"
				[(ngModel)]="formatedStrColor"
				(ngModelChange)="inputColor($event)"
				name="color"
			></ga-input>
		</div>
	</ng-container>
</div>
<div class="dropdown">
	<div *ngIf="isFirstModalOpen" class="dropdown-content" #dropdown>
		<span class="box-title"
			>{{ "main.comparison.option-msg" | transloco }}:</span
		>
		<div class="colors-grid">
			<div
				*ngFor="let c of colors"
				[style.background]="c"
				class="color-option"
				(click)="selectOption(c)"
			></div>
		</div>
		<div class="bottom">
			<input class="color-input" [(ngModel)]="color" (input)="changeColor()" />
			<div class="arrow" (click)="changeModal()">
				<i class="fa-solid fa-chevron-down"></i>
			</div>
		</div>
	</div>
</div>
