import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'root' })
export class ApplianceService {
	constructor(private http: HttpClient) {}

	getAppliances(params) {
		return this.http
			.get(`${environment.backendUrl}/api/meters/${params.meterId}/appliances`)
			.toPromise()
			.then((res: any) => {
				return res?.appliances;
			});
	}

	getAppliancesConsumptions(params) {
		if (params.applianceId) {
			return this.http
				.get(
					`${environment.backendUrl}/api/meters/${params.meterId}/appliances/${params.applianceId}/consumptions`,
					{
						params: params,
					}
				)
				.toPromise()
				.then((res: any) => {
					return res?.consumptions;
				});
		} else {
			return this.http
				.get(
					`${environment.backendUrl}/api/meters/${params.meterId}/appliances/consumptions/${params.name}`,
					{
						params: params,
					}
				)
				.toPromise()
				.then((res: any) => {
					return res?.consumptions;
				});
		}
	}

	createAppliance(params) {
		const reqParams = {
			name: params.name,
			applianceTypeId: params.applianceTypeId,
			classificationId: params.classificationId,
		};

		return this.http
			.post(
				`${environment.backendUrl}/api/meters/${params.meterId}/appliances`,
				reqParams
			)
			.toPromise()
			.then((res) => res);
	}

	updateAppliance(appliance) {
		const updateAppliance = {
			id: appliance.id,
			applianceTypeId: appliance.applianceTypeId.id,
			name: appliance.name,
		};

		return this.http
			.put(
				`${environment.backendUrl}/api/meters/${appliance.meterId}/appliances/${appliance.id}`,
				updateAppliance
			)
			.toPromise()
			.then((res) => res);
	}
}

export const ng2ApplianceService = {
	name: ApplianceService.name,
	def: downgradeInjectable(ApplianceService),
};
