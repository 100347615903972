import { InjectionToken } from '@angular/core';
import { StateParams, StateService } from 'angular-ui-router';

// $state
export function ngRouterFactory(i: any) {
	return i.get('$state');
}
export const NG_ROUTER = new InjectionToken<StateService>('$state');
export const ngRouterProvider = {
	provide: NG_ROUTER,
	useFactory: ngRouterFactory,
	deps: ['$injector'],
};

// $stateParams
export function ngRouterParamsFactory(i: any) {
	return i.get('$stateParams');
}
export const NG_STATE_PARAMS = new InjectionToken<StateParams>('$stateParams');
export const ngRouterParamsProvider = {
	provide: NG_STATE_PARAMS,
	useFactory: ngRouterParamsFactory,
	deps: ['$injector'],
};

// $transition
export function ngTransitionFactory(i: any) {
	return i.get('$transitions');
}
export const NG_TRANSITION = new InjectionToken('$transition');
export const ngTransitionProvider = {
	provide: NG_TRANSITION,
	useFactory: ngTransitionFactory,
	deps: ['$injector'],
};

// $http
export function ngHttpFactory(i: any) {
	return i.get('$http');
}
export const NG_HTTP = new InjectionToken('$http');
export const ngHttpProvider = {
	provide: NG_HTTP,
	useFactory: ngHttpFactory,
	deps: ['$injector'],
};

// $q
export function ngQFactory(i: any) {
	return i.get('$q');
}
export const NG_Q = new InjectionToken('$q');
export const ngQProvider = {
	provide: NG_Q,
	useFactory: ngQFactory,
	deps: ['$injector'],
};

// $cacheFactory
export function ngCacheFactoryFactory(i: any) {
	return i.get('$cacheFactory');
}
export const NG_CACHE_FACTORY = new InjectionToken('$cacheFactory');
export const ngCacheFactoryProvider = {
	provide: NG_CACHE_FACTORY,
	useFactory: ngCacheFactoryFactory,
	deps: ['$injector'],
};

// $rootScope
export function ngRootScopeFactory(i: any) {
	return i.get('$rootScope');
}
export const NG_ROOT_SCOPE = new InjectionToken('$rootScope');
export const ngRootScopeProvider = {
	provide: NG_ROOT_SCOPE,
	useFactory: ngRootScopeFactory,
	deps: ['$injector'],
};

// UserService
// export function userServiceFactory(i: any) {
// 	return i.get('UserService');
// }
// export const userServiceProvider = {
// 	provide: 'UserService',
// 	useFactory: userServiceFactory,
// 	deps: ['$injector'],
// };

// Auxiliar
export function auxiliarFactory(i: any) {
	return i.get('Auxiliar');
}
export const AUXILIAR = new InjectionToken('auxiliar');
export const auxiliarProvider = {
	provide: AUXILIAR,
	useFactory: auxiliarFactory,
	deps: ['$injector'],
};

// sharedSource
export function sharedSourceFactory($state: any) {
	return $state.current.resolve['sharedSource']();
}
export const SHARED_SOURCE = new InjectionToken('sharedSource');
export const sharedSourceProvider = {
	provide: SHARED_SOURCE,
	useFactory: sharedSourceFactory,
	deps: [NG_ROUTER],
};

// notify
export function notifyFactory(i: any) {
	return i.get('notify');
}
export const NOTIFY = new InjectionToken('notify');
export const notifyProvider = {
	provide: NOTIFY,
	useFactory: notifyFactory,
	deps: ['$injector'],
};
