<!-- my-loader.component.html -->
<div
	class="progress-loader"
	[ngStyle]="{ position: relative ? 'relative' : 'absolute' }"
	[ngClass]="{ 'progress-white': lightMode }"
	[hidden]="!loading"
>
	<div class="loading-spinner">
		<div class="rect1"></div>
		<div class="rect2"></div>
		<div class="rect3"></div>
		<div class="rect4"></div>
		<div class="rect5"></div>
	</div>
</div>
