import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { DataSourceService } from 'webapp/app/shared/services/datasource.service';
import { delay, map } from 'rxjs/operators';
import { SendDataSourceNotification } from './user-notification.model';
import { Subscription } from 'rxjs';
@Component({
	selector: 'user-notification',
	templateUrl: './user-notification.component.html',
	styleUrls: ['./user-notification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNotificationComponent implements OnInit {
	@Input() userName = 'Fulano';
	@Input() userId!: number;
	@Output() emitChange: EventEmitter<SendDataSourceNotification> =
		new EventEmitter();
	@Output() loading = new EventEmitter<boolean>(true);
	notificationsDs: any[] = [];
	subscriptions: Subscription[] = [];

	constructor(private datasourceService: DataSourceService) {}

	ngOnInit(): void {
		this.getDataSources();
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	getDataSources() {
		if (!this.userId) return;
		this.loading.emit(true);
		const subs = this.datasourceService
			.$getUserDataSources(this.userId)
			.pipe(
				delay(200),
				map((userDatasources: any) =>
					userDatasources
						.filter((uds) => uds.dataSource.type === 'meter')
						.map((uds) => ({
							label: uds.dataSource.label,
							userId: this.userId,
							dataSourceId: uds.dataSource.id,
							offlineReport: uds.offlineReport,
						}))
				)
			)
			.subscribe((userDatasources: any[]) => {
				this.loading.emit(false);
				this.notificationsDs = userDatasources;
			});
		this.subscriptions.push(subs);
	}

	onChanged(event, ds: SendDataSourceNotification) {
		ds.offlineReport = event;
		const { label, ...datasource } = ds;
		this.emitChange.emit(datasource);
	}
}
