import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import mixpanel from 'mixpanel-browser';
import { User } from '../models/user.model';
import { MixPanelEvent, MixPanelNavigation } from '../models/mixpanel.model';
import { DateTime } from 'luxon';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class MixPanelService {
	public preventLoop = 0;
	private currentUser!: User;

	constructor(private cookies: CookieService) {}

	public mixPanelInit(user: User) {
		this.currentUser = user;
		if (!this.currentUser || this.cookies.get('original_auth')) return;
		mixpanel.identify(this.currentUser.id);
		mixpanel.people.set({
			$user_id: this.currentUser.id,
			$name: this.currentUser.firstName,
			$email: this.currentUser.email,
			$created: this.currentUser.createdAt,
			$last_login: DateTime.now().toISO(),
			organizationPlan: this.currentUser.billingplan,
		});
	}

	public mixPanelEvent(event: MixPanelEvent) {
		if (!this.currentUser || this.cookies.get('original_auth')) return;
		const userRegister = {
			$user_id: this.currentUser.id,
			$name: this.currentUser.firstName,
			$email: this.currentUser.email,
			$organization_id: this.currentUser.organizationObj.id,
			$organization: this.currentUser.organizationObj.name,
			$timezone: DateTime.local().zoneName,
			$language: this.currentUser.language,
			$data: DateTime.now().toISO(),
			$organizationPlan: this.currentUser.billingplan,
			isAdmin: !!this.currentUser.isAdmin,
			controlPanelMode: this.getControlPanel(this.currentUser.billingplan),
		};

		mixpanel.track(event.type, { ...userRegister, ...event.object });

		if (event.hasTimeEvent) mixpanel.time_event(event.targetEvent);
	}

	getControlPanel(billingPlanId) {
		switch (billingPlanId) {
			case 1:
				return 'Simplificado';
			case 2:
				return 'Expert';
			case 3:
				return 'Crowdfunding';
			case 4:
				return 'Residencial Solar';
			default:
				return 'unset';
		}
	}

	public mixPanelLogin(user: User) {
		const userRegister = {
			$user_id: user.id,
			$name: user.firstName,
			$email: user.email,
			$organization_id: user.organizationObj.id,
			$organization: user.organizationObj.name,
			$timezone: DateTime.local().zoneName,
			$language: user.language,
			$data: DateTime.now().toISO(),
			$organizationPlan: user.billingplan,
		};
		mixpanel.track('Login', userRegister);
	}

	private evChangeModule(
		toModule: string,
		fromModule: string,
		fromTab?: string
	) {
		this.preventLoop++;
		this.mixPanelEvent({
			type: 'page_exit',
			object: { module: fromModule },
			hasTimeEvent: false,
		});

		if (fromTab) {
			this.mixPanelEvent({
				type: 'tab_exit',
				object: { tab: fromTab },
				hasTimeEvent: false,
			});
		}

		setTimeout(() => {
			this.mixPanelEvent({
				type: 'page_viewed',
				object: { module: toModule },
				hasTimeEvent: true,
				targetEvent: 'page_exit',
			});
		}, 200);
		setTimeout(() => {
			this.preventLoop = 0;
		}, 400);
	}

	private evChangeTab(toTab: string, fromTab: string) {
		this.preventLoop++;
		this.mixPanelEvent({
			type: 'tab_exit',
			object: { tab: fromTab },
			hasTimeEvent: false,
		});
		setTimeout(() => {
			this.mixPanelEvent({
				type: 'tab_viewed',
				object: { tab: toTab },
				hasTimeEvent: true,
				targetEvent: 'tab_exit',
			});
		}, 200);
		setTimeout(() => {
			this.preventLoop = 0;
		}, 400);
	}

	public evChange(mixpanelNav: MixPanelNavigation) {
		const { toState, fromModule, toModule, fromTab, toTab, fromState } =
			mixpanelNav;

		if (
			this.preventLoop <= 0 &&
			!toState.includes(fromModule) &&
			fromModule !== toModule
		) {
			this.preventLoop++;
			this.evChangeModule(toModule, fromModule, fromTab);
		}

		if (this.preventLoop <= 0 && fromTab !== toTab) {
			this.evChangeTab(toTab, fromTab);
		}
	}

	public detectVisibility() {
		let visibilityChange;
		switch (true) {
			case typeof document.hidden !== 'undefined':
				visibilityChange = 'visibilitychange';
				break;
			case typeof (<any>document).mozHidden !== 'undefined':
				visibilityChange = 'mozvisibilitychange';
				break;
			case typeof (<any>document).msHidden !== 'undefined':
				visibilityChange = 'msvisibilitychange';
				break;
			case typeof (<any>document).webkitHidden !== 'undefined':
				visibilityChange = 'webkitvisibilitychange';
				break;
			default:
				break;
		}
		const acao = (event) => {
			if (this.preventLoop > 0) return;
			this.preventLoop++;
			const currentModule = window.location.pathname.split('/')[1];
			this.mixPanelEvent({
				type: event.srcElement.hidden ? 'page_exit' : 'page_viewed',
				object: { module: currentModule },
				hasTimeEvent: !event.srcElement.hidden,
				targetEvent: 'page_exit',
			});
			setTimeout(() => {
				this.preventLoop = 0;
			}, 400);
		};
		addEventListener(visibilityChange, acao, false);
		const beforeUnloadListener = (event) => {
			event.preventDefault();
			if (this.preventLoop > 0) return;
			this.preventLoop++;
			const currentModule = window.location.pathname.split('/')[1];
			this.mixPanelEvent({
				type: 'page_exit',
				object: { module: currentModule },
				hasTimeEvent: false,
			});
			setTimeout(() => {
				this.preventLoop = 0;
			}, 400);
		};
		addEventListener('beforeunload', beforeUnloadListener, { capture: true });
	}
}

export const ng2MixPanelService = {
	name: MixPanelService.name,
	def: downgradeInjectable(MixPanelService),
};
