<ga-loading [ngClass]="{ hidden: !loading }"></ga-loading>
<div [ngClass]="{ hidden: loading }" class="dialog">
	<div *ngIf="icon" class="row tag-icon">
		<ga-tag-icon
			[icon]="icon"
			[type]="iconType ? iconType : 'default'"
			[size]="18.82"
		></ga-tag-icon>
	</div>
	<div *ngIf="title" class="panel-title" mat-dialog-title>
		<h4 class="modal-title">{{ title }}</h4>
	</div>
	<div class="modal-body">
		<div *ngIf="message" mat-dialog-content class="desc-body">
			<span>{{ message }}</span>
		</div>
		<ng-container
			*ngIf="data.template"
			[ngTemplateOutlet]="data.template"
		></ng-container>
		<div *ngIf="showButtons" mat-dialog-actions class="dialog-actions">
			<button
				type="button"
				class="modal-button cancel"
				style="margin-right: 5px"
				(click)="onDismiss()"
				*ngIf="!showOnlyOne"
			>
				{{ "meter-page.button.cancel" | transloco }}
			</button>
			<button
				type="submit"
				class="modal-button"
				[ngClass]="
					(btnError ? 'remove' : 'send') + (showOnlyOne ? ' only-button' : '')
				"
				(click)="onConfirm()"
			>
				{{
					btnError
						? ("meter-page.button.exclude" | transloco)
						: (btnMessage | transloco)
				}}
			</button>
		</div>
	</div>
</div>
