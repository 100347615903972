<div class="content-wrapper view">
	<div class="container-fluid">
		<!-- ------------------------- Navbar Module ------------------------- -->
		<nav class="ga-navbar navbar-default">
			<div class="navbar-content">
				<h3>
					<a *ngIf="sourceScreen.module" (click)="backTo()">{{
						sourceScreen.module | transloco
					}}</a>
					<i
						*ngIf="sourceScreen.module"
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<a (click)="backTo()">{{ sourceScreen.title | transloco }}</a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<span class="navbar-content__breadcrumb-page">{{
						sourceScreen.to | transloco
					}}</span>
				</h3>
			</div>
		</nav>

		<div class="description">
			<div class="description__texts">
				<span class="description__texts__title">{{
					sourceScreen.title | transloco
				}}</span>
				<span class="description__texts__subtitle">{{
					sourceScreen.subtitle | transloco
				}}</span>
			</div>
		</div>

		<div class="col-lg-12 basic-data module-group">
			<div *ngIf="!this.uploadedFiles.value.length" class="row col-lg-6">
				<h3 class="basic-data__title col-lg-12">
					{{ sourceScreen.basicInfo | transloco }}
				</h3>

				<span class="basic-data__subtitle col-lg-12">
					{{ sourceScreen.basicInfoSub1 | transloco }}<br />
					{{ sourceScreen.basicInfoSub2 | transloco }}
				</span>

				<div class="form-group col-lg-12">
					<autocomplete-orgs
						[idSelected]="organization"
						(orgEmitter)="orgSelected($event)"
						[isRequired]="true"
					></autocomplete-orgs>
				</div>

				<div class="form-group col-lg-12">
					<button
						class="btn btn-group btn-default btn-default-ga mr-4"
						type="button"
						[disabled]="!organization"
						(click)="downloadTemplate()"
					>
						{{ "import.download" | transloco }}
					</button>
				</div>

				<div class="form-group col-lg-12">
					<ga-upload
						[icon]="'upload'"
						[iconType]="'default'"
						[iconSize]="18"
						[fileTypes]="fileTypes"
						[disabled]="!organization"
						[formControl]="uploadedFiles"
						[multiple]="false"
						name="uploadedFiles"
					></ga-upload>
				</div>
			</div>
			<div *ngIf="this.uploadedFiles.value.length" class="row col-lg-12">
				<upload-meter-list
					[filesControl]="uploadedFiles"
					[name]="sourceScreen.name"
					[organization]="organization"
					(close)="cancel()"
					(backTo)="backTo()"
				></upload-meter-list>
			</div>
		</div>
	</div>
</div>
