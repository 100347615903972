import { Component, Input, forwardRef } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DistributorUrl } from '../models/distributor.model';

@Component({
	selector: 'access-keys-card',
	templateUrl: './access-keys-card.component.html',
	styleUrls: ['./access-keys-card.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AccessKeysCardComponent),
			multi: true,
		},
	],
})
export class AccessKeysCardComponent {
	@Input() currentUrl!: DistributorUrl;
	@Input() accessId: number | undefined;
	@Input() credentialId: number | undefined;
	@Input() form!: FormGroup;

	ID_LIGHT = 2;

	showPassword(event, control: string) {
		this.form.controls[control].setValue(event.accessPassword);

		this.form.controls[control].setValidators(Validators.required);
	}

	renderEditPasswordInput(fieldName: string) {
		return fieldName === 'Senha' && this.credentialId;
	}
}
