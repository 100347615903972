import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
	selector: 'realtime-advanced',
})
export class RealTimeChartDirective extends UpgradeComponent {
	@Input('meter') meter;
	@Input('attached-meters') attachedMeters;
	@Input('timezone') timezone;
	@Input('width') width;
	@Input('height') height;
	@Input('show-meter-label') showMeterLabel;

	constructor(elementRef: ElementRef, injector: Injector) {
		// Create a new AngularJS scope
		const rootScope = injector.get('$rootScope');
		const scope = rootScope.$new();

		// Create a new Angular injector with the $scope provider
		const _injector = Injector.create(
			[
				{
					provide: '$scope',
					useValue: scope,
				},
			],
			injector
		);

		// Call the constructor of the UpgradeComponent class
		super('realtimeAdvanced', elementRef, _injector);
	}
}
