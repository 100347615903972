import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
	AfterViewInit,
	Component,
	Inject,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { downgradeComponent } from '@angular/upgrade/static';
import { DateTime } from 'luxon';
import {
	ConfirmDialogComponent,
	ConfirmDialogModel,
} from 'webapp/app/shared/confirm-dialog/confirm-dialog.component';
import { TopBarSearchService } from 'webapp/app/shared/header-search/top-bar-search/top-bar-search.service';
import { OrganizationSmall } from 'webapp/app/shared/models/organization.model';
import { CalendarService } from 'webapp/app/shared/services/calendar.service';
import { SnackService } from 'webapp/app/shared/snackbar/snackbar.service';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { EconomyProjectService } from '../../../shared/services/economy-projects.service';
import { EconomyProjectConsumeDetailComponent } from './consume-detail/economy-project-consume-detail.component';
import { ConsumptionSavingComponent } from './consumption-saving/consumption-saving.component';
import { PlannedProjectsInfo } from './planned-projects/general-info/planned-projects-info.component';
import { PlannedProjectService } from './planned-projects/planned-projects.service';
import { EconomyProjectDetailComponent } from './project-detail/economy-project-detail.component';
import { EconomyProject, Installation, Mode } from './project.model';
import { EconomyProjectReportsComponent } from './project-reports/economy-project-reports';
import { ManualProjectChartComponent } from './consumption-saving/manual-project-chart/manual-project-chart.component';
import { StateParams, StateService } from 'angular-ui-router';
import { OrganizationService } from 'webapp/app/shared/services/organization.service';

@Component({
	selector: 'economy-projects',
	templateUrl: './economy-projects.component.html',
	styleUrls: ['./economy-projects.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition(
				'expanded <=> collapsed',
				animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
			),
		]),
	],
})
export class EconomyProjectsComponent
implements OnInit, OnDestroy, AfterViewInit
{
	displayedColumns: string[] = [
		'code',
		'name',
		'installations',
		'startDate',
		'beforeMonthConsumption',
		'currentMonthConsumption',
		'totalEconomy',
		'edit',
	];

	isExpandedTable = 'expanded';

	projects = new MatTableDataSource();
	organizationId: number | undefined;

	calendarChannel = '';
	observerId: any;
	calendarOptions: any = {} as any;
	calendarStartDate: Date = new Date();
	calendarEndDate: Date = new Date();

	isTableExpanded = false;

	totalEconomyValue = 0;
	totalEconomyKwh = 0;

	constructor(
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		@Inject(NG_ROUTER) private $state: StateService,
		private _liveAnnouncer: LiveAnnouncer,
		private economyProjectService: EconomyProjectService,
		private calendarService: CalendarService,
		public dialog: MatDialog,
		private plannedProjectService: PlannedProjectService,
		private snackService: SnackService,
		private topBarSearch: TopBarSearchService,
		private organizationService: OrganizationService
	) {
		this.calendarChannel = 'branches';
	}

	@ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;
	@ViewChild(MatSort) sort: MatSort = {} as MatSort;

	ngOnInit(): void {
		this.projects.filterPredicate = function (
			data: any,
			filter: string
		): boolean {
			return (
				data.name.toLowerCase().includes(filter) ||
				data.id == filter ||
				data.installations.find((install) => install.installNumber === filter)
			);
		};
		if (this.organizationService.sessionOrg?.id) {
			this.organizationId = this.organizationService.sessionOrg.id;
		}
		this.initCalendar();
	}

	ngAfterViewInit() {
		this.projects.sortingDataAccessor = (item: any, property: string) => {
			switch (property) {
			case 'name':
				return item.name;
			case 'installations':
				return item.installations[0];
			case 'startDate':
				return Date.parse(item.startDate);
			case 'beforeMonthConsumption':
				return this.getConsumptionBefore(item, 'value');
			case 'currentMonthConsumption':
				return this.getConsumptionAfter(item, 'value');
			case 'totalEconomy':
				return this.getTotalEconomyInstallations(item, 'value');
			default:
				return item[property];
			}
		};
		this.topBarSearch.querySubject.subscribe((query) => {
			this.applyFilter(query);
		});
	}

	initCalendar() {
		this.registerAsCalendarObserver();
	}

	registerAsCalendarObserver() {
		this.observerId = this.calendarService.registerObserver(
			this.onCalendarNotify.bind(this),
			this.calendarChannel
		);
	}

	ngOnDestroy() {
		this.calendarService.unregisterObserver(
			this.observerId,
			this.calendarChannel
		);
	}

	onCalendarNotify(calendarData, event) {
		if (event === this.calendarService.Events.UPDATE && this.organizationId) {
			this.calendarStartDate = new Date(calendarData.startDate);
			this.calendarEndDate = new Date(calendarData.endDate);
			this.listEconomyProjects();
		}
	}

	listEconomyProjects() {
		const startDate = DateTime.fromISO(
			this.calendarStartDate.toISOString()
		).toFormat('yyyy-MM-dd');
		const endDate = DateTime.fromISO(
			this.calendarEndDate.toISOString()
		).toFormat('yyyy-MM-dd');

		const params = {
			presentation: true,
			organization: this.organizationId,
			startDate,
			endDate,
		};
		this.economyProjectService
			.listEconomyProjects(params)
			.subscribe((resp: EconomyProject[]) => {
				const economyProjects = resp.map((obj: EconomyProject) => ({
					...obj,
					isExpanded: false,
				}));
				this.totalEconomyValue = +this.economyProjectService.getTotalEconomy(
					economyProjects,
					'value'
				);
				this.totalEconomyKwh = +this.economyProjectService.getTotalEconomy(
					economyProjects,
					'consumption'
				);
				this.economyProjectService.setEconomyProjectList(resp);
				this.projects.data = economyProjects;
				this.projects.paginator = this.paginator;
				this.projects.sort = this.sort;
			});
	}

	/** Announce the change in sort state for assistive technology. */
	announceSortChange(sortState: Sort) {
		if (sortState.direction) {
			this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
		} else {
			this._liveAnnouncer.announce('Sorting cleared');
		}
	}

	editProject(project: EconomyProject) {
		this.$state.transitionTo('main.projectsEdit', {
			id: project.id,
			organizationId: this.organizationId,
		});
	}

	orgSelected(org: OrganizationSmall) {
		if (!org) return;
		this.organizationId = org.id;
		this.$state.transitionTo(
			'main.economy',
			{
				tab: 'projects',
				organizationId: this.organizationId,
			},
			{ inherit: true, reload: false, notify: false }
		);
		this.listEconomyProjects();
	}

	createProject() {
		this.$state.transitionTo(
			'main.projectsNew',
			{
				organizationId: this.organizationId,
			},
			{ reload: false }
		);
	}

	getConsumptionBefore(element: EconomyProject, type: string): number {
		const total =
			element.installations.length > 0
				? element.installations
					.map((installation: Installation) => {
						return installation.before[type];
					})
					.reduce((acc, current) => {
						if (!acc) {
							return undefined;
						}
						return (acc += current);
					})
				: undefined;
		return total != null ? total : '0';
	}

	getConsumptionAfter(element: EconomyProject, type: string): number {
		const total =
			element.installations.length > 0
				? element.installations
					.map((installation: Installation) => {
						return installation.after[type];
					})
					.reduce((acc, current) => {
						if (!acc) {
							return undefined;
						}
						return (acc += current);
					})
				: undefined;

		return total != null ? total : '0';
	}

	getTotalEconomyInstallations(element: EconomyProject, type: string) {
		const total =
			element.installations.length > 0
				? element.installations
					.map(
						(installation: Installation) => installation.totalEconomy[type]
					)
					.reduce((acc, current) => {
						if (!acc) {
							return undefined;
						}
						return (acc += current);
					})
				: undefined;

		return total != null ? total : '0';
	}

	confirmDeleteProjectDialog(project: EconomyProject): void {
		const confirmDialogModel: ConfirmDialogModel = {} as ConfirmDialogModel;
		const message = `Tem certeza que deseja excluir o projeto ${project.name}? Esta ação não pode ser desfeita.`;
		confirmDialogModel.message = message;
		confirmDialogModel.title = project.name;
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: confirmDialogModel,
		});
		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult) {
				this.economyProjectService
					.deleteEconomyProject(<number>project.id)
					.subscribe(
						() => {
							this.listEconomyProjects();
						},
						(erro) => {
							console.error(erro);
						}
					);
			}
		});
	}

	detailProjectDialog(project: EconomyProject): void {
		this.dialog.open(EconomyProjectDetailComponent, {
			data: project,
			width: '50%',
		});
	}

	plannedProjectDialog(): void {
		this.plannedProjectService.setOrganization(this.organizationId);
		this.dialog.open(PlannedProjectsInfo, {
			data: this.organizationId,
			width: '65%',
		});
	}

	consumeDetailProjectDialog(project: EconomyProject): void {
		const date = DateTime.fromISO(
			this.calendarStartDate.toISOString()
		).toFormat('yyyy-MM-dd');
		this.economyProjectService
			.getEconomyConsumeDetail(<number>project.id)
			.subscribe(
				(resp) => {
					if (resp.consumptions.length != 0) {
						this.dialog.open(EconomyProjectConsumeDetailComponent, {
							data: { element: resp, currentDate: date },
							width: '50%',
						});
					} else {
						const message = 'Este projeto ainda não possui consumos';
						this.snackService.showSnackBar(message, 'warning');
					}
				},
				(err) => {
					this.snackService.showSnackBar(
						err.error.Error.Errors.unexpectedError[0].message,
						'danger'
					);
				}
			);
	}

	reportsDialog(project: EconomyProject) {
		this.dialog.open(EconomyProjectReportsComponent, {
			data: project,
			width: '40%',
		});
	}

	openConsumptionSaving(element: EconomyProject) {
		const currentDate = DateTime.fromISO(
			this.calendarStartDate.toISOString()
		).toFormat('yyyy-MM');
		if (element.measurementMode === Mode.MANUAL) {
			this.dialog.open(ManualProjectChartComponent, {
				data: { element, currentDate },
				width: '60%',
			});
			return;
		}
		this.dialog.open(ConsumptionSavingComponent, {
			data: { element, currentDate },
		});
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
		this.projects.filter = filterValue;
	}
}
export const ng2ProjectsComponent = {
	name: 'economyProjects',
	def: downgradeComponent({
		component: EconomyProjectsComponent,
		propagateDigest: true,
	}),
};
