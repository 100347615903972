import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	forwardRef,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormFieldBaseDirective } from '../directive/form-field-base';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
	selector: 'ga-selector',
	templateUrl: './ga-selector.component.html',
	styleUrls: ['./ga-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GaSelectorComponent),
			multi: true,
		},
	],
})
export class GaSelectorComponent
	extends FormFieldBaseDirective
	implements OnInit, OnChanges, OnDestroy
{
	@Input() isTrigger = false;
	@Input() groupSelect = false;
	@Input() isSearch = false;
	@Input() haveTranslation = false;
	@Input() emptyLabel = '';
	@Input() selectAllText = 'columns.selectall';
	@Output() onSelection = new EventEmitter();

	allComplete = false;
	subscriptions: Subscription[] = [];
	searchInput: FormControl = new FormControl();
	filteredOptions: Array<any> = [];

	constructor() {
		super();
	}

	ngOnInit(): void {
		if (this.isMultiple) {
			this.updateAllComplete();
			const subscription = this.valSubject
				.pipe(tap(() => this.updateAllComplete()))
				.subscribe();
			this.subscriptions.push(subscription);
		}
		this.search();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes.groups)
			this.filteredOptions = changes.groups.currentValue;
		if (changes && changes.options)
			this.filteredOptions = changes.options.currentValue;
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	update(event) {
		const tempVal = this.val ? [...this.val] : [];
		if (this.val?.includes(event)) tempVal.splice(this.val.indexOf(event), 1);
		else tempVal.push(event);

		this.value = tempVal;
		this.updateAllComplete();
	}

	updateAllComplete() {
		switch (this.groupSelect) {
		case true:
			this.allComplete =
					!!this.groups.length &&
					this.groups.every((group) => {
						return group.options.every((option) =>
							this.val?.includes(option.id)
						);
					});
			break;
		default:
			if (!this.options.length || !Array.isArray(this.val)) return;
			this.allComplete = this.options.every((option) =>
				this.val.includes(option.id)
			);
			break;
		}
	}

	someComplete(): boolean {
		if (this.val && this.val.length > 0 && !this.allComplete) {
			return true;
		}
		return false;
	}

	setAll(completed: boolean) {
		this.allComplete = completed;
		let selectedValues: any[] = [];
		switch (true) {
		case this.allComplete && !this.groupSelect:
			selectedValues = this.options.map((t) => t.id);
			break;
		case this.allComplete && this.groupSelect:
			this.groups.forEach((group) => {
				selectedValues = [
					...selectedValues,
					...group.options.map((t) => t.id),
				];
			});
			break;
		default:
			break;
		}
		this.value = selectedValues;
	}

	compareFn(value1, value2) {
		return value1 == value2;
	}

	private search() {
		const subscription = this.searchInput.valueChanges
			.pipe(
				tap((value) => {
					if (this.groupSelect) {
						this.applyGroupFilter(value);
					} else {
						this.applyFilter(value);
					}
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	private applyGroupFilter(value: string) {
		const checkValue = value.toLowerCase();

		this.filteredOptions = this.groups
			.map((group) => {
				const newOptions = group.options
					.filter(
						(option) =>
							!value ||
							option.label.toLowerCase().includes(checkValue) ||
							option.subtext?.toLowerCase().includes(checkValue)
					)
					.slice();

				return {
					...group,
					options: newOptions,
				};
			})
			.filter((group) => group.options.length);
	}

	private applyFilter(value: string) {
		const checkValue = value.toLowerCase();
		this.filteredOptions = this.options.filter(
			(option) =>
				!value ||
				option.label.toLowerCase().includes(checkValue) ||
				option.subtext?.toLowerCase().includes(checkValue)
		);
	}

	get selectedValueLabel() {
		if (!this.val && typeof this.val !== 'boolean') return '';

		const ids = !Array.isArray(this.val) ? [this.val] : this.val;
		let selectedText = '';
		ids.forEach((id) => {
			if (this.groups && this.groups.length) {
				this.groups.forEach((group) => {
					const foundOption = group.options.find((option) => option.id === id);

					if (foundOption)
						selectedText = selectedText
							? `${selectedText}, ${foundOption.label}`
							: foundOption.label;
				});
			}
			if (this.options && this.options.length) {
				const foundOption = this.options.find((option) => option.id === id);
				if (foundOption)
					selectedText = selectedText
						? `${selectedText}, ${foundOption.label}`
						: foundOption.label;
			}
		});
		return selectedText;
	}

	selectionChange(event) {
		this.onSelection.emit(event);
	}
}
