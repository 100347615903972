import * as moment from 'moment';
//@ts-ignore
window["moment"] = moment;
import * as Highcharts from "highcharts/highcharts.js";
import HC_more from "highcharts/highcharts-more.js";
import Heatmap from "highcharts/modules/heatmap.js";
import Boost from "highcharts/modules/boost.js";
import Exporting from "highcharts/modules/exporting.js";
import ExportData from "highcharts/modules/export-data.js";

HC_more(Highcharts)
Heatmap(Highcharts)
Boost(Highcharts)
Exporting(Highcharts)
ExportData(Highcharts)