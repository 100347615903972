import {
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { PaginatorIntl } from '../../service/paginator.service';
import { ColumnType } from '../../model/datatable.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'ga-subtable',
	templateUrl: './ga-subtable.component.html',
	styleUrls: ['../../ga-datatable-new.component.scss'],
})
export class GaSubtableComponent implements OnInit, OnChanges {
	@ViewChild('subPaginator') paginator!: MatPaginator;
	@Input() showSubcolumTitle = false;
	@Input() showSubtablePaginator = false;
	@Input() displayedSubColumns: string[] = [];
	@Input() data: any = [];
	@Input() tableId!: number;
	@Input() subColumns: ColumnType[] = [];
	@Input() templates: TemplateRef<any> = {} as TemplateRef<any>;
	id!: number;
	table: MatTableDataSource<any> = new MatTableDataSource();

	constructor(
		private cdr: ChangeDetectorRef,
		private paginatorService: PaginatorIntl
	) {
		this.createRandomId();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			changes &&
			changes.data &&
			changes.data.currentValue &&
			(!changes.data.previousValue ||
				changes.data.currentValue.toString() !==
					changes.data.previousValue.toString())
		) {
			this.initTable(changes.data.currentValue);
			this.tablePaginatorLabels();
		}
	}

	ngOnInit(): void {
		if (this.data && this.data.length) this.initTable(this.data);
	}

	private initTable(data: any[]) {
		this.table.data = data;
		setTimeout(() => (this.table.paginator = this.paginator));
		this.cdr.detectChanges();
	}

	private tablePaginatorLabels() {
		if (!this.paginator) return;
		this.paginator._intl.firstPageLabel =
			this.paginatorService.tablePaginator().firstPage;
		this.paginator._intl.lastPageLabel =
			this.paginatorService.tablePaginator().lastPage;
		this.paginator._intl.itemsPerPageLabel =
			this.paginatorService.tablePaginator().itemsPerPage;
		this.paginator._intl.nextPageLabel =
			this.paginatorService.tablePaginator().nextPage;
		this.paginator._intl.previousPageLabel =
			this.paginatorService.tablePaginator().previousPage;

		this.paginator._intl.getRangeLabel = this.paginatorService.rangeLabel;
	}

	createRandomId(): void {
		this.id = Math.floor(Math.random() * 10000);
	}
}
