<div class="range">
	<div class="time-input">
		<i class="fa-regular fa-chevron-up" (click)="up('hourCtrl')"></i>
		<input
			type="text"
			maxlength="2"
			class="time"
			appOnlyNumbers
			[formControl]="hourCtrl"
			(blur)="changeTime('hourCtrl')"
		/>
		<i class="fa-regular fa-chevron-down" (click)="down('hourCtrl')"></i>
	</div>
	<span>:</span>
	<div class="time-input">
		<i class="fa-regular fa-chevron-up" (click)="up('minuteCtrl')"></i>
		<input
			type="text"
			maxlength="2"
			class="time"
			appOnlyNumbers
			[formControl]="minuteCtrl"
			(blur)="changeTime('minuteCtrl')"
		/>
		<i class="fa-regular fa-chevron-down" (click)="down('minuteCtrl')"></i>
	</div>
	<ng-container *ngIf="haveSeconds">
		<span>:</span>
		<div class="time-input">
			<i class="fa-regular fa-chevron-up" (click)="up('secondCtrl')"></i>
			<input
				type="text"
				maxlength="2"
				class="time"
				appOnlyNumbers
				[formControl]="secondCtrl"
				(blur)="changeTime('secondCtrl')"
			/>
			<i class="fa-regular fa-chevron-down" (click)="down('secondCtrl')"></i>
		</div>
	</ng-container>
</div>
