<div *ngIf="hasMetadata()">
	<!-- Breadcrumb -->
	<nav class="navbar-default top-breadcrumb">
		<div class="navbar-content">
			<h3>
				<div class="flex">
					{{ clientName }}
				</div>
			</h3>
		</div>
	</nav>
</div>
