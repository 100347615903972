import { Injectable } from '@angular/core';
import { HttpUtil } from './http.util';
import { Auxiliar } from './auxiliar';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient } from '@angular/common/http';
import { environment } from 'webapp/environments/environment';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UploadService {
	constructor(
		private HttpUtil: HttpUtil,
		private http: HttpClient,
		private Auxiliar: Auxiliar
	) {}

	downloadTemplateExcel(type, organization = '') {
		return this.HttpUtil.get({
			url: `/api/uploads/${type}/downloadTemplate/${organization}`,
			responseType: 'blob',
		})
			.then((res) => {
				this.Auxiliar.download(res, null);
				return res;
			})
			.catch((error) => console.error(error));
	}

	transformExcelToJson(data, params, type) {
		return this.HttpUtil.postData(
			`/api/uploads/${type}/list`,
			'file',
			data,
			params
		)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {
				throw Error(error);
			});
	}

	createFromJson(data, type) {
		return this.HttpUtil.post({
			url: `/api/uploads/${type}/create`,
			data: data,
		})
			.then((res) => {
				return res.data;
			})
			.catch((error) => {
				throw Error(error);
			});
	}

	uploadFileToS3(files, source, objectId): Observable<any> {
		const filesBody = files.map((file) => ({
			fileName: file.name,
			type: file.type,
		}));

		return this.http
			.post<any[]>(
				environment.backendUrl + `/api/${source}/id/${objectId}/uploadFiles`,
				filesBody
			)
			.pipe(
				mergeMap((res) =>
					forkJoin(
						res.map((s3Link, i) => {
							const formData = new FormData();
							Object.entries(s3Link.fields).forEach(
								([key, value]: [string, any]) => {
									formData.set(key, value);
								}
							);
							formData.set('file', files[i]);

							return this.http
								.post<any>(s3Link.url, formData, { withCredentials: false })
								.pipe(
									map((res) => res),
									catchError((err) => {
										return err;
									})
								);
						})
					)
				),
				catchError((error) => {
					throw error;
				})
			);
	}

	getFileFromS3(file) {
		return this.http.get<any[]>(
			environment.backendUrl +
				`/api/${file.model}/id/${file.modelId}/files/${file.fileName}`
		);
	}
}

export const ng2UploadService = {
	name: UploadService.name,
	def: downgradeInjectable(UploadService),
};
