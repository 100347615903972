class OrganizationSelectorController {
  constructor($scope, $timeout, UserService, OrganizationService) {
    this.$scope = $scope;
    this.userService = UserService;
    this.organizationService = OrganizationService;

    this.$timeout = $timeout;
  }

  async $onInit() {
    const vm = this;
    const storageOrg = this.organizationService.sessionOrg;

    vm.orgSelectId = vm.gaId ? vm.gaId : 'organizationSelector';
    vm.placeholder = vm.placeholder ? vm.placeholder : 'organization-selector.placeholder'

    vm.currentUser = await vm.userService.getCurrentUser();
    vm.isAdmin = vm.currentUser.isAdmin;

    if (!vm.selectedOrganization) {
      await vm.loadUserOrganizations();
    }

    if (!vm.selectedOrganization && !!storageOrg && !vm.notRetrieveFromSession) {
      vm.onOrganizationSelected(storageOrg);
      return;
    }

    vm.$scope.$on('organization-selector-reset', vm.resetComponent.bind(vm));
    vm.$scope.$on('organization-selector-edit', vm.organizationSelectEventEmitted.bind(vm));
  }

  async $onChanges(changesObj) {
    const vm = this;
    const selectedOrgChanges = changesObj.selectedOrganization;
    if (selectedOrgChanges && selectedOrgChanges.currentValue) {
      await vm.loadUserOrganizations();

      const currentOrg = selectedOrgChanges.currentValue;
      vm.onSelectedOrganizationChanged(currentOrg);
      vm.$scope.$apply();
    }
  }

  resetComponent() {
    const vm = this;
    delete vm.organizationName;
    delete vm.selectedOrganization;
  }

  async loadUserOrganizations() {
    const vm = this;
    const params = {
      fields: 'id,name',
      adminRoleOnly: vm.adminRoleOnly
    }
    vm.organizations = await vm.organizationService.getOrganizations(params);
  }

  organizationSelectEventEmitted(event, organization) {
    const vm = this;
    if (vm.organizations) {
      vm.onOrganizationSelected(organization);
    }
  }

  onSelectedOrganizationChanged(selectedOrganization) {
    const vm = this;

    if (!selectedOrganization || !Array.isArray(vm.organizations) || vm.organizations.length == 0) {
      return;
    }

    const organization = vm.resolveOrganization(selectedOrganization);
    if(!organization) return;
    this.organizationService.sessionOrg = organization;
    const adminOrgName = `${organization.id} - ${organization.name}`;
    vm.organizationName = vm.isAdmin ? adminOrgName : organization.name;

    vm.selectedOrganization = organization;
  }

  clearSearchOnBackspace($event) {
    const vm = this;
    const KEY_BACKSPACE = 8;
    const KEY_DELETE = 46;
    const insertOrDelete =
      $event.keyCode === KEY_BACKSPACE || $event.keyCode === KEY_DELETE;

    if (insertOrDelete) {
      vm.organizationName = null;
      vm.selectedOrganization = null;
      this.organizationService.sessionOrg = {};
      vm.onOrgChanged(null);
    }
  }

  onOrganizationSelected(selectedOrganization) {
    const vm = this;

    vm.onSelectedOrganizationChanged(selectedOrganization);
    vm.onOrgChanged(vm.selectedOrganization);
  }

  resolveOrganization(orgToResolve) {
    const vm = this;
    //Checks if its org object or only id - then resolves the id
    const orgId = orgToResolve.id ? orgToResolve.id : orgToResolve;
    return vm.organizations.find(org => org.id == orgId);
  }
}

const OrganizationSelector = {
  templateUrl:
    "/app/directives/organization-selector/organization-selector.html",
  controller: OrganizationSelectorController,
  controllerAs: "osVm",
  bindings: {
    gaId: "@",
    adminRoleOnly: "@",
    selectedOrganization: '<?',
    onOrgChanged: '<',
    // I18n key for input text placeholder
    placeholder: '@',
		//not retrieve organization from session storage
		notRetrieveFromSession: '=?',
		isDisabled: '=?'
  },
};

export const ngOrganizationSelector = {
  name: "organizationSelector",
  def: OrganizationSelector
}
