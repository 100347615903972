import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { ColumnType, DataTableList } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import {
	GaContentDialogComponent,
	GaContentDialogModel,
} from 'webapp/app/shared/ga-dialog/ga-content-dialog.component';
import { CredentialsParams } from 'webapp/app/shared/models/credentials-params.model';
import { OrganizationSmall } from 'webapp/app/shared/models/organization.model';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { CredentialsService } from 'webapp/app/shared/services/credentials.service';
import { MixPanelService } from 'webapp/app/shared/services/mixpanel.service';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';

@Component({
	selector: 'credentials-list',
	templateUrl: './credentials-list.component.html',
	styleUrls: ['./credentials-list.component.scss'],
})
export class CredentialsListComponent {
	organizationId!: number;
	displayEl$: Observable<DataTableList> = new Observable();

	constructor(
		private credentialsService: CredentialsService,
		private i18n: TranslocoService,
		public dialog: MatDialog,
		private notification: NotificationService,
		@Inject(NG_ROUTER) private $state: any,
		private mixpanel: MixPanelService
	) {}

	mixPanelEvent(type) {
		this.mixpanel.mixPanelEvent({ type, object: {} });
	}

	get columnHeaders(): ColumnType[] {
		return [
			{
				label: 'credentials-list.table.name',
				type: 'text',
				name: 'name',
				width: '30%',
			},
			{
				label: this.i18n.translate('credentials-list.table.consuming-units'),
				type: 'selectLink',
				name: 'consumingUnits',
				sortBy: (item: any, property: string) => item[property].length,
				width: '30%',
			},
			{
				label: 'credentials-list.table.distributor',
				type: 'text',
				name: 'distributor',
			},
			{
				label: '',
				type: 'action',
				name: 'actions',
				width: '45px',
			},
		];
	}

	get alertAction(): Action[] {
		return [
			{
				title: 'Editar',
				icon: 'fa-edit',
				type: 'edit',
			},
			{
				title: this.i18n.translate('global.delete'),
				icon: 'fa-trash',
				type: 'delete',
			},
		];
	}

	buildTable() {
		const params: CredentialsParams = {
			populateMetadatas: true,
			organization: this.organizationId,
		};
		this.displayEl$ = this.credentialsService.__getCredentials(params).pipe(
			map((res) => {
				const datatable: DataTableList = {} as DataTableList;
				datatable.data = res.credentials.map((credential) => {
					return {
						id: credential.id,
						name: credential.name,
						consumingUnits: this.generateOptionsToSelect(credential.energyBillMetadatas),
						distributor: credential.distributor,
						action: this.alertAction,
					};
				});
				datatable.column = this.columnHeaders;
				datatable.badge = {
					singular: this.i18n.translate('data-table.item'),
					plural: this.i18n.translate('data-table.items'),
				};
				datatable.filter = this.filterTable;
				return datatable;
			})
		);
	}

	filterTable(data: any, filterText: string) {
		const filter = filterText.toLowerCase();
		return (
			data.name.toLowerCase().includes(filter) ||
			data.distributor.toLowerCase().includes(filter) ||
			data.consumingUnits.some((unit) => {
				return unit.label.toLowerCase().includes(filter) || unit.subtext.toLowerCase().includes(filter);
			})
		);
	}

	toNew() {
		this.$state.transitionTo('main.credentials-new');
	}

	onActionCliked($event: Action) {
		switch ($event.type) {
		case 'edit':
			this.toEdit(<number>$event.id);
			break;
		case 'delete':
			this.removeDialog(<number>$event.id);
			break;
		default:
			break;
		}
	}

	generateOptionsToSelect(list) {
		const year = new Date().getFullYear();
		const optionsArr = list.map((element) => ({
			id: element.id,
			label: element.clientName || element.name,
			subtext: `Instalação: ${element.installNumber}`,
			placeholder: `${this.i18n.translate('organization.label.units')}`,
			link: 'main.energy-bills-list',
			params: {
				metadataId: element.id,
				referenceYear: year,
				organizationId: this.organizationId,
			},
		}));

		return optionsArr || [];
	}

	toEdit(id: number) {
		this.$state.transitionTo('main.credentials-detail', { id });
	}

	removeDialog(id: number) {
		const confirmDialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		confirmDialogModel.icon = 'error';
		confirmDialogModel.iconType = 'error';
		confirmDialogModel.title = this.i18n.translate('company.msg-delete');
		confirmDialogModel.message = this.i18n.translate('company.msg-delete-2');
		confirmDialogModel.btnError = true;
		const dialogRef = this.dialog.open(GaContentDialogComponent, {
			data: confirmDialogModel,
			width: '407px',
		});
		dialogRef.componentInstance.confirm.pipe().subscribe((dialogResult) => {
			if (dialogResult) {
				this.credentialsService.deleteCredentials(id).subscribe(() => {
					this.notification.success({
						msg: this.i18n.translate('credentials-list.table.msg-delete-success'),
					});
					this.buildTable();
				});
			}
			dialogRef.close();
		});
	}

	orgSelected(organization: OrganizationSmall) {
		if (!organization?.id) return;
		this.organizationId = organization.id;
		this.buildTable();
	}
}

export const ng2CredentialsListComponent = {
	name: 'credentialsList',
	def: downgradeComponent({
		component: CredentialsListComponent,
		propagateDigest: true,
	}),
};
