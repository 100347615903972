import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { withCache } from '@ngneat/cashew';
import { AuthService } from 'webapp/app/auth/auth.service';
import { environment } from 'webapp/environments/environment';
import { User, UserResponse } from '../models/user.model';
import { SendDataSourceNotification } from 'webapp/app/dashboard/registrations/user/user-notification/user-notification.model';

@Injectable({ providedIn: 'root' })
export class UserService {
	currentUser!: User;

	constructor(private http: HttpClient, private AuthService: AuthService) {}

	/**
	 ** userType - "consumption", "solar_generation"
	 **/
	getUsers(): Promise<UserResponse['user']> {
		return this.http
			.get(`${environment.backendUrl}/api/users`, this.withCacheOptions())
			.toPromise()
			.then((res: any) => {
				return res.user;
			});
	}

	getUser(id) {
		return this.http
			.get(`${environment.backendUrl}/api/users/${id}`)
			.toPromise()
			.then((res: any) => {
				return res;
			});
	}

	getUsersByInvite(token) {
		return this.http
			.get(`${environment.backendUrl}/api/users?activeUserToken=${token}`)
			.toPromise()
			.then((res: any) => {
				return res.user;
			});
	}

	getOrganizationUsers(params) {
		return this.http
			.get(
				`${environment.backendUrl}/api/organizations/users`,
				this.withCacheOptions({ params: params })
			)
			.toPromise()
			.then((res: any) => {
				return res;
			});
	}

	saveUser(userId, body) {
		return this.http
			.post(`${environment.backendUrl}/api/users/${userId}`, body)
			.toPromise()
			.then((res: any) => res);
	}

	activeInvite(params) {
		return this.http
			.post(`${environment.backendUrl}/api/activeInvite`, params)
			.toPromise();
	}

	changePassword(params) {
		return this.http
			.post(`${environment.backendUrl}/api/users/${params.id}/password`, params)
			.toPromise();
	}

	changeLanguage(params) {
		return this.http
			.post(`${environment.backendUrl}/api/users/${params.id}/language`, params)
			.toPromise();
	}

	//logged
	invite(params) {
		return this.http
			.post(`${environment.backendUrl}/api/invite`, params)
			.toPromise();
	}

	getUserFeature() {
		return this.http
			.get(`${environment.backendUrl}/api/feature`)
			.toPromise()
			.then((res: any) => {
				return res.features.map((value) => value.name);
			});
	}

	async getCurrentUser() {
		return this.http
			.get(`${environment.backendUrl}/api/users`, this.withCacheOptions())
			.toPromise()
			.then((res: any) => {
				this.currentUser = res?.user;
				return res?.user;
			});
	}

	async getUserPermissions() {
		return this.http
			.get(`${environment.backendUrl}/api/users`, this.withCacheOptions())
			.toPromise()
			.then((res: any) => {
				return (res as UserResponse).user.permissions;
			});
	}

	getBranches(params) {
		return this.http
			.get(`${environment.backendUrl}/api/users/${params.userId}/branches`, {
				params: params,
			})
			.toPromise()
			.then((res: any) => {
				return res.branches;
			});
	}

	getMeters(params) {
		return this.http
			.get(`${environment.backendUrl}/api/users/${params.userId}/meters`, {
				params: params,
			})
			.toPromise()
			.then((res: any) => {
				return res.meters;
			});
	}

	insertBranchUser(params) {
		return this.http
			.post(
				`${environment.backendUrl}/api/users/${params.userId}/branches/${params.branchId}`,
				{}
			)
			.toPromise();
	}

	deleteBranchUser(params) {
		return this.http
			.delete(
				`${environment.backendUrl}/api/users/${params.userId}/branches/${params.branchId}`
			)
			.toPromise();
	}

	insertUserMeter(params) {
		return this.http
			.post(
				`${environment.backendUrl}/api/users/${params.userId}/data-sources/${
					params.meterId || params.groupId
				}`,
				{}
			)
			.toPromise();
	}

	deleteUserMeter(params) {
		return this.http
			.delete(
				`${environment.backendUrl}/api/users/${params.userId}/data-sources/${
					params.meterId || params.groupId
				}`
			)
			.toPromise();
	}

	updateUserMeter(params) {
		return this.http
			.put(
				`${environment.backendUrl}/api/users/${params.userId}/data-sources/${
					params.meterId || params.groupId
				}`,
				params
			)
			.toPromise()
			.then((res: any) => res);
	}

	updateListOfUserMeter(
		body: SendDataSourceNotification[],
		userId: number,
		queryParams: any = {}
	) {
		return this.http.put(
			`${environment.backendUrl}/api/users/${userId}/data-sources`,
			body,
			{ params: queryParams }
		);
	}

	personificate(user) {
		return this.AuthService.personificate(user);
	}

	despersonificate() {
		return this.AuthService.despersonificate();
	}

	isPersonificated() {
		return this.AuthService.isPersonificated();
	}

	private withCacheOptions(customOptions?: any) {
		return Object.assign(
			{
				context: withCache(),
			},
			customOptions
		);
	}

	acceptTerms() {
		return this.http.put(
			`${environment.backendUrl}/api/users/accept-terms`,
			{}
		);
	}
}

export const ngUserService = {
	name: UserService.name,
	def: downgradeInjectable(UserService),
};
