import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'root' })
export class BranchService {
	constructor(private http: HttpClient) {}

	branches(params) {
		return this.http
			.get(`${environment.backendUrl}/api/branches`, { params: params })
			.toPromise()
			.then((resp: any) => {
				return resp?.branches;
			});
	}

	getBranch(branch) {
		return this.http
			.get(`${environment.backendUrl}/api/branches/` + branch.id)
			.toPromise()
			.then((resp: any) => {
				return resp?.branches;
			});
	}

	deleteBranch(branch) {
		if (branch.id) {
			return this.http
				.delete(`${environment.backendUrl}/api/branches/` + branch.id)
				.toPromise();
		}
	}

	saveBranch(branch) {
		if (branch.id) {
			return this.http
				.put(`${environment.backendUrl}/api/branches/` + branch.id, branch)
				.toPromise();
		} else {
			return this.http
				.post(`${environment.backendUrl}/api/branches/`, branch)
				.toPromise();
		}
	}

	getUsers(params) {
		return this.http
			.get(
				`${environment.backendUrl}/api/branches/` + params.branchId + '/users',
				{ params: params }
			)
			.toPromise()
			.then((res: any) => {
				return res?.users;
			});
	}

	getDataSources(params) {
		return this.http
			.get(
				`${environment.backendUrl}/api/branches/` + params.id + '/data-sources'
			)
			.toPromise()
			.then((res: any) => {
				return res?.dataSources;
			});
	}
}

export const ng2BranchService = {
	name: BranchService.name,
	def: downgradeInjectable(BranchService),
};
