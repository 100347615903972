//window.rangy = require('rangy');
//window.rangy.saveSelection = require('rangy/lib/rangy-selectionsaverestore');
//require('textangular/dist/textAngular-rangy.min.js');
//https://github.com/textAngular/textAngular
require('angular-sanitize/angular-sanitize.min.js')
require('textangular/dist/textAngular-sanitize.min.js');
require('textangular/dist/textAngular.min.js');

module.exports = {
    textAngular: 'textAngular',
    ngSanitize: 'ngSanitize'
}
