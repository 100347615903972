<div class="dialog">
	<div class="panel-heading">
		<button type="button" class="close" (click)="dialogRef.close()">
			<span aria-hidden="true">&times;</span>
			<span class="sr-only">Close</span>
		</button>
		<h4 class="modal-title">{{ "projects.table.reports" | transloco }}</h4>
	</div>
	<div class="modal-body" mat-dialog-content>
		<ng-container *ngIf="!uploadScreen">
			<h2>{{ "projects.table.project-reports" | transloco }}</h2>
			<h4 style="font-weight: normal">{{ data.name }}</h4>
			<div
				style="padding-bottom: 15px; max-height: 35vh; overflow: auto"
				class="files-list"
			>
				<div *ngIf="files.length == 0" class="no-files">
					{{ "projects.reports.empty" | transloco }}
				</div>
				<div class="file-item" *ngFor="let f of files; let i = index">
					{{ f.name }}
					<span *wchHasFeature="'projectsUpdate'">
						<span style="color: var(--secondary-color)">
							<svg
								width="14px"
								height="14px"
								style="cursor: pointer"
								(click)="downloadFile(i)"
							>
								<use
									xlink:href="/assets/images/custom_icons/download.svg#download"
								></use>
							</svg>
						</span>
						<i class="fa-regular fa-trash" (click)="deleteFile(i)"></i>
					</span>
				</div>
			</div>
			<div style="float: right; margin-top: 15px">
				<button
					*wchHasFeature="'projectsUpdate'"
					style="font-size: 13px"
					class="btn btn-outline-ga btn-slim"
					(click)="uploadScreen = true"
				>
					{{ "projects.reports.upload" | transloco }}
				</button>
			</div>
		</ng-container>
		<ng-container *ngIf="uploadScreen">
			<h2>{{ "projects.reports.upload" | transloco }}</h2>
			<h4 style="font-weight: normal">{{ data.name }}</h4>
			<div id="files" style="margin: 20px" class="form-group">
				<div class="dropzone" dragNDrop (fileDropped)="onFilesDropped($event)">
					<input
						type="file"
						multiple
						#filesDropRef
						id="fileDropRef"
						(change)="fileBrowseHandler($event)"
					/>
					<div class="row upload-background">
						<i class="fas fa-cloud-upload-alt"></i>
					</div>
					<div class="row upload-text">
						<span>{{ "file-uploader.any" | transloco }}</span>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
