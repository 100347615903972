import {
	Component,
	ElementRef,
	HostListener,
	Input,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { ColumnType } from '../model/datatable.model';

@Component({
	selector: 'ga-color-cell',
	styles: [
		`
			.cell-container {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 32px;
				border-radius: 4px;
				min-width: 70%;
				padding: 6px;
				width: fit-content;
			}

			.cell-container span {
				font-size: 14px;
				font-weight: 700;
				font-family: 'Roboto';
			}
		`,
	],
	template: `
		<div class="cell-container" #backgroundDiv>
			<span>{{ label }}</span>
		</div>
	`,
})
export class ColorCellComponent implements OnInit {
	@Input() column!: ColumnType;
	@Input() row: any;
	@ViewChild('backgroundDiv', { static: true }) backgroundDiv!: ElementRef;
	label = '';

	constructor(private renderer: Renderer2) {}

	ngOnInit(): void {
		this.setLabel();

		const color = this.row.color;
		const backgroundColor = this.generateLighterColor(color);

		this.renderer.setStyle(this.backgroundDiv.nativeElement, 'color', color);
		this.renderer.setStyle(
			this.backgroundDiv.nativeElement,
			'background-color',
			backgroundColor
		);
	}

	setLabel() {
		this.label =
			this.row.label.length >= 14 && window.innerWidth < 992
				? this.row.label.slice(0, 10) + '...'
				: this.row.label;
	}

	generateLighterColor(hexColor: string): string {
		const red = parseInt(hexColor.slice(1, 3), 16);
		const green = parseInt(hexColor.slice(3, 5), 16);
		const blue = parseInt(hexColor.slice(5, 7), 16);

		const lighterRed = Math.min(Math.floor(red + (255 - red) * 0.85), 255);
		const lighterGreen = Math.min(
			Math.floor(green + (255 - green) * 0.85),
			255
		);
		const lighterBlue = Math.min(Math.floor(blue + (255 - blue) * 0.85), 255);

		const lighterHex = `#${lighterRed
			.toString(16)
			.padStart(2, '0')}${lighterGreen
			.toString(16)
			.padStart(2, '0')}${lighterBlue.toString(16).padStart(2, '0')}`;

		return lighterHex;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		// this.checkIfTruncateNeeded(event);
	}

	checkIfTruncateNeeded(event) {
		if (event.target.innerWidth < 992) {
			this.label =
				this.row.label.length >= 14
					? this.row.label.slice(0, 10) + '...'
					: this.row.label;
		} else {
			this.label = this.row.label;
		}
	}
}
