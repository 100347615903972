import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GlobalLoadingComponent } from './loader.component';

@NgModule({
	declarations: [GlobalLoadingComponent],
	exports: [GlobalLoadingComponent],
	imports: [CommonModule],
})
export class GlobalLoadingModule {}
