export const transformFirstLetterUppercase = (value: string) => {
	return value.charAt(0).toUpperCase() + value.slice(1);
};

export const maxStringSize = (str: string, size = 12) => {
	return str.length > size ? (str = str.substring(0, size) + '...') : str;
};

export function isEmptyObject(obj: object): boolean {
	return Object.keys(obj).length === 0;
}
