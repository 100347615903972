import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { NG_STATE_PARAMS } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { EnergyBillService } from 'webapp/app/shared/services/energy-bill.service';

@Component({
	selector: 'energy-bill-breadcrumb',
	templateUrl: './energy-bill-breadcrumb.component.html',
})
export class EnergyBillBreadcrumb implements OnInit {
	metadata;
	hasCompany;
	clientName;
	clientNumber;
	cnpj;

	constructor(
		@Inject(NG_STATE_PARAMS) private $stateParams,
		private energyBillService: EnergyBillService
	) {}

	ngOnInit() {
		this.getMetadata()
			.then((metadata) => {
				this.metadata = metadata;
				return metadata;
			})
			.then((metadata) => {
				this.initCompanyCrumb(metadata);
				this.initMetadataCrumb(metadata);
			});
	}

	getMetadata() {
		const metadata = this.$stateParams.metadata;
		if (metadata) {
			return Promise.resolve(metadata);
		} else {
			const metadataId = this.$stateParams.metadataId;
			const referenceYear = this.$stateParams.referenceYear;
			return this.energyBillService.getPresentationMetadata(metadataId, {
				populateBills: true,
				referenceYear: referenceYear,
			});
		}
	}

	hasMetadata() {
		return this.metadata != null;
	}

	initCompanyCrumb(metadata) {
		this.hasCompany = metadata.company != null;
	}

	initMetadataCrumb(metadata) {
		this.clientName = metadata.clientName;
		this.clientNumber = metadata.clientNumber;
		this.cnpj = metadata.cnpj;
	}
}

export const ng2EnergyBillBreadcrumbComponent = {
	name: 'energyBillBreadcrumb',
	def: downgradeComponent({ component: EnergyBillBreadcrumb }),
};
