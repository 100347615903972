<div class="content-wrapper view">
	<div class="container-fluid">
		<!-- ------------------------- Navbar Module ------------------------- -->
		<nav class="ga-navbar navbar-default">
			<div class="navbar-content">
				<h3>
					<a (click)="backTo()">{{ 'main.menu.registrations' | transloco }}</a>
					<i class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"></i>
					<a (click)="backTo()">{{ 'global.branch.users' | transloco }}</a>
					<i class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"></i>
					<span *ngIf="!user.id" class="navbar-content__breadcrumb-page">{{
						'user-invite.modal.header' | transloco
					}}</span>
					<span *ngIf="user.id" class="navbar-content__breadcrumb-page">{{ user.firstName }}</span>
				</h3>
			</div>
		</nav>

		<!-- ------------------------- Title description of page ------------------------- -->
		<div class="description">
			<div class="description__texts">
				<span class="description__texts__title">{{
					!user.id ? ('user-invite.modal.header' | transloco) : ('user-invite.modal.edit-header' | transloco)
				}}</span>
				<span class="description__texts__subtitle">{{
					!user.id ? ('user-invite.modal.header-subtitle' | transloco) : ('user-invite.modal.edit-subtitle' | transloco)
				}}</span>
			</div>
		</div>

		<!-- ------------------------- Form Module ------------------------- -->
		<form id="meterForm" [formGroup]="userForm" class="flex-col" (ngSubmit)="onSubmit()" focusInvalidInput>
			<!-- ------------------------- Basic Data Module ------------------------- -->
			<div id="basicData" class="row col-lg-12 module-group">
				<h3 class="basic-data__title col-lg-12">
					{{ 'global.basic-info' | transloco }}
				</h3>
				<div class="row col-lg-12 p-uns">
					<!-- Nome -->
					<div class="form-group col-lg-4 col-sm-12">
						<ga-input
							[title]="'user-invite.firstname' | transloco"
							[placeholder]="'user-invite.placeholder.firstname' | transloco"
							[disabled]="userForm.controls['firstName'].disabled"
							[required]="true"
							[showError]="!userForm.controls['firstName'].valid && userForm.controls['firstName'].touched"
							formControlName="firstName"
							name="firstName"
						></ga-input>
					</div>
					<!-- Sobrenome -->
					<div class="form-group col-lg-4 col-sm-12">
						<ga-input
							[title]="'user-invite.lastname' | transloco"
							[placeholder]="'user-invite.placeholder.lastname' | transloco"
							[disabled]="userForm.controls['lastName'].disabled"
							[required]="true"
							[showError]="!userForm.controls['lastName'].valid && userForm.controls['lastName'].touched"
							formControlName="lastName"
							name="lastName"
						></ga-input>
					</div>
					<!-- Email -->
					<div class="form-group col-lg-4 col-sm-12">
						<ga-input
							[title]="'user-invite.email' | transloco"
							[placeholder]="'user-invite.placeholder.email' | transloco"
							[disabled]="userForm.controls['email'].disabled"
							[type]="'email'"
							[required]="true"
							[showError]="!userForm.controls['email'].valid && userForm.controls['email'].touched"
							[error]="'user-invite.email-invalid-msg' | transloco"
							formControlName="email"
							name="email"
						></ga-input>
					</div>
				</div>
				<!-- Organization Profiles -->
				<div class="form-group col-lg-12">
					<ga-organization-profiles
						[currentUserRoleOrgs]="currentUserRoleOrgs"
						[userRoleOrgs]="userRoleOrgs"
						[mainOrg]="user.organization"
						(changeOrg)="updateDatasources($event)"
						(mainOrgEmitter)="updateMainOrg($event)"
					>
					</ga-organization-profiles>
				</div>
				<div class="form-group col-lg-12">
					<span class="description-hiperlink">
						{{ 'user-invite.hiperlink.prefix' | transloco }}
					</span>
					<span class="description-hiperlink__link">
						<a href="{{ 'user-invite.hiperlink.user-types-url' | transloco }}" target="_blank">{{
							'user-invite.hiperlink.user-types' | transloco
						}}</a
						>.
					</span>
				</div>
				<div id="navigation" class="col-lg-12 p-uns">
					<hr class="separator" />
					<h3 class="settings__title">
						<span class="mr-5">{{ 'user-invite.group.navigation' | transloco }}</span>
						<ga-helper
							[text]="'user-invite.tooltip.navigation' | transloco"
							[icon]="'fa-solid fa-circle-info'"
							[iconSize]="14"
							[iconColor]="'var(--grayscale---gray-400)'"
							[tooltipLocale]="'above'"
						></ga-helper>
					</h3>
					<div class="row" id="sectionNavigation">
						<div class="form-group col-lg-3 col-md-4 col-sm-6">
							<ga-selector
								[title]="'user-invite.control.panel' | transloco"
								[placeholder]="'global.select' | transloco"
								[options]="panelOptions"
								[disabled]="userForm.controls['billingplan'].disabled || !isSelfEdit"
								[required]="true"
								[showError]="!userForm.controls['billingplan'].valid && userForm.controls['billingplan'].touched"
								formControlName="billingplan"
								name="billingplan"
							></ga-selector>
						</div>
					</div>
				</div>
				<div class="form-group col-lg-12">
					<span class="description-hiperlink">
						{{ 'user-invite.hiperlink.prefix' | transloco }}
					</span>
					<span class="description-hiperlink__link">
						<a href="{{ 'user-invite.hiperlink.view-types-url' | transloco }}" target="_blank">{{
							'user-invite.hiperlink.view-types' | transloco
						}}</a
						>.
					</span>
				</div>
				<div id="measurements" class="col-lg-12 p-uns">
					<hr class="separator" />
					<h3 class="settings__title">
						<span class="mr-5">{{ 'user-invite.group.measurements' | transloco }}</span>
						<ga-helper
							[text]="'user-invite.tooltip.measurements' | transloco"
							[icon]="'fa-solid fa-circle-info'"
							[iconSize]="14"
							[iconColor]="'var(--grayscale---gray-400)'"
							[tooltipLocale]="'above'"
						></ga-helper>
					</h3>
					<div class="row" id="sectionMeasurements">
						<div class="form-group col-lg-4 col-md-6">
							<ga-selector
								[title]="'user-invite.data.sources' | transloco"
								[placeholder]="'global.select' | transloco"
								[isMultiple]="true"
								[groupSelect]="true"
								[groups]="groupDataSources"
								[isSearch]="true"
								[isTrigger]="true"
								[disabled]="userForm.controls['dataSources'].disabled"
								formControlName="dataSources"
								name="dataSources"
							>
								<p>
									{{
										userForm.controls['dataSources'].value.length +
											' ' +
											('user-invite.placeholder.selected-points' | transloco)
									}}
								</p>
							</ga-selector>
						</div>
					</div>
					<div class="form-group col-lg-12">
						<div class="description-hiperlink">
							<span class="description-hiperlink__title">
								{{ 'user-invite.hiperlink.prefix2' | transloco }}
							</span>
							<span class="ddescription-hiperlink">
								{{ 'user-invite.hiperlink.sufix1' | transloco }}
							</span>
						</div>
					</div>
				</div>
				<div id="expiration" class="col-lg-12 p-uns">
					<hr class="separator" />
					<h3 class="settings__title">
						<span class="mr-5">{{ 'user-invite.group.expiration' | transloco }}</span>
						<ga-helper
							[text]="'user-invite.tooltip.expiration' | transloco"
							[icon]="'fa-solid fa-circle-info'"
							[iconSize]="14"
							[iconColor]="'var(--grayscale---gray-400)'"
							[tooltipLocale]="'above'"
						></ga-helper>
					</h3>
					<div class="row" id="sectionExpiration">
						<div class="form-group col-lg-3 col-md-4 col-sm-6">
							<ga-date-range-picker
								[title]="'user-invite.expiration.date' | transloco"
								[placeholder]="'00/00/0000'"
								[showTime]="false"
								formControlName="accountExpiration"
								name="accountExpiration"
								[showOptions]="false"
								[startWithoutCurrentDate]="true"
								[position]="'above'"
								[formattedDate]="formattedDate"
							>
							</ga-date-range-picker>
						</div>
					</div>
				</div>
			</div>

			<!-- ------------------------- Notification Module ------------------------- -->
			<div id="notification" class="row col-lg-12 module-group">
				<h3 class="settings__title mt-0">
					<span class="mr-5">{{ 'user-invite.alerts' | transloco }}</span>
					<ga-helper
						[text]="'user-invite.tooltip.notification' | transloco"
						[icon]="'fa-solid fa-circle-info'"
						[iconSize]="14"
						[iconColor]="'var(--grayscale---gray-400)'"
						[tooltipLocale]="'above'"
					></ga-helper>
				</h3>
				<div class="row" id="sectionExpiration">
					<div class="form-group col-lg-3 col-md-4 col-sm-6">
						<ga-selector
							[title]="'user-invite.receive-alerts' | transloco"
							[placeholder]="'global.select' | transloco"
							[options]="connectionOptions"
							[disabled]="userForm.controls['offlineReport'].disabled"
							[required]="true"
							[showError]="!userForm.controls['offlineReport'].valid && userForm.controls['offlineReport'].touched"
							formControlName="offlineReport"
							name="offlineReport"
						></ga-selector>
					</div>
				</div>
				<div class="form-group col-lg-12">
					<div class="description-hiperlink">
						<span class="description-hiperlink__title">
							{{ 'user-invite.hiperlink.prefix2' | transloco }}
						</span>
						<span class="ddescription-hiperlink">
							{{ 'user-invite.hiperlink.sufix2' | transloco }}
						</span>
					</div>
				</div>

				<!-- ------------------------- button group ------------------------- -->
				<hr class="separator" />
				<div class="button-group flex-row">
					<div class="btn-toolbar" role="toolbar" style="text-align: center">
						<div class="btn-group" role="group">
							<button class="btn btn-default" (click)="backTo()">
								{{ 'appliance.btn.cancel' | transloco }}
							</button>
						</div>
						<div class="btn-group" role="group">
							<button class="btn btn-primary btn-color-ga" type="submit" [disabled]="userForm.invalid">
								<span>
									{{ isEdition() ? ('global.save' | transloco) : ('user-invite.send.invite' | transloco) }}
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>

<ng-template #inviteSucessTemplate let-data>
	<div class="button-group modal-btn-group flex-row">
		<div class="btn-group" role="group">
			<button class="btn btn-default" (click)="closeDialog(); backTo()">
				{{ 'user-invite.modal.finish' | transloco }}
			</button>
		</div>
		<div class="btn-group" role="group">
			<ga-button-dropdown
				[label]="'user-invite.modal.btn.new.invite' | transloco"
				[btnSize]="'large'"
				[actionOptions]="actionButtons"
				(action)="newAction($event)"
			></ga-button-dropdown>
		</div>
	</div>
</ng-template>
