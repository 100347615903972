/* eslint-disable @typescript-eslint/no-empty-function */
import * as moment from 'moment-timezone';
import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { downgradeInjectable } from '@angular/upgrade/static';
import { NOTIFY } from 'webapp/hybrid-helpers/ajs-upgraded-providers';

declare let $: JQueryStatic;

@Injectable({ providedIn: 'root' })
export class Auxiliar {
	constructor(@Inject(NOTIFY) private notify) {}

	applyTariffAndTotal(items) {
		const total = { symbol: '', valueRaw: 0, value: 0, activeEnergy: 0 };
		items.forEach(function (item) {
			item.price = this.currency(item.activeEnergy, item.invoiceDate);
			total.activeEnergy += item.activeEnergy;
			total.valueRaw += isNaN(item.price.valueRaw) ? 0 : item.price.valueRaw;

			if (!total.symbol && item.price.symbol) {
				total.symbol = item.price.symbol;
			}
		});
		// total.activeEnergy = $filter('toWatts')(total.activeEnergy);
		// total.value = $filter('currency')(total.valueRaw, '', 2);
		total.value = total.valueRaw;
		return { items: items, total: total };
	}

	buildDateSeries(
		startDt,
		endDt,
		period,
		items,
		valueProperty,
		timezone,
		inspectionDay: number | null = null,
		dateProperty = 'date'
	) {
		const labels: number[] = [];
		const values: any[] = [];
		const newItems: any[] = [];
		valueProperty = valueProperty || 'activeEnergy';
		const sdt = moment.tz(moment(startDt).format('YYYY-MM-DD'), timezone);
		const edt = moment.tz(moment(endDt).format('YYYY-MM-DD'), timezone);
		startDt = moment.tz(moment(startDt).format('YYYY-MM-DD'), timezone);
		sdt.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
		edt.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
		const k = 0;
		const max = 0;
		const min = 0;
		const ii = 0;
		const counter = 0;
		const h = 0;
		//Generate labels from start date and inspection date
		switch (period) {
		case 'day':
			return this.buildDaySerie(
				edt,
				sdt,
				items,
				timezone,
				dateProperty,
				valueProperty,
				max,
				labels,
				values,
				newItems
			);
		case 'month':
			return this.buildMonthSerie(
				edt,
				sdt,
				items,
				timezone,
				dateProperty,
				valueProperty,
				max,
				labels,
				values,
				newItems,
				counter
			);
		case 'invoice':
			return this.buildInvoiceSerie(
				startDt,
				endDt,
				items,
				timezone,
				dateProperty,
				valueProperty,
				max,
				labels,
				values,
				newItems,
				ii,
				k,
				inspectionDay
			);
		case 'hour':
			return this.buildHourSerie(
				edt,
				sdt,
				startDt,
				h,
				items,
				timezone,
				dateProperty,
				valueProperty,
				max,
				labels,
				values,
				newItems
			);
		case 'minute':
			return this.buildMinuteSerie(
				startDt,
				endDt,
				h,
				items,
				timezone,
				dateProperty,
				valueProperty,
				min,
				max,
				labels,
				values,
				newItems,
				k
			);
		case 'year':
			return this.buildYearSerie(
				startDt,
				endDt,
				items,
				timezone,
				dateProperty,
				valueProperty,
				max,
				labels,
				values,
				newItems,
				ii,
				k
			);
		default:
			return { labels, values, max, newItems };
		}
	}
	buildDaySerie(
		edt,
		sdt,
		items,
		timezone,
		dateProperty,
		valueProperty,
		max,
		labels,
		values,
		newItems
	) {
		//Increment 1 day for each date until the inspection date
		while (edt.diff(sdt, 'days') >= 0) {
			let value = 0;
			const item =
				items.find(
					(i) =>
						sdt.tz(timezone).format('YYYY-MM-DD') ==
						moment(i[dateProperty]).tz(timezone).format('YYYY-MM-DD')
				) || {};
			if (item && item[valueProperty]) {
				value = item[valueProperty];
				max = value > max ? value : max;
			}
			// if(items[k] && sdt.tz(timezone).format('YYYY-MM-DD') == moment(items[k].date).tz(timezone).format('YYYY-MM-DD')){
			//   item = items[k];
			//   value = items[k][valueProperty];
			//   max = value > max ? value : max;
			//   k++;
			// }
			labels.push(parseInt(sdt.tz(timezone).format('x')));
			values.push({
				y: value ? value : 0,
				name: parseInt(sdt.tz(timezone).format('x')),
			});
			newItems.push(item);
			sdt.add(1, 'day');
		}
		return { labels, values, max, newItems };
	}
	buildMonthSerie(
		edt,
		sdt,
		items,
		timezone,
		dateProperty,
		valueProperty,
		max,
		labels,
		values,
		newItems,
		counter
	) {
		const months = moment(edt).diff(sdt, 'months') + 1;
		//Increment 1 day for each date until the inspection date
		while (months - counter > 0) {
			let value = 0;
			const item =
				items.find(
					(i) =>
						sdt.tz(timezone).month() ==
							moment(i[dateProperty]).tz(timezone).month() &&
						sdt.tz(timezone).year() ==
							moment(i[dateProperty]).tz(timezone).year()
				) || {};
			if (item && item[valueProperty]) {
				value = item[valueProperty];
				max = value > max ? value : max;
			}
			// if(items[k] && sdt.tz(timezone).format('YYYY-MM-DD') == moment(items[k].date).tz(timezone).format('YYYY-MM-DD')){
			//   item = items[k];
			//   value = items[k][valueProperty];
			//   max = value > max ? value : max;
			//   k++;
			// }
			labels.push(parseInt(sdt.tz(timezone).format('x')));
			values.push({
				y: value ? value : 0,
				name: parseInt(sdt.tz(timezone).format('x')),
				/*,drilldown: true*/
			});
			newItems.push(item);
			//if(sdt.month()!=12){
			sdt.month(sdt.month() + 1);
			counter++;
		}
		return { labels, values, max, newItems };
	}
	buildInvoiceSerie(
		startDt,
		endDt,
		items,
		timezone,
		dateProperty,
		valueProperty,
		max,
		labels,
		values,
		newItems,
		ii,
		k,
		inspectionDay
	) {
		const startDate = moment.tz(startDt, timezone);
		let yearsCount = moment(endDt).diff(startDate, 'years') + 1;
		//Increment 1 month for each date until the inspection date
		while (ii < 12 && yearsCount > 0) {
			let item = { activeEnergy: 0, group: ii + 1 };
			let value = 0;
			if (
				items[k] &&
				(parseInt(moment(items[k][dateProperty]).tz(timezone).format('MM')) ==
					ii + 1 ||
					(inspectionDay != null &&
						parseInt(moment(items[k][dateProperty]).tz(timezone).format('MM')) -
							1 ==
							ii + 1 &&
						parseInt(
							moment(items[k][dateProperty]).tz(timezone).format('DD')
						) <= inspectionDay))
			) {
				item = items[k];
				value = items[k][valueProperty];
				max = value > max ? value : max;
				k++;
			}
			//labels.push(ii+1);
			labels.push(
				parseInt(moment(startDate).month(ii).tz(timezone).format('x'))
			);
			values.push({
				y: value ? value : 0,
				name: parseInt(moment(startDate).month(ii).tz(timezone).format('x')),
				/*,drilldown: true*/
			});
			newItems.push(item);
			ii++;

			if (ii == 12) {
				ii = 0;
				yearsCount--;
				startDate.add(1, 'years');
			}
		}
		return { labels, values, max, newItems };
	}
	buildHourSerie(
		edt,
		sdt,
		startDt,
		h,
		items,
		timezone,
		dateProperty,
		valueProperty,
		max,
		labels,
		values,
		newItems
	) {
		const startDate = moment.tz(startDt, timezone).hours(h);
		h = startDate.hours();

		let daysCount = edt.diff(sdt, 'days') + 1;

		while (h < 24 && daysCount > 0) {
			let value = 0;
			const item =
				items.find(
					(i) =>
						moment(startDate).tz(timezone).format('YYYY-MM-DD HH') ==
						moment(i[dateProperty]).tz(timezone).format('YYYY-MM-DD HH')
				) || {};
			if (item) {
				if (typeof valueProperty === 'function') {
					value = valueProperty(item);
				} else {
					value = item[valueProperty];
				}
				max = value > max ? value : max;
			}
			//Create labels using momentjs date object with differente hours
			labels.push(+moment(startDate).hours(h).format('x'));
			values.push({
				y: value ? value : 0,
				name: +moment(startDate).hours(h).format('x'),
			});
			newItems.push(item);
			h++;
			if (h == 24) {
				h = 0;
				daysCount--;
				startDate.add(1, 'days');
			}
			startDate.hours(h);
		}
		return { labels, values, max, newItems };
	}
	buildMinuteSerie(
		startDt,
		endDt,
		h,
		items,
		timezone,
		dateProperty,
		valueProperty,
		min,
		max,
		labels,
		values,
		newItems,
		k
	) {
		const startDate = moment.tz(startDt, timezone).hours(h).minutes(min);
		h = startDate.hours();
		min = startDate.minutes();

		let daysCount = moment(endDt).diff(startDt, 'days') + 1;

		while (min < 60 && h < 24 && daysCount > 0) {
			let value = 0;
			let item = {};
			if (
				items[k] &&
				moment(startDate).tz(timezone).format('YYYY-MM-DD HH:mm') ==
					moment(items[k][dateProperty]).tz(timezone).format('YYYY-MM-DD HH:mm')
			) {
				item = items[k];

				if (typeof valueProperty === 'function') {
					value = valueProperty(items[k]);
				} else {
					value = items[k][valueProperty];
				}
				max = value > max ? value : max;
				k++;
			}

			//Create labels using momentjs date object with differente hours
			labels.push(
				+moment(startDate)
					.hours(h)
					.minutes(min + 15)
					.format('x')
			);
			values.push({
				y: value ? value : 0,
				name: +moment(startDate).hours(h).minutes(min).format('x'),
			});
			newItems.push(item);
			min = min + 15;
			if (min == 60) {
				min = 0;
				h++;
				if (h == 24) {
					h = 0;
					daysCount--;
					startDate.add(1, 'days');
				}
			}
			startDate.hours(h).minutes(min);
		}
		return { labels, values, max, newItems };
	}
	buildYearSerie(
		startDt,
		endDt,
		items,
		timezone,
		dateProperty,
		valueProperty,
		max,
		labels,
		values,
		newItems,
		ii,
		k
	) {
		const startDate = moment.tz(startDt, timezone);

		let yearsCount = moment(endDt).diff(startDate, 'years') + 1;
		//Increment 1 month for each date until the inspection date
		while (yearsCount > 0) {
			let item = { activeEnergy: 0, group: ii + 1 };
			let value = 0;

			if (
				items[k] &&
				moment(items[k][dateProperty]).tz(timezone).year() ==
					moment(startDt)
						.set({ year: moment(startDt).year() + ii })
						.year()
			) {
				item = items[k];
				value = items[k][valueProperty];
				max = value > max ? value : max;
				k++;
			}
			//labels.push(ii+1);
			labels.push(parseInt(moment(startDate).tz(timezone).format('x')));
			values.push({
				y: value ? value : 0,
				name: parseInt(moment(startDate).tz(timezone).format('x')),
				/*,drilldown: true*/
			});
			newItems.push(item);
			ii++;
			startDate.add(1, 'years');
			yearsCount--;
		}
		return { labels, values, max, newItems };
	}

	diffDays(startDt, endDt) {
		return Math.floor((Date.parse(endDt) - Date.parse(startDt)) / 86400000);
	}

	cardColor(d, k) {
		const hsvToRgb = function (h, s, v) {
			let r, g, b;
			const i = Math.floor(h * 6);
			const f = h * 6 - i;
			const p = v * (1 - s);
			const q = v * (1 - f * s);
			const t = v * (1 - (1 - f) * s);
			switch (i % 6) {
			case 0:
				(r = v), (g = t), (b = p);
				break;
			case 1:
				(r = q), (g = v), (b = p);
				break;
			case 2:
				(r = p), (g = v), (b = t);
				break;
			case 3:
				(r = p), (g = q), (b = v);
				break;
			case 4:
				(r = t), (g = p), (b = v);
				break;
			case 5:
				(r = v), (g = p), (b = q);
				break;
			}
			return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
		};
		return hsvToRgb((77 * Math.tanh(d * k) + 77) / 360, 0.7, 0.75);
	}

	download(res, contentType) {
		const contentDisposition = res.data
			? res.headers('Content-Disposition')
			: res.headers.get('Content-Disposition');
		const filename = contentDisposition.match(/filename="(.+)"/i)[1];

		contentType = contentType || res.headers('Content-Type');

		const blob = res.data
			? new Blob([res.data], { type: contentType })
			: res.body;

		// Create an invisible A element
		const a = document.createElement('a');
		a.style.display = 'none';
		document.body.appendChild(a);

		// Set the HREF to a Blob representation of the data to be downloaded
		a.href = window.URL.createObjectURL(blob);

		// Use download attribute to set set desired file name
		a.setAttribute('download', filename);

		// Trigger the download by simulating click
		a.click();

		// Cleanup
		window.URL.revokeObjectURL(a.href);
		document.body.removeChild(a);
	}

	// service implementation
	getPager(totalItems, currentPage, pageSize) {
		// default to first page
		currentPage = currentPage || 1;

		// default page size is 10
		pageSize = pageSize || 10;

		// calculate total pages
		const totalPages = Math.ceil(totalItems / pageSize);

		let startPage, endPage;
		if (totalPages <= 10) {
			// less than 10 total pages so show all
			startPage = 1;
			endPage = totalPages;
		} else {
			// more than 10 total pages so calculate start and end pages
			if (currentPage <= 6) {
				startPage = 1;
				endPage = 10;
			} else if (currentPage + 4 >= totalPages) {
				startPage = totalPages - 9;
				endPage = totalPages;
			} else {
				startPage = currentPage - 5;
				endPage = currentPage + 4;
			}
		}

		// calculate start and end item indexes
		const startIndex = (currentPage - 1) * pageSize;
		const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

		// create an array of pages to ng-repeat in the pager control
		const pages = _.range(startPage, endPage + 1);

		const offset = (currentPage - 1) * pageSize;

		// return object with all pager properties required by the view
		return {
			totalItems: totalItems,
			currentPage: currentPage,
			pageSize: pageSize,
			totalPages: totalPages,
			startPage: startPage,
			endPage: endPage,
			startIndex: startIndex,
			endIndex: endIndex,
			pages: pages,
			offset: offset,
		};
	}

	/**
  @param inspectionDay
  @param date - If date param is passed so is used that date's month for inspectionDate
  **/
	getInspectionDate(inspectionDay, date) {
		const inspectionDate = new Date();

		if (inspectionDay == 0) {
			const referenceDate = date ? moment(date) : moment();
			inspectionDate.setDate(
				referenceDate.date(1).add(1, 'month').subtract(1, 'day').date()
			);
		} else {
			inspectionDate.setDate(inspectionDay);
		}

		inspectionDate.setHours(12, 0, 0);

		if (date == undefined) {
			date = new Date();
			date.setHours(12, 0, 0);
			if (inspectionDate < date) {
				inspectionDate.setMonth(inspectionDate.getMonth() + 1);
			}
		} else {
			inspectionDate.setMonth(date.getMonth());
			inspectionDate.setFullYear(date.getFullYear());
		}

		return inspectionDate;
	}

	applyDateTimeMask(selector) {
		(<any>$(selector)).inputmask('datetime', {
			mask: '1/2/y - h:s',
			placeholder: 'DD/MM/YYYY - HH:MM',
			leapday: '-02-29',
			separator: '/',
			autoclear: false,
			alias: 'dd-mm-yyyy',
			onincomplete: function () {},
			oncomplete: function () {},
		});
	}

	applyDateMask(selector) {
		(<any>$(selector)).inputmask('date', {
			mask: '1/2/y',
			placeholder: 'DD/MM/YYYY',
			leapday: '-02-29',
			separator: '/',
			autoclear: false,
			alias: 'dd-mm-yyyy',
			onincomplete: function () {
				console.log('inputmask incomplete');
			},
			oncomplete: function () {
				console.log('inputmask complete');
			},
		});
	}

	paceAdjustment() {
		$('.pace-progress').css('top', '80px');

		const scrollToTop = $(window).scrollTop();

		$(document).scroll(function () {
			scrollToTop && scrollToTop > 100
				? $('#header').addClass('test')
				: $('#header').removeClass('test');

			if (scrollToTop && scrollToTop > 0) {
				$('.pace-progress').css('top', '0px');
			} else {
				$('.pace-progress').css('top', '80px');
			}
		});
	}

	forceResizeWindow() {
		setTimeout(function () {
			window.dispatchEvent(new Event('resize'));
		}, 100);
	}

	showMessage(message, type = 'success') {
		const templateClass = type == 'success' ? 'fa-circle-check' : 'fa-circle-x';

		const notifyMessage = {
			messageTemplate: `<i class='fa-solid ${templateClass} fa-2 icon-notity' aria-hidden='true'></i><span>{{$message}}</span>`,
			message: message,
			templateUrl: '/app/components/angular-notify.html',
			classes: type != 'success' ? 'cg-notify-message-error' : '',
			position: 'right',
			duration: 4 * 1000,
		};

		this.notify(notifyMessage);
	}

	showErrorMessages(res) {
		const data = res.data ? res.data.Error : res.error?.Error;
		if (!data) {
			this.showMessage('Erro ao conectar ao servidor', 'error');
			return;
		}
		Object.keys(data.Errors).forEach((key) => {
			this.showMessage(data?.Errors[key][0]?.message, 'error');
		});
	}
}

export const ngAuxiliarFactory = {
	name: Auxiliar.name,
	def: downgradeInjectable(Auxiliar),
};
