import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
	selector: '[appOnlyNumbers]',
})
export class OnlyNumbersDirective {
	private specialKeys = [
		'Backspace',
		'Delete',
		'Tab',
		'Enter',
		'Escape',
		'Home',
		'End',
		'ArrowLeft',
		'ArrowRight',
		'Clear',
		'Copy',
		'Paste',
	];
	inputElement: HTMLElement;

	constructor(public el: ElementRef) {
		this.inputElement = el.nativeElement;
	}

	// Ao pressionar teclas que produzem ou não produzem um caractere
	@HostListener('keydown', ['$event'])
	onKeyDown(e: KeyboardEvent) {
		if (
			this.specialKeys.indexOf(e.key) > -1 || // Permite specialKeys: backspace, delete, arrows etc.
			(e.key === 'a' && (e.ctrlKey || e.metaKey)) || // Permite: Ctrl+A ou Cmd+A (Mac)
			(e.key === 'c' && (e.ctrlKey || e.metaKey)) || // Permite: Ctrl+C ou Cmd+C (Mac)
			(e.key === 'v' && (e.ctrlKey || e.metaKey)) || // Permite: Ctrl+V ou Cmd+V (Mac)
			(e.key === 'x' && (e.ctrlKey || e.metaKey)) // Permite: Ctrl+X ou Cmd+X (Mac)
		) {
			// Ok
			return;
		}
		// Verificando se a tecla não é um número
		if (
			(e.shiftKey || e.key < '0' || e.key > '9') &&
			(e.key < '0' || e.key > '9')
		) {
			// Cancela o pressionamento da tecla
			e.preventDefault();
		}
	}

	// Ao pressionar teclas que produzem um caractere
	@HostListener('keypress', ['$event'])
	onKeyPress(e: KeyboardEvent) {
		// Bloqueia caracteres como: !@#$%¨&*()/
		const charCode = e.which ? e.which : e.keyCode;
		if (
			(e.shiftKey || charCode < 48 || charCode > 57) &&
			(charCode < 96 || charCode > 105)
		) {
			// Cancela o pressionamento da tecla
			e.preventDefault();
		}
	}

	// Ao colar valor
	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		event.preventDefault();
		/*eslint-disable-line */
		if (!event.clipboardData) return;

		const pastedInput: string = event.clipboardData
			.getData('text/plain')
			.replace(/\D/g, '');
		navigator.clipboard.writeText(pastedInput); // Remove caracteres não numéricos
	}

	// Ao arrastar valor
	@HostListener('drop', ['$event'])
	onDrop(event: DragEvent) {
		event.preventDefault();
		const textData = event.dataTransfer?.getData('text').replace(/\D/g, '');

		this.inputElement.focus();
		navigator.clipboard.writeText(textData || '');
	}
}
