import { Component, Inject, Input } from '@angular/core';
import { Params } from '@angular/router';
import { downgradeComponent } from '@angular/upgrade/static';
import * as moment from 'moment';
import {
	chartStyle,
	seriesConsumption,
} from 'ng-app/directives/consumption-chart/consumption-chart.util';
import * as tinycolor from 'tinycolor2';
import { AUXILIAR } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { ApiParams } from '../../../shared/models/params.model';
import {
	ConsumptionChartService,
	IConsumptionComponent,
} from './consumption-chart.service';
import * as Highcharts from 'highcharts';

@Component({
	selector: 'app-consumption-chart',
	templateUrl: './consumption-chart.component.html',
	styleUrls: ['./consumption-chart.component.scss'],
})
export class ConsumptionChartComponent {
	@Input() params: Params | undefined = {
		dataSourceId: 2805,
		endDate: '2021-06-30',
		meterId: 2805,
		resolution: 'days',
		startDate: '2021-05-31',
	};

	Highcharts: typeof Highcharts = Highcharts;
	chartOptions = {};

	disableForecastFit = false;
	remainingDays: number | undefined;
	latestInstallationDate: any;

	sourceObject;
	viewSelected;
	viewBalance;
	showSelectView;
	viewTotal;

	isFreeMarketSharing;
	chartSelected;
	period;

	chart;

	constructor(
		private service: ConsumptionChartService,
		@Inject(AUXILIAR) private auxiliar: any
	) {}

	async start(cmp: IConsumptionComponent, params: Partial<ApiParams>) {
		const { sourceType } = cmp;
		this.disableForecastFit = await this.service.isInstallationLateEnough(cmp);
		if (this.disableForecastFit) {
			this.remainingDays =
				90 - moment().diff(this.latestInstallationDate, 'days');
		}
		const compositions = this.sourceObject.contracts;
		this.isFreeMarketSharing = Array.isArray(compositions)
			? compositions.length > 1 &&
			  compositions.find(
			  	(contract) => contract.type.type == 'freeMarketSharing'
			  ) != undefined
			: false;

		if (sourceType == 'group') {
			if (this.sourceObject.bidirectional == true) {
				this.viewSelected = this.viewBalance;
				this.showSelectView = false;
			} else {
				this.viewSelected = this.viewTotal;
				this.showSelectView = true;
			}
		}

		this.chartSelected =
			this.chartSelected == null ? this.viewSelected : this.chartSelected;
		if (params.period == 'hour' || params.period == 'year') {
			this.chartSelected = this.viewSelected;
		}
		const [consumptions] = (await this.service.start(cmp, params)) || [];
		this.drawChart(consumptions, cmp, params);

		this.period = params.period;
	}

	drawChart(consumptions, cmp, params: Partial<ApiParams>) {
		this.drawConsumptionChart(consumptions || [], cmp, params);
	}

	async drawConsumptionChart(consumptions, cmp, params: Partial<ApiParams>) {
		this.viewSelected =
			this.sourceObject.bidirectional == true
				? this.viewBalance
				: this.viewTotal;
		const consumptionSeriesAttribute =
			this.viewSelected == this.viewTotal ? 'activeEnergy' : 'consumedEnergy';

		const data = this.auxiliar.buildDateSeries(
			params.startDate,
			params.endDate,
			params.resolution,
			consumptions,
			consumptionSeriesAttribute,
			cmp.timezone,
			this.sourceObject.inspectionDay
		);

		//TODO REFACTOR
		const items = data.newItems;
		const labels = data.labels;
		let values = data.values;

		const resolution = params.resolution;
		const timezone = cmp.timezone;
		const intermediatePeakValues: any[] = [];
		const peakValues: any = [];

		const injectedData = this.auxiliar.buildDateSeries(
			params.startDate,
			params.endDate,
			params.resolution,
			consumptions,
			'injectedEnergy',
			cmp.timezone
		);

		const injectedValues =
			this.viewSelected == this.viewTotal ? [] : injectedData.values;

		const mainSerieType = 'column';
		const newValues: any[] = [];
		let forecastCount = 0;
		for (let i = 0; i < values.length; i++) {
			let value = values[i];
			const item = items[i];
			//filter negative values
			if (value < 0 && value > -100) {
				value = 0;
			}

			if (item.forecast) {
				newValues.push({
					color: tinycolor('#AEAEAE')
						.brighten(1.0 * forecastCount)
						.toString(),
					y: value.y,
				});
				forecastCount++;
			}

			if (item.offPeak) {
				newValues.push({
					color: 'var(--chartColor)',
					y:
						item.offPeak[consumptionSeriesAttribute] == 0
							? null
							: item.offPeak[consumptionSeriesAttribute],
				});
			} else {
				newValues.push({
					color: 'var(--chartColor)',
					y: null,
				});
			}

			if (item.intermediatePeak) {
				intermediatePeakValues.push({
					color: 'var(--secondary-color)',
					y:
						item.intermediatePeak[consumptionSeriesAttribute] == 0
							? null
							: item.intermediatePeak[consumptionSeriesAttribute],
				});
			} else {
				intermediatePeakValues.push({
					color: 'var(--secondary-color)',
					y: null,
				});
			}

			if (item.intermediatePeak) {
				intermediatePeakValues.push({
					color: 'var(--secondary-color)',
					y:
						item.intermediatePeak[consumptionSeriesAttribute] == 0
							? null
							: item.intermediatePeak[consumptionSeriesAttribute],
				});
			} else {
				intermediatePeakValues.push({
					color: 'var(--secondary-color)',
					y: null,
				});
			}

			if (item.peak) {
				peakValues.push({
					color: 'var(--chartColordark)',
					y:
						item.peak[consumptionSeriesAttribute] == 0
							? null
							: item.peak[consumptionSeriesAttribute],
				});
			} else {
				peakValues.push({
					color: 'var(--chartColordark)',
					y: null,
				});
			}
		}
		values = newValues;

		const marginTop = this.viewSelected == this.viewBalance ? 45 : 45;
		const tickAmount = this.viewSelected == this.viewBalance ? undefined : 3;

		const consumptionSeriesData = [];

		if (params.period == 'year') {
			// consumptionSeriesData = await this.drawContractedLines(
			//   consumptions || [],
			//   consumptionSeriesData,
			//   labels
			// );
		} else {
			seriesConsumption({
				mainSerieType,
				values,
				intermediatePeakValues,
				peakValues,
				injectedValues,
				injectedData,
			});
		}

		this.chart = chartStyle({
			marginTop,
			resolution,
			timezone,
			vm: this,
			items,
			labels,
			tickAmount,
			injectedValues,
			consumptionSeriesData,
		});
	}
}

export const ng2ConsumptionChart = {
	name: 'appConsumptionChart',
	def: downgradeComponent({ component: ConsumptionChartComponent }),
};
