<div class="content">
	<div class="description">
		<div class="description__texts">
			<span class="description__texts__title">{{
				"consuming-units-home.consuming-units" | transloco
			}}</span>
			<span class="description__texts__subtitle">{{
				"consuming-units-home.desc" | transloco
			}}</span>
		</div>

		<div class="btn-toolbar description__action-buttons" role="toolbar">
			<button
				class="btn btn-color-ga"
				type="button"
				(click)="
					mixPanelEvent('click_button_consuming_units_create');
					stateGo('main.uc', { id: null })
				"
				*wchHasFeature="'energyBillCreate'"
			>
				<i class="fa-regular fa-store"></i>
				{{ "consuming-unit-list.new" | transloco }}
			</button>
		</div>
	</div>

	<div class="row col-lg-12 m-0 p-0">
		<autocomplete-orgs
			[idSelected]="organizationId"
			(orgEmitter)="orgSelected($event)"
		>
		</autocomplete-orgs>
	</div>

	<div class="row col-lg-12 m-0 p-0 dataTable">
		<div *ngIf="!organizationId" class="no-organization">
			<ga-tag-icon [icon]="'error'" [size]="22"></ga-tag-icon>
			<span class="message">
				{{ "consuming-unit-list.table.no-organization" | transloco }}
			</span>
		</div>
		<ga-datatable-new
			[ngClass]="{ hidden: !organizationId }"
			[tableName]="'Consuming_units'"
			[element$]="displayEl$"
			(onAction)="onActionCliked($event)"
		>
			<ng-container emptyTitle>
				<h3 class="empty-table">
					{{ "consuming-unit-list.table.empty-table" | transloco }}
				</h3>
			</ng-container>
			<ng-container emptySubtitle>
				<h4 class="empty-table__tips">
					<span>
						{{ "consuming-unit-list.table.empty-table-msg-1" | transloco }}
						<a (click)="stateGo('main.uc', { id: null })">{{
							"consuming-unit-list.table.empty-table-msg-2" | transloco
						}}</a></span
					>
					<span>
						{{
							"consuming-unit-list.table.empty-table-msg-3" | transloco
						}}</span
					>
				</h4>
			</ng-container>
		</ga-datatable-new>
	</div>
</div>
