import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { StateService } from 'angular-ui-router';
import { Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AutocompleteOrgsComponent } from 'webapp/app/shared/autocomplete-components/organizations/autocomplete-orgs.component';
import {
	GaContentDialogComponent,
	GaContentDialogModel,
} from 'webapp/app/shared/ga-dialog/ga-content-dialog.component';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { MixPanelService } from 'webapp/app/shared/services/mixpanel.service';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { ProinfaListService } from './proinfa-list.service';
import { DataTableList } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';

@Component({
	selector: 'proinfa-list',
	templateUrl: './proinfa-list.component.html',
	styleUrls: ['./proinfa-list.component.scss'],
})
export class ProinfaListComponent implements OnDestroy {
	organizationId: number | undefined = undefined;
	subscriptions: Array<Subscription> = [];
	displayEl$: Observable<any> = new Observable();
	isOrgAdmin = false;
	dialogRef!: MatDialogRef<any>;
	tariffs: any[] = [];
	selectedRow: any = null;
	@ViewChild(AutocompleteOrgsComponent)
		organizationsComponent!: AutocompleteOrgsComponent;

	constructor(
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(TranslocoService) private i18n: TranslocoService,
		public dialog: MatDialog,
		private mixpanel: MixPanelService,
		private notification: NotificationService,
		private proinfaListService: ProinfaListService
	) {}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
	}

	mixPanelEvent(type) {
		this.mixpanel.mixPanelEvent({ type, object: {} });
	}

	newAction(event) {
		const route = event ? 'main.proinfaDetails' : 'main.proinfaNew';
		const params: any = {
			id: event?.row?.tariff || null,
			instanceId: event?.row?.id || null,
			tab: this.$state.params.tab,
			clone: event?.type == 'clone',
			'#': event?.type == 'edit' && event?.id == event?.row?.id ? 'edit-cycle' : event?.type,
			organizationId: this.organizationId,
		};
		this.stateGo(route, params);
	}

	newCicle(event) {
		const route = 'main.proinfaNewCicle';
		const params: any = {
			id: event.id,
			tab: this.$state.params.tab,
			'#': event.type,
		};
		this.stateGo(route, params);
	}

	stateGo(route, params) {
		this.$state.transitionTo(route, params);
	}

	orgSelected(event: { id: number; name: string }) {
		if (!event?.id || event?.id === this.organizationId) return;
		this.organizationId = event?.id;
		this.isOrgAdmin = this.organizationsComponent.isOrgAdmin || this.organizationsComponent.currentUser.isAdmin;
		this.listProinfas();
	}

	listProinfas() {
		const params: any = { subType: 'incentive' };
		if (this.organizationId) params.organizationId = this.organizationId;

		this.displayEl$ = this.proinfaListService.getTariffs(params).pipe(
			map((data) => {
				const element: DataTableList = {} as DataTableList;
				this.tariffs = data.tariffs;
				element.data = this.proinfaListService.renderListToTable(data.tariffs);
				element.column = this.proinfaListService.columns;
				element.badge = {
					singular: this.i18n.translate('proinfa-list.table.badge'),
					plural: this.i18n.translate('proinfa-list.table.badges'),
				};
				element.sub = this.proinfaListService.subColumns;

				return element;
			})
		);
	}

	onRowExpand(event) {
		this.selectedRow = event;
		if (!event || event.subrows.length) return;
		const subscription = this.proinfaListService.renderSubRows(event).subscribe((subrows) => {
			event.subrows = subrows;
		});
		this.subscriptions.push(subscription);
	}

	doAction(event) {
		const dialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		switch (event.type) {
		case 'delete':
			dialogModel.icon = 'error';
			dialogModel.iconType = 'error';
			dialogModel.title = this.i18n.translate('global.modal.delete-title');
			dialogModel.message = this.i18n.translate('global.modal.delete-message');
			dialogModel.btnError = true;
			break;
		case 'new-cycle':
			this.newCicle(event);
			return;
		default:
			this.newAction(event);
			return;
		}
		this.dialogRef = this.dialog.open(GaContentDialogComponent, {
			disableClose: true,
			data: dialogModel,
			width: '407px',
		});

		this.subscriptions.push(
			this.dialogRef.componentInstance.confirm
				.pipe(
					tap(() => {
						this.onDelete(
							event.row,
							event.id === event.row.tariff || (this.selectedRow && this.selectedRow.subrows.length == 1)
						);
					})
				)
				.subscribe()
		);
	}

	onDelete(element, isTariff = false) {
		const subscription = this.proinfaListService[isTariff ? 'deleteTariff' : 'deleteTariffInstance'](
			isTariff ? { id: element.tariff } : element
		)
			.pipe(
				tap(() => {
					this.notification.success({
						msg: isTariff
							? this.i18n.translate('proinfa-list.delete-success')
							: this.i18n.translate('proinfa-list.delete-instance-success'),
					});
					this.listProinfas();
					this.closeDialog();
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					this.closeDialog();
					return error;
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	closeDialog() {
		this.dialogRef.close();
	}
}

export const ng2ProinfaListComponent = {
	name: 'proinfaList',
	def: downgradeComponent({
		component: ProinfaListComponent,
		propagateDigest: true,
	}),
};
