import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { SourceSelectorComponent } from './source-selector.component';

@NgModule({
	declarations: [SourceSelectorComponent],
	exports: [SourceSelectorComponent],
	imports: [CommonModule, TranslocoModule, ReactiveFormsModule],
})
export class SourceSelectorModule {}
