import { AngularEditorConfig } from '@kolkov/angular-editor';

export interface EditorConfig {
	showToolbar?: boolean;
	minHeight?: string;
	maxHeight?: string;
	toolbarButtons: string[];
}

export const initialEditorConfig: AngularEditorConfig = {
	editable: true,
	spellcheck: true,
	height: 'auto',
	minHeight: '100px',
	maxHeight: 'auto',
	width: 'auto',
	minWidth: '0',
	translate: 'yes',
	enableToolbar: true,
	showToolbar: true,
	defaultParagraphSeparator: '',
	defaultFontName: 'Roboto',
	defaultFontSize: '3',
	fonts: [
		{ class: 'Roboto', name: 'Roboto' },
		{ class: 'arial', name: 'Arial' },
		{ class: 'times-new-roman', name: 'Times New Roman' },
		{ class: 'calibri', name: 'Calibri' },
		{ class: 'comic-sans-ms', name: 'Comic Sans MS' },
	],
	customClasses: [
		{
			name: 'quote',
			class: 'quote',
		},
		{
			name: 'redText',
			class: 'redText',
		},
		{
			name: 'titleText',
			class: 'titleText',
			tag: 'h1',
		},
	],
	sanitize: true,
	toolbarPosition: 'top',
	toolbarHiddenButtons: [
		[
			'undo',
			'redo',
			'bold',
			'italic',
			'underline',
			'strikeThrough',
			'subscript',
			'superscript',
			'justifyLeft',
			'justifyCenter',
			'justifyRight',
			'justifyFull',
			'indent',
			'outdent',
			'insertUnorderedList',
			'insertOrderedList',
			'heading',
			'fontName',
			'fontSize',
			'textColor',
			'backgroundColor',
			'customClasses',
			'link',
			'unlink',
			'insertImage',
			'insertVideo',
			'insertHorizontalRule',
			'removeFormat',
			'toggleEditorMode',
		],
	],
};
