import { Component, Input, OnInit } from '@angular/core';
import { ColumnType } from '../model/datatable.model';

@Component({
	selector: 'active-cell',
	styles: [
		`
			.ml-1 {
				margin-left: 6px;
			}
			.items-center {
				display: flex;
				flex-direction: row;
				align-items: center;
			}
			@media (max-width: 1170px) {
				.items-center {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			}
		`,
	],
	template: `
		<div class="items-center">
			<ga-helper
				[text]="row[column.name].text"
				[tooltipLocale]="'above'"
				[iconColor]="row[column.name].status ? '#33CC99' : '#D73A3A'"
				[icon]="
					row[column.name].status
						? 'fa-solid fa-circle-check'
						: 'fa-solid fa-circle-x'
				"
			></ga-helper>
			<span class="ml-1">{{
				row[column.name].status
					? (activeLabel | transloco)
					: (inactiveLabel | transloco)
			}}</span>
		</div>
	`,
})
export class ActiveCellComponent implements OnInit {
	@Input() column!: ColumnType;
	@Input() row: any;
	activeLabel = 'data-table.active';
	inactiveLabel = 'data-table.inactive';

	ngOnInit(): void {
		this.activeLabel = this.column.activeLabel ?? this.activeLabel;
		this.inactiveLabel = this.column.inactiveLabel ?? this.inactiveLabel;
	}
}
