import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
	AbstractControl,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from 'webapp/app/shared/services/user.service';
import { SnackService } from 'webapp/app/shared/snackbar/snackbar.service';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { setupDowngradeName } from 'webapp/hybrid-helpers/setupDowngradeName';
import { NewPasswordComponent } from '../new-password/new-password.component';
@Component({
	selector: 'app-invite',
	templateUrl: './invite.component.html',
	styleUrls: ['./invite.component.scss', '../login/login.component.scss'],
	host: {
		class: 'login',
	},
	encapsulation: ViewEncapsulation.None,
})
export class InviteComponent implements OnInit {
	readonly currentDate = new Date();
	readonly splashPages = [
		'/assets/images/green_analitycs.png',
		'/assets/images/green_contratos.png',
	];
	readonly bgColors = ['#cccc99', '#7affbf'];

	form = new FormGroup(
		{
			firstName: new FormControl('', { validators: [Validators.required] }),
			lastName: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.required, Validators.email]),
			password: new FormControl('', [
				Validators.required,
				Validators.minLength(6),
			]),
			passwordConfirmation: new FormControl('', [Validators.required]),
			acceptTerms: new FormControl('', [
				Validators.required,
				Validators.requiredTrue,
			]),
			token: new FormControl('', { validators: [Validators.required] }),
		},
		{ validators: NewPasswordComponent.equalTo }
	);

	splashPageURL: any;
	message: { error: boolean; msg: string } | undefined = undefined;

	constructor(
		@Inject(NG_ROUTER) private $state,
		@Inject(NG_STATE_PARAMS) private $stateParams,
		private i18n: TranslocoService,
		private userService: UserService,
		private sanitize: DomSanitizer,
		private snackService: SnackService
	) {}

	ngOnInit(): void {
		const rand = Math.floor(Math.random() * this.splashPages.length);
		this.splashPageURL = this.sanitize.bypassSecurityTrustResourceUrl(
			this.splashPages[rand]
		);
		$('.embed-responsive.embed-responsive-image')
			.eq(0)
			.css('background-color', this.bgColors[rand]);
		this.loadUserInfo();
	}

	signup({ valid, value }: AbstractControl) {
		if (!valid) {
			this.message = { error: true, msg: this.getValidationErrors() };
			return;
		}
		this.activeUser(value);
	}

	private activeUser(user) {
		this.userService
			.activeInvite(user)
			.then(() => {
				const success = this.i18n.translate('signup.done-message.msg');
				this.snackService.showSnackBar(success, 'success');
				this.message = { error: false, msg: success };
				this.$state.go('login');
			})
			.catch((error) => {
				const errMsg =
					error?.Errors[Object.keys(error?.Errors)[0]][0].message ||
					error?.error ||
					error;
				this.snackService.showSnackBar(errMsg, 'danger');
				this.message = { error: true, msg: errMsg };
			});
	}

	private loadUserInfo() {
		this.userService.getUsersByInvite(this.$stateParams.token).then((user) => {
			if (user) {
				this.form.setValue({
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
					password: null,
					passwordConfirmation: null,
					token: this.$stateParams.token,
					acceptTerms: null,
				});
				this.form.controls['email'].disable();
			}
		});
	}

	private getValidationErrors(): string {
		if (this.form.get('firstName')?.errors)
			return this.i18n.translate('signup.validation.name');
		if (this.form.get('lastName')?.errors)
			return this.i18n.translate('signup.validation.lastName');
		if (this.form.get('email')?.errors)
			return this.i18n.translate('signup.validation.email');
		if (this.form.get('password')?.errors)
			return this.i18n.translate('signup.validation.password');
		if (this.form.hasError('passwordNotCompare'))
			return this.i18n.translate('signup.validation.passwordConfirmation');
		if (this.form.get('acceptTerms')?.errors)
			return this.i18n.translate('signup.validation.terms');
		return this.i18n.translate('signup.validation.mandatory');
	}
}

export const ng2InviteComponent = {
	name: setupDowngradeName(InviteComponent.name),
	def: downgradeComponent({ component: InviteComponent }),
};
