import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentContainerComponent } from './component-container.component';

@NgModule({
	declarations: [
		ComponentContainerComponent,
		//GlobalLoadingComponent
	],
	exports: [
		ComponentContainerComponent,
		//GlobalLoadingComponent
	],
	imports: [CommonModule],
})
export class ComponentContainerModule {}
