import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormFieldBaseDirective } from '../directive/form-field-base';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmIdentityComponent } from '../confirm-identity/confirm-identity.component';
import { Subscription } from 'rxjs';

@Component({
	selector: 'ga-access-password',
	templateUrl: './ga-access-password.component.html',
	styleUrls: ['./ga-access-password.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GaAccessPasswordComponent),
			multi: true,
		},
	],
})
export class GaAccessPasswordComponent
	extends FormFieldBaseDirective
	implements OnDestroy
{
	@Input() rAddon = '';
	@Input() lAddon = '';
	@Input() maxLength: number | undefined;
	@Input() minLength: number | undefined;
	@Input() min = 0;
	@Input() max: number | undefined;
	@Input() step: number | undefined;
	@Input() mask = '';
	@Input() accessId: number | undefined;
	@Output() accessPassword = new EventEmitter();
	subscription: Subscription | undefined;

	hidePassword = true;
	hasPassword = false;

	constructor(private dialog: MatDialog) {
		super();
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	get inputType() {
		if (this.hidePassword === true) return 'password';

		return 'text';
	}

	changePasswordVisibility() {
		if (this.hasPassword) {
			this.hidePassword = !this.hidePassword;
		} else {
			const dialogRef = this.dialog.open(ConfirmIdentityComponent, {
				width: '385px',
				autoFocus: false,
			});

			const instance = dialogRef.componentInstance;
			instance.accessId = this.accessId;

			this.subscription = dialogRef.afterClosed().subscribe((result) => {
				if (result) {
					this.accessPassword.emit(result);
					this.hidePassword = !this.hidePassword;
					this.hasPassword = !this.hasPassword;
				}
			});
		}
	}
}
