import * as angular from 'angular';
import ngAnimate from 'angular-animate';
import * as ngCookies from 'angular-cookies';
import ngRoute from 'angular-route';
import uiBootstrap from 'angular-ui-bootstrap';
import { Transition } from 'angular-ui-router';
import { ng2AuthService } from 'webapp/app/auth/auth.service';
import { ng2ForgotComponent } from 'webapp/app/auth/forgot/forgot.component';
import { ng2InviteComponent } from 'webapp/app/auth/invite/invite.component';
import { loginCmp } from 'webapp/app/auth/login/login.component';
import { ng2LogoutComponent } from 'webapp/app/auth/logout.component';
import { ng2NewPassowordComponent } from 'webapp/app/auth/new-password/new-password.component';
import datePicker from '../../vendors/angular-datepicker';
import angularTranslate from '../../vendors/angular-translate';
import ngTagsInput from '../../vendors/ng-tags-input';
import { ngRedirectControler } from './redirect.controller';
import { ngSignupController } from './signup.controller';

const AuthModule = angular.module('AuthModule', [
    angularTranslate,
    ngCookies,
    ngAnimate,
    uiBootstrap,
    ngRoute,
    ngTagsInput,
    datePicker
]);

AuthModule.directive(loginCmp.name, loginCmp.def);
AuthModule.directive(ng2LogoutComponent.name, ng2LogoutComponent.def);
AuthModule.directive(ng2NewPassowordComponent.name, ng2NewPassowordComponent.def);
AuthModule.controller(ngRedirectControler.name, ngRedirectControler.def);
AuthModule.controller(ngSignupController.name, ngSignupController.def);
AuthModule.directive(ng2InviteComponent.name, ng2InviteComponent.def);
AuthModule.directive(ng2ForgotComponent.name, ng2ForgotComponent.def);

//Routes guard
AuthModule.run(['$transitions', function ($transitions: Transition) {
    const notRequiredAuthStates = [
        'login',
        'signup',
        'invite',
        'forgot',
        'redirect',
        'new-password'
    ]

    const match = {
        to: function (state) {
            return notRequiredAuthStates.find(safeState => {
                return state.name == safeState
            }) == undefined;
        }
    }

    $transitions.onBefore(match, function (trans) {
        let authService = trans.injector().get(ng2AuthService.name);
        // If isAuthenticated returns false, the transition  goes to login.
        return authService.isAuthenticated() ? true : trans.router.stateService.target('login');
    });
}]);

export default AuthModule.name