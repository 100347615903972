import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'webapp/environments/environment';
import { KPIVariableType } from '../models/kpi-variable-type.model';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import pt_BR from '../../../../../ng-app/src/app/_l10n/pt_BR.json';

@Injectable({ providedIn: 'root' })
export class KpiService {
	private allMetrics: any = [];
	constructor(private http: HttpClient, private i18n: TranslocoService) {}

	getKpis(params) {
		return this.http
			.get(`${environment.backendUrl}/api/kpis`, { params: params })
			.toPromise()
			.then((res) => res)
			.catch((error) => {
				return new Error(error);
			});
	}

	getKpi(id: number) {
		return this.http.get(`${environment.backendUrl}/api/kpis/${id}`);
	}

	getAllKpiPoints(params) {
		return this.http
			.get(`${environment.backendUrl}/api/kpis/kpi-points`, { params: params })
			.toPromise()
			.then((res) => res)
			.catch((error) => {
				return new Error(error);
			});
	}

	getKpiPoints(kpiId, params) {
		return this.http
			.get(`${environment.backendUrl}/api/kpis/${kpiId}/kpi-points`, {
				params: params,
			})
			.toPromise()
			.then((res) => res)
			.catch((error) => {
				return new Error(error);
			});
	}

	getKpiPointsByDataSource(params) {
		return this.http
			.get(
				`${environment.backendUrl}/api/kpis/${params.kpiId}/datasource-kpis/${params.datasourceId}/kpi-points`,
				{ params: params }
			)
			.toPromise()
			.then((res) => res)
			.catch((error) => {
				return new Error(error);
			});
	}

	getKpiPoint(params) {
		return this.http
			.get(
				`${environment.backendUrl}/api/kpis/${params.kpi}/kpi-points/${params.id}`,
				{
					params: params,
				}
			)
			.toPromise()
			.then((res) => res)
			.catch((error) => {
				return new Error(error);
			});
	}

	getOperationTypes(params) {
		return this.http
			.get(`${environment.backendUrl}/api/kpis/operations`, { params: params })
			.toPromise()
			.then((res) => res)
			.catch((error) => {
				return new Error(error);
			});
	}

	createKpi(params) {
		return this.http.post(`${environment.backendUrl}/api/kpis`, params);
	}

	updateKpi(id: number, body: any) {
		return this.http.put(`${environment.backendUrl}/api/kpis/${id}`, body);
	}

	deleteKpi(params) {
		return this.http
			.delete(`${environment.backendUrl}/api/kpis/${params.id}`)
			.toPromise()
			.then((res) => res)
			.catch((error) => {
				return new Error(error);
			});
	}

	createKpiPoint(params) {
		return this.http
			.post(
				`${environment.backendUrl}/api/kpis/${params.kpiId}/datasource-kpis/${params.dataSourceKPIId}/kpi-points`,
				params
			)
			.toPromise()
			.then((res: any) => {
				return res;
			})
			.catch((error) => {
				return new Error(error);
			});
	}

	updateKpiPoint(params) {
		return this.http
			.put(
				`${environment.backendUrl}/api/kpis/${params.kpiId}/datasource-kpis/${params.dataSourceKPIId}/kpi-points/${params.id}`,
				params
			)
			.toPromise()
			.then((res) => res)
			.catch((error) => {
				return new Error(error);
			});
	}

	deleteKpiPoint(params) {
		return this.http
			.delete(
				`${environment.backendUrl}/api/kpis/${params.kpiId}/kpi-points/${params.id}`,
				{
					params: params,
				}
			)
			.toPromise()
			.catch((error) => {
				return new Error(error);
			});
	}

	/* DEPRECATED */
	async getConstants(organization?: number) {
		const params = {
			...(organization && { organization }),
		};
		const variables = await this.http
			.get<KPIVariableType[]>(`${environment.backendUrl}/api/kpis/variables`, {
				params,
			})
			.toPromise();

		return {
			dividend: variables?.find((v) => v.dividend) || {},
			divisor: variables?.filter((v) => !v.dividend) || [],
		};
	}

	$getConstants(organization?: number): Observable<any> {
		const params = {
			...(organization && { organization }),
		};
		return this.http.get(`${environment.backendUrl}/api/kpis/variables`, {
			params,
		});
	}

	toMetricObj(id: number) {
		return this.allMetrics.find((m) => m.id == id);
	}

	set metrics(metrics) {
		this.allMetrics = metrics;
	}

	get metrics() {
		return this.allMetrics;
	}

	transformMetrics(metrics) {
		const obj = {
			dividend: metrics?.find((v) => v.dividend) || {},
			divisor: metrics?.filter((v) => !v.dividend) || [],
			id: null,
		};

		return obj;
	}

	buildMetric(dividend, divisor) {
		let metric = {} as any;
		const objDivisor = divisor ? divisor : dividend;

		const label = pt_BR.kpi.metrics[objDivisor.type]
			? this.i18n.translate('kpi.metrics.' + objDivisor.type)
			: objDivisor.label;
		const unit = `${dividend.unit}${
			divisor ? '/' + (divisor.unit ? divisor.unit : divisor.label) : ''
		}`;
		metric = {
			label,
			unit,
			organization: divisor ? divisor.organization : null,
			id: divisor?.id ?? dividend.id,
		};
		return metric;
	}
}

export const ng2KpiService = {
	name: KpiService.name,
	def: downgradeInjectable(KpiService),
};
