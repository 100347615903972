import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormFieldBaseDirective } from '../directive/form-field-base';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EditorConfig, initialEditorConfig } from './ga-editor.model';
import { TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
	selector: 'ga-editor',
	templateUrl: './ga-editor.component.html',
	styleUrls: ['./ga-editor.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GaEditorComponent),
			multi: true,
		},
	],
})
export class GaEditorComponent
	extends FormFieldBaseDirective
	implements OnInit, OnDestroy
{
	@Input() maxLength: number | undefined;
	@Input() minLength: number | undefined;
	@Input() min = 0;
	@Input() max: number | undefined;
	@Input() step: number | undefined;
	@Input() editorConfig: EditorConfig = {} as EditorConfig;
	configs: AngularEditorConfig = {};
	editorCtrl = new FormControl('');
	initialEditorConfig: AngularEditorConfig = initialEditorConfig;
	subscription!: Subscription;

	constructor(private i18n: TranslocoService) {
		super();
	}

	ngOnInit(): void {
		this.loadConfigs();
		this.loadTooltipsTranslate();

		this.subscription = this.valSubject
			.pipe(tap((value: string) => this.editorCtrl.setValue(value)))
			.subscribe();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	loadTooltipsTranslate() {
		const arr = this.initialEditorConfig.toolbarHiddenButtons
			? this.initialEditorConfig.toolbarHiddenButtons[0]
			: [];
		setTimeout(() => {
			arr.forEach((key) => {
				const el = document.querySelector(`[id^="${key}"]`);
				if (el) {
					el.setAttribute('title', this.i18n.translate(`editor.${key}`));
					const firstChild = el.firstElementChild;
					firstChild?.classList.remove('fa');
					firstChild?.classList.add('fa-solid');
				}
			});
		}, 0);
	}

	loadConfigs() {
		const toolbarHidden = this.initialEditorConfig.toolbarHiddenButtons
			? this.initialEditorConfig.toolbarHiddenButtons[0]
			: [];
		this.configs = {
			...this.initialEditorConfig,
			toolbarHiddenButtons: [
				toolbarHidden.filter(
					(button) => !this.editorConfig.toolbarButtons.includes(button)
				),
			],
		};

		Object.keys(this.editorConfig).forEach((key) => {
			if (
				Object.prototype.hasOwnProperty.call(this.editorConfig, key) &&
				key !== 'toolbarButtons'
			)
				this.configs[key] = this.editorConfig[key];
		});
	}
}
