// <!--directives-->
//import "webapp/app/shared/header-search/source-select/source-selector/source-selector.directive";
//import "./app/directives/calendar-navigator/calendar-navigator.directive";
//import "webapp/app/shared/component-container/component-container.directive";
//import "./app/directives/common/abstract-chart.component";
//import "./app/directives/consumption-chart/consumption.abstract";
//import "./app/directives/consumption-chart/consumption-chart.directive";
//import "./app/directives/consumption-chart/consumption-generation-chart.directive";
//import "./app/directives/goal-chart/goal-chart.directive";
//import "./app/directives/power-factor-chart/power-factor-chart.directive";
//import "./app/directives/power-demand-chart/power-demand-chart.directive";
//import "./app/directives/has-permission/has-permission.directive";
//import "./app/directives/realtime/abstract-realtime.component";
//import "./app/directives/realtime/realtime.directive";
import "./app/directives/realtime/realtime-advanced.directive";
//import "./app/directives/expense/expense.directive";
import "./app/directives/modal-delete/modal-delete";
import "./app/directives/form-builder/form-builder.directive";
import "./app/directives/date-input/date-input.directive";
import "./app/directives/meter-modals/meter-close-cycle.directive";
import "./app/directives/comparative-chart/comparative-chart.directive";
import "./app/directives/tariff-selector/tariff-selector.component";
//import "./app/directives/composition-selector/composition-selector.component";
import "./app/directives/item-selector/item-selector.component";
import "./app/directives/file-uploader/file-uploader.directive";