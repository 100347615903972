import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'root' })
export class CategoryService {
	constructor(private http: HttpClient) {}

	findAll(params) {
		return this.http
			.get(`${environment.backendUrl}/api/category`, { params: params })
			.toPromise();
	}

	applianceType(params) {
		return this.http
			.get(`${environment.backendUrl}/api/applianceType`, { params: params })
			.toPromise();
	}

	save(params) {
		if (params.id) {
			return this.http
				.post(`${environment.backendUrl}/api/category/` + params.id, params)
				.toPromise();
		} else {
			return this.http
				.post(`${environment.backendUrl}/api/category`, { params: params })
				.toPromise();
		}
	}

	remove(params) {
		if (params.id) {
			return this.http
				.delete(`${environment.backendUrl}/api/category/` + params.id, {
					params: params,
				})
				.toPromise();
		}
	}

	enableDetection(params) {
		if (params.uid) {
			return this.http
				.get(`${environment.backendUrl}/api/detection/` + params.uid)
				.toPromise();
		}
	}

	saveDetection(params) {
		if (params.categoryId) {
			return this.http
				.post(
					`${environment.backendUrl}/api/category/` +
						params.categoryId +
						'/detection',
					params
				)
				.toPromise();
		}
	}
}

export const ng2CategoryService = {
	name: CategoryService.name,
	def: downgradeInjectable(CategoryService),
};
