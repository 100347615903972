import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ConfirmDialogComponent {
	title: string;
	message: string;
	btnSucess: string;
	btnDanger: string;

	constructor(
		public dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
	) {
		// Update view with given values
		this.title = data.title;
		this.message = data.message;
		this.btnSucess = data.btnSucess || 'Deletar';
		this.btnDanger = data.btnDanger || 'Cancelar';
	}

	onConfirm(): void {
		// Close the dialog, return true
		this.dialogRef.close(true);
	}

	onDismiss(): void {
		// Close the dialog, return false
		this.dialogRef.close(false);
	}
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export interface ConfirmDialogModel {
	title: string;
	message: string;
	btnSucess: string;
	btnDanger: string;
}
