import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { OrganizationListService } from './organization-list.service';
import { OrganizationService } from 'webapp/app/shared/services/organization.service';
import { Observable } from 'rxjs';
import { DataTableList } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { map } from 'rxjs/operators';

@Component({
	selector: 'ng-organization-list',
	templateUrl: './organization-list.component.html',
	styleUrls: ['./organization-list.component.scss'],
})
export class OrganizationListComponent implements OnInit {
	organizationId!: number;
	displayTable$: Observable<DataTableList> = new Observable();

	constructor(
		private listService: OrganizationListService,
		private organizationService: OrganizationService,
		@Inject(NG_ROUTER) private $state: any
	) {}

	ngOnInit(): void {
		this.list();
	}

	orgSelected(organization: any) {
		this.organizationId = organization.id;
	}

	list() {
		const params = {
			hasDatasources: true,
			omitStyles: true,
		};
		this.displayTable$ = this.organizationService
			.$getOrganizations(params)
			.pipe(map((res) => this.listService.buildTable(res.organizations)));
	}

	toNew() {
		this.$state.transitionTo('main.organizations-new', { id: null });
	}

	toEdit(id: number) {
		this.$state.transitionTo('main.organizations-detail', { id });
	}

	inviteUser(organizationId: number) {
		this.$state.transitionTo('main.user-invite', { id: null, organizationId });
	}

	listUsers(organizationId: number) {
		this.$state.transitionTo(
			'registrations',
			{ tab: 'users', organizationId },
			{
				inherit: true,
				reload: true,
				notify: false,
			}
		);
	}

	getLazyColumns(event) {
		if (!event || !event.length) return;
		event.forEach((org) => {
			if (org.dataSources.length) return;
			org.dataSources = this.listService.renderLazyDataSources(org.id);
			if (org.users.length) return;
			org.users = this.listService.renderLazyUsers(org.id);
		});
	}

	onActionCliked($event: Action) {
		const type = $event.type;
		switch (type) {
			case 'edit':
				this.toEdit(<number>$event.id);
				break;
			case 'view-user':
				this.listUsers(<number>$event.id);
				break;
			case 'invite-user':
				this.inviteUser(<number>$event.id);
				break;
		}
	}
}

export const ng2OrganizationListComponent = {
	name: 'ngOrganizationList',
	def: downgradeComponent({
		component: OrganizationListComponent,
		propagateDigest: true,
	}),
};
