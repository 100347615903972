import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import DataFrame from 'dataframe-js';
import * as moment from 'moment';
import { environment } from 'webapp/environments/environment';
import { ContractService } from './contract.service';
import { GroupService } from './group.service';
import { MeasurementService } from './measurement.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DataSourceService {
	constructor(
		private httpClient: HttpClient,
		private groupService: GroupService,
		private measurementService: MeasurementService,
		private contractService: ContractService
	) {}

	/**
	 ** meterType - "consumption", "solar_generation"
	 **/
	getMeters(params: any = {}) {
		return this.httpClient
			.get(`${environment.backendUrl}/api/meters`, { params: params })
			.toPromise()
			.then((res: any) => {
				return res?.meters;
			});
	}
	getDataSource(id) {
		return this.httpClient
			.get(`${environment.backendUrl}/api/datasources/${id}`)
			.toPromise()
			.then((res: any) => {
				return res?.dataSource;
			});
	}

	$getDataSources(params: any = {}) {
		return this.httpClient
			.get<any>(`${environment.backendUrl}/api/datasources`, { params: params })
			.pipe(map((res) => res?.dataSources));
	}

	/**
	 ** meterType - "consumption", "solar_generation"
	 **/
	getDataSources(params: any = {}) {
		return this.httpClient
			.get(`${environment.backendUrl}/api/datasources`, { params: params })
			.toPromise()
			.then((res: any) => {
				return res?.dataSources;
			});
	}

	async getDataSourcesOfUser(userId) {
		return this.httpClient
			.get(`${environment.backendUrl}/api/users/${userId}/data-sources`)
			.toPromise()
			.then((res) => res);
	}

	/* Deprecated */
	async getUserDataSources(userId) {
		return this.httpClient
			.get(`${environment.backendUrl}/api/users/${userId}/user-data-sources`)
			.toPromise()
			.then((res: any) => res);
	}

	$getUserDataSources(userId) {
		return this.httpClient.get(
			`${environment.backendUrl}/api/users/${userId}/user-data-sources`
		);
	}

	getContracts(dataSourceId) {
		return this.httpClient
			.get(
				`${environment.backendUrl}/api/datasources/${dataSourceId}/contracts`
			)
			.toPromise()
			.then((res: any) => {
				return res?.contracts;
			});
	}
	getTariffInstances(datasourceId, params) {
		return this.httpClient
			.get(
				`${environment.backendUrl}/api/datasources/${datasourceId}/tariffInstances`,
				{ params: params }
			)
			.toPromise()
			.then((res: any) => {
				return res?.contracts;
			});
	}
	getMaxInspectionDay(dataSourceIds) {
		return this.httpClient
			.get(`${environment.backendUrl}/api/datasources/maxInspectionDay`, {
				params: { dataSourceIds: dataSourceIds },
			})
			.toPromise()
			.then((res: any) => {
				return res?.maxInspectionDay;
			});
	}

	async deleteDataSource(dataSourceId) {
		return await this.httpClient
			.delete(`${environment.backendUrl}/api/datasources/${dataSourceId}`)
			.toPromise();
	}

	async loadDataSourceTotal(dataSource, startDate, endDate, period, totalCB) {
		const params: any = {};

		if (period == 'year') {
			params.period = moment(endDate).format('YYYY');
			params.type = 'invoice';
		} else if (period != 'year' && startDate && endDate) {
			params.startDate = moment(startDate).format('YYYY-MM-DD');
			params.endDate = moment(endDate).format('YYYY-MM-DD');
		} else {
			params.type = 'invoice';
		}

		dataSource.noLoading = false;
		dataSource.invoice = null;
		dataSource.activeEnergy = null;
		//dataSource.formattedInvoicePeriod = `${moment(startDate).format("DD MMM")} - ${moment(endDate).format("DD MMM")}`;
		if (dataSource.type == 'group') {
			params.groupId = dataSource.id;
			this.groupService
				.groupTotal(params)
				.then((totalData) => totalCB(dataSource, totalData));
		} else {
			params.meterId = dataSource.id;
			this.measurementService
				.total(params)
				.then((totalData) => totalCB(dataSource, totalData));
		}
	}

	async dataSourceTotalComparative(params) {
		const dataSourceId = params.dataSourceId;
		return this.httpClient
			.get(
				`${environment.backendUrl}/api/datasources/${dataSourceId}/totals/comparative`,
				{ params: params }
			)
			.toPromise()
			.then((res) => {
				return res;
			});
	}

	async loadTotals(params) {
		const dataSourceId = params.dataSourceId;
		return this.httpClient
			.get(`${environment.backendUrl}/api/datasources/${dataSourceId}/totals`, {
				params: params,
			})
			.toPromise()
			.then((res) => {
				return res;
			});
	}

	loadTotalsRefact(params: any) {
		const dataSourceId = params.dataSourceId;
		return this.httpClient.get<any>(
			`${environment.backendUrl}/api/datasources/${dataSourceId}/totals`,
			{
				params: params,
			}
		);
	}

	getDataSourceSByListOfIds(params: any) {
		return this.httpClient.get<any[]>(
			`${environment.backendUrl}/api/datasources/totals/ids`,
			{
				params: params,
			}
		);
	}

	async loadDataSourceDemand(dataSource, startDate, endDate, period, demandCB) {
		const params: any = {
			period:
				period == 'year'
					? moment(endDate).format('YYYY')
					: moment(endDate).format('YYYY-MM'),
			startDate,
			endDate,
		};

		if (dataSource.type == 'group') {
			params.groupId = dataSource.id;
			this.groupService
				.getDemand(params)
				.then((demand) => demandCB(dataSource, demand));
		} else if (dataSource.type == 'meter') {
			params.meterId = dataSource.id;
			this.measurementService
				.getDemand(params)
				.then((demand) => demandCB(dataSource, demand));
		}
	}

	async getOptimalDemandResults(dataSourceId) {
		return this.httpClient
			.get(
				`${environment.backendUrl}/api/datasources/${dataSourceId}/contracts/optimaldemand`
			)
			.toPromise()
			.then((res: any) => {
				return res?.dataSource;
			});
	}

	async getPowerFactorCorrection(dataSource, vm) {
		function setDataFrame(
			measurements,
			capacitiveStartHour,
			capacitiveEndHour
		) {
			let df = new DataFrame(measurements, [
				'avgActivePower',
				'avgReactivePower',
				'PF',
				'date',
			]);
			df = df.withColumn('avgApparentPower', (row) =>
				Math.sqrt(
					Math.pow(row.get('avgActivePower'), 2) +
						Math.pow(row.get('avgReactivePower'), 2)
				)
			);

			capacitiveStartHour = moment(`2018-01-01T${capacitiveStartHour}`);
			capacitiveEndHour = moment(`2018-01-01T${capacitiveEndHour}`);

			df = df.withColumn(
				'isCapacitiveHour',
				(row) =>
					((moment(row.get('date')).hour() >= capacitiveStartHour.hour() &&
						moment(row.get('date')).minute() >= capacitiveStartHour.minute()) ||
						moment(row.get('date')).hour() >= capacitiveStartHour.hour() + 1) &&
					((moment(row.get('date')).hour() <= capacitiveEndHour.hour() &&
						moment(row.get('date')).minute() < capacitiveEndHour.minute()) ||
						moment(row.get('date')).hour() < capacitiveEndHour.hour())
			);
			df = df.withColumn(
				'isInductiveHour',
				(row) => !row.get('isCapacitiveHour')
			);

			df = df.sortBy('date');

			return df;
		}

		function computeCapacitorBank(df) {
			const newFps = 0.92;
			df = df.withColumn(
				'Qold',
				(row) =>
					Math.sqrt(
						Math.pow(row.get('avgApparentPower'), 2) -
							Math.pow(row.get('avgActivePower'), 2)
					) * Math.sign(row.get('PF'))
			);
			df = df.withColumn('newS', (row) => row.get('avgActivePower') / newFps);
			df = df.withColumn('Qnew', (row) =>
				Math.sqrt(
					Math.pow(row.get('newS'), 2) - Math.pow(row.get('avgActivePower'), 2)
				)
			);
			df = df.withColumn(
				'capacitorBank',
				(row) => row.get('Qold') - row.get('Qnew')
			);
			const capacitorBank = df.stat.max('capacitorBank');

			return capacitorBank;
		}

		function resultPassive(df, Qbc) {
			let result = df;
			result = result.withColumn(
				'newQ',
				(row) => row.get('avgReactivePower') + Qbc
			);
			result = result.withColumn(
				'newFPS',
				(row) =>
					(row.get('avgActivePower') /
						Math.sqrt(
							Math.pow(row.get('avgActivePower'), 2) +
								Math.pow(row.get('newQ'), 2)
						)) *
					Math.sign(row.get('newQ'))
			);

			return result;
		}

		function result_active_passive(df, Qbc) {
			const passive = resultPassive(df, Qbc);

			const qtFinesCapacitiveNew = passive
				.filter(
					(row) =>
						row.get('isCapacitiveHour') &&
						row.get('newFPS') < 0 &&
						row.get('newFPS') > -0.92
				)
				.count();
			const qtFinesCapacitiveOld = passive
				.filter(
					(row) =>
						row.get('isCapacitiveHour') &&
						row.get('PF') < 0 &&
						row.get('PF') > -0.92
				)
				.count();

			return {
				bestType:
					qtFinesCapacitiveNew <= qtFinesCapacitiveOld ? '' : 'Automático',
				finesOld: qtFinesCapacitiveOld,
				finesNew: qtFinesCapacitiveNew,
			};
		}

		function getCapacitorBank(
			measurements,
			capacitiveStartHour,
			capacitiveEndHour
		) {
			const df = setDataFrame(
				measurements,
				capacitiveStartHour,
				capacitiveEndHour
			);
			const inductiveData = df.filter(
				(row) => row.get('isInductiveHour') && row.get('PF') > 0
			);
			const fineData = inductiveData.filter(
				(row) => row.get('PF') < 0.92 && row.get('avgActivePower') > 0
			);
			if (fineData.count() > 0) {
				const QCapacitorBank = computeCapacitorBank(fineData);
				return {
					sizeCapacitorBank: QCapacitorBank,
					type: result_active_passive(df, QCapacitorBank),
					startDate: df.head(1).getRow(0).get('date'),
					endDate: df.tail(1).getRow(0).get('date'),
				};
			} else if (inductiveData.count() > 0) {
				return {
					sizeCapacitorBank: 0,
					onlyInductive: true,
					type: 'Automático',
					startDate: df.head(1).getRow(0).get('date'),
					endDate: df.tail(1).getRow(0).get('date'),
				};
			}
		}

		const params: any = {};
		params.startDate = vm.startDate;
		params.endDate = vm.endDate;

		let contracts;
		let measurementsPower;

		if (dataSource.type == 'meter') {
			params.meterId = dataSource.id;
			measurementsPower = await this.measurementService.powers(params);
			contracts = await this.contractService.getMeterContracts(
				dataSource.id,
				vm.startDate,
				vm.endDate
			);
		} else {
			params.dataSourceId = vm.dataSourceId;
			measurementsPower = await this.measurementService.powersGroup(params);
			contracts = await this.contractService.getGroupContracts(
				dataSource.id,
				vm.startDate,
				vm.endDate
			);
		}

		const mainContract = contracts.find((contract) =>
			contract.instances.find((instance) => instance.mainTariff)
		);
		const mainInstance = mainContract.instances.filter(
			(instance) => instance.mainTariff == true
		)[0];
		const answer = getCapacitorBank(
			measurementsPower.powers,
			mainInstance.fields.capacitiveStartHour,
			mainInstance.fields.capacitiveEndHour
		);
		const response = {
			status: 200,
			data: answer,
		};
		return response;
	}

	createInvoice(datasourceId, params) {
		return this.httpClient
			.post(
				`${environment.backendUrl}/api/datasources/${datasourceId}/invoices`,
				params
			)
			.toPromise()
			.then((res) => {
				return res;
			});
	}

	editInvoice(datasourceId, params, invoice) {
		return this.httpClient
			.post(
				`${environment.backendUrl}/api/datasources/${datasourceId}/invoices`,
				invoice,
				params
			)
			.toPromise()
			.then((res) => {
				return res;
			});
	}

	createInvoiceItem(invoiceId, datasourceId, invoiceItem) {
		return this.httpClient
			.post(
				`${environment.backendUrl}/api/datasources/${datasourceId}/invoices/${invoiceId}/invoice-items`,
				invoiceItem
			)
			.toPromise()
			.then((res) => {
				return res;
			});
	}

	editInvoiceItem(invoiceId, datasourceId, invoiceItemId, invoiceItem) {
		return this.httpClient
			.put(
				`${environment.backendUrl}/api/datasources/${datasourceId}/invoices/${invoiceId}/invoice-items/${invoiceItemId}`,
				invoiceItem
			)
			.toPromise()
			.then((res) => {
				return res;
			});
	}

	deleteInvoiceItem(invoiceId, datasourceId, invoiceItemId) {
		return this.httpClient
			.delete(
				`${environment.backendUrl}/api/datasources/${datasourceId}/invoices/${invoiceId}/invoice-items/${invoiceItemId}`
			)
			.toPromise()
			.then((res) => {
				return res;
			});
	}
}

export const ng2DatasourceService = {
	name: DataSourceService.name,
	def: downgradeInjectable(DataSourceService),
};
