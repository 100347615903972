import { Injectable } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MultiSelectOption } from './multi-selector.model';
@Injectable({
	providedIn: 'any',
})
export class MultiSelectorService {
	private optionList: MultiSelectOption[] = [];
	private onlyOption: MultiSelectOption = {} as MultiSelectOption;
	public limit: number | undefined;
	public matSelect: MatSelect = {} as MatSelect;

	public permissionsToUseMultiSelector() {
		const onlyOption = this.optionList.filter((opt) => opt.onlyOption);
		if (onlyOption) {
			this.onlyOption = onlyOption[0];
		}
	}

	get list(): MultiSelectOption[] {
		return this.optionList;
	}

	set list(list: MultiSelectOption[]) {
		this.optionList = list;
	}

	public listWithoutOnlyOption(): MultiSelectOption[] {
		if (this.optionList.length !== 0) {
			return this.optionList.filter((option) => !option.onlyOption);
		}
		return [];
	}

	get onlyOptionObject(): MultiSelectOption | undefined {
		return this.onlyOption;
	}

	public isOnlyOptionExists(
		onlyOption: MultiSelectOption | undefined
	): boolean {
		return onlyOption ? true : false;
	}

	public listCheckedOptions(
		matList: MatOption[],
		matOptionEvent: MatOption,
		option: MultiSelectOption
	): MatOption[] {
		if (matOptionEvent.selected) {
			matList.push(matOptionEvent);
		} else {
			const selectedIndex = matList.findIndex((x) => x.value === option.id);
			matList.splice(selectedIndex, 1);
		}
		return matList;
	}
}
