<div class="content-wrapper view">
	<div class="container-fluid">
		<!-- ------------------------- Navbar ------------------------- -->
		<nav class="ga-navbar">
			<div class="navbar-content">
				<h3>
					<a (click)="backTo()">
						{{ "main.menu.registrations" | transloco }}
					</a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<a (click)="backTo()">
						{{ "main.menu.organizations" | transloco }}
					</a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<span *ngIf="!isEdit" class="navbar-content__breadcrumb-page">
						{{ "organization.label.new" | transloco }}
					</span>
					<span *ngIf="isEdit" class="navbar-content__breadcrumb-page">
						{{ orgForm.value.name }}
					</span>
				</h3>
			</div>
		</nav>

		<!-- ------------------------- Título ------------------------- -->
		<div class="description">
			<div class="description__texts">
				<span class="description__texts__title">{{
					!isEdit
						? ("organization.label.new" | transloco)
						: ("organization.label.edit" | transloco)
				}}</span>
				<span class="description__texts__subtitle">{{
					!isEdit
						? ("organization.label.new-subtitle" | transloco)
						: ("organization.label.subtitle" | transloco)
				}}</span>
			</div>
		</div>

		<form
			[formGroup]="orgForm"
			id="orgForm"
			class="flex-col"
			focusInvalidInput
			(ngSubmit)="onSubmit()"
		>
			<div class="row col-lg-12 module-group">
				<h3 class="basic-data__title col-lg-12">
					{{ "global.basic-info" | transloco }}
				</h3>
				<div class="row">
					<div class="form-group col-lg-6">
						<ga-input
							[title]="'organization.label.name' | transloco"
							[placeholder]="'organization.label.name-placeholder' | transloco"
							[required]="true"
							formControlName="name"
							name="name"
							[showError]="
								!orgForm.controls['name'].valid &&
								orgForm.controls['name'].touched
							"
						></ga-input>
					</div>
					<div class="form-group col-lg-6">
						<ga-input
							[tooltip]="'organization.label.description-tooltip' | transloco"
							[title]="'organization.label.description' | transloco"
							[placeholder]="
								'organization.label.description-placeholder' | transloco
							"
							formControlName="description"
							name="description"
						></ga-input>
					</div>
				</div>
				<hr class="separator" />
				<organization-theme [theme]="theme"></organization-theme>

				<hr class="separator" />
				<h3 class="basic-data__title mt">
					<span class="mr-5">{{ "organization.label.roles" | transloco }}</span>
					<ga-helper
						[text]="'organization.label.extra-functionalty-tooltip' | transloco"
						[icon]="'fa-solid fa-circle-info'"
						[iconSize]="14"
						[iconColor]="'#A3A3A3'"
						[tooltipLocale]="'above'"
						class="helper"
					></ga-helper>
				</h3>
				<div class="row-group">
					<div class="form-group col-lg-10">
						<ga-autocomplete-chips
							class="grow-field"
							[title]="'organization.label.active-features' | transloco"
							[placeholder]="'meter-page.placeholder.new' | transloco"
							[options]="featuresRoles"
							formControlName="roles"
							name="roles"
						>
						</ga-autocomplete-chips>
					</div>
				</div>
				<hr class="separator" />
				<h3 class="basic-data__title mt">
					<span class="mr-5">{{
						"organization.label.contact-us" | transloco
					}}</span>
					<ga-helper
						[text]="'organization.label.contact-us-tooltip' | transloco"
						[icon]="'fa-solid fa-circle-info'"
						[iconSize]="14"
						[iconColor]="'#A3A3A3'"
						[tooltipLocale]="'above'"
						class="helper"
					></ga-helper>
				</h3>
				<div class="row-group">
					<div class="form-group col-lg-12">
						<ga-editor
							[placeholder]="
								'organization.label.contact-us-placeholder' | transloco
							"
							[editorConfig]="editorConfig"
							formControlName="contactUs"
							name="contactUs"
						></ga-editor>
					</div>
				</div>
				<!----------- Botões --------------->
				<hr class="separator" />
				<div class="button-group flex-row mt-24">
					<div class="btn-toolbar" role="toolbar" style="text-align: center">
						<div class="btn-group" role="group">
							<button class="btn btn-default" type="button" (click)="backTo()">
								{{ "appliance.btn.cancel" | transloco }}
							</button>
						</div>
						<div class="btn-group" role="group">
							<button class="btn btn-primary btn-color-ga ml" type="submit">
								{{ "organization.btn.save" | transloco }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
