import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as moment from 'moment';
import { SubGroupResponse } from '../models/subgroup.model';
import { TariffType } from '../models/tariff-type.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'webapp/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ContractService {
	meterContracts = [];
	groupContracts = [];
	listTariffs: any[] = [];
	constructor(private http: HttpClient) {}

	getContracts(params) {
		return this.http
			.get(`${environment.backendUrl}/api/contracts/`, { params: params })
			.toPromise()
			.then((res: any) => res?.contracts || []);
	}

	getTariffs(params?: any): any {
		return this.http
			.get(`${environment.backendUrl}/api/tariffs/`, { params: params })
			.toPromise()
			.then((res: any) => res?.tariffs || []);
	}

	__getTariffs(params?: any) {
		return this.http
			.get(`${environment.backendUrl}/api/tariffs/`, { params: params })
			.pipe(map((res: any) => res?.tariffs || []));
	}

	getTariff(contractId) {
		return this.http
			.get(`${environment.backendUrl}/api/tariffs/` + contractId)
			.toPromise();
	}

	__getTariff(contractId) {
		return this.http.get(`${environment.backendUrl}/api/tariffs/` + contractId);
	}

	saveContract(contract) {
		if (contract.id) {
			return this.http
				.put(`${environment.backendUrl}/api/contracts/` + contract.id, contract)
				.toPromise();
		} else {
			return this.http
				.post(`${environment.backendUrl}/api/contracts/`, contract)
				.toPromise();
		}
	}

	saveTariff(contract) {
		if (contract.id) {
			return this.http
				.put(`${environment.backendUrl}/api/tariffs/` + contract.id, contract)
				.toPromise();
		} else {
			return this.http
				.post(`${environment.backendUrl}/api/tariffs/`, contract)
				.toPromise();
		}
	}

	__saveTariff(contract) {
		if (contract.id) {
			return this.http.put(
				`${environment.backendUrl}/api/tariffs/` + contract.id,
				contract
			);
		} else {
			delete contract.id;
			return this.http.post(`${environment.backendUrl}/api/tariffs/`, contract);
		}
	}

	deleteTariff(tariff) {
		if (tariff.id) {
			return this.http
				.delete(`${environment.backendUrl}/api/tariffs/` + tariff.id)
				.toPromise();
		}
	}

	__deleteTariff(id) {
		return this.http.delete(`${environment.backendUrl}/api/tariffs/` + id);
	}

	deleteContract(contract) {
		if (contract.id) {
			return this.http
				.delete(
					`${environment.backendUrl}/api/contracts/` +
						contract.id +
						(contract.communionComposition ? '?isFreeMarketSharing=true' : '')
				)
				.toPromise();
		}
	}

	tariffTypes(params): Promise<TariffType[]> {
		return this.http
			.get(`${environment.backendUrl}/api/tarifftypes`, {
				params: params,
				//cache: true
			})
			.toPromise()
			.then((res: any) => {
				return res || [];
			});
	}

	getTariffType(id) {
		return this.http
			.get(`${environment.backendUrl}/api/tarifftypes/` + id)
			.toPromise();
	}

	getContractTypes(params) {
		return this.http
			.get(`${environment.backendUrl}/api/contracttypes`, { params: params })
			.toPromise();
	}

	getContractTypeByType(type) {
		return this.getContractTypes({ type: type }).then((res: any) => res);
	}

	getContractTypeBySubType(subType) {
		return this.getContractTypes({ subType: subType }).then((res: any) => res);
	}

	getContractType(id) {
		return this.http
			.get(`${environment.backendUrl}/api/contracttypes/${id}`)
			.toPromise();
	}

	getTariffInstances(contract) {
		return this.http
			.get(
				`${environment.backendUrl}/api/tariffs/` + contract.id + '/instances'
			)
			.toPromise();
	}

	__getTariffInstances(contractId: number) {
		return this.http.get(
			`${environment.backendUrl}/api/tariffs/` + contractId + '/instances'
		);
	}

	getTariffInstance(contract, contractIntanceId) {
		return this.http
			.get(
				`${environment.backendUrl}/api/tariffs/` +
					contract.id +
					'/instances/' +
					contractIntanceId
			)
			.toPromise();
	}

	saveTariffInstance(contract) {
		if (contract.instance.id) {
			return this.http
				.put(
					`${environment.backendUrl}/api/tariffs/` +
						contract.id +
						'/instances/' +
						contract.instance.id,
					contract.instance
				)
				.toPromise();
		} else {
			delete contract.instance.id;

			return this.http
				.post(
					`${environment.backendUrl}/api/tariffs/` +
						contract.id +
						'/instances/',
					contract.instance
				)
				.toPromise();
		}
	}

	saveCycle(cycle) {
		if (cycle.id) {
			return this.http.put(
				`${environment.backendUrl}/api/tariffs/` +
					cycle.tariff +
					'/instances/' +
					cycle.id,
				cycle
			);
		} else {
			delete cycle.id;

			return this.http.post(
				`${environment.backendUrl}/api/tariffs/` + cycle.tariff + '/instances/',
				cycle
			);
		}
	}

	getCycle(contractId, cycleId) {
		return this.http.get(
			`${environment.backendUrl}/api/tariffs/` +
				contractId +
				'/instances/' +
				cycleId
		);
	}

	deleteTariffInstance(contractInstance) {
		return this.http
			.delete(
				`${environment.backendUrl}/api/tariffs/` +
					contractInstance.tariff +
					'/instances/' +
					contractInstance.id
			)
			.toPromise();
	}

	__deleteTariffInstance(contractInstance) {
		return this.http.delete(
			`${environment.backendUrl}/api/tariffs/` +
				contractInstance.tariff +
				'/instances/' +
				contractInstance.id
		);
	}

	getMeterContracts(meterId, startDate, endDate) {
		if (meterId && startDate && endDate) {
			const params = {
				startDate: startDate,
				endDate: endDate,
			};

			const contracts =
				this.meterContracts[
					meterId +
						'-' +
						moment(startDate).format('YYYY-MM-DD') +
						'-' +
						moment(endDate).format('YYYY-MM-DD')
				];

			if (contracts) {
				return new Promise(function (resolve, reject) {
					resolve(contracts);
				});
			} else {
				return this.http
					.get(
						`${environment.backendUrl}/api/meters/` + meterId + '/contracts/',
						{ params: params }
					)
					.toPromise()
					.then((res: any) => {
						const contracts = res?.contracts;

						this.meterContracts[
							meterId +
								'-' +
								moment(startDate).format('YYYY-MM-DD') +
								'-' +
								moment(endDate).format('YYYY-MM-DD')
						] = contracts;

						return contracts;
					});
			}
		}
	}

	getFile(fileUrl) {
		return this.http.get(`${environment.backendUrl}/${fileUrl}`).toPromise();
	}

	getGroupContracts(groupId, startDate, endDate): Promise<any> | undefined {
		if (groupId && startDate && endDate) {
			const params = {
				startDate: startDate,
				endDate: endDate,
			};

			const contracts =
				this.groupContracts[
					groupId +
						'-' +
						moment(startDate).format('YYYY-MM-DD') +
						'-' +
						moment(endDate).format('YYYY-MM-DD')
				];

			if (contracts) {
				return new Promise(function (resolve, reject) {
					resolve(contracts);
				});
			} else {
				return this.http
					.get(
						`${environment.backendUrl}/api/groups/` + groupId + '/contracts/',
						{ params: params }
					)
					.toPromise()
					.then((res: any) => {
						const contracts = res?.contracts;

						this.groupContracts[
							groupId +
								'-' +
								moment(startDate).format('YYYY-MM-DD') +
								'-' +
								moment(endDate).format('YYYY-MM-DD')
						] = contracts;

						return contracts;
					});
			}
		}
	}

	getContractDataSources(contract) {
		return this.http
			.get(
				`${environment.backendUrl}/api/contracts/` +
					contract.id +
					'/contract-data-sources'
			)
			.toPromise()
			.then((res: any) => {
				return res?.contractDataSources;
			});
	}

	getDataSources(contractId) {
		return this.http
			.get(`${environment.backendUrl}/api/contracts/${contractId}/data-sources`)
			.toPromise()
			.then((res: any) => {
				return res;
			});
	}

	getContractTariffs(contract) {
		return this.http
			.get(
				`${environment.backendUrl}/api/contracts/` + contract.id + '/tariffs'
			)
			.toPromise()
			.then((res: any) => {
				return res?.contractTariffs;
			});
	}

	getTariffSubGroups(): Promise<SubGroupResponse['subGroups']> {
		return this.http
			.get(`${environment.backendUrl}/api/tariffs/subGroups`)
			.toPromise()
			.then((res: any) => {
				return res?.subGroups || [];
			});
	}
}

export const ng2ContractService = {
	name: ContractService.name,
	def: downgradeInjectable(ContractService),
};
