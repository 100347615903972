class OrganizationSelectorMultiController {
  constructor(UserService, OrganizationService) {
    this.userService = UserService;
    this.organizationService = OrganizationService;
  }

  async $onInit() {
    const vm = this;
    vm.currentUser = await vm.userService.getCurrentUser();
    vm.isAdmin = vm.currentUser.isAdmin;

    await vm.loadUserOrganizations();
  }

  async loadUserOrganizations() {
    const vm = this;

    const toFormattedOrgName = org => {
      org.formattedName = vm.getFormattedOrgName(org);
      return org;
    };
    if(!vm.organizations) {
      const reqParams = {};
      vm.adminOnly && (reqParams.adminRoleOnly = vm.adminOnly);
      vm.organizations = await vm.organizationService.getOrganizations(reqParams)
    }
    
    vm.organizations = vm.organizations ? vm.organizations.map(toFormattedOrgName) : [];
  }

  async filterOrganizations(query) {
    const vm = this;
    if(vm.organizations){
      const filteredOrgs = vm.organizations.filter(organization => {
        // Hacky code to account for organizations passed by component binding
        // in that case the 'formattedName' property is not present.
        if(!organization.formattedName) {
          organization.formattedName = vm.getFormattedOrgName(organization);
        }
        return organization.formattedName.toLowerCase().indexOf(query.toLowerCase()) != -1;
      });
  
      return vm.sortResultsByName(filteredOrgs);
    }
    return [];
  }

  sortResultsByName(organizations) {
    return organizations.sort((aOrg, anotherOrg) => {
      let aOrgName = aOrg.name.toLowerCase();
      let anotherOrgName = anotherOrg.name.toLowerCase();
      return (aOrgName < anotherOrgName) ? -1 : (aOrgName > anotherOrgName) ? 1 : 0
    });
  }

  getFormattedOrgName(organization) {
    return `${organization.id} - ${organization.name}`
  } 
}

const OrganizationSelectorMulti = {
  templateUrl:
    "/app/directives/organization-selector-multi/organization-selector-multi.html",
  controller: OrganizationSelectorMultiController,
  controllerAs: "osmVm",
  bindings: {
    organizations: '=',
    selectedOrganizations: "=",
    adminOnly: "@"
  }
};

export const ngOrganizationSelectorMulti = {
  name: "organizationSelectorMulti",
  def: OrganizationSelectorMulti
}