import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'consumptionPipe' })
export class ConsumptionPipe implements PipeTransform {
	transform(value: number, unit = 'Wh'): string {
		let symbol = '';
		if (Math.abs(value) > 1000 * 1000 * 1000) {
			value = value / 1000 / 1000 / 1000;
			symbol = `G${unit}`;
		} else if (Math.abs(value) > 1000 * 1000) {
			value = value / 1000 / 1000;
			symbol = `M${unit}`;
		} else if (Math.abs(value) >= 1000) {
			value = value / 1000;
			symbol = `k${unit}`;
			//format = '0,0.0';
		} else {
			symbol = unit;
		}
		return formatNumber(value, 'pt-BR', '1.0-2') + symbol;
	}
}
