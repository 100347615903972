<div id="unit" class="form-group">
	<label for="unit"
		>{{
			onlyInstallNumber
				? ("energy-bill.table.install-number" | transloco)
				: ("export-bills.metadata" | transloco)
		}}
	</label>
	<input
		type="text"
		class="form-control"
		[matAutocomplete]="auto"
		[formControl]="ucInput"
		[ngClass]="{
			'has-error': (ucInput.invalid && ucInput.touched) || isErrorInput
		}"
	/>
	<mat-autocomplete
		#auto="matAutocomplete"
		(optionSelected)="getOptionSelected($event)"
	>
		<mat-option
			*ngFor="let option of listUcs"
			[value]="option.installNumber"
			[id]="option.id"
		>
			{{ option.installNumber
			}}{{
				onlyInstallNumber
					? ""
					: " - " + (option.name ? option.name : option.ClientName)
			}}
		</mat-option>
	</mat-autocomplete>
</div>
