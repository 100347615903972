import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { ColumnType } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { GASelectorLink } from 'webapp/app/shared/ga-datatable-new/model/ga-link.model';
import { environment } from 'webapp/environments/environment';
import * as moment from 'moment';
import { map, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class CompositionListService {
	tariffTypes: any[] = [];
	constructor(private http: HttpClient, private i18n: TranslocoService) {}

	getContracts(params: any = {}): Observable<any> {
		return this.http.get<any>(`${environment.backendUrl}/api/contracts`, {
			params,
		});
	}

	getContractTariffs(contract): Observable<any> {
		return this.http.get<any>(
			`${environment.backendUrl}/api/contracts/${contract.id}/tariffs`
		);
	}

	getTariffsTypes(params): Observable<any> {
		return this.http
			.get<any>(`${environment.backendUrl}/api/tarifftypes`, {
				params,
			})
			.pipe(
				tap((res) => {
					this.tariffTypes = res || [];
				})
			);
	}

	deleteContract(contract: any = {}): Observable<any> {
		return this.http.delete<any>(
			`${environment.backendUrl}/api/contracts/${contract.id}` +
				(contract.communionComposition ? '?isFreeMarketSharing=true' : '')
		);
	}

	renderListToTable(contracts) {
		return contracts.map((contract) => {
			const row = {} as any;
			row.id = contract.id;
			row.name = contract.name;
			row.type = contract.contractType.label;
			row.communionComposition = contract.communionComposition;
			row.dataSources = this.renderDataSources(contract.dataSources);
			row.subrows = [];
			row.period = this.renderPeriod(contract);
			row.demand = this.renderEnergyDemand(contract);
			row.action = this.getActions(contract);
			return row;
		});
	}

	renderSubRows(contract: any) {
		return this.getContractTariffs(contract).pipe(
			map((response) => response.contractTariffs),
			map((cTariffs) => {
				cTariffs.map((cTariff) => {
					const tariffType = this.tariffTypes.find(
						(tType) => tType.id == cTariff.tariffTypeId
					);
					cTariff.tariffType = tariffType;
					return cTariff;
				});
				return cTariffs;
			}),
			map((tariffs) =>
				tariffs.map((tariff) => {
					const subrow = {} as any;
					subrow.name = tariff.tariffName;
					subrow.type = this.renderTariffTypeName(tariff);
					subrow.params =
						tariff.tariffType.name == 'MLProinfa'
							? '-'
							: tariff.tariffType.label;
					subrow.period = this.renderPeriod(tariff);
					subrow.demand = this.renderTariffDemand(tariff);
					subrow.action = [];
					return subrow;
				})
			)
		);
	}

	renderDataSources(dataSources): GASelectorLink[] {
		const dropdownList: GASelectorLink[] = [];
		dataSources.forEach((dataSource) => {
			const dropdownObj: GASelectorLink = {} as GASelectorLink;
			dropdownObj.placeholder = `${this.i18n.translate(
				'alerts.label.meter-popuver.title-btn-plural'
			)}`;
			dropdownObj.label = dataSource.label;
			dropdownObj.value = dataSource.id;
			if (dataSource.uid)
				dropdownObj.subtext = `${this.i18n.translate('global.code')}: ${
					dataSource.uid
				}`;
			dropdownObj.link = this.isMeter(dataSource)
				? 'main.dashboard.meter'
				: 'main.dashboard.group';
			dropdownObj.params = this.isMeter(dataSource)
				? { uid: dataSource.id }
				: { id: dataSource.id };
			dropdownList.push(dropdownObj);
		});

		return dropdownList;
	}

	isMeter(dataSource) {
		return !!dataSource.meterType || dataSource.type == 'meter';
	}

	renderPeriod(contract) {
		if (!contract.startDate || !contract.endDate) {
			return '';
		}
		const startDate = moment(contract.startDate).format('DD/MM/YYYY');
		const endDate = moment(contract.endDate).format('DD/MM/YYYY');
		const today = moment().format();

		const secondLine = this.i18n.translate('contract.tariff.expired-text');

		const msgBillingDay =
			contract.inspectionDay == 0
				? this.i18n.translate('composition-list.table.month-end-billing')
				: this.i18n.translate('composition-list.table.month-day-billing', {
					inspectionDay: contract.inspectionDay,
				  });
		if (moment(today).isBefore(contract.endDate)) {
			return `
				<span class="flex-col">
				${startDate} - ${endDate}
				<small> ${msgBillingDay} <small>
				</span>
			`;
		} else {
			return `
				<span class="flex-col expired">
				${startDate} - ${endDate}
				<div>${secondLine}</div>
				</span>
			`;
		}
	}

	renderEnergyDemand(contract) {
		const contractTariffs = contract.contractTariffs || [];

		const contractTariff = contractTariffs.find(
			(cTariff) => cTariff.mainTariff == true
		);
		if (!contractTariff || !contractTariff.fields) {
			return '-';
		}

		const demand = contractTariff.fields.demand;
		const peakDemand = contractTariff.fields.peakDemand;
		const offPeakDemand = contractTariff.fields.offPeakDemand;

		const peakLabel = this.i18n.translate('composition-list.table.peak');
		const offPeakLabel = this.i18n.translate('composition-list.table.off-peak');
		if (demand) {
			return `${demand} KW`;
		} else if (peakDemand && offPeakDemand) {
			return `${peakLabel}: ${peakDemand} KW  <br> ${offPeakLabel}: ${offPeakDemand} KW`;
		} else {
			return '-';
		}
	}

	renderTariffTypeName(tariff) {
		switch (tariff.tariffType.name) {
		case 'MLFlexibility':
			return this.i18n.translate('composition-list.table.contract');
		case 'MLProinfa':
			return this.i18n.translate('composition-list.table.proinfa');
		default:
			return this.i18n.translate('composition-list.table.tariff');
		}
	}

	renderTariffDemand(tariff) {
		if (!tariff || !tariff.fields) {
			return '-';
		}

		const demand = tariff.fields.demand;
		const peakDemand = tariff.fields.peakDemand;
		const offPeakDemand = tariff.fields.offPeakDemand;

		const peakLabel = this.i18n.translate('composition-list.table.peak');
		const offPeakLabel = this.i18n.translate('composition-list.table.off-peak');
		if (demand) {
			return `${demand} KW`;
		} else if (peakDemand && offPeakDemand) {
			return `${peakLabel}: ${peakDemand} KW  <br> ${offPeakLabel}: ${offPeakDemand} KW`;
		} else {
			return '-';
		}
	}

	get columns(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('composition-list.table.name'),
				name: 'name',
				type: 'text',
				isExpanded: true,
				expandedName: 'subrows',
				width: '20%',
			},
			{
				label: this.i18n.translate('composition-list.table.col-type'),
				name: 'type',
				type: 'text',
				width: '20%',
			},
			{
				label: this.i18n.translate('composition-list.table.col-data-sources'),
				name: 'dataSources',
				type: 'selectLink',
				width: '20%',
				textCenter: true,
				sortBy: (item: any, property: string) => item[property].length,
			},
			{
				label: this.i18n.translate('composition-list.table.col-duration'),
				name: 'period',
				type: 'custom',
				width: '20%',
			},
			{
				label: this.i18n.translate('composition-list.table.col-demand'),
				name: 'demand',
				type: 'custom',
				width: '20%',
			},
			{
				label: '',
				name: 'action',
				type: 'action',
				width: '45px',
			},
		];
	}

	get subColumns(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('composition-list.table.name'),
				name: 'name',
				type: 'text',
				width: '20%',
			},
			{
				label: this.i18n.translate('composition-list.table.col-type'),
				name: 'type',
				type: 'text',
				width: '20%',
			},
			{
				label: this.i18n.translate('composition-list.table.col-params'),
				name: 'params',
				type: 'text',
				width: '20%',
			},
			{
				label: this.i18n.translate('composition-list.table.col-duration'),
				name: 'period',
				type: 'custom',
				width: '20%',
			},
			{
				label: this.i18n.translate('composition-list.table.col-demand'),
				name: 'demand',
				type: 'custom',
				width: '20%',
			},
			{
				label: '',
				name: 'action',
				type: 'action',
				width: '45px',
			},
		];
	}

	getActions(composition): Action[] {
		const actions: Action[] = [
			{
				icon: 'fa-edit',
				title: this.i18n.translate('global.edit'),
				type: 'edit',
				permission: 'compositionUpdate',
				row: composition,
			},
			{
				icon: 'fa-trash',
				title: this.i18n.translate('global.delete'),
				type: 'delete',
				permission: 'compositionDelete',
				row: composition,
			},
		];
		return actions;
	}

	filterTable(data: any, filterText: any) {
		const filter = filterText.toLowerCase();
		return (
			data.name.toLowerCase().includes(filter) ||
			data.type.toLowerCase().includes(filter) ||
			data.period.toLowerCase().includes(filter) ||
			data.demand.toLowerCase().includes(filter) ||
			data.dataSources.some((n) => n.label.toLowerCase().includes(filter))
		);
	}
}
