import { NgModule, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { GaSelectorComponent } from './ga-selector.component';
import { MaterialModule } from '@repo/projects/webapp/global/material/material.module';
import { TranslocoModule } from '@ngneat/transloco';
import { GaInputModule } from '../ga-input/ga-input.module';
import { GaHelperModule } from '../ga-helper/ga-helper.module';
import { GaCheckboxModule } from '../ga-checkbox/ga-checkbox.module';

@NgModule({
	declarations: [GaSelectorComponent],
	imports: [
		CommonModule,
		MaterialModule,
		GaInputModule,
		FormsModule,
		ReactiveFormsModule,
		TranslocoModule,
		GaHelperModule,
		GaInputModule,
		GaCheckboxModule,
	],
	exports: [GaSelectorComponent],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GaSelectorComponent),
			multi: true,
		},
	],
})
export class GaSelectorModule {}
