import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { StateParams, StateService } from 'angular-ui-router';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { UploadService } from 'webapp/app/shared/services/upload.service';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';

@Component({
	selector: 'upload-manager',
	templateUrl: './upload-manager.component.html',
	styleUrls: ['./upload-manager.component.scss'],
})
export class UploadManagerComponent implements OnInit {
	organization: number | undefined;
	fileTypes = ['.xls', '.xlsx'];
	uploadedFiles = new FormControl('', Validators.required);
	sourceScreen: any = {};

	constructor(
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		@Inject(NG_ROUTER) private $state: StateService,
		private notification: NotificationService,
		private uploadService: UploadService
	) {
		this.organization = this.$stateParams.organizationId;
	}

	ngOnInit(): void {
		this.configureSourceScreen();
	}

	configureSourceScreen() {
		this.sourceScreen = {
			name: 'meters',
			title: 'main.menu.points',
			subtitle: 'global.measurement-points-sub',
			basicInfo: 'import.measurement-point.import',
			basicInfoSub1: 'import.measurement-point.subtitle1',
			basicInfoSub2: 'import.measurement-point.subtitle2',
			to: 'file-uploader.add.measuring-point',
			module: 'main.menu.registrations',
			screen: 'registrations',
			params: {
				tab: 'dataSources',
				organizationId: this.organization,
			},
		};
	}

	orgSelected(event: { id: number; name: string } | undefined) {
		this.organization = event?.id;
		if (!this.organization) return;

		const urlParams = {
			name: this.$stateParams.name,
			organizationId: this.organization,
		};
		this.$state.transitionTo('main.measuring-points-upload', urlParams, {
			inherit: true,
			reload: false,
			notify: false,
		});
	}

	downloadTemplate() {
		this.uploadService
			.downloadTemplateExcel(
				this.sourceScreen.name,
				this.organization?.toString()
			)
			.catch((error) => {
				console.error(error);
				this.notification.error({ msg: 'Erro ao fazer download do template' });
			});
	}

	cancel() {
		this.uploadedFiles.setValue([]);
	}

	backTo() {
		this.$state.transitionTo(
			this.sourceScreen.screen,
			this.sourceScreen.params,
			{
				inherit: false,
				reload: true,
				notify: false,
			}
		);
	}
}

export const ng2UploadManagerComponent = {
	name: 'uploadManager',
	def: downgradeComponent({
		component: UploadManagerComponent,
		propagateDigest: true,
	}),
};
