import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'webapp/environments/environment';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { AuthService } from '../auth.service';
const L10N_PATH = '/app/_l10n';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	private currentLang = '';
	constructor(
		@Inject(NG_ROUTER) private $state,
		private auth: AuthService,
		private cookie: CookieService
	) {}

	get lang() {
		return this.currentLang.replace('_', '-');
	}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		const isOwnBackendUrl = this.isOwnBackendUrl(environment, request.url);
		this.currentLang = this.cookie.get('NG_TRANSLATE_LANG_KEY');
		let newReq: HttpRequest<any> | null = null;

		if (isOwnBackendUrl) {
			newReq = request.clone({
				setHeaders: {
					Authorization: 'JWT ' + this.cookie.get('auth'),
					'Accept-Language': this.lang,
				},
				withCredentials: true,
			});

			return next.handle(newReq).pipe(
				catchError((error: HttpErrorResponse) => {
					return this.handleUnauthorized(error);
				})
			);
		} else {
			return next.handle(request).pipe(catchError(this.handleUnauthorized));
		}
	}

	handleUnauthorized = (error: HttpErrorResponse) => {
		const isOwnBackendUrl = this.isOwnBackendUrl(environment, error.url);
		if (isOwnBackendUrl && error.status === 401) {
			// auto logout if 401 response returned from api
			this.auth.logout();
			this.$state.go('login');
			return EMPTY;
		} else {
			return throwError(error);
		}
	};

	private isOwnBackendUrl(environment, url: string | null) {
		if (environment.backendUrl == '') {
			//if running local
			return url?.startsWith('/api');
		}
		return (
			url?.startsWith(environment.backendUrl) || url?.startsWith(L10N_PATH)
		);
	}
}
