import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardPage } from './dashboard.component';
import { ConsumptionChartComponent } from './charts/consumption-chart/consumption-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { ComponentContainerModule } from '../shared/component-container/component-container.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { AutoCompleteModule } from '../shared/autocomplete-components/autocomplete.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	declarations: [DashboardPage, ConsumptionChartComponent],
	imports: [
		CommonModule,
		AutoCompleteModule,
		FormsModule,
		ReactiveFormsModule,
		TranslocoModule,
		HighchartsChartModule,
		ComponentContainerModule,
		MatProgressSpinnerModule,
		SharedModule,
	],
})
export class DashboardModule {}
