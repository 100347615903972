import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Company, CompanySmall } from '../../models/company.model';
import { CompanyService } from '../../services/company.service';

@Component({
	selector: 'autocomplete-companies',
	templateUrl: './autocomplete-companies.component.html',
	styleUrls: ['./autocomplete-companies.component.scss'],
})
export class AutocompleteCompaniesComponent implements OnInit, OnChanges {
	@Input() idSelected: number | undefined;
	@Input() organization: number | undefined;
	@Input() isEnabled = true;
	@Output() companyEmitter: EventEmitter<any> = new EventEmitter();
	companyEmitted = new BehaviorSubject<any>(null);
	filteredCompanys$: Observable<any[]> = new Observable();

	companyInput: FormControl = new FormControl('');
	listCompanies: CompanySmall[] = [];

	constructor(private companyService: CompanyService) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			changes.organization &&
			changes.organization.currentValue != changes.organization.previousValue
		) {
			this.listCompanies = this.optionsFactory(
				this.filterCompaniesByOrg(this.companyService.listCompanies) || [],
				'id',
				'companyName'
			);
			const selected = this.listCompanies.find(
				(option) => option.id == this.idSelected
			);
			if (!selected) {
				this.idSelected = undefined;
				this.companyInput.setValue('');
			}
		}
		this.checkSelected();
		this.isDisabled();
	}

	isDisabled() {
		if (!this.isEnabled) {
			this.companyInput.disable();
			this.companyInput.updateValueAndValidity();
		}
	}

	ngOnInit(): void {
		this.checkCompanyValues();
		this.isDisabled();
		this.filteredCompanys$ = this.companyInput.valueChanges.pipe(
			startWith(''),
			map((value: string) => {
				if (value) return this._filterCompanys(value);
				this.listCompanies = this.optionsFactory(
					this.filterCompaniesByOrg(this.companyService.listCompanies) || [],
					'id',
					'companyName'
				);
				return this.listCompanies;
			})
		);
	}

	checkCompanyValues() {
		if (this.companyService.listCompanies.length === 0) {
			this.checkCompanies();
		} else {
			this.checkSelected();
		}
	}

	selectCompany(value) {
		this.emitEvent(value);
	}

	checkSelected() {
		this.listCompanies = this.optionsFactory(
			this.filterCompaniesByOrg(this.companyService.listCompanies) || [],
			'id',
			'companyName'
		);
		const selected = this.listCompanies.find(
			(option) => option.id == this.idSelected
		);
		if (selected)
			this.companyInput.setValue(`${selected.id} - ${selected.name}`);
		this.emitEvent(selected);
	}

	checkClear(event) {
		if (event.key !== 'Delete' && event.key !== 'Backspace') return;
		this.idSelected = undefined;
		this.companyInput.setValue('');
		this.emitEvent(this.idSelected);
	}

	emitEvent(value) {
		if (this.companyEmitted.getValue() != value) {
			this.companyEmitter.emit(value);
			this.companyEmitted.next(value);
		}
	}

	private checkCompanies() {
		this.companyService.getCompanies().then((resp: Company[]) => {
			this.companyService.listCompanies = resp;
			this.companyInput.setValue('');
			this.emitEvent(this.idSelected);
			this.checkSelected();
		});
	}

	optionsFactory(arr: Array<any>, fieldId: string, fieldName: string) {
		const optionsArr = arr.map((element) => {
			return {
				id: element[fieldId],
				name: element[fieldName],
			};
		});
		return optionsArr;
	}

	filterCompaniesByOrg(companies) {
		if (!this.organization) return companies;
		return companies.filter((comp) => comp.organization == this.organization);
	}

	private _filterCompanys(value: string): any[] {
		const filterValue = value.toLowerCase();
		return this.listCompanies.filter(
			(option: CompanySmall) =>
				option.id.toString().includes(filterValue) ||
				option.name.toLowerCase().includes(filterValue)
		);
	}
}
