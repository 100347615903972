import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as Highcharts from 'highcharts';
import { DateTime } from 'luxon';
import { DataSourceService } from 'webapp/app/shared/services/datasource.service';
import { EconomyProjectService } from 'webapp/app/shared/services/economy-projects.service';
import { SnackService } from 'webapp/app/shared/snackbar/snackbar.service';
import { ProjectConsumptionDetail } from '../project.model';
import { chartsOptions, comparationChartOptions } from './chart-options';
import { ConsumptionSavingService } from './consumption-saving.service';
import { ChartType } from './models/consumption-saving.model';
import { Observable, forkJoin, from, of } from 'rxjs';
import { map, mergeMap, switchMap, toArray } from 'rxjs/operators';
import { MultiSelectOption } from 'webapp/app/shared/multi-selector/multi-selector.model';

interface Datasources {
	energies: any[];
	totals: any[];
}
@Component({
	selector: 'project-consumption-saving',
	templateUrl: './consumption-saving.component.html',
	styleUrls: ['./consumption-saving.component.scss'],
})
export class ConsumptionSavingComponent implements AfterViewInit {
	highcharts = Highcharts;
	compareHighcharts = Highcharts;
	chartOptions!: Highcharts.Options;
	compareChartOptions!: Highcharts.Options;

	isCompare = false;
	selectedConsumptionType: ChartType = 'value';
	installList: MultiSelectOption[] = [];
	installationSelected: number[] = [];
	allConsumptions: ProjectConsumptionDetail[] = [];
	dataSourcesList!: { totals: any[]; energies: any[] };
	compareDataSources: { totals: any[]; energies: any[] }[] = [];

	constructor(
		public dialogRef: MatDialogRef<ConsumptionSavingComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private economyProjectService: EconomyProjectService,
		private datasourceService: DataSourceService,
		public snackBarService: SnackService,
		public csService: ConsumptionSavingService
	) {
		this.initInstallation();
		this.currentPeriod(this.data.element.queryDate);
	}

	private initInstallation() {
		const installations = this.data.element.installations;
		this.csService.allInstallations = installations;
		this.installList = this.csService.listToMultiSelect(installations);
		/* set in the service */
		this.csService.selectedInstallations = [installations[0].installId];
		this.installationSelected.push(installations[0].installId);
	}

	async ngAfterViewInit(): Promise<void> {
		setTimeout(async () => {
			this.generateChart();
		}, 0);
	}

	currentPeriod(currentDate: string) {
		this.csService.consumptionPeriod = currentDate;
	}

	/* type  */
	changeType(type: ChartType) {
		this.selectedConsumptionType = type;
		this.generateChart();
	}

	changedInstallation($event: MultiSelectOption[]) {
		this.allConsumptions = [];
		this.dataSourcesList = { totals: [], energies: [] };

		this.installationSelected = $event.map((ev) => ev.id);
		this.csService.selectedInstallations = $event.map((ev) => ev.id);
	}

	changedPeriod($event: { start: Date; end: Date }) {
		this.csService.consumptionPeriod = DateTime.fromJSDate(
			$event.start
		).toFormat('yyyy-MM');
		this.dataSourcesList = { totals: [], energies: [] };
	}

	ngOnDestroy() {
		this.csService.allInstallations = [];
	}

	getInstallationConsumptions(installationId) {
		return this.economyProjectService.getInstallationConsumptionsById(
			installationId,
			this.csService.period.installDate.toFormat('yyyy-MM')
		);
	}

	sumInstallationConsumptions(): Observable<any> {
		let $consumptionsList: any = of([]);
		const installIds = this.csService.selectedInstalls.map((i) => i.installId);
		$consumptionsList =
			this.allConsumptions.length === 0
				? from(installIds).pipe(
					mergeMap((install) => this.getInstallationConsumptions(install)),
					toArray()
				  )
				: of([{ consumptions: this.allConsumptions }]);

		return $consumptionsList;
	}

	getSingleInstall(installationId) {
		const $consumptions =
			this.allConsumptions.length === 0
				? this.getInstallationConsumptions(installationId)
				: of({ consumptions: this.allConsumptions });
		$consumptions
			.pipe(
				switchMap((c) => {
					return this.getDataSourcesFromInstallation(
						this.csService.selectedInstalls[0].dataSource
					).pipe(map((response) => ({ obj: c, dataSources: response })));
				})
			)
			.subscribe((res) => {
				this.dataSourcesList = res.dataSources;
				this.allConsumptions = res?.obj.consumptions;
				this.populateChart();
			});
	}

	getCompareInstalls() {
		this.isCompare = true;
		const installObservables = this.csService.selectedInstalls.map(
			(install) => {
				const $consumptions = this.getInstallationConsumptions(
					install.installId
				);
				const $datasources = this.getDataSourcesFromInstallation(
					install.dataSource
				);
				return forkJoin({
					c: $consumptions,
					dataSources: $datasources,
				});
			}
		);
		forkJoin(installObservables).subscribe((results) => {
			const firstInstall = results[0];
			const compareInstall = results[1];
			this.compareDataSources.push(
				firstInstall.dataSources,
				compareInstall.dataSources
			);
			this.populateCompareChart(firstInstall, compareInstall);
		});
	}

	sunAllInstallations() {
		const { startDate, endDate } = this.csService.dataSourcePeriod();
		const dataSoucesIds = this.csService.selectedInstalls
			.filter((i) => i.dataSource)
			.map((i) => i.dataSource);
		const resolution = 'day';
		const aggregationType = 'month';
		const items = ['totals', 'energies'];
		const params = {
			startDate,
			endDate,
			aggregationType,
			resolution,
			items,
			dataSource: JSON.stringify(dataSoucesIds),
		};
		let $datasources: any = of({ totals: [], energies: [] });

		if (this.csService.selectedInstalls.length <= 20) {
			if (this.dataSourcesList.energies.length === 0) {
				$datasources = this.datasourceService
					.getDataSourceSByListOfIds(params)
					.pipe(
						map((res) => {
							const totals = this.csService.sumDataSourcesByPeriod(
								res,
								'totals',
								'value'
							);
							const energies = this.csService.sumDataSourcesByPeriod(
								res,
								'energies',
								'consumedEnergy'
							);
							this.dataSourcesList = { totals, energies };
							return { totals, energies };
						})
					);
			} else {
				$datasources = of(this.dataSourcesList);
			}
		}

		const observables = forkJoin({
			consumptionObj: this.sumInstallationConsumptions(),
			dataSources: $datasources,
		});
		observables.subscribe((res) => {
			this.allConsumptions = this.csService.sumConsumptionsByPeriod(
				res.consumptionObj
			);
			this.populateChart();
		});
	}

	populateMeasurementLine(dataSourceList: any) {
		const currType =
			this.selectedConsumptionType === 'value' ? 'totals' : 'energies';
		const currValueType =
			this.selectedConsumptionType === 'value' ? 'value' : 'consumedEnergy';
		const datasources = this.csService.aggregatedDataSources(
			dataSourceList[currType],
			currValueType
		);
		return this.csService.measurementLine(datasources);
	}

	populateBaseLine(dataSourceList: any) {
		const currType =
			this.selectedConsumptionType === 'value' ? 'totals' : 'energies';
		const currValueType =
			this.selectedConsumptionType === 'value' ? 'value' : 'consumedEnergy';
		const datasources = this.csService.aggregatedDataSources(
			dataSourceList[currType],
			currValueType
		);
		return this.csService.baseLine(datasources);
	}

	populateArea(measurementLine: number[], baseLine: number[]) {
		return measurementLine.map((value, index) => [value, baseLine[index]]);
	}

	generateChart() {
		this.isCompare = false;
		if (this.installationSelected.length === 0) return;
		if (this.installationSelected[0] === 0) {
			this.sunAllInstallations();
		} else if (this.installationSelected.length == 2) {
			this.getCompareInstalls();
		} else {
			this.getSingleInstall(this.installationSelected[0]);
		}
	}

	populateChart() {
		const expectedBar = this.csService.expectedConsumptionBar(
			this.allConsumptions,
			this.selectedConsumptionType
		);
		const executedBar = this.csService.executedConsumptionBar(
			this.allConsumptions,
			this.selectedConsumptionType
		);
		const measurementLine = this.populateMeasurementLine(this.dataSourcesList);
		const baseLine = this.populateBaseLine(this.dataSourcesList);
		const area = this.populateArea(measurementLine, baseLine);

		this.chartOptions = chartsOptions(
			this.selectedConsumptionType,
			this.csService.categories.map((c) => c.presetationDate),
			expectedBar,
			executedBar,
			baseLine,
			measurementLine,
			area
		);
	}

	populateCompareChart(firstInstall: any, compareInstall: any) {
		const allConsumptions1 = firstInstall.c.consumptions;
		const allConsumptions2 = compareInstall.c.consumptions;

		const expected1 = this.csService.expectedConsumptionBar(
			allConsumptions1,
			this.selectedConsumptionType
		);
		const expected2 = this.csService.expectedConsumptionBar(
			allConsumptions2,
			this.selectedConsumptionType
		);

		const executed1 = this.csService.executedConsumptionBar(
			allConsumptions1,
			this.selectedConsumptionType
		);

		const executed2 = this.csService.executedConsumptionBar(
			allConsumptions2,
			this.selectedConsumptionType
		);

		const baseLine1 = this.populateBaseLine(this.compareDataSources[0]);
		const baseLine2 = this.populateBaseLine(this.compareDataSources[1]);
		const measurementLine1 = this.populateMeasurementLine(
			this.compareDataSources[0]
		);
		const measurementLine2 = this.populateMeasurementLine(
			this.compareDataSources[1]
		);

		this.compareChartOptions = comparationChartOptions(
			this.selectedConsumptionType,
			this.csService.categories.map((c) => c.presetationDate),
			expected1,
			expected2,
			executed1,
			executed2,
			baseLine1,
			baseLine2,
			measurementLine1,
			measurementLine2,
			this.csService.selectedInstalls.map((i) => i.installNumber)
		);
	}

	getDataSourcesFromInstallation(datasourceId: number): Observable<any> {
		const { startDate, endDate } = this.csService.dataSourcePeriod();
		const resolution = 'day';
		const aggregationType = 'month';
		const items = ['totals', 'energies'];

		if (
			this.dataSourcesList?.totals &&
			this.dataSourcesList.totals.length !== 0
		)
			return of(this.dataSourcesList);
		if (!datasourceId) return of({ totals: [], energies: [] });
		const params = {
			startDate,
			endDate,
			aggregationType,
			resolution,
			items,
			dataSourceId: datasourceId,
		};
		return this.datasourceService.loadTotalsRefact(params).pipe(
			map((res) => {
				this.dataSourcesList = { ...res };
				return { ...res };
			})
		);
	}
}
