import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { BehaviorSubject } from 'rxjs';
import * as tinycolor from 'tinycolor2';
import { footerImg } from './images.model';
import { defaultStyle } from './whitelabel-default';

const colorElement = document.querySelector(':root') as Element;
const cssStyles = getComputedStyle(colorElement);
@Injectable({ providedIn: 'any' })
export class WhiteLabelService {
	footerImages = new BehaviorSubject<footerImg[]>([
		{ small: '', smallImageClass: '', textImage: '', textImageClass: '' },
	]);
	defaultstyle;
	primColor = '';
	secColor = '';
	boardColor = '';

	constructor() {
		this.primColor = String(
			cssStyles.getPropertyValue('--primary-color')
		).trim();
		this.secColor = String(
			cssStyles.getPropertyValue('--secondary-color')
		).trim();
		this.boardColor = String(
			cssStyles.getPropertyValue('--grayscale---gray-700')
		).trim();

		this.defaultstyle = defaultStyle();
	}

	setWhiteLabel(currentUser) {
		const org = currentUser.organizationObj;

		org.styles = org.styles ? org.styles : {};
		org.styles['primaryColor'] = org.styles['primaryColor']
			? org.styles['primaryColor']
			: this.primColor;
		org.styles['secondaryColor'] = org.styles['secondaryColor']
			? org.styles['secondaryColor']
			: this.secColor;
		org.styles['tertiaryColor'] = org.styles['tertiaryColor']
			? org.styles['tertiaryColor']
			: this.boardColor;

		const sheet = (function () {
			// Create the <style> tag
			const style = document.createElement('style');
			// Add a media (and/or media query) here if you'd like!
			// WebKit hack :(
			style.appendChild(document.createTextNode(''));
			// Add the <style> element to the page
			document.body.appendChild(style);
			return style.sheet;
		})() as CSSStyleSheet;

		const rule =
			`:root{
        --primary-color:` +
			org.styles['primaryColor'] +
			`;
        `;
		sheet.insertRule(rule, 0);
		if (
			org.styles &&
			(org.styles['primaryColor'].toUpperCase() != this.primColor ||
				org.styles['secondaryColor'].toUpperCase() != this.secColor ||
				(org.styles['tertiaryColor'].toUpperCase() != this.primColor &&
					org.styles['tertiaryColor'].toUpperCase() != this.boardColor))
		) {
			//Change dashboard colors here
			if (org.styles == null) {
				org.styles = this.defaultstyle;
				const rule =
					`:root{
            --primary-color:` +
					org.styles['primaryColor'] +
					`;
            `;
				sheet.insertRule(rule, 0);
			} else {
				let rule =
					`:root{
            --primary-color:` +
					org.styles['primaryColor'] +
					`;
            --secondary-color:` +
					org.styles['secondaryColor'] +
					`;
            --lighter-primary:` +
					tinycolor(org.styles['primaryColor']).setAlpha(0.4).toString() +
					`;
            --hover1:` +
					tinycolor(org.styles['primaryColor']).darken().toString() +
					`;
            --hover2:` +
					tinycolor(org.styles['secondaryColor']).darken().toString() +
					`;
            `;
				if (org.styles['tertiaryColor'].toUpperCase() != this.boardColor) {
					rule = rule.concat(
						'--chartColor:' + org.styles['tertiaryColor'] + ';\n'
					);
					rule = rule.concat(
						'--chartColordark:' +
							tinycolor(org.styles['tertiaryColor']).darken().toString() +
							';\n'
					);
					rule = rule.concat(
						'--chartColorlight:' +
							tinycolor(org.styles['tertiaryColor']).lighten(20).toString() +
							';}'
					);
				} else {
					rule = rule.concat(
						'--chartColor:' + org.styles['primaryColor'] + ';\n'
					);
					rule = rule.concat(
						'--chartColordark:' +
							tinycolor(org.styles['primaryColor']).darken().toString() +
							';\n'
					);
					rule = rule.concat(
						'--chartColorlight:' +
							tinycolor(org.styles['primaryColor']).lighten(20).toString() +
							';}'
					);
				}
				sheet.insertRule(rule, 0);
			}
		}
		const headerImages = [
			{
				small: org.styles['logoImage'] || this.defaultstyle.logoImage,
				textImage: org.styles['textImage'] || this.defaultstyle.textImage,
			},
		];

		const footerImages =
			org?.styles['logoImage'] !== this.defaultstyle.logoImage
				? [
					{
						small: 'assets/images/custom_icons/powered_by_GA_horizontal.svg',
						smallImageClass: '',
						textImage: 'assets/images/custom_icons/GreenAnt_Logo_Icone-1.png',
						textImageClass: '',
					},
				  ]
				: [];
		this.footerImages.next(footerImages);
		return [this.defaultstyle, headerImages, footerImages];
	}
}

export const ng2WhiteLabelService = {
	name: WhiteLabelService.name,
	def: downgradeInjectable(WhiteLabelService),
};
