import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgRouterLinkDirective } from './ng-router-link.directive';

@NgModule({
	declarations: [NgRouterLinkDirective],
	exports: [NgRouterLinkDirective],
	imports: [CommonModule],
})
export class AjsHelperModule {}
