import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { environment } from 'webapp/environments/environment';
import {
	EconomyProject,
	Installation,
} from '../../dashboard/economy/economy-projects/project.model';
import { Auxiliar } from './auxiliar';

@Injectable({
	providedIn: 'root',
})
export class EconomyProjectService {
	economyProjectsList: EconomyProject[] = [];
	economyProjectsListChanged = new Subject<EconomyProject[]>();

	constructor(private http: HttpClient, private Auxiliar: Auxiliar) {}

	listEconomyProjects(params?: any): Observable<EconomyProject[]> {
		return this.http
			.get<any>(environment.backendUrl + '/api/projects?', { params: params })
			.pipe(map((project) => project.efficiencyProjects as EconomyProject[]));
	}

	postEconomyProject(project: EconomyProject, files: any[]): Observable<any> {
		const fileNames = project.files;
		return this.http
			.post<any>(environment.backendUrl + '/api/projects', project)
			.pipe(
				concatMap((proj) => {
					return files && files.length > 0
						? forkJoin([
								this.uploadProjectFileReport(proj.id, files),
								this.createFileInstance(proj.id, fileNames),
						  ])
						: of(proj);
				}),
				catchError((error) => {
					throw error;
				})
			);
	}

	putEconomyProject(project: EconomyProject, files): Observable<any> {
		const filesToUpload = files.filter((f) => !f.id);
		return this.http
			.put<any>(environment.backendUrl + `/api/projects/${project.id}`, project)
			.pipe(
				concatMap((proj) => {
					return filesToUpload.length > 0
						? this.uploadProjectFileReport(proj.id, filesToUpload)
						: of(proj);
				}),
				catchError((error) => {
					throw error;
				})
			);
	}

	async getEconomyProjectById(id: number): Promise<EconomyProject> {
		return this.http
			.get<EconomyProject>(environment.backendUrl + `/api/projects/${id}`)
			.toPromise();
	}

	deleteEconomyProject(id: number): Observable<any> {
		return this.http.delete<any>(
			environment.backendUrl + `/api/projects/${id}`
		);
	}

	setEconomyProjectList(economyProject: EconomyProject[]) {
		this.economyProjectsListChanged.next(economyProject);
		this.economyProjectsList = economyProject;
	}

	getTotalEconomy(list: EconomyProject[], type: string): number {
		let total = 0;
		for (const economy of list) {
			if (economy.installations.length > 0) {
				const totalEconomy = economy.installations
					.map(
						(installation: Installation) => installation.totalEconomy[type] || 0
					)
					.reduce((acc, current) => (acc = acc + current));
				total = total + totalEconomy;
			}
		}
		return total;
	}

	getEconomyConsumeDetail(idProject: number, chartOpt = false) {
		const params = { chartOpt };
		return this.http.get<any>(
			environment.backendUrl + `/api/projects/${idProject}/detailed`,
			{ params }
		);
	}

	getInstallationConsumptionsById(installationId: number, startDate: string) {
		const params = { date: startDate };
		return this.http.get<any>(
			environment.backendUrl +
				`/api/projects/installation/consumption/${installationId}`,
			{ params }
		);
	}

	getInstallationByManualChart(installationId: number) {
		try {
			return this.http.get<any>(
				environment.backendUrl +
					`/api/projects/installation/consumption/${installationId}/chart`
			);
		} catch (error) {
			throw error;
		}
	}

	getProjectDetailConsumptionSaving(idProject: number) {
		return this.http.get<any>(
			environment.backendUrl + `/api/projects/${idProject}/consumptionPeriod`
		);
	}

	$getEconomyProjectById(id: number): Observable<EconomyProject> {
		return this.http.get<EconomyProject>(
			environment.backendUrl + `/api/projects/${id}`
		);
	}

	downloadInstallationsTemplate(filename?: any) {
		return this.http
			.get<Blob>(
				environment.backendUrl + '/api/projects/installation/downloadTemplate',
				{
					headers: {
						accept:
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					},
					observe: 'response',
					responseType: 'blob' as 'json',
				}
			)
			.subscribe(
				(response: HttpResponse<Blob>) => {
					this.Auxiliar.download(response, response.type);
				},
				(error) => console.error(error)
			);
	}

	transformExcelToJson(data, params = {}) {
		return this.http
			.post(environment.backendUrl + '/api/projects/installation/list', data, {
				params: params,
			})
			.toPromise()
			.then((res) => {
				return res;
			})
			.catch((error) => {
				throw error;
			});
	}

	uploadProjectFileReport(projectId, files) {
		const filesBody = files.map((file) => file.name || file.fileName);
		return this.http
			.post<any[]>(
				environment.backendUrl + `/api/projects/${projectId}/uploadFiles`,
				filesBody
			)
			.pipe(
				mergeMap((res) =>
					forkJoin(
						res.map((s3Link, i) => {
							const formData = new FormData();
							Object.entries(s3Link.fields).forEach(
								([key, value]: [string, any]) => {
									formData.set(key, value);
								}
							);
							formData.set('file', files[i]);

							return this.http
								.post<any>(s3Link.url, formData, { withCredentials: false })
								.pipe(
									map((res) => {
										return res;
									}),
									catchError((err) => {
										return err;
									})
								);
						})
					)
				),
				catchError((error) => {
					throw error;
				})
			);
	}

	createFileInstance(projectId, file) {
		return this.http.post<any>(
			environment.backendUrl + `/api/projects/${projectId}/files`,
			file
		);
	}

	getProjectFiles(projectId) {
		return this.http.get<any[]>(
			environment.backendUrl + `/api/projects/${projectId}/files`
		);
	}

	downloadFile(projectId, fileName, fileId) {
		return this.http.get<any>(
			environment.backendUrl + `/api/projects/${projectId}/files/${fileId}`,
			{ params: { fileName: fileName } }
		);
	}

	deleteFile(projectId, fileId) {
		return this.http.delete(
			environment.backendUrl + `/api/projects/${projectId}/files/${fileId}`
		);
	}
}
