import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OrganizationRole, UserOrganizationRole } from 'webapp/app/shared/models/organization.model';
import { SelectorOption } from 'webapp/app/shared/models/selector.model';

@Component({
	selector: 'ga-organization-profiles',
	templateUrl: './ga-organization-profiles.component.html',
	styleUrls: ['./ga-organization-profiles.component.scss'],
})
export class GaOrganizationProfilesComponent implements OnInit, OnChanges, OnDestroy {
	@Input() currentUserRoleOrgs: UserOrganizationRole[] = [];
	@Input() userRoleOrgs: UserOrganizationRole[] = [];
	@Input() mainOrg: number | undefined = undefined;
	@ViewChild(MatTable) table!: MatTable<any>;
	@Output() changeOrg = new EventEmitter();
	@Output() mainOrgEmitter = new EventEmitter();
	options: SelectorOption[] = [];
	selectedValues = new FormControl('');
	dataTable: OrganizationRole[] = [];
	subscription!: Subscription;

	displayedColumns: string[] = ['organization', 'mainOrg', 'profileAdmin', 'profileBasic', 'profileRemove'];

	ngOnInit(): void {
		this.subscription = this.selectedValues.valueChanges
			.pipe(
				tap((value) => {
					this.changeOrg.emit(value);
					this.updateTable();
				})
			)
			.subscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.options = this.currentUserRoleOrgs.map((role) => ({
			label: role.organization.name,
			id: role.organization.id,
		}));
		this.userRoleOrgs.forEach((role) => {
			const org = this.options.find((option) => option.id === role.organization.id);
			if (!org)
				this.options.push({
					label: role.organization.name,
					id: role.organization.id,
				});
		});

		if (changes && changes.userRoleOrgs) {
			this.selectedValues.setValue(this.userRoleOrgs.map((role) => role.organization.id));
			this.loadTable();
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	emitMainOrg(event) {
		this.mainOrgEmitter.emit(event);
	}

	loadTable() {
		this.dataTable = [];
		this.selectedValues.value.forEach((value) => {
			this.addRowById(value);
		});
	}

	updateTable() {
		this.selectedValues.value.forEach((value) => {
			const index = this.dataTable.findIndex((row) => row.organization.id === value);
			if (index < 0) this.addRowById(value);
			if (this.selectedValues.value.length == 1) {
				this.mainOrg = this.selectedValues.value[0];
				this.emitMainOrg(this.mainOrg);
			}
		});
		for (let i = this.dataTable.length - 1; i >= 0; i--) {
			const row = this.dataTable[i];
			const index = this.selectedValues.value.findIndex((value) => row.organization.id === value);
			if (index < 0) this.removeRowById(row.organization.id);
		}
	}

	addRowById(orgId) {
		let orgRole: UserOrganizationRole | undefined = this.userRoleOrgs.find(
			(option) => option.organization.id === orgId
		);
		let role = 2;
		if (orgRole) {
			role = orgRole.role.id;
		} else {
			orgRole = this.currentUserRoleOrgs.find((option) => option.organization.id === orgId);
		}
		if (!orgRole) return;
		this.dataTable.push({ organization: orgRole.organization, role: role });
		this.table?.renderRows();
	}

	removeRowById(orgId) {
		const index = this.dataTable.findIndex((row) => row.organization.id === orgId);
		this.dataTable.splice(index, 1);
		if (orgId === this.mainOrg) {
			this.mainOrg = undefined;
			this.emitMainOrg(this.mainOrg);
		}
		this.table?.renderRows();
	}

	unselectOrg(id) {
		const index = this.selectedValues.value.findIndex((value) => id === value);
		const newValues = this.selectedValues.value.slice();

		newValues.splice(index, 1);
		this.selectedValues.setValue(newValues);

		this.updateTable();
	}

	setProfile(element, type) {
		element.role = type;
	}
}
