<div class="row col-lg-12 topbar-content">
	<section class="search-box col-lg-6 col-md-6 col-sm-6 col-xs-6">
		<i class="fa fa-search"></i>
		<input
			type="text"
			[formControl]="query"
			class="form-control search-input"
			id="search-input"
			placeholder="{{ 'main.search' | transloco }}"
			(input)="mixPanelEvent('search_bar_input')"
		/>
	</section>
</div>
