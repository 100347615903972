<button
	class="btn btn-color-ga btn-secondary dropdown-toggle dropdown-button"
	[ngClass]="btnSize"
	mat-button
	[matMenuTriggerFor]="menu"
>
	<i *ngIf="icon" class="fa-regular" [ngClass]="icon"></i>
	{{ label }}
	<i *ngIf="showCaret" class="fa-regular fa-chevron-down"></i>
</button>
<mat-menu #menu="matMenu">
	<button
		class="dropdown-item"
		*ngFor="let option of actionOptions"
		(click)="emitAction(option)"
		mat-menu-item
	>
		<i *ngIf="option.icon" class="fa-regular" [ngClass]="option.icon"></i>
		<div class="text-group">
			<span
				class="text-group__title"
				[ngClass]="{ 'option-label': option.subtitle }"
				>{{ option.title }}</span
			>
			<small *ngIf="option.subtitle" class="text-group__sub">{{
				option.subtitle
			}}</small>
		</div>
	</button>
</mat-menu>
