import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionButton } from '../models/selector.model';

@Component({
	selector: 'ga-button-dropdown',
	templateUrl: './ga-button-dropdown.component.html',
	styleUrls: ['./ga-button-dropdown.component.scss'],
})
export class GaButtonDropdownComponent {
	@Input() label = '';
	@Input() icon = '';
	@Input() showCaret = false;
	@Input() btnSize = '';
	@Input() actionOptions: ActionButton[] = [];
	@Output() action = new EventEmitter();

	emitAction(option) {
		this.action.emit(option);
	}
}
