<div class="body-notification">
	<span class="sub-title">{{ "user.notification.msg" | transloco }} </span>
	<span class="sub-title bold">{{ "user.notification.msg2" | transloco }}</span>
	<div class="notifications">
		<div class="info" *ngFor="let ds of notificationsDs; let i = index">
			<p>{{ ds.label }}</p>
			<ga-toggle
				[checked]="ds.offlineReport"
				[size]="'large'"
				(toggle)="onChanged($event, ds)"
			></ga-toggle>
		</div>
	</div>
</div>
