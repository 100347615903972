import * as moment from "moment";

// https://github.com/g00fy-/angular-datepicker


const endDayHourReset = { "hour": 23, "minute": 59, "second": 59, "millisecond": 0 };
const startDayHourReset = { "hour": 0, "minute": 0, "second": 0, "millisecond": 0 };

/* @ngInject */
function CalendarNavigator(UserService) {

  var calendarNavigator = {
    restrict: 'E',
    templateUrl: '/app/directives/calendar-navigator/calendar-navigator.html',
    scope: {
      id: "@",
      calendarOptions: "=?",
      disabled: "=?",
      channel: "@"
    },

    controller: CalendarNavigatorController,
    controllerAs: 'vm',
    bindToController: true
  };
  UserService.getCurrentUser().then(user => {
    var locale = user.language;
    moment.locale(locale);
  });

  return calendarNavigator;
}

CalendarNavigatorController.$inject = ['$scope', 'Auxiliar', '$timeout', '$translate', 'CalendarService', 'UserService', 'MixPanelService'];

/* @ngInject */
function CalendarNavigatorController($scope, Auxiliar, $timeout, $translate, CalendarService, UserService, MixPanelService) {

  var vm = this;
  vm.$onInit = onInit;
  vm.clickDebounceTime = 1500;
  vm.$onDestroy = onDestroy;
  vm.$translate = $translate;
  vm.mixpanel = MixPanelService
  $scope.loadingById = function (id, v) { if (v == true) { $(id).show(); } else { $(id).hide(); } }

  $scope.loading = function (v) { $scope.loadingById('#loadingModal', v); }

  var periodFunctions = {
    /*hours bars (just one day)*/
    'hour': {
      format: function (format) {

        if (format === "year") {
          if (vm.dateNavigation.formattedYear == null) {
            vm.dateNavigation.formattedYear = moment(vm.dateNavigation[vm.calendarOptions.formatDate]).format("MMM YYYY");
          }
          return vm.dateNavigation.formattedYear;
        } else {
          if (vm.dateNavigation.formatted == null) {
            vm.dateNavigation.formatted = moment(vm.dateNavigation[vm.calendarOptions.formatDate]).format("ddd, DD");
          }
          return vm.dateNavigation.formatted;
        }
      },
      next: function () {
        vm.dateNavigation.startDate = moment(vm.dateNavigation.startDate).add(1, 'days');
        vm.dateNavigation.endDate = moment(vm.dateNavigation.endDate).add(1, 'days').set({ "hour": 23, "minute": 59, "second": 59 });
        this.checkNavigationControls();
      },
      previous: function () {
        vm.dateNavigation.startDate = moment(vm.dateNavigation.startDate).subtract(1, 'days');
        vm.dateNavigation.endDate = moment(vm.dateNavigation.endDate).subtract(1, 'days').set({ "hour": 23, "minute": 59, "second": 59 });
        this.checkNavigationControls();
      },
      calculateDates: function () {
        if (vm.dateNavigation.startDate == undefined) {
          vm.dateNavigation.startDate = moment().millisecond(0).set(startDayHourReset);;
        }
        vm.dateNavigation.endDate = moment(vm.dateNavigation.startDate).millisecond(0).set({ "hour": 23, "minute": 59, "second": 59 });
        this.checkNavigationControls();
      },
      checkNavigationControls: function () {
        vm.enabledNext = false;
        vm.enabledPrevious = false;

        $timeout(function () {
          vm.enabledNext = true
          vm.enabledPrevious = true;
        }, vm.clickDebounceTime);
      },
      minView: "date",
      maxView: "year"
    },
    'week': {
      format: function (format) {
        if (format === "year") {
          if (vm.dateNavigation.formattedYear == null) {
            vm.dateNavigation.formattedYear = moment(vm.dateNavigation[vm.calendarOptions.formatDate]).format("MMM YYYY");
          }
          return vm.dateNavigation.formattedYear;
        } else {
          if (vm.dateNavigation.formatted == null) {
            vm.dateNavigation.formatted = moment(vm.dateNavigation[vm.calendarOptions.formatDate]).format("ddd, DD");
          }
          return vm.dateNavigation.formatted;
        }
      },
      next: function () {
        vm.dateNavigation.startDate = moment(vm.dateNavigation.startDate).add(7, 'days');
        vm.dateNavigation.endDate = moment(vm.dateNavigation.endDate).add(7, 'days').set({ "hour": 23, "minute": 59, "second": 59 });
        this.checkNavigationControls();
      },
      previous: function () {
        vm.dateNavigation.startDate = moment(vm.dateNavigation.startDate).subtract(7, 'days');
        vm.dateNavigation.endDate = moment(vm.dateNavigation.endDate).subtract(7, 'days').set({ "hour": 23, "minute": 59, "second": 59 });
        this.checkNavigationControls();
      },
      calculateDates: function () {
        let startWeek = moment(vm.dateNavigation.startDate).subtract(moment(vm.dateNavigation.startDate).day(), "days");
        vm.dateNavigation.startDate = startWeek;

        let endWeek = moment(vm.dateNavigation.startDate).add(6, "days");
        vm.dateNavigation.endDate = endWeek;

        this.checkNavigationControls();
      },
      checkNavigationControls: function () {
        vm.enabledNext = false;
        vm.enabledPrevious = false;

        $timeout(function () {
          vm.enabledNext = !(moment(vm.dateNavigation.endDate).format("MM-DD-YYYY") == moment().format("MM-DD-YYYY"));
          vm.enabledPrevious = true;
        }, vm.clickDebounceTime);
      },
      minView: "date",
      maxView: "year"
    },
    /* invoice range*/
    'day': {
      format: function (format) {

        if (format === "year") {
          if (vm.dateNavigation.formattedYear == null) {
            if (vm.calendarOptions.format == "full" || vm.calendarOptions.format == "detailed") {
              vm.dateNavigation.formattedYear = " " + moment(vm.dateNavigation.endDate).format("YYYY");
            } else {
              vm.dateNavigation.formattedYear = " ";
            }
          }
          return vm.dateNavigation.formattedYear;
        } else {

          if (vm.dateNavigation.formatted == null) {
            if (vm.calendarOptions.format == "detailed" && vm.calendarOptions.formatDate) {
              vm.dateNavigation.formatted = moment(vm.dateNavigation[vm.calendarOptions.formatDate]).format("DD MMM");
            }
            else if (vm.calendarOptions.format == "full") {
              vm.dateNavigation.formatted = moment(vm.dateNavigation.startDate).format("DD MMM") + " - " + moment(vm.dateNavigation.endDate).format("DD MMM");
            }
            else {
              vm.dateNavigation.formatted = moment(vm.dateNavigation.endDate).format("MMMM YYYY");
            }
          }

          return vm.dateNavigation.formatted;
        }
      },

      next: function () {
        if (vm.dateNavigation.inspectionDay == 0) {// whole month
          vm.dateNavigation.startDate = moment(vm.dateNavigation.startDate).add(1, 'months');
          vm.dateNavigation.endDate = moment(vm.dateNavigation.startDate).add(1, 'months').subtract(1, 'days').set({ "hour": 23, "minute": 59, "second": 59 });
        } else {
          vm.dateNavigation.startDate = moment(vm.dateNavigation.startDate).add(1, 'months');
          vm.dateNavigation.endDate = moment(vm.dateNavigation.endDate).add(1, 'months').set({ "hour": 23, "minute": 59, "second": 59 });
        }

        this.checkNavigationControls();
      },
      previous: function () {
        if (vm.dateNavigation.inspectionDay == 0) {// whole month
          vm.dateNavigation.startDate = moment(vm.dateNavigation.startDate).subtract(1, 'months');
          vm.dateNavigation.endDate = moment(vm.dateNavigation.startDate).add(1, 'months').subtract(1, 'days').set({ "hour": 23, "minute": 59, "second": 59 });
        } else {
          vm.dateNavigation.startDate = moment(vm.dateNavigation.startDate).subtract(1, 'months');
          vm.dateNavigation.endDate = moment(vm.dateNavigation.endDate).subtract(1, 'months').set({ "hour": 23, "minute": 59, "second": 59 });
        }

        this.checkNavigationControls();
      },
      calculateDates: function () {
        if (vm.dateNavigation.inspectionDay != undefined) {
          var referenceDate = null;
          if (vm.dateNavigation.startDate) {
            //use reference date to calculate de right invoice range based on last selected date
            if (moment(vm.dateNavigation.startDate).date() > vm.dateNavigation.inspectionDay && vm.dateNavigation.inspectionDay != 0) {
              referenceDate = moment(vm.dateNavigation.startDate).add(1, "M").millisecond(0).toDate();
            } else {
              referenceDate = moment(vm.dateNavigation.startDate).toDate();
            }
          }
          vm.dateNavigation.inspectionDate = moment(Auxiliar.getInspectionDate(vm.dateNavigation.inspectionDay, referenceDate)).hour(0).millisecond(0);
        }

        if (vm.dateNavigation.inspectionDate) {
          if (vm.dateNavigation.inspectionDay == 0) {
            vm.dateNavigation.startDate = moment(vm.dateNavigation.inspectionDate).set({ "date": 1, "minute": 0, "hour": 0, "second": 0, "millisecond": 0 });
          } else {
            vm.dateNavigation.startDate = moment(vm.dateNavigation.inspectionDate).subtract(1, "months").add(1, "days").millisecond(0);
          }
        } else if (vm.dateNavigation.startDate == undefined) {
          vm.dateNavigation.startDate = moment().millisecond(0);
        }

        if (vm.dateNavigation.inspectionDate) {
          if (vm.dateNavigation.inspectionDay == 0) {
            vm.dateNavigation.endDate = moment(vm.dateNavigation.startDate).add(1, 'months').subtract(1, 'days').set({ "hour": 23, "minute": 59, "second": 59 });
          } else {
            vm.dateNavigation.endDate = moment(vm.dateNavigation.inspectionDate).millisecond(0).set({ "hour": 23, "minute": 59, "second": 59 });
          }
        } else if (vm.dateNavigation.endDate == undefined) {
          vm.dateNavigation.endDate = moment(vm.dateNavigation.startDate).add(1, "months").subtract(1, "days").millisecond(0).set({ "hour": 23, "minute": 59, "second": 59 });
        }

        vm.enabledPrevious = true;
        this.checkNavigationControls();
      },
      checkNavigationControls: function () {
        vm.enabledNext = false;
        vm.enabledPrevious = false;

        $timeout(function () {
          vm.enabledNext = true
          vm.enabledPrevious = true;
        }, vm.clickDebounceTime);
      },
      minView: "month",
      maxView: "year"
    },
    'month': {
      format: function (format) {

        if (format === "year") {
          if (vm.dateNavigation.formattedYear == null) {
            if (vm.calendarOptions.displayControls) {
              vm.dateNavigation.formattedYear = moment(vm.dateNavigation[vm.calendarOptions.formatDate]).format("YYYY");
            }
            else {
              vm.dateNavigation.formattedYear = moment(vm.dateNavigation[vm.calendarOptions.formatDate]).format("MMM YYYY");
            }
          }

          return vm.dateNavigation.formattedYear;
        } else {
          if (vm.calendarOptions.format == "detailed") {
            if (vm.dateNavigation.formatted == null) {
              vm.dateNavigation.formatted = moment(vm.dateNavigation[vm.calendarOptions.formatDate]).format("ddd, DD");
            }
            return vm.dateNavigation.formatted;
          } else {
            if (vm.dateNavigation.formatted == null) {
              vm.dateNavigation.formatted = moment(vm.dateNavigation.startDate).format("MMMM");
            }
            return vm.dateNavigation.formatted;
          }

        }
      },
      next: function () {
        vm.dateNavigation.startDate = moment(vm.dateNavigation.startDate).add(1, 'months');
        vm.dateNavigation.endDate = moment(vm.dateNavigation.startDate).add(1, 'months').subtract(1, 'days').set({ "hour": 23, "minute": 59, "second": 59 });

        this.checkNavigationControls();
      },
      previous: function () {
        vm.dateNavigation.startDate = moment(vm.dateNavigation.startDate).subtract(1, 'months');
        vm.dateNavigation.endDate = moment(vm.dateNavigation.startDate).add(1, 'months').subtract(1, 'days').set({ "hour": 23, "minute": 59, "second": 59 });
        this.checkNavigationControls();
      },
      calculateDates: function () {

        if (vm.dateNavigation.endDate == undefined) {
          vm.dateNavigation.startDate = moment().millisecond(0).set("date", 1);
        } else {
          vm.dateNavigation.startDate = moment(vm.dateNavigation.startDate).millisecond(0).set("date", 1);
        }

        vm.dateNavigation.endDate = moment(vm.dateNavigation.startDate).millisecond(0).add(1, 'months').subtract(1, 'days').set({ "hour": 23, "minute": 59, "second": 59 });
        this.checkNavigationControls();
      },
      checkNavigationControls: function () {
        vm.enabledNext = false;
        vm.enabledPrevious = false;

        $timeout(function () {
          vm.enabledNext = true
          vm.enabledPrevious = true;
        }, vm.clickDebounceTime);
      },
      minView: "month",
      maxView: "year"
    },
    'semester': {
      format: function(format) {
        let { startDate, endDate, formatted, formattedYear } = vm.dateNavigation;
        formattedYear = moment(startDate).format('YYYY');
        formatted = `${moment(startDate).format("MMM")} - ${moment(endDate).format("MMM")}`;
        return format == 'year' ? formattedYear : formatted;
      },
      next: function() {
        let { startDate, endDate } = vm.dateNavigation;

        const endDateYear = moment(endDate).year();
        const getMiddleYear = (year) => moment(`${year}/06/30`, 'YYYY/MM/DD');
        const curYearMiddleDt = getMiddleYear(endDateYear);
      
        if (moment(startDate).isSameOrAfter(curYearMiddleDt)) {
          //we are in the second semester - update to first semester, next year
          vm.dateNavigation.endDate = moment(curYearMiddleDt).add(1, 'year');
          vm.dateNavigation.startDate = moment(vm.dateNavigation.endDate).startOf('year');
        } else {
          //we are in the firt semester - go to second semester
          vm.dateNavigation.startDate = moment(curYearMiddleDt).add(1, 'day');
          vm.dateNavigation.endDate = moment(endDate).endOf('year');
        }
        this.checkNavigationControls();
      },
      previous: function() {
        let { startDate, endDate } = vm.dateNavigation;
        const endDateYear = moment(endDate).year();
        const getMiddleYear = (year) => moment(`${year}/06/30`, 'YYYY/MM/DD');
        const curYearMiddleDt = getMiddleYear(endDateYear);
        if (moment(startDate).isSameOrAfter(curYearMiddleDt)){
          //we are in the second semester - update dates to first semester
          vm.dateNavigation.startDate = moment(endDate).startOf('year');
          vm.dateNavigation.endDate = moment(curYearMiddleDt);
      
        } else {
          //we are in the firt semester - go to last year second semester
          const lastYear = moment(curYearMiddleDt.subtract(1, 'year'))
          vm.dateNavigation.startDate = moment(lastYear).add(1, 'day');
          vm.dateNavigation.endDate = moment(lastYear).endOf('year');
        }
        this.checkNavigationControls();
      },
      calculateDates: function() {
        // End date is used to determine the proper semester
        let { endDate } = vm.dateNavigation;

        endDate = endDate ? moment(endDate): moment();
        const MIDDLE_YEAR = moment(`${moment(vm.dateNavigation.endDate).year()}/06/30`, 'YYYY/MM/DD');
        if (endDate.isSameOrBefore(MIDDLE_YEAR)) {
          vm.dateNavigation.startDate = moment(endDate).startOf('year');
          vm.dateNavigation.endDate = MIDDLE_YEAR;
        } else {
          vm.dateNavigation.startDate = moment(MIDDLE_YEAR).add(1, 'day');
          vm.dateNavigation.endDate = moment(endDate).endOf('year');
        }
        
        this.checkNavigationControls();
      },
      checkNavigationControls: function() {
        let { endDate } = vm.dateNavigation;
        vm.enabledNext = false;
        vm.enabledPrevious = false;

        $timeout(function () {
          vm.enabledNext = moment(endDate).isSameOrBefore(moment(), 'MM')
          vm.enabledPrevious = true;
        }, vm.clickDebounceTime);
      },
      minView: 'month',
      maxView: 'year'
    },
    'year': {
      format: function (format) {
        if (format === "year") {
          vm.dateNavigation.formattedYear = "";
          return "";
        } else {

          if (vm.calendarOptions.format == "detailed") {
            if (vm.dateNavigation.formatted == null) {
              vm.dateNavigation.formatted = moment(vm.dateNavigation[vm.calendarOptions.formatDate]).format("ddd, DD MMM YYYY");
            }
            return vm.dateNavigation.formatted;
          }
          if (vm.calendarOptions.format == "month") {
            if (vm.dateNavigation.formatted == null) {
              vm.dateNavigation.formatted = moment(vm.dateNavigation[vm.calendarOptions.formatDate]).format("MMMM, YYYY");
            }
            return vm.dateNavigation.formatted;
          } else {
            if (vm.dateNavigation.formatted == null) {
              vm.dateNavigation.formatted = moment(vm.dateNavigation[vm.calendarOptions.formatDate]).format("YYYY");
            }
            return vm.dateNavigation.formatted;
          }
        }
      },

      next: function () {
        vm.dateNavigation.startDate = moment(vm.dateNavigation.startDate).add(1, 'years');
        vm.dateNavigation.endDate = moment(vm.dateNavigation.endDate).add(1, 'years').set({ "hour": 23, "minute": 59, "second": 59 });
        this.checkNavigationControls();
      },
      previous: function () {
        vm.dateNavigation.startDate = moment(vm.dateNavigation.startDate).subtract(1, 'years');
        vm.dateNavigation.endDate = moment(vm.dateNavigation.endDate).subtract(1, 'years').set({ "hour": 23, "minute": 59, "second": 59 });
        this.checkNavigationControls();
      },
      calculateDates: function () {

        if (vm.dateNavigation.startDate == undefined) {
          vm.dateNavigation.startDate = moment();
        }

        vm.dateNavigation.startDate = moment(vm.dateNavigation.startDate).set({ "date": 1, "month": 0, "millisecond": 0, "hour": 0, "minute": 0, "second": 0 });

        vm.dateNavigation.endDate = moment(vm.dateNavigation.startDate).add(1, 'years').subtract(1, 'days').set({ "hour": 23, "minute": 59, "second": 59 });
        this.checkNavigationControls();
      },
      checkNavigationControls: function () {
        vm.enabledNext = false;
        vm.enabledPrevious = false;

        $timeout(function () {
          vm.enabledNext = +(moment(vm.dateNavigation.endDate).format("YYYY") <
            +moment().format("YYYY"));
          vm.enabledPrevious = true;
        }, vm.clickDebounceTime);
      },
      minView: "year",
      maxView: "year"
    },
    'custom': {
      format: function (format) {
        if (format === "year") {
          if (vm.dateNavigation.formattedYear == null && vm.dateNavigation.startDate && vm.dateNavigation.endDate) {
            vm.dateNavigation.formattedYear = moment(vm.dateNavigation[vm.calendarOptions.formatDate]).format("YYYY");
          }
          return " " + vm.dateNavigation.formattedYear;
        } else {
          if (vm.dateNavigation.formatted == null && vm.dateNavigation.startDate && vm.dateNavigation.endDate) {
            if (vm.calendarOptions.displayControls) {
              vm.dateNavigation.formatted = moment(vm.dateNavigation.startDate).format("DD MMM") + " - " + moment(vm.dateNavigation.endDate).format("DD MMM");
            }
            else {
              vm.dateNavigation.formatted = moment(vm.dateNavigation[vm.calendarOptions.formatDate]).format("DD MMM");
            }
          }
          return vm.dateNavigation.formatted;
        }
      },
      formattedSecondCalendar: function (format) {
        return ""
      },
      calculateDates: function () {
        if (vm.dateNavigation.inspectionDay != undefined) {
          var inspectionDate = null;
          if (vm.dateNavigation.startDate) {
            inspectionDate = moment(vm.dateNavigation.startDate).toDate();
          }
          vm.dateNavigation.inspectionDate = moment(Auxiliar.getInspectionDate(vm.dateNavigation.inspectionDay, inspectionDate)).millisecond(0).hour(0);
        }

        if (vm.dateNavigation.endDate == undefined) {
          vm.dateNavigation.endDate = moment().millisecond(0).set({ "hour": 23, "minute": 59, "second": 59 });
        }

        if (vm.dateNavigation.startDate == undefined) {
          vm.dateNavigation.startDate = moment(vm.dateNavigation.endDate).millisecond(0).subtract(1, "months").add(1, "days");
        }

        this.checkNavigationControls();
      },
      checkNavigationControls: function () {
        vm.enabledNext = false;
        vm.enabledPrevious = false;
      },
      minView: "date",
      maxView: "year"
    }
  }

  function onInit() {
    vm.observerId = CalendarService.registerObserver(onNotify, vm.channel);
  }

  function onDestroy() {
    if (vm.observerId) {
      CalendarService.unregisterObserver(vm.observerId, vm.channel);
    }
  }

  function onNotify(calendarData, event) {
    //need this validation to not end in a loop
    if (JSON.stringify(vm.dateNavigation) !== JSON.stringify(calendarData)
      && calendarData.inspectionDay != undefined) {
      vm.dateNav = calendarData;

      //Clone object
      vm.dateNavigation = {
        period: vm.dateNav.period,
        inspectionDay: vm.dateNav.inspectionDay,
        backTo: calendarData.backTo,
        hasContracts: calendarData.hasContracts
      };

      if (vm.dateNav.startDate && vm.dateNav.endDate) {
        vm.dateNavigation.startDate = moment(vm.dateNav.startDate).millisecond(0).set({ "hour": 0, "minute": 0, "second": 0 });
        vm.dateNavigation.endDate = moment(vm.dateNav.endDate).millisecond(0).set({ "hour": 23, "minute": 59, "second": 59 });
      }

      const defaultPeriods = ['hour', 'week', 'month', 'semester', 'year', 'day', 'custom'];
      var options = {
        displayControls: true,
        displayPeriodSelector: true,
        datepickerPosition: "left",
        displayFormatted: true,
        displayBorder: true,
        hasInvoice: calendarData.hasInvoice ? calendarData.hasInvoice : false,
        format: 'full',
        formatDate: 'startDate',
        periods: calendarData.periods ? calendarData.periods : defaultPeriods,
        periodNames: {
          'hour': 'hour',
          'month': 'month',
          'semester': 'semester',
          'year': 'year',
          'custom': 'custom',
          'week': 'week',
          'day': 'day',
        },
        calendarLabel: ""
      };
      vm.calendarOptions = Object.assign({}, options, vm.calendarOptions);

      vm.periods = vm.calendarOptions.periods;
      vm.periodNames = buildPeriodNames(vm.calendarOptions);
      vm.calendarLabel = vm.calendarOptions.calendarLabel;
      vm.enabledNext = true;
      vm.enabledPrevious = true;
      vm.displayNext = true;
      vm.displayPrevious = true;
      vm.displayBack = false;

      if (vm.disabled == true) {
        vm.calendarOptions.displayControls = false;
      }

      if (vm.dateNavigation && vm.dateNavigation.period) {
        periodFunctions[vm.dateNavigation.period].calculateDates();
        vm.dateNavigation.minView = periodFunctions[vm.dateNavigation.period].minView;
        vm.dateNavigation.maxView = periodFunctions[vm.dateNavigation.period].maxView;
      }

      vm.display = false;
      vm.displaySecond = false;
      vm.dateRange = false;

      vm.dateRange = vm.dateNavigation.period == 'custom';

      vm.dropCalendarStyle = {
        top: '42px',
        right: '-75px'
      };

      callChangeListener(event);
    }
  }

  vm.getPeriodName = function (periodOpt) {
    const vm = this;

    const period = periodOpt || vm.dateNavigation.period;
    const periodAliases = vm.calendarOptions.periodAliases;

    const aliasPeriod = periodAliases?.find(alias => alias.name == period);
    if (periodAliases && aliasPeriod) {
      return vm.$translate.instant(aliasPeriod.label);
    } else {
      return vm.$translate.instant(`main.navigator.${vm.periodNames[period]}`);
    }
  }

  function buildPeriodNames(calendarOptions) {
    const { periodNames, periodAliases } = calendarOptions;
    periodAliases &&
      periodAliases.forEach(alias => {
        periodNames[alias.name] = alias.name;
        periodFunctions[alias.name] = periodFunctions[alias.mapsTo];
      });
    return calendarOptions.periodNames;
  }

  function periodToResolution(period) {
    var mapResolutionToPeriod = {
      "hour": "hour",
      "day": "day",
      "week": "day",
      "month": "day",
      "custom": "day",
      "year": "invoice"
    };

    return mapResolutionToPeriod[period];
  }

  function update() {

    vm.display = false;
    periodFunctions[vm.dateNavigation.period].calculateDates();
    vm.dateNavigation.minView = periodFunctions[vm.dateNavigation.period].minView;
    vm.dateNavigation.maxView = periodFunctions[vm.dateNavigation.period].maxView;

    vm.dateNavigation.formatted = null;
    vm.dateNavigation.formattedYear = null;

    var view = false;
    if (vm.dateRange) {
      view = 'date';
    }

    $scope.$broadcast('pickerUpdate', vm.id, {
      minView: vm.dateNavigation.minView,
      maxView: vm.dateNavigation.maxView,
      view: view
    });

    callChangeListener();
  }


  vm.back = () => {
    if (vm.dateNavigation.period == "hour") {
      vm.dateNavigation.backTo = vm.dateNavigation.backTo ? vm.dateNavigation.backTo : (vm.dateNavigation.hasContracts ? 'day' : 'month');
      vm.selectPeriod(vm.dateNavigation.backTo);
    }
  }

  vm.selectPeriod = period => {
    vm.dateNavigation.period = period;
    vm.dateRange = period == 'custom';
    update();
    if (vm.dateRange) {
      vm.display = true;
    }
  };
  vm.formattedCalendar = function (format) {
    if (vm.dateNavigation && vm.dateNavigation.startDate && vm.dateNavigation.endDate) {
      return periodFunctions[vm.dateNavigation.period].format(format);
    } else {
      return "";
    }
  }
  vm.formattedSecondCalendar = function (format) {
    if (vm.dateNavigation && vm.dateNavigation.startDate && vm.dateNavigation.endDate) {
      return periodFunctions[vm.dateNavigation.period].formattedSecondCalendar(format);
    } else {
      return "";
    }
  }

  vm.next = function () {
    if (vm.enabledNext) {
      $scope.loading(true);
      periodFunctions[vm.dateNavigation.period].next();
      vm.dateNavigation.formatted = null;
      vm.dateNavigation.formattedYear = null;
      callChangeListener();
    }
  }
  vm.previous = function () {
    if (vm.enabledPrevious) {
      $scope.loading(true);
      periodFunctions[vm.dateNavigation.period].previous();
      vm.dateNavigation.formatted = null;
      vm.dateNavigation.formattedYear = null;
      callChangeListener();
    }
  }

  vm.updateDatePicker = function () {
    vm.datePicker = {
      startDate: vm.dateNavigation.startDate,
      endDate: vm.dateNavigation.endDate
    }

    if (vm.dateNavigation.period == "day") {
      vm.datePicker.maxDate = moment(Auxiliar.getInspectionDate(vm.dateNavigation.inspectionDay)).hour(0).format("YYYY-MM-DD");
    }
    else {
      vm.datePicker.maxDate = moment().format("YYYY-MM-DD");
    }

    setTimeout(function () {
      $('span').parent().parent().removeClass('selected');
      $('span.active').parent().parent().addClass('selected');
    }, 200)
  }

  $scope.changeDate = function (modelName, date) {
    setTimeout(function () {
      $('span').parent().parent().removeClass('selected');
      $('span.active').parent().parent().addClass('selected');
    }, 200)
  }

  vm.updateDateNavigation = function () {
    vm.updateDateNav(vm.datePicker.startDate, moment(vm.datePicker.endDate).set({ "hour": 23, "minute": 59, "second": 59 }));
  }

  vm.updateDateNav = function (startDate, endDate) {
    vm.dateNavigation.startDate = startDate;
    vm.dateNavigation.endDate = endDate;

    periodFunctions[vm.dateNavigation.period].calculateDates();

    vm.display = false;
    vm.dateNavigation.formatted = null;
    vm.dateNavigation.formattedYear = null;

    callChangeListener();
  }

  vm.openCalendar = function ($event) {
    if (vm.disabled != true) {
      vm.display = !vm.display;
      vm.updateDatePicker();
    }
  }

  vm.mixPanelEvent = (eventName) => {
    const period = vm.dateNavigation.period;
    const objectLog = {
      period,
    };
    this.mixpanel.mixPanelEvent({
      type: eventName,
      object: objectLog
    });
  }

  function callChangeListener(event) {
    periodFunctions[vm.dateNavigation.period].format();
    periodFunctions[vm.dateNavigation.period].format('year');

    vm.dateNavigation.resolution = periodToResolution(vm.dateNavigation.period);
    vm.dateNavigation.update = function () {
      update();
    };

    CalendarService.updateCalendarData(vm.obeserverId, vm.dateNavigation, vm.channel, event);

    const displayBackToInvoice = vm.calendarOptions.displayBackToInvoice;
    vm.displayBack = vm.dateNavigation.period == "hour" && (displayBackToInvoice !== undefined && displayBackToInvoice == true);
  }
}

export const gaCalendarNavigator = {
  name: 'gaCalendarNavigator',
  def: CalendarNavigator
}
