import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'any' })
export class NavStateService extends BehaviorSubject<boolean> {
	constructor() {
		super(window.innerWidth < 770);
	}

	toogle() {
		this.next(!this.value);
	}

	setHideState(event: boolean) {
		if (this.value == event) return;

		this.next(event);
	}
}
