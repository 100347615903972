import {
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { ColumnType } from '../model/datatable.model';
import { GaDataTableNewService } from '../service/datatable.service';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'ga-templates-cell',
	styleUrls: ['../ga-datatable-new.component.scss'],
	template: `
		<ng-container
			[ngTemplateOutlet]="getTemplateRef()"
			[ngTemplateOutletContext]="{ element: row, column: column }"
		></ng-container>

		<ng-template #textCellRef let-element="element" let-column="column">
			<p [ngClass]="{ 'error-field': findRowError(element, column) }">
				{{ element[column.name] || '-' }}
			</p>
		</ng-template>

		<ng-template #unitCellRef let-element="element" let-column="column">
			<ng-container *ngIf="element[column.name].unit; else helperRef">
				<div [innerHTML]="getSafeHtml(element[column.name].unit)"></div>
			</ng-container>
			<ng-template #helperRef>
				<ga-helper
					class="aling-center"
					[icon]="element[column.name].icon"
					[iconColor]="element[column.name].color || '#A3A3A3'"
					[text]="element[column.name].tooltip"
					[iconSize]="element[column.name].size"
					[tooltipLocale]="element[column.name].locale"
					style="margin-left: 3px"
				></ga-helper>
			</ng-template>
		</ng-template>

		<ng-template #validCellRef let-element="element" let-column="column">
			<i
				[ngClass]="
					element[column.name]
						? 'fa-solid fa-circle-x iconInvalid justify-center'
						: 'fa-solid fa-circle-check iconValid justify-center'
				"
			></i>
		</ng-template>

		<ng-template #selectLinkCellRef let-element="element" let-column="column">
			<ng-container *ngIf="element[column.name].length > 1; else linkRef">
				<ga-selector
					[placeholder]="
						element[column.name].length +
						' ' +
						element[column.name][0].placeholder?.toLowerCase()
					"
					[options]="element[column.name]"
					(onSelection)="selectLink($event)"
				></ga-selector>
			</ng-container>
			<ng-template #linkRef>
				<ng-container *ngIf="element[column.name].length === 0; else oneCell">
					<p>-</p>
				</ng-container>
				<ng-template #oneCell>
					<ga-link-cell
						[row]="element"
						[column]="column"
						(onSelection)="selectLink($event)"
					></ga-link-cell>
				</ng-template>
			</ng-template>
		</ng-template>

		<ng-template #selectCellRef let-element="element" let-column="column">
			<ng-container *ngIf="element[column.name].length > 1; else onlyRef">
				<ga-selector
					[placeholder]="
						element[column.name].length +
						' ' +
						element[column.name][0].placeholder.toLowerCase()
					"
					[options]="element[column.name]"
				></ga-selector>
			</ng-container>
			<ng-template #onlyRef>
				<ga-only-cell [row]="element" [column]="column"> </ga-only-cell>
			</ng-template>
		</ng-template>

		<ng-template #linkCellRef let-element="element" let-column="column">
			<ga-link-cell
				[row]="element"
				[column]="column"
				(onSelection)="selectLink($event)"
			></ga-link-cell>
		</ng-template>

		<ng-template #actionCellRef let-element="element" let-column="column">
			<ga-action-cell
				[row]="element"
				[column]="column"
				[tableId]="tableId"
			></ga-action-cell>
		</ng-template>

		<ng-template #colorCellRef let-element="element" let-column="column">
			<ga-color-cell [row]="element" [column]="column"></ga-color-cell>
		</ng-template>

		<ng-template #activeCellRef let-element="element" let-column="column">
			<active-cell
				*ngIf="element[column.name]"
				[row]="element"
				[column]="column"
			></active-cell>
		</ng-template>

		<ng-template
			#dropdownCustomCellRef
			let-element="element"
			let-column="column"
		>
			<ga-dropdown-custom-table
				[title]="'Silenciar por'"
				[elements]="element.reactivate"
				[toogleIcon]="element.active"
				[id]="element.id"
			></ga-dropdown-custom-table>
		</ng-template>
	`,
})
export class GATableTemplatesComponent {
	@ViewChild('textCellRef') textCellRef!: TemplateRef<any>;
	@ViewChild('validCellRef') validCellRef!: TemplateRef<any>;
	@ViewChild('buttonCellRef') buttonCellRef!: TemplateRef<any>;
	@ViewChild('customCellRef') customCellRef!: TemplateRef<any>;
	@ViewChild('actionCellRef') actionCellRef!: TemplateRef<any>;
	@ViewChild('activeCellRef') activeCellRef!: TemplateRef<any>;
	@ViewChild('selectCellRef') selectCellRef!: TemplateRef<any>;
	@ViewChild('selectLinkCellRef') selectLinkCellRef!: TemplateRef<any>;
	@ViewChild('linkCellRef') linkCellRef!: TemplateRef<any>;
	@ViewChild('dropdownCustomCellRef') dropdownCustomCellRef!: TemplateRef<any>;
	@ViewChild('colorCellRef') colorCellRef!: TemplateRef<any>;
	@ViewChild('unitCellRef') unitCellRef!: TemplateRef<any>;
	typesWithoutValue = ['action', 'valid'];
	@Input() column!: ColumnType;
	@Input() row: any;
	@Input() tableId!: number;

	constructor(
		private tableService: GaDataTableNewService,
		private sanitizer: DomSanitizer,
		private cdr: ChangeDetectorRef,
		@Inject(NG_ROUTER) private $state
	) {}

	ngAfterViewInit() {
		this.cdr.detectChanges();
	}

	getTemplateRef(): TemplateRef<any> {
		const templateRef = this.tableService.tableTypes[this.column.type]?.ref;
		if (
			!templateRef ||
			(!this.typesWithoutValue.includes(this.column.type) &&
				!this.row[this.column.name])
		) {
			return this.textCellRef;
		}
		return this[templateRef];
	}

	findRowError(element, column): boolean {
		const error = element.error?.find((e) => e.columnError === column.name);
		return !!error;
	}

	selectLink(event) {
		const params = event.params ? event.params : {};
		this.$state.transitionTo(event.link, params);
	}

	public getSafeHtml(content): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}
}
