// loader-interceptor.service.ts
import { Inject, Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { LoaderService } from '../global-loading/loader.service';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';

// *****************************************************************************
//                 THIS INTERCEPTOR IS TEMPORARILLY DISABLED
// *****************************************************************************
//
// It was decided to disable it in order to make the application feel
// more responsive.
// In many cases multiple requests happen in the background, and we often have
// to wait for all of them before we can click in anything in the screen
//
// To re-enable this interceptor, add the following line to 'index.ts' again
// { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
	private requests: HttpRequest<any>[] = [];
	private readonly WHITELISTED_ROUTES: string[] = ['main.dashboard.meter'];

	constructor(
		private loaderService: LoaderService,
		@Inject(NG_ROUTER) private $state
	) {}

	removeRequest(req: HttpRequest<any>) {
		const i = this.requests.indexOf(req);
		if (i >= 0) {
			this.requests.splice(i, 1);
		}
		if (this.loaderService.isLoading.getValue())
			this.loaderService.isLoading.next(this.requests.length > 0);
	}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (this.shouldWatchRequests()) {
			this.requests.push(req);
			this.loaderService.isLoading.next(true);
		}

		return new Observable((observer: Observer<any>) => {
			const subscription = next.handle(req).subscribe(
				(event) => {
					if (event instanceof HttpResponse) {
						this.removeRequest(req);
						observer.next(event);
					}
				},
				(err) => {
					this.removeRequest(req);
					observer.error(err);
				},
				() => {
					this.removeRequest(req);
					observer.complete();
				}
			);
			// remove request from queue when cancelled
			return () => {
				this.removeRequest(req);
				subscription.unsubscribe();
			};
		});
	}

	shouldWatchRequests(): boolean {
		const activeRoute = this.$state.current.name;
		if (this.WHITELISTED_ROUTES.includes(activeRoute)) {
			return false;
		} else {
			return true;
		}
	}
}
