import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class OrganizationFormService {
	form: FormGroup = new FormGroup({});

	initFormGroup(): FormGroup {
		this.form = new FormGroup({
			id: new FormControl(),
			name: new FormControl('', Validators.required),
			description: new FormControl(''),
			//users: new FormArray([]), /* add next release */
			roles: new FormControl('', Validators.required),
			contactUs: new FormControl('', Validators.required),
		});
		return this.form;
	}

	addOrgToForm(form: FormGroup, organization: any) {
		const { styles, ...org } = organization;
		form.patchValue({ ...org });
		return form;
	}

	addUser() {
		const user = new FormGroup({
			id: new FormControl('', Validators.required),
			name: new FormControl('', Validators.required),
			profile: new FormControl('', Validators.required),
		});

		this.usersForm.push(user);
		return this.usersForm;
	}

	removeUser(i: number) {
		if (this.usersForm.length === 1) {
			this.clearFormGroup(this.usersForm.at(i) as FormGroup);
		} else {
			this.usersForm.removeAt(i);
		}
	}

	private clearFormGroup(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach((key) => {
			formGroup.controls[key].setValue('');
		});
	}

	get usersForm() {
		return this.form.controls['users'] as FormArray;
	}
}
