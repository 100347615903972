import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
	AfterContentInit,
	ChangeDetectorRef,
	Component,
	ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'webapp/app/shared/confirm-dialog/confirm-dialog.component';
import { SnackService } from 'webapp/app/shared/snackbar/snackbar.service';

import { FormPlannedProjectComponent } from '../form/form-planned-project.component';
import { PlannedProjectsInfo } from '../general-info/planned-projects-info.component';
import { PlannedProject } from '../planned-project.model';
import { PlannedProjectService } from '../planned-projects.service';

export interface PreviewProjects {
	name: string;
	period: string;
	monthlyConsume: number;
}

@Component({
	selector: 'app-list-planned-projects',
	templateUrl: './list-planned-projects.component.html',
	styleUrls: ['./list-planned-projects.component.scss'],
})
export class ListPlannedProjectsComponent implements AfterContentInit {
	dataSource = new MatTableDataSource();
	filterPlannedProjects: PlannedProject[] = [];
	listPlannedProjects: PlannedProject[] = [];
	displayedColumns: string[] = [
		'name',
		'period',
		'plannedEnergySavings',
		'action',
	];

	@ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;
	@ViewChild(MatSort) sort: MatSort = {} as MatSort;

	constructor(
		public dialogRef: MatDialogRef<ListPlannedProjectsComponent>,
		public dialog: MatDialog,
		private _liveAnnouncer: LiveAnnouncer,
		public plannedProjectService: PlannedProjectService,
		public cdk: ChangeDetectorRef,
		private snackService: SnackService
	) {}

	ngAfterContentInit() {
		this.cdk.detectChanges();
		this.listPlannedProjects = this.plannedProjectService.listPlannedProjects;
		this.dataSource.data = this.listPlannedProjects;
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	announceSortChange(sortState: Sort) {
		if (sortState.direction) {
			this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
		} else {
			this._liveAnnouncer.announce('Sorting cleared');
		}
	}

	newPreviewProject() {
		this.dialogRef.close();
		this.dialog.open(FormPlannedProjectComponent, {
			width: '65%',
		});
	}

	backTo() {
		this.dialogRef.close();
		this.dialog.open(PlannedProjectsInfo, {
			width: '65%',
		});
	}

	search(event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	edit(element: PlannedProject) {
		this.dialogRef.close();
		this.dialog.open(FormPlannedProjectComponent, {
			data: element,
			width: '65%',
		});
	}

	remove(element) {
		const message = `Tem certeza que deseja excluir o projeto ${element.name}? 
    Esta ação não pode ser desfeita.`;
		const confirmDialog = {
			title: element.name,
			message,
		};
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: confirmDialog,
		});
		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult) {
				this.plannedProjectService.delete(<number>element.id).subscribe(
					() => {
						this.plannedProjectService.findAndRemove(element.id);
						this.dataSource.data = this.listPlannedProjects;
						const message = 'projeto previsto removido com sucesso';
						this.snackService.showSnackBar(message, 'success');
					},
					(erro) => {
						console.error(erro);
					}
				);
			}
		});
	}
}
