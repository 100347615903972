import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { map } from 'rxjs/operators';
import { CompanyParams } from 'webapp/app/dashboard/registrations/companies/models/company.model';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'root' })
export class CompanyService {
	constructor(private http: HttpClient) {}
	listCompanies: any[] = [];

	getCompanies(params = {}) {
		return this.http
			.get(`${environment.backendUrl}/api/companies/`, { params })
			.toPromise()
			.then((res: any) => {
				return res?.companies || [];
			});
	}

	__getCompanies(query: CompanyParams = {}) {
		let params = new HttpParams();
		params = query.organization
			? params.append('organization', query.organization)
			: params;
		params = query.agentClassId
			? params.append('agentClassId', query.agentClassId ?? '')
			: params;
		params = query.agentClassName
			? params.append('agentClassName', query.agentClassName ?? '')
			: params;
		params = query.populateCCEEProfile
			? params.append('populateCCEEProfile', query.populateCCEEProfile ?? '')
			: params;

		return this.http.get<any>(`${environment.backendUrl}/api/companies`, {
			params: params,
		});
	}

	getCompanyOld(id) {
		return this.http
			.get(`${environment.backendUrl}/api/companies/${id}`)
			.toPromise()
			.then((res: any) => {
				return res?.company;
			});
	}

	getCompany(id) {
		return this.http.get(`${environment.backendUrl}/api/companies/${id}`).pipe(
			map((res: any) => {
				return res?.company;
			})
		);
	}

	getCompanyTypes(params) {
		return this.http
			.get(`${environment.backendUrl}/api/companytypes`, { params: params })
			.toPromise()
			.then((res: any) => {
				return res?.companyTypes;
			});
	}

	getAgentCategories() {
		return this.http.get(`${environment.backendUrl}/api/agentCategories`).pipe(
			map((res: any) => {
				return res?.agentCategories;
			})
		);
	}

	getAgentClassesByCategory(categoryId: number) {
		return this.http
			.get(`${environment.backendUrl}/api/agentClasses/${categoryId}`)
			.pipe(
				map((res: any) => {
					return res?.agentClasses;
				})
			);
	}

	getBillings(params) {
		/*
      this if is for handle with fast clicks -
      need to improve when update module
    */
		if (params.organizationId.length === 0 || !params.organizationId)
			return Promise.all([]);

		return this.http
			.get(`${environment.backendUrl}/api/companies/billings`, {
				params: params || {},
			})
			.toPromise()
			.then((res: any) => {
				return res?.companies;
			});
	}

	getCompanyBilling(id, params) {
		return this.http
			.get(`${environment.backendUrl}/api/companies/${id}/billings`, {
				params: params,
			})
			.toPromise()
			.then((res: any) => {
				return res?.invoices;
			});
	}

	getCoverages(params) {
		return this.http
			.get(`${environment.backendUrl}/api/companies/profilebalances`, {
				params: params,
			})
			.toPromise()
			.then((res) => res);
	}

	getCompanyCoverages(params) {
		return this.http
			.get(
				`${environment.backendUrl}/api/companies/${params.companyId}/profilebalances`,
				{ params: params }
			)
			.toPromise()
			.then((res) => res);
	}

	createProfileItem(params) {
		return this.http
			.post(
				`${environment.backendUrl}/api/companies/${params.companyId}/profiles/${params.profileId}/items`,
				params
			)
			.toPromise()
			.then((res) => res);
	}

	updateProfileItem(params) {
		this.http
			.put(
				`${environment.backendUrl}/api/companies/${params.companyId}/profiles/${params.profileId}/items/${params.profileItemId}`,
				params
			)
			.toPromise()
			.then((res) => res);
	}

	deleteProfileItem(params) {
		return this.http
			.delete(
				`${environment.backendUrl}/api/companies/${params.companyId}/profiles/${params.profileId}/items/${params.profileItemId}`,
				{ params: params }
			)
			.toPromise()
			.then((res) => res);
	}

	saveCompany(params) {
		if (params.id) {
			return this.http
				.put(`${environment.backendUrl}/api/companies/` + params.id, params)
				.toPromise();
		} else {
			delete params.id;
			return this.http
				.post(`${environment.backendUrl}/api/companies`, params)
				.toPromise();
		}
	}

	deleteCompany(id: number) {
		return this.http.delete(`${environment.backendUrl}/api/companies/${id}`);
	}
}

export const ng2CompanyService = {
	name: CompanyService.name,
	def: downgradeInjectable(CompanyService),
};
