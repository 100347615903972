import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { StateParams, StateService } from 'angular-ui-router';
import { map, tap } from 'rxjs/operators';
import { Meter } from 'webapp/app/shared/models/meter.model';
import { DataSourceService } from 'webapp/app/shared/services/datasource.service';
import { KpiService } from 'webapp/app/shared/services/kpi.service';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { CreateKpi } from './create-kpi.model';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { TranslocoService } from '@ngneat/transloco';
import {
	SelectorGroup,
	SelectorOption,
} from 'webapp/app/shared/models/selector.model';
@Component({
	selector: 'app-kpi-detail',
	templateUrl: './kpi-detail.component.html',
	styleUrls: ['./kpi-detail.component.scss'],
})
export class KpiDetailComponent implements OnInit {
	isEdit = false;
	form!: FormGroup;
	organization: number | undefined;
	groupDataSources: Array<SelectorGroup> = [];
	optMetrics: Array<SelectorOption> = [];
	isOrgEnable = true;
	constructor(
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		@Inject(NG_ROUTER) private $state: StateService,
		private datasourceService: DataSourceService,
		private kpiService: KpiService,
		private notification: NotificationService,
		@Inject(TranslocoService) private i18n
	) {
		this.initFormGroup();
		this.addIndicator();
		this.editMode();
	}

	ngOnInit(): void {
		if (this.isEdit) {
			this.getKpi(+this.$stateParams?.id);
		}
	}

	populateEditForm(kpi) {
		this.form.patchValue({
			id: kpi.id,
			organization: kpi.organization,
			dataSources: kpi.dataSources.map((ds) => ds.id),
			indicators: [
				{
					metric: kpi.divisor?.id ?? kpi.dividend.id,
					goal: kpi.goal,
				},
			],
		});
	}

	getKpi(id: number) {
		this.kpiService.getKpi(id).subscribe((kpi: any) => {
			this.populateEditForm(kpi);
		});
	}

	initFormGroup() {
		const obj = {
			id: new FormControl(null),
			organization: new FormControl('', Validators.required),
			dataSources: new FormControl([], Validators.required),
			indicators: new FormArray([]),
		};

		this.form = new FormGroup(obj);
	}

	get indicatorsFormArray() {
		return this.form.controls['indicators'] as FormArray;
	}

	onSubmit() {
		const id = +this.$stateParams?.id;
		const kpis = this.formTokpiObj();
		!this.isEdit ? this.createKpi(kpis) : this.updateKpi(id, kpis);
	}

	createKpi(kpis) {
		this.kpiService.createKpi(kpis).subscribe((res) => {
			this.stateGo('main.analysis.kpi', {});
			this.notification.success({
				msg: this.i18n.translate('kpi.msg-create-success'),
			});
		});
	}

	stateGo(route, params) {
		params = {
			...params,
			tab: 'kpi',
		};
		this.$state.transitionTo(route, params);
	}

	updateKpi(id: number, kpis) {
		this.kpiService.updateKpi(id, kpis[0]).subscribe((res) => {
			this.stateGo('main.analysis.kpi', {});
			this.notification.success({
				msg: this.i18n.translate('kpi.msg-update-success'),
			});
		});
	}

	getDivisor(metricId: number): number | null {
		const findMetric = this.kpiService.metrics.find((m) => m.id == +metricId);
		if (findMetric?.dividend) {
			return null;
		}
		return findMetric?.id ?? null;
	}

	formTokpiObj() {
		const formValue = this.form.value;
		const kpis: CreateKpi[] = [];
		for (const indicator of formValue.indicators) {
			const divisor = this.getDivisor(indicator.metric);
			kpis.push({
				dataSources: this.form.controls['dataSources'].value,
				goal: indicator.goal,
				period: 'monthly',
				dividend: 1,
				divisor,
				KPIOperationType: divisor ? 1 : 2,
				organizationId: <number>this.organization,
			});
		}
		return kpis;
	}

	addIndicator() {
		const fgIndicators = new FormGroup({
			id: new FormControl(null),
			metric: new FormControl('', [Validators.required]),
			goal: new FormControl(0),
		});
		this.indicatorsFormArray.push(fgIndicators);
	}

	removeContact(i: number) {
		if (this.indicatorsFormArray.length === 1) {
			const contact = this.indicatorsFormArray.at(i) as FormGroup;
			Object.keys(contact.controls).forEach((key) => {
				contact.controls[key].setValue('');
			});
		} else {
			this.indicatorsFormArray.removeAt(i);
		}
	}

	editMode() {
		this.isEdit = this.$stateParams?.id ? true : false;
		if (this.isEdit) {
			this.isOrgEnable = false;
			this.form.controls['dataSources']?.disable();
			this.indicatorsFormArray.controls[0].get('metric')?.disable();
		}
	}

	backTo() {
		this.$state.transitionTo('main.analysis.kpi', {
			tab: 'kpi',
		});
	}
	orgSelected(event) {
		if (!event) return;
		this.organization = <number>event.id;
		this.form.controls['organization'].setValue(this.organization);
		this.listDataSources(this.organization);
		this.listMetrics(this.organization);
	}

	listDataSources(organizationId: number) {
		if (!organizationId) return;
		this.datasourceService
			.$getDataSources({
				organizationId,
			})
			.pipe(
				tap(
					(res: Meter[]) =>
						(this.groupDataSources = this.selectorGroupFactory(res))
				)
			)
			.subscribe();
	}

	listMetrics(organizationId: number) {
		this.kpiService
			.$getConstants(organizationId)
			.pipe(
				map((res) => {
					this.kpiService.metrics = res;
					const tMetrics = this.kpiService.transformMetrics(res);
					return [
						this.kpiService.buildMetric(tMetrics.dividend, null),
						...tMetrics.divisor.map((d) =>
							this.kpiService.buildMetric(tMetrics.dividend, d)
						),
					];
				})
			)
			.subscribe((res) => {
				const options = res.map((m) => ({
					label: m.label,
					id: m.id,
				}));
				this.optMetrics = options;
			});
	}

	getMetricAddon(idIndicator: number) {
		const metric = this.kpiService.metrics.find((m) => m.id == idIndicator);

		if (!metric || !metric.unit || metric.type == 'consumption') return 'kWh';
		return `kWh/${metric.unit}`;
	}

	selectorGroupFactory(arr: Array<any>) {
		const group: SelectorGroup[] = [];
		arr.forEach((element) => {
			const foundOrg = group.find((org) => org.id == element.organizationId);

			if (foundOrg && foundOrg.options) {
				foundOrg.options.push({
					id: element.id,
					label: element.label,
					icon: element.uid ? 'fa-circle-bolt' : 'fa-draw-circle',
					subtext: element.uid
						? `${this.i18n.translate('global.code')}: ${element.uid}`
						: '',
				});
			} else {
				group.push({
					label: element.organizationName as string,
					id: element.organizationId as number,
					disabled: false,
					options: [
						{
							id: element.id,
							label: element.label,
							icon: element.uid ? 'fa-circle-bolt' : 'fa-draw-circle',
							subtext: element.uid
								? `${this.i18n.translate('global.code')}: ${element.uid}`
								: '',
						},
					],
				});
			}
		});

		return group.sort((a, b) => this.compareDesc(a, b, 'name'));
	}

	compareDesc(a, b, field) {
		if (a[field] > b[field]) {
			return -1;
		}
		if (a[field] < b[field]) {
			return 1;
		}
		return 0;
	}
}

export const ng2KpiDetailComponent = {
	name: 'ng2KpiDetail',
	def: downgradeComponent({
		component: KpiDetailComponent,
		propagateDigest: true,
	}),
};
