import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { StateParams, StateService } from 'angular-ui-router';
import { EMPTY, Subscription, from } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Metadata } from 'webapp/app/shared/models/consuming-unit.model';
import { Credentials } from 'webapp/app/shared/models/credentials.model';
import {
	DashboardDistributor,
	Distributor,
	DistributorUrl,
} from 'webapp/app/shared/models/distributor.model';
import { Meter } from 'webapp/app/shared/models/meter.model';
import {
	SelectorGroup,
	SelectorOption,
} from 'webapp/app/shared/models/selector.model';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { CredentialsService } from 'webapp/app/shared/services/credentials.service';
import { DataSourceService } from 'webapp/app/shared/services/datasource.service';
import { EnergyBillService } from 'webapp/app/shared/services/energy-bill.service';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';

@Component({
	selector: 'consuming-unit-details',
	templateUrl: './consuming-unit-details.component.html',
	styleUrls: ['./consuming-unit-details.component.scss'],
})
export class ConsumingUnitDetailsComponent implements OnInit, OnDestroy {
	organization: number | undefined;
	acquisitionMode: Array<SelectorOption> = [];
	isSaving = false;
	credentials: Array<Credentials> = [];
	filteredCredentials: Array<SelectorOption> = [];
	dataSources: Array<Meter> = [];
	groupDataSources: Array<SelectorGroup> = [];
	distributors: Array<Distributor> = [];
	dashboardDistributors: Array<DashboardDistributor> = [];
	selectedDistributorUrl: DistributorUrl | undefined = undefined;
	distributorsOptions: Array<SelectorOption> = [];
	distributorsURLS: Array<SelectorOption> = [];
	consumingUnit: Metadata = {} as Metadata;
	metadataForm!: FormGroup;
	subscriptions: Subscription[] = [];

	constructor(
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(TranslocoService) private i18n,
		private credentialsService: CredentialsService,
		private dataSourceService: DataSourceService,
		private energyBillService: EnergyBillService,
		private notificationService: NotificationService
	) {
		this.organization = this.$stateParams.organizationId;
		this.initFormGroup();
	}

	ngOnInit(): void {
		const promises: Array<Promise<void>> = [];

		promises.push(this.loadCredentials());
		promises.push(this.loadDistributors());
		promises.push(this.loadDashboardDistributors());
		Promise.all(promises).then(() => {
			this.loadAcquisitionMode();
			this.loadConsumingUnit();
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subs) => subs.unsubscribe());
	}

	loadAcquisitionMode(distributor: Distributor | null = null) {
		const hasRobotApi = !!distributor;
		this.acquisitionMode = [
			{
				id: 'upload',
				label: this.i18n.translate('consuming-unit.acquisition.upload'),
			},
		];
		if (hasRobotApi)
			this.acquisitionMode.push({
				id: 'automatic',
				label: this.i18n.translate('consuming-unit.acquisition.automatic'),
			});
		if (
			!hasRobotApi &&
			this.metadataForm.controls['acquisitionMode'].value !== 'upload'
		) {
			this.metadataForm.controls['acquisitionMode'].setValue('upload');
			this.changeAcquisition('upload');
		}
	}

	initFormGroup() {
		const group: any = {
			organization: new FormControl(''),
			name: new FormControl(''),
			utility: new FormControl(''),
			installNumber: new FormControl(''),
			dataSource: new FormControl(''),
			cnpj: new FormControl(''),
			acquisitionMode: new FormControl(''),
			distributorCredential: new FormControl(''),
			newCredentialName: new FormControl(''),
			newCredentialURL: new FormControl(''),
			fieldOne: new FormControl(''),
			fieldTwo: new FormControl(''),
			fieldThree: new FormControl(''),
			startdate: new FormControl(''),
		};
		this.metadataForm = new FormGroup(group);
	}

	loadCredentials() {
		return this.credentialsService
			.getCredentials()
			.then((credentials: Credentials[]) => {
				this.credentials = credentials;
				this.filterCredentials();
			});
	}

	loadDataSources() {
		return this.dataSourceService
			.getDataSources({ organizationId: this.organization })
			.then((dataSources: Meter[]) => {
				this.dataSources = dataSources;
				this.groupDataSources = this.selectorGroupFactory(dataSources);
			});
	}

	loadDistributors() {
		return this.credentialsService
			.getDistributors()
			.then((distributors: Distributor[]) => {
				distributors?.forEach(this.normalizeDistributorUrlName);
				this.distributors = distributors;
			});
	}

	loadDashboardDistributors() {
		return this.credentialsService
			.getDashboardDistributors()
			.then((distributors: DashboardDistributor[]) => {
				this.dashboardDistributors = distributors;
				this.distributorsOptions = distributors.map((dtb) => ({
					id: dtb.name,
					label: dtb.name,
				}));
			});
	}

	loadConsumingUnit() {
		if (!this.isEditMode()) return;

		const { metadata, id } = this.$stateParams;
		if (!metadata) {
			this.energyBillService
				.getEnergyBillMetadata(id)
				.then((foundMetadata: Metadata) => {
					this.consumingUnit = foundMetadata;
					this.prepareConsumigUnitForEdit(foundMetadata);
				});
		} else {
			this.consumingUnit = metadata;
			metadata.distributorCredential =
				metadata.distributorCredential?.id || metadata.distributorCredential;
			this.prepareConsumigUnitForEdit(metadata);
		}
	}

	prepareConsumigUnitForEdit(metadata: Metadata) {
		Object.keys(this.metadataForm.controls).forEach((key) => {
			if (metadata[key])
				this.metadataForm.controls[key].setValue(metadata[key]);
		});

		const dataSource = metadata.dataSource;
		if (dataSource) {
			this.orgSelected({
				id: dataSource.organization,
				name: 'organizationName',
			});
			this.metadataForm.controls['dataSource'].setValue(dataSource.id);
		} else {
			this.metadataForm.controls['dataSource'].setValue(undefined);
		}

		if (metadata.acquisitionMode !== 'automatic')
			this.metadataForm.controls['acquisitionMode'].setValue('upload');
		this.organization = metadata.organization;
	}

	orgSelected(event: { id: number; name: string }) {
		if (this.organization === event?.id) return;
		this.organization = event?.id;
		this.metadataForm.controls['organization'].setValue(this.organization);
		this.loadDataSources();
		this.checkSelectedDatasourceOrg();
		this.formDisable(!this.organization);
		this.filterCredentials();
	}

	checkSelectedDatasourceOrg() {
		if (!this.metadataForm.controls['dataSource'].value) return;
		const datasource = this.dataSources.find(
			(ds) => ds.id === this.metadataForm.controls['dataSource'].value
		);

		if (!datasource) this.metadataForm.controls['dataSource'].setValue(null);
	}

	formDisable(value: boolean) {
		if (value) {
			this.metadataForm.disable();
		} else {
			this.metadataForm.enable();
		}
	}

	normalizeDistributorUrlName(distributor) {
		distributor.urls = distributor.urls.map((urlFields) => {
			if (!urlFields.nome_url) {
				urlFields.nome_url = urlFields.url;
			}
			return urlFields;
		});
		return distributor;
	}

	isEditMode(): boolean {
		const { id, metadata } = this.$stateParams;

		const hasIdParam = id && id != '';
		const hasMetadataParam = metadata && metadata != '';
		return hasMetadataParam || hasIdParam;
	}

	backTo() {
		this.$state.transitionTo(
			'registrations',
			{ tab: 'consumingUnits', organizationId: this.organization },
			{
				inherit: false,
				reload: false,
				notify: false,
			}
		);
	}

	changeDatasources(event) {
		if (!event) {
			this.metadataForm.controls['cnpj'].setValue('');
			return;
		}
		const dataSource = this.dataSources.find((ds) => ds.id == event);
		if (dataSource) {
			this.metadataForm.controls['organization'].setValue(
				dataSource.organizationId
			);
			this.organization = dataSource.organizationId as number;
			this.filterCredentials();
		}
	}

	changeAcquisition(event) {
		switch (event) {
		case 'upload':
			this.metadataForm.controls['distributorCredential'].setValue(null);
			this.metadataForm.controls['distributorCredential'].clearValidators();
			this.clearNewCredentialValidator();
			break;
		default:
			return;
		}
		Object.keys(this.metadataForm.controls).forEach((key) => {
			this.metadataForm.controls[key].updateValueAndValidity();
		});
	}

	changeCredentials(event) {
		switch (true) {
		case event !== 'new' && this.distributorsURLS.length === 1:
			this.metadataForm.controls['newCredentialURL'].setValue(
				this.distributorsURLS[0].id
			);
			this.metadataForm.controls['distributorCredential'].value == event;
			this.changeDistributorURL(this.distributorsURLS[0].id);
			return;
		case event === 'new':
			this.metadataForm.controls['newCredentialURL'].setValue(null);
			return;
		default:
			this.clearNewCredentialValidator();
		}
	}

	clearNewCredentialValidator() {
		const credentials = [
			'newCredentialName',
			'newCredentialURL',
			'fieldOne',
			'fieldTwo',
			'fieldThree',
		];
		credentials.forEach((field) => {
			this.metadataForm.controls[field].setValue(null);
			this.metadataForm.controls[field].clearValidators();
		});
		this.changeDistributorURL(undefined);

		Object.keys(this.metadataForm.controls).forEach((key) => {
			this.metadataForm.controls[key].updateValueAndValidity();
		});
	}

	changeDistributor(event) {
		const dashboardDistributor = this.dashboardDistributors.find(
			(d) => d.name?.toLowerCase() === event.toLowerCase()
		);
		const distributor = this.distributors.find(
			(d) => d.cnpj === dashboardDistributor?.cnpj
		);
		this.loadAcquisitionMode(distributor);

		if (!distributor) return;
		this.distributorsURLS =
			distributor.urls?.map((objURL) => ({
				id: objURL.url,
				label: objURL.nome_url ? objURL.nome_url : objURL.url,
			})) || [];
		this.metadataForm.controls['newCredentialURL'].setValue(null);
		this.changeDistributorURL(undefined);
		if (this.distributorsURLS.length === 1) {
			this.metadataForm.controls['newCredentialURL'].setValue(
				this.distributorsURLS[0].id
			);
			this.changeDistributorURL(this.distributorsURLS[0].id);
		}
		this.filterCredentials();
	}

	changeDistributorURL(event) {
		this.selectedDistributorUrl = undefined;
		this.distributors.forEach((dist) => {
			const distributorUrl = dist.urls?.find((url) => url.url === event);
			if (distributorUrl) this.selectedDistributorUrl = distributorUrl;
		});
	}

	filterCredentials() {
		this.filteredCredentials = this.credentials
			.filter((cred) => {
				return (
					cred.organization === this.organization &&
					cred.distributor.toLowerCase() ===
						this.metadataForm.controls['utility'].value.toLowerCase()
				);
			})
			.map((cred) => {
				const option = {
					id: cred.id,
					label: cred.name,
				};
				return option;
			});
		if (this.metadataForm.controls['distributorCredential'].value) {
			const findCredential = this.filteredCredentials.find(
				(cred) =>
					cred.id === this.metadataForm.controls['distributorCredential'].value
			);
			if (!findCredential)
				this.metadataForm.controls['distributorCredential'].setValue(null);
		}
		this.filteredCredentials.unshift({
			id: 'new',
			label: this.i18n.translate('consuming-unit.credential.new'),
		});
	}

	onSubmit() {
		this.isSaving = true;

		const consumingUnit = this.preparePayload();

		const subscription = from(
			this.isEditMode()
				? this.energyBillService.updateBillMetadata(
					this.$stateParams.id,
					consumingUnit
				  )
				: this.energyBillService.createBillMetadata(consumingUnit)
		)
			.pipe(
				tap(() => {
					this.notificationService.success({
						msg: this.i18n.translate('consuming-unit-list.notify.save-success'),
					});
					this.backTo();
				}),
				catchError((error) => {
					if (error.message) {
						this.notificationService.error({
							msg: this.i18n.translate('consuming-unit-list.notify.save-error'),
						});
					} else {
						this.notificationService.showErrorMessages(error);
					}
					return EMPTY;
				}),
				finalize(() => (this.isSaving = false))
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	preparePayload() {
		const consumingUnit = { credentials: {} };

		const credentialFields = this.metadataForm.value.newCredentialName
			? {
				newCredentialName: 'name',
				newCredentialURL: 'distributorUrl',
				fieldOne: 'fieldOne',
				fieldTwo: 'fieldTwo',
				fieldThree: 'fieldThree',
			  }
			: {};
		let data = '';
		for (const key of Object.keys(this.metadataForm.value)) {
			switch (true) {
			case key === 'distributorCredential' &&
					this.metadataForm.controls[key].value == 'new':
				continue;
			case key === 'startdate': {
				data =
						this.metadataForm.controls[key].value &&
						typeof this.metadataForm.controls[key].value !== 'string'
							? this.metadataForm.controls[key].value.toISOString()
							: this.metadataForm.controls[key].value;
				consumingUnit[key] = data ? data.slice(0, 10) : null;
				continue;
			}
			case Object.keys(credentialFields).includes(key):
				consumingUnit.credentials[credentialFields[key]] =
						this.metadataForm.controls[key].value;
				continue;
			default:
				consumingUnit[key] = this.metadataForm.controls[key].value;
			}
		}

		return consumingUnit;
	}

	selectorGroupFactory(arr: Array<any>) {
		const group: SelectorGroup[] = [];
		arr.forEach((element) => {
			const foundOrg = group.find((org) => org.id == element.organizationId);

			if (foundOrg && foundOrg.options) {
				foundOrg.options.push({
					id: element.id,
					label: element.label,
					icon: element.uid ? 'fa-circle-bolt' : 'fa-draw-circle',
					subtext: element.uid
						? `${this.i18n.translate('global.code')}: ${element.uid}`
						: '',
				});
			} else {
				group.push({
					label: element.organizationName as string,
					id: element.organizationId as number,
					disabled: false,
					options: [
						{
							id: element.id,
							label: element.label,
							icon: element.uid ? 'fa-circle-bolt' : 'fa-draw-circle',
							subtext: element.uid
								? `${this.i18n.translate('global.code')}: ${element.uid}`
								: '',
						},
					],
				});
			}
		});

		return group.sort((a, b) => this.compareDesc(a, b, 'name'));
	}

	compareDesc(a, b, field) {
		if (a[field] > b[field]) {
			return -1;
		}
		if (a[field] < b[field]) {
			return 1;
		}
		return 0;
	}
}

export const ng2ConsumingUnitDetailsComponent = {
	name: 'consumingUnitDetails',
	def: downgradeComponent({
		component: ConsumingUnitDetailsComponent,
		propagateDigest: true,
	}),
};
