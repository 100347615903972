import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { ColumnType } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { GASelectorLink } from 'webapp/app/shared/ga-datatable-new/model/ga-link.model';
import { environment } from 'webapp/environments/environment';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class ConsumingUnitListService {
	constructor(private http: HttpClient, private i18n: TranslocoService) {}
	organization = 0;

	listConsumingUnits(params: any = {}): Observable<any> {
		params.populateBills = 'false';
		if (params.organization) this.organization = params.organization;
		return this.http.get(`${environment.backendUrl}/api/energyBillMetadatas`, {
			params: params,
		});
	}

	renderListToTable(metadatas) {
		return metadatas.map((md) => {
			const row = {} as any;

			row.name = this.renderMetaData(md);
			row.dataSource = this.renderDataSource(md);
			row.distributor = md.utility;
			row.acquisitionMode = this.getAcquisitionMode(md.acquisitionMode);
			row.distributorCredential = md.distributorCredential?.name || '';
			row.action = this.getActions(md);
			return row;
		});
	}

	renderDataSource(metadata): GASelectorLink[] {
		const dropdownList: GASelectorLink[] = [];
		const dropdownObj: GASelectorLink = {} as GASelectorLink;

		if (!metadata.dataSource)
			return this.i18n.translate('consuming-unit-list.table.type-independent');

		dropdownObj.label = metadata.dataSource.label;
		dropdownObj.value = metadata.dataSource.id;
		if (metadata.dataSource.uid)
			dropdownObj.subtext = `${this.i18n.translate('consuming-unit.code')}: ${
				metadata.dataSource.uid
			}`;

		dropdownObj.link = this.isMeter(metadata.dataSource)
			? 'main.dashboard.meter'
			: 'main.dashboard.group';

		dropdownObj.params = this.isMeter(metadata.dataSource)
			? { uid: metadata.dataSource.id }
			: { id: metadata.dataSource.id };
		dropdownList.push(dropdownObj);

		return dropdownList;
	}

	isMeter(dataSource): boolean {
		return dataSource.type == 'meter';
	}

	renderMetaData(metadata): GASelectorLink[] {
		const dropdownList: GASelectorLink[] = [];
		const dropdownObj: GASelectorLink = {} as GASelectorLink;

		const year = moment().format('YYYY');
		dropdownObj.label = metadata.name || metadata.clientName;
		if (metadata.installNumber)
			dropdownObj.subtext = `${this.i18n.translate(
				'consuming-unit.installation'
			)}: ${metadata.installNumber}`;
		dropdownObj.value = metadata.id;
		dropdownObj.link = 'main.energy-bills-list';
		dropdownObj.params = {
			metadataId: metadata.id,
			referenceYear: year,
			//metadata: uc,
			organizationId: this.organization,
		};
		dropdownList.push(dropdownObj);

		return dropdownList;
	}

	get columns(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('consuming-unit-list.table.name'),
				name: 'name',
				type: 'link',
				sortBy: (item: any, property: string) => item[property][0].label,
				width: '20%',
			},
			{
				label: this.i18n.translate('consuming-unit-list.table.data-source'),
				name: 'dataSource',
				tooltip: this.i18n.translate('consuming-unit-list.table.meter-tooltip'),
				sortBy: (item: any, property: string) => item[property][0].label,
				type: 'link',
				width: '20%',
			},
			{
				label: this.i18n.translate('consuming-unit.distributor'),
				name: 'distributor',
				type: 'text',
				width: '20%',
			},
			{
				label: this.i18n.translate('consuming-unit-list.table.ingestion-type'),
				name: 'acquisitionMode',
				type: 'text',
				width: '20%',
			},
			{
				label: this.i18n.translate('consuming-unit-list.table.credentials'),
				name: 'distributorCredential',
				type: 'text',
				width: '20%',
			},
			{
				label: '',
				name: 'action',
				type: 'action',
				width: '45px',
			},
		];
	}

	getActions(metadata): Action[] {
		const actions: Action[] = [
			{
				icon: 'fa-edit',
				title: this.i18n.translate('meter-page.button.edit'),
				type: 'edit',
				permission: 'energyBillUpdate',
				row: metadata,
			},
			{
				icon: 'fa-trash',
				title: this.i18n.translate('meter-page.button.exclude'),
				type: 'delete',
				permission: 'energyBillDelete',
				row: metadata,
			},
		];

		return actions;
	}

	getAcquisitionMode(acquisitionMode) {
		switch (acquisitionMode) {
			case 'upload':
				return this.i18n.translate(
					'consuming-unit-list.table.acquisition.upload'
				);
			case 'automatic':
				return this.i18n.translate(
					'consuming-unit-list.table.acquisition.automatic'
				);
			default:
				return this.i18n.translate(
					'consuming-unit-list.table.acquisition.upload'
				);
		}
	}

	filterTable(data: any, filterText: any) {
		const filter = filterText.toLowerCase();
		return (
			data.acquisitionMode.toLowerCase().includes(filter) ||
			data.distributor.toLowerCase().includes(filter) ||
			data.distributorCredential.toLowerCase().includes(filter) ||
			data.name.some((n) => n.label.toLowerCase().includes(filter)) ||
			(Array.isArray(data.dataSource)
				? data.dataSource.some((n) => n.label.toLowerCase().includes(filter))
				: data.dataSource.toLowerCase().includes(filter))
		);
	}
}
