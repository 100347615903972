import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import {
	NG_HTTP,
	NG_ROUTER,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { environment } from 'webapp/environments/environment';
import { AuthService } from 'webapp/app/auth/auth.service';

interface GET {
	url: string;
	method?: string;
	params?: any;
	headers?: {
		[key: string]: string;
	};
	responseType?: string;
	cache?: true;
	ttl?: number;
}

interface POST {
	url: string;
	data?: any;
	method?: string;
	params?: any;
	headers?: {
		[key: string]: string;
	};
	responseType?: string;
}

interface PUT {
	url: string;
	data?: any;
	method?: string;
	params?: string;
}

interface DELETE {
	url: string;
	method?: string;
	params?: string;
}

/**
 * @deprecated Procure usar o 'HttpClient' do Angular 12
 */
@Injectable({ providedIn: 'root' })
export class HttpUtil {
	constructor(
		@Inject(NG_HTTP) private $http,
		private authService: AuthService,
		@Inject(NG_ROUTER) private $state: any
	) {}

	private getCompleteUrl(endpoint) {
		return `${environment.backendUrl}${endpoint}`;
	}

	private httpCall(params): Promise<any> {
		if (!params.url.includes('http')) {
			params.url = environment.backendUrl + params.url;
		}
		return this.$http(params)
			.then((data) => data)
			.catch((error) => {
				if (error.status === 401) {
					this.authService.logout();
					this.$state.go('login');
				} else {
					throw error;
				}
			});
	}

	/**
	 * @deprecated Procure usar o 'HttpClient' do Angular 12
	 */
	get(params: GET) {
		params.method = 'GET';
		return this.httpCall(params);
	}

	/**
	 * @deprecated Procure usar o 'HttpClient' do Angular 12
	 */
	post(params: POST) {
		params.method = 'POST';
		return this.httpCall(params);
	}

	/**
	 * @deprecated Procure usar o 'HttpClient' do Angular 12
	 */
	postData(endpoint, dataParam, data, params) {
		const url = this.getCompleteUrl(endpoint);
		const formData = new FormData();
		data.forEach((datum) => {
			formData.append(dataParam, datum, datum.name);
		});

		return this.$http.post(url, formData, {
			headers: { 'Content-Type': undefined },
			params: params,
		});
	}

	/**
	 * @deprecated Procure usar o 'HttpClient' do Angular 12
	 */
	del(params: DELETE) {
		params.method = 'DELETE';
		return this.httpCall(params);
	}

	/**
	 * @deprecated Procure usar o 'HttpClient' do Angular 12
	 */
	put(params: PUT) {
		params.method = 'PUT';
		return this.httpCall(params);
	}
}

export const ngHttpUtil = {
	name: HttpUtil.name,
	def: downgradeInjectable(HttpUtil),
};
