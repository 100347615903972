<div class="notify-container">
	<i class="fa-regular info" [ngClass]="[data.icon]"></i>
	<p>{{data.msg }}</p>
	<span class="spacer"></span>
	<i
		class="fa-regular fa-times"
		(click)="ref.dismiss()"
		style="cursor: pointer"
	></i>
</div>
