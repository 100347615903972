import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'root' })
export class MeasurementService {
	localTotalCache = [];

	constructor(private http: HttpClient) {}

	measurements(params) {
		if (params.groupId) {
			return this.getGroupMeterMeasurements(params);
		} else {
			if (params.type && params.type == 'invoice') {
				return this.http
					.get(
						`${environment.backendUrl}/api/meters/${params.meterId}/measurements/total`,
						{ params: params }
					)
					.toPromise()
					.then((res: any) => res?.measurement);
			} else {
				return this.http
					.get(
						`${environment.backendUrl}/api/meters/${params.meterId}/measurements`,
						{ params: params }
					)
					.toPromise()
					.then((res: any) => res?.measurement);
			}
		}
	}

	getGroupMeterMeasurements(params) {
		if (params.type && params.type == 'invoice') {
			return this.http
				.get(
					`${environment.backendUrl}/api/groups/${params.groupId}/datasources/${params.meterId}/measurements/total`,
					{ params: params }
				)
				.toPromise()
				.then((res: any) => res?.measurement);
		} else {
			return this.http
				.get(
					`${environment.backendUrl}/api/groups/${params.groupId}/datasources/${params.meterId}/measurements`,
					{ params: params }
				)
				.toPromise()
				.then((res: any) => res?.measurement);
		}
	}

	getForecasts(params) {
		if (params.type && params.type == 'invoice') {
			return this.http
				.get(
					`${environment.backendUrl}/api/meters/${params.meterId}/forecasts/total`,
					{ params: params }
				)
				.toPromise()
				.then((res: any) => res?.measurement)
				.catch((err) => {
					return err;
				});
		} else {
			return this.http
				.get(
					`${environment.backendUrl}/api/meters/${params.meterId}/forecasts`,
					{ params: params }
				)
				.toPromise()
				.then((res: any) => res?.measurement)
				.catch((err) => {
					return err;
				});
		}
	}

	getForecastsTotal(params) {
		return this.http
			.get(
				`${environment.backendUrl}/api/meters/${params.meterId}/forecasts/total`,
				{ params: params }
			)
			.toPromise()
			.then((res: any) => res?.measurement)
			.catch((err) => {
				return err;
			});
	}

	getFits(params) {
		if (params.type && params.type == 'invoice') {
			return this.http
				.get(
					`${environment.backendUrl}/api/meters/${params.meterId}/fits/total`,
					{ params: params }
				)
				.toPromise()
				.then((res: any) => res?.measurement)
				.catch((err) => {
					return err;
				});
		} else {
			return this.http
				.get(`${environment.backendUrl}/api/meters/${params.meterId}/fits`, {
					params: params,
				})
				.toPromise()
				.then((res: any) => res?.measurement)
				.catch((err) => {
					return err;
				});
		}
	}

	powers(params) {
		if (params.meterId) {
			return this.http
				.get(`${environment.backendUrl}/api/meters/${params.meterId}/powers`, {
					params: params,
				})
				.toPromise()
				.then((resp: any) => {
					return { powers: resp?.powers, contracts: resp?.contracts };
				});
		}
	}

	powersGroup(params) {
		if (params.dataSourceId) {
			return this.http
				.get(
					`${environment.backendUrl}/api/groups/${params.dataSourceId}/powers`,
					{ params: params }
				)
				.toPromise()
				.then((resp: any) => {
					return { powers: resp?.powers, contracts: resp?.contracts };
				});
		}
	}

	total(params) {
		if (params.groupId) {
			return this.getGroupMeterTotal(params);
		} else {
			let key = '';
			if (params.period && params.startDate && params.endDate) {
				key =
					params.meterId +
					'_' +
					params.period +
					'_' +
					params.type +
					'_' +
					params.startDate +
					'_' +
					params.endDate;
			} else if (params.period) {
				key = params.meterId + '_' + params.period + '_' + params.type;
			} else {
				key = params.meterId + '_' + params.startDate + '_' + params.endDate;
			}

			//this.localTotalCache[key] != undefined && moment().isBefore(this.localTotalCache[key].date)
			if (
				this.localTotalCache[key] != undefined &&
				moment().isBefore(this.localTotalCache[key].date)
			) {
				return new Promise((resolve, reject) => {
					resolve(angular.copy(this.localTotalCache[key].res));
				});
			} else {
				return this.http
					.get(
						`${environment.backendUrl}/api/meters/${params.meterId}/measurements/total`,
						{ params: params }
					)
					.toPromise()
					.then((res) => {
						this.localTotalCache[key] = {
							res: angular.copy(res),
							date: moment().add(5, 'minutes'), //cache time
						};
						return res;
					});
			}
		}
	}
	getGroupMeterTotal(params) {
		let key = '';
		if (params.period && params.startDate && params.endDate) {
			key =
				params.meterId +
				'_' +
				params.period +
				'_' +
				params.type +
				'_' +
				params.startDate +
				'_' +
				params.endDate;
		} else if (params.period) {
			key = params.meterId + '_' + params.period + '_' + params.type;
		} else {
			key = params.meterId + '_' + params.startDate + '_' + params.endDate;
		}

		return this.http
			.get(
				`${environment.backendUrl}/api/groups/${params.groupId}/datasources/${params.meterId}/measurements/total`,
				{ params: params }
			)
			.toPromise()
			.then((res) => {
				this.localTotalCache[key] = {
					res: angular.copy(res),
					date: moment().add(5, 'minutes'), //cache time
				};
				return res;
			});
	}

	getDemand(params) {
		if (!params.meterId || !params.period) throw new Error();
		return this.http
			.get(`${environment.backendUrl}/api/meters/${params.meterId}/demand`, {
				params: params,
			})
			.toPromise()
			.then((resp) => {
				return resp;
			});
	}
	getReactiveSurplus(params) {
		if (
			params.meterId &&
			(params.period || (params.startDate && params.endDate))
		) {
			return this.http
				.get(
					`${environment.backendUrl}/api/meters/${params.meterId}/reactive`,
					{ params: params }
				)
				.toPromise()
				.then((resp) => {
					return resp;
				});
		}
	}
}

export const ng2MeasurementService = {
	name: MeasurementService.name,
	def: downgradeInjectable(MeasurementService),
};
