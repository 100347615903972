import { AuthService } from 'webapp/app/auth/auth.service';

RedirectController.$inject = ['$state', '$timeout', '$transition$', 'AuthService'];

/* @ngInject */
function RedirectController($state, $timeout, $transition$, AuthService: AuthService) {
	var vm = this;

	$timeout(function () {
		let token = $transition$.params().token;
		let url = $transition$.params().url;
		if (token) {
			let user = { token: token };
			AuthService.auth(user).then(function (data) {
				AuthService.setRedirectUrlCookie(url)
				$state.transitionTo('main.dashboard');
			});
		}
	});
}

export const ngRedirectControler = {
	name: RedirectController.name,
	def: RedirectController
}

