import {
	Directive,
	EventEmitter,
	HostBinding,
	HostListener,
	Output,
} from '@angular/core';

@Directive({
	selector: '[dragNDrop]',
})
export class DragNDropDirective {
	@HostBinding('class.fileover') fileOver = false;

	@Output() fileDropped: EventEmitter<any> = new EventEmitter();

	@HostListener('dragover', ['$event']) onDragOver(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.fileOver = true;
	}

	@HostListener('dragleave', ['$event']) public onDragLeaver(evt) {
		evt.preventDefault();
		evt.stopPropagation();
	}

	@HostListener('drop', ['$event']) public onDrop(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.fileOver = false;
		const files = evt.dataTransfer.files;
		if (files.length > 0) {
			this.fileDropped.emit(files);
		}
	}
}
