<div class="content">
	<div class="description">
		<div class="description__texts">
			<span class="description__texts__title">{{
				"contract.label.compositions" | transloco
			}}</span>
			<span class="description__texts__subtitle">{{
				"contract.label.compositions-sub" | transloco
			}}</span>
		</div>

		<div
			class="btn-toolbar description__action-buttons"
			role="toolbar"
			*wchHasFeature="'contractCreate'"
		>
			<ga-button-dropdown
				[label]="'composition-list.new.btn-label' | transloco"
				[icon]="'fa-layer-group'"
				[actionOptions]="actionButtons"
				(action)="newAction($event, null)"
			></ga-button-dropdown>
		</div>
	</div>

	<div class="row col-lg-12 m-0 p-0">
		<autocomplete-orgs
			[idSelected]="organizationId"
			(orgEmitter)="orgSelected($event)"
		></autocomplete-orgs>
	</div>
	<div class="row col-lg-12 m-0 p-0 dataTable">
		<div *ngIf="!organizationId" class="no-organization">
			<ga-tag-icon [icon]="'error'" [size]="22"></ga-tag-icon>
			<span class="message">
				{{ "composition-list.table.no-organization" | transloco }}
			</span>
		</div>
		<ga-datatable-new
			[ngClass]="{ hidden: !organizationId }"
			[tableName]="'Compositions'"
			[element$]="displayEl$"
			(onAction)="doAction($event)"
			[showSubcolumTitle]="true"
			(rowExpand)="onRowExpand($event)"
		>
			<ng-container emptyTitle>
				<h3 class="empty-table">
					{{ "composition-list.table.no-content" | transloco }}
				</h3>
			</ng-container>
			<ng-container emptySubtitle>
				<h4 *ngIf="isOrgAdmin" class="empty-table__tips">
					<span>
						{{ "composition-list.table.no-content-sub1" | transloco }}
						{{ "composition-list.table.no-content-sub2" | transloco }}
					</span>
					<span>
						{{ "composition-list.table.no-content-sub3" | transloco }}
					</span>
				</h4>
				<h4 *ngIf="!isOrgAdmin" class="empty-table__tips">
					<span>
						{{ "composition-list.table.no-content-sub4" | transloco }}
					</span>
					<span>
						{{ "composition-list.table.no-content-sub5" | transloco }}
					</span>
				</h4>
			</ng-container>
		</ga-datatable-new>
	</div>
</div>
