import * as angular from 'angular';

/* @ngInject */
function DateInput() {

    var dateInput = {
        restrict: 'E',
        templateUrl: '/app/directives/date-input/date-input.html',
        scope: {
            includehour: "<",
            model: "=",
            label: "@",
            disabled: "=",
            orientation: "@"
        },
        controller: DateInputController,
        controllerAs: 'dateVm',
        bindToController: true
    };

    return dateInput;
}

DateInputController.$inject = ['Auxiliar'];

function DateInputController(Auxiliar) {
    const vm = this;
    vm.$onInit = onInit;
    vm.auxiliar = Auxiliar;

    function onInit() {
        let lang = 'br';
        const orientation = vm.orientation || 'auto';
        if (vm.includehour == true) {
            vm.format = "DD/MM/YYYY - HH:MM";
            $('.date-input-input').datetimepicker({
                language: lang,
                format: "dd/mm/yyyy - hh:ii",
                autoclose: true,
                orientation: orientation
            });
            vm.auxiliar.applyDateTimeMask('.date-input-input');
        }
        else {
            vm.format = "DD/MM/YYYY";
            $('.date-input-input').datepicker({
                language: lang,
                format: "dd/mm/yyyy",
                autoclose: true,
                orientation: orientation
            });
            vm.auxiliar.applyDateMask('.date-input-input');
        }

        if (isValidDate(vm.model, vm.includehour)) {
            if (!vm.includehour) {
                $('#' + vm.directiveId + ' .date-input-input').datepicker('update', vm.model);
            }
        }
    }
    function isValidDate(s, hastime) {
        if (s) {
            var bits = s.split('/');
            if (hastime) {
                var aux = bits[2].split(' - ');
                bits.pop(2);
                bits.push(aux[0]);
                aux = aux[1].split(':');
                bits.push(aux[0]);
                bits.push(aux[1]);
                if (bits.length == 5) {
                    var d = new Date(bits[2], bits[1], bits[0], bits[3], bits[4], 0, 0);
                    return !!(d && (d.getMonth() + 1) == bits[1] && d.getDate() == Number(bits[0]));
                }
            }
            else {
                var d = new Date(bits[2] + '/' + bits[1] + '/' + bits[0]);
                return !!(d && (d.getMonth() + 1) == bits[1] && d.getDate() == Number(bits[0]));
            }
        }
        else {
            return false;
        }
    }
}

angular
    .module('dashboard-directives')
    .directive('gaDateInput', DateInput);