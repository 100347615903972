<div class="dialog">
	<div class="panel-heading" mat-dialog-title>
		<button type="button" class="close" (click)="dialogRef.close()">
			<span aria-hidden="true">&times;</span>
			<span class="sr-only">Close</span>
		</button>
		<h4 class="modal-title">Economia acumulada</h4>
	</div>
	<div class="modal-body" mat-dialog-content>
		<app-my-loader [lightMode]="true"></app-my-loader>
		<div class="row" style="margin-bottom: 10px">
			<div class="col-lg-6">
				<div class="form-group">
					<ga-multi-selector
						title="Escolha uma instalação ou compare duas instalações"
						[optionsList]="installList"
						[limit]="2"
						[marked]="installationSelected"
						(selectEmitter)="changedInstallation($event)"
						[tooltip]="
							'Se o projeto tiver mais de vinte instalações, ao somá-las, somente o consumo setorial será exibido.'
						"
					></ga-multi-selector>
				</div>
			</div>
			<div class="col-lg-6 flex">
				<div class="col-flex">
					<label for="installations">Período</label>
					<app-date-range
						[startDate]="this.csService.period.startDate.toJSDate()"
						[isAutoEndDate]="true"
						(selectedDate)="changedPeriod($event)"
					></app-date-range>
				</div>
				<div class="col-flex">
					<button
						class="btn btn-default btn-color-ga"
						style="margin-top: 25px"
						(click)="generateChart()"
					>
						{{ "projects.monthly-consume" | transloco }}
					</button>
				</div>
			</div>
		</div>
		<div class="row graphic">
			<div class="col-md-12 text-center" style="margin-bottom: 20px">
				<mat-button-toggle-group
					#group="matButtonToggleGroup"
					[value]="selectedConsumptionType"
					(change)="changeType(group.value)"
				>
					<mat-button-toggle value="value">R$</mat-button-toggle>
					<mat-button-toggle value="consumption">kWh</mat-button-toggle>
				</mat-button-toggle-group>
			</div>
			<div class="col-md-12" style="text-align: -webkit-center">
				<ng-container *ngIf="chartOptions && !isCompare">
					<highcharts-chart
						id="chart"
						[Highcharts]="highcharts"
						[options]="chartOptions"
					>
					</highcharts-chart>
				</ng-container>
				<ng-container *ngIf="compareChartOptions && isCompare">
					<highcharts-chart
						id="chart"
						[Highcharts]="compareHighcharts"
						[options]="compareChartOptions"
					>
					</highcharts-chart>
				</ng-container>
			</div>
		</div>
	</div>
</div>
