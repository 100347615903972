import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GADropdownCustom } from '../model/dropdown-custom.model';
import { GaDataTableNewService } from '../service/datatable.service';

@Component({
	selector: 'ga-dropdown-custom-table',
	styles: [
		`
			::ng-deep .mat-menu-content:not(:empty) {
				padding-top: 0px !important;
				padding-bottom: 0px !important;
			}
			.title {
				font-family: 'Roboto';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 19px;
				color: var(--grayscale---gray-700);
				padding: 14px 14px 10px 14px;
				margin-bottom: 0;
			}
			.dropdown {
				text-align: center;
			}
			i {
				cursor: pointer;
			}
			i.bell.active {
				color: var(--primary-color);
			}
			i.bell {
				position: absolute;
				width: 27.5px;
				height: 27.5px;
				left: calc(50% - 27.5px / 2);
				top: calc(50% - 27.5px / 2);
				font-weight: 400;
				font-size: 24px;
				line-height: 24px;
				display: flex;
				align-items: center;
				text-align: center;
				color: var(--grayscale---gray-400);
			}
			i.arrow {
				width: 17.5px;
				height: 17.5px;
				font-weight: 900;
				font-size: 14px;
				line-height: 14px;
				margin-left: 35px;
				color: var(--grayscale---gray-500);
			}
			.group {
				display: flex;
				flex-direction: column;
				padding: 0 14px;
			}
			::ng-deep .dropdown-menu-right {
				box-shadow: 0 4px 8px 0 rgba(212, 212, 212, 0.5) !important;
			}
			::ng-deep.group .mat-radio-container {
				width: 14px;
				height: 14px;
			}

			::ng-deep.group .mat-radio-outer-circle {
				border: 1px solid var(--grayscale---gray-400) !important;
				width: 14px;
				height: 14px;
			}

			::ng-deep.group .mat-radio-button.mat-accent .mat-radio-inner-circle {
				background-color: var(--grayscale---basic--white);
				width: 14px;
				height: 14px;
			}
			::ng-deep.group
				.mat-radio-button.mat-accent.mat-radio-checked
				.mat-radio-outer-circle {
				background-color: var(--primary-color);
			}
			::ng-deep.group mat-radio-button label {
				color: var(--grayscale---gray-500);
				font-size: 14px;
				font-weight: 400;
				margin-bottom: 8px;
			}
			hr {
				margin-top: 2px;
				margin-bottom: 0px;
				border: 0;
				border-top: 1px solid var(--grayscale---gray-200);
			}

			.btns {
				display: flex;
				justify-content: center;
				justify-content: center;
				align-items: center;
				padding: 14px;
				gap: 8px;
			}

			.txt-btn {
				font-family: 'Roboto';
				font-style: normal;
				font-weight: 700;
				font-size: 14px;
				line-height: 19px;
			}
		`,
	],

	template: `
		<div class="dropdown" *wchHasFeature="elements">
			<div class="align-icon">
				<!-- Reactivate Alert -->
				<ng-container *ngIf="toogleIcon">
					<i class="fa-regular fa-bell bell active"> </i>
					<i class="fa-solid fa-sort-down arrow" [matMenuTriggerFor]="menu">
					</i>
				</ng-container>
				<ng-container *ngIf="!toogleIcon">
					<i class="fa-regular fa-bell-slash bell" (click)="onActivated()"> </i>
				</ng-container>
			</div>
			<mat-menu
				#menu="matMenu"
				class="menu dropdown-menu-right "
				xPosition="before"
			>
				<p class="title">
					{{ title }}
				</p>
				<mat-radio-group (click)="$event.stopPropagation()" class="group">
					<mat-radio-button
						[disableRipple]="true"
						[value]="item.value"
						*ngFor="let item of elements"
						(change)="onSelectionChange(item)"
						>{{ item.label }}</mat-radio-button
					>
				</mat-radio-group>

				<hr />
				<div class="btns" (click)="$event.stopPropagation()">
					<button
						class="btn btn-color-ga txt-btn"
						(click)="apply()"
						style="width: 100%;"
					>
						Aplicar
					</button>
				</div>
			</mat-menu>
		</div>
	`,
})
export class DropdownCustomCellComponent {
	@Input() title = '';
	@Input() elements: any[] = [];
	@Input() toogleIcon = true;
	@Input() id = 0;
	@Output() emitDropdownEvent: EventEmitter<GADropdownCustom> =
		new EventEmitter();

	itemSelected!: GADropdownCustom;

	constructor(private dataTable: GaDataTableNewService) {}

	onSelectionChange(item: GADropdownCustom) {
		item.isActive = false;
		this.itemSelected = item;
	}

	apply() {
		this.dataTable.onDropdownChanged(this.itemSelected);
		this.toogleIcon = !this.toogleIcon;
	}

	onActivated() {
		const obj: GADropdownCustom = {} as GADropdownCustom;
		obj.id = this.id;
		obj.isActive = true;
		this.dataTable.onDropdownChanged(obj);
		this.toogleIcon = !this.toogleIcon;
	}
}
