import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormFieldBaseDirective } from '../directive/form-field-base';
import { Size } from './ga-toogle.model';
@Component({
	selector: 'ga-toggle',
	templateUrl: './ga-toggle.component.html',
	styleUrls: ['./ga-toggle.component.scss'],
})
export class GaToggleComponent extends FormFieldBaseDirective {
	@Input() size: Size = 'default';
	@Input() subtext!: string;
	@Input() icon!: string;
	@Output() toggle = new EventEmitter();
	@Input() checked = false;

	emitChange(event) {
		this.toggle.emit(event.checked);
	}
}
