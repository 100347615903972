import { Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'ga-tag-icon',
	templateUrl: './ga-tag-icon.component.html',
	styleUrls: ['./ga-tag-icon.component.scss'],
})
export class GaTagIconComponent {
	@Input() type = 'default';
	@Input() icon = 'info';
	@Input() size = 20;

	get selectIcon() {
		switch (this.icon) {
		case 'upload':
			return 'fa-cloud-arrow-up';
		case 'change':
			return 'fa-arrows-repeat';
		case 'play-pause':
			return 'fa-play-pause';
		case 'success':
			return 'fa-circle-check';
		case 'info':
			return 'fa-circle-question';
		case 'warning':
			return 'fa-triangle-exclamation';
		case 'error':
			return 'fa-circle-exclamation';
		case 'notification':
			return 'fa-bell';
		case 'wifi':
			return 'fa-wifi';
		default:
			return 'fa-circle-question';
		}
	}
}

export const ng2GaTagIconComponent = {
	name: 'gaTagIcon',
	def: downgradeComponent({
		component: GaTagIconComponent,
		propagateDigest: true,
	}),
};
