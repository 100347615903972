class WelcomeController {
  constructor() {}

  $onInit() {}
}

const welcomeComponent = {
  templateUrl: '/app/directives/welcome/welcome.html',
  controller: WelcomeController,
  controllerAs: 'wVm',
  bindings: {
    title: '@',
    usage: '@',
    usageDetail: '@',
    tips: '@',
    active: '=',
  },
};

export const ngGaWelcome = {
  name: 'gaWelcome',
  def: welcomeComponent
}
