import { NgModule } from '@angular/core';
import { ConsumptionPipe } from './consumption.pipe';
import { InspectionDayPipe } from './inspection-day.pipe';
import { SanitizeHtmlPipe } from './sanitizer.pipe';

@NgModule({
	declarations: [ConsumptionPipe, SanitizeHtmlPipe, InspectionDayPipe],
	imports: [],
	exports: [ConsumptionPipe, SanitizeHtmlPipe, InspectionDayPipe],
})
export class GAPipesModule {}
