import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { EnergyBillService } from '../../services/energy-bill.service';

@Component({
	selector: 'autocomplete-ucs',
	templateUrl: './autocomplete-ucs.component.html',
	styleUrls: ['./autocomplete-ucs.component.scss'],
})
export class AutocompleteUcsComponent implements OnInit, OnChanges {
	@Output() ucSelected: EventEmitter<any> = new EventEmitter();
	@Input() onlyInstallNumber = false;
	@Input() filters: any;
	@Input() ucId: any;
	@Input() ucInstallNumber: any;
	@Input() organizationId: any;
	listUcs: any[] = [];
	isErrorInput = false;

	filteredUcs$: Observable<any[]> = new Observable();
	ucInput: FormControl = new FormControl('', [Validators.required]);

	constructor(private energyBillService: EnergyBillService) {}

	ngOnInit(): void {
		this.registerUCInputOnChangeEvent();
	}

	ngOnChanges({ ucInstallNumber }: SimpleChanges): void {
		this.ucInput.setValue(ucInstallNumber.currentValue);
	}

	private registerUCInputOnChangeEvent() {
		this.ucInput.valueChanges
			.pipe(
				debounceTime(1000),
				tap(() => (this.listUcs = [])),
				filter((installNumber) => installNumber != this.ucInstallNumber),
				filter((installNumber) => installNumber.length >= 3)
			)
			.subscribe((installNumber: string) => {
				const params: any = {
					installNumber: installNumber,
				};
				Object.assign(params, this.filters);

				if (this.organizationId) {
					params.organization = this.organizationId;
				}
				this.energyBillService
					.getObservableEnergyBills(params)
					.subscribe(({ energyBillMetadatas }: any) => {
						this.listUcs = energyBillMetadatas;
						this.checkSelected();
					});
			});
	}

	findUc(value: number | string) {
		return this.listUcs.find(
			(uc) => uc.id == value || uc.installNumber == value
		);
	}

	getOptionSelected($event: MatAutocompleteSelectedEvent) {
		this.isErrorInput = false;
		const uc = this.findUc($event.option.value);
		this.ucSelected.emit(uc);
	}

	checkSelected() {
		if (this.ucId || this.ucInstallNumber) {
			const uc = this.findUc(this.ucId) || this.findUc(this.ucInstallNumber);
			if (uc) {
				this.ucSelected.emit(uc);
			}
		}
	}
}
