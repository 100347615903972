import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'webapp/environments/environment.homolog';
import { PlannedProject } from './planned-project.model';

@Injectable({
	providedIn: 'root',
})
export class PlannedProjectService {
	baseUrl = '/api/plannedProjects';
	private organizationId: number | undefined;
	listPlannedProjects: PlannedProject[] = [];

	constructor(private http: HttpClient) {}

	getAll(params?: any): Observable<PlannedProject[]> {
		return this.http.get<any>(environment.backendUrl + this.baseUrl, {
			params: params,
		});
	}

	post(plannedProject: any): Observable<PlannedProject> {
		return this.http.post<any>(
			environment.backendUrl + this.baseUrl,
			plannedProject
		);
	}

	put(project: PlannedProject): Observable<PlannedProject> {
		return this.http.put<PlannedProject>(
			environment.backendUrl + this.baseUrl + `/${project.id}`,
			project
		);
	}

	getById(id: number): Observable<PlannedProject> {
		return this.http.get<PlannedProject>(
			environment.backendUrl + this.baseUrl + `${id}`
		);
	}

	delete(id: number): Observable<any> {
		return this.http.delete<any>(
			environment.backendUrl + this.baseUrl + `/${id}`
		);
	}

	setOrganization(organizationId): void {
		this.organizationId = organizationId;
	}

	getOrganization(): number {
		return <number>this.organizationId;
	}

	findAndRemove(id) {
		const index = this.listPlannedProjects.findIndex(
			(planned) => planned.id == id
		);
		if (index !== -1) this.listPlannedProjects.splice(index, 1);
	}
}
