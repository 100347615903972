import { AfterContentInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from '@angular/material/dialog';
import { OrganizationService } from 'webapp/app/shared/services/organization.service';
import { SnackService } from 'webapp/app/shared/snackbar/snackbar.service';
import { PlannedProjectsInfo } from '../general-info/planned-projects-info.component';
import { PlannedProject } from '../planned-project.model';
import { PlannedProjectService } from '../planned-projects.service';

@Component({
	selector: 'app-form-preview-project',
	templateUrl: './form-planned-project.component.html',
	styleUrls: ['./form-planned-project.component.scss'],
})
export class FormPlannedProjectComponent implements OnInit, AfterContentInit {
	organization = '';
	plannedProjectForm: FormGroup = new FormGroup({
		name: new FormControl('', { validators: [Validators.required] }),
		startDate: new FormControl('', { validators: [Validators.required] }),
		endDate: new FormControl('', { validators: [Validators.required] }),
		plannedEnergySavings: new FormControl('', {
			validators: [Validators.required],
		}),
		plannedMonetarySavings: new FormControl('', {
			validators: [Validators.required],
		}),
	});

	constructor(
		public dialogRef: MatDialogRef<FormPlannedProjectComponent>,
		public dialog: MatDialog,
		private plannedProjectService: PlannedProjectService,
		private snackService: SnackService,
		private organizationService: OrganizationService,
		@Inject(MAT_DIALOG_DATA) public data: PlannedProject
	) {}

	getOrganizationById() {
		this.organizationService
			.getOrganization(this.plannedProjectService.getOrganization())
			.then((organization: any) => {
				this.organization = organization.id + '-' + organization.name;
			});
	}

	ngOnInit(): void {
		const organization = this.plannedProjectService.getOrganization();
		this.plannedProjectForm.addControl(
			'organization',
			new FormControl(organization)
		);
		if (this.data?.id) {
			this.setEditValues();
		}
	}

	ngAfterContentInit() {
		setTimeout(() => {
			this.getOrganizationById();
		}, 0);
	}

	backTo() {
		this.dialogRef.close();
		this.dialog.open(PlannedProjectsInfo, {
			width: '65%',
		});
	}

	onSubmit() {
		if (this.data?.id) {
			this.edit();
		} else {
			this.create();
		}
	}

	private create() {
		const plannedProject: PlannedProject = <PlannedProject>(
			this.plannedProjectForm.value
		);
		this.plannedProjectService.post(plannedProject).subscribe(
			(resp: PlannedProject) => {
				this.plannedProjectService.listPlannedProjects.push(resp);
				const message = 'projeto previsto cadastrado com sucesso';
				this.snackService.showSnackBar(message, 'success');
				this.backTo();
			},
			(erro) => {
				const message = 'alguma deu erro. Tente mais tarde';
				this.snackService.showSnackBar(message, 'danger');
				console.error(erro);
			}
		);
	}

	private edit() {
		const plannedProject: PlannedProject = <PlannedProject>(
			this.plannedProjectForm.value
		);
		plannedProject.id = this.data.id;
		this.plannedProjectService.put(plannedProject).subscribe(
			() => {
				this.plannedProjectService.findAndRemove(plannedProject.id);
				this.plannedProjectService.listPlannedProjects.unshift(plannedProject);
				const message = 'projeto previsto modificado com sucesso';
				this.snackService.showSnackBar(message, 'success');
				this.backTo();
			},
			(erro) => {
				const message = 'alguma deu erro. Tente mais tarde';
				this.snackService.showSnackBar(message, 'danger');
				console.error(erro);
			}
		);
	}

	setEditValues() {
		this.plannedProjectForm;
		this.plannedProjectForm.controls['name'].setValue(this.data.name);
		this.plannedProjectForm.controls['startDate'].setValue(
			new Date(this.data.startDate).toISOString().split('T')[0]
		);
		this.plannedProjectForm.controls['endDate'].setValue(
			new Date(this.data.endDate).toISOString().split('T')[0]
		);
		this.plannedProjectForm.controls['plannedEnergySavings'].setValue(
			this.data.plannedEnergySavings
		);
	}
}
