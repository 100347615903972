import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { StateParams, StateService } from 'angular-ui-router';
import { EMPTY, Subscription, from } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import {
	GaContentDialogComponent,
	GaContentDialogModel,
} from 'webapp/app/shared/ga-dialog/ga-content-dialog.component';
import {
	Distributor,
	DistributorUrl,
} from 'webapp/app/shared/models/distributor.model';
import { SelectorOption } from 'webapp/app/shared/models/selector.model';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { CredentialsService } from 'webapp/app/shared/services/credentials.service';
import { EnergyBillService } from 'webapp/app/shared/services/energy-bill.service';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';

@Component({
	selector: 'app-credential-details',
	templateUrl: './credential-details.component.html',
	styleUrls: ['./credential-details.component.scss'],
})
export class CredentialDetailsComponent implements OnInit, OnDestroy {
	organization: number | undefined;
	credentialId: number | undefined;
	credentialName = '';
	distributors: Distributor[] = [];
	distributorsNames: SelectorOption[] = [];
	subscriptions: Subscription[] = [];
	currentDistributor!: Distributor;
	currentUrl!: DistributorUrl;
	urlOptions: SelectorOption[] = [];
	installNumbers = [];
	initialInstallNumbers: string[] = [];
	credentialForm!: FormGroup;
	accessId: number | undefined;
	isBasicDataFilled = false;

	constructor(
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		private credentialService: CredentialsService,
		private energyService: EnergyBillService,
		private dialog: MatDialog,
		private i18n: TranslocoService,
		private notification: NotificationService
	) {
		this.credentialId = this.$stateParams.id;
	}

	ngOnInit() {
		this.initFormGroup();
		this.loadDistributors();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	loadCredential() {
		if (!this.credentialId) return;

		const subscription = from(
			this.energyService.getPresentationBills({
				populateDataSources: true,
				organization: this.organization,
			})
		)
			.pipe(
				switchMap((installNumbers) => {
					this.installNumbers = installNumbers.map(this.toPresentation);
					return this.credentialService.getCredential(this.credentialId);
				}),
				switchMap((credential) => {
					this.credentialForm.patchValue({
						name: credential.name,
						id: credential.id,
						distributorName: credential.distributor,
					});
					this.onChangeDistributor();

					this.credentialForm.controls['installNumbers'].setValue(
						credential.energyBillMetadatas.map((energy) => energy.installNumber)
					);

					this.initialInstallNumbers =
						this.credentialForm.controls['installNumbers'].value;

					this.credentialForm.controls['installNumbers'].clearValidators();

					this.credentialName = this.credentialForm.controls['name'].value;

					this.accessId = credential.robotApiData.accessIds[0];

					return this.credentialService.getCredentialAccess(this.accessId);
				}),
				tap((access) => {
					this.credentialForm.patchValue({
						distributorUrlId: access.distribuidora_url.id_url,
						fieldOne: access.campo_1,
						fieldTwo: access.campo_2,
						fieldThree: access.campo_3,
					});
				}),
				catchError(() => {
					this.notification.error({ msg: 'Erro ao carregar credencial.' });
					return EMPTY;
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	initFormGroup() {
		const credential = {
			id: new FormControl(),
			name: new FormControl('', Validators.required),
			organization: new FormControl('', Validators.required),
			distributorName: new FormControl('', Validators.required),
			distributorUrlId: new FormControl('', Validators.required),
			distributorUrl: new FormControl('', Validators.required),
			installNumbers: new FormControl([], Validators.required),
			fieldOne: new FormControl(null),
			fieldTwo: new FormControl(null),
			fieldThree: new FormControl(null),
		};

		this.credentialForm = new FormGroup(credential);

		this.onChangeBasicData();

		this.onChangeUrl();
	}

	onChangeBasicData() {
		const subscription = this.credentialForm.valueChanges.subscribe(() => {
			if (
				this.credentialForm.controls['name'].valid &&
				this.credentialForm.controls['organization'].valid &&
				this.credentialForm.controls['distributorName'].valid &&
				this.credentialForm.controls['installNumbers'].valid &&
				this.credentialForm.controls['distributorUrlId'].valid
			) {
				this.isBasicDataFilled = true;
			} else {
				this.isBasicDataFilled = false;
			}
		});
		this.subscriptions.push(subscription);
	}

	onChangeUrl() {
		const subscription = this.credentialForm.controls[
			'distributorUrlId'
		].valueChanges.subscribe((url) => {
			const currentUrl = this.currentDistributor.urls.find(
				(item) => item.id_url === url
			);

			if (currentUrl !== undefined) this.currentUrl = currentUrl;

			this.credentialForm.patchValue({ distributorUrl: this.currentUrl.url });
		});
		this.subscriptions.push(subscription);
	}

	onChangeDistributor() {
		const distributorName =
			this.credentialForm.controls['distributorName'].value;
		const distributor = this.distributors.find(
			(distributor) => distributor.nome === distributorName
		);
		if (distributor !== undefined) this.currentDistributor = distributor;
		this.urlOptions = this.currentDistributor.urls.map((url) => {
			return {
				label: url.url,
				id: url.id_url,
			};
		});
		if (this.urlOptions.length === 1)
			this.credentialForm.controls['distributorUrlId'].setValue(
				this.urlOptions[0].id
			);
	}

	loadDistributors() {
		this.credentialService
			.getDistributors()
			.then((distributors) => {
				this.distributors = distributors;
				this.distributorsNames = distributors.map(
					(distributor: Distributor) => ({
						id: distributor.nome,
						label: distributor.nome,
					})
				);
			})
			.catch(() => {
				this.notification.error({
					msg: this.i18n.translate(
						'credentials-details.notify.distributors.error'
					),
				});
			});
	}

	toPresentation(item) {
		return {
			id: `${item.installNumber}`,
			label: item.clientName || item.name,
			subtext: item.installNumber,
			disabled: !!item.distributorCredential,
		};
	}

	loadEnergyBillMetadatas() {
		this.energyService
			.getPresentationBills({
				populateDataSources: true,
				organization: this.organization,
			})
			.then((res) => {
				this.installNumbers = res.map(this.toPresentation);
			})
			.catch(() => {
				this.notification.error({
					msg: this.i18n.translate(
						'credentials-details.notify.consuming-units.error'
					),
				});
			});
	}

	orgSelected(event: { id: number; name: string }) {
		this.organization = event?.id;
		this.credentialForm.controls['organization'].setValue(this.organization);

		if (!this.organization) return;

		if (this.credentialId) {
			this.loadCredential();
		} else {
			this.loadEnergyBillMetadatas();
		}
	}

	onSubmit() {
		if (this.credentialId) {
			if (this.credentialForm.controls['installNumbers'].value.length === 0) {
				this.removeDialog(this.credentialId);
				return;
			}

			this.updateCredential();
		} else {
			this.createCredential();
		}
	}

	createCredential() {
		this.credentialService
			.createCredentials(this.credentialForm.value)
			.then(() => {
				this.notification.success({
					msg: this.i18n.translate(
						'credentials-details.notify.credential.success'
					),
				});
				this.$state.transitionTo('registrations', {
					tab: 'credentials',
				});
			})
			.catch((error) => {
				this.notification.showErrorMessages(error);
			});
	}

	updateCredential() {
		const installsToAdd = this.credentialForm.controls[
			'installNumbers'
		].value.filter((item) => !this.initialInstallNumbers.includes(item));
		const installsToRemove = this.initialInstallNumbers.filter(
			(item) =>
				!this.credentialForm.controls['installNumbers'].value.includes(item)
		);

		const credentials = {
			distributorName: this.credentialForm.controls['distributorName'].value,
			distributorUrlId: this.credentialForm.controls['distributorUrlId'].value,
			distributorUrl: this.credentialForm.controls['distributorUrl'].value,
			fieldOne: this.credentialForm.controls['fieldOne'].value,
			fieldThree: this.credentialForm.controls['fieldThree'].value,
			fieldTwo: this.credentialForm.controls['fieldTwo'].value,
			installsToAdd,
			installsToRemove,
		};

		const distributorCredential = {
			distributor: this.credentialForm.controls['distributorName'].value,
			name: this.credentialForm.controls['name'].value,
			organization: this.credentialForm.controls['organization'].value,
		};

		this.credentialService
			.updateCredential(this.credentialId, {
				credentials,
				distributorCredential,
			})
			.then(() => {
				this.notification.success({
					msg: this.i18n.translate(
						'credentials-details.notify.credential.success'
					),
				});
				this.$state.transitionTo('registrations', {
					tab: 'credentials',
				});
			})
			.catch((error) => {
				this.notification.showErrorMessages(error);
			});
	}

	cancel() {
		this.$state.transitionTo('registrations', {
			tab: 'credentials',
		});
	}

	backTo() {
		this.$state.transitionTo(
			'registrations',
			{ tab: 'credentials' },
			{
				inherit: false,
				reload: true,
				notify: false,
			}
		);
	}

	removeDialog(id: number) {
		const confirmDialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		confirmDialogModel.icon = 'warning';
		confirmDialogModel.iconType = 'warning';
		confirmDialogModel.title = this.i18n.translate(
			'credentials-details.modal-delete.title'
		);
		confirmDialogModel.message = this.i18n.translate(
			'credentials-details.modal-delete.message'
		);
		confirmDialogModel.btnError = true;
		const dialogRef = this.dialog.open(GaContentDialogComponent, {
			data: confirmDialogModel,
			width: '407px',
		});
		const subscription = dialogRef.componentInstance.confirm
			.pipe()
			.subscribe((dialogResult) => {
				if (dialogResult) {
					const subscription = this.credentialService
						.deleteCredentials(id)
						.subscribe(() => {
							this.notification.success({
								msg: this.i18n.translate(
									'credentials-list.table.msg-delete-success'
								),
							});
							this.$state.transitionTo('registrations', {
								tab: 'credentials',
							});
						});
					this.subscriptions.push(subscription);
				}
				dialogRef.close();
			});
		this.subscriptions.push(subscription);
	}
}

export const ng2CredentialDetailsComponent = {
	name: 'credentialDetails',
	def: downgradeComponent({
		component: CredentialDetailsComponent,
		propagateDigest: true,
	}),
};
