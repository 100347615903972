import * as angular from 'angular';
import { ng2AuthService } from 'webapp/app/auth/auth.service';
import { ng2GroupService } from 'webapp/app/shared/services/group.service';
import { ng2MeasurementService } from 'webapp/app/shared/services/measurement.service';
import { ng2MeterService } from 'webapp/app/shared/services/meter.service';
import { ng2CalendarService } from 'webapp/app/shared/services/calendar.service';
import { ng2ContractService } from 'webapp/app/shared/services/contract.service';
import { ngCharts } from 'webapp/app/shared/services/charts-service';
import { ng2DatasourceService } from 'webapp/app/shared/services/datasource.service';
import { ng2EnergyBillService } from 'webapp/app/shared/services/energy-bill.service';
import { ng2ExportService } from 'webapp/app/dashboard/exporter/services/export.service';
import { ng2UploadService } from 'webapp/app/shared/services/upload.service';
import { ng2TagService } from 'webapp/app/shared/services/tag.service';
import { ng2MixPanelService } from 'webapp/app/shared/services/mixpanel.service';
import { ngUserService } from 'webapp/app/shared/services/user.service';
import { ng2LocationService } from 'webapp/app/shared/services/location.service';

const DashboardServices = angular.module('dashboard-services', []);
//angular.module('dashboard-services').service(ng2OrganizationService.name, ng2OrganizationService.def);
angular.module('dashboard-services').service(ng2TagService.name, ng2TagService.def);
angular.module('dashboard-services').factory(ng2AuthService.name, ng2AuthService.def);
angular.module('dashboard-services').factory(ng2GroupService.name, ng2GroupService.def);
angular.module('dashboard-services').factory(ng2MeterService.name, ng2MeterService.def);
angular.module('dashboard-services').factory(ng2MeasurementService.name, ng2MeasurementService.def);
angular.module('dashboard-services').service(ng2CalendarService.name, ng2CalendarService.def);
angular.module('dashboard-services').service(ng2ContractService.name, ng2ContractService.def);
angular.module('dashboard-services').factory(ngCharts.name, ngCharts.def);
angular.module('dashboard-services').factory(ng2DatasourceService.name, ng2DatasourceService.def);
angular.module('dashboard-services').service(ng2EnergyBillService.name, ng2EnergyBillService.def);
angular.module('dashboard-services').factory(ng2ExportService.name, ng2ExportService.def);
angular.module('dashboard-services').factory(ng2UploadService.name, ng2UploadService.def);
angular.module('dashboard-services').factory(ng2MixPanelService.name, ng2MixPanelService.def);
angular.module('dashboard-services').factory(ngUserService.name, ngUserService.def);
angular.module('dashboard-services').factory(ng2LocationService.name, ng2LocationService.def);

export default DashboardServices.name

