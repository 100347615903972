<div class="content-wrapper view">
	<div class="container-fluid">
		<!-- ------------------------- Navbar ------------------------- -->
		<nav class="ga-navbar">
			<div class="navbar-content">
				<h3>
					<a (click)="backTo()">
						{{ "main.menu.registrations" | transloco }}
					</a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<a (click)="backTo()"> {{ "main.menu.companies" | transloco }} </a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<span *ngIf="!companyId" class="navbar-content__breadcrumb-page">
						{{ "company.new" | transloco }}
					</span>
					<span *ngIf="companyId" class="navbar-content__breadcrumb-page">
						{{ companyName }}
					</span>
				</h3>
			</div>
		</nav>

		<!-- ------------------------- Título ------------------------- -->
		<div class="description">
			<div class="description__texts">
				<span class="description__texts__title">{{
					!companyId
						? ("company.new" | transloco)
						: ("company.edit" | transloco)
				}}</span>
				<span class="description__texts__subtitle">{{
					!companyId
						? ("company.new-company-subtitle" | transloco)
						: ("company.edit-subtitle" | transloco)
				}}</span>
			</div>
		</div>

		<form
			[formGroup]="companyForm"
			id="companyForm"
			class="flex-col"
			focusInvalidInput
			(ngSubmit)="onSubmit()"
		>
			<div class="row col-lg-12 module-group">
				<h3 class="basic-data__title col-lg-12">
					{{ "global.basic-info" | transloco }}
				</h3>

				<div class="row">
					<!-- Organização -->
					<div class="form-group col-lg-6">
						<autocomplete-orgs
							[idSelected]="organization"
							(orgEmitter)="orgSelected($event)"
							[isRequired]="true"
						></autocomplete-orgs>
					</div>

					<!-- CNPJ -->
					<div class="form-group col-lg-6">
						<ga-input
							[title]="'company.form.cnpj' | transloco"
							[placeholder]="'00.000.000/0000-00'"
							[required]="true"
							[showError]="
								companyForm.controls['registeredNumber'].invalid &&
								companyForm.controls['registeredNumber'].touched
							"
							formControlName="registeredNumber"
							name="registeredNumber"
							[mask]="'00.000.000/0000-00'"
						></ga-input>
					</div>

					<!-- Razão Social -->
					<div class="form-group col-lg-6">
						<ga-input
							[title]="'company.form.companyName' | transloco"
							[placeholder]="'company.form.companyName-placeholder' | transloco"
							[disabled]="false"
							[required]="true"
							[showError]="
								companyForm.controls['companyName'].invalid &&
								companyForm.controls['companyName'].touched
							"
							formControlName="companyName"
							name="companyName"
						></ga-input>
					</div>

					<!-- Nome fantasia-->
					<div class="form-group col-lg-6">
						<ga-input
							[title]="'company.form.tradingName' | transloco"
							[placeholder]="'company.form.tradingName-placeholder' | transloco"
							[disabled]="false"
							[required]="true"
							[showError]="
								companyForm.controls['tradingName'].invalid &&
								companyForm.controls['tradingName'].touched
							"
							formControlName="tradingName"
							name="tradingName"
						></ga-input>
					</div>
				</div>

				<!-- Endereço -->
				<div>
					<div class="row">
						<div class="form-group col-lg-12">
							<ga-typeahead
								[title]="'meter-page.label.address' | transloco"
								[placeholder]="
									'meter-page.label.address-placeholder' | transloco
								"
								[list]="locations"
								(keyPress)="getLocation($event)"
								(selectionChange)="setAddressSelected()"
								[externalget]="true"
								[required]="true"
								[showError]="
									companyForm.controls['formatedAddress'].invalid &&
									companyForm.controls['formatedAddress'].touched
								"
								formControlName="formatedAddress"
								name="formatedAddress"
							></ga-typeahead>
						</div>
					</div>
				</div>

				<!-- Contatos -->
				<div formArrayName="contacts">
					<hr class="separator" />
					<h3 class="title">
						{{ "company.form.contact-title" | transloco }}
					</h3>

					<div
						class="row"
						*ngFor="let contact of contactsForm.controls; let i = index"
						[formGroupName]="i"
					>
						<div class="row-group">
							<div class="form-group col-lg-6 col-md-11 col-sm-11 col-xs-11">
								<ga-input
									[title]="'company.form.contact' | transloco"
									[placeholder]="'company.form.contact-placeholder' | transloco"
									formControlName="name"
									name="name"
								></ga-input>
							</div>

							<div
								class="form-group col-lg-6 col-md-1 col-sm-1 col-xs-1"
								style="width: 64px"
							>
								<button
									type="button"
									(click)="removeContact(i)"
									class="btn trash"
								>
									<i class="fa-regular fa-trash"></i>
								</button>
							</div>
						</div>

						<div class="form-group col-lg-6 col-md-12">
							<ga-input
								[title]="'company.form.email' | transloco"
								[placeholder]="'exemplo@email.com'"
								formControlName="email"
								name="email"
							></ga-input>
						</div>

						<div class="form-group col-lg-3 col-md-6">
							<ga-input
								[title]="'company.form.phone' | transloco"
								[placeholder]="'+55 (00) 0000-0000'"
								formControlName="phone"
								name="phone"
							></ga-input>
						</div>

						<div class="form-group col-lg-3 col-md-6">
							<ga-selector
								[title]="'company.form.profile' | transloco"
								[placeholder]="'meter-page.placeholder.select' | transloco"
								[options]="contactTypes"
								formControlName="contactType"
								name="contactType"
							></ga-selector>
						</div>
					</div>

					<div class="addition-btn-div">
						<button class="add-contact" type="button" (click)="addContact()">
							{{ "company.form.add-contact" | transloco }}
						</button>
					</div>
				</div>
			</div>

			<!----------- Cadastro CCEE --------------->
			<div class="row col-lg-12 module-group">
				<h3 class="basic-data__title">
					<span class="mr-5">{{
						"meter-page.label.ccee-fields" | transloco
					}}</span>
					<ga-helper
						[text]="'company.form.tooltip.ccee' | transloco"
						[icon]="'fa-solid fa-circle-info'"
						[iconSize]="14"
						[iconColor]="'#A3A3A3'"
						[tooltipLocale]="'above'"
						class="helper"
					></ga-helper>
				</h3>

				<div class="row">
					<div class="form-group col-lg-2 col-md-4">
						<ga-selector
							[title]="'company.form.agent-category' | transloco"
							[placeholder]="'meter-page.placeholder.select' | transloco"
							[required]="true"
							[options]="agentCategories"
							formControlName="agentCategory"
							name="agentCategory"
						></ga-selector>
					</div>

					<div class="form-group col-lg-2 col-md-4" *ngIf="!isNotAgent">
						<ga-selector
							[title]="'company.form.agent-class' | transloco"
							[placeholder]="'meter-page.placeholder.select' | transloco"
							[required]="true"
							[showError]="
								companyForm.controls['agentClass'].invalid &&
								companyForm.controls['agentClass'].touched
							"
							[options]="agentClasses"
							formControlName="agentClass"
							name="agentClass"
						></ga-selector>
					</div>

					<div class="form-group col-lg-2 col-md-4" *ngIf="!isNotAgent">
						<ga-input
							[title]="'company.form.agent-code' | transloco"
							[placeholder]="'meter-page.placeholder.enter-code' | transloco"
							[required]="true"
							[showError]="
								companyForm.controls['CCEEAgentCode'].invalid &&
								companyForm.controls['CCEEAgentCode'].touched
							"
							formControlName="CCEEAgentCode"
							name="CCEEAgentCode"
						></ga-input>
					</div>
				</div>

				<div formArrayName="CCEEProfiles" *ngIf="!isNotAgent">
					<div
						class="row"
						*ngFor="let profile of profilesForm.controls; let i = index"
						[formGroupName]="i"
					>
						<div class="form-group col-lg-2 col-md-6">
							<ga-input
								[title]="
									showTitle(i)
										? ('meter-page.label.ccee-profile-code' | transloco)
										: ''
								"
								[placeholder]="'company.form.contact-placeholder' | transloco"
								formControlName="name"
								name="name"
							></ga-input>
						</div>

						<div class="form-group col-lg-2 col-md-6">
							<ga-selector
								[title]="
									showTitle(i)
										? ('company.form.ccee-profile-class' | transloco)
										: ''
								"
								[placeholder]="'meter-page.placeholder.select' | transloco"
								[options]="cceeProfileClasses"
								formControlName="CCEEProfileClass"
								name="CCEEProfileClass"
							></ga-selector>
						</div>

						<div class="form-group col-lg-2 col-md-6">
							<ga-input
								[title]="
									showTitle(i)
										? ('company.form.ccee-agent-code' | transloco)
										: ''
								"
								[placeholder]="'Insira o código'"
								formControlName="code"
								name="code"
							></ga-input>
						</div>

						<div class="form-group col-lg-2 col-md-6">
							<ga-selector
								[title]="
									showTitle(i)
										? ('company.form.ccee-sub-market' | transloco)
										: ''
								"
								[placeholder]="'meter-page.placeholder.select' | transloco"
								[options]="subMarkets"
								formControlName="CCEESubMarket"
								name="CCEESubMarket"
							></ga-selector>
						</div>

						<div class="col-lg-4 pl-0 pr-0">
							<div class="form-group col-lg-5 col-md-5">
								<ga-selector
									[title]="
										showTitle(i)
											? ('company.form.ccee-profile-special' | transloco)
											: ''
									"
									[placeholder]="'meter-page.placeholder.select' | transloco"
									[options]="specialOptions"
									formControlName="typeSpecial"
									name="typeSpecial"
								></ga-selector>
							</div>

							<div class="row-group col-lg-7 col-md-7 pl-0 pr-24">
								<div class="form-group col-lg-10 col-md-11 col-sm-11 col-xs-11">
									<ga-selector
										[title]="
											showTitle(i)
												? ('company.form.standard-discount' | transloco)
												: ''
										"
										[placeholder]="'meter-page.placeholder.select' | transloco"
										[options]="discountOptions"
										formControlName="standardDiscount"
										name="standardDiscount"
									></ga-selector>
								</div>
								<div
									class="form-group col-lg-2 col-md-1 col-sm-1 col-xs-1"
									style="width: 40px"
								>
									<button
										type="button"
										(click)="removeProfile(i)"
										class="btn trash"
									>
										<i class="fa-regular fa-trash"></i>
									</button>
								</div>
							</div>
						</div>
					</div>

					<div class="addition-btn-div">
						<button class="add-contact" type="button" (click)="addProfile()">
							{{ "company.form.add-profile" | transloco }}
						</button>
					</div>
				</div>

				<!----------- Botões --------------->
				<hr class="separator" />
				<div class="button-group flex-row">
					<div class="btn-toolbar" role="toolbar" style="text-align: center">
						<div class="btn-group" role="group">
							<button class="btn btn-default" type="button" (click)="cancel()">
								{{ "appliance.btn.cancel" | transloco }}
							</button>
						</div>
						<div class="btn-group" role="group">
							<button
								class="btn btn-primary btn-color-ga"
								type="submit"
								[disabled]="!companyForm.valid"
							>
								{{ "organization.btn.save" | transloco }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
