import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { StateParams, StateService } from 'angular-ui-router';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Company } from 'webapp/app/shared/models/company.model';
import { SelectorOption } from 'webapp/app/shared/models/selector.model';
import { Auxiliar } from 'webapp/app/shared/services/auxiliar';
import { CCEEService } from 'webapp/app/shared/services/ccee.service';
import { CompanyService } from 'webapp/app/shared/services/company.service';
import { LocationService } from 'webapp/app/shared/services/location.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';

@Component({
	selector: 'app-company-details',
	templateUrl: './company-details.component.html',
	styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit, OnDestroy {
	organization: number | undefined;
	companyId: number | undefined;
	companyName = '';
	notAgentId!: number;
	companyForm!: FormGroup;
	locations: Array<string> = [];
	agentCategories: Array<SelectorOption> = [];
	agentClasses: Array<SelectorOption> = [];
	subMarkets: Array<SelectorOption> = [];
	cceeProfileClasses: Array<SelectorOption> = [];
	isScreenSmall = false;

	contactTypes: Array<SelectorOption> = [
		{
			label: 'Técnico',
			id: 'tecnical',
		},
		{
			label: 'Financeiro',
			id: 'financial',
		},
		{
			label: 'Operacional',
			id: 'operation',
		},
		{
			label: 'Outro',
			id: 'other',
		},
	];

	discountOptions: Array<SelectorOption> = [
		{
			label: '0%',
			id: 0,
		},
		{
			label: '50%',
			id: 50,
		},
		{
			label: '100%',
			id: 100,
		},
	];

	specialOptions: Array<SelectorOption> = [
		{
			label: 'Sim',
			id: true,
		},
		{
			label: 'Não',
			id: false,
		},
	];

	subscriptions: Subscription[] = [];

	constructor(
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		private locationService: LocationService,
		private cceeService: CCEEService,
		private companyService: CompanyService,
		private auxiliar: Auxiliar,
		private breakpointObserver: BreakpointObserver
	) {
		this.companyId = this.$stateParams.id;
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ngOnInit(): void {
		this.initFormGroup();
		this.screenWidthChanges();
		this.loadAgentCategories();
		this.loadSubMarkets();
		this.loadCCEEProfileClasses();

		this.loadCompany();

		if (!this.companyId) {
			this.addContact();
			this.addProfile();

			this.changesOnAgentCategoryControl();
		}
	}

	loadAgentCategories() {
		this.subscriptions.push(
			this.companyService
				.getAgentCategories()
				.pipe(
					tap((categories) => {
						categories.forEach((category) => {
							this.agentCategories.push({
								id: category.id,
								label: category.label,
							});

							this.notAgentId = category.id;
						});
						if (!this.companyId) this.setInitialCategory();
					}),
					catchError(() => {
						this.auxiliar.showMessage(
							'Erro ao carregar categorias de agente.',
							'error'
						);
						return EMPTY;
					})
				)
				.subscribe()
		);
	}

	loadAgentClasses(categoryId: number) {
		this.subscriptions.push(
			this.companyService
				.getAgentClassesByCategory(categoryId)
				.pipe(
					tap((agentClasses) => {
						this.agentClasses = agentClasses.map((agentClass) => ({
							id: agentClass.id,
							label: agentClass.label,
						}));

						this.sortAlphabetically(this.agentClasses);
					}),
					catchError(() => {
						this.auxiliar.showMessage(
							'Erro ao carregar classes de agente.',
							'error'
						);
						return EMPTY;
					})
				)
				.subscribe()
		);
	}

	changesOnAgentCategoryControl() {
		this.subscriptions.push(
			this.companyForm.controls['agentCategory'].valueChanges
				.pipe(
					tap((categoryId) => {
						this.loadAgentClasses(categoryId);
						this.updateValidators();
					})
				)
				.subscribe()
		);
	}

	loadSubMarkets() {
		this.cceeService
			.getCCEESubMarkets()
			.then((subMarkets) => {
				subMarkets.forEach((subMarket) => {
					this.subMarkets.push({
						id: subMarket.id,
						label: subMarket.name,
					});
				});

				this.sortAlphabetically(this.subMarkets);
			})
			.catch(() => {
				this.auxiliar.showMessage('Erro ao carregar submercados.', 'error');
			});
	}

	loadCCEEProfileClasses() {
		this.cceeService
			.getCCEEProfileClasses()
			.then((profileClasses) => {
				profileClasses.forEach((profileClass) => {
					this.cceeProfileClasses.push({
						id: profileClass.id,
						label: profileClass.name,
					});
				});

				this.sortAlphabetically(this.cceeProfileClasses);
			})
			.catch(() => {
				this.auxiliar.showMessage('Erro ao classes do perfil.', 'error');
			});
	}

	setContactsForm(contacts) {
		contacts.forEach(() => {
			this.addContact();
		});

		contacts.forEach((contact, index) => {
			this.contactsForm.controls[index].patchValue({
				id: contact.id,
				name: contact.name,
				phone: contact.phone,
				email: contact.email,
				contactType: contact.contactType,
			});
		});

		if (this.contactsForm.length === 0) this.addContact();
	}

	setProfilesForm(profiles) {
		profiles.forEach(() => {
			this.addProfile();
		});

		profiles.forEach((profile, index) => {
			this.profilesForm.controls[index].patchValue({
				id: profile.id,
				CCEEProfileClass: profile.CCEEProfileClass?.id,
				CCEESubMarket: profile.CCEESubMarket?.id,
				code: profile.code,
				name: profile.name,
				standardDiscount: profile.standardDiscount,
				typeSpecial: profile.typeSpecial,
			});
		});

		if (this.profilesForm.length === 0) this.addProfile();
	}

	loadCompany() {
		if (!this.companyId) return;
		let company: Company;

		this.subscriptions.push(
			this.companyService
				.getCompany(this.companyId)
				.pipe(
					switchMap((companyResponse) => {
						company = companyResponse;
						const agentCategory =
							companyResponse['agentClass']?.agentCategory || this.notAgentId;

						return this.companyService.getAgentClassesByCategory(agentCategory);
					}),
					tap((agentClasses) => {
						this.agentClasses = agentClasses.map((agentClass) => ({
							id: agentClass.id,
							label: agentClass.label,
						}));

						this.sortAlphabetically(this.agentClasses);

						this.companyForm.patchValue({
							...company,
							agentCategory:
								company['agentClass']?.agentCategory || this.notAgentId,
						});

						this.companyForm.controls['agentClass'].setValue(
							company['agentClass']?.id
						);

						this.companyName = company['tradingName'];

						this.setContactsForm(company['contacts']);
						this.setProfilesForm(company['CCEEProfiles']);
						this.updateValidators();
						this.changesOnAgentCategoryControl();
					}),
					catchError(() => {
						this.auxiliar.showMessage('Erro ao carregar empresa.', 'error');
						return EMPTY;
					})
				)
				.subscribe()
		);
	}

	initFormGroup() {
		const company = {
			id: new FormControl(),
			organization: new FormControl('', Validators.required),
			registeredNumber: new FormControl('', Validators.required),
			companyName: new FormControl('', Validators.required),
			tradingName: new FormControl('', Validators.required),
			formatedAddress: new FormControl('', Validators.required),
			street: new FormControl(''),
			city: new FormControl(''),
			district: new FormControl(''),
			state: new FormControl(''),
			country: new FormControl(''),
			zipCode: new FormControl(''),
			lat: new FormControl(''),
			lng: new FormControl(''),
			contacts: new FormArray([]),
			CCEEProfiles: new FormArray([]),
			CCEEAgentCode: new FormControl(null, Validators.required),
			agentCategory: new FormControl('', Validators.required),
			agentClass: new FormControl(null, Validators.required),
		};

		this.companyForm = new FormGroup(company);
	}

	updateValidators() {
		if (this.isNotAgent) {
			this.companyForm.controls['CCEEAgentCode'].clearValidators();
			this.companyForm.controls['agentClass'].clearValidators();

			if (this.companyForm.controls['agentClass'].touched) {
				this.companyForm.controls['CCEEAgentCode'].setValue(null);
				this.companyForm.controls['agentClass'].setValue(null);
			}

			for (let i = this.profilesForm.length - 1; i >= 0; i--) {
				this.removeProfile(i);
			}
		} else {
			this.companyForm.controls['CCEEAgentCode'].setValidators(
				Validators.required
			);
			this.companyForm.controls['agentClass'].setValidators(
				Validators.required
			);
		}

		this.companyForm.controls['CCEEAgentCode'].updateValueAndValidity();
		this.companyForm.controls['agentClass'].updateValueAndValidity();
	}

	get contactsForm() {
		return this.companyForm.controls['contacts'] as FormArray;
	}

	addContact() {
		const contact = new FormGroup({
			id: new FormControl(''),
			name: new FormControl(''),
			email: new FormControl(''),
			phone: new FormControl(''),
			contactType: new FormControl(''),
		});

		this.contactsForm.push(contact);
	}

	removeContact(i: number) {
		if (this.contactsForm.length === 1) {
			const contact = this.contactsForm.at(i) as FormGroup;

			Object.keys(contact.controls).forEach((key) => {
				contact.controls[key].setValue('');
			});
		} else {
			this.contactsForm.removeAt(i);
		}
	}

	removeEmptyContacts() {
		for (let i = this.contactsForm.length - 1; i >= 0; i--) {
			const contact = this.contactsForm.at(i) as FormGroup;
			if (
				contact.controls['name'].value === '' &&
				contact.controls['contactType'].value === '' &&
				contact.controls['email'].value === '' &&
				contact.controls['phone'].value === ''
			) {
				this.contactsForm.removeAt(i);
			}
		}
	}

	get profilesForm() {
		return this.companyForm.controls['CCEEProfiles'] as FormArray;
	}

	addProfile() {
		const profile = new FormGroup({
			id: new FormControl(''),
			CCEEProfileClass: new FormControl(''),
			CCEESubMarket: new FormControl(null),
			code: new FormControl(''),
			name: new FormControl(''),
			standardDiscount: new FormControl(null),
			typeSpecial: new FormControl(null),
		});

		this.profilesForm.push(profile);
	}

	removeProfile(i: number) {
		if (this.profilesForm.length === 1) {
			this.profilesForm.controls[i].patchValue({
				id: null,
				CCEEProfileClass: '',
				CCEESubMarket: null,
				code: '',
				name: '',
				standardDiscount: null,
				typeSpecial: null,
			});
		} else {
			this.profilesForm.removeAt(i);
		}
	}

	removeEmptyProfiles() {
		for (let i = this.profilesForm.length - 1; i >= 0; i--) {
			const profile = this.profilesForm.controls[i] as FormGroup;
			if (
				profile.controls['code'].value === '' ||
				profile.controls['name'].value === '' ||
				profile.controls['CCEEProfileClass'].value === ''
			) {
				this.profilesForm.removeAt(i);
			}
		}
	}

	setInitialCategory() {
		this.companyForm.controls['agentCategory'].setValue(this.notAgentId);
	}

	onSubmit() {
		this.removeEmptyContacts();
		this.removeEmptyProfiles();
		this.formatRegisteredNumber();

		this.companyService
			.saveCompany(this.companyForm.value)
			.then(() => {
				this.$state.transitionTo('registrations', {
					tab: 'companies',
					organizationId: this.organization,
				});

				this.auxiliar.showMessage('Empresa salva com sucesso.');
			})
			.catch((error) => {
				this.auxiliar.showErrorMessages(error);

				if (this.contactsForm.length === 0) {
					this.addContact();
				}
			});
	}

	orgSelected(event: { id: number; name: string }) {
		this.organization = event.id;
		this.companyForm.controls['organization'].setValue(this.organization);
	}

	getLocation(query) {
		const params = { language: 'pt-br', address: query };

		this.locationService.getAddress(params).then(({ results }) => {
			if (!results.length) this.locations = [];
			this.locations = results.map((item) => item.formatted_address);
		});
	}

	setAddressSelected() {
		const addressComponents = {
			route: 'street',
			sublocality_level_1: 'district',
			administrative_area_level_2: 'city',
			administrative_area_level_1: 'state',
			country: 'country',
			postal_code: 'zipCode',
		};

		this.locationService
			.getAddress({
				language: 'pt-br',
				address: this.companyForm.controls['formatedAddress'].value,
			})
			.then(({ results }) => {
				return results.map((item) => {
					item.address_components.forEach((elem) => {
						elem.types.forEach((type) => {
							if (addressComponents[type]) {
								this.companyForm.controls[addressComponents[type]].setValue(
									elem.long_name
								);
							}
							if (type === 'administrative_area_level_1') {
								this.companyForm.controls['state'].setValue(elem.short_name);
							}
						});
					});

					this.companyForm.controls['formatedAddress'].setValue(
						item.formatted_address
					);
					this.companyForm.controls['lat'].setValue(item.geometry.location.lat);
					this.companyForm.controls['lng'].setValue(item.geometry.location.lng);
				});
			});
	}

	formatRegisteredNumber() {
		this.companyForm.controls['registeredNumber'].setValue(
			this.companyForm.controls['registeredNumber'].value.replace(
				/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
				'$1.$2.$3/$4-$5'
			)
		);
	}

	sortAlphabetically(options: SelectorOption[]) {
		options.sort((a, b) => {
			if (a.label < b.label) {
				return -1;
			}
			if (a.label > b.label) {
				return 1;
			}
			return 0;
		});
	}

	screenWidthChanges() {
		this.breakpointObserver
			.observe(['(max-width: 1200px)'])
			.subscribe((result: BreakpointState) => {
				this.isScreenSmall = result.matches;
			});
	}

	get isNotAgent() {
		return this.companyForm.controls['agentCategory'].value === this.notAgentId;
	}

	showTitle(index: number) {
		return index === 0 || this.isScreenSmall;
	}

	cancel() {
		this.$state.transitionTo('registrations', {
			tab: 'companies',
		});
	}

	backTo() {
		this.$state.transitionTo(
			'registrations',
			{ tab: 'companies' },
			{
				inherit: false,
				reload: true,
				notify: false,
			}
		);
	}
}

export const ng2CompanyDetailsComponent = {
	name: 'companyDetail',
	def: downgradeComponent({
		component: CompanyDetailsComponent,
		propagateDigest: true,
	}),
};
