<div class="dialog">
	<div class="panel-heading" mat-dialog-title>
		<button type="button" class="close" (click)="dialogRef.close()">
			<span aria-hidden="true">&times;</span>
			<span class="sr-only">Close</span>
		</button>
		<h4 class="modal-title">
			{{ "projects.planned-projects.title" | transloco }}
		</h4>
	</div>
	<div class="modal-body" mat-dialog-content>
		<form [formGroup]="plannedProjectForm" novalidate>
			<div class="row">
				<div class="col-md-12">
					<h3>{{ "projects.planned-projects.new" | transloco }}</h3>
				</div>
				<div class="col-md-7" style="padding: 0">
					<div class="col-md-12" style="margin-top: 2rem">
						<div class="form-group">
							<label for="name">{{
								"consuming-unit.organization" | transloco
							}}</label>
							<input
								type="text"
								class="form-control"
								[value]="this.organization"
								disabled
							/>
						</div>
						<div class="form-group">
							<label for="name">{{
								"projects.planned-projects.name" | transloco
							}}</label>
							<input
								type="text"
								class="form-control"
								formControlName="name"
								[ngClass]="{
									'has-error':
										plannedProjectForm.get('name')?.invalid &&
										plannedProjectForm.get('name')?.touched
								}"
							/>
						</div>
					</div>
					<div class="col-md-12" style="margin-bottom: -2.5rem">
						<h3>{{ "projects.planned-projects.period" | transloco }}</h3>
					</div>
					<div class="col-md-12" style="margin-top: 2rem; padding: 0">
						<div class="col-md-6">
							<div class="form-group">
								<label for="name">{{
									"projects.planned-projects.periodStart" | transloco
								}}</label>
								<input
									type="date"
									class="form-control"
									formControlName="startDate"
									[ngClass]="{
										'has-error':
											plannedProjectForm.get('startDate')?.invalid &&
											plannedProjectForm.get('startDate')?.touched
									}"
								/>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label for="name">{{
									"projects.planned-projects.periodEnd" | transloco
								}}</label>
								<input
									type="date"
									class="form-control"
									formControlName="endDate"
									[ngClass]="{
										'has-error':
											plannedProjectForm.get('endDate')?.invalid &&
											plannedProjectForm.get('endDate')?.touched
									}"
								/>
							</div>
						</div>
					</div>
					<div class="col-md-6" style="margin-top: 2rem">
						<label for="name">{{
							"projects.planned-projects.kwh" | transloco
						}}</label>
						<div class="inner-addon right-addon">
							<p class="addon-title">kWh</p>
							<input
								type="number"
								class="form-control"
								formControlName="plannedEnergySavings"
								[ngClass]="{
									'has-error':
										plannedProjectForm.get('plannedEnergySavings')?.invalid &&
										plannedProjectForm.get('plannedEnergySavings')?.touched
								}"
							/>
						</div>
					</div>
					<div class="col-md-6" style="margin-top: 2rem">
						<label for="name">{{
							"projects.planned-projects.monetary" | transloco
						}}</label>
						<div class="inner-addon right-addon">
							<p class="addon-title">R$</p>
							<input
								type="number"
								class="form-control"
								formControlName="plannedMonetarySavings"
								[ngClass]="{
									'has-error':
										plannedProjectForm.get('plannedMonetarySavings')?.invalid &&
										plannedProjectForm.get('plannedMonetarySavings')?.touched
								}"
							/>
						</div>
					</div>
				</div>
				<div class="col-md-12 text-center" style="margin-top: 3.3rem">
					<div class="btn-group" style="margin-right: 10px" (click)="backTo()">
						<button class="btn btn-default">
							{{ "appliance.btn.cancel" | transloco }}
						</button>
					</div>
					<div class="btn-group">
						<button
							class="btn btn-primary btn-color-ga"
							[disabled]="plannedProjectForm.invalid"
							(click)="onSubmit()"
						>
							{{ "organization.btn.save" | transloco }}
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
