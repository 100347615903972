import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
	selector: 'accept-terms-notification',
	styleUrls: ['../notification.scss'],
	template: `
		<div class="flex-row">
			<span
				>{{ 'accept-cookies.msg1' | transloco }}
				{{ 'accept-cookies.msg2' | transloco }}
				<br /><span
					>{{ 'accept-cookies.msg3' | transloco }}
					<u
						><a
							href="https://www.greenant.com.br/politica-de-privacidade"
							target="_blank"
							>{{ 'accept-cookies.title' | transloco }}</a
						></u
					>.</span
				></span
			>
			<button (click)="ref.dismissWithAction()">
				{{ 'accept-cookies.success' | transloco }}
			</button>
		</div>
	`,
})
export class AcceptTermsComponent {
	constructor(public ref: MatSnackBarRef<AcceptTermsComponent>) {}
}
