import { AuthService } from 'webapp/app/auth/auth.service';

SignupController.$inject = ['$scope', '$translate', 'AuthService', '$state', '$timeout', '$window', '$transition$'];

/* @ngInject */
function SignupController($scope, $translate, AuthService: AuthService, $state, $timeout, $window, $transition$) {
	var vm = this;

	$scope.done = false;
	$scope.fadeIn = function (id) {
		$(id).fadeIn("slow");
	}

	$scope.signup = function (user) {
		if (user) {
			$("#loadingModal").show();
			const cb = (res) => {
				$("#loadingModal").hide();

				if (!res.error) { // Success - redirect to main page
					$scope.done = true;
					$window.scrollTo(0, 0);
				} else {
					$("#loadingModal").hide();
					var first = Object.keys(res?.Errors)[0];
					$scope.message = res?.Errors[first][0]?.message; //show first validation message
				}
			}
			AuthService.signup(user).then(cb, cb);
		} else { //Message mandatory fields
			$scope.message = $translate.instant('signup.validation.mandatory');
		}
	};

	$timeout(function () {
		$scope.token = $transition$.params().token || null;
		if ($scope.token) {
			$scope.done = true;
			const cb = (res) => {
				$scope.message = res.data.message;
			}
			AuthService.active($scope.token).then(cb, cb);
		}
	});
}

export const ngSignupController = {
	name: SignupController.name,
	def: SignupController
}
