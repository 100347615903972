import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { MaterialModule } from '../../../global/material/material.module';
import { HttpClientModule } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { GaTypeaheadComponent } from './ga-typeahead/ga-typeahead.component';
import { GaEditorComponent } from './ga-editor/ga-editor.component';
import { GAPipesModule } from './pipes/pipes.module';
import { TenantModule } from './tenant/tenant.module';
import { GlobalLoadingModule } from './global-loading/global-loader.module';
import { GaInputModule } from './ga-input/ga-input.module';
import { GaHelperModule } from './ga-helper/ga-helper.module';
import { GaDatatableNewModule } from './ga-datatable-new/ga-datatable-new.module';
import { GaSelectorModule } from './ga-selector/ga-selector.module';
import { GaContentDialogModule } from './ga-dialog/ga-content-dialog.module';
import { GaTagIconModule } from './ga-tag-icon/ga-tag-icon.module';
import { GaLoadingModule } from './ga-loading/ga-loading.module';
import { GaNavigationTabsModule } from './ga-navigation-tabs/ga-navigation-tabs.module';
import { ConfirmIdentityComponent } from './confirm-identity/confirm-identity.component';
import { GaAccessPasswordModule } from './ga-access-password/ga-access-password.module';
import { AccessKeysCardModule } from './access-keys-card/access-keys-card.module';
import { GaDatePickerModule } from './ga-datepicker/ga-datepicker.module';
import { GaColorPickerModule } from './color-picker/color-picker.module';
import { GaDateRangePickerModule } from './ga-date-range-picker/ga-date-range-picker.module';
import { GaCheckboxModule } from './ga-checkbox/ga-checkbox.module';
import { AutocompleteChipsModule } from './ga-autocomplete-chips/ga-autocomplete-chips.module';

@NgModule({
	imports: [
		CommonModule,
		TranslocoModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		HttpClientModule,
		AngularEditorModule,
		GAPipesModule,
		TenantModule,
		GlobalLoadingModule,
		GaInputModule,
		GaCheckboxModule,
		GaHelperModule,
		GaDatatableNewModule,
		GaSelectorModule,
		GaContentDialogModule,
		GaTagIconModule,
		GaLoadingModule,
		GaNavigationTabsModule,
		GaAccessPasswordModule,
		AccessKeysCardModule,
		GaDatePickerModule,
		GaDateRangePickerModule,
		AutocompleteChipsModule,
	],
	declarations: [
		GaTypeaheadComponent,
		GaEditorComponent,
		ConfirmIdentityComponent,
	],
	exports: [
		GaTypeaheadComponent,
		GaEditorComponent,
		GaNavigationTabsModule,
		TenantModule,
		GaColorPickerModule,
		GaDateRangePickerModule,
	],
})
export class SharedModule {}
