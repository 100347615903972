import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
	selector: 'ga-calendar-navigator',
})
export class CalendarNavigatorDirective extends UpgradeComponent {
	@Input('calendar-options') calendarOptions;
	@Input('channel') channel;

	constructor(elementRef: ElementRef, injector: Injector) {
		const rootScope = injector.get('$rootScope');
		const scope = rootScope.$new();
		const _injector = Injector.create(
			[
				{
					provide: '$scope',
					useValue: scope,
				},
			],
			injector
		);
		super('gaCalendarNavigator', elementRef, _injector);
	}
}
