import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Action } from '../model/action.model';
import { GaDataTableNewService } from '../service/datatable.service';

@Component({
	selector: 'ga-action-cell',
	styles: [
		`
			i.more {
				padding: 0;
				font-weight: 900;
				font-size: 22px;
				line-height: 22px;
				display: flex;
				align-items: center;
				text-align: center;
				color: #a3a3a3;
				cursor: pointer;
			}
			::ng-deep .mat-menu-panel {
				border: 1px solid #e6e6e6;
				box-shadow: 0 4px 8px 0 rgba(212, 212, 212, 0.5);
				display: flex;
				align-items: center;
			}
			.dropdown-button {
				position: relative;
				border: none;
				background: unset;
				padding: 0;
			}
			.dropdown-item {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				white-space: nowrap;
				padding: 10px 15px;
				height: fit-content;
				min-width: 160px;
				color: #494d55;
				cursor: pointer;
				i {
					width: fit-content;
					text-align: center;
					margin-right: 8px;
					color: var(--grayscale---gray-400);
				}
				.text {
					width: fit-content;
					display: flex;
					flex-direction: column;
					justify-content: center;
					color: var(--grayscale---gray-600);
					font-size: 13px;
					line-height: 1.42857143;
				}
			}
		`,
	],
	template: `
		<button
			class="dropdown-button"
			style="text-align: end;"
			*wchHasFeature="actions"
			mat-button
			[matMenuTriggerFor]="menu"
		>
			<i class="fa-regular fa-ellipsis-vertical more-size more"></i>
		</button>
		<mat-menu #menu="matMenu">
			<ng-container *ngFor="let action of actions">
				<button
					class="dropdown-item"
					*wchHasFeature="action.permission"
					(click)="onAction(action)"
					mat-menu-item
				>
					<i class="fa-regular" [ngClass]="action?.icon || ''"></i>
					<div class="text">
						{{ action?.title }}
					</div>
				</button>
			</ng-container>
		</mat-menu>
	`,
})
export class ActionCellComponent {
	@Input() column!: any;
	@Input() tableId!: number;
	@Input() set row(element: any) {
		this.actions = element?.action.map((action: Action) => {
			action.id = element.id;
			return action;
		});
	}

	@Output() emitAction: EventEmitter<Action> = new EventEmitter();
	actions: Action[] = [];

	constructor(private tableService: GaDataTableNewService) {}

	onAction(action: Action) {
		this.tableService.onActionChanged({ action, id: this.tableId });
	}
}
