import { Injectable } from '@angular/core';
import { DateOption } from '../models/date-option.model';

@Injectable({
	providedIn: 'root',
})
export class GaDateRangePickerService {
	get dateOptions(): DateOption[] {
		return [
			{
				label: 'main.navigator.hour',
				value: 'day',
			},
			{
				label: 'main.navigator.week',
				value: 'week',
			},
			{
				label: 'main.navigator.month',
				value: 'month',
			},
			{
				label: 'main.navigator.year',
				value: 'year',
			},
			{
				label: 'main.navigator.day',
				value: 'invoice',
			},
			{
				label: 'main.navigator.custom',
				value: 'custom',
			},
		];
	}

	formatDate(date: Date, currentPeriod) {
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();

		switch (currentPeriod) {
			case 'month':
				return `${month}/${year}`;
			case 'year':
				return `${year}`;
			default:
				return `${day}/${month}/${year}`;
		}
	}

	formatRange(
		startDate: Date | null,
		endDate: Date | null,
		currentPeriod: string
	) {
		if (!startDate || !endDate) {
			return '';
		}
		return (
			this.formatDate(startDate, currentPeriod) +
			' - ' +
			this.formatDate(endDate, currentPeriod)
		);
	}

	formatTime(date: Date) {
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const time = `${hours}:${minutes}`;

		return time;
	}

	formatDateWithTime(date: Date, currentPeriod) {
		return `${this.formatDate(date, currentPeriod)}, ${this.formatTime(date)}`;
	}

	formatRangeWithTime(startDate: Date, endDate: Date, currentPeriod) {
		const startTime = this.formatTime(startDate);
		const endTime = this.formatTime(endDate);

		return `${this.formatDate(
			startDate,
			currentPeriod
		)}, ${startTime} - ${this.formatDate(endDate, currentPeriod)}, ${endTime}`;
	}
}
