<div class="dialog">
	<div class="panel-heading">
		<button type="button" class="close" (click)="dialogRef.close()">
			<span aria-hidden="true">&times;</span>
			<span class="sr-only">Close</span>
		</button>
		<h4 class="modal-title">Detalhes</h4>
	</div>
	<div class="modal-body" mat-dialog-content>
		<ng-container *ngIf="project">
			<h2 style="font-size: 22px">{{ project.name }}</h2>
			<div class="row">
				<div class="col-md-12" style="padding-left: 0px">
					<div class="col-md-6 group-info">
						<p>{{ "projects.form.description" | transloco }}</p>
						<span>{{ project?.description }}</span>
					</div>
					<div class="col-md-3 group-info">
						<p>{{ "projects.table.code" | transloco }}</p>
						<span>EE - {{ project.id }}</span>
					</div>
					<div class="col-md-3 group-info">
						<p>{{ "projects.table.start-date" | transloco }}</p>
						<span>{{ project.startDate | date : "dd/MM/yyyy" : "UTC" }}</span>
					</div>
				</div>
			</div>
			<h2 style="font-size: 22px">
				{{ "projects.form.installations" | transloco }}:
			</h2>
			<ng-container>
				<div
					style="padding-bottom: 15px; max-height: 25vh; overflow: auto"
					*ngIf="project.installations.length > 1"
				>
					<table mat-table [dataSource]="installationsData" [width]="'100%'">
						<ng-container matColumnDef="installNumber">
							<th mat-header-cell *matHeaderCellDef [width]="'10%'">
								{{ "projects.form.num-installation" | transloco }}
							</th>
							<td mat-cell *matCellDef="let element">
								{{ element.installNumber }}
							</td>
						</ng-container>
						<ng-container matColumnDef="startDate">
							<th mat-header-cell *matHeaderCellDef [width]="'10%'">
								{{ "projects.table.start-date" | transloco }}
							</th>
							<td mat-cell *matCellDef="let element">
								{{ element.startDate | date : "dd/MM/yyyy" : "UTC" }}
							</td>
						</ng-container>
						<ng-container matColumnDef="address">
							<th mat-header-cell *matHeaderCellDef [width]="'30%'">
								{{ "projects.form.address" | transloco }}
							</th>
							<td mat-cell *matCellDef="let element">
								<span
									>{{ element.street
									}}{{
										element.streetNumber ? ", " + element.streetNumber : ""
									}}
								</span>
								<br />
								<span>{{ element.county }}, {{ element.state }}</span>
							</td>
						</ng-container>
						<ng-container matColumnDef="distributor">
							<th mat-header-cell *matHeaderCellDef [width]="'15%'">
								{{ "consuming-unit.distributor" | transloco }}
							</th>
							<td mat-cell *matCellDef="let element">
								{{ element.distributor.name }}
							</td>
						</ng-container>
						<ng-container matColumnDef="tariff">
							<th mat-header-cell *matHeaderCellDef [width]="'25%'">
								{{ "main.panel.tariff" | transloco }}
							</th>
							<td mat-cell *matCellDef="let element">
								{{ element.tariff.name }}
							</td>
						</ng-container>
						<tr mat-header-row *matHeaderRowDef="columns"></tr>
						<tr mat-row *matRowDef="let row; columns: columns"></tr>
					</table>
				</div>
			</ng-container>
			<div class="row" *ngIf="project.installations.length == 1">
				<div class="col-md-6 v-line">
					<div class="group-info">
						<p>{{ "projects.form.num-installation" | transloco }}</p>
						<span>{{ project.installations[0].installNumber }}</span>
					</div>
					<div class="group-info">
						<p>{{ "projects.form.address" | transloco }}</p>
						<span
							>{{ project.installations[0].street }},
							{{ project.installations[0].streetNumber }}
						</span>
						<span
							>{{ project.installations[0].county }},
							{{ project.installations[0].state }}</span
						>
					</div>
				</div>
				<div class="col-md-6">
					<div class="row">
						<div class="col-md-12">
							<div class="group-info">
								<p>{{ "consuming-unit.distributor" | transloco }}</p>
								<span>{{ project.installations[0].distributor.name }}</span>
							</div>
							<div class="group-info">
								<p>{{ "main.panel.tariff" | transloco }}</p>
								<span>{{ project.installations[0].tariff.name }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
