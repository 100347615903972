import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { ColumnType, DataTableList } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { OrganizationService } from 'webapp/app/shared/services/organization.service';
import { GASelectorLink } from 'webapp/app/shared/ga-datatable-new/model/ga-link.model';
import { DataSourceService } from 'webapp/app/shared/services/datasource.service';
import { LoaderService } from 'webapp/app/shared/global-loading/loader.service';

@Injectable({
	providedIn: 'root',
})
export class OrganizationListService {
	displayTable$: Observable<DataTableList> = new Observable();
	constructor(
		private i18n: TranslocoService,
		private organizationService: OrganizationService,
		private datasourceService: DataSourceService,
		private loaderService: LoaderService
	) {}

	get columnHeaders(): ColumnType[] {
		return [
			{
				label: 'organization.label.id',
				type: 'text',
				name: 'id',
				width: '10%',
			},
			{
				label: 'organization.label.name',
				type: 'text',
				name: 'name',
				width: '20%',
			},
			{
				label: 'organization.label.datasources',
				type: 'selectLink',
				name: 'dataSources',
				textCenter: true,
				width: '25%',
			},
			{
				label: 'organization.label.users',
				type: 'select',
				name: 'users',
				textCenter: true,
				width: '25%',
			},
			{
				label: 'organization.label.create-time',
				type: 'text',
				name: 'createdAt',
				sortBy: (item: any, property: string) => DateTime.fromFormat(item[property], 'dd/MM/yy - hh:mm').toMillis(),
				width: '20%',
			},
			{
				label: '',
				type: 'action',
				name: 'actions',
				width: '45px',
			},
		];
	}

	get actions(): Action[] {
		return [
			{
				title: this.i18n.translate('global.group.edit'),
				icon: 'fa-edit',
				type: 'edit',
			},
			{
				title: this.i18n.translate('organization.btn.add-user'),
				icon: 'fa-regular fa-user-plus gear-button',
				type: 'invite-user',
			},
		];
	}

	buildTable(organizations: any[]): DataTableList {
		const datatable: DataTableList = {} as DataTableList;
		datatable.data = organizations.map((org: any) => ({
			id: org.id,
			name: org.name,
			users: [], //Lazy load datasources (renderLazyDataSources)
			dataSources: [], //Lazy load datasources (renderLazyDataSources)
			createdAt: DateTime.fromISO(org.createdAt).toFormat('dd/MM/yy - hh:mm'),
			action: this.actions,
		}));
		datatable.badge = {
			singular: this.i18n.translate('organization.label.single-organization'),
			plural: this.i18n.translate('organization.label.multiple-organizations'),
		};
		datatable.column = this.columnHeaders;
		datatable.filter = this.filterTable;
		return datatable;
	}

	renderLazyUsers(organizationId: number) {
		const params = { organizationId };
		const optionsList: any[] = [];

		this.organizationService
			.$getUsers(params)
			.pipe(
				tap((users: any[]) => {
					users.forEach((user) => {
						const optionObj: any = {} as any;
						optionObj.placeholder = `${this.i18n.translate('user.label.users')}`;
						optionObj.label = `${user.firstName} ${user.lastName}`;
						optionObj.id = user.id;
						optionObj.subtext = user.email;
						optionObj.disabled = true;
						optionsList.push(optionObj);
					});
					return optionsList;
				})
			)
			.subscribe();
		this.loaderService.isLoading.next(false);
		return optionsList;
	}

	renderLazyDataSources(organizationId: number): GASelectorLink[] {
		const params = { organizationId };
		const dropdownList: GASelectorLink[] = [];

		this.datasourceService
			.$getDataSources(params)
			.pipe(
				tap((dataSources: any[]) => {
					dataSources.forEach((meter) => {
						const dropdownObj: GASelectorLink = {} as GASelectorLink;
						dropdownObj.placeholder = this.i18n.translate('alerts.label.meter-popuver.title-btn-plural');
						dropdownObj.label = meter.label;
						dropdownObj.value = meter.id;
						dropdownObj.subtext = `${this.i18n.translate('consuming-unit.code')}: ${meter.uid}`;
						dropdownObj.link = 'main.dashboard.meter';
						dropdownObj.params = { uid: meter.id };
						dropdownList.push(dropdownObj);
					});
				})
			)
			.subscribe();
		this.loaderService.isLoading.next(false);
		return dropdownList;
	}

	filterTable(data: any, filterText: string) {
		const filter = filterText.toLowerCase();
		return (
			data.id.toString().toLowerCase().includes(filter) ||
			data.name.toLowerCase().includes(filter) ||
			data.createdAt?.trim().toLowerCase().includes(filter) ||
			`${data.dataSources.length} Pontos de medição`.toLowerCase().includes(filter) ||
			data.dataSources.some((ds) => ds.label.toLowerCase().includes(filter)) ||
			`${data.users.length} Usuários`.toLowerCase().includes(filter) ||
			data.users.some((ds) => ds.label.toLowerCase().includes(filter))
		);
	}
}
