import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import { DateTime } from 'luxon';
import { MultiSelectOption } from 'webapp/app/shared/multi-selector/multi-selector.model';
import {
	DateList,
	dateListYearNextCurrentDate,
} from 'webapp/app/utils/dateUtil';
import { Installation, ProjectConsumptionDetail } from '../../project.model';
import { chartOptions, compareChartOptions } from './manual-chart-options';
import {
	ManualChartObj,
	RangeDate,
	TogglType,
} from './manual-project-chart.component';

export interface InstalConsumptionPeriod {
	begin: DateTime;
	startInstallDate: DateTime;
	last: DateTime;
	startDate: DateTime;
	endDate: DateTime;
	installationObj: any;
}

export interface ChartObj {
	installNumber: string;
	baseline: (number | null)[];
	consumptions: (number | null)[];
	area: any;
	monetary: string;
}

@Injectable({
	providedIn: 'any',
})
export class ManualProjectChartService {
	private manualConsump: ManualChartObj = {} as ManualChartObj;
	private comparateConsump: ManualChartObj[] = [];

	public transformListToMultiSelect(list: Installation[]): MultiSelectOption[] {
		const multiSelectorList: MultiSelectOption[] = [
			{
				id: 0,
				label: 'Somar todas as instalações',
				onlyOption: true,
			},
		];
		list.forEach((item, index) => {
			const multiSelectorObj = {} as MultiSelectOption;
			multiSelectorObj.id = <number>item.id;
			multiSelectorObj.label = item.installNumber;
			multiSelectorList.push(multiSelectorObj);
		});
		return multiSelectorList;
	}

	public setRangeDate(
		startProjDate: Date,
		consumptions: ProjectConsumptionDetail[]
	) {
		const min = DateTime.fromJSDate(new Date(startProjDate)).toFormat(
			'yyyy-MM'
		);
		const max =
			consumptions[consumptions.length - 1]?.date ??
			DateTime.now().toFormat('yyyy-MM');
		return this.rangeToDateJS(min, max);
	}

	public rangeToDateJS(startDate: string, endDate: string): RangeDate {
		const min = DateTime.fromFormat(startDate, 'yyyy-MM').toJSDate();
		const max = DateTime.fromFormat(endDate, 'yyyy-MM').toJSDate();
		return { min, max };
	}

	getRangeDateByCompare(
		manualObj1: ManualChartObj,
		manualObj2: ManualChartObj
	): RangeDate {
		const min = this.getMinDate(
			<Date>manualObj1.startDate,
			<Date>manualObj2.startDate
		);
		const max = this.getMaxDate(
			manualObj1.consumptions.length !== 0
				? manualObj1.consumptions
				: manualObj1.baseline,
			manualObj2.consumptions.length !== 0
				? manualObj2.consumptions
				: manualObj2.baseline
		);
		return { min, max };
	}

	getMinDate(dateFormat1: Date, dateFormat2: Date): Date {
		return dateFormat1 < dateFormat2 ? dateFormat1 : dateFormat2;
	}

	getMaxDate(
		consumptions1: ProjectConsumptionDetail[],
		consumptions2: ProjectConsumptionDetail[]
	): Date {
		const max1 = consumptions1[consumptions1.length - 1].date;
		const max2 = consumptions2[consumptions2.length - 1].date;
		const max1ToDateTime = DateTime.fromFormat(max1, 'yyyy-MM').toJSDate();
		const max2ToDateTime = DateTime.fromFormat(max2, 'yyyy-MM').toJSDate();
		return max1ToDateTime > max2ToDateTime ? max1ToDateTime : max2ToDateTime;
	}

	set consumptions(consumptions: ManualChartObj) {
		this.manualConsump = consumptions;
	}

	set compareConsumptions(comparateConsump: ManualChartObj[]) {
		this.comparateConsump = comparateConsump;
	}

	private getCategories(startDate: DateTime): DateList[] {
		return dateListYearNextCurrentDate(startDate);
	}

	public populateCharts(
		initDate: Date,
		togglType: TogglType,
		isCompare: boolean
	): Highcharts.Options {
		const chartValues: ChartObj = {} as ChartObj;
		const initialDate = DateTime.fromJSDate(initDate);
		const categories = this.getCategories(initialDate).map(
			(date) => date.label
		);

		if (isCompare) return this.compareCharts(initDate, togglType, categories);

		const { baseline, consumptions, installNumber } = this.getValuesByPeriod(
			initDate,
			this.manualConsump
		);
		chartValues.baseline = baseline.map((b) => b[togglType]);
		chartValues.consumptions = consumptions.map((c) => c[togglType]);
		chartValues.installNumber = installNumber;
		chartValues.monetary = togglType;
		chartValues.area = this.consumptionArea(
			chartValues.baseline,
			chartValues.consumptions
		);
		return chartOptions(categories, chartValues);
	}

	private compareCharts(
		initDate: Date,
		togglType: TogglType,
		categories: any
	): Highcharts.Options {
		const firstConsump = this.comparateConsump[0];
		const secondConsump = this.comparateConsump[1];
		const firstManualConsump = this.getValuesByPeriod(initDate, firstConsump);
		const secondManualConsump = this.getValuesByPeriod(initDate, secondConsump);
		const chartFirstValues = this._consumptionToChartObj(
			firstManualConsump,
			togglType
		);
		const chartSecondValues = this._consumptionToChartObj(
			secondManualConsump,
			togglType
		);
		return compareChartOptions(categories, chartFirstValues, chartSecondValues);
	}

	private _consumptionToChartObj(
		consump: ManualChartObj,
		togglType: string
	): ChartObj {
		const chartValues: ChartObj = {} as ChartObj;
		chartValues.baseline = consump.baseline.map((base) =>
			base ? +base[togglType] : null
		);
		chartValues.consumptions = consump.consumptions.map((consump) =>
			consump ? +consump[togglType] : null
		);
		chartValues.area = this.consumptionArea(
			chartValues.baseline,
			chartValues.consumptions
		);
		chartValues.installNumber = consump.installNumber;
		chartValues.monetary = togglType;
		return chartValues;
	}

	private getValuesByPeriod(
		initDate: Date,
		manualConsump: ManualChartObj
	): ManualChartObj {
		const dates = this.getCategories(DateTime.fromJSDate(initDate));
		const baseline = this.getBaselineByPeriod(dates, manualConsump);
		const consumptions = this.getConsumpByPeriod(dates, manualConsump);
		return {
			baseline,
			consumptions,
			installNumber: manualConsump.installNumber,
		};
	}

	private getBaselineByPeriod(
		dates: DateList[],
		manualConsump: ManualChartObj
	): ProjectConsumptionDetail[] {
		const { baseline } = manualConsump;
		/* compare just month to fix base to any year period */
		return dates.map(
			(date) =>
				<ProjectConsumptionDetail>(
					baseline.find(
						(base) =>
							DateTime.fromFormat(base.date, 'yyyy-MM').month ===
							DateTime.fromFormat(date.name, 'MM-yyyy').month
					)
				)
		);
	}

	private getConsumpByPeriod(
		dates: DateList[],
		manualConsump: ManualChartObj
	): ProjectConsumptionDetail[] {
		const { consumptions } = manualConsump;
		return dates.map((date) => {
			const currDate = DateTime.fromFormat(date.name, 'MM-yyyy');
			return <ProjectConsumptionDetail>(
				(consumptions.find((c) =>
					DateTime.fromFormat(c.date, 'yyyy-MM').toFormat('MM-yyyy') ===
					currDate.toFormat('MM-yyyy')
						? this.zeroToNullByConsumption(c)
						: null
				) || { consumption: null, value: null, date: date.name })
			);
		});
	}

	private zeroToNullByConsumption(vl: ProjectConsumptionDetail) {
		if (!vl.consumption) vl.value = null;

		return vl;
	}

	private consumptionArea(
		primaryLine: (number | null)[],
		comparationLine: (number | null)[]
	): any[] {
		return primaryLine.map((line, i) => [line, comparationLine[i]]);
	}
}
