<div class="kpi-topbar-content">
	<ga-selector
		[isMultiple]="true"
		[(ngModel)]="filteredMetrics"
		placeholder="{{ 'kpi.table.metrics-placeholder' | transloco }}"
		[options]="metrics"
		[isTrigger]="true"
		(ngModelChange)="emitChange($event)"
		name="filteredMetrics"
	>
		<p>{{ filterText }}</p>
	</ga-selector>
</div>
