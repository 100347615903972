class TariffSelectorController {
	constructor(
		$scope,
		$translate,
		UserService,
		OrganizationService,
		ContractService
	) {
		this.$scope = $scope;
		this.$translate = $translate;

		this.userService = UserService;
		this.organizationService = OrganizationService;
		this.contractService = ContractService;
	}

	$onInit() {
		this.selectorId = this.gaId ? this.gaId : 'tariffSelector';
		this.disabled = this.disabled ? this.disabled : false;

		this.unshiftOldTariffs =
			this.unshiftOldTariffs != undefined ? this.unshiftOldTariffs : true;
		this.notDeleteSelected =
			this.notDeleteSelected != undefined ? this.notDeleteSelected : false;
		this.placeholder = this.placeholder
			? this.placeholder
			: 'tariff-selector.placeholder';

		this.userService
			.getCurrentUser()
			.then((user) => {
				this.currentUser = user;
				this.isAdmin = this.currentUser.isAdmin;
			})
			.then(() => this.loadTariffTypes())
			.then(() => {
				if (!this.selectedTariff) {
					return this.loadUserTariffs();
				}
			})
			.then(() => this.watchOrganizationParam());
		this.watchSelectedTariffParam();
		this.watchFilteredTariffs();
	}

	watchSelectedTariffParam() {
		this.$scope.$watch('tsVm.selectedTariff', (newTariff) => {
			this.selectedTariff = newTariff;
			newTariff && (this.tariffName = newTariff.name);
		});
	}

	watchFilteredTariffs() {
		this.$scope.$watch('tsVm.filteredTariffs', (tariffsToRemove) => {
			if (!this.tariffs || !tariffsToRemove) return;
			this.filterTariffsToRemove(tariffsToRemove);
		});
	}

	watchOrganizationParam() {
		this.$scope.$watch('tsVm.organization', (newOrg) => {
			if (this.unfilteredTariffs != null && !this.showAll) {
				!this.allowPublicTariffs
					? this.filterItemsByOrganization(newOrg)
					: this.filterItemsByOrganizationAOrNull(newOrg);
			}
		});
	}

	filterTariffsToRemove(tariffsToRemove) {
		if (!tariffsToRemove) return;

		this.tariffs = this.tariffs.filter(
			(t) => !tariffsToRemove.some((ttr) => ttr.id == t.id)
		);
	}

	clearSearchOnBackspace($event) {
		const KEY_BACKSPACE = 8;
		const KEY_DELETE = 46;
		const insertOrDelete =
			$event.keyCode === KEY_BACKSPACE || $event.keyCode === KEY_DELETE;

		if (insertOrDelete) {
			this.tariffName = null;
			this.selectedTariff = null;

			this.onTariffChanged(null);
			if (this.unshiftOldTariffs && this.oldTariff) {
				this.tariffs.unshift(this.oldTariff);
			}
		}
	}

	getOrgId(org) {
		if (!org) return;

		if (typeof org == 'object') {
			return org.id ? org.id : undefined;
		} else if (typeof org == 'string' || typeof org == 'number') {
			return org;
		} else {
			return undefined;
		}
	}

	loadUserTariffs() {
		if (this.tariffs) return Promise.resolve();

		const params = this.subType ? { subType: this.subType } : {};
		if (params != null && this.subType == 'regulated') {
			params.privateAndPublic = true;
		}
		return this.contractService
			.getTariffs(params)
			.then((tariffs) => this.filterTariffsByType(tariffs))
			.then((tariffs) => {
				this.unfilteredTariffs = angular.copy(tariffs);
				this.tariffs = tariffs;
			})
			.then(() => this.filterTariffsToRemove(this.excludeTariffs));
	}

	filterTariffsByType(tariffs) {
		if (!this.types) return tariffs;

		const types = this.types.split(' ');
		return tariffs.filter((tariff) => types.includes(tariff.tariffType.name));
	}

	loadTariffTypes() {
		if (this.tariffTypes) return;

		this.contractService
			.tariffTypes()
			.then((tariffTypes) => (this.tariffTypes = tariffTypes));
	}

	onTariffSelected(selectedTariff) {
		this.tariffName = selectedTariff.name;

		this.tariffs = this.notDeleteSelected
			? this.tariffs
			: this.tariffs.filter((t) => t.id != selectedTariff.id);

		this.selectedTariff = selectedTariff;
		this.oldTariff = !this.oldTariff ? selectedTariff : this.selectedTariff;

		this.onTariffChanged(this.selectedTariff);
	}

	filterItemsByOrganization(organization) {
		const orgId = this.getOrgId(organization);
		this.tariffs = this.unfilteredTariffs.filter(
			(tariff) => tariff.organization === orgId
		);
	}

	filterItemsByOrganizationAOrNull(organization) {
		const orgId = this.getOrgId(organization);
		this.tariffs = this.unfilteredTariffs.filter(
			(tariff) => tariff.organization === orgId || tariff.organization == null
		);
	}
}

const TariffSelector = {
	templateUrl: '/app/directives/tariff-selector/tariff-selector.html',
	controller: TariffSelectorController,
	controllerAs: 'tsVm',
	bindings: {
		gaId: '@',
		//Future uncertain - Might be deprecated/removed
		selectedTariff: '=',
		//Callback function for new tariff selected
		onTariffChanged: '<',
		//Defines if removed tariffs should be re-added to list when new tariff selected
		//Boolean. Defaults to true
		unshiftOldTariffs: '<',
		notDeleteSelected: '<',
		// A list of tariffs that will be removed from the tariff list
		excludeTariffs: '=',
		// Tariff types, separated by spaces
		types: '<',
		// Filters Contracts Based on Tariff Type.
		// Accepted Values are: 'free', 'regulated', 'conventional'
		subType: '@',
		// I18n key for input text placeholder
		placeholder: '@',
		//Organization filter - Display Tariffs from provided organization
		organization: '<',
		//Disables the Input
		disabled: '=?',
		// Show all tariffs in first load
		showAll: '=?',
		//filter tariffs by organizationId or null
		allowPublicTariffs: '=?',
	},
};

angular
	.module('dashboard-directives')
	.component('tariffSelector', TariffSelector);
