import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { StateService } from 'angular-ui-router';
import { Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AutocompleteOrgsComponent } from 'webapp/app/shared/autocomplete-components/organizations/autocomplete-orgs.component';
import { ActionButton } from 'webapp/app/shared/models/selector.model';
import { MixPanelService } from 'webapp/app/shared/services/mixpanel.service';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { CompositionListService } from './composition-list.service';
import { DataTableList } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
	GaContentDialogComponent,
	GaContentDialogModel,
} from 'webapp/app/shared/ga-dialog/ga-content-dialog.component';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';

@Component({
	selector: 'composition-list',
	templateUrl: './composition-list.component.html',
	styleUrls: ['./composition-list.component.scss'],
})
export class CompositionListComponent implements OnInit, OnDestroy {
	actionButtons: ActionButton[] = [];
	organizationId: number | undefined = undefined;
	subscriptions: Array<Subscription> = [];
	displayEl$: Observable<any> = new Observable();
	isOrgAdmin = false;
	dialogRef!: MatDialogRef<any>;
	@ViewChild(AutocompleteOrgsComponent)
		organizationsComponent!: AutocompleteOrgsComponent;
	constructor(
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(TranslocoService) private i18n: TranslocoService,
		public dialog: MatDialog,
		private mixpanel: MixPanelService,
		private notification: NotificationService,
		private compositionListService: CompositionListService
	) {
		this.subscriptions.push(this.compositionListService.getTariffsTypes(null).subscribe());
	}

	ngOnInit(): void {
		this.initActionButtons();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	initActionButtons() {
		const translateCodes = [
			'composition-list.new.opt-captive',
			'composition-list.new.opt-captive-small',
			'composition-list.new.opt-free',
			'composition-list.new.opt-free-small',
			'composition-list.new.opt-communion',
			'composition-list.new.opt-communion-small',
			'composition-list.new.opt-communion-split',
			'composition-list.new.opt-communion-split-small',
			'composition-list.new.opt-conventional',
			'composition-list.new.opt-conventional-small',
		];
		this.subscriptions.push(
			this.i18n
				.selectTranslate(translateCodes)
				.pipe(
					tap((labels) => {
						this.actionButtons = [
							{
								title: labels[0],
								subtitle: labels[1],
								actionName: 'regulatedDemand',
							},
							{
								title: labels[2],
								subtitle: labels[3],
								actionName: 'freeMarketCommon',
							},
							{
								title: labels[4],
								subtitle: labels[5],
								actionName: 'freeMarketCommunion',
							},
							{
								title: labels[6],
								subtitle: labels[7],
								actionName: 'freeMarketSharing',
							},
							{
								title: labels[8],
								subtitle: labels[9],
								actionName: 'regulatedCommon',
							},
						];
					})
				)
				.subscribe()
		);
	}

	newAction(event, id) {
		const route = 'main.composition';
		const params: any = {
			id: id,
			organizationId: this.organizationId,
			type: event.actionName,
		};
		this.stateGo(route, params);
	}

	stateGo(route, params) {
		this.$state.transitionTo(route, params);
	}

	mixPanelEvent(type) {
		this.mixpanel.mixPanelEvent({ type, object: {} });
	}

	orgSelected(event: { id: number; name: string }) {
		if (!event?.id || event?.id === this.organizationId) return;
		this.organizationId = event?.id;
		this.isOrgAdmin = this.organizationsComponent.isOrgAdmin || this.organizationsComponent.currentUser.isAdmin;
		this.listContracts();
	}

	listContracts() {
		const params: any = { populate: true };
		if (this.organizationId) params.organizationId = this.organizationId;

		this.displayEl$ = this.compositionListService.getContracts(params).pipe(
			map((data) => {
				const element: DataTableList = {} as DataTableList;
				element.data = this.compositionListService.renderListToTable(data.contracts);
				element.column = this.compositionListService.columns;
				element.badge = {
					singular: this.i18n.translate('data-table.local'),
					plural: this.i18n.translate('data-table.locals'),
				};
				element.sub = this.compositionListService.subColumns;
				element.filter = this.compositionListService.filterTable;
				return element;
			})
		);
	}

	onRowExpand(event) {
		if (!event || event.subrows.length) return;
		const subscription = this.compositionListService.renderSubRows(event).subscribe((subrows) => {
			event.subrows = subrows;
		});
		this.subscriptions.push(subscription);
	}

	doAction(event) {
		const dialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		switch (event.type) {
		case 'edit':
			this.newAction(
				{
					actionName: event.row.contractType.type,
				},
				event.row.id
			);
			return;
		case 'delete':
			dialogModel.icon = 'error';
			dialogModel.iconType = 'error';
			dialogModel.title = this.i18n.translate('composition-list.notify.message.shure-to-delete');
			dialogModel.message = this.i18n.translate('composition-list.notify.message.can-lose-data');
			dialogModel.btnError = true;
			break;
		}
		this.dialogRef = this.dialog.open(GaContentDialogComponent, {
			disableClose: true,
			data: dialogModel,
			width: '407px',
		});

		this.subscriptions.push(
			this.dialogRef.componentInstance.confirm
				.pipe(
					tap(() => {
						this.onDelete(event.row);
					})
				)
				.subscribe()
		);
	}

	onDelete(contract) {
		this.compositionListService
			.deleteContract(contract)
			.pipe(
				tap((data) => {
					this.notification.success({
						msg: this.i18n.translate('composition-list.notify.delete.success'),
					});
					this.listContracts();
					this.closeDialog();
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					this.closeDialog();
					return error;
				})
			)
			.subscribe();
	}

	closeDialog() {
		this.dialogRef.close();
	}
}

export const ng2CompositionListComponent = {
	name: 'compositionList',
	def: downgradeComponent({
		component: CompositionListComponent,
		propagateDigest: true,
	}),
};
