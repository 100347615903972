import {
	Component,
	EventEmitter,
	Input,
	Output,
	forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormFieldBaseDirective } from '../directive/form-field-base';

@Component({
	selector: 'ga-typeahead',
	templateUrl: './ga-typeahead.component.html',
	styleUrls: ['./ga-typeahead.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GaTypeaheadComponent),
			multi: true,
		},
	],
})
export class GaTypeaheadComponent extends FormFieldBaseDirective {
	@Input() type = 'text';
	@Input() rAddon = '';
	@Input() lAddon = '';
	@Input() externalget = false;
	@Input() limitTo = 999;
	@Input() list: Array<string> = [];
	@Output() keyPress = new EventEmitter();
	@Output() selectionChange = new EventEmitter();
	filteredOptions: Array<string> = [];

	constructor() {
		super();
	}

	private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();
		if (!this.list) this.list = [];
		return this.list.filter((item) => item.toLowerCase().includes(filterValue));
	}

	emitChange(item) {
		this.value = item;
		this.selectionChange.emit(true);
	}

	listItems(event) {
		this.value = event.target.value;
		this.keyPress.emit(event.target.value);
		this.filteredOptions = this._filter(event.target.value);
	}
}
