import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { StateService } from 'angular-ui-router';
import { Subject, Subscription, from } from 'rxjs';
import { catchError, debounceTime, tap } from 'rxjs/operators';
import { AutocompleteOrgsComponent } from 'webapp/app/shared/autocomplete-components/organizations/autocomplete-orgs.component';
import { Meter } from 'webapp/app/shared/models/meter.model';
import { SelectorOption } from 'webapp/app/shared/models/selector.model';
import { User } from 'webapp/app/shared/models/user.model';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { MeterService } from 'webapp/app/shared/services/meter.service';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';

@Component({
	selector: 'supervisor',
	templateUrl: './supervisor.component.html',
	styleUrls: ['./supervisor.component.scss'],
})
export class SupervisorComponent implements OnInit, OnDestroy {
	subscriptions: Array<Subscription> = [];
	$changeSupervision: Subject<void> = new Subject<void>();
	organizationId: number | undefined = undefined;
	dataSources: Array<Meter> = [];
	filterDataSources: any[] = [];
	currentUser: User = {} as User;
	dataSourceOptions: SelectorOption[] = [];
	selectedDataSources: FormControl = new FormControl();
	isOrgAdmin = false;
	@ViewChild(AutocompleteOrgsComponent)
		organizationsComponent!: AutocompleteOrgsComponent;

	constructor(
		@Inject(NG_ROUTER) private $state: StateService,
		private meterService: MeterService,
		private notification: NotificationService,
		private i18n: TranslocoService
	) {}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	ngOnInit(): void {
		const subscription = this.$changeSupervision
			.pipe(debounceTime(4000))
			.subscribe(() => this.updateSupervisionBoard());

		const formSubscription = this.selectedDataSources.valueChanges
			.pipe(
				tap((ds) => {
					this.changeSupervision(ds);
				})
			)
			.subscribe();
		this.subscriptions.push(subscription, formSubscription);
	}

	orgSelected(event: { id: number; name: string }) {
		if (!event?.id || event?.id === this.organizationId) return;
		this.organizationId = event?.id;
		const urlParams = {
			tab: 'supervisor',
			organizationId: event?.id,
		};
		this.$state.transitionTo('main.measuring-points', urlParams, {
			inherit: true,
			reload: false,
			notify: false,
		});
		this.loadDataSources(event?.id);
	}

	loadDataSources(organizationId) {
		const params = { organizationId: organizationId };

		const removeAttachedMeters = (ds) => {
			ds.attachedMeters = [];
			return ds;
		};
		const excludeGroups = (ds) => ds.type == 'meter';
		const excludeGateways = (ds) => ds.meterTypeId != 6;
		const excludeCCEE = (ds) => ds.meterTypeId != 7;
		const subscription = from(this.meterService.getDataSources(params))
			.pipe(
				tap((dataSources) => {
					this.dataSources = dataSources
						? dataSources
							.map(removeAttachedMeters)
							.filter(excludeGroups)
							.filter(excludeGateways)
							.filter(excludeCCEE)
						: [];
					this.dataSourceOptions = this.dataSources.map((element) => ({
						id: element.id,
						label: element.label,
						subtext: element.uid
							? `${this.i18n.translate('global.code')}: ${element.uid}`
							: '',
					}));
					const dataSourceIds = this.dataSources
						.filter((ds) => ds.supervision)
						.map((ds) => ds.id);

					this.selectedDataSources.setValue(dataSourceIds);
					this.isOrgAdmin = this.organizationsComponent.isOrgAdmin;
					this.currentUser = this.organizationsComponent.currentUser;
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	updateSupervisionBoard() {
		if (!this.organizationId) return;
		const savedDatasources = this.dataSources
			.filter((ds) => ds.supervision)
			.map((ds) => ds.id);

		if (
			JSON.stringify(savedDatasources.sort()) ===
			JSON.stringify(this.selectedDataSources.value.sort())
		)
			return;

		const subscription = from(
			this.meterService.updateSupervisionBoard(
				this.organizationId,
				this.selectedDataSources.value
			)
		)
			.pipe(
				tap((response: any) => {
					const dataSourcesIds = response.dataSources.map((ds) => ds.id);
					this.dataSources.forEach(
						(ds) => (ds.supervision = dataSourcesIds.includes(ds.id))
					);
					this.notification.success({
						msg: this.i18n.translate(
							'global.branch.supervision-update-success'
						),
					});
				}),
				catchError((error) => {
					this.notification.error({
						msg: this.i18n.translate('global.branch.supervision-update-error'),
					});
					return error;
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	toDataSourceObj(dataSourceIds: number[]) {
		return this.dataSources.filter((ds) => dataSourceIds.includes(ds.id));
	}

	changeSupervision(value) {
		this.filterDataSources = this.toDataSourceObj(value);
		this.$changeSupervision.next();
	}
}

export const ng2SupervisorComponent = {
	name: 'supervisor',
	def: downgradeComponent({
		component: SupervisorComponent,
		propagateDigest: true,
	}),
};
