import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { KpiService } from '../../services/kpi.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
const DIVISOR_ID = 1;

@Component({
	selector: 'kpi-top-bar',
	templateUrl: './kpi-topbar.component.html',
	styleUrls: ['./kpi-topbar.component.scss'],
})
export class KpiTopbarComponent implements OnChanges, OnDestroy {
	@Input() selectedOrg!: number | undefined;
	@Output() filterMetrics = new EventEmitter();
	subscriptions: Subscription[] = [];
	metrics: any[] = [];
	filteredMetrics: any[] = [];
	translations: any = {};
	previousLength = 0;
	constants: any = {};

	constructor(
		private i18n: TranslocoService,
		public cdr: ChangeDetectorRef,
		private kpiService: KpiService
	) {}

	ngOnChanges(): void {
		if (!this.selectedOrg) return;

		this.listMetrics(this.selectedOrg);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
		this.filteredMetrics = [];
	}

	listMetrics(organizationId: number) {
		this.kpiService
			.$getConstants(organizationId)
			.pipe(
				map((res) => {
					this.kpiService.metrics = res;
					this.constants = this.kpiService.transformMetrics(res);

					return [
						this.kpiService.buildMetric(this.constants.dividend, null),
						...this.constants.divisor.map((d) =>
							this.kpiService.buildMetric(this.constants.dividend, d)
						),
					];
				})
			)
			.subscribe((res) => {
				const options = res.map((m) => ({
					label: m.label[0].toUpperCase() + m.label.slice(1).toLowerCase(),
					id: m.id,
				}));
				this.filteredMetrics = options.map((o) => o.id);
				this.metrics = options;
				this.cdr.detectChanges();
			});
	}

	get filterText() {
		let msg = '';
		if (this.filteredMetrics.length > 1) {
			if (this.filteredMetrics.length === this.metrics.length) {
				msg = this.i18n.translate('kpi.table.all-metrics');
			} else {
				msg = `${this.filteredMetrics.length} ${this.i18n.translate(
					'kpi.table.metrics'
				)}`;
			}
		} else if (this.filteredMetrics.length === 0) {
			msg = `${this.metrics.length} ${this.i18n.translate(
				'kpi.table.metrics'
			)}`;
		} else {
			msg = `1 ${this.i18n.translate('kpi.table.metric').toLowerCase()}`;
		}
		return msg;
	}

	isAllMetersMarked(): boolean {
		return this.metrics.length === this.filteredMetrics.length;
	}

	// Adiciona as opções por divisor
	buildMetricForDivisor(divisor) {
		const metric = this.kpiService.buildMetric(
			this.constants.dividend,
			divisor
		);
		return metric.label.toLowerCase();
	}

	// Adiciona a opção consumo quando existir o id do divisor
	handleSpecialCase(event, selectedValues) {
		if (event.includes(DIVISOR_ID)) {
			try {
				const metric = this.kpiService.buildMetric(
					this.constants.dividend,
					null
				);
				selectedValues.push(metric.label.toLowerCase());
			} catch (error) {
				console.error('Error building metric for special case:', error);
			}
		}
	}

	emitChange(event) {
		const selectedDivisors =
			this.constants?.divisor?.filter((divisor) =>
				event.includes(divisor.id)
			) || [];
		const selectedValues = selectedDivisors.map((divisor) =>
			this.buildMetricForDivisor(divisor)
		);

		this.handleSpecialCase(event, selectedValues);
		this.filterMetrics.emit({ column: 'metric', value: selectedValues });
	}
}
