import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { StateParams, StateService } from 'angular-ui-router';
import * as moment from 'moment-timezone';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { EditorConfig } from 'webapp/app/shared/ga-editor/ga-editor.model';
import {
	Meter,
	MeterDetailsConfigs,
	MeterEditor,
	MeterType,
} from 'webapp/app/shared/models/meter.model';
import { SelectorOption } from 'webapp/app/shared/models/selector.model';
import { User } from 'webapp/app/shared/models/user.model';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { CompanyService } from 'webapp/app/shared/services/company.service';
import { LocationService } from 'webapp/app/shared/services/location.service';
import { MeterService } from 'webapp/app/shared/services/meter.service';
import { OrganizationService } from 'webapp/app/shared/services/organization.service';
import { UploadService } from 'webapp/app/shared/services/upload.service';
import { UserService } from 'webapp/app/shared/services/user.service';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';

@Component({
	selector: 'meter-details',
	templateUrl: './meter-details.component.html',
	styleUrls: ['./meter-details.component.scss'],
})
export class MeterDetailsComponent implements OnInit, OnDestroy {
	organization: number | undefined;
	isAdmin = false;
	fileTypes = ['.jpg', '.png', '.pdf'];
	user: User = {} as User;
	isSaving = false;
	subscriptions: Subscription[] = [];
	agents: Array<SelectorOption> = [];
	organizationUsers: SelectorOption[] = [];
	consumptionPhases: Array<SelectorOption> = [];
	configs: MeterDetailsConfigs = {} as MeterDetailsConfigs;
	meterEditor: MeterEditor = { meter: { data: {} } as Meter } as MeterEditor;
	meterForm!: FormGroup;
	editorConfig: EditorConfig = {
		showToolbar: true,
		toolbarButtons: [
			'justifyLeft',
			'justifyCenter',
			'justifyRight',
			'justifyFull',
			'bold',
			'italic',
			'underline',
			'strikeThrough',
			'insertUnorderedList',
			'insertOrderedList',
		],
	} as EditorConfig;

	constructor(
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(TranslocoService) private i18n,
		private meterService: MeterService,
		private userService: UserService,
		private organizationService: OrganizationService,
		private companyService: CompanyService,
		private locationService: LocationService,
		private cookie: CookieService,
		private uploadService: UploadService,
		private notification: NotificationService
	) {
		this.organization = this.$stateParams.organizationId;
		this.meterEditor.meterId = this.$stateParams.id;
		this.initFormGroup(this.meterEditor.meterId);
		this.configs.showHourInput = true;
		this.configs.showMoreFields = false;
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	ngOnInit(): void {
		this.formDisable(!this.organization);
		this.loadCurrentUser();
		this.loadMeterTypes();
		this.loadTimeZones();
		this.loadImagesFromS3();
	}

	ngAfterContentInit(): void {
		this.loadMeter();
	}

	initFormGroup(meterId) {
		const group: any = {
			assetNumber: new FormControl(''),
			companyId: new FormControl(),
			attachedMeterId: new FormControl(''),
			bidirectional: new FormControl(''),
			consumptionPhases: new FormControl([], Validators.required),
			cceeAgentCode: new FormControl(''),
			CCEEProfileId: new FormControl(''),
			cceeRepresentativeCode: new FormControl(''),
			conductorSection: new FormControl(''),
			formatedAddress: new FormControl('', Validators.required),
			image: new FormControl(''),
			label: new FormControl('', Validators.required),
			meterTypeId: new FormControl('', Validators.required),
			nominalCapacity: new FormControl(''),
			nominalVoltage: new FormControl(''),
			nominalCurrent: new FormControl(''),
			notes: new FormControl(''),
			people_count: new FormControl(''),
			size: new FormControl(''),
			timezone: new FormControl('', Validators.required),
			uid: new FormControl('', Validators.required),
			users: new FormControl(''),
		};
		if (!meterId)
			group.startDatePtBr = new FormControl('', Validators.required);
		this.meterForm = new FormGroup(group);

		const subscription = this.meterForm.controls['meterTypeId'].valueChanges
			.pipe(
				debounceTime(400),
				tap((value) => {
					const DADOS_CEE = 6;

					if (value === DADOS_CEE) {
						this.meterForm.controls['cceeAgentCode'].setValidators(
							Validators.required
						);
						this.meterForm.controls['consumptionPhases'].setValue([]);
						this.meterForm.controls['consumptionPhases'].clearValidators();
					} else {
						this.meterForm.controls['consumptionPhases'].setValidators(
							Validators.required
						);
						this.meterForm.controls['cceeAgentCode'].setValue('');
						this.meterForm.controls['cceeAgentCode'].clearValidators();
					}
					this.meterForm.controls['consumptionPhases'].updateValueAndValidity();
					this.meterForm.controls['cceeAgentCode'].updateValueAndValidity();

					this.onTypeChange();
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	// ============================= load sectors

	orgSelected(event: { id: number; name: string }) {
		this.organization = event?.id;
		this.meterEditor.meter.organizationId = event?.id ? event?.id : null;
		this.formDisable(!this.organization);

		if (!this.organization) return;

		this.loadUsers();
		this.loadMeters();
	}

	formDisable(value: boolean) {
		if (value) {
			this.meterForm.disable();
		} else {
			this.meterForm.enable();
		}
	}

	companySelected(event: { id: number; name: string }) {
		this.meterForm.controls['companyId'].setValue(event.id);
		this.loadCompaniesAgents();
	}

	// ============================= request variables

	loadCurrentUser() {
		try {
			this.userService.getCurrentUser().then((user) => {
				this.user = user;
				this.isAdmin = user.isAdmin;
			});
		} catch (error) {
			this.notification.error({ msg: 'Erro ao carregar usuário' });
		}
	}

	loadUsers() {
		try {
			const params = { organizationId: this.organization };
			this.organizationService.getUsersByOrganization(params).then((users) => {
				if (!users || !users.length) throw new Error();
				const foundUsers = users.map(this.toPresentationUser) || [];
				this.organizationUsers = this.optionsFactory(
					foundUsers,
					'id',
					'name',
					false,
					'subtext'
				);
			});
		} catch (error) {
			this.notification.error({ msg: 'Erro ao carregar usuários' });
		}
	}

	loadMeterTypes() {
		this.meterService.getMetersTypes().then((response: MeterType[]) => {
			this.meterEditor.meterTypes = response;
			this.meterEditor.meterTypesOptions = this.optionsFactory(
				response,
				'id',
				'label',
				true
			);
			this.loadConsumptionPhases();
		});
	}

	loadMeter() {
		if (this.meterForm.controls['startDatePtBr'])
			this.meterForm.controls['startDatePtBr'].setValue(null);

		if (!this.meterEditor.meterId) {
			if (!this.isAdmin)
				this.meterForm.controls['users'].setValue([this.user.id]);

			this.evalConditionalFormFields();
			return;
		}

		this.meterService
			.getMeter({ meterId: this.meterEditor.meterId })
			.then((meter) => {
				if (!meter) {
					this.notification.error({
						msg: 'Ponto de Medição Inexistente - Redirecionando para Listagem',
					});
					this.$state.transitionTo(
						'registrations',
						{ tab: 'dataSources' },
						{ reload: false }
					);
					return;
				}
				this.organization = meter.organizationId
					? meter.organizationId
					: this.user.organization;

				this.meterForm.controls['meterTypeId'].setValue(meter.meterTypeId);

				this.loadMeters().then(() => {
					if (meter) {
						this.meterEditor.meter = { ...meter };
						if (!this.meterEditor.meter.data) this.meterEditor.meter.data = {};

						Object.keys(meter).forEach((key) => {
							if (this.meterForm.controls[key] && key !== 'meterTypeId')
								this.meterForm.controls[key].setValue(meter[key]);
						});

						Object.keys(meter.data).forEach((key) => {
							if (this.meterForm.controls[key] && key !== 'image')
								this.meterForm.controls[key].setValue(meter.data[key]);
						});
						if (typeof this.meterForm.controls['users']?.value[0] !== 'number')
							this.meterForm.controls['users'].setValue(
								meter.users.map((user) => user.id)
							);
					}
				});
			});
	}

	loadImagesFromS3() {
		if (!this.meterEditor.meterId) return;
		this.meterService
			.getMeterFiles(this.meterEditor.meterId)
			.then((responseFiles) => {
				this.meterEditor.meterFiles = responseFiles;
				const urls: string[] = [];
				for (const file of responseFiles) {
					this.subscriptions.push(
						this.uploadService
							.getFileFromS3(file)
							.pipe(
								tap((response: any) => {
									urls.push(response.url);
								})
							)
							.subscribe()
					);
				}
				this.meterEditor.uploadedFilesURL = urls;
			});
	}

	loadMeters() {
		const query = this.organization
			? {
				organization: true,
				organizationId: this.organization,
			  }
			: {};
		let foundMeters: Meter[] = [];
		return this.meterService.getMeters(query).then((meters: Meter[]) => {
			foundMeters = meters || [];
			this.meterEditor.meters = this.optionsFactory(
				foundMeters,
				'id',
				'label',
				false
			);
		});
	}

	onAttachedMeter(sourceId) {
		this.meterService.getMeter({ meterId: sourceId }).then((meter) => {
			this.meterEditor.meter.attachedMeter = meter;
		});
	}

	loadConsumptionPhases() {
		this.consumptionPhases = [];
		const defaultPhases = ['A', 'B', 'C'];

		this.consumptionPhases = defaultPhases.map((phase) => ({
			label: `${this.i18n.translate('meter-page.label.phase')} ${phase}`,
			id: phase,
		}));
	}

	loadCompaniesAgents() {
		const items: Array<{ id: number; label: string }> = [];
		const companies = !this.meterForm.controls['companyId'].value
			? this.companyService.listCompanies
			: this.companyService.listCompanies.filter(
				(company) => company.id === this.meterForm.controls['companyId'].value
			  );

		companies.forEach((company) => {
			if (
				Array.isArray(company.CCEEProfiles) &&
				company.CCEEProfiles.length > 0
			) {
				company.CCEEProfiles.forEach((profile) => {
					items.push({
						id: profile.id,
						label: `${company.tradingName}/${profile.name}`,
					});
				});
			}
		});
		this.agents = items;
	}

	loadTimeZones() {
		const localTimezone = moment.tz.guess();
		if (
			!this.meterForm.controls['timezone'].value ||
			!this.meterForm.controls['timezone'].value[0]
		) {
			this.meterForm.controls['timezone'].setValue(localTimezone);
		}
		this.configs.timezones = moment.tz.names();
		let lang = this.user.language ? this.user.language : 'pt-BR';
		moment.locale(lang);
		if (lang == 'pt-Br' || lang == 'pt_BR') {
			lang = 'br';
		} else {
			lang = 'en';
		}
	}

	getLocation(query) {
		const params = { language: 'pt-br', address: query };

		this.locationService.getAddress(params).then(({ results }) => {
			if (!results.length) this.configs.locations = [];
			this.configs.locations = results.map((item) => item.formatted_address);
		});
	}

	setAddressSelected() {
		const addressComponents = {
			route: 'street',
			street_number: 'number',
			sublocality_level_1: 'district',
			administrative_area_level_2: 'city',
			administrative_area_level_1: 'state',
			country: 'country',
			postal_code: 'zipCode',
		};

		this.locationService
			.getAddress({
				language: 'pt-br',
				address: this.meterForm.controls['formatedAddress'].value,
			})
			.then(({ results }) => {
				return results.map((item) => {
					item.address_components.forEach((elem) => {
						elem.types.forEach((type) => {
							if (addressComponents[type]) {
								this.meterEditor.meter[addressComponents[type]] =
									elem.long_name;
							}
						});
					});

					this.meterEditor.meter.formatedAddress = item.formatted_address;
					this.meterEditor.meter.lat = item.geometry.location.lat;
					this.meterEditor.meter.lng = item.geometry.location.lng;
					this.locationService
						.getTimezone({
							lat: this.meterEditor.meter.lat,
							lng: this.meterEditor.meter.lng,
						})
						.then((res: any) => {
							this.meterEditor.meter.timezone = res.timeZoneId;
							this.meterForm.controls['timezone'].setValue(res.timeZoneId);
						});
				});
			});
	}

	setUsers() {
		const selectedUsers = [...this.meterForm.controls['users'].value];

		this.meterEditor.meter.users = this.organizationUsers
			.filter((user) => selectedUsers.find((id) => user.id === id))
			.map((founduser: any) => {
				return founduser
					? {
						id: founduser.id,
						name: `${founduser.label} (${founduser.subtext})`,
					  }
					: ({} as { id: any; name: string });
			});
	}

	// ============================= save meter

	onSubmit() {
		this.isSaving = true;
		this.setSubmitFields();

		if (this.meterEditor.meterFiles && this.meterEditor.meterFiles.length) {
			const toDelete = this.meterEditor.meterFiles.filter(
				(file) =>
					!this.meterEditor.uploadedFilesURL.some((url) =>
						url.includes(file.fileName)
					)
			);
			toDelete.map((file) => this.meterService.deleteMeterFiles(file));
		}
		this.meterService
			.saveMeter(this.meterEditor.meter)
			.then((response) => {
				this.cookie.set(
					'flowMessage-dataSource',
					this.i18n.translate('meter-page.validation.success')
				);
				if (
					!this.meterForm.controls['image'].value ||
					!this.meterForm.controls['image'].value.length
				) {
					this.$state.transitionTo('registrations', {
						tab: 'dataSources',
						organizationId: this.meterEditor.meter.organizationId,
					});
				}

				this.subscriptions.push(
					this.uploadService
						.uploadFileToS3(
							this.meterForm.controls['image'].value,
							'meter',
							response.meter.id
						)
						.pipe(
							tap(() => {
								this.$state.transitionTo('registrations', {
									tab: 'dataSources',
									organizationId: this.meterEditor.meter.organizationId,
								});
							})
						)
						.subscribe()
				);
			})
			.catch((error) => {
				this.isSaving = false;
				this.notification.showErrorMessages(error);
			});
	}

	setSubmitFields() {
		const dataFields = [
			'cceeAgentCode',
			'cceeRepresentativeCode',
			'assetNumber',
			'nominalCapacity',
			'nominalVoltage',
			'nominalCurrent',
			'conductorSection',
			'size',
			'people_count',
		];
		for (const key of Object.keys(this.meterForm.controls)) {
			if (key === 'image') continue;
			if (dataFields.includes(key)) {
				this.meterEditor.meter.data[key] = this.meterForm.controls[key].value;
				continue;
			}
			if (
				this.meterForm.controls[key].value ||
				typeof this.meterForm.controls[key].value === 'boolean'
			)
				this.meterEditor.meter[key] = this.meterForm.controls[key].value;
		}

		this.meterEditor.meter.startDate =
			this.meterForm.controls['startDatePtBr'] &&
			this.meterForm.controls['startDatePtBr'].value
				? moment(
					this.meterForm.controls['startDatePtBr'].value,
					'DD/MM/YYYY - HH:mm'
				  ).format()
				: this.meterEditor.meter.installationStartDate;
		this.setUsers();
	}

	// ============================= helpers

	onTypeChange() {
		if (!this.meterEditor.meterTypes) return;

		const selectedType = this.meterEditor.meterTypes.find(
			(type) => type.id === this.meterForm.controls['meterTypeId'].value
		);
		if (!selectedType || this.meterEditor.meter.meterType === selectedType)
			return;
		this.meterEditor.meter.meterType = selectedType;
		this.evalConditionalFormFields();
	}

	evalConditionalFormFields() {
		//Default Options
		this.configs.showMoreFields = false;
		this.configs.showPhases = true;
		this.configs.showExtras = true;
		this.configs.showPhaseImage = false;
		this.configs.showTechSpecs = false;
		this.configs.showAttachedMeter = false;
		this.configs.showCCEEFields = false;

		const meterType = this.meterEditor.meter.meterType;
		if (!meterType) return;

		switch (meterType.label) {
		case 'consumption':
			this.configs.showMoreFields = true;
			this.configs.showTechSpecs = true;
			break;
		case 'CCEE_meter':
			this.configs.showMoreFields = true;
			this.configs.showPhases = false;
			this.configs.showCCEEFields = true;
			break;
		case 'generation':
			this.configs.showMoreFields = true;
			this.configs.showPhaseImage = true;
			this.configs.showAttachedMeter = true;
			break;
		case 'consumption_generation':
			this.configs.showMoreFields = true;
			this.configs.showPhaseImage = true;
			this.meterForm.controls['bidirectional'].setValue(true);
			break;
		case 'clone_consumption':
		case 'gateway':
			this.configs.showMoreFields = true;
			break;
		case 'clone_generation':
			this.configs.showMoreFields = true;
			this.configs.showAttachedMeter = true;
			break;
		default:
			break;
		}
	}

	toPresentationUser(user) {
		return {
			id: user.id,
			name: `${user.firstName} ${user.lastName}`,
			subtext: user.email,
		};
	}

	backTo() {
		this.$state.transitionTo(
			'registrations',
			{ tab: 'dataSources', organizationId: this.organization },
			{
				inherit: false,
				reload: false,
				notify: false,
			}
		);
	}

	cancel() {
		this.$state.transitionTo('registrations', {
			tab: 'dataSources',
			organizationId:
				this.organization || this.meterEditor.meter.organizationId,
		});
	}

	optionsFactory(
		arr: Array<any>,
		fieldId: string,
		fieldName: string,
		meterPagePrefix = false,
		fieldSubtext = ''
	) {
		const optionsArr = arr.map((element) => ({
			id: element[fieldId],
			label: meterPagePrefix
				? this.i18n.translate(`meter-page.label.type-${element[fieldName]}`)
				: element[fieldName],
			subtext: element[fieldSubtext] || '',
		}));

		return meterPagePrefix ? optionsArr.sort(this.compareDesc) : optionsArr;
	}

	compareDesc(a, b) {
		if (a.label > b.label) {
			return -1;
		}
		if (a.label < b.label) {
			return 1;
		}
		return 0;
	}
}

export const ng2MeterDetailsComponent = {
	name: 'meterDetails',
	def: downgradeComponent({
		component: MeterDetailsComponent,
		propagateDigest: true,
	}),
};
