import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'root' })
export class TaskService {
	constructor(private http: HttpClient) {}

	getTasks(params) {
		return this.http
			.get(`${environment.backendUrl}/api/tasks`, { params: params })
			.toPromise()
			.then((res: any) => {
				return res?.tasks || [];
			});
	}

	createTask(params) {
		return this.http
			.post(`${environment.backendUrl}/api/tasks`, params)
			.toPromise()
			.then((res: any) => {
				return res?.task;
			});
	}

	deleteTask(params) {
		const p: any = {
			organizationId: params.organizationId || params.organization,
		};
		return this.http
			.delete(`${environment.backendUrl}/api/tasks/` + params.id, { params: p })
			.toPromise()
			.then((res: any) => {
				return res?.task;
			});
	}

	updateTask(params) {
		return this.http
			.put(`${environment.backendUrl}/api/tasks/` + params.id, params)
			.toPromise()
			.then((res: any) => {
				return res?.task;
			});
	}
}

export const ng2TaskService = {
	name: TaskService.name,
	def: downgradeInjectable(TaskService),
};
