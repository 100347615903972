import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'root' })
export class ConsumptionService {
	constructor(private http: HttpClient) {}

	consumptionClassification(params) {
		if (params.classificationId) {
			return this.http
				.get(
					`${environment.backendUrl}/api/meters/` +
						params.meterId +
						'/consumptions/classifications/' +
						params.classificationId,
					{ params: params }
				)
				.toPromise()
				.then((res: any) => res?.consumptions);
		}
		return this.http
			.get(
				`${environment.backendUrl}/api/meters/` +
					params.meterId +
					'/consumptions/classifications/',
				{ params: params }
			)
			.toPromise()
			.then((res: any) => res?.consumptions);
	}

	consumptionClassificationTotal(params) {
		return this.http
			.get(
				`${environment.backendUrl}/api/meters/` +
					params.meterId +
					'/consumptions/classifications/',
				{ params: params }
			)
			.toPromise();
	}

	consumptionAppliance(params) {
		if (params.name) {
			return this.http
				.get(
					`${environment.backendUrl}/api/meters/` +
						params.meterId +
						'/consumptions/appliances/' +
						params.name,
					{ params: params }
				)
				.toPromise()
				.then((res: any) => res?.consumptions);
		}
		return this.http
			.get(
				`${environment.backendUrl}/api/meters/` +
					params.meterId +
					'/consumptions/appliances/',
				{ params: params }
			)
			.toPromise()
			.then((res: any) => res?.consumptions);
	}

	consumptionApplianceTotal(params) {
		return this.http
			.get(
				`${environment.backendUrl}/api/meters/` +
					params.meterId +
					'/consumptions/appliances/',
				{ params: params }
			)
			.toPromise();
	}
}

export const ng2ConsumptionService = {
	name: ConsumptionService.name,
	def: downgradeInjectable(ConsumptionService),
};
