<div class="content">
	<div class="description">
		<div class="description__texts">
			<span class="description__texts__title">{{
				"global.branch.supervisor" | transloco
			}}</span>
			<span class="description__texts__subtitle">{{
				"global.branch.supervisor-sub" | transloco
			}}</span>
		</div>
	</div>

	<div class="row col-lg-12 m-0 p-0">
		<autocomplete-orgs
			[idSelected]="organizationId"
			(orgEmitter)="orgSelected($event)"
		></autocomplete-orgs>
	</div>
	<div class="col-lg-12 m-0 p-0" [ngClass]="{ hidden: !organizationId }">
		<ga-autocomplete-chips
			class="grow-field"
			[title]="'global.measurement-points' | transloco"
			[placeholder]="'global.select' | transloco"
			[tooltip]="'global.branch.meter-tooltip' | transloco"
			[options]="dataSourceOptions"
			[disabled]="!currentUser.isAdmin && !isOrgAdmin"
			[formControl]="selectedDataSources"
		>
		</ga-autocomplete-chips>
	</div>
	<div *ngIf="!organizationId" class="no-organization">
		<ga-tag-icon [icon]="'error'" [size]="22"></ga-tag-icon>
		<span class="message">
			{{ "global.branch.no-organization" | transloco }}
		</span>
	</div>

	<div class="empty" *ngIf="organizationId && !filterDataSources.length">
		<ga-tag-icon [size]="22" [icon]="'error'"></ga-tag-icon>
		<h3 class="empty__title">
			{{ "global.branch.no-content" | transloco }}
		</h3>
		<hr />
		<h4 class="empty__tips">
			<span>{{
				"global.branch.no-content-sub" +
					(currentUser.isAdmin || isOrgAdmin ? "1" : "2") | transloco
			}}</span>
		</h4>
	</div>
	<div class="realtime-content">
		<div
			class="realtime-widget"
			*ngFor="
				let dataSource of filterDataSources;
				let first = first;
				let last = last
			"
		>
			<realtime-advanced
				[meter]="dataSource"
				[timezone]="dataSource.timezone"
				[show-meter-label]="true"
				[attached-meters]="dataSource.attachedMeters"
			>
			</realtime-advanced>
		</div>
	</div>
</div>
