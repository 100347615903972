import { Injectable } from '@angular/core';
import { Base64Image, EmitFiles } from './upload-image.model';

@Injectable({
	providedIn: 'root',
})
export class GaUploadImageService {
	async convertFilesToBase64(files: File[]): Promise<EmitFiles> {
		const pFiles: Promise<string>[] = [];
		for (const key of files) {
			pFiles.push(this.convertFileToBase64(key));
		}
		const base64Files = await Promise.all(pFiles);
		return {
			files: files,
			base64: base64Files,
		};
	}

	convertBase64ToFiles(base64List: Base64Image[]) {
		const files: File[] = [];
		for (let i = 0; i < base64List.length; i++) {
			const base64Data = this.transformBase64Obj(base64List[i].base64Data);
			const fileName = base64List[i].fileName;
			const fileType = base64List[i].fileType;
			// Validate and sanitize the Base64 data
			if (!this.isValidBase64(base64Data)) {
				console.warn(`Skipping invalid Base64 data at index ${i}`);
				continue;
			}
			// Convert the Base64 data to a Blob
			const byteCharacters = atob(base64Data);
			const byteNumbers = new Array(byteCharacters.length);
			for (let j = 0; j < byteCharacters.length; j++) {
				byteNumbers[j] = byteCharacters.charCodeAt(j);
			}
			const byteArray = new Uint8Array(byteNumbers);
			const blob = new Blob([byteArray], { type: fileType });
			// Create a new File object
			const file = new File([blob], fileName, { type: fileType });
			files.push(file);
		}
		return files;
	}

	private isValidBase64(base64Data: string): boolean {
		// Check if the string is correctly encoded as Base64
		try {
			return btoa(atob(base64Data)) === base64Data;
		} catch (error) {
			return false;
		}
	}

	private convertFileToBase64(file: File): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e: any) => {
				if (e.target && typeof e.target.result === 'string') {
					resolve(e.target.result as string);
				} else {
					reject('Failed to convert the file to Base64.');
				}
			};
			reader.onerror = () => {
				reject('Failed to read the file.');
			};
			reader.readAsDataURL(file);
		});
	}

	transformBase64Obj(base64: string) {
		return base64.split(',')[1];
	}
}
