<div class="dialog">
	<div class="panel-heading-error" mat-dialog-title>
		<button type="button" class="close" (click)="dialogRef.close()">
			<span aria-hidden="true">&times;</span>
			<span class="sr-only">Close</span>
		</button>
		<h4 class="modal-title">{{ title }}</h4>
	</div>
	<div class="modal-body">
		<div mat-dialog-content class="desc-body">
			<h4>{{ message }}</h4>
		</div>
		<hr />
		<div mat-dialog-actions style="text-align: end; padding: 5px">
			<button
				type="button"
				class="btn btn-danger"
				style="margin-right: 5px"
				(click)="onDismiss()"
			>
				{{ btnDanger }}
			</button>
			<button type="submit" class="btn btn-default" (click)="onConfirm()">
				{{ btnSucess }}
			</button>
		</div>
	</div>
</div>
