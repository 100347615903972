import { Injectable } from '@angular/core';
import { chartLangDef } from './lang';
import * as Highcharts from 'highcharts';

@Injectable({
	providedIn: 'root',
})
export class ChartConfigService {
	setLanguage(lang: string) {
		Highcharts.setOptions({
			lang: chartLangDef[lang],
		});
	}
}
