import { Component, Inject } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { OrganizationFormService } from './organization-form.service';
import { EditorConfig } from 'webapp/app/shared/ga-editor/ga-editor.model';
import { StateParams, StateService } from 'angular-ui-router';
import { RoleService } from 'webapp/app/shared/services/role.service';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { OrganizationService } from 'webapp/app/shared/services/organization.service';
import { SendOrganization } from './organization.model';
import { OrganizationThemeService } from './theme/organization-theme.service';
import { Theme } from './theme/theme.model';
import { Subscription } from 'rxjs';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { TranslocoService } from '@ngneat/transloco';
@Component({
	selector: 'app-organization-form',
	templateUrl: './organization-form.component.html',
	styleUrls: ['./organization-form.component.scss'],
})
export class OrganizationFormComponent /* implements OnInit */ {
	isEdit = false;
	orgForm!: FormGroup;
	featuresRoles: any[] = [];
	theme!: Theme;
	$changeThemeSubs: Subscription = new Subscription();
	editorConfig: EditorConfig = {
		showToolbar: true,
		toolbarButtons: [
			'justifyLeft',
			'justifyCenter',
			'justifyRight',
			'justifyFull',
			'bold',
			'italic',
			'underline',
			'strikeThrough',
			'insertUnorderedList',
			'insertOrderedList',
		],
	} as EditorConfig;

	constructor(
		private formService: OrganizationFormService,
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		@Inject(NG_ROUTER) private $state: StateService,
		private organizationService: OrganizationService,
		private themeService: OrganizationThemeService,
		private roleService: RoleService,
		private notification: NotificationService,
		private i18n: TranslocoService
	) {
		this.orgForm = this.formService.initFormGroup();
		this.editMode();
		this.listFeatures();
		//this.addUser();
	}

	editMode() {
		this.isEdit = this.$stateParams?.id ? true : false;
		if (this.isEdit) this.findOrganization();
		else this.themeService.setDefaultTheme();
	}

	ngOnInit(): void {
		this.getTheme();
	}

	onSubmit() {
		this.isEdit ? this.update() : this.create();
	}

	sendInviteUser() {
		this.$state.transitionTo('main.user-invite', {
			id: null,
			organizationId: this.$stateParams?.id,
		});
	}

	get usersForm() {
		return this.orgForm.controls['users'] as FormArray;
	}

	addUser() {
		this.formService.addUser();
	}

	removeUser(i: number) {
		this.formService.removeUser(i);
	}

	backTo() {
		this.$state.transitionTo('registrations', {
			tab: 'organizations',
		});
	}

	private getTheme() {
		this.$changeThemeSubs = this.themeService.$theme.subscribe(
			(theme: Theme) => {
				this.theme = theme;
			}
		);
	}

	private create() {
		const { id, ...org } = this.orgForm.value;
		const sendOrg: SendOrganization = {
			...org,
			styles: this.theme,
		};
		this.organizationService.create(sendOrg).subscribe(
			() => {
				const msg = this.i18n.translate('organization.msgs.save-sucess');
				this.notification.success({ msg });
				this.backTo();
			},
			(err) => {
				this.notification.showErrorMessages(err);
			}
		);
	}

	private update() {
		const sendOrg: SendOrganization = {
			...this.orgForm.value,
			styles: this.theme,
		};
		this.organizationService.update(<number>sendOrg.id, sendOrg).subscribe(
			() => {
				const msg = this.i18n.translate('organization.msgs.edit-sucess');
				this.notification.success({ msg });
				this.backTo();
			},
			(err) => {
				this.notification.showErrorMessages(err);
				console.error(err);
			}
		);
	}

	private listFeatures() {
		const params = { isRoleOrganization: true };
		this.roleService.$getRoles(params).subscribe((roles: any) => {
			this.featuresRoles = roles;
		});
	}

	private findOrganization() {
		if (!this.isEdit) return;
		const id = this.$stateParams?.id;
		this.organizationService.$getOrganization(id).subscribe((org) => {
			this.orgForm = this.formService.addOrgToForm(this.orgForm, org);
			this.themeService.$newState = org.styles;
		});
	}

	ngOnDestroy() {
		this.themeService.removeFromCookie();
		this.$changeThemeSubs.unsubscribe();
	}
}

export const ng2OrganizationFormComponent = {
	name: 'ngOrganizationForm',
	def: downgradeComponent({
		component: OrganizationFormComponent,
		propagateDigest: true,
	}),
};
