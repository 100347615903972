import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Tag } from 'webapp/app/dashboard/registrations/tags/tag-details/tag.model';
import { environment } from 'webapp/environments/environment';

declare let getComputedStyle: any;

export enum PrintTag {
	TASK = 'TASK',
	USER = 'USER',
}

export interface TagParam {
	organization: number;
}

export interface PostTag {
	organization: number;
	target?: PrintTag;
	label: string;
	color?: string;
}

@Injectable({ providedIn: 'root' })
export class TagService {
	constructor(private http: HttpClient) {}

	async getTagsByOrganization(tagParams: TagParam) {
		const params = new HttpParams().set('organization', tagParams.organization);
		return this.http
			.get<Tag[]>(`${environment.backendUrl}/api/tags`, { params })
			.toPromise();
	}

	getTag(tagId: number) {
		return this.http.get<Tag>(`${environment.backendUrl}/api/tags/${tagId}`);
	}

	async createTag(tagParams: PostTag) {
		return this.http
			.post(`${environment.backendUrl}/api/tags`, tagParams)
			.toPromise();
	}

	async editTag(id, tagParams: PostTag) {
		return this.http
			.put(`${environment.backendUrl}/api/tags/${id}`, tagParams)
			.toPromise();
	}

	async deleteTag(id) {
		return this.http
			.delete(`${environment.backendUrl}/api/tags/${id}`)
			.toPromise();
	}

	async findTagsByTaggedObject(taggedObj: any, organizationId: number) {
		const params = new HttpParams()
			.set('target', taggedObj.target)
			.set('targetId', taggedObj.targetId);
		return this.http
			.get(`${environment.backendUrl}/api/tags/tagged/${organizationId}`, {
				params,
			})
			.toPromise();
	}

	async listTagsandObjectsByOrganization(tagParams: TagParam) {
		const params = new HttpParams().set('organization', tagParams.organization);
		return this.http
			.get<any[]>(`${environment.backendUrl}/api/tagslist`, { params })
			.toPromise();
	}

	get tagsColor(): any[] {
		const colorElement = document.querySelector(':root');
		const cssStyles = getComputedStyle(colorElement);

		return [
			{
				name: 'primary',
				color: String(cssStyles.getPropertyValue('--primary-color')).trim(),
			},
			{
				name: 'secondary',
				color: String(cssStyles.getPropertyValue('--secondary-color')).trim(),
			},
			{ name: 'accent', color: '#C74632' },
			{ name: 'alert', color: '#FEC51D' },
			{ name: 'orange', color: '#D1660F' },
			{ name: 'blue', color: '#016895' },
			{ name: 'green', color: '#417865' },
			{ name: 'doing', color: '#7A863B' },
			{ name: 'danger', color: '#42081B' },
			{ name: 'purple', color: '#350D36' },
		];
	}

	getRandomColor(): string {
		return this.tagsColor[Math.floor(Math.random() * this.tagsColor.length)];
	}
}

export const ng2TagService = {
	name: TagService.name,
	def: downgradeInjectable(TagService),
};
