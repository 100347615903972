import * as Highcharts from 'highcharts';

function formatterYAxis(value: string, type: string) {
	return type == 'value' ? 'R$ ' + value : value + ' kWh ';
}

function formatter(this: any, currencyType, name: string) {
	if (currencyType === 'value') {
		return `${name}: R$ ${this.options.y?.toFixed(2)}<br>`;
	} else {
		return `${name}: ${this.options.y} kWh<br>`;
	}
}

function comparationFormatter(this: any, currencyType, installs) {
	let tooltip = '';
	tooltip = `<span style="color: var(--secondary-color); font-weight:600">
                ${this.x.toUpperCase()}
              </span><br/>`;
	installs.forEach((install, i) => {
		tooltip =
			tooltip +
			`<br> <span style="color:var(--primary-color); font-weight:600">
                ${install}
                </span><br>`;

		let lastY = 0;
		this.points.forEach((point, pi) => {
			const label = point.series.name.split(':')[1];
			if (label.trim() === install) {
				if (currencyType === 'value') {
					tooltip =
						tooltip +
						`<b>${
							point.series.name.split(':')[0]
						}:</b> R$ ${point.y.toLocaleString('pt-BR')}`;
				} else {
					tooltip =
						tooltip +
						`<b>${
							point.series.name.split(':')[0]
						}:</b> ${point.y.toLocaleString('pt-BR')} kWh`;
				}
			}
			if (Math.abs(point.series.index % 2) === 0) {
				lastY = point.y;
			} else if (Math.abs(point.series.index % 2) !== 0) {
				let diff = 0;
				diff = lastY - point.y;
				if (installs.length - 1 === i) {
					if (diff >= 0) {
						if (currencyType === 'value') {
							tooltip =
								tooltip +
								` <span style="color: var(--brand-colors---brand-500)"> (R$ ${diff.toLocaleString(
									'pt-BR'
								)}) </span><br>`;
						} else {
							tooltip =
								tooltip +
								` <span style="color: var(--brand-colors---brand-500)"> (${diff.toLocaleString(
									'pt-BR'
								)} kWh) </span><br>`;
						}
					} else {
						if (currencyType === 'value') {
							tooltip =
								tooltip +
								` <span style="color: var(--error---error-500)"> (R$ ${diff.toLocaleString(
									'pt-BR'
								)}) </span><br>`;
						} else {
							tooltip =
								tooltip +
								` <span style="color: var(--error---error-500)"> (${diff.toLocaleString(
									'pt-BR'
								)} kWh) </span><br>`;
						}
					}
				}
			}
			tooltip = tooltip + '<br>';
		});
	});
	return tooltip;
}

function chartsOptions(
	currencyType,
	categories,
	consumptionBar,
	executedConsumptionBar,
	baseConsumption?,
	measurementConsumption?,
	compareConsumptionArea?
): any {
	return {
		chart: {
			zoomType: 'xy',
			width: 700,
			style: {
				textAlign: 'center',
			},
		},
		title: { text: '' },
		xAxis: [
			{
				categories: categories,
				crosshair: true,
			},
		],
		yAxis: [
			{
				// Primary yAxis
				labels: {
					format: formatterYAxis('{value}', currencyType),
				},
				title: {
					text: 'Consumo total / Economia',
					style: { color: 'var(--primary-color)' },
				},
			},
			{
				// Secondary yAxis
				title: {
					text: 'Consumo setorial',
				},
				labels: {
					format: formatterYAxis('{value}', currencyType),
				},
				opposite: true,
			},
		],

		credits: { enabled: false },
		plotOptions: {
			areasplinerange: {
				marker: {
					enabled: false,
				},
			},
			line: {
				marker: { enabled: false },
			},
		},
		series: [
			{
				name: 'Consumo previsto',
				color: 'var(--grayscale---gray-400)',
				type: 'column',
				stack: 1,
				yAxis: 1,
				data: consumptionBar,
				tooltip: {
					pointFormatter: function () {
						return formatter.call(this, currencyType, 'Consumo previsto');
					},
				},
			},
			{
				name: 'Consumo realizado',
				color: 'var(--success---success-500)',
				type: 'column',
				stack: 1,
				yAxis: 1,
				data: executedConsumptionBar,
				tooltip: {
					pointFormatter: function () {
						return formatter.call(this, currencyType, 'Consumo realizado');
					},
				},
			},

			{
				name: 'Linha de base',
				type: 'spline',
				color: 'var(--primary-color)',
				data: baseConsumption,
				tooltip: {
					pointFormatter: function () {
						return formatter.call(this, currencyType, 'Linha de base');
					},
				},
			},
			{
				name: 'Consumo médio',
				type: 'spline',
				color: 'var(--success---success-500)',
				data: measurementConsumption,
				tooltip: {
					pointFormatter: function () {
						return formatter.call(this, currencyType, 'Consumo médio');
					},
				},
			},
			{
				name: 'Economia',
				color: Highcharts.color('var(--lighter-primary)').setOpacity(0.8).get(),
				type: 'areasplinerange',
				data: compareConsumptionArea,
				tooltip: {
					pointFormatter: function formatter(this: any) {
						const total = <any>this.high - <any>this.low;
						const percent =
							((<any>this.high - <any>this.low) * 100) / <number>this.high;
						if (currencyType === 'value') {
							return `Economia: R$ ${total.toFixed(2)} (${percent.toFixed(
								1
							)}%)`;
						} else {
							return `Economia: ${total.toFixed(2)} kWh (${percent.toFixed(
								1
							)}%)`;
						}
					},
				},
			},
		],
	};
}

function comparationChartOptions(
	currencyType,
	categories,
	expected1,
	expected2,
	executed1,
	executed2,
	baseLine1,
	baseLine2,
	measurementLine1,
	measurementLine2,
	listInstall
): any {
	return {
		chart: {
			width: 700,
			style: {
				textAlign: 'center',
			},
		},
		title: {
			text: '',
		},
		subtitle: {
			text: '',
		},
		credits: { enabled: false },
		xAxis: [
			{
				categories: categories,
				crosshair: true,
			},
		],

		yAxis: [
			{
				title: { text: 'Consumo setorial' },
				opposite: true,
				labels: {
					format: formatterYAxis('{value}', currencyType),
				},
			},
			{
				title: {
					text: 'Consumo total / economia',
					style: { color: 'var(--primary-color)' },
				},
				labels: {
					format: formatterYAxis('{value}', currencyType),
				},
			},
		],
		tooltip: {
			shared: true,
			formatter: function () {
				return comparationFormatter.call(this, currencyType, listInstall);
			},
		},
		plotOptions: {
			column: {
				grouping: false,
				shadow: false,
			},
			spline: {
				marker: { enabled: false },
			},
		},

		series: [
			{
				name: 'Consumo previsto: ' + listInstall[0],
				type: 'column',
				color: 'var(--grayscale---gray-400)',
				data: expected1,
				pointPadding: 0.2,
				yAxis: 1,
			},
			{
				name: 'Consumo previsto: ' + listInstall[1],
				type: 'column',
				yAxis: 1,
				color: 'var(--grayscale---gray-900)',
				pointPadding: 0.3,
				data: expected2,
				opacity: 0.7,
			},
			{
				name: 'Consumo realizado: ' + listInstall[0],
				type: 'column',
				color: 'var(--dataviz---lt--green)',
				yAxis: 1,
				pointPadding: 0.2,
				data: executed1,
				pointPlacement: 0.35,
			},
			{
				name: 'Consumo realizado: ' + listInstall[1],
				type: 'column',
				color: 'var(--dataviz---md--green)',
				data: executed2,
				yAxis: 1,
				pointPadding: 0.3,
				pointPlacement: 0.35,
				opacity: 0.9,
			},
			{
				name: 'Linha de base: ' + listInstall[0],
				type: 'spline',
				data: baseLine1,
				color: 'var(--dataviz---md--yellow)',
			},
			{
				color: 'var(--brand-colors---brand-700)',
				name: 'Linha de base: ' + listInstall[1],
				type: 'spline',
				data: baseLine2,
			},
			{
				color: 'var(--dataviz---md--yellow)',
				name: 'Consumo médio: ' + listInstall[0],
				type: 'spline',
				data: measurementLine1,
			},
			{
				color: 'var(--brand-colors---brand-700)',
				name: 'Consumo médio: ' + listInstall[1],
				type: 'spline',
				data: measurementLine2,
			},
		],
	};
}

export { chartsOptions, comparationChartOptions };
