import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'root' })
export class CompositionService {
	constructor(private http: HttpClient) {}

	saveFreeMarketComposition(composition) {
		return this.saveComposition('freeMarketCommon', composition);
	}

	saveConventionalComposition(composition) {
		return this.saveComposition('regulatedCommon', composition);
	}

	saveCaptiveComposition(composition) {
		return this.saveComposition('regulatedDemand', composition);
	}

	saveCommunionComposition(composition) {
		return this.saveComposition('freeMarketCommunion', composition);
	}

	saveSplitComposition(composition) {
		return this.saveComposition('freeMarketSharing', composition);
	}

	saveFreeGeneratorComposition(composition) {
		return this.saveComposition('freeMarketGenerator', composition);
	}

	saveComposition(contractType, body) {
		return !body.composition.id
			? this.createComposition(contractType, body)
			: this.updateComposition(body);
	}

	createComposition(contractType, body) {
		return this.http
			.post(`${environment.backendUrl}/api/compositions/${contractType}`, body)
			.toPromise();
	}

	updateComposition(body) {
		return this.http
			.put(
				`${environment.backendUrl}/api/compositions/${body.composition.id}`,
				body
			)
			.toPromise();
	}

	getComposition(id) {
		return this.http
			.get(`${environment.backendUrl}/api/contracts/${id}`)
			.toPromise()
			.then((res: any) => res);
	}
}

export const ng2CompositionService = {
	name: CompositionService.name,
	def: downgradeInjectable(CompositionService),
};
