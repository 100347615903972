import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GaNavigationTabsComponent } from './ga-navigation-tabs.component';
import { MaterialModule } from '@repo/projects/webapp/global/material/material.module';
import { TranslocoModule } from '@ngneat/transloco';
import { TenantModule } from '../tenant/tenant.module';

@NgModule({
	declarations: [GaNavigationTabsComponent],
	imports: [CommonModule, MaterialModule, TranslocoModule, TenantModule],
	exports: [GaNavigationTabsComponent],
})
export class GaNavigationTabsModule {}
