import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionCellComponent } from './templates/action-cell.component';
import { DropdownCustomCellComponent } from './templates/dropdown-custom-cell.component';
import { GATableTemplatesComponent } from './templates/ga-datatable-templates.component';
import { MaterialModule } from '@repo/projects/webapp/global/material/material.module';
import { GaInputModule } from '../ga-input/ga-input.module';
import { GaHelperModule } from '../ga-helper/ga-helper.module';
import { GaSelectorModule } from '../ga-selector/ga-selector.module';
import { GaDatatableNewComponent } from './ga-datatable-new.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GaDataTableNewService } from './service/datatable.service';
import { LinkCellComponent } from './templates/link-cell.component';
import { GaTagIconModule } from '../ga-tag-icon/ga-tag-icon.module';
import { ActiveCellComponent } from './templates/active-cell.component';
import { TenantModule } from '../tenant/tenant.module';
import { GlobalLoadingModule } from '../global-loading/global-loader.module';
import { OnlyCellComponent } from './templates/only-cell.component';
import { ColorCellComponent } from './templates/color-cell.component';
import { GaSubtableComponent } from './components/ga-subtable/ga-subtable.component';
import { GaButtonDropdownModule } from '../ga-button-dropdown/ga-button-dropdown.module';

@NgModule({
	declarations: [
		GaDatatableNewComponent,
		ActionCellComponent,
		ActiveCellComponent,
		DropdownCustomCellComponent,
		GATableTemplatesComponent,
		LinkCellComponent,
		OnlyCellComponent,
		ColorCellComponent,
		GaSubtableComponent,
	],
	imports: [
		CommonModule,
		MaterialModule,
		GaInputModule,
		GaHelperModule,
		GaSelectorModule,
		TranslocoModule,
		FormsModule,
		ReactiveFormsModule,
		GaTagIconModule,
		GaHelperModule,
		TenantModule,
		GlobalLoadingModule,
		GaButtonDropdownModule,
	],
	exports: [
		ActionCellComponent,
		ActiveCellComponent,
		DropdownCustomCellComponent,
		GATableTemplatesComponent,
		GaDatatableNewComponent,
	],
	providers: [GaDataTableNewService],
})
export class GaDatatableNewModule {}
