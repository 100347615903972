import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TopBarSearchService {
	querySubject = new BehaviorSubject<string>('');

	setQuery(value) {
		this.querySubject.next(value);
	}
}
