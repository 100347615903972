import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { ColumnType } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { environment } from 'webapp/environments/environment';
import * as moment from 'moment';
import { map, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class TariffListService {
	tariffTypes: any[] = [];
	constructor(private http: HttpClient, private i18n: TranslocoService) {}

	getTariffs(params: any = {}): Observable<any> {
		return this.http.get<any>(
			`${environment.backendUrl}/api/tariffs/${params.id || ''}`,
			{
				params,
			}
		);
	}

	getTariffSubGroups(): Observable<any> {
		return this.http.get<any>(
			`${environment.backendUrl}/api/tariffs/subGroups`
		);
	}

	getTariffInstances(tariff): Observable<any> {
		return this.http.get<any>(
			`${environment.backendUrl}/api/tariffs/${tariff.id}/instances/${
				tariff.instanceId || ''
			}`
		);
	}

	getTariffInstancesPublic(tariff): Observable<any> {
		const params = {
			tariffId: tariff.id,
		};
		return this.http.get<any>(`${environment.backendUrl}/api/tariffInstances`, {
			params,
		});
	}

	deleteTariff(tariff: any = {}): Observable<any> {
		return this.http.delete<any>(
			`${environment.backendUrl}/api/tariffs/` + tariff.id
		);
	}

	deleteTariffInstance(tariffInstance: any = {}): Observable<any> {
		return this.http.delete<any>(
			`${environment.backendUrl}/api/tariffs/` +
				tariffInstance.tariff +
				'/instances/' +
				tariffInstance.id
		);
	}

	createTariff(body): Observable<any> {
		return this.http.post<any>(`${environment.backendUrl}/api/tariffs`, body);
	}

	saveTariff(body): Observable<any> {
		return this.http.put<any>(
			`${environment.backendUrl}/api/tariffs/${body.id}`,
			body
		);
	}

	createTariffInstance(body): Observable<any> {
		return this.http.post<any>(
			`${environment.backendUrl}/api/tariffs/${body.tariff}/instances`,
			body
		);
	}

	saveTariffInstance(body): Observable<any> {
		return this.http.put<any>(
			`${environment.backendUrl}/api/tariffs/${body.tariff}/instances/${body.id}`,
			body
		);
	}

	getTariffsTypes(params): Observable<any> {
		return this.http
			.get<any>(`${environment.backendUrl}/api/tarifftypes`, {
				params,
			})
			.pipe(
				tap((res) => {
					this.tariffTypes = res || [];
				})
			);
	}

	getSubgroups(params): Observable<any> {
		return this.http.get<any>(
			`${environment.backendUrl}/api/tariffs/subGroups`,
			{
				params,
			}
		);
	}

	renderListToTable(tariffs) {
		return tariffs.map((tariff) => {
			const row = {} as any;
			row.id = tariff.id;
			row.name = tariff.name;
			row.subrows = [];
			row.modality = tariff.tariffType?.label;
			row.subgroup = tariff.subGroup?.name || '-';
			row.distributor = tariff.distributorId?.name;
			row.period = this.renderPeriod(tariff);
			row.action = this.getActions(tariff);
			return row;
		});
	}

	renderSubRows(tariffRow: any, isPrivate): Observable<any> {
		const method = isPrivate
			? 'getTariffInstances'
			: 'getTariffInstancesPublic';
		return this[method](tariffRow).pipe(
			map((response) => response.tariffInstances),
			map((tariffInstances) =>
				tariffInstances.map((instance: any, index: number) => {
					const subrow = {} as any;
					subrow.id = instance.id;
					subrow.name = `${this.i18n.translate('contract.label.cicle')} ${
						tariffInstances.length - index
					}`;
					subrow.modality = tariffRow.modality;
					subrow.subgroup = tariffRow.subgroup;
					subrow.distributor = tariffRow.distributor;
					subrow.period = this.renderPeriod(instance);
					subrow.action = this.getSubrowActions(instance);
					return subrow;
				})
			)
		);
	}

	renderPeriod(tariff) {
		if (!tariff.startDate || !tariff.endDate) {
			return '';
		}
		const startDate = moment(tariff.startDate).format('DD/MM/YYYY');
		const endDate = moment(tariff.endDate).format('DD/MM/YYYY');
		const today = moment().format();
		const secondLine = this.i18n.translate('contract.tariff.expired-text');

		if (moment(today).isBefore(tariff.endDate)) {
			return `
				<span class="flex-col">
				${startDate} - ${endDate}
				</span>
			`;
		} else {
			return `
				<span class="flex-col expired">
				${startDate} - ${endDate}
				<div>${secondLine}</div>
				</span>
			`;
		}
	}

	get columns(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('regulated-list.table.name'),
				name: 'name',
				type: 'text',
				isExpanded: true,
				expandedName: 'subrows',
				width: '20%',
			},
			{
				label: this.i18n.translate('regulated-list.table.modality'),
				name: 'modality',
				type: 'custom',
				width: '20%',
			},
			{
				label: this.i18n.translate('regulated-list.table.subgroup'),
				name: 'subgroup',
				type: 'custom',
				width: '20%',
			},
			{
				label: this.i18n.translate('regulated-list.table.distributor'),
				name: 'distributor',
				type: 'custom',
				width: '20%',
			},
			{
				label: this.i18n.translate('regulated-list.table.duration'),
				name: 'period',
				type: 'custom',
				width: '20%',
			},
			{
				label: '',
				name: 'action',
				type: 'action',
				width: '45px',
			},
		];
	}

	get subColumns(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('proinfa-list.col-name'),
				name: 'name',
				type: 'text',
				width: '20%',
			},
			{
				label: this.i18n.translate('regulated-list.table.modality'),
				name: 'modality',
				type: 'custom',
				width: '20%',
			},
			{
				label: this.i18n.translate('regulated-list.table.subgroup'),
				name: 'subgroup',
				type: 'custom',
				width: '20%',
			},
			{
				label: this.i18n.translate('regulated-list.table.distributor'),
				name: 'distributor',
				type: 'custom',
				width: '20%',
			},
			{
				label: this.i18n.translate('regulated-list.table.duration'),
				name: 'period',
				type: 'custom',
				width: '20%',
			},
			{
				label: '',
				name: 'action',
				type: 'action',
				width: '45px',
			},
		];
	}

	getActions(tariff): Action[] {
		const contentRow = {
			tariff: tariff.id,
			id: tariff.tariffInstanceId,
			startDate: tariff.startDate,
			endDate: tariff.endDate,
		};
		const actions: Action[] = [
			{
				icon: 'fa-edit',
				title: this.i18n.translate('regulated-list.edit-tariff'),
				type: 'edit',
				permission: 'tariffUpdate',
				row: contentRow,
			},
			{
				icon: 'fa-calendar-plus',
				title: this.i18n.translate('contract.table.new-cycle'),
				type: 'new-cycle',
				permission: 'tariffUpdate',
				row: contentRow,
			},
			{
				icon: 'fa-trash',
				title: this.i18n.translate('global.delete'),
				type: 'delete',
				permission: 'tariffDelete',
				row: contentRow,
			},
		];
		return actions;
	}

	getSubrowActions(tariffInstance): Action[] {
		const actions: Action[] = [
			{
				icon: 'fa-edit',
				title: this.i18n.translate('regulated-list.edit-cycle'),
				type: 'edit',
				permission: 'tariffUpdate',
				row: tariffInstance,
			},
			{
				title: this.i18n.translate('contract.table.duplicate'),
				icon: 'fa-clone',
				type: 'clone',
				permission: 'tariffUpdate',
				row: tariffInstance,
			},
			{
				icon: 'fa-trash',
				title: this.i18n.translate('global.delete'),
				type: 'delete',
				permission: 'tariffDelete',
				row: tariffInstance,
			},
		];
		if (tariffInstance.id) {
			return actions;
		} else {
			return [
				{
					icon: 'fa-eye',
					title: this.i18n.translate('global.view'),
					type: 'view',
					row: tariffInstance,
				},
			];
		}
	}
}
