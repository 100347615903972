<div class="dialog">
	<div class="panel-heading" mat-dialog-title>
		<button type="button" class="close" (click)="dialogRef.close()">
			<span aria-hidden="true">&times;</span>
			<span class="sr-only">Close</span>
		</button>
		<h4 class="modal-title">
			{{ "projects.planned-projects.title" | transloco }}
		</h4>
	</div>
	<div class="modal-body" mat-dialog-content>
		<div class="row" style="margin-top: 1rem">
			<div class="col-md-8">
				<!--  <h3 style="margin: 5px 0">Nome do projeto</h3>
        <p>{{ "projects.planned-projects.comparation-economy" | transloco }}</p> -->
			</div>
			<!-- Listar projetos previstos -->
			<div class="col-md-4 text-right" *ngIf="plannedProjects.length !== 0">
				<div
					class="btn-group"
					style="margin-top: 1rem"
					(click)="listPlannedProjectDialog()"
				>
					<button class="btn btn-default btn-color-ga">
						{{ "projects.planned-projects.open" | transloco }}
					</button>
				</div>
			</div>
			<!-- Criar projeto previsto caso não exista listagem -->
			<div class="col-md-4 text-right" *ngIf="plannedProjects.length == 0">
				<div
					class="btn-group"
					style="margin-top: 1rem"
					(click)="createPlannedProjectDialog()"
				>
					<button class="btn btn-default btn-color-ga">
						{{ "projects.planned-projects.new" | transloco }}
					</button>
				</div>
			</div>
			<div class="col-md-12" style="margin-top: 2rem">
				<div class="l-card">
					<h3>{{ "projects.planned-projects.desc-planned" | transloco }}</h3>
					<ng-container>
						<h2 [colorAccordingToNumber]="percentTotal">
							<i *ngIf="percentTotal > 0" class="fa-regular fa-arrow-up"></i>
							<i *ngIf="percentTotal < 0" class="fa-regular fa-arrow-down"></i>
							{{ percentTotal.toFixed(2) || "--" }}
						</h2>
					</ng-container>
				</div>
			</div>
			<div class="col-md-12" style="margin-top: 3rem">
				<div class="toolbar">
					<div class="toolbar-content">
						<ga-calendar-navigator
							id="date-picker"
							[calendar-options]="calendarOptions"
							[channel]="'branches'"
						></ga-calendar-navigator>
					</div>
				</div>
			</div>
			<div class="col-md-12" style="margin-top: 3rem">
				<div class="panel panel-ga">
					<div class="panel-heading">
						<h5>
							{{ "projects.planned-projects.generate-economy" | transloco }}
						</h5>
					</div>
					<div class="panel-body">
						<div class="row">
							<div class="col-md-6 flex-list border-r">
								<h4>
									{{ "projects.planned-projects.total-economy" | transloco }}
									{{ "khw_variation_1" | transloco }}
								</h4>
								<h4 style="color: var(--primary-color)">
									{{ totalEconomyConsume.kwh || "--" }} {{ "kwh" | transloco }}
								</h4>
							</div>
							<div class="col-md-6 flex-list">
								<h4>
									{{
										"projects.planned-projects.total-economy-money" | transloco
									}}
								</h4>
								<h4 style="color: var(--primary-color)">
									{{ "currency" | transloco }}
									{{ totalEconomyConsume.monetary || "--" }}
								</h4>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-md-12">
				<div class="panel panel-ga">
					<div class="panel-heading-oppacity">
						<h5>
							{{ "projects.planned-projects.planned-economy" | transloco }}
						</h5>
					</div>
					<div class="panel-body">
						<div class="row">
							<div class="col-md-6 flex-list border-r">
								<h4>
									{{
										"projects.planned-projects.total-economy-kwh" | transloco
									}}
								</h4>
								<h4>{{ this.totalEconomyPlannedConsume.kwh || "--" }} kWh</h4>
							</div>
							<div class="col-md-6 flex-list">
								<h4>
									{{
										"projects.planned-projects.total-economy-money" | transloco
									}}
								</h4>
								<h4>
									{{ "currency" | transloco }}
									{{ this.totalEconomyPlannedConsume.monetary || "--" }}
								</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
