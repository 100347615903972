import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@repo/projects/webapp/global/material/material.module';
import { GaTagIconModule } from '../ga-tag-icon/ga-tag-icon.module';
import { TranslocoModule } from '@ngneat/transloco';
import { GaLoadingComponent } from './ga-loading.component';
import { GlobalLoadingModule } from '../global-loading/global-loader.module';

@NgModule({
	declarations: [GaLoadingComponent],
	imports: [
		CommonModule,
		MaterialModule,
		GaTagIconModule,
		TranslocoModule,
		GlobalLoadingModule,
	],
	exports: [GaLoadingComponent],
	providers: [],
})
export class GaLoadingModule {}
