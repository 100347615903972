import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormFieldBaseDirective } from '../directive/form-field-base';

@Component({
	selector: 'ga-checkbox',
	templateUrl: './ga-checkbox.component.html',
	styleUrls: ['./ga-checkbox.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GaCheckboxComponent),
			multi: true,
		},
	],
})
export class GaCheckboxComponent
	extends FormFieldBaseDirective
	implements OnInit
{
	@Input() color = 'primary';
	@Input() boldLabel = false;
	mainCheckboxName = 'columns.selectall';
	allComplete = false;

	constructor() {
		super();
	}

	ngOnInit() {
		this.allComplete = this.options.every((t) => t.id);
	}

	update() {
		this.allComplete = this.options != null && this.options.every((t) => t.id);
		this.value = [...this.options];
	}

	someComplete(): boolean {
		if (this.options == null) {
			return false;
		}
		return this.options.filter((t) => t.id).length > 0 && !this.allComplete;
	}

	setAll(completed: boolean) {
		this.allComplete = completed;
		if (this.options == null) {
			return;
		}
		this.options.forEach((t) => (t.id = completed));
		this.value = [...this.options];
	}
}
