import {
	AfterViewChecked,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { ColumnType } from '../model/datatable.model';
import { GALink } from '../model/ga-link.model';

@Component({
	selector: 'ga-link-cell',
	styles: [
		`
			a {
				font-weight: 700;
				font-size: 14px;
				line-height: 19px;
				text-decoration-line: underline;
				color: var(--primary-color);
				text-wrap: nowrap;
			}
			.textCenter {
				display: flex;
				justify-content: center;
			}
			.small-text {
				white-space: nowrap;
			}
		`,
	],
	template: ` <div
			style="display: flex; flex-direction: column"
			*ngIf="this.info && !(getTypeOf(this.info) === 'string')"
		>
			<a (click)="getLink()">{{ this.info.label || '' }}</a>
			<small *ngIf="this.info.subtext" class="small-text">
				{{ this.info.subtext }}</small
			>
		</div>
		<div
			style="display: flex; flex-direction: column"
			*ngIf="this.info && getTypeOf(this.info) === 'string'"
		>
			<span>{{ this.info }}</span>
		</div>
		<section *ngIf="!this.info">
			<span>{{ '0 ' + this.column.label }}</span>
		</section>`,
})
export class LinkCellComponent implements OnInit, AfterViewChecked {
	@Input() column!: ColumnType;
	@Input() row: any;
	@Output() onSelection = new EventEmitter();

	info: GALink = {} as GALink;

	ngAfterViewChecked(): void {
		if (!this.info && this.row[this.column.name].length) {
			this.info = this.row[this.column.name][0];
		}
	}

	ngOnInit(): void {
		this.info = Array.isArray(this.row[this.column.name])
			? this.row[this.column.name][0]
			: this.row[this.column.name];
	}

	getTypeOf(value): string {
		return typeof value;
	}

	getLink() {
		this.onSelection.emit(this.info);
	}
}
