export interface EmitFiles {
	files: File[];
	base64: string[];
}

export interface Base64Image {
	base64Data: string;
	fileName: string;
	fileType: string;
}

export function isBase64Image(item: Base64Image): item is Base64Image {
	return (
		typeof item === 'object' &&
		'base64Data' in item &&
		'fileName' in item &&
		'fileType' in item
	);
}
