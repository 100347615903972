import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
	selector: '[colorAccordingToNumber]',
})
export class ColorAccordingToNumberDirective {
	@Input() colorAccordingToNumber: any;

	constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

	ngOnChanges() {
		if (this.colorAccordingToNumber >= 0) {
			this.renderer.setStyle(
				this.elementRef.nativeElement,
				'color',
				'var(--primary-color)'
			);
		} else {
			this.renderer.setStyle(
				this.elementRef.nativeElement,
				'color',
				'var(--error---error-500)'
			);
		}
	}
}
