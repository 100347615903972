import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { SourceSelectorModule } from 'webapp/app/shared/source-selector/source-selector.module';
import { EnergyBillBreadcrumb } from './energy-bill-breadcrumb/energy-bill-breadcrumb.component';
import { TagsFilterComponent } from './tags-filter/tags-filter.component';
import { ToolSourceSelectorComponent } from './tool-source-selector/tool-source-selector.component';
import { TopBarSearchComponent } from './top-bar-search/top-bar-search.component';
import { MaterialModule } from '../../../../global/material/material.module';
import { GaSelectorModule } from '../ga-selector/ga-selector.module';

@NgModule({
	declarations: [
		TopBarSearchComponent,
		ToolSourceSelectorComponent,
		EnergyBillBreadcrumb,
		TagsFilterComponent,
	],
	exports: [
		TopBarSearchComponent,
		ToolSourceSelectorComponent,
		EnergyBillBreadcrumb,
		TagsFilterComponent,
	],
	imports: [
		CommonModule,
		TranslocoModule,
		ReactiveFormsModule,
		FormsModule,
		SourceSelectorModule,
		MaterialModule,
		GaSelectorModule,
	],
})
export class HeaderSearchModule {}
