import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogOrganizationTheme } from './dialog/dialog-organization-theme';
import { Theme } from '../theme/theme.model';

@Component({
	selector: 'organization-theme',
	templateUrl: './organization-theme.component.html',
	styleUrls: ['../organization-form.component.scss'],
})
export class OrganizationThemeComponent {
	dialogRef!: MatDialogRef<any>;
	@Input() theme!: Theme;

	constructor(public dialog: MatDialog) {}

	openDialog() {
		this.dialogRef = this.dialog.open(DialogOrganizationTheme, {
			disableClose: true,
			data: this.theme,
			width: '600px',
			minHeight: '352px',
		});
	}
}
