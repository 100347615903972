import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { ColumnType } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { GASelectorLink } from 'webapp/app/shared/ga-datatable-new/model/ga-link.model';
import { environment } from 'webapp/environments/environment';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { LoaderService } from 'webapp/app/shared/global-loading/loader.service';

@Injectable({
	providedIn: 'root',
})
export class DatasourceManagerService {
	constructor(
		private http: HttpClient,
		private i18n: TranslocoService,
		private loaderService: LoaderService
	) {}

	listDataSources(params: any = {}): Observable<any> {
		return this.http.get<any>(`${environment.backendUrl}/api/datasourcelist?`, {
			params,
		});
	}

	getUserDataSources(id: number, params: any = {}): Observable<any> {
		return this.http.get<any>(
			`${environment.backendUrl}/api/datasources/${id}/users`,
			{
				params,
			}
		);
	}

	renderListToTable(datasources) {
		return datasources.map((dc) => {
			const row = {} as any;
			row.id = dc.id;
			row.label = this.renderDataSource(dc);
			row.users = [];
			row.type = dc.meterType
				? this.i18n.translate('meter-page.label.type-' + dc.meterType.label)
				: '';
			row.deviceInstallation = dc.deviceInstallation
				? this.deviceInstallationStatus(dc.deviceInstallation)
				: '';
			row.groupMeters = dc?.groupMeters
				? this.renderGroupMeters(dc)
				: undefined;
			row.action = this.getActions(dc);
			return row;
		});
	}

	renderGroupMeters(dc: any) {
		return dc.groupMeters.map((group) => {
			const subrow = {} as any;
			subrow.id = group.id;
			subrow.label = this.renderDataSource(group);
			subrow.uid = group.uid;
			subrow.users = [];
			subrow.type = group.meterType
				? this.i18n.translate('meter-page.label.type-' + group.meterType.label)
				: '';
			subrow.deviceInstallation = group.deviceInstallation
				? this.deviceInstallationStatus(group.deviceInstallation)
				: '';
			subrow.action = this.getActions(group);
			return subrow;
		});
	}

	renderDataSource(dataSource): GASelectorLink[] {
		const dropdownList: GASelectorLink[] = [];
		const dropdownObj: GASelectorLink = {} as GASelectorLink;
		dropdownObj.label = dataSource.label;
		if (dataSource.uid)
			dropdownObj.subtext = `${this.i18n.translate('global.code')}: ${
				dataSource.uid
			}`;
		dropdownObj.value = dataSource.id;
		dropdownObj.link = this.isMeter(dataSource)
			? 'main.dashboard.meter'
			: 'main.dashboard.group';
		dropdownObj.params = this.isMeter(dataSource)
			? { uid: dataSource.id }
			: { id: dataSource.id };
		dropdownList.push(dropdownObj);

		return dropdownList;
	}

	filterTable(data: any, filterText: string) {
		const filter = filterText.toLowerCase();
		const strStatus = data.deviceInstallation.status ? 'ativo' : 'inativo';
		const filterStatus =
			'ativo' === filter
				? !!data.deviceInstallation.status
				: strStatus.includes(filter);

		return (
			data.label[0].label.toLowerCase().includes(filter) ||
			data.type.toLowerCase().includes(filter) ||
			data.uid?.toLowerCase().includes(filter) ||
			`${data.users.length} Usuários`.toLowerCase().includes(filter) ||
			data.users.some((ds) => ds.label.toLowerCase().includes(filter)) ||
			filterStatus
		);
	}

	renderUserList(dataSourceId, params): GASelectorLink[] {
		const dropdownList: GASelectorLink[] = [];

		this.getUserDataSources(dataSourceId, params)
			.pipe(
				tap((response) => {
					response.users.forEach((element) => {
						const x = {
							id: element.id,
							label: `${element.firstName} ${element.lastName}`,
							subtext: element.email,
							disabled: true,
							placeholder: `${this.i18n.translate('user.label.users')}`,
							value: null,
						};
						dropdownList.push(x);
					});
				})
			)
			.subscribe();
		this.loaderService.isLoading.next(false);

		return dropdownList;
	}

	isMeter(dataSource) {
		return !!dataSource.meterType;
	}

	deviceInstallationStatus(deviceInstallation) {
		const startDate = deviceInstallation.startDate
			? DateTime.fromISO(deviceInstallation.startDate).toFormat(
					'dd/MM/yy HH:mm'
			  ) + 'h'
			: '';
		const endDate = deviceInstallation.endDate
			? DateTime.fromISO(deviceInstallation.endDate).toFormat(
					'dd/MM/yy HH:mm'
			  ) + 'h'
			: '';
		const objectStatus = {
			text: this.setPeriod(startDate, endDate),
			status: this.isMeterActive(deviceInstallation),
		};

		return objectStatus;
	}

	setPeriod(start, end) {
		if (!start && !end) return '';

		const periodStart = `${this.i18n.translate(
			'meter-page.cicle-start'
		)}: ${start}`;
		const periodEnd = end
			? `\n ${this.i18n.translate('meter-page.cicle-end')}: ${end}`
			: '';
		return periodStart + periodEnd;
	}

	get columns(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('meter-page.label.name'),
				name: 'label',
				type: 'link',
				sortBy: (item: any, property: string) => item[property][0].label,
				isExpanded: true,
				expandedName: 'groupMeters',
				width: '30%',
			},
			{
				label: this.i18n.translate('meter-page.status'),
				name: 'deviceInstallation',
				type: 'active',
				sortBy: (item: any, property: string) => item[property].status || '',
				width: '22.5%',
			},
			{
				label: this.i18n.translate('user.label.users'),
				name: 'users',
				type: 'select',
				width: '22.5%',
				sortBy: (item: any, property: string) => item[property].length,
			},
			{
				label: this.i18n.translate('meter-page.label.type'),
				name: 'type',
				type: 'text',
				width: '25%',
			},
			{
				label: '',
				name: 'action',
				type: 'action',
				width: '45px',
			},
		];
	}

	get subColumns(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('meter-page.label.meter-name'),
				name: 'label',
				type: 'link',
				width: '30%',
			},
			{
				label: this.i18n.translate('meter-page.status'),
				name: 'deviceInstallation',
				type: 'active',
				width: '22.5%',
			},
			{
				label: this.i18n.translate('user.label.users'),
				name: 'users',
				type: 'select',
				width: '22.5%',
			},
			{
				label: this.i18n.translate('meter-page.label.type'),
				name: 'type',
				type: 'text',
				width: '25%',
			},
			{
				label: '',
				name: 'action',
				type: 'action',
				width: '45px',
			},
		];
	}

	getActions(dataSource): Action[] {
		const actions: Action[] = [
			{
				icon: 'fa-edit',
				title: this.i18n.translate('meter-page.button.edit'),
				type: 'edit',
				permission: 'dataSourceUpdate',
				row: dataSource,
			},
		];
		if (this.isMeter(dataSource)) {
			if (this.isMeterActive(dataSource.deviceInstallation)) {
				actions.push({
					icon: 'fa-play-pause',
					title: this.i18n.translate('meter-page.button.inactive'),
					type: 'inactive',
					permission: 'dataSourceUpdate',
					row: dataSource,
				});
			} else {
				actions.push({
					icon: 'fa-play-pause',
					title: this.i18n.translate('meter-page.button.active'),
					type: 'active',
					permission: 'dataSourceUpdate',
					row: dataSource,
				});
			}
			actions.push({
				icon: 'fa-arrows-repeat',
				title: this.i18n.translate('meter-page.button.change'),
				type: 'meter-change',
				permission: 'dataSourceUpdate',
				row: dataSource,
			});
		}
		actions.push({
			icon: 'fa-trash',
			title: this.i18n.translate('meter-page.button.exclude'),
			type: 'delete',
			permission: 'dataSourceDelete',
			row: dataSource,
		});
		return actions;
	}

	isMeterActive(deviceInstallation) {
		return (
			deviceInstallation &&
			deviceInstallation.startDate &&
			(!deviceInstallation.endDate ||
				moment().isBefore(deviceInstallation.endDate))
		);
	}
}
