<div class="content-wrapper view">
	<div class="container-fluid">
		<!-- ------------------------- Navbar Module ------------------------- -->
		<nav class="ga-navbar navbar-default">
			<div class="navbar-content">
				<h3>
					<a (click)="backTo()">{{ "main.menu.registrations" | transloco }}</a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<a (click)="backTo()">{{
						"consuming-unit.crumb.list" | transloco
					}}</a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<span *ngIf="!isEditMode()" class="navbar-content__breadcrumb-page">{{
						"consuming-unit.crumb.new" | transloco
					}}</span>
					<span *ngIf="isEditMode()" class="navbar-content__breadcrumb-page">{{
						consumingUnit.name || consumingUnit.clientName
					}}</span>
				</h3>
			</div>
		</nav>

		<!-- ------------------------- Title description of page ------------------------- -->
		<div class="description">
			<div class="description__texts">
				<span class="description__texts__title">{{
					!isEditMode()
						? ("consuming-unit.crumb.new" | transloco)
						: ("consuming-unit.crumb.edit" | transloco)
				}}</span>
				<span class="description__texts__subtitle">{{
					!isEditMode()
						? ("consuming-unit.crumb.new-subtitle" | transloco)
						: ("consuming-unit.crumb.edit-subtitle" | transloco)
				}}</span>
			</div>
		</div>

		<!-- ------------------------- Form Module ------------------------- -->
		<form
			id="meterForm"
			[formGroup]="metadataForm"
			class="flex-col"
			(ngSubmit)="onSubmit()"
			focusInvalidInput
		>
			<!-- ------------------------- Basic Data Module ------------------------- -->
			<div id="basicData" class="row col-lg-12 module-group">
				<h3 class="basic-data__title col-lg-12">
					{{ "global.basic-info" | transloco }}
				</h3>
				<div class="row col-lg-12 p-uns">
					<!-- Organização -->
					<div class="form-group col-lg-4 col-md-6 col-sm-12">
						<autocomplete-orgs
							[idSelected]="organization"
							(orgEmitter)="orgSelected($event)"
							[isRequired]="true"
						></autocomplete-orgs>
					</div>

					<!-- Nome -->
					<div class="form-group col-lg-4 col-md-6 col-sm-12">
						<ga-input
							[title]="'consuming-unit.type.independent.name' | transloco"
							[placeholder]="
								'consuming-unit.type.independent.name-placeholder' | transloco
							"
							[disabled]="metadataForm.controls['name'].disabled"
							[required]="true"
							[showError]="
								!metadataForm.controls['name'].valid &&
								metadataForm.controls['name'].touched
							"
							formControlName="name"
							name="name"
						></ga-input>
					</div>

					<!-- Distribuidora -->
					<div class="form-group col-lg-4 col-md-6 col-sm-12">
						<ga-selector
							[title]="
								'consuming-unit.type.independent.distributor' | transloco
							"
							[placeholder]="
								'consuming-unit.type.independent.distributor-placeholder'
									| transloco
							"
							[isSearch]="true"
							(ngModelChange)="changeDistributor($event)"
							[required]="true"
							[showError]="
								!metadataForm.controls['utility'].valid &&
								metadataForm.controls['utility'].touched
							"
							[options]="distributorsOptions"
							[disabled]="metadataForm.controls['utility'].disabled"
							formControlName="utility"
							name="utility"
						></ga-selector>
					</div>
					<!-- installNumber -->
					<div class="form-group col-lg-4 col-md-6 col-sm-12">
						<ga-input
							[title]="
								'consuming-unit.type.independent.install-code' | transloco
							"
							[placeholder]="
								'consuming-unit.type.independent.installation-placeholder'
									| transloco
							"
							[disabled]="metadataForm.controls['installNumber'].disabled"
							[required]="true"
							[showError]="
								!metadataForm.controls['installNumber'].valid &&
								metadataForm.controls['installNumber'].touched
							"
							formControlName="installNumber"
							name="installNumber"
						></ga-input>
					</div>
					<!-- Vinculo -->
					<div class="form-group col-lg-4 col-md-6 col-sm-12">
						<ga-selector
							[title]="'consuming-unit.type.independent.bond' | transloco"
							[placeholder]="'global.not-own' | transloco"
							[tooltip]="
								'consuming-unit.type.independent.bond-tooltip' | transloco
							"
							[groups]="groupDataSources"
							(ngModelChange)="changeDatasources($event)"
							[groupSelect]="true"
							[isSearch]="true"
							[emptyLabel]="'global.not-own'"
							[disabled]="metadataForm.controls['dataSource'].disabled"
							formControlName="dataSource"
							name="dataSource"
						></ga-selector>
					</div>
					<!-- CNPJ -->
					<div
						*ngIf="!metadataForm.controls['dataSource'].value"
						class="form-group col-lg-4 col-md-6 col-sm-12"
					>
						<ga-input
							[title]="'consuming-unit.type.independent.cnpj' | transloco"
							[placeholder]="
								'consuming-unit.type.independent.cnpj-placeholder' | transloco
							"
							[disabled]="metadataForm.controls['cnpj'].disabled"
							[mask]="'00.000.000/0000-00'"
							[dropSpecialCharacters]="false"
							formControlName="cnpj"
							name="cnpj"
						></ga-input>
					</div>
				</div>
			</div>
			<!-- ------------------------- Acquisition Module ------------------------- -->
			<div class="row col-lg-12 module-group">
				<h3 class="basic-data__title col-lg-12">
					{{ "consuming-unit.acquisition.title" | transloco }}
				</h3>
				<div class="row col-lg-12 p-uns">
					<!-- Tipo de coleta -->
					<div class="form-group col-lg-4 col-md-4 col-sm-6">
						<ga-selector
							[title]="'consuming-unit.acquisition.type' | transloco"
							[placeholder]="'global.select' | transloco"
							[tooltip]="'consuming-unit.acquisition.type-tooltip' | transloco"
							[options]="acquisitionMode"
							(ngModelChange)="changeAcquisition($event)"
							[required]="true"
							[showError]="
								!metadataForm.controls['acquisitionMode'].valid &&
								metadataForm.controls['acquisitionMode'].touched
							"
							[disabled]="
								metadataForm.controls['acquisitionMode'].disabled ||
								this.acquisitionMode.length == 1
							"
							formControlName="acquisitionMode"
							name="acquisitionMode"
						></ga-selector>
					</div>
					<!-- Credencial -->
					<div class="form-group col-lg-4 col-md-4 col-sm-6">
						<ga-selector
							*ngIf="
								metadataForm.controls['acquisitionMode'].value === 'automatic'
							"
							[title]="'consuming-unit.acquisition.credential' | transloco"
							[placeholder]="'global.select' | transloco"
							[tooltip]="
								'consuming-unit.acquisition.credential-tooltip' | transloco
							"
							[required]="true"
							[showError]="
								!metadataForm.controls['distributorCredential'].valid &&
								metadataForm.controls['distributorCredential'].touched
							"
							[options]="filteredCredentials"
							(ngModelChange)="changeCredentials($event)"
							[disabled]="
								metadataForm.controls['distributorCredential'].disabled
							"
							formControlName="distributorCredential"
							name="distributorCredential"
						></ga-selector>
					</div>
					<!-- Inicio da coleta -->
					<div class="form-group col-lg-4 col-md-4 col-sm-6">
						<ga-datepicker
							*ngIf="
								metadataForm.controls['acquisitionMode'].value === 'automatic'
							"
							[title]="'consuming-unit.acquisition.start-date' | transloco"
							[tooltip]="
								'consuming-unit.acquisition.start-date-tooltip' | transloco
							"
							[placeholder]="'00/00/0000'"
							[disabled]="metadataForm.controls['startdate'].disabled"
							[showError]="
								!metadataForm.controls['startdate'].valid &&
								metadataForm.controls['startdate'].touched
							"
							formControlName="startdate"
							name="startdate"
						>
						</ga-datepicker>
					</div>
				</div>
				<!-- ------------------------- button group ------------------------- -->
				<ng-container
					*ngIf="metadataForm.controls['distributorCredential'].value !== 'new'"
					[ngTemplateOutlet]="buttonGroup"
				>
				</ng-container>
			</div>

			<!-- ------------------------- New credential Module ------------------------- -->
			<div
				class="row col-lg-12 module-group"
				*ngIf="metadataForm.controls['distributorCredential'].value === 'new'"
			>
				<h3 class="basic-data__title col-lg-12">
					{{ "consuming-unit.credential.new" | transloco }}
				</h3>
				<div class="row col-lg-12 p-uns">
					<!-- Nome -->
					<div class="form-group col-lg-4 col-md-6 col-sm-12">
						<ga-input
							[title]="'credentials-list.table.name' | transloco"
							[placeholder]="
								'credentials-details.form.credential-placeholder' | transloco
							"
							[disabled]="metadataForm.controls['newCredentialName'].disabled"
							[required]="true"
							[showError]="
								!metadataForm.controls['newCredentialName'].valid &&
								metadataForm.controls['newCredentialName'].touched
							"
							formControlName="newCredentialName"
							name="newCredentialName"
						></ga-input>
					</div>
					<!-- URL -->
					<div class="form-group col-lg-8 col-md-6 col-sm-12">
						<ga-selector
							[title]="'consuming-unit.credential.url' | transloco"
							[placeholder]="'global.select' | transloco"
							[required]="true"
							[showError]="
								!metadataForm.controls['newCredentialURL'].valid &&
								metadataForm.controls['newCredentialURL'].touched
							"
							(ngModelChange)="changeDistributorURL($event)"
							[options]="distributorsURLS"
							[disabled]="metadataForm.controls['newCredentialURL'].disabled"
							formControlName="newCredentialURL"
							name="newCredentialURL"
						></ga-selector>
					</div>
					<access-keys-card
						*ngIf="selectedDistributorUrl"
						[currentUrl]="selectedDistributorUrl"
						[credentialId]="
							metadataForm.controls['distributorCredential'].value !== 'new'
								? metadataForm.controls['distributorCredential'].value
								: undefined
						"
						[form]="metadataForm"
					></access-keys-card>
				</div>
				<!-- ------------------------- button group ------------------------- -->
				<ng-container [ngTemplateOutlet]="buttonGroup"></ng-container>
			</div>
		</form>
	</div>
</div>

<ng-template #buttonGroup>
	<hr class="separator" />
	<div class="button-group flex-col">
		<div class="btn-toolbar" role="toolbar" style="text-align: center">
			<div class="btn-group" role="group">
				<button class="btn btn-default" (click)="backTo()">
					{{ "appliance.btn.cancel" | transloco }}
				</button>
			</div>
			<div class="btn-group" role="group">
				<button
					class="btn btn-primary btn-color-ga"
					type="submit"
					[disabled]="!metadataForm.valid || isSaving"
				>
					<span>
						{{ "organization.btn.save" | transloco }}
					</span>
				</button>
			</div>
		</div>
	</div>
</ng-template>
