import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'webapp/environments/environment';
import { OrganizationSmall } from '../models/organization.model';
import { Observable } from 'rxjs';
import { SendOrganization } from 'webapp/app/dashboard/registrations/organizations/form/organization.model';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

export const ORGANIZATION = 'ORGANIZATION';
@Injectable({ providedIn: 'root' })
export class OrganizationService {
	listOrgs: any[] = [];

	public _current$: BehaviorSubject<any> = new BehaviorSubject(null);
	$obsevableOrganization = this._current$.asObservable();

	constructor(private http: HttpClient) {
		if (this.sessionOrg) {
			this._current$.next(this.sessionOrg);
		}
	}

	getOrganizations(params: any = {}) {
		!params.hasOwnProperty('omitStyles') && (params.omitStyles = true);
		return this.http
			.get(`${environment.backendUrl}/api/organizations`, { params })
			.toPromise()
			.then((res: any) => res?.organizations || []);
	}

	$getOrganizations(params: any = {}): Observable<any> {
		return this.http.get<any>(`${environment.backendUrl}/api/organizations`, {
			params,
		});
	}

	/* DEPRECATED */
	getOrganization(id) {
		return this.http
			.get(`${environment.backendUrl}/api/organizations/${id}`)
			.toPromise()
			.then((res: any) => res?.organization);
	}

	$getOrganization(id: number) {
		return this.http.get(`${environment.backendUrl}/api/organizations/${id}`).pipe(
			map((res: any) => {
				return res?.organization;
			})
		);
	}

	getBillingplans() {
		return this.http
			.get(`${environment.backendUrl}/api/billingplans/`)
			.toPromise()
			.then((res: any) => {
				return res?.billingplans;
			});
	}

	setFromLocalStorage(item: OrganizationSmall) {
		localStorage.setItem(ORGANIZATION, JSON.stringify(item));
	}

	clearFromLocalStorage() {
		localStorage.removeItem(ORGANIZATION);
	}

	create(organization: SendOrganization) {
		return this.http.post<SendOrganization>(`${environment.backendUrl}/api/organizations`, organization);
	}

	update(id: number, organization: SendOrganization) {
		return this.http.put(`${environment.backendUrl}/api/organizations/${id}`, organization);
	}

	getBranches(params) {
		return this.http
			.get(`${environment.backendUrl}/api/organizations/` + params.organizationId + '/branches', {
				params: params,
			})
			.toPromise()
			.then((res: any) => {
				return res?.branches;
			});
	}

	getMeters(params) {
		return this.http
			.get(`${environment.backendUrl}/api/organizations/` + params.organizationId + '/meters', {
				params: params,
			})
			.toPromise()
			.then((res: any) => {
				return res?.meters;
			});
	}

	getUsers(params) {
		return this.http
			.get(`${environment.backendUrl}/api/organizations/` + params.organizationId + '/users', {
				params: params,
			})
			.toPromise()
			.then((res: any) => {
				return res;
			});
	}

	$getUsers(params: any = {}): Observable<any> {
		return this.http.get(`${environment.backendUrl}/api/organizations/` + params.organizationId + '/users', {
			params: params,
		});
	}

	getUsersByOrganization(params) {
		return this.http
			.get(`${environment.backendUrl}/api/organizations/users`, {
				params: params,
			})
			.toPromise()
			.then((res: any) => {
				return res;
			})
			.catch((error) => {
				return error;
			});
	}

	async getUserOrganizations(user) {
		if (user.isAdmin) {
			const organizations = await this.getOrganizations({ fields: 'id,name' });
			return organizations || [];
		} else {
			const roles = await this.getUserRoleOrganizations(user.id);
			// TODO: check if we can simply return this.getOrganizations
			// must also refactor this function callers too because user.organization
			// is a number, not an object like 'organizations'
			if (roles && roles.length > 1) {
				const organizations = await this.getOrganizations({
					fields: 'id,name',
				});
				return organizations || [];
			} else {
				return user.organization || [];
			}
		}
	}

	async getUserRoleOrganizations(userId, params?) {
		return this.http
			.get(`${environment.backendUrl}/api/users/${userId}/role-organizations`, {
				params: params,
			})
			.toPromise()
			.then((res) => res)
			.catch((error) => {
				return error;
			});
	}

	getSavingsPotential(params) {
		return this.http
			.get(`${environment.backendUrl}/api/organizations/${params?.organizationId}/savings-potential`, {
				params: params,
			})
			.toPromise()
			.then((res) => res);
	}

	set sessionOrg(org: any) {
		sessionStorage.setItem('selectedOrg', JSON.stringify(org) || '');
	}

	get sessionOrg() {
		const storageOrgstring = sessionStorage.getItem('selectedOrg');
		const storageOrg = storageOrgstring ? JSON.parse(storageOrgstring) : {};
		this._current$.next(storageOrg);
		return storageOrg;
	}
}

export const ng2OrganizationService = {
	name: OrganizationService.name,
	def: downgradeInjectable(OrganizationService),
};
