import * as angular from 'angular';
import { ng2ConsumptionChart } from 'webapp/app/dashboard/charts/consumption-chart/consumption-chart.component';
import { ng2SourceSelector } from 'webapp/app/shared/source-selector/source-selector.component';
import { ng2ProjectsComponent } from 'webapp/app/dashboard/economy/economy-projects/economy-projects.component';
import { ng2AppShell } from 'webapp/app/shared/app-shell/app-shell.component';
import { ng2ComponentContainer } from 'webapp/app/shared/component-container/component-container.component';



import { gaCalendarNavigator } from './directives/calendar-navigator/calendar-navigator.directive';
import { ngCompanySelector } from './directives/company-selector/company-selector.component';
import { ngComponentContainer } from './directives/component-container/component-container';

import { ngDatasourceSelector } from './directives/datasource-selector/datasource-selector.component';
import { ngGaLoading } from './directives/loading/loading.component';
import { ngMeterCloseCycle } from './directives/meter-modals/meter-close-cycle.directive';
import { ngOrganizationSelectorMulti } from './directives/organization-selector-multi/organization-selector-multi.component';
import { ngOrganizationSelector } from './directives/organization-selector/organization-selector.component';
import { powerFactorChart } from './directives/power-factor-chart/power-factor-chart.directive';
import { ngRealtimeComponent } from './directives/realtime/realtime.directive';
import { ngSourceSelector } from './directives/source-selector/source-selector.component';
import { ngGaWelcome } from './directives/welcome/welcome.component';
import { ngHasPermission } from "./directives/has-permission/has-permission.directive";

import { ng2TagsComponent } from 'webapp/app/dashboard/registrations/tags/tags-manager/tags-manager.component';
import { ng2TagDetailsComponent } from 'webapp/app/dashboard/registrations/tags/tag-details/tag-details.component';
import { ng2MeterDetailsComponent } from 'webapp/app/dashboard/registrations/measuring-points/meter-details/meter-details.component';
import { ng2GroupDetailsComponent } from 'webapp/app/dashboard/registrations/measuring-points/group-details/group-details.component';
import { ng2DatasourceManagerComponent } from 'webapp/app/dashboard/registrations/measuring-points/datasource-manager/datasource-manager.component';
import { ng2ConsumingUnitListComponent } from 'webapp/app/dashboard/registrations/consuming-units/consuming-unit-list/consuming-unit-list.component';
import { ng2CompanyListComponent } from 'webapp/app/dashboard/registrations/companies/company-list/company-list.component';
import { ng2UploadManagerComponent } from 'webapp/app/dashboard/registrations/measuring-points/upload-manager/upload-manager.component';
import { ng2CompanyDetailsComponent } from 'webapp/app/dashboard/registrations/companies/company-details/company-details.component';
import { ng2GaNavigationTabsComponent } from 'webapp/app/shared/ga-navigation-tabs/ga-navigation-tabs.component';
import { ng2ConsumingUnitDetailsComponent } from 'webapp/app/dashboard/registrations/consuming-units/consuming-unit-details/consuming-unit-details.component';
import { ng2CredentialDetailsComponent } from 'webapp/app/dashboard/registrations/credentials/credential-details/credential-details.component';
import { ng2UserListComponent } from 'webapp/app/dashboard/registrations/user/user-list/user-list.component';
import { ng2UserDetailsComponent } from 'webapp/app/dashboard/registrations/user/user-details/user-details.component';
import { ng2OrganizationListComponent } from 'webapp/app/dashboard/registrations/organizations/list/organization-list.component';
import { ng2OrganizationFormComponent } from 'webapp/app/dashboard/registrations/organizations/form/organization-form.component';
import { ng2KpiListComponent } from 'webapp/app/dashboard/analysis/kpis/kpi-list/kpi-list.component';
import { ng2SupervisorComponent } from 'webapp/app/dashboard/measuring-points/supervisor/supervisor.component';
import { gaRealTimeAdvanceChart } from './directives/realtime/realtime-advanced.directive';
import { ng2GaTagIconComponent } from 'webapp/app/shared/ga-tag-icon/ga-tag-icon.component';
import { ng2KpiDetailComponent } from 'webapp/app/dashboard/analysis/kpis/kpi-detail/kpi-detail.component';
import { ng2AnalysisModule } from 'webapp/app/dashboard/analysis/analysis.module';
import { ng2CredentialsListComponent } from 'webapp/app/dashboard/registrations/credentials/credentials-list/credentials-list.component';
import { ng2FreeTariffListComponent } from 'webapp/app/dashboard/contracts/free-tariff-list/free-tariff-list.component';
import { ng2ContractsModule } from 'webapp/app/dashboard/contracts/contracts.module';
import { ng2ContractDetailsComponent } from 'webapp/app/dashboard/contracts/contract-details/contract-details.component';
import { ng2CompositionListComponent } from 'webapp/app/dashboard/contracts/composition/composition-list/composition-list.component';
import { ng2ProinfaListComponent } from 'webapp/app/dashboard/contracts/proinfa/proinfa-list/proinfa-list.component';
import { ng2ProinfaDetailsComponent } from 'webapp/app/dashboard/contracts/proinfa/proinfa-details/proinfa-details.component';
import { ng2TariffListComponent } from 'webapp/app/dashboard/contracts/tariff/tariff-list/tariff-list.component';
import { ng2TariffDetailsComponent } from 'webapp/app/dashboard/contracts/tariff/tariff-details/tariff-details.component';
import { ng2CertificateListComponent } from 'webapp/app/dashboard/registrations/certificate/certificate-list/certificate-list.component';
import { ng2CertificateDetailsComponent } from 'webapp/app/dashboard/registrations/certificate/certificate-details/certificate-details.component';

const DashboardDirectives = angular.module('dashboard-directives', [])

DashboardDirectives.directive('convertToNumber', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {
      ngModel?.$parsers.push(function (val) {
        return parseInt(val, 10);
      });
      ngModel?.$formatters.push(function (val) {
        return '' + val;
      });
    }
  };
});

//ngDatasourceSelector
DashboardDirectives.directive(ngComponentContainer.name, ngComponentContainer.def);
DashboardDirectives.directive(ng2AppShell.name, ng2AppShell.def);

DashboardDirectives.directive(ng2SupervisorComponent.name, ng2SupervisorComponent.def);

DashboardDirectives.directive(ng2ProjectsComponent.name, ng2ProjectsComponent.def);
DashboardDirectives.directive(ng2TagsComponent.name, ng2TagsComponent.def);
DashboardDirectives.directive(ng2TagDetailsComponent.name, ng2TagDetailsComponent.def);
DashboardDirectives.directive(ng2DatasourceManagerComponent.name, ng2DatasourceManagerComponent.def);
DashboardDirectives.directive(ng2ConsumingUnitListComponent.name, ng2ConsumingUnitListComponent.def);
DashboardDirectives.directive(ng2GaNavigationTabsComponent.name, ng2GaNavigationTabsComponent.def);
DashboardDirectives.directive(ng2GaTagIconComponent.name, ng2GaTagIconComponent.def);
DashboardDirectives.directive(ng2CertificateListComponent.name, ng2CertificateListComponent.def);
DashboardDirectives.directive(ng2CompanyListComponent.name, ng2CompanyListComponent.def);
DashboardDirectives.directive(ng2MeterDetailsComponent.name, ng2MeterDetailsComponent.def);
DashboardDirectives.directive(ng2UploadManagerComponent.name, ng2UploadManagerComponent.def);
DashboardDirectives.directive(ng2GroupDetailsComponent.name, ng2GroupDetailsComponent.def);
DashboardDirectives.directive(ng2CompanyDetailsComponent.name, ng2CompanyDetailsComponent.def);
DashboardDirectives.directive(ng2ConsumingUnitDetailsComponent.name, ng2ConsumingUnitDetailsComponent.def);
DashboardDirectives.directive(ng2CredentialDetailsComponent.name, ng2CredentialDetailsComponent.def);
DashboardDirectives.directive(ng2CredentialsListComponent.name, ng2CredentialsListComponent.def);
DashboardDirectives.directive(ng2UserListComponent.name, ng2UserListComponent.def);
DashboardDirectives.directive(ng2UserDetailsComponent.name, ng2UserDetailsComponent.def);
DashboardDirectives.directive(ng2CertificateDetailsComponent.name, ng2CertificateDetailsComponent.def);

DashboardDirectives.directive(ng2SourceSelector.name, ng2SourceSelector.def);
DashboardDirectives.directive(ng2ComponentContainer.name, ng2ComponentContainer.def);
DashboardDirectives.directive(ng2ConsumptionChart.name, ng2ConsumptionChart.def);
DashboardDirectives.component(ngOrganizationSelector.name, ngOrganizationSelector.def);
DashboardDirectives.component(ngOrganizationSelectorMulti.name, ngOrganizationSelectorMulti.def);
DashboardDirectives.component(ngDatasourceSelector.name, ngDatasourceSelector.def);
DashboardDirectives.directive(ngSourceSelector.name, ngSourceSelector.def);
DashboardDirectives.component(ngCompanySelector.name, ngCompanySelector.def);
DashboardDirectives.component(ngGaLoading.name, ngGaLoading.def);
DashboardDirectives.component(ngGaWelcome.name, ngGaWelcome.def);
DashboardDirectives.component(ngMeterCloseCycle.name, ngMeterCloseCycle.def);
DashboardDirectives.directive(gaCalendarNavigator.name, gaCalendarNavigator.def);
DashboardDirectives.directive(gaRealTimeAdvanceChart.name, gaRealTimeAdvanceChart.def);
DashboardDirectives.directive(ngHasPermission.name, ngHasPermission.def);
DashboardDirectives.component(powerFactorChart.name, powerFactorChart.def);
DashboardDirectives.component(ngRealtimeComponent.name, ngRealtimeComponent.def);
DashboardDirectives.directive(ng2OrganizationListComponent.name, ng2OrganizationListComponent.def);
DashboardDirectives.directive(ng2OrganizationFormComponent.name, ng2OrganizationFormComponent.def);

// Analysis
DashboardDirectives.directive(ng2KpiListComponent.name, ng2KpiListComponent.def);
DashboardDirectives.directive(ng2KpiDetailComponent.name, ng2KpiDetailComponent.def);

// Contracts and Tariffs
DashboardDirectives.directive(ng2CompositionListComponent.name, ng2CompositionListComponent.def);
DashboardDirectives.directive(ng2FreeTariffListComponent.name, ng2FreeTariffListComponent.def);
DashboardDirectives.directive(ng2ContractDetailsComponent.name, ng2ContractDetailsComponent.def);
DashboardDirectives.directive(ng2ProinfaListComponent.name, ng2ProinfaListComponent.def);
DashboardDirectives.directive(ng2ProinfaDetailsComponent.name, ng2ProinfaDetailsComponent.def);
DashboardDirectives.directive(ng2TariffListComponent.name, ng2TariffListComponent.def);
DashboardDirectives.directive(ng2TariffDetailsComponent.name, ng2TariffDetailsComponent.def);

export default DashboardDirectives.name

