<section *ngIf="!isMultiple" class="checkbox-section">
	<mat-checkbox
		[(ngModel)]="val"
		id="check-{{ id }}"
		name="check-{{ id }}"
		[ngClass]="{ 'label-bold': boldLabel }"
		(ngModelChange)="onChange(val)"
		[disabled]="disabled"
		[checked]="val"
		[color]="color"
		>{{ title }}</mat-checkbox
	>
</section>

<section *ngIf="isMultiple" class="checkbox-section">
	<span class="checkbox-list-section">
		<mat-checkbox
			class="checkbox-margin"
			[ngClass]="{ 'label-bold': boldLabel }"
			[checked]="allComplete"
			[color]="color"
			[indeterminate]="someComplete()"
			(change)="setAll($event.checked)"
		>
			{{ mainCheckboxName }}
		</mat-checkbox>
	</span>
	<span class="checkbox-list-section">
		<ul>
			<li *ngFor="let option of options">
				<mat-checkbox
					[(ngModel)]="option.id"
					[ngClass]="{ 'label-bold': boldLabel }"
					[color]="color"
					(ngModelChange)="update()"
				>
					{{ option.label }}
				</mat-checkbox>
			</li>
		</ul>
	</span>
</section>
