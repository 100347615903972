import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GaHelperComponent } from './ga-helper.component';
import { MaterialModule } from '@repo/projects/webapp/global/material/material.module';

@NgModule({
	declarations: [GaHelperComponent],
	imports: [CommonModule, MaterialModule],
	exports: [GaHelperComponent],
})
export class GaHelperModule {}
