<div class="content-wrapper view">
	<div class="container-fluid">
		<nav class="ga-navbar">
			<div class="navbar-content">
				<h3>
					<a (click)="backTo()">
						{{ "main.menu.registrations" | transloco }}
					</a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<a (click)="backTo()"> {{ "main.menu.tags" | transloco }} </a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<span *ngIf="!tagId" class="navbar-content__breadcrumb-page">
						{{ "tags.new" | transloco }}
					</span>
					<span *ngIf="tagId" class="navbar-content__breadcrumb-page">
						{{ tag.label }}
					</span>
				</h3>
			</div>
		</nav>

		<div class="description">
			<div class="description__texts">
				<span class="description__texts__title">{{
					!tagId
						? ("tags.new" | transloco)
						: ("tags.form.edit-title" | transloco)
				}}</span>
				<span class="description__texts__subtitle">{{
					!tagId
						? ("tags.form.new-subtitle" | transloco)
						: ("tags.form.edit-subtitle" | transloco)
				}}</span>
			</div>
		</div>

		<form
			[formGroup]="tagForm"
			(ngSubmit)="onSubmit()"
			focusInvalidInput
			class="flex-col"
		>
			<div class="row col-lg-12 module-group">
				<h3 class="basic-data__title col-lg-12">
					{{ "tags.configuration" | transloco }}
				</h3>

				<div class="row">
					<div class="form-group col-lg-6">
						<ga-input
							[title]="'credentials-list.table.name' | transloco"
							[tooltip]="'tags.form.max-char-20' | transloco"
							[maxLength]="20"
							[placeholder]="'tags.form.label-placeholder' | transloco"
							[required]="true"
							[showError]="
								tagForm.controls['label'].invalid &&
								tagForm.controls['label'].touched
							"
							formControlName="label"
							name="label"
						></ga-input>
					</div>

					<div class="form-group col-lg-6">
						<ga-color-picker
							[title]="'tags.color' | transloco"
							[color]="tagForm.value.color"
							[showIcon]="false"
							(colorEmitter)="onChangeColor($event)"
						></ga-color-picker>
					</div>

					<div class="form-group col-lg-6">
						<label>
							<span>{{ "tags.form.pre-visualization" | transloco }}</span>
						</label>
						<div
							class="color-container"
							[style.background]="tagBackgroundColor"
						>
							<span [style.color]="tagColor">{{ tagName }}</span>
							<i [style.color]="tagColor" class="fa fa-close"></i>
						</div>
					</div>
				</div>

				<hr class="separator" />
				<div class="button-group flex-row">
					<div class="btn-toolbar" role="toolbar" style="text-align: center">
						<div class="btn-group" role="group">
							<button class="btn btn-default" type="button" (click)="backTo()">
								{{ "appliance.btn.cancel" | transloco }}
							</button>
						</div>
						<div class="btn-group" role="group">
							<button
								class="btn btn-primary btn-color-ga"
								type="submit"
								[disabled]="tagForm.invalid"
							>
								{{ "organization.btn.save" | transloco }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
