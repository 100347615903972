<div class="loading-component-container">
	<mat-spinner *ngIf="spinnerModel" class="loading-component"></mat-spinner>
	<app-my-loader
		*ngIf="!spinnerModel"
		[lightMode]="true"
		[loading]="true"
		[relative]="true"
	></app-my-loader>
	<span class="loading-component__legend">{{ text | transloco }}</span>
</div>
