<div id="company-selector">
	<label for="company-selector">{{
		"company-selector.label" | transloco
	}}</label>
	<input
		type="text"
		class="form-control"
		[matAutocomplete]="auto"
		[formControl]="companyInput"
		[placeholder]="'company.placeholder' | transloco"
		matAutocompletePosition="below"
	/>
	<mat-autocomplete #auto="matAutocomplete">
		<mat-option
			*ngFor="let option of filteredCompanys$ | async"
			[value]="option.id + ' - ' + option.name"
			(onSelectionChange)="selectCompany(option)"
		>
			{{ option.id }} - {{ option.name }}
		</mat-option>
	</mat-autocomplete>
</div>
