<ga-loading [ngClass]="{ hidden: !loading }"></ga-loading>
<div [ngClass]="{ hidden: loading }" class="dialog">
	<div class="row m-0">
		<ga-tag-icon
			[icon]="'warning'"
			[type]="'warning'"
			[size]="18"
		></ga-tag-icon>
	</div>
	<div class="panel-title" mat-dialog-title>
		<h4 class="modal-title">
			{{ "credentials-details.confirm-identity.title" | transloco }}
		</h4>
	</div>
	<form [formGroup]="form" (ngSubmit)="onSubmit()">
		<div class="modal-body">
			<div mat-dialog-content class="desc-body">
				<span>
					{{ "credentials-details.confirm-identity.subtitle" | transloco }}
				</span>
				<div class="form-input">
					<ga-input
						[placeholder]="'signup.ph-password' | transloco"
						[required]="true"
						formControlName="password"
						name="password"
						type="password"
					></ga-input>
				</div>
			</div>
		</div>

		<div mat-dialog-actions class="dialog-actions">
			<button
				type="button"
				class="modal-button cancel"
				style="margin-right: 5px"
				(click)="onCancel()"
			>
				{{ "global.cancel" | transloco }}
			</button>
			<button type="submit" class="modal-button" [ngClass]="'send'">
				{{ "credentials-details.confirm-identity.send" | transloco }}
			</button>
		</div>
	</form>
</div>
