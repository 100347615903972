import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { EconomyProjectService } from 'webapp/app/shared/services/economy-projects.service';
import { EconomyProject } from '../project.model';

@Component({
	selector: 'app-economy-project-detail',
	templateUrl: './economy-project-detail.component.html',
	styleUrls: ['./economy-project-detail.component.scss'],
})
export class EconomyProjectDetailComponent {
	project!: EconomyProject;
	columns = ['installNumber', 'startDate', 'address', 'distributor', 'tariff'];
	installationsData = new MatTableDataSource();

	constructor(
		public dialogRef: MatDialogRef<EconomyProjectDetailComponent>,
		@Inject(MAT_DIALOG_DATA) public projectData: EconomyProject,
		private economyProjectService: EconomyProjectService
	) {
		if (!this.projectData.id) {
			return;
		}

		this.economyProjectService
			.getEconomyProjectById(this.projectData.id)
			.then((project) => {
				this.project = project;
				this.installationsData.data = this.project.installations;
			});
	}
}
