import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GaAccessPasswordComponent } from './ga-access-password.component';
import { GaHelperModule } from '../ga-helper/ga-helper.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { MaterialModule } from '@repo/projects/webapp/global/material/material.module';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
	declarations: [GaAccessPasswordComponent],
	imports: [
		CommonModule,
		GaHelperModule,
		ReactiveFormsModule,
		FormsModule,
		TranslocoModule,
		MaterialModule,
		NgxMaskModule,
	],
	exports: [GaAccessPasswordComponent],
})
export class GaAccessPasswordModule {}
