<div class="dialog">
	<div class="panel-heading" mat-dialog-title>
		<button type="button" class="close" (click)="dialogRef.close()">
			<span aria-hidden="true">&times;</span>
			<span class="sr-only">Close</span>
		</button>
		<h4 class="modal-title">
			{{ "projects.detail-consumption.title" | transloco }}
		</h4>
	</div>
	<div class="modal-body" mat-dialog-content>
		<div class="row">
			<div class="col-md-8">
				<p style="color: rgba(0, 0, 0, 0.87); font-size: 1.5rem">
					{{ data.element.name }}
				</p>
			</div>
			<div class="col-md-4 text-right">
				<div class="pick-year">
					<i
						class="fa-solid fa-chevron-left chevron-size"
						(click)="subYear()"
					></i>
					<p>{{ currentYear }}</p>
					<i
						class="fa-solid fa-chevron-right chevron-size"
						(click)="addYear()"
					></i>
				</div>
			</div>
			<div class="col-md-12" *ngIf="currentConsumptions.length > 0">
				<table class="table table-bordered">
					<thead class="thead-dark">
						<tr>
							<th scope="col"></th>
							<th scope="col" style="background-color: #f4f4f4">kWh</th>
							<th scope="col" style="background-color: #f4f4f4">R$</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let details of currentConsumptions">
							<td style="background-color: #f4f4f4">
								{{ details.date | date : "MMMM" }}
							</td>
							<td>
								<span *ngIf="details.consumption">
									{{ details.consumption | number : "1.2-2" }}
									{{ "kwh" | transloco }}
								</span>
								<span *ngIf="!details.consumption">
									{{ " -- " }} {{ "kwh" | transloco }}
								</span>
							</td>
							<td>
								<span *ngIf="details.value">
									{{ "currency" | transloco }}
									{{ details.value | number : "1.2-2" }}
								</span>
								<span *ngIf="!details.value">
									{{ "currency" | transloco }} {{ " -- " }}
								</span>
							</td>
						</tr>
						<tr class="footer" style="background-color: #ecffe9">
							<td>{{ "main.consumption.label.total" | transloco }}</td>
							<td>
								{{ totalByYear("consumption") | number : "1.2-2" }}
								{{ "kwh" | transloco }}
							</td>
							<td>
								{{ "currency" | transloco }}
								{{ totalByYear("value") | number : "1.2-2" }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row" *ngIf="currentConsumptions.length === 0">
			<div class="col-md-12 text-center">
				<h4>Não possuímos consumo detalhado nesse período</h4>
			</div>
		</div>
	</div>
</div>
