import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Paginator } from '../model/paginator.model';

@Injectable({
	providedIn: 'root',
})
export class PaginatorIntl {
	constructor(@Inject(TranslocoService) private i18n) {}

	tablePaginator(): Paginator {
		const obj: Paginator = {} as Paginator;
		obj.firstPage = this.i18n.translate('data-table.first-page');
		obj.lastPage = this.i18n.translate('data-table.last-page');
		obj.itemsPerPage = this.i18n.translate('data-table.items-per-page');
		obj.nextPage = this.i18n.translate('data-table.next-page');
		obj.previousPage = this.i18n.translate('data-table.previous-page');
		return obj;
	}

	rangeLabel = (page: number, pageSize: number, length: number) => {
		if (length == 0 || pageSize == 0) {
			return `1 ${this.i18n.translate('data-table.of')} 1`;
		}
		length = Math.max(length, 0);
		const startIndex = page * pageSize;

		// If the start index exceeds the list length, do not try and fix the end index to the end.
		const endIndex =
			startIndex < length
				? Math.min(startIndex + pageSize, length)
				: startIndex + pageSize;

		return `${startIndex + 1} - ${endIndex} ${this.i18n.translate(
			'data-table.of'
		)} ${length}`;
	};
}
