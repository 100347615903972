class ItemSelectorController {
  constructor($scope, $translate) {
    this.$scope = $scope;
    this.$translate = $translate;
  }

  $onInit() {
    const vm = this;

    vm.selectorId = vm.gaId ? vm.gaId : 'itemSelector';
    vm.placeholder = vm.placeholder ? vm.placeholder : 'global.search';
    vm.disabled = vm.disabled ? vm.disabled : false;

    vm.watchItemsParam();
    vm.watchOrganizationParam();
    vm.watchSelectedItemParam();
  }

  watchOrganizationParam() {
    const vm = this;

    vm.$scope.$watch('isVm.organization', function (newOrg) {
      if (vm.itemsInitialized) {
        vm.filterItemsByOrganization(newOrg);
      }
    });
  }

  watchSelectedItemParam() {
    const vm = this;
    vm.$scope.$watch('isVm.selectedItem', (newItem) => {
      vm.selectedItem = newItem;
      if (!newItem && vm.onItemChanged) {
        vm.onItemChanged(null);
      }
    });
  }

  watchItemsParam() {
    const vm = this;
    vm.itemsInitialized = false;
    vm.$scope.$watch('isVm.items', function (newItems) {
      if (newItems && !vm.itemsInitialized) {
        vm.initItems = newItems;
        vm._items = newItems;
        vm.itemsInitialized = true;
      }
      if (vm.itemsInitialized) {
        vm.filterItemsByOrganization(vm.organization, newItems);
      }
    });
  }

  getOrgId(org) {
    if (!org) return;

    if (typeof org == 'object') {
      return org.id ? org.id : undefined;
    } else if (typeof org == 'string' || typeof org == 'number') {
      return org;
    } else {
      return undefined;
    }
  }

  onItemSelected(selectedItem) {
    const vm = this;

    if (selectedItem) {
      vm.selectedItem = selectedItem;
      if (vm.onItemChanged) {
        vm.onItemChanged(selectedItem);
      }
    }
  }

  /**
   * Item represents one of the items in the
   * 'items' binding.
   * The resolved label is available as 'match.label'
   * in the dropdown template
   * @param {any} item
   */
  getLabel(item) {
    return item ? item[this.itemLabel] : null;
  }

  getTemplateName() {
    return this.itemTemplate ? this.itemTemplate : 'item-template';
  }

  getListItem(match) {
    return match.model[this.itemLabel];
  }

  filterItemsByOrganization(organization, newItems) {
    const vm = this;
    if (organization) {
      const orgId = vm.getOrgId(organization);
      vm._items = vm.initItems.filter(
        (item) => item.organization == orgId || item.organizationId == orgId
      );
    } else if (organization == false) {
      vm._items = newItems;
    } else {
      vm._items = vm.initItems;
    }
  }
}

const ItemSelector = {
  templateUrl: '/app/directives/item-selector/item-selector.html',
  controller: ItemSelectorController,
  controllerAs: 'isVm',
  bindings: {
    gaId: '@',
    //Items to be displayed on list
    items: '=',
    //Selected Item - Used for Edit Mode
    selectedItem: '=?',
    // Item label - Not needed if itemTemplate is used
    itemLabel: '@',
    // Template used to render each list item
    itemTemplate: '@',
    //Callback function when new Item is selected
    onItemChanged: '<',
    // Contract Type - Type Filter
    type: '@',
    // Contract Type - SubType Filter
    // subType: '@',
    // I18n key for input text placeholder
    placeholder: '@',
    //Organization filter - Display Tariffs from provided organization
    // If False - Always resets to present items when filtering by orgs
    organization: '=',
    //Disables the Input
    disabled: '=?',
  },
};
angular.module('dashboard-directives').component('itemSelector', ItemSelector);
