import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'root' })
export class GroupService {
	currentGroup = {};

	constructor(private http: HttpClient) {}

	getDataSources() {
		return this.http
			.get(`${environment.backendUrl}/api/datasources`, {
				params: { type: 'group' },
			})
			.toPromise()
			.then((res: any) => {
				return res?.dataSources;
			});
	}

	getContracts(dataSourceId) {
		return this.http
			.get(
				`${environment.backendUrl}/api/datasources/${dataSourceId}/contracts`
			)
			.toPromise()
			.then((res: any) => {
				return res?.contracts;
			});
	}

	groupTotal(group) {
		return this.http
			.get(`${environment.backendUrl}/api/groups/${group.groupId}/total`, {
				params: group,
			})
			.toPromise();
	}

	groupMeasurements(params): Promise<any> {
		if (params && (params.type == 'invoice' || params.resolution == 'month')) {
			return this.http
				.get(`${environment.backendUrl}/api/groups/${params.groupId}/total`, {
					params: params,
				})
				.toPromise()
				.then((res: any) => res?.measurement);
		} else {
			return this.http
				.get(
					`${environment.backendUrl}/api/groups/${params.groupId}/measurements`,
					{ params: params }
				)
				.toPromise()
				.then((res: any) => res?.measurement);
		}
	}

	saveGroup(group) {
		if (group.id) {
			return this.http
				.put(`${environment.backendUrl}/api/groups/${group.id}`, group)
				.toPromise();
		} else {
			return this.http
				.post(`${environment.backendUrl}/api/groups/`, group)
				.toPromise();
		}
	}

	deleteGroup(group) {
		if (group.id) {
			return this.http
				.delete(`${environment.backendUrl}/api/groups/${group.id}`)
				.toPromise();
		}
	}

	getDemand(params) {
		if (!params.groupId || !params.period) throw new Error();

		return this.http
			.get(`${environment.backendUrl}/api/groups/${params.groupId}/demand`, {
				params: params,
			})
			.toPromise()
			.then((resp: any) => {
				return resp;
			});
	}

	getReactiveSurplus(params) {
		if (
			params.groupId &&
			(params.period || (params.startDate && params.endDate))
		) {
			return this.http
				.get(
					`${environment.backendUrl}/api/groups/${params.groupId}/reactive`,
					{ params: params }
				)
				.toPromise()
				.then((resp: any) => {
					return resp;
				});
		}
	}

	getForecasts(params) {
		if (params.type && params.type == 'invoice') {
			return this.http
				.get(
					`${environment.backendUrl}/api/groups/${params.groupId}/forecasts/total`,
					{ params: params }
				)
				.toPromise()
				.then((res: any) => res?.measurement);
		} else {
			return this.http
				.get(
					`${environment.backendUrl}/api/groups/${params.groupId}/forecasts`,
					{ params: params }
				)
				.toPromise()
				.then((res: any) => res?.measurement);
		}
	}

	getForecastsTotal(params) {
		return this.http
			.get(`${environment.backendUrl}/api/groups/${params.groupId}/forecasts`, {
				params: params,
			})
			.toPromise()
			.then((res: any) => res?.measurement);
	}

	getFits(params) {
		if (params.type && params.type == 'invoice') {
			return this.http
				.get(
					`${environment.backendUrl}/api/groups/${params.groupId}/fits/total`,
					{ params: params }
				)
				.toPromise()
				.then((res: any) => res?.measurement);
		} else {
			return this.http
				.get(`${environment.backendUrl}/api/groups/${params.groupId}/fits`, {
					params: params,
				})
				.toPromise()
				.then((res: any) => res?.measurement);
		}
	}

	getGroup(params) {
		return this.http
			.get(`${environment.backendUrl}/api/groups/${params.groupId}`, {
				params: params,
			})
			.toPromise()
			.then((res: any) => {
				return res?.group;
			});
	}

	getGroupDataSources(params): Promise<any[]> {
		return this.http
			.get(
				`${environment.backendUrl}/api/groups/${params.groupId}/datasources`,
				{ params: params }
			)
			.toPromise()
			.then((res: any) => {
				return res?.dataSources;
			});
	}

	getGroupMeters(params): Promise<any[]> {
		return this.http
			.get(`${environment.backendUrl}/api/groups/${params.groupId}/meters`, {
				params: params,
			})
			.toPromise()
			.then((res: any) => {
				return res?.dataSources;
			});
	}

	getUsers(params) {
		return this.http
			.get(`${environment.backendUrl}/api/groups/${params.groupId}/users`, {
				params: params,
			})
			.toPromise()
			.then((res: any) => {
				return res?.users;
			});
	}
}

export const ng2GroupService = {
	name: GroupService.name,
	def: downgradeInjectable(GroupService),
};
