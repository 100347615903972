import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as Highcharts from 'highcharts';

import { DateTime } from 'luxon';
import { from } from 'rxjs';
import { combineAll, mergeMap } from 'rxjs/operators';
import { DateRange } from 'webapp/app/shared/date-range/date-range.component';
import { MultiSelectOption } from 'webapp/app/shared/multi-selector/multi-selector.model';
import { EconomyProjectService } from 'webapp/app/shared/services/economy-projects.service';
import { ProjectConsumptionDetail } from '../../project.model';
import { ManualProjectChartService } from './manual-project.service';

export type TogglType = 'value' | 'consumption';
export interface RangeDate {
	min: Date;
	max: Date;
}

export interface ManualChartObj {
	baseline: ProjectConsumptionDetail[];
	consumptions: ProjectConsumptionDetail[];
	startDate?: Date;
	installNumber: string;
}

@Component({
	selector: 'manual-project-chart',
	templateUrl: './manual-project-chart.component.html',
	styleUrls: ['../consumption-saving.component.scss'],
	providers: [ManualProjectChartService],
})
export class ManualProjectChartComponent implements OnInit {
	multiSelectorList: MultiSelectOption[] = [];
	startDate!: Date;
	rangeDate: RangeDate = {} as RangeDate;
	highcharts = Highcharts;
	chartOptions: Highcharts.Options | undefined;
	togglType: TogglType = 'value';
	isCompare = false;
	installOptions: MultiSelectOption[] = [];

	constructor(
		public service: ManualProjectChartService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<ManualProjectChartComponent>,
		private economyProjectService: EconomyProjectService
	) {
		this.startDate = DateTime.fromFormat(
			this.data.element.queryDate,
			'yyyy-MM'
		).toJSDate();
	}

	ngOnInit(): void {
		this.getnstallationsByProject(this.data.element.id);
		this.getAllInstallationsSun();
	}

	getnstallationsByProject(projectId: number) {
		this.economyProjectService
			.$getEconomyProjectById(projectId)
			.subscribe((res) => {
				this.multiSelectorList = this.service.transformListToMultiSelect(
					res.installations
				);
			});
	}

	getAllInstallationsSun() {
		this.isCompare = false;
		this.chartOptions = undefined;
		this.economyProjectService
			.getEconomyConsumeDetail(this.data.element.id, true)
			.subscribe((resp: ManualChartObj) => {
				this.consumptions = resp;
				this.rangeDate = this.service.setRangeDate(
					<Date>resp.startDate,
					resp.consumptions
				);
				this.chartOptions = this.service.populateCharts(
					this.startDate,
					this.togglType,
					this.isCompare
				);
			});
	}

	getConsumptionsByInstallation(id: number) {
		this.isCompare = false;
		this.economyProjectService
			.getInstallationByManualChart(id)
			.subscribe((res: ManualChartObj) => {
				this.consumptions = res;
				const { consumptions, baseline, startDate } = res;
				const start = baseline[0]?.date
					? DateTime.fromFormat(baseline[0].date, 'MM-yyyy').toJSDate()
					: <Date>startDate;
				this.rangeDate = this.service.setRangeDate(
					start,
					consumptions.length !== 0 ? consumptions : baseline
				);
				this.chartOptions = this.service.populateCharts(
					this.startDate,
					this.togglType,
					this.isCompare
				);
			});
	}

	set consumptions(consumptions: ManualChartObj) {
		this.service.consumptions = consumptions;
	}

	set compareConsumptions(compare: ManualChartObj[]) {
		this.service.compareConsumptions = compare;
	}

	applyChanges() {
		if (typeof this.installOptions[0]?.id !== 'number') return;
		else if (this.installOptions[0].id === 0) {
			this.chartOptions = undefined;
			this.getAllInstallationsSun();
		} else if (this.installOptions.length > 1) {
			this.chartOptions = undefined;
			this.compareInstallations(this.installOptions.map((i) => i.id));
		} else {
			this.chartOptions = undefined;
			this.getConsumptionsByInstallation(this.installOptions[0].id);
		}
		this.chartOptions = this.service.populateCharts(
			this.startDate,
			this.togglType,
			this.isCompare
		);
	}

	changeInstallation(installations: MultiSelectOption[]) {
		this.installOptions = installations;
	}

	compareInstallations(ids: number[]) {
		const $ids = from(ids);
		$ids
			.pipe(
				mergeMap((id: number) => [
					this.economyProjectService.getInstallationByManualChart(id),
				]),
				combineAll()
			)
			.subscribe((res: ManualChartObj[]) => {
				this.isCompare = true;
				this.compareConsumptions = res;
				this.rangeDate = this.service.getRangeDateByCompare(res[0], res[1]);
				this.chartOptions = this.service.populateCharts(
					this.startDate,
					this.togglType,
					this.isCompare
				);
			});
	}

	changeTogglType(value: TogglType) {
		this.togglType = value;
		this.chartOptions = this.service.populateCharts(
			this.startDate,
			this.togglType,
			this.isCompare
		);
	}

	onChangedDate($event: DateRange) {
		this.startDate = $event.start;
	}
}
