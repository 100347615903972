import { AbstractRealtime } from "./abstract-realtime.component";
import * as tinycolor from 'tinycolor2'
import { environment } from "webapp/environments/environment";
import * as Highcharts from 'highcharts';

const colorElement = document.querySelector(':root');
const cssStyles = getComputedStyle(colorElement);
const cssValS = String(cssStyles.getPropertyValue('--alert---alert-500')).trim();

const RealtimeComponent = {
  bindings: {
    meter: '=',
    attachedMeters: '=?',
    width: '@',
    height: '@',
    timezone: '=?',
    mode: '@?',
    options: '=?',
    showMeterLabel: '=?'
  },
  templateUrl: '/app/directives/realtime/realtime.html',
  controllerAs: 'vm',
  controller: class Realtime extends AbstractRealtime {
    constructor($scope, $compile, $interval, $timeout, Charts, MeterService, GroupService, $translate) {
      super($scope, $compile, $interval, $timeout, Charts, MeterService, GroupService, $translate);

    }

    async $onInit() {
      const vm = this;
      if (!this.mode || this.mode != 'groupMeter') {
        this.setOffLineTimer(15 * 60 * 1000, this.meter.id);
      }
      this.loading = true;
      this.timezone = this.timezone || "America/Sao_Paulo";
      this.series = [];
      this.lastReceived = [];
      this.notAvailable = false;
      this.ids = [];
      this.ids.push(this.meter.id);
      this.formatdate = this.$translate.instant('date-format-fullextended');
      const cssValP = String(cssStyles.getPropertyValue('--chartColor')).trim();
      this.options = this.options || {
        energyStyle: {
          'font-size': ' 26px',
          'text-align': 'left'/*,
            'padding-left': '0px'*/
        },
        showChart: true,
        showOffLineFlagIcon: true,
        showSerieIcon: true,
        loadingBarWidth: "120px",
      };
      if (this.attachedMeters) {
        this.attachedMeters.forEach(meter => {
          this.ids.push(meter.id);
        });
      }
      if (!this.meter.hasRealtime && (this.meter.meterType && !this.meter.meterType.hasRealtime)) {
        this.notAvailable = true;
        this.offLineFlag = true;
        this.lastReceived.push({ id: this.meter.id });
        await this.getLastStatus(this.meter.id, this.options.showChart);
      }
      this.panelId = 'realtime-chart' + '-' + JSON.stringify(angular.copy(this.ids).sort());
      let colors = this.options.colors || [tinycolor(cssValP).darken().toString(), cssValS];
      let icons = this.options.icons || ["icon-bolt", "icon-sun"];
      let seriesNames = this.options.seriesNames || [this.$translate.instant('main.realtime.power'), this.$translate.instant('main.realtime.generation')];
      let axis = [0, 1];

      vm.serverAddress = environment.backendUrl;
      vm.ids.forEach(id => {
        if (id != null) {
          vm.subscribeSocket(id, vm.measurementsListener.bind(vm));

          let serie = {
            name: seriesNames.shift(),
            color: colors.shift(),
            data: [],
            id: id,
            icon: icons.shift(),
            fillOpacity: 0.4,
            xAxis: axis.shift()
          };

          vm.series.push(serie);
        }
      });

    }

    createSamplesTimer(serie, serieIndex) {
      const vm = this;
      let qSize = serie.data;
      let interval = 1000;
      let dQueue = qSize > 10 ? qSize - 10 : 1;

      this.$timeout(function () {
        if (serie.data.length > 0) {
          serie.lastReceivedValue = serie.data.shift();

          for (let i = 0; i < dQueue; i++) {
            vm.charts.addRealtimePoint(vm.chart, [serie.lastReceivedValue], serieIndex);
            //vm.meter.lastReceivedValue = serie.lastReceivedValue[1];

          }
        }

        if (serie.data.length > 0) {
          vm.createSamplesTimer(serie, serieIndex);
        }
      }, interval);
    }

    measurementsListener(data) {
      const vm = this;
      if (!vm.notAvailable) {
        //if there is data and the incoming data has an uid that this component is waiting for
        if (data.samples && data.samples.length > 0 && vm.ids.find(id => id == data.meterId) != undefined) {

          vm.offLineFlag = false;
          vm.loading = false;

          //chart wasn't constructed yet
          if (vm.chart == null && vm.options.showChart) {
            vm.chart = vm.buildRealtime(vm.panelId, vm.series, vm.options);
          }

          let serieIndex = -1;
          let serie = null;

          let samples = [];
          data.samples.forEach(function (sample) {
            let timestamp = +moment(sample.timestamp).tz(vm.timezone).format("x");
            if (vm.mode == 'powerFactor') {
              samples.push([timestamp, vm.calcPF(sample)]);
            }
            else {
              samples.push([timestamp, parseInt((sample.pa || 0) + (sample.pb || 0) + (sample.pc || 0))]);
            }
          });
          //Set last Sample
          vm.meter.lastSample = data.samples[data.samples.length - 1];
          //find serie index using uid
          for (let i = 0; i < vm.series.length; i++) {
            serie = vm.series[i];
            if (data.meterId == serie.id) {
              serieIndex = i;
              serie.data = samples;
              break;
            }
          }

          serie.fillOpacity = 0.1;

          //add data
          vm.createSamplesTimer(serie, serieIndex)
        }
      }
    }
    calcPF(data) {
      let activePower = data.pa + data.pb + data.pc;
      let reactivePower = data.qa + data.qb + data.qc;
      let aparentPower = Math.sqrt(Math.pow(activePower, 2) + Math.pow(reactivePower, 2));

      let signal = Math.sign(reactivePower);

      let powerFactor = (activePower / aparentPower) * signal;
      return powerFactor
    }

    buildRealtime(idpanel, series, argOptions) {
      const vm = this;
      //Default chart options
      var options = {

        chart: {
          renderTo: idpanel,
          backgroundColor: null,
          spacingLeft: 0,
          type: 'area'
        },
        credits: {
          enabled: false
        },
        showYTicks: true,
        plotOptions: {
          series: {

            marker: {
              enabled: false
            },
            /*fillColor: "#CBE3C8",*/
            enableMouseTracking: true,
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        },

        title: {
          text: null
        },

        legend: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          formatter: function () {
            //moment.locale('pt-br');
            var dateFormatted = moment(this.x).format(vm.formatdate);
            return dateFormatted + '<br/>' + this.series.name + ': <strong>' + this.y + ' W</strong>';
          }
        },
        xAxis: [
          {
            type: 'datetime',
            endOnTick: false,
            gridLineWidth: 0,
            lineColor: 'var(--grayscale---gray-400)',
            tickColor: 'var(--grayscale---gray-400)',
            lineWidth: 0.2,
            tickWidth: 0,
            tickLength: 5,
            tickPixelInterval: 5,
            tickAmount: 30,
            labels: {
              enabled: false
            }
          },
          {
            type: 'datetime',
            endOnTick: false,
            gridLineWidth: 0,
            lineColor: 'var(--grayscale---gray-400)',
            tickColor: 'var(--grayscale---gray-400)',
            lineWidth: 0.2,
            tickWidth: 0,
            tickLength: 5,
            tickPixelInterval: 5,
            tickAmount: 30,
            labels: {
              enabled: false
            }
          }
        ],
        // xAxis: {
        //   type: 'datetime',
        //   endOnTick: false,
        //   gridLineWidth: 0,
        //   lineColor: '#A9A9A9',
        //   tickColor: '#A9A9A9',
        //   lineWidth: 0.2,
        //   tickWidth: 0,
        //   tickLength: 5,
        //   tickPixelInterval: 5,
        //   tickAmount: 30,
        //   labels: {
        //     enabled: false
        //   }
        // },
        yAxis: {
          title: {
            text: null
          },
          tickColor: 'var(--grayscale---gray-400)',
          lineColor: 'var(--grayscale---gray-400)',
          tickWidth: 0.2,
          tickLength: 5,
          lineWidth: 0.2,
          tickAmount: 6,

          gridLineWidth: 0,
          //
          // tickPositioner: function() {
          //   if (this.dataMin !== null && this.dataMax != null) {
          //     var scale = Math.pow(10, (this.dataMax.toString().length - 1));
          //     var tickAmount = this.tickAmount;
          //     var positions = [];
          //
          //     var maxNormalized = Math.ceil(this.dataMax / scale) * scale;
          //     var tickInterval = Math.ceil(maxNormalized / (tickAmount - 2));
          //
          //     for (var i = 0; i < tickAmount; i++) {
          //       positions.push(i * tickInterval);
          //     }
          //     return positions;
          //   }
          //   return [];
          // }
        },
        series: series
      }

      angular.extend(argOptions, options);


      if (document.getElementById(idpanel) == null) {
        return null;
      }
      const mainSerieColor = "var(--secondary-color)";


      var chart = angular.element(document.getElementById(idpanel)).highcharts();

      chart = new Highcharts.Chart(argOptions);

      return chart;
    }
  }
}

export const ngRealtimeComponent = {
  name: 'realtime',
  def: RealtimeComponent
}

