<div class="dropdown col-sm-3 dropdown-meter">
	<button
		class="btn btn-default dropdown-toggle"
		type="button"
		id="drop-meters"
		data-toggle="dropdown"
	>
		<span
			*ngIf="meter?.items?.length == 0"
			style="display: inline-flex; margin-right: 8px"
		>
			{{ "main.menu.no-meter-msg" | transloco }}
		</span>

		<span
			*ngIf="
				selectedSourceType == sources.meter &&
				(meter?.items?.length ?? -1) > 0 &&
				selectedSource == undefined
			"
			style="display: inline-flex; margin-right: 8px"
		>
			{{ "main.menu.point" | transloco }}
		</span>

		<span
			*ngIf="selectedSourceType == sources.meter && selectedSource"
			style="display: inline-flex; margin-right: 8px"
		>
			<img
				src="assets/images/custom_icons/icone_meter.svg"
				alt=""
				style="width: 24px; margin-right: 8px"
			/>
			{{ selectedSource.label }}
		</span>

		<span
			*ngIf="selectedSourceType == sources.group"
			style="display: inline-flex; margin-right: 8px"
		>
			<img
				src="assets/images/custom_icons/icone_grupo.svg"
				alt=""
				style="width: 24px; margin-right: 8px"
			/>
			{{ selectedSource?.label }}
		</span>
		<span class="caret"></span>
	</button>
	<ul class="dropdown-menu dropdown-menu-meter" aria-labelledby="drop-meters">
		<li>
			<span class="search-box">
				<i class="fa fa-search"></i>
				<input
					type="text"
					class="form-control search-input"
					[formControl]="filterMeter"
					placeholder="{{ 'main.search' | transloco }}"
				/>
			</span>

			<div class="scrollbox">
				<ul class="list-unstyled col-sm-12">
					<li
						*ngFor="let m of filteredItems; let index = index"
						style="margin: 5px 0px"
					>
						<div
							*ngIf="
								showOrganizationTitle == true &&
								(index == 0 ||
									m.organizationId != filteredItems[index - 1].organizationId)
							"
							class="divisor"
						>
							{{ m.organizationName }}
						</div>
						<a
							style="display: block"
							*ngIf="m.type == 'meter'"
							[ngClass]="{ active: selectedSource?.id == m.id }"
							(click)="selectMeter(m); mixPanelEvent(m)"
						>
							<img alt="" src="assets/images/custom_icons/icone_meter.svg" />
							{{ m.label }}
						</a>
						<a
							style="display: block"
							*ngIf="m.type == 'group'"
							[ngClass]="{ active: selectedSource?.id == m.id }"
							(click)="selectGroup(m); mixPanelEvent(m)"
						>
							<img alt="" src="assets/images/custom_icons/icone_grupo.svg" />
							{{ m.label }}
						</a>
					</li>
				</ul>
			</div>
		</li>
	</ul>
</div>
