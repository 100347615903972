import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';

export interface DateRange {
	start: Date;
	end: Date;
}

@Component({
	selector: 'app-date-range',
	templateUrl: './date-range.component.html',
	styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {
	@Input()
	startDate!: Date;

	@Input()
	endDate!: Date;

	@Output()
	selectedDate: EventEmitter<DateRange> = new EventEmitter<DateRange>();

	@Input()
	maxDate!: Date;

	@Input()
	minDate!: Date;

	rangeDateGroup: FormGroup;

	dateView: 'month' | 'year' | 'multi-year' = 'month';

	@Input()
	isAutoEndDate = true;

	constructor() {
		this.dateView = 'month';
		this.rangeDateGroup = new FormGroup({
			start: new FormControl(),
			end: new FormControl(),
		});
	}

	ngOnInit(): void {
		const emptyDates = !new Date(this.startDate) || !new Date(this.endDate);
		if (emptyDates) {
			throw 'need to pass start date or end date as Date type';
		} else {
			this.rangeDateGroup.get('start')?.setValue(this.startDate);
			this.rangeDateGroup.get('end')?.setValue(this.endDate);
		}
		this.disableEndDate();
		this.calculateEndDateByYear();
	}

	changedDate() {
		const start = this.rangeDateGroup.get('start')?.value;
		const end = this.rangeDateGroup.get('end')?.value;
		this.selectedDate.emit({ start, end });
	}

	calculateEndDateByYear() {
		this.endDate = DateTime.fromJSDate(this.startDate)
			.plus({ months: 11 })
			.toJSDate();
		this.rangeDateGroup.get('end')?.setValue(this.formatEndDate(this.endDate));
	}

	changeMonth(event: any | DateTime, picker: any) {
		this.startDate = DateTime.fromISO(event)
			.toUTC()
			.plus({ hours: 3 })
			.toJSDate();

		this.rangeDateGroup.get('start')?.setValue(this.startDate);
		this.calculateEndDateByYear();

		this.selectedDate.emit({ start: this.startDate, end: this.endDate });
		picker.close();
	}

	formatEndDate(endDate) {
		return DateTime.fromJSDate(endDate).toFormat('dd/MM/yyyy');
	}

	disableEndDate() {
		if (this.isAutoEndDate) {
			this.rangeDateGroup.get('end')?.disable();
		}
	}
}
