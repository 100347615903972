<h3 class="basic-data__title mt">
	<span class="mr-5">{{ "organization.label.msg1" | transloco }}</span>
	<ga-helper
		[text]="'organization.label.theme-tooltip' | transloco"
		[icon]="'fa-solid fa-circle-info'"
		[iconSize]="14"
		[iconColor]="'#A3A3A3'"
		[tooltipLocale]="'above'"
		class="helper"
	></ga-helper>
</h3>

<div class="row theme-row">
	<div class="form-group mt pd col-items">
		<label for="">{{ "organization.modal.msg5" | transloco }}</label>
		<ng-container *ngIf="theme?.textImage">
			<div
				class="box-logo"
				[style.background-color]="
					theme?.primaryColor && !this.theme?.isBackgroundWhite
						? theme?.primaryColor
						: 'white'
				"
			>
				<img [src]="theme.textImage" />
			</div>
		</ng-container>
	</div>
	<div class="form-group mt pd col-items">
		<label for="">{{ "organization.modal.msg4" | transloco }}</label>
		<ng-container *ngIf="theme?.logoImage">
			<div class="box-symbol" [style.background-color]="theme?.primaryColor">
				<img [src]="theme.logoImage" />
			</div>
		</ng-container>
	</div>
	<div class="form-group mt pd col-items">
		<label for="">{{ "organization.modal.primary" | transloco }}</label>
		<ng-container *ngIf="theme?.primaryColor">
			<div class="box-color" [style.background-color]="theme?.primaryColor">
				<small>{{ theme?.primaryColor }}</small>
			</div>
		</ng-container>
	</div>
	<div class="form-group mt pd col-items">
		<label for="">{{ "organization.modal.secondary" | transloco }}</label>
		<ng-container *ngIf="theme?.secondaryColor">
			<div class="box-color" [style.background-color]="theme?.secondaryColor">
				<small>{{ theme?.secondaryColor }}</small>
			</div>
		</ng-container>
	</div>
	<div class="form-group mt pd col-items">
		<label for="">{{ "organization.modal.tertiary" | transloco }}</label>
		<ng-container *ngIf="theme?.tertiaryColor">
			<div class="box-color" [style.background-color]="theme?.tertiaryColor">
				<small>{{ theme?.tertiaryColor }}</small>
			</div>
		</ng-container>
	</div>
</div>
<div class="addition-btn-div sm-center">
	<button class="add-user" type="button" (click)="openDialog()">
		{{ "organization.label.personalize" | transloco }}
	</button>
</div>

<div class="user-text">
	<p>
		<span>{{ "organization.label.important" | transloco }}</span>
		{{ "organization.label.theme-msg" | transloco }}
	</p>
</div>
