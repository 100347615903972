import { sailsConn } from "webapp/app/setup/sails";

export class AbstractRealtime {
  constructor($scope, $compile, $interval, $timeout, Charts, MeterService, GroupService, $translate) {
    this.$interval = $interval;
    this.$compile = $compile;
    this.charts = Charts;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.meterService = MeterService;
    this.groupService = GroupService;
    this.$translate = $translate;

    this.meterSamples = [];
  }

  async setOffLineTimer(interval, meterId, showChart) {
    const vm = this;
    this.offLineFlag = false;
    this.$interval.cancel(this.offLineTimer);
    await vm.getLastStatus(meterId, showChart); 
    this.offLineTimer = this.$interval(function() {
      vm.getLastStatus(meterId, showChart);
    }, interval || 300000);
  }
  async getLastStatus(id, showChart){
    if(showChart){
      const vm = this;
      let meterdata = await this.meterService.status({meterId:id});
      if(vm.lastReceived){
        for(let i= 0; i<vm.lastReceived.length; i++){
          if(vm.lastReceived[i].id == id){
            if(meterdata?.received){
              let lastsent = meterdata.received.updatedAt;
              let lastreceived = meterdata.received.lastReceived.timestamp;
              if(moment().diff(lastsent, 'minutes') >= 15){
                vm.offLineFlag = true;
                vm.timestampoff = moment(lastsent).format(vm.$translate.instant('date-format-txt'));
              }
              else if(moment().diff(lastsent, 'minutes') > 7){
                vm.offLineFlag = false;
                vm.timestampoff = moment(lastsent).format(vm.$translate.instant('date-format-txt'));
              }
              else{
                vm.offLineFlag = false;
                vm.timestampoff = null;
              }

              if(vm.hasgeneration && moment(lastsent).diff(lastreceived, 'minutes') > 5){
                if(vm.meter.meterTypeId == '3'){
                  vm.timestampon[1] = (moment(lastreceived).format(vm.$translate.instant('date-format-txty')));
                }
                else{
                  vm.timestampon[0] = (moment(lastreceived).format(vm.$translate.instant('date-format-txty')));
                }
              }
              else if(moment(lastsent).diff(lastreceived, 'minutes') > 5){
                vm.timestampon = moment(lastreceived).format(vm.$translate.instant('date-format-txty'));
              }
              else{
                vm.timestampon = null
              }
              vm.loading = false;
            }
            else{
              vm.nodata = true;
              vm.offLineFlag = true;
              vm.loading = false;
            }
          }
        }   
      }    

    }
  }

  $onDestroy(){
    const vm = this;

    sailsConn.socket.off('measurements');

    vm.panelId = null;
    vm.ids.forEach(id => {
      if (id != null) {
        vm.leaveSocket(id);
      }
    });

    if (this.chart) {
      this.chart.destroy();
      this.chart = undefined;
    }
  }

  //leave realtime socket
  leaveSocket(id) {
    sailsConn.socket.post(this.serverAddress + '/api/leaveRealtime', {
      meterId: id
    }, function(body, JWR) {
      console.log('Sails responded with: ', body);
    });
  }

  //subscribe realtime socket
  subscribeSocket(id, measurementsListener) {
    const vm = this;
    sailsConn.socket.post(vm.serverAddress + '/api/realtime', {
      meterId: id
    }, function serverResponded(body, JWR) {
      console.log('Sails responded with: ', body);

    });

    //Start listening socket
    sailsConn.socket.on('measurements', measurementsListener);
  }

  setAvailablePhases(phases){
    this.availablePhases = phases;
  }

  getAvailablePhases(){
    return this.availablePhases || [];
  }


  setSamples(samples, meterId){
    const vm = this;
    vm.meterSamples[meterId] = {
      activePower :[], //p*
      reactivePower : [], //q*
      current : [],// i*
      voltage : []//v*
    }

    samples.forEach( sample => {
      let timestamp = +moment(sample.timestamp).tz(vm.timezone).format("x");

      vm.meterSamples[meterId].activePower.push({
        timestamp : timestamp,
        a : sample.pa,
        b : sample.pb,
        c : sample.pc,
        aggregated : vm.sumAttributes(sample, 'p', false)
      });

      vm.meterSamples[meterId].reactivePower.push({
        timestamp : timestamp,
        a : sample.qa,
        b : sample.qb,
        c : sample.qc,
        aggregated : vm.sumAttributes(sample, 'q', false)
      });

      vm.meterSamples[meterId].current.push({
        timestamp : timestamp,
        a : sample.ia,
        b : sample.ib,
        c : sample.ic,
        aggregated :  vm.sumAttributes(sample, 'i', false)
      });

      vm.meterSamples[meterId].voltage.push({
        timestamp : timestamp,
        a : sample.va,
        b : sample.vb,
        c : sample.vc,
        aggregated : vm.avgAttributes(sample, 'v', false)
      });
    });
  }
  setGenerationSamples(samples, meterId, isgeneration){
    const vm = this;
    vm.meterSamplesAux[meterId] = {
      activePower :[], //p*
      reactivePower : [], //q*
      current : [],// i*
      voltage : []//v*
    }

    if(isgeneration){
      vm.meterSamples[meterId] = {
        activePower :[], //p*
        reactivePower : [], //q*
        current : [],// i*
        voltage : []//v*
      }
      samples.forEach( sample => {
        let timestamp = +moment(sample.timestamp).tz(vm.timezone).format("x");

        vm.meterSamplesAux[meterId].activePower.push({
          timestamp : timestamp,
          a : sample.pa,
          b : sample.pb,
          c : sample.pc,
          generation : 0,
          aggregated : vm.sumAttributes(sample, 'p', isgeneration)
        });

        vm.meterSamplesAux[meterId].reactivePower.push({
          timestamp : timestamp,
          a : sample.qa,
          b : sample.qb,
          c : sample.qc,
          generation : 0,
          aggregated : vm.sumAttributes(sample, 'q', isgeneration)
        });

        vm.meterSamplesAux[meterId].current.push({
          timestamp : timestamp,
          a : sample.ia,
          b : sample.ib,
          c : sample.ic,
          generation : 0,
          aggregated : vm.sumAttributes(sample, 'i', isgeneration)
        });

        vm.meterSamplesAux[meterId].voltage.push({
          timestamp : timestamp,
          a : sample.va,
          b : sample.vb,
          c : sample.vc,
          generation : 0,
          aggregated : vm.avgAttributes(sample, 'v', isgeneration)
        });
      });
    }
    else{
      samples.forEach( sample => {
        let timestamp = +moment(sample.timestamp).tz(vm.timezone).format("x");
  
        vm.meterSamplesAux[meterId].activePower.push({
          timestamp : timestamp,
          a : sample.pa,
          b : sample.pb,
          c : sample.pc,
          generation: 0,
          aggregated : vm.sumAttributes(sample, 'p', false)
        });
  
        vm.meterSamplesAux[meterId].reactivePower.push({
          timestamp : timestamp,
          a : sample.qa,
          b : sample.qb,
          c : sample.qc,
          generation: 0,
          aggregated : vm.sumAttributes(sample, 'q', false)
        });
  
        vm.meterSamplesAux[meterId].current.push({
          timestamp : timestamp,
          a : sample.ia,
          b : sample.ib,
          c : sample.ic,
          generation: 0,
          aggregated :  vm.sumAttributes(sample, 'i', false)
        });
  
        vm.meterSamplesAux[meterId].voltage.push({
          timestamp : timestamp,
          a : sample.va,
          b : sample.vb,
          c : sample.vc,
          generation: 0,
          aggregated : vm.avgAttributes(sample, 'v', false)
        });
      });
    }
  }

  mergeSamples(meterIdC, meterIdG){
    const vm = this;
    vm.meterSamples[meterIdC] = {
      activePower :[], //p*
      reactivePower : [], //q*
      current : [],// i*
      voltage : []//v*
    }

    if(vm.meterSamplesAux[meterIdC] && vm.meterSamplesAux[meterIdG]){
      for( var i = 0; i < vm.meterSamplesAux[meterIdC].activePower.length; i++){
        let k = vm.meterSamplesAux[meterIdG].activePower.findIndex((thistime) => thistime.timestamp == vm.meterSamplesAux[meterIdC].activePower[i]['timestamp']);
        if(vm.meterSamplesAux[meterIdG].activePower[i] && k>-1 && (vm.meterSamplesAux[meterIdC].activePower[i]['timestamp'] == vm.meterSamplesAux[meterIdG].activePower[k]['timestamp'])){
          vm.meterSamples[meterIdC].activePower.push({
            timestamp : vm.meterSamplesAux[meterIdC].activePower[i]['timestamp'],
            a : vm.meterSamplesAux[meterIdC].activePower[i]['a'],
            b : vm.meterSamplesAux[meterIdC].activePower[i]['b'],
            c : vm.meterSamplesAux[meterIdC].activePower[i]['c'],
            generation : vm.meterSamplesAux[meterIdG].activePower[i]['aggregated'],
            aggregated : (vm.meterSamplesAux[meterIdC].activePower[i]['aggregated']),
          });
          vm.meterSamples[meterIdC].reactivePower.push({
            timestamp : vm.meterSamplesAux[meterIdC].reactivePower[i]['timestamp'],
            a : vm.meterSamplesAux[meterIdC].reactivePower[i]['a'],
            b : vm.meterSamplesAux[meterIdC].reactivePower[i]['b'],
            c : vm.meterSamplesAux[meterIdC].reactivePower[i]['c'],
            generation : vm.meterSamplesAux[meterIdG].reactivePower[i]['aggregated'],
            aggregated : vm.meterSamplesAux[meterIdC].reactivePower[i]['aggregated'],
          });
          vm.meterSamples[meterIdC].current.push({
            timestamp : vm.meterSamplesAux[meterIdC].current[i]['timestamp'],
            a : vm.meterSamplesAux[meterIdC].current[i]['a'],
            b : vm.meterSamplesAux[meterIdC].current[i]['b'],
            c : vm.meterSamplesAux[meterIdC].current[i]['c'],
            generation : vm.meterSamplesAux[meterIdG].current[i]['aggregated'],
            aggregated : vm.meterSamplesAux[meterIdC].current[i]['aggregated'],
          });
          vm.meterSamples[meterIdC].voltage.push({
            timestamp : vm.meterSamplesAux[meterIdC].voltage[i]['timestamp'],
            a : vm.meterSamplesAux[meterIdC].voltage[i]['a'],
            b : vm.meterSamplesAux[meterIdC].voltage[i]['b'],
            c : vm.meterSamplesAux[meterIdC].voltage[i]['c'],
            generation : vm.meterSamplesAux[meterIdG].voltage[i]['aggregated'],
            aggregated : vm.meterSamplesAux[meterIdC].voltage[i]['aggregated'],
          });
        }
        else if(!vm.meterSamplesAux[meterIdG].activePower[i] && vm.meterSamplesAux[meterIdC].activePower[i]['timestamp']){
          vm.meterSamples[meterIdC].activePower.push({
            timestamp : vm.meterSamplesAux[meterIdC].activePower[i]['timestamp'],
            a : vm.meterSamplesAux[meterIdC].activePower[i]['a'],
            b : vm.meterSamplesAux[meterIdC].activePower[i]['b'],
            c : vm.meterSamplesAux[meterIdC].activePower[i]['c'],
            generation : 0,
            aggregated : (vm.meterSamplesAux[meterIdC].activePower[i]['aggregated']),
          });
          vm.meterSamples[meterIdC].reactivePower.push({
            timestamp : vm.meterSamplesAux[meterIdC].reactivePower[i]['timestamp'],
            a : vm.meterSamplesAux[meterIdC].reactivePower[i]['a'],
            b : vm.meterSamplesAux[meterIdC].reactivePower[i]['b'],
            c : vm.meterSamplesAux[meterIdC].reactivePower[i]['c'],
            generation : 0,
            aggregated : vm.meterSamplesAux[meterIdC].reactivePower[i]['aggregated'],
          });
          vm.meterSamples[meterIdC].current.push({
            timestamp : vm.meterSamplesAux[meterIdC].current[i]['timestamp'],
            a : vm.meterSamplesAux[meterIdC].current[i]['a'],
            b : vm.meterSamplesAux[meterIdC].current[i]['b'],
            c : vm.meterSamplesAux[meterIdC].current[i]['c'],
            generation : 0,
            aggregated : vm.meterSamplesAux[meterIdC].current[i]['aggregated'],
          });
          vm.meterSamples[meterIdC].voltage.push({
            timestamp : vm.meterSamplesAux[meterIdC].voltage[i]['timestamp'],
            a : vm.meterSamplesAux[meterIdC].voltage[i]['a'],
            b : vm.meterSamplesAux[meterIdC].voltage[i]['b'],
            c : vm.meterSamplesAux[meterIdC].voltage[i]['c'],
            generation : 0,
            aggregated : vm.meterSamplesAux[meterIdC].voltage[i]['aggregated'],
          });
        }
        else if(vm.meterSamplesAux[meterIdG].activePower[i] && !vm.meterSamplesAux[meterIdC].activePower[i]){
          vm.meterSamples[meterIdC].activePower.push({
            timestamp : vm.meterSamplesAux[meterIdG].activePower[i]['timestamp'],
            a : vm.meterSamplesAux[meterIdG].activePower[i]['a'],
            b : vm.meterSamplesAux[meterIdG].activePower[i]['b'],
            c : vm.meterSamplesAux[meterIdG].activePower[i]['c'],
            generation : vm.meterSamplesAux[meterIdG].activePower[i]['aggregated'],
            aggregated : -1 * vm.meterSamplesAux[meterIdG].activePower[i]['aggregated'],
          });
          vm.meterSamples[meterIdC].reactivePower.push({
            timestamp : vm.meterSamplesAux[meterIdG].reactivePower[i]['timestamp'],
            a : vm.meterSamplesAux[meterIdG].reactivePower[i]['a'],
            b : vm.meterSamplesAux[meterIdG].reactivePower[i]['b'],
            c : vm.meterSamplesAux[meterIdG].reactivePower[i]['c'],
            generation : vm.meterSamplesAux[meterIdG].reactivePower[i]['aggregated'],
            aggregated : -1 * vm.meterSamplesAux[meterIdG].reactivePower[i]['aggregated'],
          });
          vm.meterSamples[meterIdC].current.push({
            timestamp : vm.meterSamplesAux[meterIdG].current[i]['timestamp'],
            a : vm.meterSamplesAux[meterIdG].current[i]['a'],
            b : vm.meterSamplesAux[meterIdG].current[i]['b'],
            c : vm.meterSamplesAux[meterIdG].current[i]['c'],
            generation : vm.meterSamplesAux[meterIdG].current[i]['aggregated'],
            aggregated : -1 * vm.meterSamplesAux[meterIdG].current[i]['aggregated'],
          });
          vm.meterSamples[meterIdC].voltage.push({
            timestamp : vm.meterSamplesAux[meterIdG].voltage[i]['timestamp'],
            a : vm.meterSamplesAux[meterIdG].voltage[i]['a'],
            b : vm.meterSamplesAux[meterIdG].voltage[i]['b'],
            c : vm.meterSamplesAux[meterIdG].voltage[i]['c'],
            generation : vm.meterSamplesAux[meterIdG].voltage[i]['aggregated'],
            aggregated : vm.meterSamplesAux[meterIdG].voltage[i]['aggregated'],
          });
        }
      } 
    }
  }
  sumAttributes(sample, attributeLetter, isgeneration){
    let total = 0;
    if(this.meter.attachedMeters && this.meter.attachedMeters.length > 0 && isgeneration){
      this.meter.attachedMeters[0].consumptionPhases.forEach(phase => {
        let value = sample[attributeLetter + phase.toLowerCase()];
        total += value || 0;
      });
    }
    else{
      this.getAvailablePhases().forEach(phase => {
        let value = sample[attributeLetter + phase.toLowerCase()];
        total += value || 0;
      });
    }
    return total;
  }
  avgAttributes(sample, attributeLetter, isgeneration){
    let total = 0;
    let count = 0;
    if(this.meter.attachedMeters.length > 0 && isgeneration){
      this.getAvailablePhases().forEach(phase => {

        total += sample[attributeLetter + phase.toLowerCase()] || 0;
        count++;
      });
    }
    else{
      this.getAvailablePhases().forEach(phase => {

        total += sample[attributeLetter + phase.toLowerCase()] || 0;
        count++;
      });
    }

    if(total != 0){
      return total / count;
    } else {
      return 0;
    }
  }

  genereateSamples(meterId, powerType, phase) {
    if(this.meterSamples[meterId][powerType].length == 0 && this.meterSamplesAux[meterId]){
      this.meterSamples[meterId][powerType] = this.meterSamplesAux[meterId][powerType];
    }
    return this.meterSamples[meterId][powerType].map( sample =>{
      return [sample.timestamp, sample[phase]];
    });
  }
}
