import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';

@Component({
	selector: 'ga-color-picker',
	templateUrl: './color-picker.component.html',
	styleUrls: ['./color-picker.component.scss'],
})
export class GaColorPickerComponent implements OnChanges {
	@Input() title!: string;
	@Input() tooltip = '';
	@Input() required = false;
	@Input() color = '#737373';
	@Input() hasInput = false;
	@Input() showIcon = true;
	@Input() showFirstModal = true;
	@Output() colorEmitter: EventEmitter<string> = new EventEmitter();

	isFirstModalOpen = false;
	isSecondModalOpen = false;
	hasNotClicked = true;
	formatedStrColor = '';

	@ViewChild('dropdown') dropdown!: ElementRef;

	colors = [
		'#33CC99',
		'#48A0F0',
		'#E062B4',
		'#EC8720',
		'#9E0059',
		'#045DB7',
		'#008F5D',
		'#D73A3A',
		'#9820B1',
		'#47C8F0',
		'#52357C',
		'#996633',
	];

	ngOnInit() {
		this.formattedColor = this.color;
	}

	constructor(private renderer: Renderer2, private el: ElementRef) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.color) {
			if (this.color.trim() === '') {
				this.color = '#737373';
				this.formattedColor = this.color;
			}
		}
	}

	set formattedColor(color) {
		this.formatedStrColor = color.substring(1);
	}

	@HostListener('document:click', ['$event'])
	onDocumentClick(event: Event) {
		if (
			!this.el.nativeElement.contains(event.target) &&
			this.isFirstModalOpen
		) {
			this.isFirstModalOpen = false;
		}
	}
	inputColor(event) {
		this.color = '#' + event;
		this.changeColor();
	}
	changeColor() {
		this.formattedColor = this.color;
		this.colorEmitter.emit(this.color);
	}

	selectOption(colorOption: string) {
		this.color = colorOption;
		this.changeColor();
	}

	toggleDropdown() {
		if (this.showFirstModal) {
			if (this.isSecondModalOpen) {
				this.isSecondModalOpen = !this.isSecondModalOpen;
				return;
			}
			this.isFirstModalOpen = !this.isFirstModalOpen;
		} else {
			this.isSecondModalOpen = !this.isSecondModalOpen;
			this.updatePickerStyle();
		}
	}

	changeModal() {
		this.isFirstModalOpen = !this.isFirstModalOpen;
		this.isSecondModalOpen = !this.isSecondModalOpen;

		this.updatePickerStyle();
	}

	updatePickerStyle() {
		if (!this.hasNotClicked) return;

		setTimeout(() => {
			const elements = this.el.nativeElement.querySelector('.color-picker');

			if (this.showFirstModal) {
				this.appendArrow(elements);
			}

			this.appendHr(elements);

			this.hasNotClicked = !this.hasNotClicked;
		}, 0);
	}

	appendArrow(elements) {
		const div = this.renderer.createElement('div');

		const icon = this.renderer.createElement('i');

		this.renderer.addClass(icon, 'fa-solid');
		this.renderer.addClass(icon, 'fa-arrow-left');
		this.renderer.addClass(icon, 'fa-lg');
		this.renderer.addClass(div, 'go-back');

		this.renderer.appendChild(div, icon);

		this.renderer.listen(div, 'click', () => {
			this.isFirstModalOpen = !this.isFirstModalOpen;
			this.isSecondModalOpen = !this.isSecondModalOpen;
		});

		this.renderer.insertBefore(elements, div, elements.firstChild);
	}

	appendHr(elements) {
		const buttons = this.el.nativeElement.querySelector('.button-area');
		const hr = this.renderer.createElement('hr');

		this.renderer.insertBefore(elements, hr, buttons);
	}
}
