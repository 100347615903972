<label *ngIf="title">
	<span class="mr-5">{{ title }}</span>
	<span class="required mr-5">* </span>
	<ga-helper
		*ngIf="tooltip"
		[text]="tooltip"
		[icon]="'fa-solid fa-circle-info'"
		[iconSize]="12"
		[iconColor]="'#A3A3A3'"
		[tooltipLocale]="'above'"
	></ga-helper>
</label>
<div
	class="group-section"
	[ngClass]="{ 'ga-input-error': showError, 'field-disabled': disabled }"
>
	<span *ngIf="lAddon" class="input-group-addon">
		<i class="laddon-icon fa-regular" [ngClass]="lAddon"></i>
	</span>
	<ng-container>
		<input
			class="form-control"
			[(ngModel)]="val"
			id="input-{{ id }}"
			name="input-{{ id }}"
			(input)="onChange(val)"
			[disabled]="!hasPassword"
			[value]="val"
			[placeholder]="hasPassword ? placeholder : '•••••••••'"
			(blur)="onTouch()"
			(keydown)="tryEmitEnter($event)"
			[type]="inputType"
			[min]="min"
			[max]="max"
			[attr.maxlength]="maxLength"
			[attr.minlength]="minLength"
			[step]="step"
			[ngClass]="{
				'has-error': showError,
				hasRAddon: rAddon,
				hasLAddon: lAddon
			}"
			[mask]="mask"
		/>
		<div
			*ngIf="!hasPassword"
			(click)="changePasswordVisibility()"
			class="div-has-not-password"
		></div>
	</ng-container>
	<ng-container *ngIf="hidePassword; else showIcon">
		<i class="fa-solid fa-eye show" (click)="changePasswordVisibility()"></i>
	</ng-container>
	<ng-template #showIcon>
		<i
			class="fa-solid fa-eye-slash show"
			(click)="changePasswordVisibility()"
		></i>
	</ng-template>
	<span *ngIf="rAddon" class="input-group-addon">{{ rAddon }}</span>
</div>
<mat-error *ngIf="showError">{{
	error || ("global.field-required" | transloco)
}}</mat-error>
