<div class="navbar-content" [ngClass]="{ 'no-border': !hasBorderBottom }">
	<!-- adicionar dynamicHeight no mat-tab-group para remover animação -->
	<mat-tab-group
		*ngIf="tabList.length"
		[selectedIndex]="this.selectedIndex"
		(selectedTabChange)="selectedTabChange($event)"
	>
		<ng-container *ngFor="let tab of tabList; let i = index">
			<!-- necessário adicionar *wchHasFeature="tab.permission"
				no mat-tab para adicionar as permissões,
				mas está navegando para primeira aba -->
			<mat-tab [label]="tab.label | transloco" [disabled]="tab.disabled">
				<ng-template matTabContent>
					<ng-content></ng-content>
				</ng-template>
			</mat-tab>
		</ng-container>
	</mat-tab-group>
</div>
