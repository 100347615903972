import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { CookieService } from 'ngx-cookie-service';
import { ChartConfigService } from '../shared/chart/chart-config.service';

declare let $: JQueryStatic;

@Injectable({ providedIn: 'root' })
export class TranslocoManipulationService {
	constructor(
		private chartConfig: ChartConfigService,
		private i18n: TranslocoService,
		private cookie: CookieService
	) {}

	get cookieOptions(): any {
		return {
			path: '/',
		};
	}

	async setTranslation(user) {
		const lang = user.language.replace('-', '_');
		this.cookie.set('NG_TRANSLATE_LANG_KEY', lang, this.cookieOptions);
		this.setupThirdPartyLang(lang);
	}

	removeLangFromCookie() {
		/* another way to skip the bug of ngx-cookie-service and remove correctly the cookie */
		this.cookie.set(
			'NG_TRANSLATE_LANG_KEY',
			'',
			new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
			'/'
		);
	}

	isSameLang(user): boolean {
		const lang = user.language.replace('-', '_');
		const old = this.cookie.get('NG_TRANSLATE_LANG_KEY');
		if (lang && old.toLowerCase() !== lang.toLowerCase()) {
			this.removeLangFromCookie();
			return false;
		}
		return true;
	}

	setupThirdPartyLang(lang: string) {
		if (lang == 'pt_BR' || lang == 'en_US' || lang == 'es_MX') {
			this.i18n.setActiveLang(lang);
			if (lang == 'en_US') {
				this.chartConfig.setLanguage(lang);
			}
		} else {
			const arr = lang.split('');
			arr[3] = arr[3].toUpperCase();
			arr[4] = arr[4].toUpperCase();
			lang = arr.join('');
		}
	}
}

export const ng2TranslocoService = {
	name: TranslocoManipulationService.name,
	def: downgradeInjectable(TranslocoManipulationService),
};
