class CompanySelectorController {
  constructor($scope, $timeout, UserService, CompanyService, $translate) {
    this.$scope = $scope;
    this.userService = UserService;
    this.companyService = CompanyService;

    this.$translate = $translate;
    this.$timeout = $timeout;
  }

  async $onInit() {
    this.watchSelectedCompany();

    this.disabled = this.disabled ? this.disabled : false;
    this.userService.getCurrentUser().then((user) => (this.isAdmin = user.isAdmin));

    this.compSelectId = this.selectedCompany ? this.selectedCompany.id : 'companySelector';
    this.placeholder = this.placeholder ? this.placeholder : 'company-selector.placeholder';

    this.$scope.$on('company-selector-reset', this.resetComponent.bind(this));
    this.$scope.$on('company-selector-edit', this.companySelectEventEmitted.bind(this));
  }

  getOrganizationParam() {
    return this.organization ? {organization : this.organization} : {}
  }

  async getCompanies(params = {}) {
    return this.companyService.getCompanies(params).then((companies) => {
      this.getSelectedCompany(companies)
      return companies;
    });
  }

  getSelectedCompany(companies) {
    if (this.compId) {
      this.selectedCompany = companies.find((c) => c.id == this.compId);
      this.companyName =
      this.selectedCompany.id + ' - ' + this.selectedCompany.tradingName;
    }
  }

  async $onChanges(changesObj) {
    if (
      changesObj.organization?.currentValue &&
      changesObj.organization.previousValue !=
      changesObj.organization.currentValue 
    ) {
      const params = this.getOrganizationParam();
      this.companies = await this.getCompanies(params);
      
    }

    this.setSelectedValue(changesObj.compId)
  }

  setSelectedValue(companyId) {
    if (companyId && this.companies) {
      this.selectedCompany = this.companies.find((c) => c.id == companyId.currentValue);
      this.companyName = this.selectedCompany ? this.selectedCompany.id + ' - ' + this.selectedCompany.tradingName : "";
    }
  }

  onChange(ev) {
    this.onCompChanged(null);
  }

  onCompanySelected(company) {
    this.selectedCompany = company;
    this.onCompChanged(company);
  }

  watchSelectedCompany() {
    const vm = this;
    vm.$scope.$watch('coVm.selectedCompany', function (newCompany) {
      if (newCompany) {
        vm.selectedCompany = newCompany;
        vm.companyName = `${vm.selectedCompany.id} - ${vm.selectedCompany.tradingName}`;
      }
    });
  }
  
  resetComponent() {
    const vm = this;
    delete vm.companyName;
    delete vm.selectedCompany;
  }

  companySelectEventEmitted(event, company) {
    const vm = this;
    if(vm.companies) {
      let comp = (company && company.id) ? company : vm.companies.find((c) => c.id == company);
      vm.onCompanySelected(comp);
    }
  }
}

const CompanySelector = {
  templateUrl: '/app/directives/company-selector/company-selector.html',
  controller: CompanySelectorController,
  controllerAs: 'coVm',
  bindings: {
    organization: '<',
    compId: '<?',
    selectedCompany: '=?',
    onCompChanged: '<',
    // Disables the Input
    disabled: '<',
    // I18n key for input text placeholder
    placeholder: '@',
  },
};

export const ngCompanySelector = {
  name: 'companySelector',
  def: CompanySelector
}