import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'root' })
export class TariffService {
	constructor(private http: HttpClient) {}

	getFormSchemaByTariffType(tariffTypeId) {
		if (!tariffTypeId) return;

		return this.http
			.get(`${environment.backendUrl}/api/tarifftypes/${tariffTypeId}/schema`)
			.toPromise()
			.then((res) => {
				return res;
			});
	}
}

export const ng2TariffService = {
	name: TariffService.name,
	def: downgradeInjectable(TariffService),
};
