import * as angular from 'angular';

class MeterCloseCycleComponent{

    constructor(notify, $scope, $translate, $state, MeterService) {
        this.notify = notify;
        this.$scope = $scope;
        this.$translate = $translate;
        this.$state = $state;
        this.meterService = MeterService;
    }

    confirmClose() {
        const vm = this;
        vm.confirmend = true;
    }

    async finishCycle() {
        const vm = this;
        moment.locale('pt-BR');
        vm.deviceinstallation.endDate = moment(vm.deviceinstallation.closeHour, 'DD/MM/YYYY - HH:mm').format();
        try {
          await vm.meterService.saveDeviceInstallation(vm.deviceinstallation);
          if(vm.callingfrom == 'details'){
            vm.showMessage(vm.$translate.instant('meter-page.validation.success-close-cycle'));
          }
        } catch (error) {
          vm.showErrorMessages(error);
        }
        $("#meterCloseCycle").modal('hide');
    }


    showMessage(message, type = 'success'){

        let notifyMessage = {
          messageTemplate: "<i class='fa fa-check-circle fa-2 icon-notity' aria-hidden='true'></i><span>" + message + "</span>",
          templateUrl: "/app/components/angular-notify.html",
          position: "right",
          duration: (4 * 1000)
        };
    
        if(type == 'error'){
          notifyMessage.messageTemplate = "<i class='fa fa-exclamation-circle fa-2 icon-notity' aria-hidden='true'></i><span>" + message + "</span>";
          notifyMessage.classes = "cg-notify-message-error";
        }
    
        this.notify(notifyMessage);
      }
    
      showErrorMessage(message){
        this.showMessage(message, 'error');
      }
      showErrorMessages(res){
        const vm = this;
        let data = res?.Error;
        let first = Object.keys(data.Errors)[0];
        Object.keys(data.Errors).forEach(key => {
          vm.showErrorMessage(data.Errors[key][0].message);
        });
      }
}

const meterCloseCycleOptions = {
    templateUrl: '/app/directives/meter-modals/meter-close-cycle.html',
    bindings: {
        meter: '=',
        deviceinstallation: '=',
        confirmend: '=',
        callingfrom: '@'
    },
    controller: MeterCloseCycleComponent,
    controllerAs: 'ccVm'
};

export const ngMeterCloseCycle = {
  name: 'meterCloseCycle',
  def: meterCloseCycleOptions
}

// (function() {
//     'use strict';

//     angular
//     .module('dashboard-directives')
//     .directive('meterCloseCycle', meterCloseCycle);

//     function meterCloseCycle(){
//         var closeCycle =  {
//             restrict: 'E',{
//             restrict: 'E',
//             templateUrl: '/app/directives/meter-modals/meter-close-cycle.html',
//             scope: {
//                 meter: '=meter',
//                 deviceinstallation: '=deviceinstallation',
//                 confirmend: '=confirmend',
//                 closecycle: '=closecycle',
//                 callingfrom: '@callingfrom'
//             },
//             link: function(scope) {
//                 scope.$watch('deviceinstallation', function(value) {
//                 console.log(value)
//                 });

//                 console.log("linkin park")
//                 console.log(scope.deviceinstallation)
//             }
//         };
//             templateUrl: '/app/directives/meter-modals/meter-close-cycle.html',
//             scope: {
//                 meter: '=meter',
//                 deviceinstallation: '=deviceinstallation',
//                 confirmend: '=confirmend',
//                 closecycle: '=closecycle',
//                 callingfrom: '@callingfrom'
//             },
//             link: function(scope) {
//                 scope.$watch('deviceinstallation', function(value) {
//                 console.log(value)(function() {
//     'use strict';

//     angular
//     .module('dashboard-directives')
//     .directive('meterCloseCycle', meterCloseCycle);

//     function meterCloseCycle(){
//         var closeCycle =  {
//             restrict: 'E',{
//             restrict: 'E',
//             templateUrl: '/app/directives/meter-modals/meter-close-cycle.html',
//             scope: {
//                 meter: '=meter',
//                 deviceinstallation: '=deviceinstallation',
//                 confirmend: '=confirmend',
//                 closecycle: '=closecycle',
//                 callingfrom: '@callingfrom'
//             },
//             link: function(scope) {
//                 scope.$watch('deviceinstallation', function(value) {
//                 console.log(value)
//                 });

//                 console.log("linkin park")
//                 console.log(scope.deviceinstallation)
//             }
//         };
//             templateUrl: '/app/directives/meter-modals/meter-close-cycle.html',
//             scope: {
//                 meter: '=meter',
//                 deviceinstallation: '=deviceinstallation',
//                 confirmend: '=confirmend',
//                 closecycle: '=closecycle',
//                 callingfrom: '@callingfrom'
//             },
//             link: function(scope) {
//                 scope.$watch('deviceinstallation', function(value) {
//                 console.log(value)
//                 });

//                 console.log("linkin park")
//                 console.log(scope.deviceinstallation)
//             }
//         };
//         return closeCycle;
//     }
// })();
//                 });

//                 console.log("linkin park")
//                 console.log(scope.deviceinstallation)
//             }
//         };
//         return closeCycle;
//     }
// })();