import { DateTime, Settings } from 'luxon';
Settings.defaultLocale = 'pt-BR';
export interface DateList {
	label: string;
	name: string;
	month: number;
	value?: number | null;
}

export const addZeroToMonth = (month: number) => {
	let sMonth = '';
	return month < 10 ? (sMonth = '0' + month) : month;
};

export const dateFormatShortMonthAndYear = (date: DateTime) => {
	return (
		date.toFormat('MMM').charAt(0).toUpperCase() +
		date.toFormat('MMM').slice(1) +
		' ' +
		date.year
	);
};

export const isRealDate = (date: string) => {
	let isDate = true;
	try {
		if (DateTime.fromISO(date).isValid) {
			isDate = true;
		} else {
			isDate = false;
		}
	} catch (e) {
		isDate = false;
	}
	return isDate;
};

export const dateListYearNextCurrentDate = (
	luxonDate: DateTime
): DateList[] => {
	try {
		const dates: DateList[] = [];
		for (let i = 0; i <= 11; i++) {
			const date = luxonDate.plus({ months: i });
			const dateMonth = date.toFormat('MM-yyyy');
			dates.push({
				name: dateMonth,
				label: dateFormatShortMonthAndYear(date),
				month: date.month - 1,
				value: null,
			});
		}
		return dates;
	} catch (e) {
		console.error(e, 'formato de data incorreto');
		return [];
	}
};

export const dateListYearBeforeCurrentDate = (
	luxonDate: DateTime
): DateList[] => {
	try {
		const dates: DateList[] = [];
		for (let i = 12; i >= 1; i--) {
			const date = luxonDate.minus({ months: i });
			const dateMonth = date.toFormat('MM-yyyy');
			dates.push({
				name: dateMonth,
				label: dateFormatShortMonthAndYear(date),
				month: date.month - 1,
			});
		}
		return dates;
	} catch (e) {
		console.error('formato de data incorreto');
		return [];
	}
};

export const isoToDateTime = (date: string) => {
	return DateTime.fromISO(date).toUTC();
};

export const sortByDates = (arr: DateList[]) => {
	return arr.sort(
		(a: any, b: any) =>
			<any>DateTime.fromFormat(a.name, 'MM-yyyy') -
			<any>DateTime.fromFormat(b.name, 'MM-yyyy')
	);
};

export const getWeekDays = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
];
