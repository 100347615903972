import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { WINDOW } from '@ng-web-apis/common';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoManipulationService } from 'webapp/app/transloco/translate-manipulation.service';
import { environment } from 'webapp/environments/environment';
import {
	NG_ROOT_SCOPE,
	NG_ROUTER,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { ChartConfigService } from '../../chart/chart-config.service';
import { SessionUser } from '../../models/session-user.model';
import { TawkService } from '../../services/tawk.service';
import { UserService } from '../../services/user.service';
import { NavStateService } from '../nav-state.service';
import { WhiteLabelService } from '../whitelabel.service';
import { MixPanelService } from '../../services/mixpanel.service';

declare const $: JQueryStatic;

// const colorElement = document.querySelector(':root') as Element;
// const cssStyles = getComputedStyle(colorElement);

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
	headerImages: {
		small: string;
		textImage: string;
	}[] = [];
	preventLoop = 0;
	footerImages;

	headerImage = {
		small: '',
		textImage: '',
	};

	currentUser: SessionUser = {} as any;
	defaultstyle;

	tool: any;
	version = '';

	isMenuCollapsed = false;

	primaryColor: any;
	isBackgroundWhite: any;

	get gravatarUrl() {
		if (this.currentUser) {
			return `${this.currentUser.gravatarHashURL}.jpg?s=100&d=mm`;
		}
		return undefined;
	}

	constructor(
		@Inject(NG_ROUTER) private $state,
		@Inject(NG_ROOT_SCOPE) private $rootScope,
		@Inject(WINDOW) private window: Window,
		private navState: NavStateService,
		private whitelabel: WhiteLabelService,
		private translocoManipulation: TranslocoManipulationService,
		private i18n: TranslocoService,
		private chartConfig: ChartConfigService,
		private tawk: TawkService,
		private userService: UserService,
		private mixpanel: MixPanelService
	) {}

	ngOnInit(): void {
		this.version = environment.appVersion;
		this.toolGetSetup();
		this.changeTabs();
		this.userService.getCurrentUser().then((user) => {
			const currentModule = window.location.pathname.split('/')[1];
			this.toolGetSetup();
			this.currentUser = user;
			this.mixpanel.mixPanelInit(user);
			this.mixpanel.mixPanelEvent({
				type: 'page_viewed',
				object: { module: currentModule },
				hasTimeEvent: true,
				targetEvent: 'page_exit',
			});
			this.currentUser.isPersonificated = this.userService.isPersonificated();
			this.tawk.UpdateTawkUser(user);
			[this.defaultstyle, this.headerImages, this.footerImages] =
				this.whitelabel.setWhiteLabel(this.currentUser);

			this.primaryColor = this.currentUser.organizationObj.styles?.primaryColor;
			this.isBackgroundWhite =
				this.currentUser.organizationObj.styles?.isBackgroundWhite;

			const isSameLang = this.translocoManipulation.isSameLang(user);

			if (!isSameLang) {
				this.window.location.reload();
			}
			this.translocoManipulation.setTranslation(this.currentUser);
		});
		this.detectVisibility();
	}

	changeTabs() {
		this.$rootScope.$watchCollection('$stateParams', () => {
			const view = this.$state.current.data?.['tools@main'];
			this.tool = view;
		});
	}

	despersonificate() {
		this.userService.despersonificate().then((data) => {
			this.currentUser.isPersonificated = false;
			this.window.location.reload();
		});
	}

	hideMenu() {
		this.navState.toogle();
		this.isMenuCollapsed = !this.isMenuCollapsed;

		if (this.isMenuCollapsed) {
			this.mixpanel.mixPanelEvent({ type: 'menu_collapse' });
		} else {
			this.mixpanel.mixPanelEvent({ type: 'menu_expansion' });
		}
	}

	setupThirdPartyLang(lang: string) {
		if (lang == 'pt_BR' || lang == 'en_US' || lang == 'es_MX') {
			this.i18n.setActiveLang(lang);
			if (lang == 'en_US') {
				this.chartConfig.setLanguage(lang);
			}
		} else {
			const arr = lang.split('');
			arr[3] = arr[3].toUpperCase();
			arr[4] = arr[4].toUpperCase();
			lang = arr.join('');
		}
	}

	//TODO: Migrate to be using routerLink
	logout() {
		this.$state.go('logout');
	}

	//TODO: Migrate to be using routerLink
	goToMetersPage() {
		this.$state.go('registrations', { tab: 'dataSources' });
	}

	//TODO: Migrate to be using routerLink
	goToUserPage() {
		this.$state.go('main.user');
	}

	toolGetSetup() {
		const view = this.$state.current.data?.['tools@main'];
		this.tool = view;
		this.preventLoop = 0;
		this.$rootScope.$on(
			'$stateChangeSuccess',
			(event, toState, toParams, fromState, fromParams, options) => {
				const view = this.$state.current.data?.['tools@main'];
				this.tool = view;

				const toModule = toState.url.split('?')[0].split('/')[1];
				const fromModule = window.location.pathname.split('/')[1];

				const fromTab = fromParams.tab;
				const toTab = toParams.tab;

				if (
					this.preventLoop <= 0 &&
					!toState.name.includes(fromModule) &&
					fromModule !== toModule
				)
					this.changeModule(toModule, fromModule, fromState, fromTab);

				if (this.preventLoop <= 0 && fromTab !== toTab)
					this.changeTab(toTab, fromTab);
			}
		);
	}

	changeModule(toModule, fromModule, fromState, fromTab) {
		this.preventLoop++;
		this.mixpanel.mixPanelEvent({
			type: 'page_exit',
			object: { module: fromModule },
		});

		if (fromTab) {
			this.mixpanel.mixPanelEvent({
				type: 'tab_exit',
				object: { tab: fromTab },
			});
		}

		setTimeout(() => {
			this.mixpanel.mixPanelEvent({
				type: 'page_viewed',
				object: { module: toModule },
				hasTimeEvent: true,
				targetEvent: 'page_exit',
			});
		}, 200);
		setTimeout(() => {
			this.preventLoop = 0;
		}, 400);
	}

	changeTab(toTab, fromTab) {
		this.preventLoop++;
		this.mixpanel.mixPanelEvent({ type: 'tab_exit', object: { tab: fromTab } });
		setTimeout(() => {
			this.mixpanel.mixPanelEvent({
				type: 'tab_viewed',
				object: { tab: toTab },
				hasTimeEvent: true,
				targetEvent: 'tab_exit',
			});
		}, 200);
		setTimeout(() => {
			this.preventLoop = 0;
		}, 400);
	}

	detectVisibility() {
		let visibilityChange;
		switch (true) {
		case typeof document.hidden !== 'undefined':
			visibilityChange = 'visibilitychange';
			break;
		case typeof (<any>document).mozHidden !== 'undefined':
			visibilityChange = 'mozvisibilitychange';
			break;
		case typeof (<any>document).msHidden !== 'undefined':
			visibilityChange = 'msvisibilitychange';
			break;
		case typeof (<any>document).webkitHidden !== 'undefined':
			visibilityChange = 'webkitvisibilitychange';
			break;
		default:
			break;
		}
		const acao = (event) => {
			if (this.preventLoop > 0) return;
			this.preventLoop++;
			const currentModule = window.location.pathname.split('/')[1];
			this.mixpanel.mixPanelEvent({
				type: event.srcElement.hidden ? 'page_exit' : 'page_viewed',
				object: { module: currentModule },
				hasTimeEvent: !event.srcElement.hidden,
				targetEvent: 'page_exit',
			});
			setTimeout(() => {
				this.preventLoop = 0;
			}, 400);
		};
		addEventListener(visibilityChange, acao, false);
		const beforeUnloadListener = (event) => {
			event.preventDefault();
			if (this.preventLoop > 0) return;
			this.preventLoop++;
			const currentModule = window.location.pathname.split('/')[1];
			this.mixpanel.mixPanelEvent({
				type: 'page_exit',
				object: { module: currentModule },
			});
			setTimeout(() => {
				this.preventLoop = 0;
			}, 400);
		};
		addEventListener('beforeunload', beforeUnloadListener, { capture: true });
	}
}

export const ng2HeaderComponent = {
	name: 'appHeader',
	def: downgradeComponent({ component: HeaderComponent }),
};
