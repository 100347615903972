<div class="expand-section">
	<table
		mat-table
		id="table-{{ id }}"
		class="subtable main"
		[dataSource]="table"
	>
		<ng-container
			*ngFor="let subCol of subColumns"
			[matColumnDef]="subCol.name"
		>
			<th
				mat-header-cell
				*matHeaderCellDef
				class="subCol-header expanded-table"
				[ngClass]="{
					hidden: !showSubcolumTitle
				}"
				[style.width]="subCol.width || 'none'"
				[style.padding-right]="subCol.paddingRight || 'none'"
			>
				{{ subCol.label | transloco }}
				<ga-helper
					*ngIf="subCol.tooltip"
					[text]="subCol.tooltip"
					[iconSize]="12"
					[tooltipLocale]="'above'"
					style="margin-left: 3px"
				></ga-helper>
			</th>
			<td
				mat-cell
				*matCellDef="let row; let k = index"
				class="expanded-table"
				[style.width]="subCol.width || 'none'"
				[style.padding-right]="subCol.paddingRight || 'none'"
			>
				<ng-container
					[ngTemplateOutlet]="templates"
					[ngTemplateOutletContext]="{
						element: row,
						column: subCol,
						tableId: tableId
					}"
				>
				</ng-container>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedSubColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedSubColumns"></tr>
	</table>
	<mat-paginator
		#subPaginator
		id="subPaginator-{{ id }}"
		class="subtable"
		[ngClass]="{ hide: !showSubtablePaginator || !data.length }"
		[pageSizeOptions]="[10, 15, 20]"
		[showFirstLastButtons]="true"
	>
	</mat-paginator>
</div>
