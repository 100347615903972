import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { convertImage } from 'webapp/app/utils/files';
import { FormControl } from '@angular/forms';
import { defaultStyle } from 'webapp/app/shared/app-shell/whitelabel-default';
import { CookieService } from 'ngx-cookie-service';
import { DateTime } from 'luxon';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { BehaviorSubject } from 'rxjs';
import { GetTheme, Theme } from './theme.model';
export const SEND_THEME = 'SEND_THEME';
@Injectable({
	providedIn: 'root',
})
export class OrganizationThemeService {
	private _state$: BehaviorSubject<Theme> = new BehaviorSubject({} as Theme);
	$theme = this._state$.asObservable();

	constructor(
		private i18n: TranslocoService,
		private cookie: CookieService,
		private notification: NotificationService
	) {
		this.initFromCookie();
	}

	initFromCookie() {
		if (this.cookie.get(SEND_THEME)?.length) {
			const cookieInfo: Theme = JSON.parse(this.cookie.get(SEND_THEME));
			this._state$.next(cookieInfo);
			return;
		}
		this.setDefaultTheme();
	}

	set $newState(theme: Theme) {
		this._state$.next(theme);
	}

	setDefaultTheme() {
		this._state$.next(this.transformToTheme(this.defaultTheme()));
	}

	removeFromCookie() {
		this.cookie.delete(SEND_THEME);
	}

	transformToTheme(theme: GetTheme): Theme {
		return {
			...theme,
			isBackgroundWhite: JSON.parse(theme.isBackgroundWhite.value), //to boolean
			textImage: theme.textImage[0].base64Data,
			logoImage: theme.logoImage[0].base64Data,
		};
	}

	sendToCookie(theme: Theme) {
		const expires = DateTime.now().plus({ days: 1 }).toJSDate();
		const msg = this.i18n.translate('organization.modal.send');
		this._state$.next(theme);
		this.notification.success({ msg });
		this.cookie.set(SEND_THEME, JSON.stringify(theme), expires);
	}

	tooltipLogoTranslation() {
		return `${this.i18n.translate('organization.label.theme-format')} PNG
		${this.i18n.translate('organization.label.theme-dimension')} 150x40px
		${this.i18n.translate('organization.label.theme-image')} ${this.i18n.translate(
	'organization.label.theme-image-text'
)}`;
	}

	defaultTheme(): GetTheme {
		return {
			defaultStyle: true,
			isBackgroundWhite: new FormControl(false),
			primaryColor: defaultStyle().primaryColor,
			secondaryColor: defaultStyle().secondaryColor,
			tertiaryColor: defaultStyle().tertiaryColor,
			logoImage: convertImage(defaultStyle().logoImage),
			textImage: convertImage(defaultStyle().textImage),
		};
	}

	tooltipSymbolTranslation() {
		return `${this.i18n.translate('organization.label.theme-format')} PNG
		${this.i18n.translate('organization.label.theme-dimension')} 28x40px`;
	}
}
