import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { ProjectConsumptionDetail } from '../project.model';

@Component({
	selector: 'app-economy-project-consume-detail',
	templateUrl: './economy-project-consume-detail.component.html',
	styleUrls: ['./economy-project-consume-detail.component.scss'],
	//encapsulation: ViewEncapsulation.None,
})
export class EconomyProjectConsumeDetailComponent implements OnInit {
	currentYear: number = DateTime.now().year;
	currentConsumptions: ProjectConsumptionDetail[] = [];
	allConsumptions: ProjectConsumptionDetail[] = [];

	constructor(
		public dialogRef: MatDialogRef<EconomyProjectConsumeDetailComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit() {
		if (this.data.element.consumptions.length !== 0) {
			this.allConsumptions = this.data.element.consumptions;
			this.currentYear = DateTime.fromFormat(
				this.data.currentDate,
				'yyyy-MM-dd'
			).year;
			this.currentConsumptions = this.filterConsumptionsByYear(
				this.currentYear
			);
		}
	}

	filterConsumptionsByYear(year: number) {
		return this.allConsumptions.filter(
			(consumption) =>
				DateTime.fromFormat(consumption.date, 'yyyy-MM').year === year
		);
	}

	subYear() {
		const firstDate = this.allConsumptions[0].date;
		const firstYear = DateTime.fromFormat(firstDate, 'yyyy-MM').year;
		if (this.currentYear - 1 < firstYear) return;
		this.currentYear = <number>this.currentYear - 1;
		this.currentConsumptions = this.filterConsumptionsByYear(this.currentYear);
	}

	addYear() {
		const lastDate = this.allConsumptions[this.allConsumptions.length - 1].date;
		const lastYear = DateTime.fromFormat(lastDate, 'yyyy-MM').year;
		if (this.currentYear + 1 > lastYear) return;
		this.currentYear = <number>this.currentYear + 1;
		this.currentConsumptions = this.filterConsumptionsByYear(this.currentYear);
	}

	totalByYear(value: string): number {
		return this.currentConsumptions
			.map((element: any) => element[value])
			.reduce((acc, current) => (acc += current), 0);
	}
}
