import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'webapp/environments/environment';
import { CredentialsParams } from '../models/credentials-params.model';
import { Credential } from '../models/credential.model';

@Injectable({ providedIn: 'root' })
export class CredentialsService {
	constructor(private http: HttpClient) {}

	getCredentials(populateMetadatas = false) {
		return this.http
			.get(`${environment.backendUrl}/api/credentials`, {
				params: { populateMetadatas: populateMetadatas },
			})
			.toPromise()
			.then((res: any) => {
				return res?.credentials || [];
			})
			.catch((error) => {
				throw error;
			});
	}

	__getCredentials(query: CredentialsParams) {
		let params = new HttpParams();
		params = params.append('populateMetadatas', query.populateMetadatas);
		params = params.append('organization', query.organization);

		return this.http.get<any>(`${environment.backendUrl}/api/credentials`, {
			params: params,
		});
	}

	getDistributors() {
		return this.http
			.get(`${environment.backendUrl}/api/credentials/distributors`)
			.toPromise()
			.then((res: any) => {
				return res?.distributors || [];
			})
			.catch((error) => {
				throw error;
			});
	}

	getDashboardDistributors() {
		return this.http
			.get(`${environment.backendUrl}/api/dashboard/distributors`)
			.toPromise()
			.then((res: any) => {
				return res?.distributors || [];
			})
			.catch((error) => {
				throw error;
			});
	}

	createCredentials(data) {
		return this.http
			.post(`${environment.backendUrl}/api/credentials`, data)
			.toPromise()
			.then((res: any) => res)
			.catch((error) => {
				throw error;
			});
	}

	deleteCredentials(id) {
		return this.http.delete(`${environment.backendUrl}/api/credentials/${id}`);
	}

	getCredential(id) {
		return this.http.get<Credential>(
			`${environment.backendUrl}/api/credentials/${id}`
		);
	}

	getAccessPassword(body) {
		return this.http.post(
			`${environment.backendUrl}/api/credentials/getAccessPassword`,
			body
		);
	}

	updateCredential(id, body) {
		return this.http
			.put(`${environment.backendUrl}/api/credentials/${id}`, body)
			.toPromise()
			.catch((error) => {
				throw Error(error);
			});
	}

	getCredentialAccess(robotApiId) {
		return this.http
			.get(`${environment.backendUrl}/api/credentials/access/${robotApiId}`)
			.toPromise()
			.then((res: any) => res)
			.catch((error) => {
				throw Error(
					`${error.statusText} - list access credential by robot api`
				);
			});
	}

	getInstallNumbers(credId) {
		return this.http
			.get(`${environment.backendUrl}/api/credentials/${credId}/installnumbers`)
			.toPromise()
			.then((res: any) => res?.installNumbers)
			.catch((error) => {
				throw error;
			});
	}

	addInstallNumber(credId, installNumber) {
		return this.http
			.put(
				`${environment.backendUrl}/api/credentials/${credId}/installnumbers/${installNumber}/add`,
				{ installNumber: installNumber }
			)
			.toPromise()
			.catch((error) => {
				throw error;
			});
	}

	removeInstallNumber(credId, installNumber) {
		return this.http
			.put(
				`${environment.backendUrl}/api/credentials/${credId}/installnumbers/${installNumber}/remove`,
				{ installNumber: installNumber }
			)
			.toPromise()
			.then((res: any) => res)
			.catch((error) => {
				throw error;
			});
	}
}

export const ng2CredentialsService = {
	name: CredentialsService.name,
	def: downgradeInjectable(CredentialsService),
};
