import {
	Component,
	Inject,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { CalendarService } from 'webapp/app/shared/services/calendar.service';
import { EconomyProjectService } from 'webapp/app/shared/services/economy-projects.service';

import { EconomyProject } from '../../project.model';
import { FormPlannedProjectComponent } from '../form/form-planned-project.component';
import { ListPlannedProjectsComponent } from '../list/list-planned-projects.component';
import { PlannedProject } from '../planned-project.model';
import { PlannedProjectService } from '../planned-projects.service';

@Component({
	selector: 'app-planned-projects-info',
	templateUrl: './planned-projects-info.component.html',
	styleUrls: ['./planned-projects-info.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class PlannedProjectsInfo implements OnInit, OnDestroy {
	plannedProjects: PlannedProject[] = [];
	economyProjects: EconomyProject[] = [];

	calendarChannel = '';
	observerId: any;
	calendarOptions: any = {} as any;
	calendarDate: Date = new Date();

	totalEconomyConsume: any = {} as any;
	totalEconomyPlannedConsume: any = {} as any;
	percentTotal = 0;

	plannedProjectsSubscription!: Subscription;

	constructor(
		public dialogRef: MatDialogRef<PlannedProjectsInfo>,
		public dialog: MatDialog,
		public plannedProjectService: PlannedProjectService,
		public economyProjectService: EconomyProjectService,
		private calendarService: CalendarService,
		@Inject(MAT_DIALOG_DATA) public organizationId: number
	) {
		this.calendarChannel = 'branches';
		this.calendarOptions = {
			displayFormatted: true,
			displayPeriodSelector: false,
			format: 'short',
			datepickerPosition: 'right',
			period: 'month',
			periods: ['month'],
		};
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.listAll();
		}, 0);
		this.getTotalConsumeEconomy(this.economyProjectService.economyProjectsList);
		this.updateProjectInfo();
		this.initCalendar();
	}

	initCalendar() {
		this.registerAsCalendarObserver();
	}

	registerAsCalendarObserver() {
		this.observerId = this.calendarService.registerObserver(
			this.onCalendarNotify.bind(this),
			this.calendarChannel
		);
	}

	updateProjectInfo() {
		this.plannedProjectsSubscription =
			this.economyProjectService.economyProjectsListChanged.subscribe(
				(planned: EconomyProject[]) => {
					this.getTotalConsumeEconomy(planned);
				}
			);
	}

	getTotalConsumeEconomy(list: EconomyProject[]) {
		this.totalEconomyConsume.monetary = +this.economyProjectService
			.getTotalEconomy(list, 'value')
			.toFixed(2);
		this.totalEconomyConsume.kwh = +this.economyProjectService
			.getTotalEconomy(list, 'consumption')
			.toFixed(2);
	}

	ngOnDestroy() {
		this.plannedProjectsSubscription.unsubscribe();
		this.calendarService.unregisterObserver(
			this.observerId,
			this.calendarChannel
		);
	}

	onCalendarNotify(calendarData, event) {
		if (
			event === this.calendarService.Events.UPDATE &&
			this.plannedProjectService.getOrganization()
		) {
			this.calendarDate = new Date(calendarData.startDate);
			this.listAll();
		}
	}

	listAll() {
		const currentDate = DateTime.fromISO(
			this.calendarDate.toISOString()
		).toFormat('yyyy-MM-dd');
		const params = {
			date: currentDate,
			presentation: true,
			organization: this.plannedProjectService.getOrganization(),
		};
		this.plannedProjectService.getAll(params).subscribe((resp) => {
			this.plannedProjects = resp;
			this.getTotalPlannedEconomy();
			this.getPercentTotalEnergyMonetaryByConsumption();
			this.plannedProjectService.listPlannedProjects = resp;
		});
	}

	listPlannedProjectDialog(): void {
		this.dialogRef.close();
		this.dialog.open(ListPlannedProjectsComponent, {
			width: '65%',
		});
	}

	createPlannedProjectDialog(): void {
		this.dialogRef.close();
		this.dialog.open(FormPlannedProjectComponent, {
			width: '65%',
		});
	}

	getTotalPlannedEconomy() {
		if (this.plannedProjects.length != 0) {
			this.totalEconomyPlannedConsume.kwh = this.plannedProjects
				.map((planned: PlannedProject) => planned.plannedEnergySavings)
				.reduce((acc, current) => (acc += current));
			this.totalEconomyPlannedConsume.monetary =
				this.plannedProjects
					.map((planned: PlannedProject) => planned.plannedMonetarySavings)
					.reduce((acc, current) => (acc += current)) || '--';
		}
	}

	getPercentTotalEnergyMonetaryByConsumption() {
		if (this.totalEconomyPlannedConsume.monetary) {
			this.percentTotal =
				(this.totalEconomyPlannedConsume.monetary /
					this.totalEconomyConsume.monetary) *
				100;
		}
	}
}
