import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { ChartType, InstallationObj } from './models/consumption-saving.model';
import { ProjectConsumptionDetail } from '../project.model';
import { MultiSelectOption } from 'webapp/app/shared/multi-selector/multi-selector.model';

export interface ConsumptionPeriod {
	installDate: DateTime;
	last?: DateTime;
	startDate: DateTime;
	endDate: DateTime;
}

@Injectable({
	providedIn: 'any',
})
export class ConsumptionSavingService {
	private allInstalls: InstallationObj[] = [];
	public selectedInstalls: InstallationObj[] = [];
	public period!: ConsumptionPeriod;

	set consumptionPeriod(currentDate: string) {
		this.period = {
			installDate: this.minInstallDate,
			startDate: DateTime.fromFormat(currentDate, 'yyyy-MM'),
			endDate: DateTime.fromFormat(currentDate, 'yyyy-MM')
				.plus({ months: 11 })
				.endOf('month'),
		};
	}

	set allInstallations(installations) {
		this.allInstalls = installations;
	}

	listToMultiSelect(list: any[]): MultiSelectOption[] {
		const multiSelectorList: MultiSelectOption[] = [
			{
				id: 0,
				label: 'Somar todas as instalações',
				onlyOption: true,
			},
		];
		list.forEach((item, index) => {
			const multiSelectorObj = {} as MultiSelectOption;
			multiSelectorObj.id = item.installId;
			multiSelectorObj.label = item.installNumber;
			multiSelectorList.push(multiSelectorObj);
		});
		return multiSelectorList;
	}

	installToSelector(installations: InstallationObj[]): {
		id: string | number;
		label: string;
	}[] {
		return installations.map((i) => ({
			id: i.installId,
			label: i.installNumber,
		}));
	}

	set selectedInstallations(installId: number[]) {
		if (installId[0] === 0) {
			this.selectedInstalls = this.allInstalls;

			return;
		}
		this.selectedInstalls = this.allInstalls.filter((i) =>
			installId.includes(i.installId)
		);
	}

	get minInstallDate(): DateTime {
		return this.selectedInstalls.reduce((minDate, install) => {
			const startDate = DateTime.fromISO(install.startDate);
			return startDate < minDate ? startDate : minDate;
		}, DateTime.now());
	}

	get categories(): { date: string; presetationDate: string }[] {
		const datesArray: { date: string; presetationDate: string }[] = [];
		for (let i = 0; i < 12; i++) {
			const date = this.period.startDate.plus({ months: i });
			datesArray.push({
				date: date.toFormat('MM-yyyy'),
				presetationDate: date.toFormat('MMM yyyy'),
			});
		}
		return datesArray;
	}

	/* Consumo previsto */
	expectedConsumptionBar(
		allConsumptions: ProjectConsumptionDetail[] = [],
		type: ChartType = 'consumption'
	) {
		return this.categories.map((category) => {
			const luxonDate = DateTime.fromFormat(category.date, 'MM-yyyy');
			const targetDate =
				luxonDate < this.period.installDate &&
				luxonDate >= this.period.startDate
					? luxonDate
					: luxonDate.minus({ year: 1 });

			const found = allConsumptions.find(
				(d) => DateTime.fromFormat(d.date, 'yyyy-MM').month === targetDate.month
			);
			return found ? found[type] : null;
		});
	}

	/* consumo executado */
	executedConsumptionBar(
		allConsumptions: ProjectConsumptionDetail[] = [],
		type: ChartType = 'value'
	) {
		const lastDate = this.categories[this.categories.length - 1].date;
		const luxonLastDate = DateTime.fromFormat(lastDate, 'MM-yyyy');
		return this.categories.map((category) => {
			const luxonDate = DateTime.fromFormat(category.date, 'MM-yyyy');
			const targetDate =
				luxonDate >= this.period.installDate && luxonDate <= luxonLastDate
					? luxonDate.toFormat('yyyy-MM')
					: null;

			const found = allConsumptions.find((d) => d.date === targetDate);
			return found ? found[type] : null;
		});
	}

	dataSourcePeriod(): { startDate: string; endDate: string } {
		const endDate = this.categories[this.categories.length - 1].date;
		const strEndDate = DateTime.fromFormat(endDate, 'MM-yyyy').toFormat(
			'yyyy-MM-dd'
		);
		let startDate = this.categories[0].date;
		startDate =
			DateTime.fromFormat(endDate, 'MM-yyyy') <= this.period.installDate
				? DateTime.fromFormat(startDate, 'MM-yyyy').toFormat('yyyy-MM-dd')
				: DateTime.fromFormat(startDate, 'MM-yyyy')
					.minus({ year: 1 })
					.toFormat('yyyy-MM-dd');

		return { startDate, endDate: strEndDate };
	}

	measurementLine(datasources: any[]) {
		return this.categories.map((category) => {
			const luxonDate = DateTime.fromFormat(category.date, 'MM-yyyy');
			const targetDate =
				luxonDate.startOf('month') >= this.period.installDate.startOf('month')
					? luxonDate.toFormat('yyyy-MM')
					: null;
			const found = datasources.find(
				(d) => d?.period && d.period == targetDate
			);
			return found ? found?.value : null;
		});
	}

	sumConsumptionsByPeriod(
		list: [{ consumptions: ProjectConsumptionDetail[] }]
	): ProjectConsumptionDetail[] {
		const summedValues = {};
		list.forEach((item) => {
			item.consumptions.forEach((consumptionObj) => {
				const { consumption, date, value } = consumptionObj;
				if (value && date) {
					if (summedValues.hasOwnProperty(date)) {
						summedValues[date].value += value;
						summedValues[date].consumption += consumption;
					} else {
						summedValues[date] = { value, date, consumption };
					}
				}
			});
		});
		return Object.values(summedValues);
	}

	sumDataSourcesByPeriod(
		totals: { energies: any[]; totals: any[] }[],
		property = 'totals',
		sumProperty = 'value'
	) {
		const summedValues = {};
		totals.forEach((obj) => {
			obj[property].forEach((total) => {
				const { [sumProperty]: value, period } = total;
				if (value && period) {
					if (summedValues.hasOwnProperty(period)) {
						summedValues[period].value += value;
					} else {
						summedValues[period] = { value, period: period };
					}
				}
			});
		});
		return Object.values(summedValues);
	}

	baseLine(datasources: any[]) {
		return this.categories.map((category) => {
			const luxonDate = DateTime.fromFormat(category.date, 'MM-yyyy');
			const targetDate =
				luxonDate < this.period.installDate &&
				luxonDate >= this.period.startDate
					? luxonDate
					: luxonDate.minus({ year: 1 });
			const found = datasources.find(
				(d) =>
					d?.period &&
					DateTime.fromFormat(d.period, 'yyyy-MM').month === targetDate.month
			);
			return found ? found?.value : null;
		});
	}

	private aggregateDatasources(totals, keysToAggregate, keysToKeep) {
		const newTotals = JSON.parse(JSON.stringify(totals));
		for (const key in newTotals) {
			const period = newTotals[key];
			const output = {};
			for (const k of keysToAggregate) {
				output[k] = 0;
			}
			for (const obj of period) {
				for (const k of keysToAggregate) {
					output[k] += obj[k];
				}
			}
			for (const keep of keysToKeep) {
				output[keep] = period[0][keep];
			}
			newTotals[key] = output;
		}
		return Object.values(newTotals);
	}

	aggregatedDataSources(
		list: any[],
		type: 'value' | 'consumedEnergy'
	): { value: number; period: string }[] {
		const dataSourcesByPeriod = {};
		for (const total of list) {
			if (!dataSourcesByPeriod[total.period]) {
				dataSourcesByPeriod[total.period] = [];
			}
			dataSourcesByPeriod[total.period].push(total);
		}
		const aggregatedTotals = this.aggregateDatasources(
			dataSourcesByPeriod,
			[type],
			['period']
		);
		return aggregatedTotals.map((a: any) => ({
			value: a[type],
			period: a.period,
		}));
	}
}
