import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Auxiliar } from './auxiliar';
import { environment } from 'webapp/environments/environment';
import { HttpUtil } from './http.util';

@Injectable({ providedIn: 'root' })
export class EnergyBillService {
	listMetadatas: any[] = [];
	constructor(
		private http: HttpClient,
		private httpUtil: HttpUtil,
		private Auxiliar: Auxiliar
	) {}

	createBillMetadata(params) {
		return this.http
			.post(environment.backendUrl + '/api/energyBillMetadatas', params)
			.toPromise()
			.then((res: any) => res?.energyBillMetadatas)
			.catch((error) => {
				throw Error(error);
			});
	}

	updateBillMetadata(id, metadata) {
		return this.http
			.put(`${environment.backendUrl}/api/energyBillMetadatas/${id}`, metadata)
			.toPromise()
			.then(
				(res: any) => res?.energyBillMetadatas,
				(error) => {
					throw Error(error);
				}
			);
	}

	uploadEnergyBill(params, data) {
		return this.httpUtil
			.postData('/api/energyBills', 'invoice', data, params)
			.then((res) => {
				return res?.data;
			})
			.catch((error) => {
				throw Error(error);
			});
	}

	getPresentationBills(params: any = {}) {
		params.presentationView =
			params.presentationView != null ? params.presentationView : true;
		return this.getEnergyBills(params);
	}

	getEnergyBills(params: any = {}) {
		return this.http
			.get(`${environment.backendUrl}/api/energyBillMetadatas`, {
				params: params,
			})
			.toPromise()
			.then((res: any) => res?.energyBillMetadatas)
			.catch((error) => {
				throw Error(error);
			});
	}

	getObservableEnergyBills(params: any = {}) {
		return this.http.get(`${environment.backendUrl}/api/energyBillMetadatas`, {
			params: params,
		});
	}

	getPresentationMetadata(metadataId, params: any = {}) {
		params.presentationView = true;
		return this.getEnergyBillMetadata(metadataId, params);
	}

	getEnergyBillMetadata(metadataId, params = {}) {
		return this.http
			.get(`${environment.backendUrl}/api/energyBillMetadatas/${metadataId}`, {
				params: params,
			})
			.toPromise()
			.then((res: any) => res)
			.catch((error) => {
				throw Error(error);
			});
	}

	getEnergyBillMetadatas(params: any = {}) {
		params.populateBills = 'false';
		return this.http
			.get(`${environment.backendUrl}/api/energyBillMetadatas`, {
				params: params,
			})
			.toPromise()
			.then((res: any) => res?.energyBillMetadatas || [])
			.catch((error) => {
				throw Error(error);
			});
	}

	deleteMetadata(metadataId) {
		return this.http
			.delete(`${environment.backendUrl}/api/energyBillMetadatas/${metadataId}`)
			.toPromise()
			.then((res: any) => res)
			.catch((error) => {
				throw Error(error);
			});
	}

	deleteEnergyBill(energyBillId) {
		return this.http
			.delete(`${environment.backendUrl}/api/energyBills/${energyBillId}`)
			.toPromise()
			.then((res: any) => res) // Deleted EnergyBill ID
			.catch((error) => {
				throw Error(error);
			});
	}

	downloadBillPDF(energyBillId) {
		return this.httpUtil
			.get({
				url: `/api/energyBills/${energyBillId}/pdf`,
				responseType: 'blob',
			})
			.then((res) => {
				this.Auxiliar.download(res, null);
				return true;
			})
			.catch((error) => console.error(error));
	}

	listInstallationNumbers() {
		return this.http
			.get(`${environment.backendUrl}/api/energyBillMetadatas/installNumbers`)
			.toPromise()
			.then((res: any) => res?.energyBillMetadatas)
			.catch((error) => {
				return new error();
			});
	}
}

export const ng2EnergyBillService = {
	name: EnergyBillService.name,
	def: downgradeInjectable(EnergyBillService),
};
