import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
	selector: 'ga-helper',
	templateUrl: './ga-helper.component.html',
	styleUrls: ['./ga-helper.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class GaHelperComponent {
	@Input() text = '';
	@Input() icon = 'fa-solid fa-circle-info';
	@Input() iconSize = 16;
	@Input() iconColor = '#A3A3A3';
	@Input() tooltipLocale: TooltipPosition = 'below';
}
