import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DistributorService } from '../../services/distributor.service';

@Component({
	selector: 'autocomplete-distributors',
	templateUrl: './autocomplete-distributors.component.html',
})
export class AutocompleteDistributorsComponent implements OnInit, OnChanges {
	@Input() distributorId: number | undefined;
	@Input() distributorName: string | undefined;
	@Output() distributorSelected: EventEmitter<any> = new EventEmitter();

	listDistributors: any[] = [];

	filteredDistributors$: Observable<any[]> = new Observable();
	distributorInput: FormControl = new FormControl('', [Validators.required]);

	constructor(private distributorService: DistributorService) {}

	async ngOnInit(): Promise<void> {
		this.listDistributors = await this.distributorService.distributors();
		this.filteredDistributors$ = this.distributorInput.valueChanges.pipe(
			startWith(''),
			map((value: string) => {
				if (value) {
					return this._filterDistributors(value);
				}
				return this.listDistributors;
			})
		);
		if (this.distributorId || this.distributorName) {
			this.checkSelected();
		}
	}

	ngOnChanges(changes) {
		if (changes.distributorId || changes.distributorName) {
			this.checkSelected();
		}
	}

	checkSelected() {
		if (
			this.distributorId &&
			this.findDistributor(<number>this.distributorId)
		) {
			this.distributorInput.setValue(
				this.findDistributor(<number>this.distributorId).name
			);
			this.distributorSelected.emit(
				this.findDistributor(this.distributorId).id
			);
		}
		if (this.distributorName && this.findDistributor(this.distributorName)) {
			this.distributorInput.setValue(this.distributorName);
			this.distributorSelected.emit(
				this.findDistributor(this.distributorName).id
			);
		}
	}

	findDistributor(value: any) {
		return (
			this.listDistributors.find((distributor) => distributor.id == value) ||
			this.listDistributors.find(
				(distributor) =>
					distributor.name.replace('-', ' ').toLowerCase() ==
					value.toLowerCase()
			)
		);
	}

	getOptionSelected($event: MatAutocompleteSelectedEvent) {
		this.distributorSelected.emit($event.option.id);
	}

	private _filterDistributors(value: string): any[] {
		const filterValue = value.toLowerCase();
		return this.listDistributors.filter((option: any) =>
			option.name.toLowerCase().includes(filterValue)
		);
	}
}
