import { AbstractRealtime } from "./abstract-realtime.component";
import * as Highcharts from 'highcharts';
import * as angular from 'angular';
import { environment } from "webapp/environments/environment";

const colorElement = document.querySelector(':root');
const cssStyles = getComputedStyle(colorElement);

const phasesCharacteristics = {
  'A': {
    name: "Fase A",
    color: 'var(--dataviz---md--blue)',
    phase: 'a'
  },
  'B': {
    name: "Fase B",
    color: 'var(--dataviz---lt--pink)',
    phase: 'b'
  },
  'C': {
    name: "Fase C",
    color: 'var(--dataviz---dk--purple)',
    phase: 'c'
  },
  'generation': {
    name: "Geração",
    color: 'var(--dataviz---dk--orange)',
    phase: 'generation'
  }
}

function RealTimeAdvancedChart() {

  var realTimeChart = {
    restrict: 'E',
    template: require('raw-loader!./realtime-advanced.html').default,
    scope: {
			meter: '=',
			width: '@',
			attachedMeters: '=?',
			height: '@',
			timezone: '=?',
			options: '=?',
			showMeterLabel: '=?'
    },

    controller: RealtimeAdvanced,
    controllerAs: 'vm',
    bindToController: true
  };

  return realTimeChart;
}

class RealtimeAdvanced extends AbstractRealtime {
	constructor($scope, $compile, $interval, $timeout, Charts, MeterService, GroupService, $translate, UserService, MixPanelService) {
		super($scope, $compile, $interval, $timeout, Charts, MeterService, GroupService, $translate);
		this.createChart = this.createChart.bind(this);
		this.userService = UserService;
		this.mixpanel = MixPanelService;

	}

	$onInit() {
		const vm = this;
		this.loading = true;
		this.timezone = this.timezone || "America/Sao_Paulo";
		this.series = [];
		this.ids = [];
		this.lastReceived = [];
		this.data = [];
		this.ids.push(this.meter.id);
		this.meterSamplesAux = [];
		this.injecting = false;
		this.hasgeneration = false;
		this.isbidirectional = false;
		this.isgeneration = false;
		this.swaping = false;
		this.highestUnit = '';
		this.divisorValue = 1;
		this.pushedLastHour = false;
		this.meterIsActive = this.isMeterActive(this.meter);
		this.options = this.options || {
			energyStyle: {
				'font-size': ' 13px',
				'text-align': 'left'/*,
				 'padding-left': '0px'*/
			},
			showChart: true,
			showOffLineFlagIcon: true,
			showSerieIcon: true,
			loadingBarWidth: "120px",
		};
		this.setOffLineTimer(5 * 60 * 1000, this.meter.id, this.options.showChart);
		if (this.meter.attachedMeters.length > 0) {
			this.hasgeneration = true;
			this.timestampon = [];
		}
		if (this.meter.bidirectional) {
			this.isbidirectional = true;
		}
		if ([3,4,5].includes(this.meter.meterTypeId)) {
			this.isgeneration = true;
		}
		if (!this.meter.hasRealtime && (this.meter.meterType && !this.meter.meterType.hasRealtime)) {
			this.notAvailable = true;
			this.offLineFlag = true;
		}
		this.setAvailablePhases(vm.meter.consumptionPhases);
		vm.powerTypeActive = "activePower";
		vm.powerTypeReactive = "reactivePower";
		vm.powerTypeCurrent = "current";
		vm.powerTypeVoltage = "voltage";

		vm.powerTypes = [{
			label: vm.powerTypeActive,
			symbol: "W"
		},
		{
			label: vm.powerTypeReactive,
			symbol: "VAr"
		},
		{
			label: vm.powerTypeCurrent,
			symbol: "A"
		},
		{
			label: vm.powerTypeVoltage,
			symbol: "V"
		}];

		vm.powerTypeSelected = vm.powerTypeActive;

		this.panelId = 'realtime-chart' + '-' + JSON.stringify(angular.copy(this.ids).sort());

		vm.serverAddress = environment.backendUrl;
		vm.ids.forEach(id => {
			if (id != null) {
				vm.pushLastSamples(id);
				vm.subscribeSocket(id, vm.measurementsListener.bind(vm));
				vm.lastReceived.push({ id: id });
				let powerTypeSymbol = vm.powerTypes.find(pt => pt.label == vm.powerTypeSelected).symbol;
				const cssValT = String(cssStyles.getPropertyValue('--chartColor')).trim();
				vm.series.push({
					name: "Total",
					color: cssValT,
					data: [],
					id: id,
					phase: 'aggregated',
					fillOpacity: 0.2,
					enabled: true,
					symbol: powerTypeSymbol
				});

				vm.getAvailablePhases().forEach(phase => {
					var aux = phasesCharacteristics[phase].name.replace("Fase", `${vm.$translate.instant('main.demand.phase')}`);
					vm.series.push({
						name: aux,
						type: 'line',
						color: phasesCharacteristics[phase].color,
						phase: phasesCharacteristics[phase].phase,
						data: [],
						id: id,
						fillOpacity: 0.0,
						enabled: true,
						symbol: powerTypeSymbol
					});
				});
			}
		});
		if (vm.hasgeneration) {
			var idg = vm.meter.attachedMeters[0].id;
			let powerTypeSymbol = vm.powerTypes.find(pt => pt.label == vm.powerTypeSelected).symbol;
			vm.ids.push(idg);
			vm.lastReceived.push({ id: idg });
			vm.subscribeSocket(idg, vm.measurementsListener.bind(vm));
			vm.series.push({
				name: vm.$translate.instant('main.realtime.generation'),
				type: 'line',
				color: phasesCharacteristics['generation'].color,
				phase: phasesCharacteristics['generation'].phase,
				data: [],
				id: idg,
				fillOpacity: 0.2,
				enabled: true,
				symbol: powerTypeSymbol
			})
		}

		//chart wasn't constructed yet
		if (!vm.chart && vm.options.showChart) {
			vm.buildRealtime(vm.panelId, vm.series, vm.options);
		}
		;
	}

    isMeterActive(meter) {
      const hasActiveInstallation = (
        meter.deviceInstallation &&
        meter.deviceInstallation.startDate &&
        (!meter.deviceInstallation.endDate || moment().isBefore(meter.deviceInstallation.endDate))
      ) || !!meter.activeInstallationId
      if ((meter.deviceInstallation && meter.deviceInstallation.endDate) || meter.installationEndDate)
      this.timestampoff = moment(meter.installationEndDate || meter.deviceInstallation.endDate).format(this.$translate.instant('date-format-txt'));

      return this.meter.active && hasActiveInstallation
    }

    mixPanelEvent(eventName){
      const objectLog = {
        activeInstallationId: this.meter.activeInstallationId,
        activeInstallationStartDate: this.meter.activeInstallationStartDate,
        meterName: this.meter.label,
        meterId: this.meter.id,
        meterType: this.meter && this.meter.meterType ? this.meter.meterType.description : null,
      };
      this.mixpanel.mixPanelEvent({
        type: eventName,
        object: objectLog,
      });
    }

	selectPowerType(powerType) {
		const vm = this;
		vm.swaping = true;
		this.powerTypeSelected = powerType;
		if (this.chart) {

			if (vm.sampleTimeout) {
				vm.$timeout.cancel(vm.sampleTimeout);
			}

			for (let i = 0; i < vm.series.length; i++) {
				vm.chart.series[0].remove();
			}

			let powerTypeSymbol = vm.powerTypes.find(pt => pt.label == vm.powerTypeSelected).symbol;
			vm.leaveSocket(vm.meter.id);
			this.series.forEach(serie => {
				serie.lastReceivedValue = [];
				serie.data = [];
				serie.symbol = powerTypeSymbol;
				vm.chart.addSeries(serie);
			});

			if (vm.data) {
				//vm.data.forEach(data => {
				//vm.fillSeriesData(data);
				//vm.createSamplesTimer();
				//})
				vm.data = [];
				vm.pushLastSamples(vm.meter.id);
				setTimeout(() => {
					vm.subscribeSocket(vm.meter.id, vm.measurementsListener.bind(vm));
				}, 5000);
			}
		}
	}

	createSamplesTimer() {
		const vm = this;
		let highestValue = 0;
		let interval = 1000;
		let generatedValue = 0;
		let cosumedValue = 0;
		vm.sampleTimeout = this.$timeout(function () {
			for (let i = 0; i < vm.series.length; i++) {
				let serie = vm.series[i];
				let qSize = serie.data.length;
				let dQueue = qSize > 12 ? qSize - 12 : 1;
				if (serie.data.length > 0) {
					let mostRecent = vm.returnMostRecent(serie.data);
					if (mostRecent && moment().diff(mostRecent[0], 'minutes') > 1) {
						vm.lastTimestamp = moment(mostRecent[0]).format(vm.$translate.instant('date-format-fn'));
					}
					else if (moment().diff(mostRecent[0], 'minutes') <= 1) {
						vm.lastTimestamp = null;
					}
					serie.data = serie.data.sort(function (a, b) {
						return (a[0] - b[0]);
					});
					serie.lastReceivedValue = serie.data.shift();
					if (vm.hasgeneration || vm.isbidirectional) {
						if (serie.phase === phasesCharacteristics['generation'].phase) {
							generatedValue = serie.lastReceivedValue[1];
						}
						if (serie.phase === 'aggregated') {
							cosumedValue = serie.lastReceivedValue[1];
						}
					}
					vm.charts.addRealtimePoint(vm.chart, [serie.lastReceivedValue], i);
					if (serie.data.length == qSize) {
						serie.data.shift();
					}
				}
				if (serie.lastReceivedValue && Math.abs(serie.lastReceivedValue[1]) > highestValue) {
					highestValue = Math.abs(serie.lastReceivedValue[1]);
				}
			}

			if (generatedValue > cosumedValue) {
				vm.injecting = true;
			}
			if (highestValue > 1000 * 1000 * 1000) {
				vm.highestUnit = 'G';
				vm.divisorValue = 1000 * 1000 * 1000;
			}
			else if (highestValue > 1000 * 1000) {
				vm.highestUnit = 'M';
				vm.divisorValue = 1000 * 1000;

			}
			else if (highestValue > 1000) {
				vm.highestUnit = 'k';
				vm.divisorValue = 1000;
			}
			else {
				vm.highestUnit = '';
				vm.divisorValue = 1;
			}
			if (vm.series[0].data.length > 0) {

				vm.createSamplesTimer();
			}
		}, interval);
	}

	toggleSerie(serie) {
		const vm = this;
		for (let i = 0; i < vm.series.length; i++) {
			if (serie.phase == vm.series[i].phase) {
				vm.chart.series[i].visible == true ? vm.chart.series[i].hide() : vm.chart.series[i].show();
				break;
			}
		}
	}

	removeLast(data) {
		let lastIndex = 0;
		data.forEach((d, i) => {
			if (moment(d[0]).isBefore(moment(data[lastIndex][0]))) {
				lastIndex = i;
			}
		});
		let lastElement = data[lastIndex];
		data.splice(lastIndex, 1);
		return lastElement;
	}

	returnMostRecent(data) {
		let firstIndex = 0;
		data.forEach((d, i) => {
			if (moment(d[0]).isAfter(moment(data[firstIndex][0]))) {
				firstIndex = i;
			}
		});
		let firstElement = data[firstIndex];
		return firstElement;
	}

	async pushLastSamples(id) {
		const vm = this;
		let meterdata = await this.meterService.status({ meterId: id });
		if (meterdata?.received?.lastSamples?.length > 0) {
			meterdata.meterId = id.toFixed(0);
			meterdata.samples = meterdata.received.lastSamples;
			vm.measurementsListener(meterdata);
			vm.pushedLastHour = true;
		} else {
			vm.swaping = false;
		}
	}

	measurementsListener(data) {
		const vm = this;
		vm.data.push(data);
		//if there is data and the incoming data has an uid that this component is waiting for
		if (data.samples && data.samples.length > 0 && vm.ids.find(id => id == data.meterId) != undefined) {
			if (data.samples[0].pa != undefined || data.samples[0].pb != undefined || data.samples[0].pc != undefined) {
				vm.displayActive = true;
			}

			if (data.samples[0].qa != undefined || data.samples[0].qb != undefined || data.samples[0].qc != undefined) {
				vm.displayReactive = true;
			}

			if (data.samples[0].ia != undefined || data.samples[0].ib != undefined || data.samples[0].ic != undefined) {
				vm.displayCurrent = true;
			}

			if (data.samples[0].va != undefined || data.samples[0].vb != undefined || data.samples[0].vc != undefined) {
				vm.displayVoltage = true;
			}
			vm.loading = false;
			vm.fillSeriesData(data);
			//add data

			if (!data.received || vm.swaping == true) {
				vm.createSamplesTimer();
				vm.swaping = false;
			} else {
				let highestValue = 0;
				for (let i = 0; i < vm.series.length; i++) {
					let serie = vm.series[i];
					serie.lastReceivedValue = serie.data[serie.data.length - 1];
					if (serie.lastReceivedValue[1] > highestValue) {
						highestValue = serie.lastReceivedValue[1];
					}
				}
				if (highestValue > 1000 * 1000 * 1000) {
					vm.highestUnit = 'G';
					vm.divisorValue = 1000 * 1000 * 1000;
				}
				else if (highestValue > 1000 * 1000) {
					vm.highestUnit = 'M';
					vm.divisorValue = 1000 * 1000;
				}
				else if (highestValue > 1000) {
					vm.highestUnit = 'k';
					vm.divisorValue = 1000;
				}
				else {
					vm.highestUnit = '';
					vm.divisorValue = 1
				}
			}
		}
	}

	fillSeriesData(data) {
		const vm = this;
		var isgene = false;
		if ((data.meterId == vm.ids[0] && vm.hasgeneration) || (!vm.isgeneration && data.meterId == vm.ids[0])) {
			isgene = false;
		}
		else {
			isgene = true;
		}
		if (vm.hasgeneration) {
			if (data.samples) {
				vm.setGenerationSamples(data.samples, data.meterId, isgene);
				vm.mergeSamples(vm.ids[0], vm.ids[1]);
				for (let i = 0; i < vm.series.length; i++) {
					let serie = vm.series[i];
					serie.data = vm.genereateSamples(vm.ids[0], vm.powerTypeSelected, serie.phase);
				}
			}
		}
		else if (vm.isgeneration) {
			if (data.samples) {
				vm.setGenerationSamples(data.samples, data.meterId, isgene);
				for (let i = 0; i < vm.series.length; i++) {
					let serie = vm.series[i];
					serie.data = vm.genereateSamples(vm.ids[0], vm.powerTypeSelected, serie.phase);
				}
			}
		}
		else {
			if (data.samples) {
				vm.setSamples(data.samples, data.meterId);
				for (let i = 0; i < vm.series.length; i++) {
					let serie = vm.series[i];

					if (data.meterId == serie.id) {
						serie.data = vm.genereateSamples(data.meterId, vm.powerTypeSelected, serie.phase);
					}
				}
			}
		}
	}

	buildRealtime(idpanel, series, argOptions) {
		const vm = this;
		//Default chart options
		var options = {

			chart: {
				renderTo: idpanel,
				backgroundColor: null,
				spacingLeft: 0,
				type: 'area',
				zoomType: 'x'
			},
			credits: {
				enabled: false
			},
			showYTicks: true,
			plotOptions: {
				series: {
					marker: {
						enabled: false
					},
					enableMouseTracking: true,
					states: {
						inactive: {
							opacity: 1
						}
					}
				}
			},

			title: {
				text: null
			},

			legend: {
				enabled: false
			},
			tooltip: {
				enabled: true,
				formatter: function () {
					//moment.locale('pt-br');
					var dateFormatted = moment(this.x).tz(vm.timezone).format(vm.$translate.instant('date-format-fullextended'));
					let powerTypeSymbol = vm.powerTypes.find(pt => pt.label == vm.powerTypeSelected).symbol;
					var show = dateFormatted + "<br/><strong>" + (this.y / vm.divisorValue).toFixed(2) + vm.highestUnit + powerTypeSymbol + "</strong>";

					return show;
				}
			},
			xAxis: {
				type: 'datetime',
				endOnTick: false,
				gridLineWidth: 0,
				lineColor: 'var(--grayscale---gray-400)',
				tickColor: 'var(--grayscale---gray-400)',
				lineWidth: 0.2,
				tickWidth: 0,
				tickLength: 5,
				tickPixelInterval: 5,
				tickAmount: 300,
				labels: {
					enabled: false
				}
			},
			yAxis: {
				title: {
					text: null
				},
				tickColor: 'var(--grayscale---gray-400)',
				lineColor: 'var(--grayscale---gray-400)',
				startOnTick: true,
				tickWidth: 0.2,
				tickLength: 5,
				lineWidth: 0.2,

				tickAmount: 6,

				gridLineWidth: 0,

				tickPositioner: function () {

					if (vm.powerTypeSelected == vm.powerTypeActive || vm.powerTypeSelected == vm.powerTypeReactive) {
						return null;
					}
					if (this.dataMin !== null && this.dataMax != null) {
						let maxPoint = Math.ceil(this.dataMax) * 1.10;
						let minPoint = Math.round(this.dataMin) * 0.90;

						maxPoint == 0 ? maxPoint = 1 : maxPoint = maxPoint;

						var tickAmount = this.tickAmount;
						var positions = [];

						var jump = (maxPoint - minPoint) / tickAmount;
						positions.push(+minPoint.toFixed(2));
						for (var i = 0; i < tickAmount - 1; i++) {
							positions.push(+(minPoint + (jump * i)).toFixed(2));
						}
						positions.push(+maxPoint.toFixed(2));

						return positions.filter(p => p >= 0);
					}
					return [];
				}
			},
			series: series
		}

		angular.extend(argOptions, options);
		setTimeout(() => {
			vm.createChart(argOptions);
		}, 2000);
	}

	createChart(argOptions) {
		const vm = this;
		const chartDiv = document.getElementById(vm.panelId);
		if (chartDiv) {
			angular.element(chartDiv).highcharts();
			vm.chart = new Highcharts.Chart(argOptions);
		}
	}
}

export const gaRealTimeAdvanceChart = {
  name: 'realtimeAdvanced',
  def: RealTimeAdvancedChart
}
