import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
	selector: 'ga-content-dialog',
	templateUrl: './ga-content-dialog.component.html',
	styleUrls: ['./ga-content-dialog.component.scss'],
})
export class GaContentDialogComponent {
	icon: string;
	iconType: string;
	title: string;
	message: string;
	showButtons: boolean;
	btnError: boolean;
	btnMessage = 'meter-page.button.save';
	template: TemplateRef<any>;
	confirm: Subject<boolean> = new Subject();
	close: Subject<boolean> = new Subject();
	loading = false;
	showOnlyOne = false;

	constructor(
		public dialogRef: MatDialogRef<GaContentDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: GaContentDialogModel
	) {
		this.icon = data.icon;
		this.iconType = data.iconType;
		this.title = data.title;
		this.message = data.message;
		this.showButtons =
			typeof data.showButtons == 'boolean' ? data.showButtons : true;
		this.btnError = data.btnError || false;
		this.showOnlyOne = data.showOnlyOne || false;
		if (data.btnMessage) this.btnMessage = data.btnMessage;
		this.template = data.template;
	}

	onConfirm(): void {
		// Close the dialog, return false
		this.confirm.next(true);
	}

	onDismiss(): void {
		this.close.next(true);
		// Close the dialog, return false
		this.dialogRef.close(false);
	}
}

export interface GaContentDialogModel {
	icon: string;
	iconType: string;
	title: string;
	message: string;
	showButtons: boolean;
	btnError: boolean;
	btnMessage: string;
	template: TemplateRef<any>;
	showOnlyOne: boolean;
}
