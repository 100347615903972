import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@repo/projects/webapp/global/material/material.module';
import { TranslocoModule } from '@ngneat/transloco';
import { GaDatepickerComponent } from './ga-datepicker.component';
import { GaHelperModule } from '../ga-helper/ga-helper.module';

@NgModule({
	declarations: [GaDatepickerComponent],
	imports: [
		CommonModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		TranslocoModule,
		GaHelperModule,
	],
	exports: [GaDatepickerComponent],
})
export class GaDatePickerModule {}
