<ng-container *ngIf="!isAutoEndDate">
	<label for="period">Período Inicial</label>
	<div class="range-group">
		<mat-date-range-input
			[formGroup]="rangeDateGroup"
			[rangePicker]="rangerPicker"
			[comparisonStart]="rangeDateGroup.value.start"
			[comparisonEnd]="rangeDateGroup.value.end"
		>
			<input matStartDate placeholder="Data inicial" formControlName="start" />
			<input matEndDate placeholder="Data Final" formControlName="end" />
		</mat-date-range-input>
		<mat-datepicker-toggle
			matSuffix
			[for]="rangerPicker"
		></mat-datepicker-toggle>
		<mat-date-range-picker
			#rangerPicker
			(closed)="changedDate()"
		></mat-date-range-picker>
	</div>
</ng-container>

<!-- picker first date and calculate last -->
<ng-container *ngIf="isAutoEndDate">
	<div class="date" [formGroup]="rangeDateGroup">
		<mat-datepicker
			#datepicker
			startView="year"
			[startAt]="startDate"
			(monthSelected)="changeMonth($event, datepicker)"
			panelClass="example-month-picker"
		></mat-datepicker>
		<mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
		<input
			matInput
			[matDatepicker]="datepicker"
			class="form-control"
			formControlName="start"
			[min]="minDate"
			[max]="maxDate"
		/>
		<p>até</p>
		<input class="form-control" formControlName="end" style="text-align: end" />
	</div>
</ng-container>
