
import { Ng1StateDeclaration } from "angular-ui-router";
import { ng2ForgotComponent } from "webapp/app/auth/forgot/forgot.component";
import { ng2InviteComponent } from "webapp/app/auth/invite/invite.component";
import { loginCmp } from "webapp/app/auth/login/login.component";
import { ng2LogoutComponent } from "webapp/app/auth/logout.component";
import { ng2NewPassowordComponent } from "webapp/app/auth/new-password/new-password.component";


export const authRoutes: Ng1StateDeclaration[] = [
    {
        url: '/login',
        name: 'login',
        component: loginCmp.name
    },
    {
        url: '/signup?token',
        name: 'signup',
        controller: 'SignupController',
        templateUrl: '/app/auth/signup.html',
        controllerAs: 'loginVm',
    },
    {
        url: '/redirect?token&url',
        name: 'redirect',
        controller: 'RedirectController',
        templateUrl: '/app/auth/redirect.html',
        controllerAs: 'loginVm',
    },
    {
        url: '/new-password?token',
        name: 'new-password',
        component: ng2NewPassowordComponent.name
    },
    {
        url: '/invite?token',
        name: 'invite',
        component: ng2InviteComponent.name
    },
    {
        url: '/forgot',
        name: 'forgot',
        component: ng2ForgotComponent.name
    },
    {
        url: '/logout',
        name: 'logout',
        component: ng2LogoutComponent.name
    }
]