<label *ngIf="title">
	<span class="mr-5">{{ title }}</span>
	<span *ngIf="required" class="required mr-5">*</span>
	<ga-helper
		*ngIf="tooltip"
		[text]="tooltip"
		[icon]="'fa-solid fa-circle-info'"
		[iconSize]="12"
		[iconColor]="'#A3A3A3'"
		[tooltipLocale]="'above'"
	></ga-helper>
</label>
<section class="upload-section">
	<ngx-dropzone
		(change)="onSelect($event)"
		class="btn-upload"
		[multiple]="false"
		[accept]="'image/png'"
	>
		<i class="fa fa-regular fa-cloud-arrow-up"></i>
	</ngx-dropzone>

	<div
		class="image-uploaded"
		[ngStyle]="{ 'background-color': backgroundColor }"
	>
		<ngx-dropzone-image-preview
			ngProjectAs="ngx-dropzone-preview"
			*ngFor="let f of files"
			[file]="f"
			[removable]="true"
			(removed)="onRemove(f)"
		>
		</ngx-dropzone-image-preview>
	</div>
</section>
