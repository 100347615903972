import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessKeysCardComponent } from './access-keys-card.component';
import { GaHelperModule } from '../ga-helper/ga-helper.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { MaterialModule } from '@repo/projects/webapp/global/material/material.module';
import { NgxMaskModule } from 'ngx-mask';
import { GaAccessPasswordModule } from '../ga-access-password/ga-access-password.module';
import { GaInputModule } from '../ga-input/ga-input.module';

@NgModule({
	declarations: [AccessKeysCardComponent],
	imports: [
		CommonModule,
		GaHelperModule,
		ReactiveFormsModule,
		FormsModule,
		TranslocoModule,
		MaterialModule,
		NgxMaskModule,
		GaAccessPasswordModule,
		GaInputModule,
	],
	exports: [AccessKeysCardComponent],
})
export class AccessKeysCardModule {}
