import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormFieldBaseDirective } from '../directive/form-field-base';
import { GaUploadImageService } from './ga-upload-image.service';
import { Base64Image, EmitFiles, isBase64Image } from './upload-image.model';

@Component({
	selector: 'ga-upload-image',
	templateUrl: './ga-upload-image.component.html',
	styleUrls: ['./ga-upload-image.component.scss'],
})
export class GaUploadImageComponent extends FormFieldBaseDirective {
	@Output() emitFiles: EventEmitter<EmitFiles> = new EventEmitter();
	@Input() backgroundColor = '#fff';
	@Input() set fileImages(file: Base64Image | Base64Image[] | File | File[]) {
		const files = Array.isArray(file) ? file : [file];
		files.forEach((item) => {
			if (item instanceof File) {
				this.files = <File[]>files;
			} else if (isBase64Image(<Base64Image>item)) {
				this.files = this.uploadService.convertBase64ToFiles(
					<Base64Image[]>file
				);
			}
		});
	}
	files: File[] = [];

	constructor(private uploadService: GaUploadImageService) {
		super();
	}

	async onSelect(event) {
		this.changeSelectedFile(event);
		const convetedFiles = await this.uploadService.convertFilesToBase64(
			this.files
		);
		this.emitFiles.emit(convetedFiles);
	}

	changeSelectedFile(event) {
		if (this.files.length >= 1) {
			this.files = [];
		}
		this.files.push(...event.addedFiles);
	}

	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
		this.emitFiles.emit({} as EmitFiles);
	}
}
