<label *ngIf="title">
	<span class="mr-5">{{ title }}</span>
	<span *ngIf="required" class="required mr-5">*</span>
	<ga-helper
		*ngIf="tooltip"
		[text]="tooltip"
		[icon]="'fa-solid fa-circle-info'"
		[iconSize]="12"
		[iconColor]="'#A3A3A3'"
		[tooltipLocale]="'above'"
	></ga-helper>
</label>
<mat-chip-list
	#chipList
	aria-label="Chip selection"
	[ngClass]="{ 'chiplist-error': showError, 'field-disabled': disabled }"
>
	<mat-chip
		*ngFor="let option of selectedOptions"
		[selectable]="!disabled"
		[removable]="!disabled"
		(removed)="remove(option)"
	>
		{{ option.label }}
		<button matChipRemove *ngIf="!disabled">
			<i class="fa-solid fa-circle-xmark"></i>
		</button>
	</mat-chip>
	<input
		#chipsInput
		class="form-control"
		[matAutocomplete]="auto"
		[formControl]="optionCtrl"
		[placeholder]="placeholder"
		[disabled]="disabled"
		[matChipInputFor]="chipList"
		[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
		(matChipInputTokenEnd)="add($event)"
	/>
	<i
		class="fa-regular fa-xmark remove"
		(click)="clearSelection()"
		*ngIf="!disabled"
	></i>
</mat-chip-list>
<mat-autocomplete
	#auto="matAutocomplete"
	class="autocomplete-chips"
	(optionSelected)="selected($event)"
>
	<mat-option
		*ngFor="let option of filteredOptions"
		[value]="option.id"
		[disabled]="option.disabled"
	>
		<span class="option-label">{{ option.label }}</span>
		<small *ngIf="option.subtext">{{ option.subtext }}</small>
	</mat-option>
</mat-autocomplete>
<mat-error *ngIf="showError">{{
	error || ("global.field-required" | transloco)
}}</mat-error>
