<div class="content-wrapper view">
	<div class="container-fluid">
		<!-- ------------------------- Navbar Module ------------------------- -->
		<nav class="ga-navbar navbar-default">
			<div class="navbar-content">
				<h3>
					<a (click)="backTo()">{{ 'main.menu.registrations' | transloco }}</a>
					<i class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"></i>
					<a (click)="backTo()">{{ 'certification.list.title' | transloco }}</a>
					<i class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"></i>
					<span *ngIf="!certificate.id" class="navbar-content__breadcrumb-page">{{
						'certification.details.new-certificate' | transloco
					}}</span>
					<span *ngIf="certificate.id" class="navbar-content__breadcrumb-page">{{ certificate.name }}</span>
				</h3>
			</div>
		</nav>

		<!-- ------------------------- Title description of page ------------------------- -->
		<div class="description">
			<div class="description__texts">
				<span class="description__texts__title">{{
					!certificate.id
						? ('certification.details.new-certificate' | transloco)
						: ('certification.details.edit-certificate' | transloco)
				}}</span>
				<span class="description__texts__subtitle">{{
					!certificate.id
						? ('certification.details.new-subtitle' | transloco)
						: ('certification.details.edit-subtitle' | transloco)
				}}</span>
			</div>
		</div>

		<!-- ------------------------- Form Module ------------------------- -->
		<form id="certificateForm" [formGroup]="certificateForm" class="flex-col" (ngSubmit)="onSubmit()" focusInvalidInput>
			<!-- ------------------------- Basic Data Module ------------------------- -->
			<div id="basicData" class="row col-lg-12 module-group">
				<h3 class="basic-data__title col-lg-12">
					{{ 'global.basic-info' | transloco }}
				</h3>
				<div class="row col-lg-8 p-uns">
					<!-- Organização -->
					<div class="form-group col-lg-6">
						<autocomplete-orgs
							[idSelected]="organization"
							(orgEmitter)="orgSelected($event)"
							[isRequired]="true"
							[isEnabled]="!certificate.id"
						></autocomplete-orgs>
					</div>

					<!-- Senha do certificado -->
					<div class="form-group col-lg-6">
						<ga-input
							[title]="'certification.details.document-password' | transloco"
							[placeholder]="!!certificate.id ? '******' : ('certification.details.password-placeholder' | transloco)"
							[type]="'password'"
							[disabled]="certificateForm.controls['certificatePassword'].disabled || !!certificate.id"
							[required]="true"
							[showError]="
								!certificateForm.controls['certificatePassword'].valid &&
								certificateForm.controls['certificatePassword'].touched
							"
							formControlName="certificatePassword"
							name="certificatePassword"
						></ga-input>
					</div>

					<!-- Usuario na CCEE -->
					<div class="form-group col-lg-6">
						<ga-input
							[title]="'certification.details.user-CCEE' | transloco"
							[placeholder]="'certification.details.user-placeholder' | transloco"
							[disabled]="certificateForm.controls['cceeUser'].disabled"
							[required]="true"
							[showError]="!certificateForm.controls['cceeUser'].valid && certificateForm.controls['cceeUser'].touched"
							formControlName="cceeUser"
							name="cceeUser"
						></ga-input>
					</div>

					<!-- Senha na CCEE -->
					<div class="form-group col-lg-6">
						<ga-input
							[title]="'certification.details.password-CCEE' | transloco"
							[placeholder]="'certification.details.password-placeholder' | transloco"
							[type]="'password'"
							[disabled]="certificateForm.controls['cceePassword'].disabled"
							[required]="true"
							[showError]="
								!certificateForm.controls['cceePassword'].valid && certificateForm.controls['cceePassword'].touched
							"
							formControlName="cceePassword"
							name="cceePassword"
						></ga-input>
					</div>
					<!-- Expiration notice -->
					<div class="form-checkbox col-lg-12">
						<ga-checkbox
							[title]="'certification.details.expiration-notice' | transloco"
							[isMultiple]="false"
							[disabled]="certificateForm.controls['expirationReport'].disabled"
							formControlName="expirationReport"
							name="expirationReport"
						></ga-checkbox>
					</div>
				</div>
				<!-- certificate -->
				<div class="form-group left-divider col-lg-4">
					<ga-upload
						[icon]="'upload'"
						[title]="'certification.details.document-attachment' | transloco"
						[iconType]="'default'"
						[iconSize]="18"
						[fileTypes]="fileTypes"
						[multiple]="false"
						[required]="true"
						[disabled]="certificateForm.controls['certificate'].disabled || !!certificate.id"
						[showError]="
							!certificateForm.controls['certificate'].valid && certificateForm.controls['certificate'].touched
						"
						[formError]="errorFields.includes('certificate')"
						[uploadedFilesURL]="certificate.name ? [certificate.name + '.pfx'] : []"
						formControlName="certificate"
						name="certificate"
						(ngModelChange)="clearFieldError('certificate')"
					></ga-upload>
				</div>

				<!-- ------------------------- button group ------------------------- -->
				<span class="separator form-group col-lg-12"></span>
				<div class="button-group">
					<div class="btn-toolbar" role="toolbar" style="text-align: center">
						<div class="btn-group" role="group">
							<button class="btn btn-default" (click)="backTo()">
								{{ 'global.cancel' | transloco }}
							</button>
						</div>
						<div class="btn-group" role="group">
							<button
								class="btn btn-primary btn-color-ga"
								type="submit"
								[disabled]="!certificateForm.valid || isSaving"
							>
								<span>
									{{ 'global.save' | transloco }}
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
