import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
	AbstractControl,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { setupDowngradeName } from 'webapp/hybrid-helpers/setupDowngradeName';
import { AuthService } from '../auth.service';

declare let $: JQueryStatic;

@Component({
	selector: 'app-forgot',
	templateUrl: './forgot.component.html',
	styleUrls: ['../login/login.component.scss'],
	host: {
		class: 'login',
	},
	encapsulation: ViewEncapsulation.None,
})
export class ForgotComponent implements OnInit {
	readonly currentDate = new Date();

	readonly splashPages = [
		'/assets/images/green_analitycs.png',
		'/assets/images/green_contratos.png',
	];
	readonly bgColors = ['#cccc99', '#7affbf'];
	splashPageURL: any;

	form = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email]),
	});
	user = {};
	message: { error: boolean; msg: string } | undefined = undefined;
	isSentEmail = false;

	constructor(
		@Inject(NG_ROUTER) private $state,
		private i18n: TranslocoService,
		private authService: AuthService,
		private sanitize: DomSanitizer
	) {}

	ngOnInit(): void {
		this.isSentEmail = false;
		const rand = Math.floor(Math.random() * this.splashPages.length);
		this.splashPageURL = this.sanitize.bypassSecurityTrustResourceUrl(
			this.splashPages[rand]
		);
		$('.embed-responsive.embed-responsive-image')
			.eq(0)
			.css('background-color', this.bgColors[rand]);
	}

	onSubmit({ valid, value }: AbstractControl) {
		if (!valid) {
			this.isSentEmail = true;
			this.message = {
				error: true,
				msg: this.i18n.translate('forgot.mandatory'),
			};
			return;
		}
		this.sendForgotEmail(value);
		this.form.reset();
	}

	goTo(state: string) {
		this.$state.transitionTo(state);
	}

	sendForgotEmail(user) {
		this.authService
			.forgot(user)
			.then(() => {
				this.isSentEmail = true;
				this.message = {
					error: false,
					msg: this.i18n.translate('forgot.ph-email-instructions'),
				};
			})
			.catch((error) => {
				this.isSentEmail = true;
				this.message = {
					error: true,
					msg: error.message || error.error,
				};
			});
	}
}

export const ng2ForgotComponent = {
	name: setupDowngradeName(ForgotComponent.name),
	def: downgradeComponent({ component: ForgotComponent }),
};
