import { Inject, Injectable } from '@angular/core';
import { RefTypes } from '../types/table.types';
import { Subject } from 'rxjs';
import { GADropdownCustom } from '../model/dropdown-custom.model';
import * as XLSX from 'xlsx';
import { ColumnType } from '../model/datatable.model';
import { TranslocoService } from '@ngneat/transloco';
import { convertToPlain } from '../utils/transform';
import { NotificationService } from '../../notification/notification.service';

const getFileName = (name?: string) => {
	const timeSpan = new Date().toISOString();
	const sheetName = name || 'ExportResult';
	const fileName = `${sheetName}-${timeSpan}`;
	return {
		sheetName: sheetName.slice(0, 31),
		fileName,
	};
};
@Injectable({
	providedIn: 'root',
})
export class GaDataTableNewService {
	private _actionSubject$: Subject<{ action; id }> = new Subject();
	$action = this._actionSubject$.asObservable();

	private _dropdownCustomSubject$: Subject<GADropdownCustom> = new Subject();
	$dropdownCustom = this._dropdownCustomSubject$.asObservable();

	public tableTypes: RefTypes = {
		text: {
			ref: 'textCellRef',
		},
		unit: {
			ref: 'unitCellRef',
		},
		valid: {
			ref: 'validCellRef',
		},
		custom: {
			ref: 'customCellRef',
		},
		action: {
			ref: 'actionCellRef',
		},
		active: {
			ref: 'activeCellRef',
		},
		selectLink: {
			ref: 'selectLinkCellRef',
		},
		select: {
			ref: 'selectCellRef',
		},
		dropdownCustom: {
			ref: 'dropdownCustomCellRef',
		},
		link: {
			ref: 'linkCellRef',
		},
		color: {
			ref: 'colorCellRef',
		},
	};

	constructor(
		@Inject(TranslocoService) private i18n,
		@Inject(NotificationService) private notificationService
	) {}

	onActionChanged({ action, id }) {
		this._actionSubject$.next({ action, id });
	}

	onDropdownChanged(item: GADropdownCustom) {
		this._dropdownCustomSubject$.next(item);
	}

	exportJsonToExcel(arr: any[], name?: string) {
		try {
			const { sheetName, fileName } = getFileName(name);
			const wb = XLSX.utils.book_new();
			let ws = XLSX.utils.json_to_sheet(arr);
			ws = this.setDynamicColumnWidths(arr, ws);
			XLSX.utils.book_append_sheet(wb, ws, sheetName);
			XLSX.writeFile(wb, `${fileName}.xlsx`);
			this.notificationService.success({
				msg: this.i18n.translate('global.branch.export-success'),
			});
		} catch (error) {
			this.notificationService.error({
				msg: this.i18n.translate('global.branch.export-error'),
			});
		}
	}

	setDynamicColumnWidths(arr: any[], ws: any) {
		const columnWidths: any[] = [];
		const keys = Object.keys(arr[0]).filter((key) => key !== '');
		keys.forEach((key) => {
			const maxLength = Math.max(
				key.length, // Consider the column title length as well
				...arr.map((item) => (item[key] || '').toString().length)
			);
			const paddingFactor = 1.2; //power factor to add some end space in the cell
			const columnWidth = Math.ceil(maxLength * paddingFactor);
			columnWidths.push({ wch: columnWidth });
		});
		if (arr.length > 0) ws['!cols'] = columnWidths;
		return ws;
	}

	prepareJsonToXlsx(columns: ColumnType[], rows: any[]) {
		const objs: any[] = [];
		rows.forEach((row, i) => {
			const obj: any = {};
			columns.forEach((col) => {
				obj[this.i18n.translate(col.label)] = this.changeExportCellByColumnType(
					col,
					row
				);
			});
			objs.push(obj);
		});
		return objs;
	}

	private changeExportCellByColumnType(col: ColumnType, row: any) {
		switch (col.type) {
		case 'custom':
			return convertToPlain(row[col.name]);
		case 'unit':
			if (row[col.name].unit) return convertToPlain(row[col.name].unit);
			return '-';
		case 'select':
		case 'selectLink':
			if (row[col.name].length == 1) return row[col.name][0].label;
			return row[col.name].length;
		case 'link':
			if (Array.isArray(row[col.name])) return row[col.name][0].label;
			return row[col.name].label;
		case 'active':
			return row[col.name].status
				? this.i18n.translate('data-table.active')
				: this.i18n.translate('data-table.inactive');
		case 'action':
			return '';
		case 'dropdownCustom':
			return row.active
				? this.i18n.translate('data-table.active')
				: this.i18n.translate('data-table.inactive');
		default:
			return row[col.name];
		}
	}

	sortByType(item: any, property: string, columns: ColumnType[]) {
		const column = columns.find((column) => column.name === property);
		if (!column) return item[property];

		return column.sortBy ? column.sortBy(item, property) : item[property];
	}
}
