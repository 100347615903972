import {
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { DataSourceService } from '../services/datasource.service';
import { GroupService } from '../services/group.service';
import { MeterService } from '../services/meter.service';
import { Meter } from './meter.model';
import * as _ from 'lodash';
import { MixPanelService } from '../services/mixpanel.service';

declare let $: JQueryStatic;

@Component({
	selector: 'ng2-source-selector',
	templateUrl: './source-selector.component.html',
	styleUrls: ['./source-selector.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SourceSelectorComponent implements OnInit {
	@Output() changeListener = new EventEmitter();
	@Input() params: any | undefined;
	@Input() selectFirstElement: any | undefined;
	@Output() update = new EventEmitter();

	showOrganizationTitle;

	selectedSource;
	meter: Meter = {} as any;
	selectedSourceType;
	sources: any = {};
	groups;
	showMeters;
	filteredBroupsByBranch;
	selectedMeter;
	selectedGroup;

	filterMeter = new FormControl();
	filteredItems: any[] = [];

	constructor(
		@Inject(NG_ROUTER) private $state,
		private elr: ElementRef,
		private DataSourceService: DataSourceService,
		private MeterService: MeterService,
		private GroupService: GroupService,
		private mixpanel: MixPanelService
	) {}

	ngOnInit(): void {
		this.initSources();
		this.loadSources();
	}

	initSources() {
		this.sources = {
			meter: 'meter',
			group: 'group',
		};

		this.selectFirstElement = this.selectFirstElement || true;

		this.meter = {
			items: [],
			contracts: [],
			selected: null,
			queueCBs: [],
			current: {
				inspectionDay: null,
			},
			realtimeChartOptions: {
				tickAmount: 3,
				showYTicks: false,
				serieColor: 'var(--primary-color)',
				energyStyle: {
					'font-size': '15px',
					color: 'var(--secondary-color)',
				},
				chart: {
					type: 'area',
				},
			},
		};

		this.groups = {
			items: [],
			selectedGroup: {},
		};
		this.showMeters = true;
		this.filteredBroupsByBranch = [];

		$(this.elr.nativeElement)
			.find('.dropdown-menu-item')
			.on('click', (e) => {
				//prevent meter dropdownd from closing
				$(this.elr.nativeElement)
					.find('.dropdown-menu-item')
					.each(() => {
						$(this).removeClass('active');
					});
				$(this).addClass('active');

				e.stopPropagation();
			});
	}

	//Load meters
	loadSources() {
		this.DataSourceService.getDataSources().then((values) => {
			this.meter.items = values;
			this.showOrganizationTitle = true;
			this.filteredItems = this.meter.items;
			this.filterMeter.valueChanges.subscribe((value) => {
				if (value && value != '') {
					const filteredName = this.filterMeterBy(value, 'label');
					const filteredOrg = this.filterMeterBy(value, 'organizationName');
					const filteredUID = this.filterMeterBy(value, 'uid');
					const filteredID = this.filterMeterBy(value, 'id');
					this.filteredItems = _.sortBy(
						_.union(filteredName, filteredOrg, filteredUID, filteredID),
						'organizationName'
					);
				} else {
					this.filteredItems = this.meter.items;
				}
			});
			if (this.meter?.items?.length == 0) {
				if (this.changeListener.observers.length) {
					this.changeListener.emit({
						source: null,
					});
				}
			} else {
				let countOrgs = 1;
				let tempOrgId;
				if (this.meter?.items) {
					tempOrgId = this.meter?.items[0]?.organizationId;
					this.meter?.items.forEach((ds) => {
						if (ds.organizationId !== tempOrgId) {
							countOrgs++;
						}
					});
					this.showOrganizationTitle = countOrgs > 1;
				}
			}

			const url = this.$state.current.url;
			const sourceType = this.params ? this.params.sourceType : '';

			if (url.match(/^\/group.*/g) || this.sources.group === sourceType) {
				this.selectedSourceType = this.sources.group;
			} else if (
				url.match(/^\/meter.*/g) ||
				this.sources.meter === sourceType
			) {
				this.selectedSourceType = this.sources.meter;
			} else {
				this.selectedSourceType = this.sources.meter;
			}

			//Sets a default meter
			if (this.meter?.items?.length > 0) {
				const bySourceType = (dataSource) =>
					dataSource.type == this.selectedSourceType;
				if (this.selectedSourceType == this.sources.meter) {
					let selectedMeter: any = null;
					const paramUID = this.params?.uid;
					if (paramUID) {
						const foundMeter = this.meter.items.find(
							(meter) => meter.uid == paramUID || meter.id == paramUID
						);
						selectedMeter = paramUID ? foundMeter : null;
					}

					//if the route doesnt fit any meters at the list the first meter will be selected

					if (selectedMeter == null && this.selectFirstElement == true) {
						selectedMeter = this.meter.items.find(bySourceType);
					}
					this.selectMeter(selectedMeter);
				} else if (this.selectedSourceType == this.sources.group) {
					let selectedGroup: any = null;
					if (this.params) {
						const paramGroupId = this.params.id;
						selectedGroup = this.meter.items.find(
							(dataSource) => dataSource.id == paramGroupId
						);
					}

					if (!selectedGroup) {
						selectedGroup = this.meter.items.find(bySourceType);
					}

					//Set Selected Group
					if (selectedGroup) {
						this.selectGroup(selectedGroup);
					}
				}
			}

			$('.scrollbox').each(function () {
				if ($(this).hasClass('scrollbox-active') == false) {
					(<any>$(this)).enscroll({
						showOnHover: true,
						verticalTrackClass: 'track3',
						verticalHandleClass: 'handle3',
					});

					$(this).addClass('scrollbox-active');
				}
			});
		});
	}

	mixPanelEvent(item) {
		const eventName = 'search_header';
		const objectLog = {
			itemId: item.id,
			itemName: item.label,
			itemOrganizationName: item.organizationName,
			itemOrganizationId: item.organizationId,
			itemType: item.type,
		};
		this.mixpanel.mixPanelEvent({
			type: eventName,
			object: objectLog,
		});
	}

	selectMeter(meter) {
		if (meter) {
			this.filterMeter.setValue('');
			if (
				this.meter.selected == undefined ||
				this.meter.selected.id != meter.id
			) {
				this.meter.selected = meter;
				this.groups.selectedGroup = null;

				if (meter.contracts && meter.contracts.length > 0) {
					this.setSource(meter, this.sources.meter);
				} else {
					this.MeterService.getMeter({ meterId: meter.id })
						.then((completeMeter) => {
							Object.assign(meter, completeMeter);
							return this.MeterService.getContracts(meter.id);
						})
						.then((contracts) => {
							meter.contracts = contracts;
							if (contracts && contracts.length > 0) {
								meter.inspectionDay = meter.contracts[0].inspectionDay;
							} else {
								meter.inspectionDay = 0;
							}
							this.setSource(meter, this.sources.meter);
						});
				}
			}
		}
	}

	selectGroup(group) {
		if (group) {
			this.filterMeter.setValue('');
			if (
				this.groups.selectedGroup == undefined ||
				this.groups.selectedGroup.id != group.id
			) {
				this.meter.selected = null;
				this.groups.selectedGroup = group;

				if (group.contracts && group.contracts.length > 0) {
					this.setSource(group, this.sources.group);
				} else {
					this.GroupService.getGroup({ groupId: group.id })
						.then((completeGroup) => {
							Object.assign(group, completeGroup);
							return this.GroupService.getContracts(group.id);
						})
						.then((contracts) => {
							group.contracts = contracts;
							if (contracts && contracts.length > 0) {
								group.inspectionDay = group.contracts[0].inspectionDay;
							} else {
								group.inspectionDay = 0;
							}
							this.setSource(group, this.sources.group);
						});
				}
			}
		}
	}

	setSource(selectedSource, selectedSourceType) {
		this.selectedSourceType = selectedSourceType;
		this.selectedSource = selectedSource;
		if (this.changeListener.observers.length) {
			const source = {
				selectedSource: this.selectedSource,
				selectedSourceType: this.selectedSourceType,
			};
			this.changeListener.emit({
				source: source,
			});
		}
	}

	filterMeterBy(value, property) {
		return this.meter.items.filter((meter) =>
			property != 'id'
				? meter[property]?.toLowerCase().trim().includes(value.toLowerCase())
				: meter[property]
					?.toFixed(0)
					.toLowerCase()
					.trim()
					.includes(value.toLowerCase())
		);
	}
}

export const ng2SourceSelector = {
	name: 'ng2sourceSelector',
	def: downgradeComponent({ component: SourceSelectorComponent }),
};
