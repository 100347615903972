<div class="datepicker-content" (clickOutside)="clickedOutside()">
	<label *ngIf="title">
		<span>{{ title }}</span>
		<span *ngIf="required" class="required">*</span>
		<ga-helper
			*ngIf="tooltip"
			[text]="tooltip"
			[icon]="'fa-solid fa-circle-info'"
			[iconSize]="12"
			[iconColor]="'#A3A3A3'"
			[tooltipLocale]="'above'"
		></ga-helper>
	</label>
	<div
		class="datePicker items-center"
		[ngClass]="{
			'ga-input-error': !isOpen && showError,
			disabled: disabled
		}"
	>
		<mat-datepicker-toggle matSuffix [tabIndex]="-1" (click)="toggleCalendar(); onTouch()"></mat-datepicker-toggle>
		<input
			matInput
			class="form-control"
			[placeholder]="placeholder"
			[formControl]="formattedDate"
			[disabled]="disabled"
			[ngClass]="{
				'has-error': !isOpen && showError
			}"
			(blur)="onTouch()"
		/>
	</div>
	<mat-error *ngIf="!isOpen && showError">{{ error || ('global.field-required' | transloco) }}</mat-error>

	<div *ngIf="isOpen" class="dropdown" id="ga-date-dropdown" [ngClass]="position">
		<div *ngIf="showOptions" class="options">
			<button
				type="button"
				*ngFor="let option of dateOptions"
				(click)="changePeriod(option.value)"
				[ngClass]="{ period: currentPeriod.value === option.value }"
			>
				{{ option.label | transloco }}
			</button>
		</div>

		<div class="calendars" [ngSwitch]="currentPeriod.value">
			<div *ngSwitchCase="'day'">
				<mat-calendar
					class="calendar-container"
					[(selected)]="selectedDate"
					[startAt]="fitCalendar?.start"
					(selectedChange)="setFitCalendar($event)"
				></mat-calendar>
				<div class="time-container" *ngIf="showTime">
					<ngx-mat-timepicker
						[(ngModel)]="timeControlOne"
						[stepHour]="stepHour"
						[stepMinute]="stepMinute"
						[showSeconds]="showSeconds"
					></ngx-mat-timepicker>
				</div>
			</div>
			<mat-calendar
				*ngSwitchCase="'week'"
				class="calendar-container"
				[selected]="selectedWeek"
				[startAt]="fitCalendar?.start"
				(selectedChange)="selectedWeekChange($event); setFitCalendar($event)"
			></mat-calendar>
			<mat-calendar
				*ngSwitchCase="'month'"
				class="calendar-container"
				startView="multi-year"
				[selected]="selectedMonth"
				#monthPicker
				[startAt]="fitCalendar?.start"
				(monthSelected)="setMonthAndYear($event, monthPicker, selectedMonth); setFitCalendar($event)"
				(viewChanged)="viewChangedHandler($event, monthPicker, 'year')"
			></mat-calendar>
			<mat-calendar
				*ngSwitchCase="'year'"
				class="calendar-container"
				[selected]="selectedYear"
				[startAt]="fitCalendar?.start"
				#yearPicker
				startView="multi-year"
				(viewChanged)="viewChangedHandler($event, yearPicker, 'multi-year')"
				(yearSelected)="setYear($event, yearPicker); setFitCalendar($event)"
			></mat-calendar>
			<mat-calendar
				*ngSwitchCase="'invoice'"
				class="calendar-container"
				[selected]="selectedInvoice"
				[startAt]="fitCalendar?.start"
				(selectedChange)="setFitCalendar($event)"
				#invoicePicker
				startView="multi-year"
				(monthSelected)="setMonthAndYear($event, invoicePicker, selectedInvoice)"
				(viewChanged)="viewChangedHandler($event, invoicePicker, 'year')"
			></mat-calendar>
			<div class="custom-calendar" *ngSwitchCase="'custom'">
				<div>
					<mat-calendar
						class="calendar-container"
						[selected]="selectedCustom"
						[startAt]="selectedCustom?.end == fitCalendar?.start ? selectedCustom?.start : fitCalendar?.start"
						(selectedChange)="selectedCustomChange($event); setFitCalendar($event)"
					></mat-calendar>
					<div class="time-container" *ngIf="showTime">
						<ngx-mat-timepicker
							[(ngModel)]="timeControlOne"
							[stepHour]="stepHour"
							[stepMinute]="stepMinute"
							[showSeconds]="showSeconds"
						></ngx-mat-timepicker>
					</div>
				</div>
				<div>
					<mat-calendar
						class="calendar-container"
						[selected]="selectedCustom"
						[startAt]="
							(selectedCustom?.end == fitCalendar?.start ? selectedCustom?.end : fitCalendar?.end) ||
							secondCalendarStart
						"
						(selectedChange)="selectedCustomChange($event); setFitCalendar($event)"
					></mat-calendar>
					<div class="time-container" *ngIf="showTime">
						<ngx-mat-timepicker
							[(ngModel)]="timeControlTwo"
							[stepHour]="stepHour"
							[stepMinute]="stepMinute"
							[showSeconds]="showSeconds"
						></ngx-mat-timepicker>
					</div>
				</div>
			</div>

			<div class="buttons">
				<button *ngIf="!hideClearButton" class="cancel-button" type="button" (click)="clear()">
					{{ 'global.clear' | transloco }}
				</button>
				<button class="apply-button" type="button" (click)="apply()" [ngClass]="{ 'full-size-apply': hideClearButton }">
					{{ 'global.apply' | transloco }}
				</button>
			</div>
		</div>
	</div>
</div>
