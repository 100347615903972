import {
	AfterContentInit,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { TranslocoService } from '@ngneat/transloco';
import { MultiSelectOption } from './multi-selector.model';

import { MultiSelectorService } from './multi-selector.service';

const ONLYOPT = 0;
@Component({
	selector: 'ga-multi-selector',
	templateUrl: './multi-selector.component.html',
	styleUrls: ['./multi-selector.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MultiSelectorComponent implements AfterContentInit {
	@Input() title: string | undefined;
	@Input() placeholder: string | undefined;
	@Input() limit = 0;
	@Input() showSelectAllOption = false;
	@Input() marked: number[] = [];
	@Input() optionsList: MultiSelectOption[] = [];
	@Input() bindLabel = false;
	@Input() showFilterField = false;
	@Input() customClass = '';
	@Input() errorMessage: string | undefined;
	@Input() tooltip!: string;

	filteringString = '';
	@ViewChild('select') select: MatSelect = {} as MatSelect;

	@Output() selectEmitter: EventEmitter<any> = new EventEmitter();

	isOnlyOption = false;
	disabledOptions = false;
	foundOnlyOption: MultiSelectOption | undefined;
	togglAllSelected = false;
	matOptionsList: MatOption[] = [];
	filteredList: MultiSelectOption[] = [];
	isonlyOptionSelected = false;

	constructor(
		private multiSelectorService: MultiSelectorService,
		private transloco: TranslocoService
	) {}

	ngAfterContentInit(): void {
		this.multiSelectorService.list = this.optionsList;
		this.optionsList = this.multiSelectorService.listWithoutOnlyOption();
		this.filteredList = this.optionsList.slice();

		this.multiSelectorService.limit = this.limit;

		this.multiSelectorService.permissionsToUseMultiSelector();
		this.loadOnlyOptionIfExists();
	}

	private loadOnlyOptionIfExists(): void {
		this.foundOnlyOption = this.multiSelectorService.onlyOptionObject;
		this.isOnlyOption = this.multiSelectorService.isOnlyOptionExists(
			this.foundOnlyOption
		);
	}

	findSelectedLabel(id) {
		return this.bindLabel
			? this.optionsList.find((o) => o.id == id)?.label.replace(/<.*?>/g, '')
			: this.optionsList.find((o) => o.id == id)?.label;
	}

	onlyOptionSelected(matOnlyOption: MatOption) {
		this.matOptionsList = this.multiSelectorService.listCheckedOptions(
			this.matOptionsList,
			matOnlyOption,
			<MultiSelectOption>this.multiSelectorService.onlyOptionObject
		);
		const filterOptions = this.select.options.filter(
			(opt) => opt.value !== matOnlyOption.value
		);

		filterOptions.forEach((item: MatOption) => {
			item.deselect();
			if (matOnlyOption.selected) {
				item.disabled = true;
				this.isonlyOptionSelected = true;
			} else {
				item.disabled = false;
				this.isonlyOptionSelected = false;
			}
		});
	}

	optionSelected(matOptionEvent: MatOption, option: MultiSelectOption) {
		this.matOptionsList = this.multiSelectorService.listCheckedOptions(
			this.matOptionsList,
			matOptionEvent,
			option
		);
		const onlyOptObj = this.multiSelectorService.onlyOptionObject;
		const foundMatOnlyOption = this.matOptionsList.find(
			(opt) => +opt.value === onlyOptObj?.id
		);

		this.limitedSelections();
		let newStatus = true;
		const list = this.select.options.filter(
			(item: MatOption) => item.value !== this.foundOnlyOption?.id
		);
		list.forEach((item) => {
			if (foundMatOnlyOption?.selected) {
				item.disabled = true;
			}
			if (!item.selected) {
				newStatus = false;
			}
		});
		this.togglAllSelected = newStatus;
	}

	toggleAllSelection() {
		switch (true) {
		case this.togglAllSelected && !!this.filteredList.length:
			this.selectAllFiltered();
			break;
		case this.togglAllSelected && !this.filteredList.length:
			this.selectAllOptions();
			break;
		case !this.togglAllSelected && !!this.filteredList.length:
			this.deselectAllFiltered();
			break;
		case !this.togglAllSelected && !this.filteredList.length:
			this.deselectAllOptions();
			break;
		}
	}

	private selectAllOptions(): void {
		this.select.options.forEach((item: MatOption) => {
			if (this.foundOnlyOption?.id === item.value) {
				item.deselect();
			} else {
				this.togglAllSelected = true;
				item.select();
			}
		});
	}

	private selectAllFiltered(): void {
		this.select.options.forEach((item: MatOption) => {
			if (this.filteredList.some((opt) => opt.id === item.value)) item.select();
		});
	}

	private deselectAllOptions(): void {
		this.select.options.forEach((item: MatOption) => {
			item.disabled = false;
			item.deselect();
		});
	}

	private deselectAllFiltered(): void {
		this.select.options.forEach((item: MatOption) => {
			if (this.filteredList.some((opt) => opt.id === item.value))
				item.deselect();
		});
	}

	private limitedSelections() {
		if (this.limit > 0) {
			if (this.matOptionsList.length >= this.limit) {
				this.select.options.forEach((item: MatOption) => {
					if (!item.selected) item.disabled = true;
				});
			} else {
				this.select.options.forEach((item: MatOption) => {
					item.disabled = false;
				});
			}
		}
	}

	changeOption() {
		const onlyOptSelected = this.marked.find((option) => option === ONLYOPT);
		if (this.isOnlyOption && onlyOptSelected === ONLYOPT) {
			this.selectEmitter.emit([this.foundOnlyOption]);
			return;
		}
		const values = this.optionsList.filter((option) => {
			return this.marked.includes(option.id);
		});

		this.selectEmitter.emit(values);
	}

	filter(str) {
		const instalNumber = this.transloco.translate('install-number');
		this.filteredList = this.optionsList.filter((opt) =>
			opt.label
				.replace(instalNumber, '')
				.toLowerCase()
				.includes(str.toLowerCase())
		);
	}

	shownOption(option) {
		return (
			this.filteringString != '' &&
			!this.filteredList.find((op) => op.id == option.id)
		);
	}

	clearFilter() {
		this.filteringString = '';
	}
}
