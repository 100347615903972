import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormControl } from '@angular/forms';
import { TimePickerService } from '../service/time-picker.service';
import { InputTime } from '../models/input-time.model';

@Component({
	selector: 'ga-input-time',
	templateUrl: './ga-input-time.component.html',
	styleUrls: ['../time-picker.component.scss'],
})
export class GaInputTimeComponent {
	@Output() changes: EventEmitter<InputTime> = new EventEmitter();

	@Input() haveSeconds = true;
	@Input() set time(time: string) {
		if (typeof time != 'string' || time == '') {
			this.clearTime();
			return;
		}

		this.hourCtrl.setValue(time.split(':')[0]);
		this.minuteCtrl.setValue(time.split(':')[1]);
		this.secondCtrl.setValue(time.split(':')[2] || '');
	}

	hourCtrl = new FormControl('00');
	minuteCtrl = new FormControl('00');
	secondCtrl = new FormControl('00');

	constructor(private service: TimePickerService) {}

	public up(ctrlType: string) {
		ctrlType == 'hourCtrl'
			? this[ctrlType].setValue(this.service.upHour(+this[ctrlType].value))
			: this[ctrlType].setValue(
				this.service.upMinuteAndSecond(+this[ctrlType].value)
			  );

		this.emitChanges();
	}

	public down(ctrlType: string): void {
		this[ctrlType].setValue(this.service.goDown(+this[ctrlType].value));
		this.emitChanges();
	}

	public changeTime(ctrlType: string): void {
		const vl = +this[ctrlType].value;
		const formatVl =
			ctrlType === 'hourCtrl'
				? this.service.changeHour(vl)
				: this.service.changeMinAndSec(vl);
		this[ctrlType].setValue(formatVl);
		this.emitChanges();
	}

	clearTime() {
		this.hourCtrl = new FormControl('00');
		this.minuteCtrl = new FormControl('00');
		this.secondCtrl = new FormControl('00');
	}

	private emitChanges() {
		this.changes.emit({
			hour: this.hourCtrl.value,
			minutes: this.minuteCtrl.value,
			seconds: this.secondCtrl.value,
		});
	}
}
