import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormFieldBaseDirective } from '../directive/form-field-base';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
	selector: 'ga-input',
	templateUrl: './ga-input.component.html',
	styleUrls: ['./ga-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GaInputComponent),
			multi: true,
		},
	],
})
export class GaInputComponent extends FormFieldBaseDirective {
	@Input() type = 'text';
	@Input() rAddon = '';
	@Input() lAddon = '';
	@Input() lText = '';
	@Input() maxLength: number | undefined;
	@Input() minLength: number | undefined;
	@Input() min = 0;
	@Input() max: number | undefined;
	@Input() step: number | undefined;
	@Input() mask = '';
	@Input() dropSpecialCharacters = true;
	@Input() tooltipPosition: TooltipPosition = 'above';

	hidePassword = true;

	constructor() {
		super();
	}

	get inputType() {
		if (this.type === 'password' && !this.hidePassword) return 'text';
		return this.type;
	}

	changePasswordVisibility() {
		this.hidePassword = !this.hidePassword;
	}
}
