<div class="content">
	<div class="description">
		<div class="description__texts">
			<span class="description__texts__title">{{ 'global.measurement-points' | transloco }}</span>
			<span class="description__texts__subtitle">{{ 'global.measurement-points-sub' | transloco }}</span>
		</div>

		<div class="btn-toolbar description__action-buttons" role="toolbar" *wchHasFeature="'dataSourceCreate'">
			<button
				class="btn btn-group btn-default btn-default-ga mr-4 new-btn"
				type="button"
				(click)="openDialog({ type: 'measurements-import' })"
			>
				<i class="fa-regular fa-cloud-arrow-up"></i>
				{{ 'import.measurements.upload' | transloco }}
			</button>
			<ga-button-dropdown
				[label]="'main.measuring-points.new-point-label' | transloco"
				[icon]="'fa-circle-bolt'"
				[actionOptions]="actionButtons"
				(action)="newAction($event)"
			></ga-button-dropdown>
		</div>
	</div>

	<div class="row col-lg-12 m-0 p-0">
		<autocomplete-orgs [idSelected]="organizationId" (orgEmitter)="orgSelected($event)"></autocomplete-orgs>
	</div>
	<div class="row col-lg-12 m-0 p-0 dataTable">
		<div *ngIf="!organizationId" class="no-organization">
			<ga-tag-icon [icon]="'error'" [size]="22"></ga-tag-icon>
			<span class="message">
				{{ 'meter-page.no-organization' | transloco }}
			</span>
		</div>
		<ga-datatable-new
			[ngClass]="{ hidden: !organizationId }"
			[tableName]="'MeasuringPoints'"
			[element$]="displayEl$"
			(onAction)="openDialog($event)"
			(renderDataChange)="getDataSourceUsers($event)"
			(rowExpand)="onRowExpand($event)"
		>
			<ng-container emptyTitle>
				<h3 class="empty-table">
					{{ 'meter-page.no-content' | transloco }}
				</h3>
			</ng-container>
			<ng-container emptySubtitle>
				<h4 class="empty-table__tips">
					<span
						>{{ 'meter-page.no-content-sub1' | transloco }}
						<a (click)="newAction({ actionName: 'new-measuring-point' })">{{
							'meter-page.no-content-sub2' | transloco
						}}</a></span
					>
					<span
						>{{ 'meter-page.no-content-sub3' | transloco }}
						<a (click)="newAction({ actionName: 'import' })">{{ 'meter-page.no-content-sub4' | transloco }}</a></span
					>
				</h4>
			</ng-container>
		</ga-datatable-new>
	</div>
</div>

<ng-template #activeTemplate>
	<div class="form-group">
		<ga-date-range-picker
			[formControl]="dataMedicao"
			[showTime]="true"
			[showOptions]="false"
			[error]="'main.comparison.invalid-msg' | transloco"
			[showError]="!dataMedicao.valid && dataMedicao.touched"
			[position]="'dialog'"
			[placeholder]="'00/00/00 - 00:00h'"
			[hideClearButton]="true"
		>
		</ga-date-range-picker>
	</div>
</ng-template>

<ng-template #changeTemplate>
	<div class="form-group">
		<ga-input
			[title]="'meter-page.label.meter-code' | transloco"
			[placeholder]="'meter-page.placeholder.uid' | transloco"
			[formControl]="codigoMedidor"
		></ga-input>
	</div>
	<div class="form-group">
		<ga-datepicker
			[title]="'meter-page.label.start-meter-date' | transloco"
			[formControl]="dataMedicao"
			[showTime]="true"
			[showSeconds]="false"
		>
		</ga-datepicker>
	</div>
</ng-template>

<ng-template #importMeasurementTemplate let-data>
	<measurements-import
		(changeContent)="changeModalContent($event)"
		(loading)="setModalLoading($event)"
		(emitClose)="closeDialog()"
		[organization]="organizationId"
	></measurements-import>
</ng-template>
