import * as Highcharts from 'highcharts';
import { maxStringSize } from 'webapp/app/utils/transformUtil';
import { ChartObj } from './manual-project.service';

const prefix = 'R$ ';
const sufix = ' kWh';

function formatter(this: any, chartValues: ChartObj[]) {
	let tooltip = '';
	let content = '';
	const header = `<p class="tooltipHeader">
                    <span >Mês:</span> ${this.key}
                  </p>
                  <hr class="division-header">`;
	if (chartValues.length === 1) {
		content = `
          <p class="tooltipTittle">
            ${
	chartValues[0]?.installNumber
		? 'Instalação: ' + chartValues[0]?.installNumber
		: 'Todas as instalações'
}
          </p>
    `;
	} else if (chartValues.length > 1) {
		content = `
          <p class="tooltipTittle">
            Instalação: ${this.series.name.split(':')[1]}
          </p>
        `;
	}
	content += `
          <p class="tooltipSerie">
            ${this.series.name.split(':')[0]} :
            ${
	chartValues[0].monetary === 'value'
		? prefix + Highcharts.numberFormat(this.y, 2)
		: this.y + sufix
}
          </p>`;
	tooltip = header + content;
	return tooltip;
}

function commonOptions(categories, chartValues): Highcharts.Options {
	return {
		chart: {
			width: 700,
			style: {
				textAlign: 'center',
			},
		},
		title: { text: '' },
		credits: { enabled: false },
		yAxis: [
			{
				min: 0,
				title: {
					text: 'Consumo total / Economia',
				},
			},
		],
		plotOptions: {
			line: {
				marker: { enabled: false },
			},
			arearange: {
				marker: {
					enabled: false,
				},
			},
		},
		xAxis: [
			{
				categories,
				crosshair: true,
			},
		],
		tooltip: {
			formatter: function () {
				return formatter.call(this, chartValues);
			},
			useHTML: true,
			style: {
				color: '#666666',
				width: 200,
			},
			valueDecimals: 3,
			backgroundColor: '#fff',
			borderColor: '#eee',
			borderRadius: 5,
			borderWidth: 1,
		},
		legend: {
			align: 'center',
			verticalAlign: 'bottom',
			squareSymbol: true,
			padding: 10,
			symbolWidth: 20,
			layout: 'horizontal',
		},
	};
}

export function chartOptions(
	categories: string[],
	chartValues: ChartObj
): Highcharts.Options {
	const options: Highcharts.Options = {
		series: [
			{
				type: 'line',
				name: 'Linha de base',
				data: chartValues.baseline,
				color: '#669966',
				stickyTracking: false,
			},
			{
				type: 'line',
				name: 'Consumo realizado',
				data: chartValues.consumptions,
				color: Highcharts.color('var(--primary-color)').get(),
				dashStyle: 'ShortDash',
				borderWidth: 2,
				stickyTracking: false,
			},
			{
				type: 'arearange',
				name: 'Economia',
				color: 'transparent',
				fillColor: 'rgb(102, 153, 102, 0.2)',
				data: chartValues.area,
				stickyTracking: false,
				borderWidth: 0,
				zIndex: -10000,
			},
		],
		...commonOptions(categories, [chartValues]),
	};
	return options;
}

export function compareChartOptions(
	categories: string[],
	chartValues1: ChartObj,
	chartValues2: ChartObj
) {
	const options: Highcharts.Options = {
		series: [
			{
				type: 'line',
				name: 'Linha base: ' + maxStringSize(chartValues1.installNumber),
				data: chartValues1.baseline,
				color: '#669966',
				legendIndex: 0,
				stickyTracking: false,
			},
			{
				type: 'line',
				name:
					'Consumo total realizado: ' +
					maxStringSize(chartValues1.installNumber),
				data: chartValues1.consumptions,
				color: Highcharts.color('var(--primary-color)').get(),
				dashStyle: 'ShortDash',
				legendIndex: 2,
				stickyTracking: false,
			},
			{
				type: 'arearange',
				name: 'Economia: ' + maxStringSize(chartValues1.installNumber),
				color: 'transparent',
				fillColor: 'rgb(102, 153, 102, 0.2)',
				data: chartValues1.area,
				legendIndex: 4,
				borderWidth: 0,
				zIndex: -1000,
			},
			{
				type: 'line',
				name: 'Linha base:' + maxStringSize(chartValues2.installNumber),
				data: chartValues2.baseline,
				color: '#0066CC',
				legendIndex: 1,
				stickyTracking: false,
			},
			{
				type: 'line',
				name:
					'Consumo total realizado: ' +
					maxStringSize(chartValues2.installNumber),
				data: chartValues2.consumptions,
				color: '#0066CC',
				dashStyle: 'ShortDash',
				legendIndex: 3,
				stickyTracking: false,
			},
			{
				type: 'arearange',
				name: 'Economia: ' + maxStringSize(chartValues2.installNumber),
				color: 'transparent',
				fillColor: 'rgba(0, 102, 204, 0.2)',
				data: chartValues2.area,
				legendIndex: 5,
				borderWidth: 0,
				zIndex: -1000,
			},
		],
		...commonOptions(categories, [chartValues1, chartValues2]),
	};
	return options;
}
