import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { catchError } from 'rxjs/operators';
import {
	ConfirmDialogComponent,
	ConfirmDialogModel,
} from 'webapp/app/shared/confirm-dialog/confirm-dialog.component';
import { LoaderService } from 'webapp/app/shared/global-loading/loader.service';
import { EconomyProjectService } from 'webapp/app/shared/services/economy-projects.service';
import { EconomyProject } from '../project.model';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';

@Component({
	selector: 'app-economy-project-reports',
	templateUrl: './economy-project-reports.component.html',
	styleUrls: ['./economy-project-reports.component.scss'],
})
export class EconomyProjectReportsComponent {
	files: any[] = [];
	uploadScreen = false;
	@ViewChild('filesDropRef') fileInput!: ElementRef;

	constructor(
		public dialogRef: MatDialogRef<EconomyProjectReportsComponent>,
		private notification: NotificationService,
		private loader: LoaderService,
		@Inject(MAT_DIALOG_DATA) public data: EconomyProject,
		public dialog: MatDialog,
		private i18n: TranslocoService,
		private economyProjectService: EconomyProjectService
	) {
		this.loader.isLoading.next(true);
		this.economyProjectService.getProjectFiles(this.data.id).subscribe(
			(files) => {
				this.files = files.map((f) => {
					return { name: f.fileName, ...f };
				});
				this.loader.isLoading.next(false);
			},
			(err) => {
				this.notification.showErrorMessages(err);
			}
		);
	}

	deleteFile(index) {
		const confirmDialogModel: ConfirmDialogModel = {} as ConfirmDialogModel;
		const message = this.i18n.translate('projects.reports.remove-file');
		confirmDialogModel.message = message;
		confirmDialogModel.title = this.i18n.translate('category.modal-rm.header');
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: confirmDialogModel,
		});
		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult) {
				const fileToDelete = this.files.splice(index, 1);
				this.economyProjectService
					.deleteFile(this.data.id, fileToDelete[0].id)
					.subscribe(
						(resp) => {
							this.notification.success({
								msg: this.i18n.translate(
									'projects.reports.remove-file-success'
								),
							});
						},
						(err) => {
							this.notification.showErrorMessages(err);
						}
					);
			}
		});
	}

	onFilesDropped(files: FileList) {
		this.loader.isLoading.next(true);
		const inputEl: HTMLInputElement = this.fileInput?.nativeElement;
		const curFiles = inputEl?.files?.length ? inputEl?.files : files;
		if (curFiles) {
			// a file was selected
			const files = Array.from(curFiles);
			this.economyProjectService
				.uploadProjectFileReport(this.data.id, files)
				.subscribe(
					(res) => {
						const f = files.map((f) => {
							return { fileName: f.name, type: f.type };
						});
						return (
							this.economyProjectService
								.createFileInstance(this.data.id, f)
								.subscribe((res) => {
									this.uploadScreen = false;
									this.files = this.files.concat(
										res.map(({ value }) => {
											return {
												id: value.id,
												name: value.fileName,
												type: value.type,
											};
										})
									);
									this.loader.isLoading.next(false);
									return res;
								}),
							catchError((err) => {
								return err;
							})
						);
					},
					(err) => {
						this.notification.showErrorMessages(err);
					}
				);
		}
	}

	fileBrowseHandler(event: Event) {
		const target = event.target as HTMLInputElement;
		const files = target.files as FileList;
		this.onFilesDropped(files);
	}

	downloadFile(index) {
		this.economyProjectService
			.downloadFile(this.data?.id, this.files[index].name, this.files[index].id)
			.subscribe(
				(resp) => {
					// Hacks to download file
					const a = document.createElement('a');
					a.style.display = 'none';
					document.body.appendChild(a);
					a.href = resp.url;
					a.setAttribute('download', this.files[index].name);
					a.click();
					window.URL.revokeObjectURL(a.href);
					document.body.removeChild(a);
				},
				(err) => {
					this.notification.showErrorMessages(err);
				}
			);
	}
}
