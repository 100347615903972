import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { AuthService } from 'webapp/app/auth/auth.service';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { setupDowngradeName } from 'webapp/hybrid-helpers/setupDowngradeName';

@Component({
	selector: 'app-logout',
	template: '',
	encapsulation: ViewEncapsulation.None,
})
export class LogoutComponent implements OnInit {
	constructor(
		@Inject(NG_ROUTER) private $state,
		private AuthService: AuthService
	) {}

	ngOnInit() {
		this.AuthService.logout().then((value: any) => {
			if (value === true) {
				this.$state.transitionTo('main.dashboard');
			} else {
				const redirectUrl = this.AuthService.popRedirectUrlCookie();
				if (redirectUrl) {
					window.open(redirectUrl, '_self');
				} else {
					this.$state.go('login');
				}
			}
		});
	}
}

export const ng2LogoutComponent = {
	name: setupDowngradeName(LogoutComponent.name),
	def: downgradeComponent({ component: LogoutComponent }),
};
