<div class="content-meterlist">
	<h3 class="basic-data__title col-lg-12">
		{{ "import.measurement-point.import" | transloco }}
	</h3>

	<ga-loading *ngIf="loading"></ga-loading>

	<section
		*ngIf="
			response.arrExtractedDataError &&
			!response.arrExtractedDataError.length &&
			!this.loading
		"
		class="basic-data__subtitle col-lg-12 flex-col"
	>
		<span>
			{{ "import.meter-success-tip1" | transloco }}{{ response.data.length
			}}{{ "import.meter-success-tip2" | transloco }}
		</span>
		<span>
			<span class="bold">{{ "import.attention" | transloco }}: </span>
			{{ "import.meter-success-tip3" | transloco }}
		</span>
	</section>

	<section
		*ngIf="
			response.arrExtractedDataError && response.arrExtractedDataError.length
		"
		class="basic-data__subtitle col-lg-12 flex-col"
	>
		<span>
			{{ "import.meter-error-tip1" | transloco
			}}{{ extractNumberFromErrors(response.arrExtractedDataError)
			}}{{ "import.meter-error-tip2" | transloco }}
		</span>
		<span class="bold mt-24">{{ "import.fill-tips" | transloco }}: </span>
		<span *ngFor="let tip of errorTips">{{ tip }}</span>
	</section>

	<ga-datatable-new
		[ngClass]="{ hidden: loading }"
		[tableName]="'Meters'"
		[element$]="displayEl$"
		[hideShadow]="true"
		[hideSearch]="true"
	>
		<ng-container emptyTitle>
			<h3 class="empty-table">
				{{ "meter-page.no-content" | transloco }}
			</h3>
		</ng-container>
	</ga-datatable-new>
	<div *ngIf="!loading" class="button-group flex-row">
		<div class="btn-toolbar" role="toolbar" style="text-align: center">
			<div class="btn-group" role="group">
				<button class="btn btn-default" (click)="back()">
					{{ "main.menu.back-no-contract" | transloco }}
				</button>
			</div>
			<div
				class="btn-group"
				role="group"
				*ngIf="response.arrExtractedDataError.length"
			>
				<button class="btn btn-default" (click)="cancel()">
					{{ "global.cancel" | transloco }}
				</button>
			</div>
			<div
				class="btn-group"
				role="group"
				*ngIf="
					response.arrExtractedDataError &&
					!response.arrExtractedDataError.length
				"
			>
				<button class="btn btn-primary btn-color-ga" (click)="send()">
					{{ "organization.btn.save" | transloco }}
				</button>
			</div>
		</div>
	</div>
</div>
