<div class="content-measurement-import">
	<div class="description">
		<p>
			<span class="bold">{{ "import.attention" | transloco }}: </span
			>{{ "import.meter-success-tip3" | transloco }}
		</p>
	</div>
	<ng-container *ngIf="step === 1">
		<div class="form-item col-lg-12">
			<button
				class="btn btn-group btn-default btn-default-ga mr-4"
				type="button"
				(click)="downloadTemplate()"
			>
				{{ "import.download" | transloco }}
			</button>
		</div>

		<div class="form-item col-lg-12">
			<ga-upload
				[icon]="'upload'"
				[iconType]="'default'"
				[iconSize]="18"
				[fileTypes]="fileTypes"
				[formControl]="uploadedFiles"
				[multiple]="false"
				name="uploadedFiles"
			></ga-upload>
		</div>
	</ng-container>
</div>
