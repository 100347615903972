import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasFeatureDirective } from './directives/has-feature.directive';

@NgModule({
	declarations: [HasFeatureDirective],
	exports: [HasFeatureDirective],
	imports: [CommonModule],
})
export class TenantModule {}
