<div class="content">
	<div class="description">
		<div class="description__texts">
			<span class="description__texts__title">{{
				"kpi.title" | transloco
			}}</span>
			<span class="description__texts__subtitle">{{
				"kpi.subtitle" | transloco
			}}</span>
		</div>

		<div
			class="btn-toolbar description__action-buttons"
			role="toolbar"
			*wchHasFeature="'kpisCreate'"
		>
			<button class="btn btn-color-ga" type="button" (click)="onNewKpi()">
				<i class="fa-regular fa-chart-line"></i>
				{{ "kpi.new" | transloco }}
			</button>
		</div>
	</div>

	<div class="organization-row">
		<autocomplete-orgs
			[idSelected]="organizationId"
			(orgEmitter)="orgSelected($event)"
		></autocomplete-orgs>
		<ga-date-range-picker
			[title]="'⠀⠀⠀⠀⠀⠀⠀'"
			[placeholder]="'00/0000'"
			[showTime]="false"
			[showOptions]="false"
			[initialPeriod]="'month'"
			[(ngModel)]="selectedMonth"
			(ngModelChange)="selectDate()"
			[position]="'right'"
			[hideClearButton]="true"
		>
		</ga-date-range-picker>
	</div>
	<div class="row col-lg-12 m-0 p-0 dataTable">
		<div *ngIf="!organizationId" class="no-organization">
			<ga-tag-icon [icon]="'error'" [size]="22"></ga-tag-icon>
			<span class="message">
				{{ "kpi.table.no-organization" | transloco }}
			</span>
		</div>
		<ga-datatable-new
			[ngClass]="{ hidden: !organizationId }"
			[tableName]="'Kpi'"
			[element$]="displayEl$"
			(renderDataChange)="getKpiPoints($event)"
			(onAction)="onActionCliked($event)"
			[filterMultiple]="true"
		>
			<ng-container externalFilters>
				<kpi-top-bar
					[selectedOrg]="organizationId"
					(filterMetrics)="setFilters($event)"
				></kpi-top-bar>
			</ng-container>
			<ng-container emptyTitle>
				<h3 class="empty-table">
					{{ "kpi.table.no-content" | transloco }}
				</h3>
			</ng-container>
			<ng-container emptySubtitle>
				<h4 class="empty-table__tips" *ngIf="currentUser.isAdmin || isOrgAdmin">
					<span
						>{{ "kpi.table.no-content-sub1" | transloco }}
						<a (click)="onNewKpi()">{{
							"kpi.table.no-content-sub2" | transloco
						}}</a></span
					>
					<span>{{ "kpi.table.no-content-sub3" | transloco }}</span>
				</h4>
				<h4
					class="empty-table__tips"
					*ngIf="!currentUser.isAdmin && !isOrgAdmin"
				>
					<span>{{ "kpi.table.no-content-sub4" | transloco }}</span>
				</h4>
			</ng-container>
		</ga-datatable-new>
	</div>
</div>
