import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { UserService } from 'webapp/app/shared/services/user.service';
import { setupDowngradeName } from 'webapp/hybrid-helpers/setupDowngradeName';
import { AuthService } from '../auth.service';

declare let $: JQueryStatic;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	host: {
		class: 'login',
	},
	encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
	message: string | null = null;
	readonly currentDate = new Date();

	readonly splashPages = [
		'/assets/images/green_analitycs.png',
		'/assets/images/green_contratos.png',
	];

	readonly bgColors = ['#cccc99', '#7affbf'];

	splashPageURL: any;

	form = new FormGroup({
		email: new FormControl('', {
			validators: [Validators.required, Validators.email],
		}),
		password: new FormControl('', [Validators.required]),
	});

	constructor(
		private sanitize: DomSanitizer,
		private authService: AuthService,
		private i18n: TranslocoService,
		private userService: UserService,
		@Inject(NG_ROUTER) private $state: any
	) {}

	ngOnInit(): void {
		const rand = Math.floor(Math.random() * this.splashPages.length);
		this.splashPageURL = this.sanitize.bypassSecurityTrustResourceUrl(
			this.splashPages[rand]
		);
		$('.embed-responsive.embed-responsive-image')
			.eq(0)
			.css('background-color', this.bgColors[rand]);
	}

	onSubmit(form: FormGroup) {
		const { valid, value } = form;
		if (!valid) {
			this.message = this.i18n.translate('login.validation.mandatory');
			return;
		}
		this.authService
			.login(value)
			.then(() => {
				const permission = { name: 'branch' };
				this.userService.getUserPermissions().then((permissions: any[]) => {
					const hasPermission =
						permissions
							.map((permission: { name: any }) => permission.name)
							.indexOf(permission) >= 0;
					if (hasPermission) {
						this.$state.transitionTo('main.measuring-points');
					} else {
						this.$state.transitionTo('main.dashboard');
					}
				});
			})
			.catch((data) => {
				this.message = data.message;
				if (data.error) {
					this.message = data.error;
				}
			});
	}
}

export const loginCmp = {
	name: setupDowngradeName(LoginComponent.name),
	def: downgradeComponent({ component: LoginComponent }),
};
