<label *ngIf="title">
	<span class="mr-5">{{ title }}</span>
	<ga-helper
		*ngIf="tooltip"
		[text]="tooltip"
		[icon]="'fa-solid fa-circle-info'"
		[iconSize]="12"
		[iconColor]="'#A3A3A3'"
		[tooltipLocale]="'above'"
	></ga-helper>
</label>
<angular-editor
	class="form-editor"
	[formControl]="editorCtrl"
	id="editor-{{ id }}"
	(input)="onChange(editorCtrl.value)"
	[disabled]="disabled"
	[placeholder]="placeholder"
	(blur)="onTouch()"
	(keydown)="tryEmitEnter($event)"
	[attr.maxlength]="maxLength"
	[attr.minlength]="minLength"
	[ngClass]="{ 'has-error': showError }"
	[config]="configs"
></angular-editor>
