import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
	NG_ROOT_SCOPE,
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { HttpCancelService } from '../services/httpCancel.service';

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
	constructor(
		@Inject(NG_ROOT_SCOPE) private $rootScope,
		@Inject(NG_ROUTER) private $state,
		@Inject(NG_STATE_PARAMS) private $stateParams,
		private httpCancelService: HttpCancelService
	) {
		this.$rootScope.$stateParams = this.$stateParams;
		this.$rootScope.$on(
			'$stateChangeStart',
			function (event, toState, fromState) {
				httpCancelService.cancelPendingRequests();
			}
		);
		this.$rootScope.$watchCollection(
			'$stateParams',
			function (newValue, oldValue) {
				if (
					newValue['tab'] != oldValue['tab'] &&
					newValue['tab'] &&
					oldValue['tab']
				) {
					httpCancelService.cancelPendingRequests();
				}
			}
		);
	}

	intercept<T>(
		req: HttpRequest<T>,
		next: HttpHandler
	): Observable<HttpEvent<T>> {
		return next
			.handle(req)
			.pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
	}
}
