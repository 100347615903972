<div class="dialog">
	<div class="panel-heading" mat-dialog-title>
		<button type="button" class="close" (click)="dialogRef.close()">
			<span aria-hidden="true">&times;</span>
			<span class="sr-only">Close</span>
		</button>
		<h4 class="modal-title">
			{{ "projects.planned-projects.title" | transloco }}
		</h4>
	</div>
	<div class="modal-body" mat-dialog-content>
		<div class="row" style="margin-top: 1rem">
			<div class="col-md-8 col-sm-12">
				<nav class="ga-navbar navbar-default">
					<div class="navbar-content">
						<h3>
							<a (click)="backTo()">
								{{ "projects.planned-projects.title" | transloco }}
							</a>
							<i style="top: 3px" class="fa-solid fa-chevron-right"></i>
							<span> {{ "projects.planned-projects.list" | transloco }}</span>
						</h3>
					</div>
				</nav>
			</div>
			<div class="col-md-4 col-sm-12 text-right">
				<div class="btn-group" (click)="newPreviewProject()">
					<button class="btn btn-default btn-color-ga">
						{{ "projects.planned-projects.new" | transloco }}
					</button>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12" style="margin-top: 3rem">
				<span class="search-box">
					<i class="fa fa-search"></i>
					<input
						type="text"
						class="form-control search-input"
						placeholder="{{ 'main.search' | transloco }}"
						style="width: 250px"
						(keypress)="search($event)"
					/>
				</span>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12" style="margin-top: 3rem">
				<div class="table-responsive table-responsive-ga">
					<table
						style="width: 100%"
						mat-table
						#table
						[dataSource]="dataSource"
						matSort
						(matSortChange)="announceSortChange($event)"
					>
						<!-- Name Column -->
						<ng-container matColumnDef="name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>
								{{ "projects.planned-projects.title" | transloco }}
							</th>
							<td mat-cell *matCellDef="let element">{{ element.name }}</td>
						</ng-container>

						<!-- period Column -->
						<ng-container matColumnDef="period">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Período</th>
							<td mat-cell *matCellDef="let element">
								{{ element.startDate | date : "MMM yyyy" }} -
								{{ element.endDate | date : "MMM yyyy" }}
							</td>
						</ng-container>

						<!-- period Column -->
						<ng-container matColumnDef="plannedEnergySavings">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>
								{{ "projects.planned-projects.monthly-consume" | transloco }}
							</th>
							<td mat-cell *matCellDef="let element">
								{{ element.plannedEnergySavings }} kWh
							</td>
						</ng-container>

						<!-- action Column -->
						<ng-container matColumnDef="action">
							<th mat-header-cell *matHeaderCellDef></th>
							<td mat-cell *matCellDef="let element">
								<div class="f-inline">
									<i
										class="fa-regular fa-pen-to-square button-icon"
										(click)="edit(element)"
									></i>
									<i
										class="fa-regular fa-trash button-icon"
										(click)="remove(element)"
									></i>
								</div>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
					</table>
				</div>
				<mat-paginator [pageSizeOptions]="[5, 10, 20, 25]"> </mat-paginator>
			</div>
		</div>
	</div>
</div>
