import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { map } from 'rxjs/operators';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'root' })
export class CCEEService {
	constructor(private http: HttpClient) {}

	getCCEEProfileClasses() {
		return this.http
			.get(`${environment.backendUrl}/api/ccee/profileclasses`)
			.toPromise()
			.then((res: any) => {
				return res?.profileClasses;
			});
	}

	getCCEESubMarkets() {
		return this.http
			.get(`${environment.backendUrl}/api/ccee/submarkets`)
			.toPromise()
			.then((res: any) => {
				return res?.subMarkets;
			});
	}

	__getCCEESubMarkets() {
		return this.http.get(`${environment.backendUrl}/api/ccee/submarkets`).pipe(
			map((res: any) => {
				return res?.subMarkets;
			})
		);
	}
}

export const ng2CCEEService = {
	name: CCEEService.name,
	def: downgradeInjectable(CCEEService),
};
