import {
	AfterViewChecked,
	Component,
	ElementRef,
	Inject,
	OnInit,
	Renderer2,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Subscription } from 'rxjs';
import { NG_ROOT_SCOPE, NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { SessionUser } from '../../models/session-user.model';
import { UserService } from '../../services/user.service';
import { WhiteLabelService } from '../whitelabel.service';
import { MixPanelService } from '../../services/mixpanel.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	host: { class: 'sticky menu-include' },
	encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, AfterViewChecked {
	@ViewChild('navWrapper', { static: false }) navWrapper!: ElementRef;
	@ViewChild('nav', { static: false }) mainNav!: ElementRef;
	@ViewChild('menu', { static: false }) menu!: ElementRef;
	@ViewChild('footerMenu', { static: false }) footerMenu!: ElementRef;

	branch;
	screen;
	scrollMenu = false;
	footerImages: any[] = [];

	currentUser: SessionUser = {} as any;
	$subsLogoRendered: Subscription = new Subscription();

	constructor(
		@Inject(NG_ROUTER) private $state,
		@Inject(NG_ROOT_SCOPE) private $rootScope,
		private whitelabel: WhiteLabelService,
		private UserService: UserService,
		private renderer: Renderer2,
		private elRef: ElementRef,
		private mixpanel: MixPanelService
	) {
		this.screen = this.$state.current.url;
		this.$rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams, options) => {
			this.screen = toState.url;
			//prevent black screen when click on "medições" menu twice
			if (
				(fromState.name == 'main.dashboard.meter' || fromState.name == 'main.dashboard.group') &&
				toState.name == 'main.dashboard'
			) {
				event.preventDefault();
			}
		});
	}

	ngOnInit(): void {
		this.UserService.getCurrentUser().then((user) => {
			this.currentUser = user;
		});
		this.$subsLogoRendered = this.whitelabel.footerImages.subscribe((footer) => {
			this.footerImages = footer;
		});
	}

	ngAfterViewChecked() {
		const body = this.elRef.nativeElement.closest('body');
		const navheight = body.offsetHeight - 80 - this.footerMenu.nativeElement.offsetHeight;
		this.renderer.setStyle(this.mainNav.nativeElement, 'height', navheight);
		this.scrollMenu =
			this.mainNav.nativeElement.offsetHeight <
			this.footerMenu.nativeElement.offsetHeight + this.menu.nativeElement.offsetHeight
				? true
				: false;
	}

	//TODO: Migrate to be using routerLink
	goTocontactUs() {
		this.$state.go('main.contact-us', { id: this.currentUser.organization });
	}

	ngOnDestroy() {
		this.$subsLogoRendered.unsubscribe();
	}

	mixPanelEvent(eventName) {
		this.mixpanel.mixPanelEvent({ type: eventName });
	}
}

export const ng2NavbarComponent = {
	name: 'appNavbar',
	def: downgradeComponent({ component: NavbarComponent }),
};
