import * as angular from 'angular';
import * as tinycolor from 'tinycolor2';
import * as Highcharts from 'highcharts';
import * as moment from 'moment-timezone';

Charts.$inject = [];
const STACKED_SERIES_INDEX = 3;
const GENERATION_SERIES_INDEX = 4;
const STACKED_FORECAST_SERIES_INDEX = 5;
const FITS_SERIES_INDEX = 6;
const STACKED_CONSUMPTION_METERS_INDEX = 7;

function Charts() {
	const Charts = {
		generation: generation,
		stackedConsumption: stackedConsumption,
		stackedForecast: stackedForecast,
		stackedMeter: stackedMeter,
		stackedFits: stackedFits,
		destroyRealtime: destroyRealtime,
		addRealtimePoint: addRealtimePoint,
		buildRealtime: buildRealtime,
		comparison: comparison,
	};

	return Charts;

	function addConsumptionData(
		itemsO,
		resolution,
		idPanel,
		serieIndex,
		chartItemsName,
		functionName
	) {
		const element = document.getElementById(idPanel);
		if (!element) return;
		const items = angular.copy(itemsO);
		const bar: any = angular.element(element).highcharts();
		bar.series[serieIndex][chartItemsName] = [];

		const points: any[] = [];
		//Default day format
		resolution = resolution || 'day';
		let formatComparison = 'YYYY-MM-DD';

		if (resolution === 'hour') {
			formatComparison = 'HH';
		} else if (resolution === 'invoice') {
			formatComparison = 'YYYY-MM';
		}

		//clean stacked serie
		bar.series[serieIndex].setData([]);

		if (items[0] != undefined)
			bar.series[serieIndex].name = functionName(items[0]);
		let forecastCount = 0;
		for (let i = 0; i < bar.xAxis[0].categories.length; i++) {
			const category = bar.xAxis[0].categories[i];

			let point: any = {};

			const currentCategoryDateTime = moment(category)
				.tz(bar.timezone)
				.format(formatComparison);
			const itemDateTime =
				items[0] != undefined
					? moment(items[0].date).tz(bar.timezone).format(formatComparison)
					: undefined;

			if (
				itemDateTime != undefined &&
				currentCategoryDateTime === itemDateTime
			) {
				const item = items.shift();
				bar.series[serieIndex][chartItemsName].push(item);

				point = {
					y: item.activeEnergy > 0 ? item.activeEnergy : 0,
					invoice: item.invoice,
				};

				if (item.forecast == true) {
					point.color = tinycolor('#AEAEAE')
						.brighten(1.0 * forecastCount)
						.toString();
					forecastCount++;
				}
			} else {
				point = { y: null };
			}
			points.push(point);
			// bar.series[serieIndex].addPoint(point);
		}
		bar.series[serieIndex].setData(points, false);
		bar.redraw();
	}

	function buildRealtime(idpanel, series, argOptions) {
		const element = document.getElementById(idpanel);
		if (!element) return;

		let chart: any = angular.element(element).highcharts();

		//Default chart options
		const options: any = {
			chart: {
				renderTo: idpanel,
				backgroundColor: undefined,
				spacingLeft: 0,
				type: 'line',
			},
			showYTicks: true,
			plotOptions: {
				series: {
					marker: {
						enabled: false,
					},
					enableMouseTracking: true,
					states: {
						inactive: {
							opacity: 1,
						},
					},
				},
			},

			title: {
				text: undefined,
			},

			legend: {
				enabled: false,
			},
			tooltip: {
				enabled: true,
				formatter: function () {
					//moment.locale('pt-br');
					const dateFormatted = moment(this.x).format('dddd, MMM D, HH:mm:ss');
					return (
						dateFormatted + '<br/>Potência: <strong>' + this.y + '</strong>'
					);
				},
			},
			xAxis: {
				type: 'datetime',
				endOnTick: false,
				gridLineWidth: 0,
				lineColor: '#000',
				lineWidth: 0.2,
				tickWidth: 0,
				tickLength: 5,
				tickPixelInterval: 5,
				//tickAmount: 6,
				tickAmount: 30,
				tickColor: '#000',
				labels: {
					enabled: false,
				},
			},
			yAxis: {
				title: {
					text: null,
				},
				tickColor: '#000',
				tickWidth: 0.2,
				tickLength: 5,
				lineColor: '#000',
				lineWidth: 0.2,
				tickAmount: 6,

				gridLineWidth: 0,

				tickPositioner: function () {
					if (this.dataMin !== null && this.dataMax != null) {
						const scale = Math.pow(10, this.dataMax.toString().length - 1);
						const tickAmount = this.tickAmount;
						const positions: number[] = [];

						const maxNormalized = Math.ceil(this.dataMax / scale) * scale;
						const tickInterval = Math.ceil(maxNormalized / (tickAmount - 2));

						for (let i = 0; i < tickAmount; i++) {
							positions.push(i * tickInterval);
						}
						return positions;
					}
					return [];
				},
			},
			series: series,
		};

		//merge param options with default options
		angular.extend(options, argOptions);

		chart = new Highcharts.Chart(options);

		return chart;
	}

	function destroyRealtime(chart) {
		if (chart) {
			chart.destroy();
			chart = undefined;
			return true;
		}
		return false;
	}

	function addRealtimePoint(chart, data, serieIndex) {
		if (chart != null && data.length > 0) {
			let shift = false;
			const serie = chart.series[serieIndex];
			if (serie.data.find((d) => d == null)) {
				serie.data.splice(
					serie.data.findIndex((d) => d == null),
					1
				);
			}
			if (
				serie.data.length >= 300 ||
				(serie.data.length >= 12 &&
					serie.data[serie.data.length - 1].x -
						serie.data[serie.data.length - 2].x ==
						300000)
			) {
				shift = true;
			} else if (serie.data.length > 12) {
				const last5minPoints = [12, 11];
				for (let i = 0; i <= 288; i += 24) {
					if (
						serie.data.length == 12 + i &&
						serie.data[last5minPoints[0]].x - serie.data[last5minPoints[1]].x >
							1000
					) {
						shift = true;
						chart.xAxis[0].minRange = 1500000 - (i + i / 24) * 5350;
						break;
					}
					last5minPoints[0]--;
					last5minPoints[1]--;
				}
			}
			chart.series[serieIndex].addPoint(data[0], true, shift);
			chart.reflow();
		}
	}

	function comparison(items, labels, values, chartHandle, idpanel) {
		if (values && labels) {
			if (chartHandle) {
				//When a chart alredy exists, then destroy and recreate
				chartHandle.destroy();
				$('#comparisonChart').remove();
			}
			const data = {
				labels: labels,
				datasets: [
					{
						fillColor: 'var(--primary-color)',
						strokeColor: 'none',
						highlightFill: 'var(--secondary-color)',
						highlightStroke: 'none',
						data: values,
					},
				],
			};
			const m = max(values[0], values[1]);
			const Chart = <any>Highcharts.Chart;
			Chart.types.Bar.extend({
				name: 'BarGA',
				intialize: function () {
					Chart.types.Bar.intialize.draw.apply(
						this,
						items,
						labels,
						values,
						chartHandle,
						idpanel
					);
				},
				new: Chart,
				draw: function () {
					Chart.types.Bar.prototype.draw.apply(
						this,
						items,
						labels,
						values,
						chartHandle,
						idpanel
					);
					const ctx = this.chart.ctx;
					ctx.save();
					ctx.lineWidth = this.scale.lineWidth;
					ctx.strokeStyle = 'var(--grayscale---gray-200)';
					ctx.beginPath();
					ctx.moveTo(this.scale.xScalePaddingLeft, this.scale.calculateY(0));
					ctx.lineTo(this.chart.width, this.scale.calculateY(0));
					ctx.stroke();
					ctx.closePath();
					ctx.restore();
				},
			});
			$('#' + idpanel).append(
				'<canvas id=\'comparisonChart\' height=\'100%\' width=\'100%\'></canvas>'
			);
			const comparisonChart: any = document.getElementById('comparisonChart');
			if (comparisonChart) {
				const comparisonChart2d = comparisonChart.getContext('2d');
				const comparisonChartHandle = (<any>(
					new Highcharts.Chart(comparisonChart2d)
				)).BarGA(data, {
					responsive: true,
					scaleFontFamily: '\'Roboto\', \'Helvetica Neue\', \'Helvetica\', \'Arial\'',
					scaleFontSize: 11,
					barValueSpacing: 1,
					barShowStroke: false,
					scaleOverride: true,
					scaleStartValue: 0,
					scaleStepWidth: Math.round(m / 3),
					scaleSteps: 3,
					scaleLabel: function (v) {
						const value = v.value;
						return value == '0' ? '' : value + '';
					},
					scaleLineWidth: 1,
					scaleLineColor: 'rgba(0, 0, 0, 0)',
					scaleShowHorizontalLines: true,
					scaleShowVerticalLines: false,

					tooltipTemplate: '<%if (label){%><%=label%>: <%}%><%= value %> kWh',
				});
				if (comparisonChartHandle.datasets) {
					comparisonChartHandle.datasets[0].bars[0].fillColor =
						'var(--primary-color)';
					comparisonChartHandle.datasets[0].bars[1].fillColor =
						'var(--secondary-color)';
					comparisonChartHandle.datasets[0].bars[2].fillColor =
						'var(--alert---alert-500)'; //80c342
					//comparisonChart.datasets[0].bars[3].fillColor = "#cfdd26";
					comparisonChartHandle.update();
				}

				return comparisonChartHandle;
			}
		}
	}

	function stackedConsumption(items, resolution, idPanel) {
		addConsumptionData(
			items,
			resolution,
			idPanel,
			STACKED_SERIES_INDEX,
			'stackedItems',
			function (item) {
				let serieName = '';
				if (item.applianceTypeId) {
					serieName = item.name;
				} else {
					serieName = 'Aparelho ' + item.classificationMeterSequence;
				}
				return serieName;
			}
		);
	}

	function generation(items, resolution, idPanel) {
		addConsumptionData(
			items,
			resolution,
			idPanel,
			GENERATION_SERIES_INDEX,
			'consumptionItems',
			function (item) {
				return 'Consumption';
			}
		);
	}

	function stackedForecast(
		items,
		resolution,
		idPanel,
		forecastType = 'realistic'
	) {
		items.forEach((item) => {
			item.forecast = true;
		});

		const element = document.getElementById(idPanel);
		if (!element) return;
		const bar: any = angular.element(element).highcharts();

		const selectedSerie = bar.series[0];

		if (selectedSerie) {
			let formatComparison = 'YYYY-MM-DD';

			if (resolution === 'hour') {
				formatComparison = 'HH';
			} else if (resolution === 'invoice') {
				formatComparison = 'YYYY-MM';
			}

			const lastConsumptionDate = moment(
				selectedSerie.consumptionItems.reduce((max, current) =>
					current.date ? current.date : max
				)
			).format(formatComparison);

			angular.forEach(
				selectedSerie.consumptionItems.filter(
					(consumption) => consumption.date != undefined
				),
				function (item, key) {
					const category = moment(item.date).format(formatComparison);
					items.forEach((i, index, itemsArray) => {
						if (category === moment(i.date).format(formatComparison)) {
							if (resolution != 'hour') {
								i.activeEnergy += item.activeEnergy;

								if (i.cepa) {
									i.cepa += item.cepa || 0;
								}
								if (i.cepb) {
									i.cepb += item.cepb || 0;
								}
								if (i.cepc) {
									i.cepc += item.cepc || 0;
								}

								if (
									i.offPeak &&
									i.offPeak.activeEnergy &&
									item.offPeak &&
									item.offPeak.activeEnergy
								) {
									i.offPeak.activeEnergy += item.offPeak.activeEnergy;

									if (i.offPeak.invoice && item.offPeak.invoice) {
										i.offPeak.invoice.value += item.offPeak.invoice.value;
									}
								}

								if (
									i.peak &&
									i.peak.activeEnergy &&
									item.peak &&
									item.peak.activeEnergy
								) {
									i.peak.activeEnergy += item.peak.activeEnergy;

									if (i.peak.invoice && item.peak.invoice) {
										i.peak.invoice.value += item.peak.invoice.value;
									}
								}

								if (
									i.intermediatePeak &&
									i.intermediatePeak.activeEnergy &&
									item.intermediatePeak &&
									item.intermediatePeak.activeEnergy
								) {
									i.intermediatePeak.activeEnergy +=
										item.intermediatePeak.activeEnergy;

									if (
										i.intermediatePeak.invoice &&
										item.intermediatePeak.invoice
									) {
										i.intermediatePeak.invoice.value +=
											item.intermediatePeak.invoice.value;
									}
								}

								if (i.invoice && item.invoice) {
									i.invoice.value += item.invoice.value;
								}
							} else {
								if (
									moment(i.date).format(formatComparison) != lastConsumptionDate
								) {
									itemsArray.splice(index, 1);
								}
							}
						} else {
							if (
								resolution == 'invoice' &&
								moment(i.date).isAfter(moment(lastConsumptionDate), 'month')
							) {
								itemsArray.splice(index, 1);
							}
						}
					});
				}
			);
		}

		addConsumptionData(
			items,
			resolution,
			idPanel,
			STACKED_FORECAST_SERIES_INDEX,
			'forecastItems',
			function (item) {
				return 'forecast-' + forecastType;
			}
		);
	}

	function stackedFits(items, resolution, idPanel) {
		const element = document.getElementById(idPanel);
		if (!element) return;
		const bar: any = angular.element(element).highcharts();
		const selectedSerie = bar.series[0];
		const itemsCopy = [...items];
		if (selectedSerie) {
			let formatComparison = 'YYYY-MM-DD';

			if (resolution === 'hour') {
				formatComparison = 'YYYY-MM-DD HH';
			} else if (resolution === 'invoice') {
				formatComparison = 'YYYY-MM';
			}
			const lastDate = selectedSerie.consumptionItems.reduce((max, current) =>
				current.date ? current.date : max
			);
			const lastConsumptionDate = moment
				.tz(lastDate.date ? lastDate.date : lastDate, bar.timezone)
				.format(formatComparison);

			angular.forEach(
				selectedSerie.consumptionItems.filter(
					(consumption) => consumption.date != undefined
				),
				function (item, key) {
					const category = moment(item.date).format(formatComparison);
					let numRemoved = 0;
					items.forEach((i, index) => {
						if (
							resolution === 'hour' &&
							moment(i.date).isAfter(
								moment.tz(lastConsumptionDate, bar.timezone),
								'hour'
							)
						) {
							itemsCopy.splice(index - numRemoved, 1);
							numRemoved++;
						} else if (
							resolution == 'invoice' &&
							moment(i.date).isAfter(
								moment.tz(lastConsumptionDate, bar.timezone),
								'month'
							)
						) {
							itemsCopy.splice(index - numRemoved, 1);
							numRemoved++;
						} else if (
							resolution == 'day' &&
							moment(i.date).isAfter(
								moment.tz(lastConsumptionDate, bar.timezone),
								'day'
							)
						) {
							itemsCopy.splice(index - numRemoved, 1);
							numRemoved++;
						}
					});
				}
			);

			addConsumptionData(
				itemsCopy,
				resolution,
				idPanel,
				FITS_SERIES_INDEX,
				'fitsItems',
				function (item) {
					return 'fits';
				}
			);
		}
	}

	function stackedMeter(items, resolution, idPanel, color, name) {
		const element = document.getElementById(idPanel);
		if (!element) return;
		const bar: any = angular.element(element).highcharts();

		bar.series[STACKED_CONSUMPTION_METERS_INDEX].color = color;

		addConsumptionData(
			items,
			resolution,
			idPanel,
			STACKED_CONSUMPTION_METERS_INDEX,
			'consumptionMeters',
			function (item) {
				return 'meter-' + name;
			}
		);
	}
}

export const ngCharts = {
	name: Charts.name,
	def: Charts,
};
