import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CredentialsService } from '../services/credentials.service';
import { catchError, tap } from 'rxjs/operators';
import { EMPTY, Subscription } from 'rxjs';
import { NotificationService } from '../notification/notification.service';

@Component({
	selector: 'ga-dialog-confirm-identity',
	templateUrl: './confirm-identity.component.html',
	styleUrls: ['./confirm-identity.component.scss'],
})
export class ConfirmIdentityComponent implements OnInit, OnDestroy {
	@Input() accessId!: number | undefined;
	status!: boolean;
	form!: FormGroup;
	loading = false;
	subscription: Subscription | undefined;

	constructor(
		public dialogRef: MatDialogRef<ConfirmIdentityComponent>,
		private fb: FormBuilder,
		private credentialsService: CredentialsService,
		private notification: NotificationService
	) {}

	ngOnInit() {
		const form = {
			password: ['', [Validators.required]],
		};
		this.form = this.fb.group(form);
	}

	ngOnDestroy() {
		this.subscription?.unsubscribe();
	}

	onCancel(): void {
		this.status = false;
		this.dialogRef.close(this.status);
	}

	onSubmit() {
		if (this.form.valid) {
			this.loading = true;
			this.subscription = this.credentialsService
				.getAccessPassword({
					accessId: this.accessId,
					password: this.form.value.password,
				})
				.pipe(
					tap((result) => {
						this.dialogRef.close(result);
					}),
					catchError(() => {
						this.dialogRef.close(false);
						this.notification.error({ msg: 'Erro ao verificar senha.' });
						return EMPTY;
					})
				)
				.subscribe();
		}
	}
}
