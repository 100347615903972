<div class="description">
	<div class="texts">
		<span class="title">{{ "main.comparison.projects" | transloco }}</span>
		<span class="subtitle">{{
			"main.comparison.projects-sub" | transloco
		}}</span>
	</div>

	<div class="btn-toolbar action-buttons" *ngIf="organizationId">
		<button
			class="btn btn-color-ga"
			type="button"
			*wchHasFeature="'projectsCreate'"
			(click)="createProject()"
		>
			<i class="fa-regular fa-clipboard"></i>
			{{ "projects.new" | transloco }}
		</button>
	</div>
</div>

<div class="row">
	<div class="col-md-12 p-uns">
		<autocomplete-orgs
			[idSelected]="organizationId"
			(orgEmitter)="orgSelected($event)"
		></autocomplete-orgs>
	</div>
</div>

<ng-container *ngIf="organizationId">
	<div class="row" style="padding: 24px 0">
		<div class="panel panel-ga">
			<div class="panel-heading flex-list">
				<h5>{{ "projects.summary" | transloco }}</h5>
				<a (click)="plannedProjectDialog()">{{
					"projects.open-future" | transloco
				}}</a>
			</div>
			<div class="panel-body">
				<div class="row">
					<div class="col-md-4 flex-list border-r">
						<h4>{{ "projects.num" | transloco }}</h4>
						<span>{{ projects.data.length }}</span>
					</div>
					<div class="col-md-4 flex-list border-r">
						<h4>{{ "projects.economy-kwh" | transloco }}</h4>
						<span>{{ totalEconomyKwh || 0 | consumptionPipe }}</span>
					</div>
					<div class="col-md-4 flex-list">
						<h4>{{ "projects.economy-money" | transloco }}</h4>
						<span>{{
							totalEconomyValue || 0
								| currency : "BRL" : "symbol" : "1.2-2" : "pt-BR"
						}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="module table-responsive table-responsive-ga">
			<app-my-loader [lightMode]="true"></app-my-loader>
			<table
				mat-table
				#table
				[dataSource]="projects"
				matSort
				multiTemplateDataRows
			>
				<!-- Code Column -->
				<ng-container matColumnDef="code">
					<th
						width="11%"
						mat-header-cell
						*matHeaderCellDef
						style="padding-left: 29px"
						mat-sort-header
					>
						{{ "projects.table.code" | transloco }}
					</th>
					<td
						mat-cell
						*matCellDef="let element"
						[ngStyle]="{
							'padding-left': element.installations.length > 1 ? '5px' : '32px'
						}"
					>
						<p class="td-dropdown">
							<span
								style="cursor: pointer; padding-right: 3px"
								*ngIf="element.installations.length > 1"
								(click)="element.isExpanded = !element.isExpanded"
							>
								<i
									*ngIf="!element.isExpanded"
									class="fa-solid fa-chevron-down chevron-size"
								></i>
								<i
									*ngIf="element.isExpanded"
									class="fa-solid fa-chevron-up chevron-size"
								></i>
							</span>
							EE - {{ element.id }}
						</p>
					</td>
				</ng-container>
				<!-- Name Column -->
				<ng-container matColumnDef="name">
					<th width="13%" mat-header-cell *matHeaderCellDef mat-sort-header>
						{{ "projects.table.project" | transloco }}
					</th>
					<td mat-cell *matCellDef="let element">
						{{ element.name }}
					</td>
				</ng-container>

				<!-- Installation Column -->
				<ng-container matColumnDef="installations">
					<th width="13%" mat-header-cell *matHeaderCellDef mat-sort-header>
						{{ "projects.table.installation" | transloco }}
					</th>
					<td mat-cell *matCellDef="let element">
						<p class="td-dropdown" *ngIf="element.installations.length > 1">
							{{ element.installations.length }}
							{{ "projects.installations" | transloco }}
						</p>
						<p class="td-dropdown" *ngIf="element.installations.length == 1">
							{{ element.installations[0].installNumber }}
						</p>
					</td>
				</ng-container>

				<!-- Start Date Column -->
				<ng-container matColumnDef="startDate">
					<th width="11%" mat-header-cell *matHeaderCellDef mat-sort-header>
						{{ "projects.table.start-date" | transloco }}
					</th>
					<td mat-cell *matCellDef="let element">
						{{ element.startDate | date : "dd/MM/yyyy" : "UTC" }}
					</td>
				</ng-container>

				<!-- consumption month Column -->
				<ng-container matColumnDef="beforeMonthConsumption">
					<th width="18%" mat-header-cell *matHeaderCellDef mat-sort-header>
						{{ "projects.table.previous-consumption" | transloco }}
					</th>
					<td mat-cell *matCellDef="let element" class="consumption">
						<p>
							{{
								getConsumptionBefore(element, "value")
									| currency : "BRL" : "symbol" : "1.2-2" : "pt-BR"
							}}
						</p>
						<p>
							{{
								getConsumptionBefore(element, "consumption") | consumptionPipe
							}}
						</p>
					</td>
				</ng-container>

				<!-- cunsumption now Column -->
				<ng-container matColumnDef="currentMonthConsumption">
					<th width="18%" mat-header-cell *matHeaderCellDef mat-sort-header>
						{{ "projects.table.current-consumption" | transloco }}
					</th>
					<td mat-cell *matCellDef="let element" class="consumption">
						<p>
							{{
								getConsumptionAfter(element, "value")
									| currency : "BRL" : "symbol" : "1.2-2" : "pt-BR"
							}}
						</p>
						<p>
							{{
								getConsumptionAfter(element, "consumption") | consumptionPipe
							}}
						</p>
					</td>
				</ng-container>

				<!-- Total economy Column -->
				<ng-container matColumnDef="totalEconomy">
					<th width="15%" mat-header-cell *matHeaderCellDef mat-sort-header>
						{{ "projects.table.total-economy" | transloco }}
					</th>
					<td mat-cell *matCellDef="let element" class="consumption">
						<p>
							{{
								getTotalEconomyInstallations(element, "value")
									| currency : "BRL" : "symbol" : "1.2-2" : "pt-BR"
							}}
						</p>
						<p>
							{{
								getTotalEconomyInstallations(element, "consumption")
									| consumptionPipe
							}}
						</p>
					</td>
				</ng-container>

				<!-- Edit Column -->
				<ng-container matColumnDef="edit">
					<th width="1%" mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element">
						<div class="dropdown" style="float: right; margin-top: 8px">
							<i
								class="fa-regular fa-ellipsis-vertical more-size"
								style="cursor: pointer"
								data-toggle="dropdown"
							></i>
							<ul class="dropdown-menu dropdown-menu-right">
								<li>
									<a (click)="openConsumptionSaving(element)">
										{{ "projects.table.see-chart" | transloco }}
									</a>
								</li>
								<li>
									<a (click)="consumeDetailProjectDialog(element)">
										{{ "projects.table.detailed-consumption" | transloco }}
									</a>
								</li>
								<li>
									<a (click)="reportsDialog(element)">
										{{ "projects.table.project-reports" | transloco }}
									</a>
								</li>
								<li>
									<a (click)="detailProjectDialog(element)">
										{{ "projects.table.details" | transloco }}
									</a>
								</li>
								<li *wchHasFeature="'projectsUpdate'">
									<a (click)="editProject(element)">
										{{ "projects.table.edit" | transloco }}
									</a>
								</li>
								<li *wchHasFeature="'projectsDelete'">
									<a (click)="confirmDeleteProjectDialog(element)">
										{{ "projects.table.remove" | transloco }}
									</a>
								</li>
							</ul>
						</div>
					</td>
				</ng-container>

				<!-- Extra extpanded collumn-->
				<ng-container matColumnDef="expandedDetail">
					<td
						mat-cell
						*matCellDef="let element"
						[attr.colspan]="displayedColumns.length"
						style="padding: 0px"
					>
						<div
							class="sub-row-detail"
							[@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'"
						>
							<table class="table table-borderless">
								<tbody>
									<tr *ngFor="let installation of element.installations">
										<td mat-cell style="width: 11%; padding-left: 29px"></td>
										<td mat-cell style="width: 13%"></td>
										<td mat-cell style="width: 13%">
											{{ installation.installNumber }}
										</td>
										<td mat-cell style="width: 11%">
											{{ installation.startDate | date : "dd/MM/yyyy" : "UTC" }}
										</td>
										<td mat-cell style="width: 18%" class="consumption">
											<p>
												{{
													installation.before.value
														| currency : "BRL" : "symbol" : "1.2-2" : "pt-BR"
												}}
											</p>
											<p>
												{{
													installation.before.consumption?.toFixed(2)
														| consumptionPipe
												}}
											</p>
										</td>
										<td mat-cell style="width: 18%" class="consumption">
											<p>
												{{
													installation.after.value
														| currency : "BRL" : "symbol" : "1.2-2" : "pt-BR"
												}}
											</p>
											<p>
												{{
													installation.after.consumption?.toFixed(2)
														| consumptionPipe
												}}
											</p>
										</td>
										<td mat-cell style="width: 15%" class="consumption">
											<p>
												{{
													installation.totalEconomy.value
														| currency : "BRL" : "symbol" : "1.2-2" : "pt-BR"
												}}
											</p>
											<p>
												{{
													installation.totalEconomy.consumption?.toFixed(2)
														| consumptionPipe
												}}
											</p>
										</td>
										<td mat-cell style="width: 1%"></td>
									</tr>
								</tbody>
							</table>
						</div>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: ['expandedDetail']"
					[hidden]="!row.isExpanded"
				></tr>
			</table>
			<mat-paginator
				[pageSizeOptions]="[10, 15, 20, 25]"
				aria-label="Select page"
			>
			</mat-paginator>
		</div>
	</div>
</ng-container>
