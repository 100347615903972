import { Component, Input, OnInit } from '@angular/core';
import { ColumnType } from '../model/datatable.model';

@Component({
	selector: 'ga-only-cell',
	styles: [
		`
			div {
				display: flex;
				flex-direction: column;
			}

			span {
				font-size: 14px;
			}
			.bold {
				font-weight: 600;
			}
			small {
				font-size: 12px;
			}
		`,
	],
	template: `
		<div>
			<span [ngClass]="{ bold: this.info.subtext }">{{ this.info.label }}</span>
			<small>{{ this.info.subtext }}</small>
		</div>
	`,
})
export class OnlyCellComponent implements OnInit {
	@Input() column!: ColumnType;
	@Input() row: any;

	info: any = {} as any;

	ngAfterViewChecked(): void {
		if (!Object.keys(this.info).length && this.row[this.column.name].length) {
			this.info = this.row[this.column.name][0];
		}
	}

	ngOnInit(): void {
		this.info = Array.isArray(this.row[this.column.name])
			? this.row[this.column.name][0] || {}
			: this.row[this.column.name];
	}
}
