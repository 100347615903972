<label for="distributor"
	>{{ 'organization-selector.label' | transloco }}
	<span *ngIf="isRequired" class="required">*</span>
</label>
<div id="distributor" class="group-section" [ngClass]="{ 'ga-input-error': orgInput.invalid && orgInput.touched }">
	<input
		type="text"
		class="form-control"
		[matAutocomplete]="auto"
		[formControl]="orgInput"
		matAutocompletePosition="below"
		(keyup)="!orgInput.value ? checkClear($event) : $event.stopPropagation()"
		[placeholder]="'alerts.label.choose-organization' | transloco"
		[ngClass]="{
			'has-error': orgInput.invalid && orgInput.touched
		}"
	/>
	<mat-autocomplete #auto="matAutocomplete">
		<mat-option
			*ngFor="let option of filteredOrgs$ | async"
			[value]="option.id + ' - ' + option.name"
			(onSelectionChange)="selectOrg(option)"
		>
			{{ option.id }} - {{ option.name }}
		</mat-option>
	</mat-autocomplete>
</div>
<mat-error *ngIf="orgInput.invalid && orgInput.touched">{{ 'global.field-required' | transloco }}</mat-error>
