import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({ name: 'inspectionDay' })
export class InspectionDayPipe implements PipeTransform {
	constructor(private i18n: TranslocoService) {}

	transform(value: string): string {
		return !value
			? this.i18n.translate('global.branch.month-end')
			: `${this.i18n.translate('main.navigator.hour')} ${value}`;
	}
}
