export function addOrUpdateItem(array: any[], newItem, key) {
	const index = array.findIndex((item) => item[key] === newItem[key]);
	if (index === -1) {
		// If the item doesn't exist, add it to the array
		array.push(newItem);
	} else {
		// If the item exists, replace the old item with the new one
		array[index] = newItem;
	}
	return array;
}
