import { AfterViewInit, Directive, ElementRef } from '@angular/core';

declare let $: JQueryStatic;

@Directive({ selector: '[app-kit-menu]' })
export class AppKitMenuDirective implements AfterViewInit {
	constructor(private elr: ElementRef) {}

	ngAfterViewInit() {
		(<any>$(this.elr.nativeElement)).metisMenu();
	}
}
