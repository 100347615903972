import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { OrganizationSmall } from '../../models/organization.model';
import { Permission } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { OrganizationService } from '../../services/organization.service';
import { take, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

/**
 * Implementa a funcionalidade de feature toogle de acordo
 * as permissões do usuário e sua respectiva organização
 */
@Directive({ selector: '[wchHasFeature]', exportAs: '[wchHasFeature]' })
export class HasFeatureDirective implements OnInit, OnDestroy {
	/**
	 * Permissões necessárias para exibir a parte da tela a que foi atrelada a diretive
	 * @type {string|undefined} Uma string contendo duas substrings separadas por um caracter de espaço
	 */
	@Input('wchHasFeature') permissions: string | Array<any> | undefined;
	@Input() preferAdminRole = false;
	subscriptions: Subscription[] = [];

	constructor(
		private vcr: ViewContainerRef,
		private tmp: TemplateRef<any>,
		private userService: UserService,
		private organizationService: OrganizationService
	) {}

	async ngOnInit() {
		const currentUser = await this.userService.getCurrentUser();
		this.checkAndRenderViewBasedOnPermissions(currentUser);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
	}

	private checkAndRenderViewBasedOnPermissions(currentUser) {
		if (!Array.isArray(this.permissions)) {
			this.changedPermission(currentUser, <string>this.permissions);
		} else {
			this.changedPermissions(currentUser, this.permissions);
		}
	}

	changedPermission(currentUser, userPermission: string) {
		let checked = false;
		const userPermissions = currentUser.permissions;
		const subscription = this.organizationService.$obsevableOrganization
			.pipe(
				take(1),
				tap((org) => {
					this.vcr.clear();
					checked = this.check(
						userPermission,
						this.preferAdminRole ? undefined : org?.id,
						currentUser,
						userPermissions
					);
					if (checked || currentUser.isAdmin) {
						this.vcr.createEmbeddedView(this.tmp);
					} else {
						this.vcr.clear();
					}
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	changedPermissions(currentUser, permissions: any[]) {
		let checked = false;
		const userPermissions = currentUser.permissions;
		/*Filtra a permissão do usuário de acordo a organização */
		const subscription = this.organizationService.$obsevableOrganization
			.pipe(
				take(1),
				tap((org) => {
					checked = permissions.some((element) =>
						this.check(element.permission, this.preferAdminRole ? undefined : org?.id, currentUser, userPermissions)
					);
					if (checked) {
						this.vcr.createEmbeddedView(this.tmp);
					} else {
						this.vcr.clear();
					}
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	private check(
		permissions: string | undefined,
		organization: OrganizationSmall[] | number | undefined,
		currentUser: any,
		userPermissions: any
	) {
		if (!permissions) return true;
		const paramPermissions = permissions && permissions.trim();

		const isAdmin = currentUser.isAdmin;

		if (isAdmin || !paramPermissions?.length) {
			return true;
		} else {
			const requiredPermissions = paramPermissions.split(' ').map((perm) => perm.trim());

			const hasPermission = userPermissions
				.filter((perm) => this.byOrganization(perm, organization))
				.map((perm) => perm.name)
				.some((perm) => requiredPermissions.includes(perm));

			return hasPermission;
		}
	}

	private byOrganization(permission: Permission, organization: OrganizationSmall[] | number | undefined) {
		if (!organization) {
			return true;
		}
		if (Array.isArray(organization)) {
			return organization.some((org) => org.id == permission.organization);
		} else {
			return permission.organization === organization;
		}
	}
}
