import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { StateParams, StateService } from 'angular-ui-router';
import { TagService } from 'webapp/app/shared/services/tag.service';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { Tag } from './tag.model';
import { catchError, tap } from 'rxjs/operators';
import { EMPTY, Subscription } from 'rxjs';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';

@Component({
	selector: 'tag-details',
	templateUrl: './tag-details.component.html',
	styleUrls: ['./tag-details.component.scss'],
})
export class TagDetailsComponent implements OnInit, OnDestroy {
	organization: number | undefined;
	tagId: number | undefined;
	tag: Tag = {} as Tag;
	tagName = this.i18n.translate('tags.form.tag-name');
	tagColor = this.getInitialColor();
	tagBackgroundColor = this.generateLighterColor(this.tagColor);
	tagForm!: FormGroup;
	subscriptions: Subscription[] = [];

	constructor(
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		@Inject(NG_ROUTER) private $state: StateService,
		private tagService: TagService,
		private i18n: TranslocoService,
		private notification: NotificationService
	) {
		this.organization = this.$stateParams.organizationId;
		this.tagId = this.$stateParams.id;
	}

	ngOnInit(): void {
		this.initFormGroup();
		this.loadTag();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	initFormGroup() {
		this.tagForm = new FormGroup({
			label: new FormControl('', { validators: [Validators.required] }),
			color: new FormControl(this.tagColor, {
				validators: [Validators.required],
			}),
		});

		this.updatePreVisualizationLabel();
	}

	loadTag() {
		if (!this.tagId) return;

		const subscription = this.tagService
			.getTag(this.tagId)
			.pipe(
				tap((curTag) => {
					this.tag = curTag;
					this.tagForm.get('label')?.setValue(this.tag.label);
					this.tagForm.get('color')?.setValue(this.tag.color);
					this.tagName = this.tagForm.get('label')?.value;
					this.updatePreVisualizationColor(this.tagForm.get('color')?.value);
				}),
				catchError(() => {
					this.notification.error({
						msg: this.i18n.translate('tags.form.msg-load-error'),
					});
					return EMPTY;
				})
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	onChangeColor(event: string) {
		this.tagForm.get('color')?.setValue(event);
		this.updatePreVisualizationColor(this.tagForm.get('color')?.value);
	}

	updatePreVisualizationColor(color) {
		this.tagColor = color;
		this.tagBackgroundColor = this.generateLighterColor(this.tagColor);
	}

	updatePreVisualizationLabel() {
		const subscription = this.tagForm.controls['label'].valueChanges.subscribe(
			(label) => {
				if (label.trim() === '') {
					this.tagName = this.i18n.translate('tags.form.tag-name');
				} else {
					this.tagName = label;
				}
			}
		);

		this.subscriptions.push(subscription);
	}

	onSubmit() {
		if (this.organization) {
			this.tag.organization = this.organization;
		}
		this.tag.label = this.tagForm.value.label;
		this.tag.color = this.tagForm.value.color;
		if (this.tag?.id) {
			this.updateTag();
		} else {
			this.createTag();
		}
	}

	updateTag() {
		this.tagService.editTag(this.tag.id, this.tag).then(
			() => {
				this.notification.success({
					msg: this.i18n.translate('tags.save-success'),
				});
				this.backTo();
			},
			(error) => {
				this.notification.showErrorMessages(error);
			}
		);
	}

	createTag() {
		this.tagService.createTag(this.tag).then(
			() => {
				this.notification.success({
					msg: this.i18n.translate('tags.save-success'),
				});
				this.backTo();
			},
			(error) => {
				this.notification.showErrorMessages(error);
			}
		);
	}

	backTo() {
		this.$state.transitionTo(
			'registrations',
			{ tab: 'tags', organizationId: this.organization },
			{
				inherit: false,
				reload: false,
				notify: false,
			}
		);
	}

	getInitialColor() {
		const colorElement = document.querySelector(':root') as Element;
		const cssStyles = getComputedStyle(colorElement);
		return cssStyles.getPropertyValue('--grayscale---gray-500');
	}

	generateLighterColor(hexColor: string): string {
		const red = parseInt(hexColor.slice(1, 3), 16);
		const green = parseInt(hexColor.slice(3, 5), 16);
		const blue = parseInt(hexColor.slice(5, 7), 16);

		const lighterRed = Math.min(Math.floor(red + (255 - red) * 0.85), 255);
		const lighterGreen = Math.min(
			Math.floor(green + (255 - green) * 0.85),
			255
		);
		const lighterBlue = Math.min(Math.floor(blue + (255 - blue) * 0.85), 255);

		const lighterHex = `#${lighterRed
			.toString(16)
			.padStart(2, '0')}${lighterGreen
			.toString(16)
			.padStart(2, '0')}${lighterBlue.toString(16).padStart(2, '0')}`;

		return lighterHex;
	}
}

export const ng2TagDetailsComponent = {
	name: 'tagDetails',
	def: downgradeComponent({
		component: TagDetailsComponent,
		propagateDigest: true,
	}),
};
