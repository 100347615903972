<div class="wrapper">
	<section class="header-dialog">
		<h1 class="m-0">
			{{'organization.label.theme-manual-app-color-tag' | transloco}}
		</h1>
		<p class="mt-10 mb-24">
			{{ "organization.label.theme-text-info" | transloco }}
		</p>
		<p class="mt-10">
			<span>{{ "organization.label.attention" | transloco }}</span>{{
			"organization.label.theme-text-attention" | transloco }}
		</p>
	</section>
	<section class="body">
		<div class="row-group">
			<div class="col-lg-5 pd">
				<ga-upload-image
					[title]="'organization.modal.msg5' | transloco"
					[tooltip]="tooltipLogoTranslation"
					[backgroundColor]="theme.isBackgroundWhite.value ? '#fff' : theme.primaryColor"
					[fileImages]="theme.textImage"
					(emitFiles)="emitLogo($event)"
				></ga-upload-image>
			</div>
			<div class="col-lg-5 pd">
				<ga-upload-image
					[title]="'organization.modal.msg4' | transloco"
					[tooltip]="tooltipSymbolTranslation"
					[backgroundColor]="theme.primaryColor"
					[fileImages]="theme.logoImage"
					(emitFiles)="emitSymbol($event)"
				></ga-upload-image>
			</div>
		</div>

		<div class="row-group mt">
			<div class="col-lg-4 pd">
				<div class="flex-row">
					<ga-color-picker
						[title]="'organization.modal.primary' | transloco"
						[hasInput]="true"
						[color]="theme.primaryColor"
						[tooltip]="'organization.label.theme-color' | transloco"
						(colorEmitter)="changedPrimaryColor($event)"
						[showFirstModal]="false"
					></ga-color-picker>
				</div>
			</div>
			<div class="col-lg-4 pd">
				<ga-color-picker
					[title]="'organization.modal.secondary' | transloco"
					[hasInput]="true"
					[color]="theme.secondaryColor"
					[tooltip]="'organization.label.theme-color' | transloco"
					(colorEmitter)="changedSecondaryColor($event)"
					[showFirstModal]="false"
				></ga-color-picker>
			</div>
			<div class="col-lg-4 pd">
				<ga-color-picker
					[title]="'organization.modal.charts' | transloco"
					[hasInput]="true"
					[color]="theme.tertiaryColor"
					[tooltip]="'organization.modal.msg8' | transloco"
					(colorEmitter)="changedTertiaryColor($event)"
					[showFirstModal]="false"
				></ga-color-picker>
			</div>
		</div>

		<div class="row-group mt">
			<ga-checkbox
				[isMultiple]="false"
				[formControl]="theme.isBackgroundWhite"
				name="whiteColorInput"
				[title]="
					'organization.label.theme-cbx-info-white-background' | transloco
				"
			></ga-checkbox>
		</div>
		<hr style="margin-top: 10px" />
		<div class="button-group flex-between">
			<div class="btn-group">
				<button
					class="btn btn-default btn-size"
					type="button"
					(click)="close()"
				>
					{{ "appliance.btn.cancel" | transloco }}
				</button>
			</div>
			<div class="btn-group">
				<button
					class="btn btn-default btn-outline-ga btn-size"
					type="button"
					(click)="restoreDefault()"
				>
					{{ "organization.label.btn-theme-default" | transloco }}
				</button>
			</div>
			<div class="btn-group">
				<button
					class="btn btn-primary btn-color-ga btn-size"
					type="submit"
					(click)="send()"
				>
					{{ "meter-page.button.send" | transloco }}
				</button>
			</div>
		</div>
	</section>
</div>
