import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { StateService } from 'angular-ui-router';
import { DateTime } from 'luxon';
import { Observable, Subscription, from } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import {
	ColumnType,
	DataTableList,
} from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { UploadResponse } from 'webapp/app/shared/models/upload.model';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { UploadService } from 'webapp/app/shared/services/upload.service';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';

@Component({
	selector: 'upload-meter-list',
	templateUrl: './upload-meter-list.component.html',
	styleUrls: ['./upload-meter-list.component.scss'],
})
export class UploadMeterListComponent implements OnInit, OnDestroy {
	@Input() filesControl!: FormControl;
	@Input() name = '';
	@Input() organization: number | undefined = 0;
	@Output() close = new EventEmitter();
	@Output() backTo = new EventEmitter();
	errorTips: Array<string> = [];
	loading = true;
	response: UploadResponse = {} as UploadResponse;
	subscriptions: Subscription[] = [];
	displayEl$: Observable<any> = new Observable();

	constructor(
		@Inject(TranslocoService) private i18n,
		@Inject(NG_ROUTER) private $state: StateService,
		private uploadService: UploadService,
		private translocoService: TranslocoService,
		private notification: NotificationService
	) {}

	ngOnInit(): void {
		const subscription = this.filesControl.valueChanges
			.pipe(tap((value) => this.upload(value)))
			.subscribe();
		this.subscriptions.push(subscription);
		this.upload(this.filesControl.value);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subs) => subs.unsubscribe());
	}

	setStatusAndTips(res) {
		res.json.forEach((row, i) => {
			if (row.startDate)
				row.startDate = DateTime.fromISO(row.startDate).toFormat('dd/MM/yyyy');
			if (
				typeof row.bidirectional === 'boolean' ||
				typeof row.bidirectional === 'number'
			)
				row.bidirectional = row.bidirectional
					? this.i18n.translate('data-table.true')
					: this.i18n.translate('data-table.false');
			let consumptionPhases = '';
			row.consumptionPhases.forEach((cf, i) =>
				i == row.consumptionPhases.length - 1
					? (consumptionPhases += cf)
					: (consumptionPhases += cf + ', ')
			);
			row.consumptionPhases = consumptionPhases;

			const error = res.arrExtractedDataError.filter(
				(error) => error.lineError - 1 === i
			);
			if (error && error.length) row.error = error;
		});

		this.errorTips = res.arrExtractedDataError.map((x) => x.errorMessage);

		this.errorTips = this.errorTips.filter(
			(element, index) => this.errorTips.indexOf(element) === index
		);
		return res;
	}

	upload(excelFile) {
		if (!excelFile || excelFile.length == 0) {
			return;
		}
		this.loading = true;
		this.displayEl$ = from(
			this.uploadService.transformExcelToJson(
				excelFile,
				{ organization: this.organization },
				this.name
			)
		).pipe(
			map((res: any) => {
				if (!res.json.length && !res.arrExtractedDataError.length) {
					this.notification.error({
						msg: this.translocoService.translate(
							'import.measurements.error-title2'
						),
					});
					this.back();
					return res;
				}
				this.response.data = res.json.map((element) => ({ ...element }));
				this.response.arrExtractedDataError = res.arrExtractedDataError;
				this.response.organization = this.organization;
				return this.setStatusAndTips(res);
			}),
			map((res) => {
				const element: DataTableList = {} as DataTableList;
				element.data = res.json;
				element.column = this.columns;
				return element;
			}),
			catchError((error) => {
				this.notification.error({
					msg: this.translocoService.translate(
						'import.measurements.error-title4'
					),
				});
				this.back();
				return error;
			}),
			finalize(() => (this.loading = false))
		);
	}

	send() {
		this.loading = true;
		this.uploadService
			.createFromJson(
				{ data: this.response.data, organization: this.response.organization },
				this.name
			)
			.then((res) => {
				this.response.res = res;
				const resultErrors = this.response.res.filter(
					(data) => data.isRegister === false
				);
				if (resultErrors.length) {
					const errors = this.formErrors(resultErrors);
					this.notification.showErrorMessages(errors);
					this.loading = false;
					return;
				} else
					this.notification.success({
						msg: this.translocoService.translate(
							'import.measurement-point.success'
						),
					});

				this.$state.transitionTo(
					'registrations',
					{ tab: 'dataSources', organizationId: this.response.organization },
					{
						inherit: true,
						reload: false,
						notify: false,
					}
				);

				this.loading = false;
			})
			.catch(() => {
				this.loading = false;
				this.response.res.push({ isRegister: false });
				this.notification.error({
					msg: this.translocoService.translate('file-uploader-created-error'),
				});
			});
	}

	back() {
		this.close.emit();
	}

	cancel() {
		this.backTo.emit();
	}

	formErrors(arrError) {
		const Errors = {};
		arrError.forEach((error, i) => {
			Errors[i] = [
				{
					rule: null,
					message: error.errorMessage,
				},
			];
		});
		const x = {
			data: {
				Error: {
					Errors,
				},
			},
		};
		return x;
	}

	extractNumberFromErrors(arrError): number {
		let elements = arrError.map((x) => x.lineError);

		elements = elements.filter(
			(element, index) => elements.indexOf(element) === index
		);

		return elements.length;
	}

	get columns(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('meter-page.status'),
				name: 'error',
				type: 'valid',
				width: '5%',
			},
			{
				label: this.i18n.translate('meter-page.label.uid'),
				name: 'uid',
				type: 'text',
				width: '10%',
			},
			{
				label: this.i18n.translate('meter-page.label.point-name'),
				name: 'label',
				type: 'text',
			},
			{
				label: this.i18n.translate('file-uploader.table.zipcode'),
				name: 'zipCode',
				type: 'text',
				width: '10%',
			},
			{
				label: this.i18n.translate('file-uploader.table.consumptionPhases'),
				name: 'consumptionPhases',
				type: 'text',
				width: '7%',
			},
			{
				label: this.i18n.translate('meter-page.label.type'),
				name: 'meterTypeName',
				type: 'text',
			},
			{
				label: this.i18n.translate('file-uploader.table.bidirecional'),
				name: 'bidirectional',
				type: 'text',
				width: '10%',
			},
			{
				label: this.i18n.translate('file-uploader.table.startDate'),
				name: 'startDate',
				type: 'text',
				width: '13%',
			},
		];
	}
}
