import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GaButtonDropdownComponent } from './ga-button-dropdown.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
	declarations: [GaButtonDropdownComponent],
	imports: [CommonModule, MatMenuModule],
	exports: [GaButtonDropdownComponent],
})
export class GaButtonDropdownModule {}
