<div id="tariff" class="form-group">
	<label for="tariff">Tarifa</label>
	<input
		type="text"
		class="form-control"
		[matAutocomplete]="auto"
		[formControl]="tariffInput"
		[ngClass]="{
			'has-error': (tariffInput.invalid && tariffInput.touched) || isErrorInput
		}"
	/>
	<mat-autocomplete
		#auto="matAutocomplete"
		(optionSelected)="getOptionSelected($event)"
	>
		<mat-option
			*ngFor="let option of filteredTariffs$ | async"
			[value]="option.name"
			[id]="option.id"
		>
			{{ option.name }}
		</mat-option>
	</mat-autocomplete>
</div>
