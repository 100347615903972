import { Component, Inject, OnInit } from '@angular/core';
import {
	NG_ROUTER,
	SHARED_SOURCE,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';

@Component({
	selector: 'app-tool-source-selector',
	template: `
		<ng2-source-selector
			[params]="params"
			(changeListener)="sourceSelectorListener($event)"
		>
		</ng2-source-selector>
	`,
	//styleUrls: ['./tool-source-selector.component.scss']
})
export class ToolSourceSelectorComponent implements OnInit {
	params = {};

	constructor(
		@Inject(NG_ROUTER) private $state,
		@Inject(SHARED_SOURCE) private sharedSource
	) {}

	ngOnInit(): void {
		this.params = this.$state.params;
	}

	sourceSelectorListener(source) {
		this.sharedSource.sourceObj = source;
		this.sharedSource.notify(source.source);
	}
}
