import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	Output,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { StateParams } from 'angular-ui-router';
import { EMPTY, Subscription, from } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { UploadResponse } from 'webapp/app/shared/models/upload.model';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { UploadService } from 'webapp/app/shared/services/upload.service';
import { NG_STATE_PARAMS } from 'webapp/hybrid-helpers/ajs-upgraded-providers';

@Component({
	selector: 'measurements-import',
	templateUrl: './measurements-import.component.html',
	styleUrls: ['./measurements-import.component.scss'],
})
export class MeasurementsImportComponent implements OnDestroy {
	@Input() organization: number | undefined;
	@Output() loading = new EventEmitter<boolean>();
	@Output() changeContent = new EventEmitter();
	@Output() emitClose = new EventEmitter();
	fileTypes = ['.xls', '.xlsx'];
	uploadedFiles = new FormControl('', Validators.required);
	subscriptions: Subscription[] = [];
	response: UploadResponse = {} as UploadResponse;
	step = 1;

	constructor(
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		private notification: NotificationService,
		private i18n: TranslocoService,
		private uploadService: UploadService
	) {
		this.organization = this.$stateParams.organizationId;
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subs) => subs.unsubscribe());
	}

	downloadTemplate() {
		this.uploadService.downloadTemplateExcel('measurements').catch((error) => {
			console.error(error);
			this.notification.error({ msg: 'Erro ao fazer download do template' });
		});
	}

	upload() {
		if (!this.uploadedFiles.value || this.uploadedFiles.value.length == 0) {
			return;
		}
		this.loading.emit(true);
		const subscription = from(
			this.uploadService.transformExcelToJson(
				this.uploadedFiles.value,
				{ organization: this.organization },
				'measurements'
			)
		)
			.pipe(
				tap((res: any) => {
					this.response.data = res.json;
					this.response.arrExtractedDataError = res.arrExtractedDataError;

					if (res.arrExtractedDataError && res.arrExtractedDataError.length) {
						this.step = 1;
						this.changeContent.emit({
							title: this.i18n.translate('import.measurements.error-title1'),
							message: this.i18n.translate('import.measurements.error-tip1'),
							btnMessage: 'meter-page.button.send',
						});
					} else if (res.json && !res.json.length) {
						this.step = 1;
						this.changeContent.emit({
							title: this.i18n.translate('import.measurements.error-title2'),
							message: this.i18n.translate('import.measurements.subtitle'),
							btnMessage: 'meter-page.button.send',
						});
					} else {
						this.step = 2;
						this.changeContent.emit({
							title: this.i18n.translate('import.measurements.import'),
							message:
								this.i18n.translate('import.measurements.success-tip1') +
								res.json.length +
								this.i18n.translate('import.measurements.success-tip2'),
							response: this.response,
							btnMessage: 'meter-page.button.save',
						});
					}
				}),
				catchError((error) => {
					this.step = 1;
					this.changeContent.emit({
						title: this.i18n.translate('import.measurements.error-title3'),
						message: this.i18n.translate('import.measurements.subtitle'),
						btnMessage: 'meter-page.button.send',
					});
					return typeof error === 'object' ? EMPTY : error;
				}),
				finalize(() => {
					this.uploadedFiles.setValue([]);
					this.loading.emit(false);
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	send() {
		const subscription = from(
			this.uploadService.createFromJson(
				{ data: this.response.data, organization: this.organization },
				'measurements'
			)
		)
			.pipe(
				tap(() => {
					this.notification.success({
						msg: this.i18n.translate('import.measurements.success'),
					});
					this.emitClose.emit();
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return typeof error === 'object' ? EMPTY : error;
				}),
				finalize(() => {
					this.uploadedFiles.setValue([]);
					this.loading.emit(false);
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}
}
