export interface Base64Image {
	base64Data: string;
	fileName: string;
	fileType: string;
}

function transformBase64Obj(base64: string) {
	return base64.split(',')[1];
}

function base64ArrayToFiles(base64List: Base64Image[]): File[] {
	const files: File[] = [];
	for (let i = 0; i < base64List.length; i++) {
		const base64Data = base64List[i].base64Data;
		const fileName = base64List[i].fileName;
		const fileType = base64List[i].fileType;
		// Validate and sanitize the Base64 data
		if (!isValidBase64(base64Data)) {
			console.warn(`Skipping invalid Base64 data at index ${i}`);
			continue;
		}
		// Convert the Base64 data to a Blob
		const byteCharacters = atob(base64Data);
		const byteNumbers = new Array(byteCharacters.length);
		for (let j = 0; j < byteCharacters.length; j++) {
			byteNumbers[j] = byteCharacters.charCodeAt(j);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: fileType });
		// Create a new File object
		const file = new File([blob], fileName, { type: fileType });
		files.push(file);
	}
	return files;
}

function isValidBase64(base64Data: string): boolean {
	// Check if the string is correctly encoded as Base64
	try {
		return btoa(atob(base64Data)) === base64Data;
	} catch (error) {
		return false;
	}
}

function convertImage(base64: string, name = 'image'): Base64Image[] {
	return [
		{
			base64Data: base64,
			fileName: name,
			fileType: 'image/png',
		},
	];
}

export { base64ArrayToFiles, transformBase64Obj, convertImage };
