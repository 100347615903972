<section class="toggle-section">
	<section *ngIf="icon || title || subtext || tooltip" class="dropdown-item">
		<i *ngIf="icon" class="fa-regular" [ngClass]="icon"></i>
		<div class="text-group">
			<span *ngIf="title" [ngClass]="{ 'option-label': subtext }">
				{{ title }}
			</span>
			<small *ngIf="subtext" class="text-group__sub">
				{{ subtext }}
			</small>
		</div>
		<ga-helper
			*ngIf="tooltip"
			class="margin-adjust"
			[text]="tooltip"
			[icon]="'fa-solid fa-circle-info'"
			[iconSize]="12"
			[iconColor]="'#A3A3A3'"
			[tooltipLocale]="'above'"
		></ga-helper>
	</section>
	<mat-slide-toggle
		class="ga-toggle"
		[ngClass]="size"
		(change)="emitChange($event)"
		[(ngModel)]="checked"
		[disableRipple]="true"
	></mat-slide-toggle>
</section>
