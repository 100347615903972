import "stickyfilljs/dist/stickyfill.min";
import "angular-i18n/angular-locale_pt-br";
import "tether/dist/js/tether.min";
import "tether-shepherd/dist/js/shepherd.min";
import "moment-timezone/builds/moment-timezone-with-data-2012-2022.min";
import "moment/locale/pt-br";
import './vendors/highcharts';
import "file-saver/FileSaver.min";
import "numeral/locales/pt-br.js";
import "./vendors/textangular-setup";

import AppModule from './app/app.module';

import "./util"
import "./services";
import "./directives";

export default AppModule

