<div class="dialog" *ngIf="multiSelectorList.length !== 0">
	<div class="panel-heading" mat-dialog-title>
		<button type="button" class="close" (click)="dialogRef.close()">
			<span aria-hidden="true">&times;</span>
			<span class="sr-only">Close</span>
		</button>
		<h4 class="modal-title">Economia acumulada</h4>
	</div>
	<div class="modal-body" mat-dialog-content>
		<app-my-loader [lightMode]="true"></app-my-loader>
		<div class="row" style="margin-bottom: 10px">
			<div class="col-md-6">
				<div class="form-group">
					<ga-multi-selector
						title="Selecione instalações para visualizar ou comparar"
						[optionsList]="multiSelectorList"
						[limit]="2"
						[marked]="[multiSelectorList[0].id]"
						(selectEmitter)="changeInstallation($event)"
						[showSelectAllOption]="false"
					></ga-multi-selector>
				</div>
			</div>
			<div class="col-md-6 flex">
				<div class="col-flex">
					<label for="installations">Período</label>
					<app-date-range
						[isAutoEndDate]="true"
						(selectedDate)="onChangedDate($event)"
						[startDate]="startDate"
					></app-date-range>
				</div>
				<div class="col-flex">
					<button
						class="btn btn-default btn-color-ga"
						style="margin-top: 25px"
						(click)="applyChanges()"
					>
						{{ "projects.monthly-consume" | transloco }}
					</button>
				</div>
			</div>
		</div>
		<div class="row graphic">
			<div class="col-md-12 text-center" style="margin-bottom: 20px">
				<mat-button-toggle-group
					#group="matButtonToggleGroup"
					[value]="togglType"
					(change)="changeTogglType(group.value)"
				>
					<mat-button-toggle value="value">R$</mat-button-toggle>
					<mat-button-toggle value="consumption">kWh</mat-button-toggle>
				</mat-button-toggle-group>
			</div>
			<div class="col-md-12" style="text-align: -webkit-center">
				<highcharts-chart
					*ngIf="chartOptions"
					id="manual_chart"
					[Highcharts]="highcharts"
					[options]="chartOptions"
				>
				</highcharts-chart>
			</div>
		</div>
	</div>
</div>
