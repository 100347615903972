import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
	AbstractControl,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from 'webapp/app/auth/auth.service';
import { SnackService } from 'webapp/app/shared/snackbar/snackbar.service';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { setupDowngradeName } from 'webapp/hybrid-helpers/setupDowngradeName';

@Component({
	selector: 'app-new-password',
	templateUrl: './new-password.component.html',
	styleUrls: ['../login/login.component.scss'],
	host: {
		class: 'login',
	},
	encapsulation: ViewEncapsulation.None,
})
export class NewPasswordComponent implements OnInit {
	readonly currentDate = new Date();

	readonly splashPages = [
		'/assets/images/green_analitycs.png',
		'/assets/images/green_contratos.png',
	];
	readonly bgColors = ['#cccc99', '#7affbf'];

	confirmationForm = new FormGroup(
		{
			password: new FormControl('', {
				validators: [Validators.required, Validators.minLength(6)],
			}),
			passwordConfirmation: new FormControl('', [Validators.required]),
			token: new FormControl('', { validators: [Validators.required] }),
		},
		{ validators: NewPasswordComponent.equalTo }
	);

	splashPageURL: any;
	message: { error: boolean; msg: string } | undefined = undefined;

	constructor(
		@Inject(NG_ROUTER) private $state,
		@Inject(NG_STATE_PARAMS) private $stateParams,
		private i18n: TranslocoService,
		private authService: AuthService,
		private sanitize: DomSanitizer,
		private snackService: SnackService
	) {}

	ngOnInit() {
		const rand = Math.floor(Math.random() * this.splashPages.length);
		this.splashPageURL = this.sanitize.bypassSecurityTrustResourceUrl(
			this.splashPages[rand]
		);
		$('.embed-responsive.embed-responsive-image')
			.eq(0)
			.css('background-color', this.bgColors[rand]);

		this.confirmationForm.get('token')?.setValue(this.$stateParams.token);
	}

	static equalTo(group: AbstractControl): any {
		const password = <AbstractControl>group.get('password');
		const passwordConfirmation = <AbstractControl>(
			group.get('passwordConfirmation')
		);
		if (!password.value || !passwordConfirmation.value) {
			return false;
		}
		if (password.value !== passwordConfirmation.value) {
			return { passwordNotCompare: true };
		}
		return undefined;
	}

	changePassword() {
		this.message = undefined;
		if (!this.validateErrors()) {
			this.authService
				.newPassword(this.confirmationForm.value)
				.then((res) => {
					this.message = { error: false, msg: res.message };
					this.$state.go('login');
					this.snackService.showSnackBar(res.message, 'success');
				})
				.catch((err) => {
					const errMsg =
						err?.error?.error || err?.error?.message || err?.message || err;
					this.message = { error: true, msg: errMsg };
				});
		}
	}

	validateErrors(): boolean {
		let isError = false;
		const passwordNotCompare =
			this.confirmationForm.hasError('passwordNotCompare');
		if (this.confirmationForm.get('password')?.errors) {
			this.message = {
				error: true,
				msg: this.i18n.translate('signup.validation.password'),
			};
			isError = true;
		} else if (passwordNotCompare || !this.confirmationForm.valid) {
			this.message = {
				error: true,
				msg: this.i18n.translate('signup.validation.passwordConfirmation'),
			};
			isError = true;
		}
		return isError;
	}
}

export const ng2NewPassowordComponent = {
	name: setupDowngradeName(NewPasswordComponent.name),
	def: downgradeComponent({ component: NewPasswordComponent }),
};
