import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { environment } from 'webapp/environments/environment';
import { RecipientType } from './alert-form/alert-recipients/alert-recipients.model';
import {
	AlertList,
	AlertType,
	ConditionPeriod,
	SelectTypeOpt,
	SilencyObj,
} from './alert.model';
import { ColumnType } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { GASelectorLink } from 'webapp/app/shared/ga-datatable-new/model/ga-link.model';
import { GADropdownCustom } from 'webapp/app/shared/ga-datatable-new/model/dropdown-custom.model';
import { first, map } from 'rxjs/operators';

@Injectable({ providedIn: 'any' })
export class AlertService {
	$conditions = new BehaviorSubject<ConditionPeriod[]>([]);
	$conditionsDays = new BehaviorSubject<ConditionPeriod[]>([]);
	$conditionsHours = new BehaviorSubject<ConditionPeriod[]>([]);
	$recipients = new BehaviorSubject<RecipientType[]>([]);
	$resendHour = new BehaviorSubject<number | null>(null);
	alertsList: AlertType[] = [];

	constructor(
		private http: HttpClient,
		private notification: NotificationService,
		private i18n: TranslocoService
	) {}

	getAlerts(organizationId: number): Observable<AlertType[]> {
		let params: HttpParams = new HttpParams();
		params = params.append('organization', <number>organizationId);
		return this.http.get<AlertType[]>(environment.backendUrl + '/api/alerts', {
			params,
		});
	}

	getAlert(alertId): Observable<AlertType> {
		return this.http.get<AlertType>(
			environment.backendUrl + `/api/alerts/${alertId}`
		);
	}

	getAlertMeters(alertId) {
		return this.http.get(
			`${environment.backendUrl}/api/alerts/${alertId}/meters`
		);
	}

	deleteAlert(alert: AlertType): Observable<void> {
		return this.http.delete<void>(
			environment.backendUrl + `/api/alerts/${alert.id}`
		);
	}

	create(alert: AlertType): Observable<any> {
		return this.http.post(environment.backendUrl + '/api/alerts', alert);
	}

	update(alert: AlertType, id: number): Observable<any> {
		return this.http.put(environment.backendUrl + `/api/alerts/${id}`, alert);
	}

	getHoursMinutesResend() {
		const hoursMinutesResend: any = {} as any;

		hoursMinutesResend.hours = [];
		hoursMinutesResend.minutes = [];

		for (let i = 0; i < 25; i++) {
			hoursMinutesResend.hours.push(i);
		}

		for (let i = 0; i < 46; i += 15) {
			hoursMinutesResend.minutes.push(i);
		}
		return hoursMinutesResend;
	}

	populateUnit(condition: any) {
		if (condition.fact == 'activePower') {
			condition.unitMeasurement = ' kW';
		} else if (condition.fact == 'reactivePower') {
			condition.unitMeasurement = ' kVAr';
		} else if (
			condition.fact == 'invoiceConsumedEnergy' ||
			condition.fact == 'invoiceInjectedEnergy' ||
			condition.fact == 'invoiceActiveEnergy'
		) {
			condition.unitMeasurement = 'kWh';
		} else {
			condition.unitMeasurement = null;
		}
	}

	filterTable(data: any, filterText: any) {
		const filter = filterText.toLowerCase();
		return (
			data.address.toLowerCase().includes(filter) ||
			data.condition.toLowerCase().includes(filter) ||
			data.label.toLowerCase().includes(filter) ||
			data.schedule.toLowerCase().includes(filter) ||
			`${data.measurementPoint.length} Pontos de medição`
				.toLowerCase()
				.includes(filter) ||
			data.measurementPoint.some((ds) =>
				ds.label.toLowerCase().includes(filter)
			)
		);
	}

	getConditionLabels(allConditions: any[]): string[] {
		return allConditions.map((condition) => {
			if (condition.fact) {
				return (
					this.i18n.translate(`alerts.label.facts.${condition.fact}`) +
					' ' +
					this.i18n.translate(`alerts.label.operator.${condition.operator}`) +
					' ' +
					condition.value +
					' ' +
					condition.unitMeasurement
				);
			}
			return '';
		});
	}

	getConditionHoursAndDaysLabels(
		conditionsDays: any[],
		conditionsHours: any[]
	): string[] {
		return conditionsDays.map((day) => {
			let label = '';
			/* find attr 'every day' */
			if (day.value[0] === 'all') {
				label = this.i18n.translate('alerts.label.all');
			}
			/* find attr 'any time' */
			conditionsHours.map((hour) => {
				if (hour.value[0] === 'any') {
					label = label + ' ' + this.i18n.translate('alerts.label.any');
				}
			});

			return label;
		});
	}

	validateErrors(alertValue: AlertType) {
		const entries = Object.entries(alertValue);
		for (let i = 0; i < entries.length; i++) {
			/* need to check is false because of resend form can be null not false */
			if (entries[i][1] === '') {
				this.showSnackErrorMsg(entries[i][0]);
				return;
			}
		}
	}

	showSnackErrorMsg(msg: string) {
		this.notification.error({
			msg: this.i18n.translate('alerts.label.validation.' + msg + '.required'),
		});
	}

	transformMinuteToTime(interval: number): {
		days: number;
		hours: number;
		minutes: number;
	} {
		const resendPeriod = { days: 0, hours: 0, minutes: 0 };
		resendPeriod.days = Math.floor(interval / 60 / 24);
		resendPeriod.hours = Math.floor(
			(interval - resendPeriod.days * 24 * 60) / 60
		);
		resendPeriod.minutes = (interval - resendPeriod.days * 24 * 60) % 60;
		return resendPeriod;
	}

	get silenceArr(): SilencyObj[] {
		return [
			{ label: 'alerts.label.silence-popuver.hour', value: 1 },
			{ label: 'alerts.label.silence-popuver.eight', value: 8 },
			{ label: 'alerts.label.silence-popuver.twenty-four', value: 24 },
			{ label: 'alerts.label.silence-popuver.week', value: 168 },
			{ label: 'alerts.label.silence-popuver.indeterminated', value: null },
		];
	}

	get columns(): ColumnType[] {
		return [
			{
				label: 'alerts.label.table-columns.alert',
				type: 'text',
				name: 'label',
				width: '15%',
			},
			{
				label: 'alerts.label.table-columns.condition',
				type: 'custom',
				name: 'condition',
				width: '20%',
			},
			{
				label: 'alerts.label.table-columns.schedule',
				type: 'custom',
				name: 'schedule',
				width: '15%',
			},
			{
				label: 'alerts.label.table-columns.measurement-point',
				type: 'selectLink',
				name: 'measurementPoint',
				textCenter: true,
				width: '22%',
			},
			{
				label: 'alerts.label.table-columns.addressee',
				type: 'custom',
				name: 'address',
				width: '18%',
			},
			{
				label: 'alerts.label.table-columns.notification',
				type: 'dropdownCustom',
				name: 'reactivate',
				width: '10%',
			},
			{
				label: '',
				type: 'action',
				name: 'action',
				width: '45px',
			},
		];
	}

	get alertAction(): Action[] {
		return [
			{
				title: this.i18n.translate('global.edit'),
				icon: 'fa-edit',
				type: 'edit',
				permission: 'alertUpdate',
			},
			{
				title: this.i18n.translate('global.delete'),
				icon: 'fa-trash',
				type: 'delete',
				permission: 'alertDelete',
			},
		];
	}

	renderListToTable(list: AlertType[]) {
		const alertList: AlertList[] = [];
		list.forEach((item) => {
			const obj: AlertList = {} as AlertList;
			obj.id = <number>item.id;
			obj.active = item.active;
			obj.label = item.label;
			obj.condition = this.renderConditionToHtml(item.conditions);
			obj.schedule = this.renderDaysToHtml(item);
			obj.address = this.renderAddress(item.event);
			obj.measurementPoint = this.renderMeasurementPoints(item);
			obj.reactivate = this.renderDropdown(item);
			obj.action = this.alertAction;
			alertList.push(obj);
		});
		return alertList;
	}

	renderDropdown(item: AlertType): GADropdownCustom[] {
		const obj: GADropdownCustom[] = [];
		this.silenceArr.forEach((el) => {
			const dropItem: GADropdownCustom = {} as GADropdownCustom;
			dropItem.label = this.i18n.translate(el.label);
			dropItem.id = <number>item.id;
			dropItem.value = el.value;
			dropItem.permission = 'alertUpdate';
			obj.push(dropItem);
		});
		return obj;
	}

	renderConditionToHtml(conditions): string {
		const all = conditions.conditions.all;
		let text = '';
		all.forEach((item) => {
			text +=
				'<span>' +
				this.i18n.translate('alerts.label.facts.' + item.fact) +
				'</span>' +
				' ';
			text +=
				'<span>' +
				this.i18n.translate('alerts.label.operator.' + item.operator) +
				'</span>' +
				' ';
			text +=
				'<span>' +
				item.value +
				' ' +
				(item.unitMeasurement ?? '') +
				'</span>' +
				'<br>';
		});
		return text;
	}

	renderHoursToHtml(conditions) {
		const hours = conditions.conditionsHours.conditions.all;
		let text = '';
		hours.forEach((hour) => {
			if (hour.value[0] == 'any') {
				text +=
					'<span>' +
					this.i18n.translate('alerts.label.any') +
					'</span>' +
					'<br>';
			} else {
				hour.value.forEach((h, i) => {
					text += h;
					if (hour.value.length - 1 !== i) {
						text += ' e ';
					}
				});
			}
		});
		return text;
	}

	renderDaysToHtml(conditions) {
		const days = conditions.conditionsDays.conditions.all;
		let text = '';

		days.forEach((day) => {
			if (day.value[0] == 'all') {
				text =
					'<span>' +
					this.i18n.translate('alerts.label.day-short.all') +
					'</span>' +
					'<br>';
			} else {
				day.value.forEach((d, i) => {
					text +=
						'<span>' +
						this.i18n.translate('alerts.label.day-short.' + d) +
						' ' +
						'</span>';
					if (day.value.length - 1 === i) {
						text += '<br>';
					}
				});
			}
		});
		text += this.renderHoursToHtml(conditions);
		return text;
	}

	renderAddress(item) {
		let text = '';
		const addresses = item.params.actions;
		addresses.forEach((address) => {
			text += '<span>' + address.value + '</span>' + '<br>';
		});
		return text;
	}

	renderMeasurementPoints(alert: AlertType): GASelectorLink[] {
		const dropdownList: GASelectorLink[] = [];
		alert.meters.forEach((meter) => {
			const dropdownObj: GASelectorLink = {} as GASelectorLink;
			dropdownObj.placeholder = this.i18n.translate(
				'alerts.label.meter-popuver.title-btn-plural'
			);
			if (meter.uid)
				dropdownObj.subtext = `${this.i18n.translate('global.code')}: ${
					meter.uid
				}`;
			dropdownObj.label = meter.label;
			dropdownObj.value = meter.id;
			dropdownObj.link = 'main.dashboard.meter';
			dropdownObj.params = { uid: meter.id };
			dropdownList.push(dropdownObj);
		});
		return dropdownList;
	}

	findAlertObjById(id: number): AlertType {
		return <AlertType>this.alertsList.find((alert) => alert.id === id);
	}

	allTypes: SelectTypeOpt[] = [
		{
			id: 'invoiceConsumedEnergy',
			label: 'alerts.label.facts.invoiceConsumedEnergy',
		},
		{
			id: 'inductivePowerFactor',
			label: 'alerts.label.facts.inductivePowerFactor',
		},
		{
			id: 'capacitivePowerFactor',
			label: 'alerts.label.facts.capacitivePowerFactor',
		},
		{
			id: 'activePower',
			label: 'alerts.label.facts.activePower',
		},
		{
			id: 'reactivePower',
			label: 'alerts.label.facts.reactivePower',
		},
	];

	withoutInvoiceTypes: SelectTypeOpt[] = [
		{
			id: 'inductivePowerFactor',
			label: 'alerts.label.facts.inductivePowerFactor',
		},
		{
			id: 'capacitivePowerFactor',
			label: 'alerts.label.facts.capacitivePowerFactor',
		},
		{
			id: 'activePower',
			label: 'alerts.label.facts.activePower',
		},
		{
			id: 'reactivePower',
			label: 'alerts.label.facts.reactivePower',
		},
	];

	invoiceTypes: SelectTypeOpt[] = [
		{
			id: 'invoiceConsumedEnergy',
			label: 'alerts.label.facts.invoiceConsumedEnergy',
		},
	];

	operators: SelectTypeOpt[] = [
		{
			id: 'lessThan',
			label: 'alerts.label.operator.lessThan',
		},
		{
			id: 'lessThanInclusive',
			label: 'alerts.label.operator.lessThanInclusive',
		},
		{
			id: 'greaterThan',
			label: 'alerts.label.operator.greaterThan',
		},
		{
			id: 'greaterThanInclusive',
			label: 'alerts.label.operator.greaterThanInclusive',
		},
	];

	initBehavioursSubjects() {
		this.$conditions.next([]);
		this.$conditionsDays.next([]);
		this.$conditionsHours.next([]);
		this.$recipients.next([]);
		this.$resendHour.next(null);
	}

	translations(codeToTranslate: SelectTypeOpt[]): Observable<any> {
		const all = codeToTranslate.map((type) => type.label);
		return this.i18n.selectTranslate(all).pipe(
			first(),
			map((translations) =>
				codeToTranslate.map((code, i) => ({
					...code,
					label: translations[i],
				}))
			)
		);
	}

	translationsStr(values: string[]): Observable<any> {
		return this.i18n.selectTranslate(values).pipe(first());
	}
}

export const ng2AlertService = {
	name: AlertService.name,
	def: downgradeInjectable(AlertService),
};
