import cgNotify from "@cgross/angular-notify";
import * as angular from 'angular';
import * as ngCookies from 'angular-cookies';
import uiBootstrap from 'angular-ui-bootstrap';
import uiMask from 'angular-ui-mask';
import * as Highcharts from 'highcharts';
import * as numeral from 'numeral';
import * as uiToggle from '../vendors/angular-bootstrap-toggle';
import datePicker from '../vendors/angular-datepicker';
import angularTranslate from '../vendors/angular-translate';
import { uiRouter, uiRouterStateEvents, uiRouterStateHelper } from '../vendors/angular-ui-router';
import ngFileUpload from '../vendors/ng-file-upload';
import ngImageInputWithPreview from '../vendors/ng-image-input-with-preview';
import ngTagsInput from '../vendors/ng-tags-input';
import sly from '../vendors/scalyr';
import { ngSanitize, textAngular } from '../vendors/textangular-setup';
import AppKitModule from './appkit.module';
import DashboardDirectives from './dashboard-directives.module';
import DashboardFilter from './dashboard-filters.module';
import DashboardRoutes from './dashboard-routes.module';
import DashboardServices from './dashboard-services.module';
import * as ocLazyLoad from 'oclazyload';
import AuthModule from "./auth/auth.module";
import { ngHasPermission } from "./directives/has-permission/has-permission.directive";

const AppModule = angular.module('AppModule', [
	ocLazyLoad,
	//AppKitModule,
	AuthModule,
	uiRouter,
	uiRouterStateHelper,
	uiRouterStateEvents,
	DashboardRoutes,
	DashboardFilter,
	DashboardServices,
	DashboardDirectives,
	ngCookies,
	angularTranslate,
	datePicker,
	cgNotify,
	ngTagsInput,
	uiBootstrap,
	sly,
	uiToggle,
	ngImageInputWithPreview,
	ngSanitize,
	textAngular,
	uiMask,
	ngFileUpload
])

/*AppModule.factory('cacheInterceptor', ['$cacheFactory', '$timeout', function ($cacheFactory, $timeout) {
	//cache strategy based in this post https://stackoverflow.com/a/42410229/2241710
	var ttlMap = {};
	var serialize = function (obj) {
		var str = [];
		for (var p in obj)
			if (obj.hasOwnProperty(p)) {
				//@ts-ignore
				str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
			}
		return str.join("&");
	}
	return {
		request: function (config) {
			if (config.purge == true) {
				$cacheFactory.get('$http').remove(config.url + "?" + serialize(config.params));
				delete ttlMap[config.url + "?" + serialize(config.params)];
			}
			else if (config.ttl) {
				var ttl = config.ttl;
				delete config.ttl;
				config.cache = true;

				// If not in ttlMap then we set up a timer to delete, otherwise there's already a timer.
				if (!ttlMap[config.url]) {
					ttlMap[config.url + "?" + serialize(config.params)] = true;
					$timeout(ttl)
						.then(function () {
							$cacheFactory.get('$http').remove(config.url + "?" + serialize(config.params));
							delete ttlMap[config.url + "?" + serialize(config.params)];
						});
				}
			}
			return config;
		}
	};
}])*/

AppModule.config(['$httpProvider', function ($httpProvider) {
	$httpProvider.defaults.withCredentials = true;
	//$httpProvider.interceptors.push('cacheInterceptor');
	numeral.locale('pt-br');
}])

AppModule.config(['$translateProvider', function ($translateProvider) {
	//Localization config
	$translateProvider.useStaticFilesLoader({
		prefix: '/app/_l10n/',
		suffix: '.json'
	});
	$translateProvider.useSanitizeValueStrategy('sanitize');
	$translateProvider.preferredLanguage('pt_BR');
	$translateProvider.useCookieStorage();
}])

AppModule.config([
	'$locationProvider', '$compileProvider',
	function ($locationProvider, $compileProvider) {
		$compileProvider.debugInfoEnabled(false);
		$locationProvider.html5Mode({
			enabled: true,
			rewriteLinks: false
		}); //config urls mode
		$locationProvider.hashPrefix('*');
	}
]);

AppModule.run(['$rootScope', '$location', '$trace', '$http', '$cookies',
	function ($rootScope, $location, $trace, $http, $cookies) {
		//$trace.enable()
		$http.defaults.headers.post['X-CSRFToken'] = $cookies.csrftoken;
		$rootScope.theme = "theme-ga-1";

		if ($location.absUrl().indexOf("dashboard.greenant.com.br") > 0) {
			$rootScope.production = true;
		} else {
			$rootScope.production = false;
		}

		$rootScope.findVM = function (scope, vmVariableName) {
			if (scope[vmVariableName] != undefined) {
				return scope[vmVariableName];
			}
			else {
				return $rootScope.findVM(scope.$parent, vmVariableName);
			}
		}

		// $rootScope.$on('$stateChangeStart',
		// 	function (event, toState, toParams, fromState, fromParams, options) {
		// 		$rootScope.screen = toState.name;
		// 		//prevent black screen when click on "medições" menu twice
		// 		if ((fromState.name == "main.dashboard.meter" || fromState.name == "main.dashboard.group") && toState.name == "main.dashboard") {
		// 			event.preventDefault();
		// 		}
		// 	});

		Highcharts.setOptions({
			lang:{
				months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
				shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
				weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
				loading: 'Aguarde...',
				resetZoom: 'Desfazer Zoom',
			},	
			exporting: {
				enabled: false
			}
		});
	}]);

export default AppModule.name