import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment';
import { Observable } from 'rxjs';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { ColumnType } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import { GASelectorLink } from 'webapp/app/shared/ga-datatable-new/model/ga-link.model';
import { environment } from 'webapp/environments/environment';

const COMPLETE_MONTH_RATIO = 1;

@Injectable({
	providedIn: 'root',
})
export class KpiListService {
	kpis: any = [];
	calendarData!: any;

	constructor(private http: HttpClient, private i18n: TranslocoService) {}

	listKpis(params: any = {}): Observable<any> {
		return this.http.get<any>(`${environment.backendUrl}/api/kpis?`, {
			params,
		});
	}

	getKpiPoints(kpiId): Observable<any> {
		return this.http.get<any>(
			`${environment.backendUrl}/api/kpis/${kpiId}/kpi-points`,
			{
				params: this.calendarData,
			}
		);
	}

	deleteKpi(params): Observable<any> {
		return this.http.delete<any>(
			`${environment.backendUrl}/api/kpis/${params.id}`
		);
	}

	renderListToTable(kpis) {
		return kpis.map((kpi) => {
			const row = {} as any;
			row.id = kpi.id;
			row.metric = this.getMetric(kpi);
			row.dataSources = this.renderDataSource(kpi.dataSources);
			row.points = [];
			row.goal = this.goalUnitRender(kpi);
			row.indicator = this.genericIndicatorRender(kpi, { normalize: false });
			row.projection = this.genericIndicatorRender(kpi, { normalize: true });
			row.status = this.statusRender(kpi);
			row.action = this.getActions();
			return row;
		});
	}

	getMetric(row) {
		const by = row.divisor
			? ' ' +
			  this.i18n.translate('kpi.by') +
			  ' ' +
			  this.i18n.translate(`kpi.metrics.${row.divisor.type}`).toLowerCase()
			: '';
		return `${this.i18n.translate('kpi.metrics.' + row.dividend.type)}${by}`;
	}

	renderDataSource(dataSources): GASelectorLink[] {
		const dropdownList: GASelectorLink[] = [];

		dataSources.forEach((dataSource) => {
			const dropdownObj: GASelectorLink = {} as GASelectorLink;
			dropdownObj.label = dataSource.label;
			dropdownObj.value = dataSource.id;
			dropdownObj.placeholder =
				dataSources.length === 1
					? this.i18n.translate('kpi.table.datasource')
					: this.i18n.translate('kpi.table.datasources');
			if (dataSource.uid)
				dropdownObj.subtext = `${this.i18n.translate('global.code')}:
			${dataSource.uid}`;
			dropdownObj.link = 'main.dashboard.meter';
			dropdownObj.params = { uid: dataSource.id };
			dropdownList.push(dropdownObj);
		});

		return dropdownList;
	}

	goalUnitRender(row) {
		const valuesAvg = this.calculateIndicatorValue(row, {
			useAverages: true,
		});
		const goal = row.goal ? row.goal : valuesAvg;

		if (!goal)
			return {
				icon: 'fa fa-clock',
				tooltip: this.i18n.translate('kpi.table.status-info'),
				size: 16,
				locale: 'above',
			};

		const by = row.divisor
			? '/' +
			  (row.divisor.unit
			  	? row.divisor.unit
			  	: this.i18n.translate('kpi.metrics.' + row.divisor.type))
			: '';
		return {
			unit: `${Intl.NumberFormat('pt-BR', {
				maximumFractionDigits: 2,
			}).format(goal)} ${row.dividend.unit}${by}`,
		};
	}

	get columns(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('kpi.table.metric'),
				name: 'metric',
				type: 'text',
				width: '25%',
			},
			{
				label: this.i18n.translate('kpi.table.datasource'),
				name: 'dataSources',
				type: 'selectLink',
				sortBy: (item: any, property: string) => item[property][0].label,
				width: '20%',
			},
			{
				label: this.i18n.translate('kpi.table.goal'),
				name: 'goal',
				type: 'unit',
				tooltip: this.i18n.translate('kpi.table.goal-info'),
				width: '15%',
				sortBy: (item: any, property: string) => item[property].length,
			},
			{
				label: this.i18n.translate('kpi.table.indicator'),
				name: 'indicator',
				type: 'unit',
				width: '15%',
			},
			{
				label: this.i18n.translate('kpi.table.indicator-projection'),
				name: 'projection',
				type: 'unit',
				tooltip: this.i18n.translate('kpi.table.projection-info'),
				width: '15%',
			},
			{
				label: this.i18n.translate('kpi.table.status'),
				name: 'status',
				type: 'unit',
				width: '10%',
			},
			{
				label: '',
				name: 'action',
				type: 'action',
				width: '45px',
			},
		];
	}

	getActions(): Action[] {
		const actions: Action[] = [
			{
				icon: 'fa-edit',
				title: this.i18n.translate('global.edit'),
				type: 'edit',
				permission: 'kpiUpdate',
			},
			{
				icon: 'fa-trash',
				title: this.i18n.translate('global.delete'),
				type: 'delete',
				permission: 'kpiDelete',
			},
		];
		return actions;
	}

	genericIndicatorRender(row, { normalize = false }) {
		if (!row.points || row.points.length == 0)
			return {
				icon: 'fa fa-clock',
				tooltip: this.i18n.translate('kpi.table.status-info'),
				size: 16,
				locale: 'above',
			};

		const indicatorValue = this.calculateIndicatorValue(row, {
			useAverages: false,
			normalize,
		});
		const completeKPI = this.kpis.find((k) => k.id == row.id);
		const by = completeKPI.divisor
			? '/' +
			  (completeKPI.divisor.unit
			  	? completeKPI.divisor.unit
			  	: this.i18n.translate('kpi.metrics.' + completeKPI.divisor.type))
			: '';
		return {
			unit: `<span>${Intl.NumberFormat('pt-BR', {
				maximumFractionDigits: 2,
			}).format(indicatorValue)} ${completeKPI.dividend.unit}${by}</span>`,
		};
	}

	calculateIndicatorValue(row, { useAverages, normalize = false }): number {
		const numPoints = row?.points?.length;
		if (numPoints == 0) {
			return 0;
		}

		let indicator = row?.points?.reduce((acc, cur) => {
			return acc + (useAverages ? cur.kpiPointAverage : cur.value);
		}, 0);

		if (normalize) {
			indicator = indicator / this.calculateElapsedMonthPercentage();
		}

		return indicator / numPoints;
	}

	calculateElapsedMonthPercentage() {
		return this.isCalInCurrentMonth()
			? moment().date() / moment().daysInMonth()
			: COMPLETE_MONTH_RATIO;
	}

	isCalInCurrentMonth() {
		const calEndDate = moment(this.calendarData.endDate);
		return moment().isSameOrBefore(calEndDate, 'month');
	}

	statusRender(row) {
		if (!row.points || row.points.length == 0)
			return {
				icon: 'fa fa-clock',
				tooltip: this.i18n.translate('kpi.table.status-info'),
				size: 16,
				locale: 'above',
			};
		const indicator = this.calculateIndicatorValue(row, {
			useAverages: false,
			normalize: true,
		});
		const avgIndicator = this.calculateIndicatorValue(row, {
			useAverages: true,
		});
		const completeKPI = this.kpis.find((k) => k.id == row.id);
		const goal = completeKPI.goal ? completeKPI.goal : avgIndicator;

		const status = indicator / goal;

		if (Number.isNaN(status)) {
			return '-';
		}
		if (status <= 1) {
			return {
				unit: `<span class="status-percent" style="color: var(--success---success-500)">
			${Intl.NumberFormat('pt-BR').format(Math.round(status * 100))}%
			</span>`,
			};
		} else {
			return {
				unit: `<span class="status-percent" style="color: var(--error---error-500)">
			${Intl.NumberFormat('pt-BR').format(Math.round(status * 100))}%
			</span>`,
			};
		}
	}

	filterTable(data: any, value: any) {
		const generalFilter = value.find((v) => v.column == 'all');
		const columnFilters = value.filter((v) => v.column !== 'all');
		let filterAll = true;
		let findInColumn = true;

		if (generalFilter && generalFilter.value) {
			const columnValue = generalFilter.value.toLowerCase();
			filterAll =
				data.metric.toLowerCase().includes(columnValue) ||
				`${data.dataSources.length} Pontos de medição`
					.toLowerCase()
					.includes(generalFilter.value) ||
				data.dataSources.some((ds) =>
					ds.label.toLowerCase().includes(columnValue)
				) ||
				data.goal.unit?.toLowerCase().includes(columnValue) ||
				data.indicator.unit?.toLowerCase().includes(columnValue) ||
				data.projection.unit?.toLowerCase().includes(columnValue) ||
				data.status.unit?.toLowerCase().includes(columnValue);
		}
		findInColumn =
			!columnFilters.length ||
			columnFilters.every((filter) => !filter.value.length) ||
			(columnFilters &&
				columnFilters[0].value.some((value) => {
					if (value === 'consumo') {
						return data[columnFilters[0].column].toLowerCase() === value;
					} else {
						return data[columnFilters[0].column].toLowerCase().includes(value);
					}
				}));

		return filterAll && findInColumn;
	}
}
