import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@repo/projects/webapp/global/material/material.module';
import { GaContentDialogComponent } from './ga-content-dialog.component';
import { GaTagIconModule } from '../ga-tag-icon/ga-tag-icon.module';
import { TranslocoModule } from '@ngneat/transloco';
import { GaLoadingModule } from '../ga-loading/ga-loading.module';

@NgModule({
	declarations: [GaContentDialogComponent],
	imports: [
		CommonModule,
		MaterialModule,
		GaTagIconModule,
		GaLoadingModule,
		TranslocoModule,
	],
	exports: [GaContentDialogComponent],
	providers: [],
})
export class GaContentDialogModule {}
