import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslocoModule } from '@ngneat/transloco';
import { AutocompleteDistributorsComponent } from './distributors/autocomplete-distributors.component';
import { AutocompleteOrgsComponent } from './organizations/autocomplete-orgs.component';
import { AutocompleteTariffsComponent } from './tariffs/autocomplete-tariffs.component';
import { AutocompleteUcsComponent } from './ucs/autocomplete-ucs.component';
import { AutocompleteCompaniesComponent } from './companies/autocomplete-companies.component';
import { MaterialModule } from '@repo/projects/webapp/global/material/material.module';

@NgModule({
	declarations: [
		AutocompleteDistributorsComponent,
		AutocompleteOrgsComponent,
		AutocompleteTariffsComponent,
		AutocompleteUcsComponent,
		AutocompleteCompaniesComponent,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatAutocompleteModule,
		TranslocoModule,
		MaterialModule,
	],
	exports: [
		AutocompleteDistributorsComponent,
		AutocompleteOrgsComponent,
		AutocompleteTariffsComponent,
		AutocompleteUcsComponent,
		AutocompleteCompaniesComponent,
	],
})
export class AutoCompleteModule {}
