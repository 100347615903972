import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { MaterialModule } from '@repo/projects/webapp/global/material/material.module';
import { AcceptTermsComponent } from './accept-terms/accept-terms-notification';
import { NotificationService } from './notification.service';
import { ResponseNotificationComponent } from './response/response-notification';

@NgModule({
	declarations: [ResponseNotificationComponent, AcceptTermsComponent],
	exports: [ResponseNotificationComponent, AcceptTermsComponent],
	imports: [CommonModule, MaterialModule, TranslocoModule],
	providers: [NotificationService],
})
export class NotificationModule {}
