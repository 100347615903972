import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { StateParams, StateService } from 'angular-ui-router';
import { Subscription, from } from 'rxjs';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { ProinfaListService } from '../proinfa-list/proinfa-list.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { DistributorService } from 'webapp/app/shared/services/distributor.service';
import { TranslocoService } from '@ngneat/transloco';
import { GaDateRangePickerService } from 'webapp/app/shared/ga-date-range-picker/service/ga-date-range-picker.service';

@Component({
	selector: 'proinfa-details',
	templateUrl: './proinfa-details.component.html',
	styleUrls: ['./proinfa-details.component.scss'],
})
export class ProinfaDetailsComponent implements OnInit, OnDestroy {
	organization: number | undefined;
	subscriptions: Subscription[] = [];
	type: string | undefined = '';
	title: string | undefined = '';
	subtitle: string | undefined = '';
	isSaving = false;
	proinfaForm!: FormGroup;
	defaultDistributor!: any;
	dateRange = new FormControl();
	formattedDate = new FormControl();
	months: string[] = [
		'jan',
		'feb',
		'mar',
		'apr',
		'may',
		'jun',
		'jul',
		'aug',
		'sep',
		'oct',
		'nov',
		'dec',
	];

	constructor(
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		private datePipe: DatePipe,
		private i18n: TranslocoService,
		private notification: NotificationService,
		private proinfaListService: ProinfaListService,
		private distributorService: DistributorService,
		private datePickerService: GaDateRangePickerService
	) {}

	ngOnInit(): void {
		this.setTitle();
		this.initFormGroup();
		this.getDefaultDistributor();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
	}

	getDefaultDistributor() {
		const defaultId = 0;
		const subscription = from(this.distributorService.getDistributor(defaultId))
			.pipe(
				tap((distributor) => {
					this.defaultDistributor = distributor;
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	initFormGroup() {
		const group: any = {
			organization: new FormControl(''),
			name: new FormControl(''),
		};

		switch (this.type) {
		case null: {
			this.initDates(group);
			this.initEnergyVolume(group);
			group.tariffType = new FormControl(7);
			group.distributorId = new FormControl('');
			break;
		}
		case 'edit': {
			group.id = new FormControl('');
			break;
		}
		default:
			group.id = new FormControl('');
			group.organization.disable();
			group.name.disable();
			this.initDates(group);
			this.initEnergyVolume(group);
			break;
		}

		this.proinfaForm = new FormGroup(group);
	}

	initDates(ObjectForm) {
		ObjectForm.startDate = new FormControl('');
		ObjectForm.endDate = new FormControl('');
	}

	initEnergyVolume(ObjectForm) {
		ObjectForm.fields = new FormGroup({
			'proinfa-1': new FormControl(''),
			'proinfa-2': new FormControl(''),
			'proinfa-3': new FormControl(''),
			'proinfa-4': new FormControl(''),
			'proinfa-5': new FormControl(''),
			'proinfa-6': new FormControl(''),
			'proinfa-7': new FormControl(''),
			'proinfa-8': new FormControl(''),
			'proinfa-9': new FormControl(''),
			'proinfa-10': new FormControl(''),
			'proinfa-11': new FormControl(''),
			'proinfa-12': new FormControl(''),
		});
	}

	orgSelected(event: { id: number; name: string }) {
		this.organization = event?.id;
		this.proinfaForm.controls['organization'].setValue(event?.id);
	}

	backTo() {
		this.$state.transitionTo(
			'contracts',
			{ tab: 'proinfa', organizationId: this.organization },
			{
				inherit: false,
				reload: false,
				notify: false,
			}
		);
	}

	setTitle() {
		this.type = this.$stateParams['#'];
		switch (this.type) {
		case null: {
			this.title = 'proinfa-list.new-proinfa';
			this.subtitle = 'proinfa-list.new-proinfa-sub';
			break;
		}
		case 'edit': {
			this.title = 'proinfa-list.edit-proinfa';
			this.subtitle = 'proinfa-list.edit-proinfa-sub';
			this.getTariff(this.$stateParams['id']);
			break;
		}
		case 'new-cycle': {
			this.title = 'proinfa-list.new-cycle';
			this.subtitle = 'proinfa-list.new-cycle-sub';
			this.getTariff(this.$stateParams['id']);
			break;
		}
		case 'edit-cycle': {
			this.title = 'proinfa-list.edit-cycle';
			this.subtitle = 'proinfa-list.edit-cycle-sub';
			this.getTariff(
				this.$stateParams['id'],
				this.$stateParams['instanceId']
			);
			break;
		}
		case 'clone': {
			this.title = 'proinfa-list.new-cycle';
			this.subtitle = 'proinfa-list.new-cycle-sub';
			this.getTariff(
				this.$stateParams['id'],
				this.$stateParams['instanceId']
			);
			break;
		}
		default:
			break;
		}
	}

	changeDate() {
		const startDate = this.datePipe.transform(
			this.dateRange.value.start,
			'yyyy-MM-ddTHH:mm:ssZ'
		);
		this.proinfaForm.controls['startDate'].setValue(startDate);

		const endDate = this.datePipe.transform(
			this.dateRange.value.end,
			'yyyy-MM-ddTHH:mm:ssZ'
		);
		this.proinfaForm.controls['endDate'].setValue(endDate);
	}

	getTariff(id, instanceId = null) {
		const subscription1 = this.proinfaListService
			.getTariffs({ id })
			.pipe(
				tap((response) => {
					const fields = ['organization', 'id', 'name'];
					fields.forEach((field) => {
						this.proinfaForm.controls[field].patchValue(response.tariff[field]);
						this.proinfaForm.controls[field].updateValueAndValidity();
					});
				})
			)
			.subscribe();
		this.subscriptions.push(subscription1);

		if (!instanceId) return;

		const subscription2 = this.proinfaListService
			.getTariffInstances({
				id,
				instanceId,
			})
			.pipe(
				tap((response) => {
					const fields = ['startDate', 'endDate', 'tariff', 'name', 'fields'];
					fields.forEach((field) => {
						switch (field) {
						case 'tariff': {
							this.proinfaForm.controls['id'].patchValue(
								response.tariffInstance[field]
							);
							break;
						}
						case 'fields': {
							Object.keys(response.tariffInstance[field]).forEach((key) => {
								this.proinfaForm
									.get('fields.' + key)
									?.patchValue(response.tariffInstance[field][key]);
							});
							break;
						}
						default: {
							this.proinfaForm.controls[field].patchValue(
								response.tariffInstance[field]
							);
							break;
						}
						}
					});
					const startDate = new Date(this.proinfaForm.value.startDate);
					const endDate = new Date(this.proinfaForm.value.endDate);

					this.formattedDate.setValue(
						this.datePickerService.formatRange(startDate, endDate, 'custom')
					);
				})
			)
			.subscribe();
		this.subscriptions.push(subscription2);
	}

	onSubmit() {
		this.isSaving = true;
		switch (this.type) {
		case null: {
			this.createProinfa();
			break;
		}
		case 'edit': {
			this.editProinfa();
			break;
		}
		case 'new-cycle': {
			this.saveCycle({
				tariff: this.proinfaForm.controls['id'].value,
				startDate: this.proinfaForm.controls['startDate'].value,
				endDate: this.proinfaForm.controls['endDate'].value,
				fields: this.proinfaForm.controls['fields'].value,
			});
			break;
		}
		case 'edit-cycle': {
			this.saveCycle({
				id: this.$stateParams['instanceId'],
				tariff: this.proinfaForm.controls['id'].value,
				startDate: this.proinfaForm.controls['startDate'].value,
				endDate: this.proinfaForm.controls['endDate'].value,
				fields: this.proinfaForm.controls['fields'].value,
			});
			break;
		}
		case 'clone': {
			this.saveCycle({
				tariff: this.proinfaForm.controls['id'].value,
				startDate: this.proinfaForm.controls['startDate'].value,
				endDate: this.proinfaForm.controls['endDate'].value,
				fields: this.proinfaForm.controls['fields'].value,
			});
			break;
		}
		default:
			break;
		}
	}

	createProinfa() {
		this.proinfaForm.controls['distributorId'].setValue(
			this.defaultDistributor
		);
		const subscription = this.proinfaListService
			.createTariff(this.proinfaForm.value)
			.pipe(
				tap((response) => {
					this.saveCycle(
						{
							tariff: response.tariff.id,
							startDate: this.proinfaForm.controls['startDate'].value,
							endDate: this.proinfaForm.controls['endDate'].value,
							fields: this.proinfaForm.controls['fields'].value,
						},
						'proinfa-list.save-success'
					);
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return error;
				}),
				finalize(() => (this.isSaving = false))
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	editProinfa() {
		const subscription = this.proinfaListService
			.saveTariff(this.proinfaForm.value)
			.pipe(
				tap(() => {
					this.notification.success({
						msg: this.i18n.translate('proinfa-list.save-success'),
					});
					this.backTo();
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return error;
				}),
				finalize(() => (this.isSaving = false))
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	saveCycle(bodyCicle, proinfamessage = '') {
		const functionName = bodyCicle.id
			? 'saveTariffInstance'
			: 'createTariffInstance';
		const subscription = this.proinfaListService[functionName](bodyCicle)
			.pipe(
				tap(() => {
					this.notification.success({
						msg: this.i18n.translate(
							proinfamessage || 'proinfa-list.cycle-success'
						),
					});
					this.backTo();
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return error;
				}),
				finalize(() => (this.isSaving = false))
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}
}

export const ng2ProinfaDetailsComponent = {
	name: 'proinfaDetails',
	def: downgradeComponent({
		component: ProinfaDetailsComponent,
		propagateDigest: true,
	}),
};
