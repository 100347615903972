import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpCacheManager } from '@ngneat/cashew';
import { DateTime } from 'luxon';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'webapp/environments/environment';
import { Login } from './login.model';

@Injectable({ providedIn: 'any' })
export class AuthService {
	private token: string | null = null;
	/* initialized out of constructor to not broken auth.spec  */
	private manager!: HttpCacheManager;

	constructor(
		private http: HttpClient,
		private cookies: CookieService,
		private injector: Injector
	) {}

	get cookieOptions(): any {
		return {
			path: '/',
		};
	}

	saveAuthCookie(token, personificate = false, keepConnected = false) {
		const options = this.cookieOptions;

		if (personificate) {
			this.cookies.set('original_auth', this.cookies.get('auth'), options);
		}
		if (keepConnected) {
			options.expires = DateTime.now().plus({ months: 3 }).toJSDate();
		}
		this.cookies.set('auth', token, options);
	}

	saveUserEmail(email, personificate = false) {
		if (personificate) {
			this.cookies.set(
				'original_email',
				this.cookies.get('email'),
				this.cookieOptions
			);
		}

		this.cookies.set('email', email, this.cookieOptions);
	}

	getUserEmail(emailKey = 'email') {
		return this.cookies.get(emailKey);
	}

	getAuthCookie(authKey = 'auth') {
		return this.cookies.get(authKey);
	}

	isAuthenticated(authKey = 'auth') {
		return this.cookies.check(authKey);
	}

	isPersonificated() {
		return this.cookies.check('original_auth');
	}

	deleteAuthCookie() {
		this.cookies.delete('auth');
		this.cookies.delete('original_auth');
		this.cookies.delete('email');
		this.cookies.delete('original_email');
		sessionStorage.clear();
	}

	setRedirectUrlCookie(url) {
		if (url) {
			this.cookies.set('redirectUrl', url, this.cookieOptions);
		}
	}

	popRedirectUrlCookie() {
		const redirectUrl = this.cookies.get('redirectUrl');
		this.cookies.delete('redirectUrl');
		return redirectUrl;
	}

	async auth(userParam) {
		if (this.isAuthenticated()) {
			this.token = this.getAuthCookie();
			return { token: this.token };
		} else {
			const params = userParam;
			//get token by cookie
			return await this.login(params);
		}
	}

	personificate(userParam) {
		const token = userParam.token || this.getAuthCookie();
		const keepCookies =
			userParam.keepCookies != undefined ? userParam.keepCookies : true;

		const params = {
			email: userParam.email,
			password: '',
			token: token,
			keepCookies: keepCookies,
		};

		//get token by cookie
		return this.login(params);
	}

	despersonificate() {
		const originalToken = this.getAuthCookie('original_auth');
		const originalEmail = this.getAuthCookie('original_email');
		/*
			bug from library cookie service,
			random delete cookie, set first date the cookie is automaticated deleted
		*/
		this.cookies.set(
			'original_auth',
			'',
			new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
			'/'
		);
		this.cookies.set(
			'original_email',
			'',
			new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
			'/'
		);
		return this.personificate({
			email: originalEmail,
			token: originalToken,
			keepCookies: false,
		});
	}

	login(body: { email; password; keepCookies?; keepConnected?; token? }) {
		return (
			this.http
				.post<Login>(environment.backendUrl + '/api/login', body)
				.toPromise()
				//TODO: Remover quando a req passar a emitir 400 e não 200 ao falhar
				.then((data) => {
					if (!data?.user) throw data;
					return data;
				})
				.then((data) => {
					if (data?.user) {
						this.token = data.token;
						this.saveAuthCookie(
							this.token,
							body.keepCookies,
							body.keepConnected
						);
						this.saveUserEmail(data.user.email, body.keepCookies);
					}
					return data;
				})
		);
	}

	logout(param?: any): Promise<any> {
		this.manager = this.injector.get(HttpCacheManager);
		this.deleteAuthCookie();
		this.token = '';
		if (this.cookies.check('original_auth')) {
			return this.http
				.post(environment.backendUrl + '/api/logout', param?.user)
				.toPromise()
				.then(() => {
					return true;
				});
		} else {
			return this.http
				.post(environment.backendUrl + '/api/logout', param?.user)
				.toPromise()
				.then(() => {
					this.manager.clear();
				});
		}
	}

	forgot(userParam) {
		const params = { user: userParam };
		return this.http
			.post(environment.backendUrl + '/api/forgot', params.user)
			.toPromise() as Promise<any>;
	}

	signup(userParam) {
		const params = { user: userParam };
		return this.http
			.post(environment.backendUrl + '/api/signup', params.user)
			.toPromise() as Promise<any>;
	}

	active(token) {
		const params = { token: token };
		return this.http
			.post(environment.backendUrl + '/api/active', { token: params.token })
			.toPromise() as Promise<any>;
	}

	newPassword(user) {
		const params = { user: user };
		return this.http
			.post(environment.backendUrl + '/api/newPassword', params.user)
			.toPromise() as Promise<any>;
	}
}

export const ng2AuthService = {
	name: AuthService.name,
	def: downgradeInjectable(AuthService),
};
