<div id="distributor" class="form-group">
	<label for="distributor">{{ "regulated-list.type-distr" | transloco }}</label>
	<input
		type="text"
		class="form-control"
		[matAutocomplete]="auto"
		[formControl]="distributorInput"
		[ngClass]="{
			'has-error': distributorInput.invalid && distributorInput.touched
		}"
	/>
	<mat-autocomplete
		#auto="matAutocomplete"
		(optionSelected)="getOptionSelected($event)"
	>
		<mat-option
			*ngFor="let option of filteredDistributors$ | async"
			[value]="option.name"
			[id]="option.id"
		>
			{{ option.name }}
		</mat-option>
	</mat-autocomplete>
</div>
