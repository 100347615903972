<label *ngIf="title">
	<span class="mr-5">{{ title }}</span>
	<span *ngIf="required" class="required mr-5">*</span>
	<ga-helper
		*ngIf="tooltip"
		[text]="tooltip"
		[icon]="'fa-solid fa-circle-info'"
		[iconSize]="12"
		[iconColor]="'#A3A3A3'"
		[tooltipLocale]="'above'"
	></ga-helper>
</label>
<div class="group-section" [ngClass]="{ 'ga-input-error': showError }">
	<span *ngIf="lAddon" class="input-group-addon">
		<i class="laddon-icon fa-regular" [ngClass]="lAddon"></i>
	</span>
	<input
		class="form-control"
		id="typeahead-{{ id }}"
		(input)="listItems($event)"
		[disabled]="disabled"
		[placeholder]="placeholder"
		(blur)="onTouch()"
		[type]="type"
		[ngClass]="{ 'has-error': showError, hasRAddon: rAddon, hasLAddon: lAddon }"
		[value]="val"
		matAutocompletePosition="below"
		[matAutocomplete]="auto"
	/>
	<span *ngIf="rAddon" class="input-group-addon">{{ rAddon }}</span>
</div>
<mat-error *ngIf="showError">{{
	error || ("global.field-required" | transloco)
}}</mat-error>
<mat-autocomplete #auto="matAutocomplete">
	<mat-option
		*ngFor="
			let item of externalget ? list : (filteredOptions | slice : 0 : limitTo);
			let i = index
		"
		(click)="emitChange(item)"
		[value]="item"
	>
		{{ item }}
	</mat-option>
</mat-autocomplete>
