import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { NavTab } from '../models/selector.model';

@Component({
	selector: 'ga-navigation-tabs',
	templateUrl: './ga-navigation-tabs.component.html',
	styleUrls: ['./ga-navigation-tabs.component.scss'],
})
export class GaNavigationTabsComponent implements OnInit {
	@Input() tabList: Array<NavTab> = [];
	@Input() currentTab = '';
	@Input() hasBorderBottom = true;
	@Output() setTab = new EventEmitter();
	selectedIndex = 0;

	ngOnInit(): void {
		this.selectedIndex = this.tabList.findIndex(
			(tab: NavTab) => tab.name == this.currentTab
		);
	}

	selectedTabChange(event) {
		this.setTab.emit(this.tabList[event.index].name);
	}
}

export const ng2GaNavigationTabsComponent = {
	name: 'gaNavigationTabs',
	def: downgradeComponent({
		component: GaNavigationTabsComponent,
		propagateDigest: true,
	}),
};
