import { AbstractChart } from "../common/abstract-chart.component";
import * as Highcharts from "highcharts";
import * as moment from "moment";

let vm;
const comparativeChartComponent = {
  bindings: {
    sourceObject: "<?",
    sourceType: '<?',
    timezone: '<?'
  },
  templateUrl: '/app/directives/comparative-chart/comparative-chart.html',
  controllerAs: 'comparativeVm',
  controller: class ComparativeChart extends AbstractChart {
    constructor(CalendarService, $element, DataSourceService, Auxiliar, $filter, $scope, notify, $location, $translate) {
      super("comparativeChart", CalendarService, notify);
      this.$element = $element;
      this.dataSourceService = DataSourceService;
      this.auxiliar = Auxiliar;
      this.$filter = $filter;
      this.$scope = $scope;
      this.$location = $location;
      this.$translate = $translate;
      this.totals = {savings: {value: 0}, percent: 0};
      this.hasBill = false;
      const colorElement = document.querySelector(':root');
      const cssStyles = getComputedStyle(colorElement);
      this.chartColor = String(cssStyles.getPropertyValue('--dataviz---lt--green')).trim();
      this.acrColor = String(cssStyles.getPropertyValue('--dataviz---md--blue')).trim();
      this.grey5 = String(cssStyles.getPropertyValue('--grayscale---gray-500')).trim();
      this.grey4 = String(cssStyles.getPropertyValue('--grayscale---gray-400')).trim();
      this.generationColor = String(cssStyles.getPropertyValue('--alert---alert-500')).trim();
      this.missingBill = false;
      vm = this;
    }


    getData() {
      this.showLoading();
      const vm = this;
      let params = this.buildRequestParams();
      vm.series = [];
      vm.chartPeriod = vm.dates.period;
      
      params.items = ['totals', 'energies'];
      params.billsMode = true;
      params.startDate = params.startDate || vm.dates.startDate.split('T')[0];
      params.endDate = params.endDate || vm.dates.endDate.split('T')[0];
      vm.dataSourceService.dataSourceTotalComparative(params).then( totals => {
        if(totals){
          totals.savings = { value: null, percent: null };
          vm.hasBill = totals.commonBills && totals.reprocessedBills;
          if(Array.isArray(totals.commonTotals.totals) && totals.commonTotals.totals.length > 0 && Array.isArray(totals.mainTariffTotals.totals)){
            vm.commonTotalsValue = totals.commonTotals.totals.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
            vm.mainTariffTotalsValue = totals.mainTariffTotals.totals.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
            totals.savings.value = (vm.mainTariffTotalsValue - vm.commonTotalsValue);
            totals.savings.percent = isNaN(Math.round( (1 - (vm.commonTotalsValue / vm.mainTariffTotalsValue)) * 100 )) ? 0 : Math.round( (1 - (vm.commonTotalsValue / vm.mainTariffTotalsValue)) * 100 );
  
            if(vm.hasBill && Array.isArray(totals.commonBills.totals) && totals.commonBills.totals.length > 0 && Array.isArray(totals.reprocessedBills.totals)){
              totals.billSavings = { value: null };
              totals.commonBills.totals.sort(function(a,b){
                if(moment(a.date).isBefore(moment(b.date))){
                  return -1;
                }
                else{
                  return 1;
                }
              })
              totals.reprocessedBills.totals.sort(function(a,b){
                if(moment(a.date).isBefore(moment(b.date))){
                  return -1;
                }
                else{
                  return 1;
                }
              })
              vm.commonBillTotalsValue = totals.commonBills.totals.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
              vm.reprocessedBillsTotalsValue = totals.reprocessedBills.totals.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
              totals.billSavings.value = (vm.reprocessedBillsTotalsValue - vm.commonBillTotalsValue);
              totals.billSavings.percent = Math.round( (1 - (vm.commonBillTotalsValue / vm.reprocessedBillsTotalsValue)) * 100 );
            }
            
            vm.totals = totals;
            vm.$scope.$apply();
            vm.selectChart();
          }
        }
        
        vm.hideLoading();
        //const commonTotalsValue = totals.commonTotals.totals.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
        //const mainTariffTotalsValue = totals.mainTariffTotals.totals.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);

      });
    }

    selectChart(){
      const vm = this;
      let commonValue, reprocessedValue;
      if(!vm.hasBill){
        commonValue = vm.commonTotalsValue;
        reprocessedValue = vm.mainTariffTotalsValue;
      }
      else{
        commonValue = vm.commonBillTotalsValue;
        reprocessedValue = vm.reprocessedBillsTotalsValue;
      }
      if(vm.chartPeriod === "year"){
        vm.drawYearChart('commonTotals', 'mainTariffTotals', 'commonBills', 'reprocessedBills');
      } else {
        vm.drawChart(commonValue, reprocessedValue);
      }
      this.hideLoading();

    }

    drawChart(commonTotalsValue, mainTariffTotalsValue) {

      //Set Data
      vm.series = [ {
        name: "ml",
        label: vm.$translate.instant('main.comparative.charts-categories-ML'),
        data: {color: vm.chartColor, y: commonTotalsValue},
        color: vm.chartColor,
        enabled: true,
      },{
        name: "acr",
        label: vm.$translate.instant('main.comparative.charts-categories-ACR'),
        data: {color: vm.acrColor, y: mainTariffTotalsValue},
        color: vm.acrColor,
        enabled: true,
      }];
      let currency = 'R$';

      this.chart = Highcharts.chart(this.$element.find('.chart')[0], {
        chart: {
          type: 'bar',
          marginRight: 4,
          events: {
            load: function() {
              const chart = this,
                points = chart.series[0].data,
                options = {
                  dataLabels: {
                    x: 5,
                    style: {
                      color: 'var(--grayscale---gray-600)',
                    }
                  }
                };
      
              points.forEach(function(point) {
                if (point.shapeArgs.height < 100) {
                  point.update(options, false);
                }
              });
      
              chart.redraw();
            }
          }
        },
        tooltip: {
          enabled: false
        },
        xAxis: {
          categories: [vm.$translate.instant('main.comparative.charts-categories-ML'), vm.$translate.instant('main.comparative.charts-categories-ACR')]
        },
        yAxis: {
            min: 0,
            visible: true,
            tickAmount: 4,
            endOnTick: false,
            title: null,
            labels: {
              enabled: false
            }

        },
        legend: {
          enabled: false
        },
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          bar: {
              dataLabels: {
                  enabled: true, 
                  formatter: function(){ return `${currency} <b>${vm.$filter('formatMoney')(this.y, '', 2)}</b>` },
                  style: {
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    color: "#FFF",
                    textOutline: "none",
                  },
                  align: 'right',
                  x: -10
              }
          }
        },
        series: [{
          data: vm.series.map(s => s.data)
        }]
      });

    }

    drawYearChart(commonItem, mainTariffItem, commonBillsItem, reprocessedBillsItem) {
      

      let marginTop = 20;
      let tickAmount = 3;
      let resolution = this.dates.resolution;
      const isAccumulatedComparative = true;

      const mainTariffSeriesData = this.auxiliar.buildDateSeries(this.dates.startDate, this.dates.endDate, this.dates.resolution, vm.totals[mainTariffItem].totals, "value", this.timezone, null, 'period');
      const commonTotalsSeriesData = this.auxiliar.buildDateSeries(this.dates.startDate, this.dates.endDate, this.dates.resolution, vm.totals[commonItem].totals, "value", this.timezone, null, 'period');
      const reprocessedBillsSeriesData = this.hasBill ? this.auxiliar.buildDateSeries(this.dates.startDate, this.dates.endDate, this.dates.resolution, vm.totals[reprocessedBillsItem].totals, "value", this.timezone, null, 'period') : [];
      const commonBillsSeriesData = this.hasBill ? this.auxiliar.buildDateSeries(this.dates.startDate, this.dates.endDate, this.dates.resolution, vm.totals[commonBillsItem].totals, "value", this.timezone, null, 'period') : [];

      let comparativeSeriesData = []; 
      let billsPeriods = [];
      let accumulatedComparative = 0;
      commonTotalsSeriesData.values.forEach( (data, index) => {
        let mainData = mainTariffSeriesData.values.find( (serieData) => serieData.name === data.name);
        if(this.hasBill && commonTotalsSeriesData.newItems[index].period === commonBillsSeriesData.newItems[index].period){
          billsPeriods.push(true);
          mainData = reprocessedBillsSeriesData.values.find( (serieData) => serieData.name === data.name);
          mainTariffSeriesData.values[index].y = null;
          commonTotalsSeriesData.values[index].y = null;
        }
        else{
          billsPeriods.push(false);
          vm.missingBill = true;
          if(this.hasBill){
            reprocessedBillsSeriesData.values[index].y = null;
            commonBillsSeriesData.values[index].y = null;
          }
        }
        let comparativeData = billsPeriods[index] ? comparativeSeriesData.find( (serieData) => serieData.name === commonBillsSeriesData.values[index].name) : comparativeSeriesData.find( (serieData) => serieData.name === data.name);
        if(!comparativeData){
          comparativeData = { y: 0, name: billsPeriods[index] ? commonBillsSeriesData.values[index].name : data.name };
          if(mainData.y && (data.y || commonBillsSeriesData.values[index].y) || accumulatedComparative == 0){  
            comparativeSeriesData.push(comparativeData);
          }
        }

        if(isAccumulatedComparative) {
          const comparativeDiff = mainData.y - (billsPeriods[index] ? commonBillsSeriesData.values[index].y : data.y);
          comparativeData.y = accumulatedComparative + comparativeDiff;
          accumulatedComparative += comparativeDiff; 
        } else {
          comparativeData.y += mainData.y - (billsPeriods[index] ? commonBillsSeriesData.values[index].y : data.y);
        }
      });

      const commonTotalsValue = commonTotalsSeriesData.values.reduce((acc, cur) => (cur.y ? acc + cur.y : acc), 0) + (vm.hasBill ? commonBillsSeriesData.values.reduce((acc, cur) => (cur.y ? acc + cur.y : acc), 0) : 0);
      const mainTariffTotalsValue = mainTariffSeriesData.values.reduce((acc, cur) => (cur.y ? acc + cur.y : acc), 0) + (vm.hasBill ? reprocessedBillsSeriesData.values.reduce((acc, cur) => (cur.y ? acc + cur.y : acc), 0) : 0);
      const comparativeTotalValue = comparativeSeriesData[comparativeSeriesData.length - 1].y;

      const mainSerieType = 'column';
      vm.series = [ {
          name: "ml-simulted",
          label: vm.$filter('translate')('main.comparative.charts-categories-ML'),
          type: mainSerieType,
          data: commonTotalsSeriesData.values,
          color: (vm.hasBill ? vm.grey5 : vm.chartColor),
          enabled: true,
          total: commonTotalsValue
      },{
          name: "acr-simulated",
          label: vm.$filter('translate')('main.comparative.charts-categories-ACR'),
          type: mainSerieType,
          data: mainTariffSeriesData.values,
          color: (vm.hasBill ? vm.grey4 : vm.acrColor),
          enabled: true,
          total: mainTariffTotalsValue
      },{
        name: "ml-bill",
        label: vm.$filter('translate')('main.comparative.charts-categories-ML'),
        type: mainSerieType,
        data: commonBillsSeriesData.values,
        color: vm.chartColor,
        enabled: true,
        total: commonTotalsValue
      },{
        name: "acr-bill",
        label: vm.$filter('translate')('main.comparative.charts-categories-ACR'),
        type: mainSerieType,
        data: reprocessedBillsSeriesData.values,
        color: vm.acrColor,
        enabled: true,
        total: mainTariffTotalsValue
      },{
        name: "economy",
        label: vm.$filter('translate')('main.comparative.charts-categories-economy'),
        type: 'spline',
        color: vm.generationColor,
        data: comparativeSeriesData,
        enabled: true,
        total: comparativeTotalValue
      }];
      vm.$scope.$apply();
      vm.chart = Highcharts.chart(this.$element.find('.chart')[0], {
          chart: {
            spacingLeft: 0,
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: marginTop,
            // height: 280,
            marginBottom: resolution === 'day' ? 40 : 25,
            style: {
              style: {
                overflow: 'visible',
                zIndex: 1
              }
            }
          },
          boost: {
              useGPUTranslations: true
          },
          drilldown: {
              series: []
          },
          xAxis: {
              type: "datetime",
              gridLineWidth: 0,
              tickColor: "#FFF",
              endOnTick: false,
              categories: mainTariffSeriesData.labels,
              labels: {
                useHTML: true,
                formatter: function(){
                  return Highcharts.dateFormat('%b', this.value, true);
                }
              }
          },
          yAxis: {
              title: {
                  text: null
              },
              gridLineWidth: 0.5,
              tickAmount: tickAmount,
              labels: {
                formatter: function() {
                  return `${vm.$filter('translate')('currency')} ${vm.$filter('formatMoney')(this.value)}`;
                }
              }
          },
          legend: {
              enabled: false
          },
          title: {
              text: null
          },
          credits: {
              enabled: false
          },
          tooltip: {
              useHTML: true,
              shared: true,
              formatter: function() {
                  let selectedItemTooltip = "";
                  if(this && this.points){
                    let hasAllSeries = this.points.length == 3;
                    if(hasAllSeries){
                      let EconomyPercentual = Math.round((1- (this.points[0].y/this.points[1].y)) * 100);
                      let EconomyValue = this.points[1].y - this.points[0].y;
                        this.points.forEach( (point, idx) => {
                            selectedItemTooltip +=  `<div>
                            <div style='z-index:99999;height:8px; width:8px; background-color:${point.color}; display: inline-block; margin-right:4px;'></div>`;
                            selectedItemTooltip += ( idx < this.points.length - 1 ) ? 
                            `${point.series.userOptions.label} ${vm.$filter('translate')('currency')} ${vm.$filter('formatMoney')(point.y)}` :
                            `${vm.$filter('translate')('main.comparative.charts-categories-economy-month')} ${vm.$filter('translate')('currency')} ${vm.$filter('formatMoney')(EconomyValue)} <b> (${EconomyPercentual}%)</b>`;
                            selectedItemTooltip += `</div>`;
                        });
                    }else{
                      this.points.forEach( (point, idx) => {
                        selectedItemTooltip +=  `<div>
                        <div style='z-index:99999;height:8px; width:8px; background-color:${point.color}; display: inline-block; margin-right:4px;'></div>
                        ${point.series.userOptions.label} ${vm.$filter('translate')('currency')} ${vm.$filter('formatMoney')(point.y)}
                        </div>`;
                      })
                    }
                      
                  }
                  return selectedItemTooltip;
              }
          },
          plotOptions: {
              series: {
                  pointPadding: 0,
                  groupPadding: 0.2,
                  cursor: 'pointer',
                  point: {
                    events: {
                      click: function() {
                        vm.deepCalendar({date : moment(this.category).toDate()});
                      }
                    }
                  }
              }
          },
          series: vm.series 
      });
      //CSV - Format timestamp 
      (function(H) {
        H.wrap(H.Chart.prototype, 'getDataRows', function(proceed, multiLevelHeaders) {
            let rows = proceed.call(this, multiLevelHeaders);
            rows = rows.map(row => {
              if (row.name) {
                row[0] = Highcharts.dateFormat('%m-%Y', row.name );
              }
              return row;
            });
      
            return rows;
          });
        }(Highcharts));
    }

    toggleSerie(serie){
      for(let i = 0; i < vm.series.length; i++){
        if(serie.name == vm.series[i].name){
          vm.chart.series[i].visible == true ? vm.chart.series[i].hide() : vm.chart.series[i].show();
          break;
        }
      }
    }

  }
};


angular
  .module('dashboard-directives')
  .component('comparativeChart', comparativeChartComponent);

