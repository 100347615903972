<div class="content-wrapper view">
	<div class="container-fluid">
		<!-- ------------------------- Navbar ------------------------- -->
		<nav class="ga-navbar">
			<div class="navbar-content">
				<h3>
					<a (click)="backTo()">
						{{ "main.menu.registrations" | transloco }}
					</a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<a (click)="backTo()"> {{ "credentials-list.label" | transloco }} </a>
					<i
						class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"
					></i>
					<span *ngIf="!credentialId" class="navbar-content__breadcrumb-page">
						{{ "credentials-list.new" | transloco }}
					</span>
					<span *ngIf="credentialId" class="navbar-content__breadcrumb-page">
						{{ credentialName }}
					</span>
				</h3>
			</div>
		</nav>

		<!-- ------------------------- Título ------------------------- -->
		<div class="description">
			<div class="description__texts">
				<span class="description__texts__title">{{
					!credentialId
						? ("credentials-list.new" | transloco)
						: ("credentials-details.form.edit-title" | transloco)
				}}</span>
				<span class="description__texts__subtitle">{{
					!credentialId
						? ("credentials-details.form.new-subtitle" | transloco)
						: ("credentials-details.form.edit-subtitle" | transloco)
				}}</span>
			</div>
		</div>
		<form
			[formGroup]="credentialForm"
			id="companyForm"
			class="flex-col"
			focusInvalidInput
			(ngSubmit)="onSubmit()"
		>
			<div class="row col-lg-12 module-group">
				<h3 class="basic-data__title col-lg-12">
					{{ "global.basic-info" | transloco }}
				</h3>

				<div class="row">
					<!-- Organização -->
					<div class="form-group col-lg-6">
						<autocomplete-orgs
							[idSelected]="organization"
							(orgEmitter)="orgSelected($event)"
							[isRequired]="true"
							[isEnabled]="!credentialId"
						></autocomplete-orgs>
					</div>

					<!-- Nome -->
					<div class="form-group col-lg-6">
						<ga-input
							[title]="'credentials-list.table.name' | transloco"
							[placeholder]="
								'credentials-details.form.credential-placeholder' | transloco
							"
							[disabled]="false"
							[required]="true"
							[showError]="
								credentialForm.controls['name'].invalid &&
								credentialForm.controls['name'].touched
							"
							formControlName="name"
							name="name"
						></ga-input>
					</div>

					<div class="form-group col-lg-12">
						<ga-autocomplete-chips
							class="grow-field"
							[title]="'main.menu.uc' | transloco"
							[placeholder]="'credentials-details.form.new' | transloco"
							[tooltip]="
								'credentials-details.tooltip.consuming-unity' | transloco
							"
							[options]="installNumbers"
							[required]="true"
							[disabled]="false"
							formControlName="installNumbers"
							name="installNumbers"
							[showError]="
								credentialForm.controls['installNumbers'].invalid &&
								credentialForm.controls['installNumbers'].touched
							"
						>
						</ga-autocomplete-chips>
					</div>
					<div class="form-group col-lg-4">
						<ga-selector
							[title]="'credentials-list.table.distributor' | transloco"
							[placeholder]="'meter-page.placeholder.select' | transloco"
							[options]="distributorsNames"
							[required]="true"
							[disabled]="!!credentialId"
							[showError]="
								credentialForm.controls['distributorName'].invalid &&
								credentialForm.controls['distributorName'].touched
							"
							[isSearch]="true"
							formControlName="distributorName"
							name="distributorName"
							(onSelection)="onChangeDistributor()"
						></ga-selector>
					</div>

					<div class="form-group col-lg-8">
						<ga-selector
							[title]="'credentials-list.modal.url' | transloco"
							[placeholder]="'meter-page.placeholder.select' | transloco"
							[options]="urlOptions"
							[required]="true"
							[disabled]="credentialForm.controls['distributorName'].invalid"
							[showError]="
								credentialForm.controls['distributorUrlId'].invalid &&
								credentialForm.controls['distributorUrlId'].touched
							"
							formControlName="distributorUrlId"
							name="distributorUrlId"
						></ga-selector>
					</div>
				</div>
			</div>

			<div class="row col-lg-12 module-group" *ngIf="isBasicDataFilled">
				<h3 class="basic-data__title iconTooltip col-lg-12">
					<span class="mr-5">{{
						"credentials-details.form.access-key" | transloco
					}}</span>
				</h3>

				<access-keys-card
					[currentUrl]="currentUrl"
					[accessId]="accessId"
					[credentialId]="credentialId"
					[form]="credentialForm"
				></access-keys-card>

				<!----------- Botões --------------->
				<hr class="separator" />
				<div class="button-group flex-row">
					<div class="btn-toolbar" role="toolbar" style="text-align: center">
						<div class="btn-group" role="group">
							<button class="btn btn-default" type="button" (click)="cancel()">
								{{ "appliance.btn.cancel" | transloco }}
							</button>
						</div>
						<div class="btn-group" role="group">
							<button
								class="btn btn-primary btn-color-ga"
								type="submit"
								[disabled]="credentialForm.invalid"
							>
								{{ "organization.btn.save" | transloco }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
