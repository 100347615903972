import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GaDateRangePickerComponent } from './ga-date-range-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { GaHelperModule } from '../ga-helper/ga-helper.module';
import { TranslocoModule } from '@ngneat/transloco';

import {
	NgxMatDatetimePickerModule,
	NgxMatNativeDateModule,
	NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { ClickOutsideDirective } from './directive/click-outside.directive';

@NgModule({
	declarations: [GaDateRangePickerComponent, ClickOutsideDirective],
	imports: [
		CommonModule,
		FormsModule,
		MatCardModule,
		MatFormFieldModule,
		MatDatepickerModule,
		MatInputModule,
		GaHelperModule,
		ReactiveFormsModule,
		NgxMatDatetimePickerModule,
		NgxMatNativeDateModule,
		NgxMatTimepickerModule,
		TranslocoModule,
	],
	exports: [GaDateRangePickerComponent],
})
export class GaDateRangePickerModule {}
