import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ContractService } from 'webapp/app/shared/services/contract.service';

@Component({
	selector: 'autocomplete-tariffs',
	templateUrl: './autocomplete-tariffs.component.html',
})
export class AutocompleteTariffsComponent implements OnChanges {
	@Output() tariffSelected: EventEmitter<any> = new EventEmitter();
	@Input() tariffId: number | undefined;
	@Input() tariffName: string | undefined;
	@Input() distributorId: number | undefined;
	@Input() filters: any;
	listTariffs: any[] = [];
	isErrorInput = false;
	filteredTariffs$: Observable<any[]> = new Observable();
	tariffInput: FormControl = new FormControl('', [Validators.required]);

	constructor(private contractService: ContractService) {
		if (this.contractService.listTariffs.length == 0) {
			this.contractService.getTariffs(this.filters).then((tariffs) => {
				this.listTariffs = tariffs;
				if (this.contractService.listTariffs.length == 0) {
					this.contractService.listTariffs = tariffs.slice();
				}
			});
		} else {
			this.listTariffs = this.contractService.listTariffs;
		}
		this.setFilteredTariffs();
	}

	setFilteredTariffs() {
		this.filteredTariffs$ = this.tariffInput.valueChanges.pipe(
			startWith(''),
			map((value: string) => {
				if (value) {
					return this._filterTariffs(value);
				}
				return this.listTariffs;
			})
		);
	}

	async ngOnChanges(changes) {
		if (changes.filters && this.filters) {
			this.contractService.getTariffs(this.filters).then((tariffs) => {
				this.listTariffs = tariffs;
				this.contractService.listTariffs = tariffs.slice();
				if (this.distributorId) {
					this.listTariffs = this.contractService.listTariffs.filter(
						(tariff) => tariff.distributorId.id == this.distributorId
					);
				}
				if (this.tariffId || this.tariffName) {
					this.checkSelected();
				}
				this.setFilteredTariffs();
			});
		}
		if (changes.distributorId && this.distributorId) {
			this.listTariffs = this.contractService.listTariffs.filter(
				(tariff) => tariff.distributorId.id == this.distributorId
			);
			this.setFilteredTariffs();
		}
		if (changes.tariffId || changes.tariffName) {
			this.checkSelected();
		}
	}

	checkSelected() {
		if (this.tariffId && this.findTariff(this.tariffId)) {
			this.tariffInput.setValue(this.findTariff(this.tariffId).name);
			this.tariffSelected.emit(this.findTariff(this.tariffId).id);
		}
		if (this.tariffName && this.findTariff(this.tariffName)) {
			this.tariffInput.setValue(this.tariffName);
			this.tariffSelected.emit(this.findTariff(this.tariffName).id);
		}
	}

	findTariff(value) {
		return (
			this.listTariffs.find((tariff) => tariff.id == value) ||
			this.listTariffs.find((tariff) => tariff.name == value)
		);
	}

	getOptionSelected($event: MatAutocompleteSelectedEvent) {
		this.isErrorInput = false;
		this.tariffSelected.emit($event.option.id);
	}

	private _filterTariffs(value: string): any[] {
		const filterValue = value.toLowerCase();
		return this.listTariffs.filter((option: any) =>
			option.name.toLowerCase().includes(filterValue)
		);
	}
}
