import { InjectionToken } from '@angular/core';
import * as sailsIOClient from 'sails.io.js';
import * as SailsIOJS from 'sails.io.js';
import * as socketIOClient from 'socket.io-client';
import { environment } from 'webapp/environments/environment';

export const sailsConn = sailsIOClient(socketIOClient);
sailsConn.sails.url = environment.backendUrl;

export const SAILS_SOCKET = new InjectionToken<SailsIOJS.Client>(
	'Sails socket connection'
);

export const socketFactory = () => {
	return sailsConn;
};
