<label *ngIf="title">
	<span class="mr-5">{{ title }}</span>
	<ga-helper
		*ngIf="tooltip"
		[text]="tooltip"
		[icon]="'fa-solid fa-circle-info'"
		[iconSize]="12"
		[iconColor]="'#A3A3A3'"
		[tooltipLocale]="'above'"
	></ga-helper>
</label>
<div class="form-group">
	<mat-select
		#select
		multiple
		class="form-control"
		placeholder="{{ placeholder || ('export.multiselect.choose' | transloco) }}"
		[panelClass]="customClass ? customClass + ' white' : 'white'"
		[(ngModel)]="marked"
		(closed)="changeOption()"
	>
		<mat-select-trigger>
			<span *ngIf="marked.length == 1">
				{{
					isonlyOptionSelected
						? foundOnlyOption?.label
						: findSelectedLabel(marked[0])
				}}
			</span>
			<span *ngIf="marked.length == 2">
				{{ findSelectedLabel(marked[0]) }}, {{ findSelectedLabel(marked[1]) }}
			</span>
			<span *ngIf="(marked.length || 0) > 2">
				{{ marked.length + ("export.multiselect.n-selected" | transloco) }}
			</span>
		</mat-select-trigger>
		<ng-container *ngIf="showFilterField">
			<div class="multiselect-item filter-field">
				<div class="input-group">
					<span class="input-group-addon">
						<i class="fa-solid fa-magnifying-glass"></i>
					</span>
					<input
						type="text"
						class="form-control multiselect-search"
						placeholder="{{ 'export-bills.filter-placeholder' | transloco }}"
						(ngModelChange)="filter($event)"
						[(ngModel)]="filteringString"
					/>
					<span class="input-group-btn">
						<button
							class="btn btn-default multiselect-clear-filter"
							(click)="clearFilter()"
							type="button"
						>
							<i class="fa-regular fa-circle-xmark"></i>
						</button>
					</span>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="showSelectAllOption">
			<div class="select-all mat-option">
				<mat-checkbox
					color="primary"
					(change)="toggleAllSelection()"
					[(ngModel)]="togglAllSelected"
					>{{ "datasource-selector.select.all" | transloco }}</mat-checkbox
				>
			</div>
		</ng-container>
		<ng-container *ngIf="isOnlyOption">
			<mat-option
				#onlyOption
				[value]="foundOnlyOption?.id"
				(onSelectionChange)="onlyOptionSelected(onlyOption)"
				>{{ foundOnlyOption?.label }}</mat-option
			>
		</ng-container>
		<mat-option
			#option
			*ngFor="let topic of optionsList"
			[value]="topic.id"
			[class.hide]="shownOption(topic)"
			(onSelectionChange)="optionSelected(option, topic)"
		>
			<span *ngIf="bindLabel" [innerHtml]="topic.label"></span>
			{{ !bindLabel ? topic.label : "" }}
		</mat-option>
	</mat-select>
</div>
