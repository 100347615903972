<header class="header sticky">
	<div
		class="branding float-left"
		[style.background-color]="
			primaryColor && !isBackgroundWhite ? primaryColor : 'white'
		"
	>
		<h1 class="logo text-center" [title]="version">
			<a href="index.html" *ngFor="let headerImage of headerImages">
				<img class="logo-icon-ga-small" [src]="headerImage.small" alt="" />
				<img class="logo-icon-ga-full" [src]="headerImage.textImage" alt="" />
			</a>
		</h1>
	</div>
	<div class="topbar">
		<button class="main-nav-toggle" type="button" (click)="hideMenu()">
			<span class="sr-only">Toggle navigation</span>
			<span class="icon-bar"></span>
			<span class="icon-bar"></span>
			<span class="icon-bar"></span>
		</button>
		<div class="component-tool col-lg-5 col-md-6 col-sm-5 col-xs-4">
			<ng-container *ngComponentOutlet="tool"></ng-container>
		</div>

		<div class="personificate-warning" *ngIf="currentUser.isPersonificated">
			<div class="card-row">
				<i class="fa-solid fa-eye"></i>
				<div class="col-sm-10 personificate-name">
					{{ "main.view" | transloco }} {{ currentUser.firstName }}
				</div>
				<i class="fa-regular fa-xmark remove" (click)="despersonificate()"></i>
			</div>
		</div>

		<div class="navbar-tools">
			<div class="user-container dropdown">
				<div
					class="dropdown-toggle"
					data-toggle="dropdown"
					id="dropdownMenu-user"
				>
					<img [src]="gravatarUrl" alt="" style="margin-right: 8px" />
					<span class="welcome-user"
						>{{ "main.welcome" | transloco }}, {{ currentUser.firstName }}</span
					>
					<i class="fa fa-caret-down"></i>
				</div>
				<ul
					class="dropdown-menu"
					role="menu"
					aria-labelledby="dropdownMenu-user"
					style="width: 300px"
				>
					<li
						(click)="goToUserPage()"
						style="cursor: pointer; padding-bottom: 8px"
					>
						<div
							style="padding-top: 8px; display: flex; justify-content: center"
						>
							<div>
								<img [src]="gravatarUrl" alt="" />
							</div>
							<div style="width: 75%">
								<div class="col-sm-12">
									<strong
										>{{ currentUser.firstName }}
										{{ currentUser.lastName }}</strong
									>
								</div>
								<div class="col-sm-12">{{ currentUser.email }}</div>
							</div>
						</div>
					</li>
					<li>
						<span class="arrow"></span>
						<a role="menuitem" (click)="goToUserPage()">
							<i class="fa-regular fa-user"></i>
							{{ "page.my-account" | transloco }}
						</a>
					</li>

					<li>
						<span class="arrow"></span>
						<a role="menuitem" (click)="goToMetersPage()">
							<i class="fa-regular fa-circle-bolt"></i>
							{{ "page.my-meters" | transloco }}
						</a>
					</li>

					<li>
						<a role="menuitem" (click)="logout()">
							<i class="fa-regular fa-power-off"></i>
							{{ "page.logout-bt" | transloco }}
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</header>
