import { Directive, HostListener, Inject, Input } from '@angular/core';
import { StateService } from 'angular-ui-router';
import { NG_ROUTER } from 'webapp/hybrid-helpers/ajs-upgraded-providers';

@Directive({
	selector: '[ngRouterLink]',
	exportAs: '[ngRouterLink]',
})
export class NgRouterLinkDirective {
	@Input('ngRouterLink') path = '';
	params;

	@HostListener('click')
	navigate() {
		// const start = this.path.search(/\(.*\)/) + 1
		// const end = this.path.length - 1
		// const params = this.path.slice(start, end)
		this.$state.go(this.path, this.params);
	}

	constructor(@Inject(NG_ROUTER) private $state: StateService) {}
}
