import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'root' })
export class ClassificationService {
	constructor(private http: HttpClient) {}

	getClassifications(params) {
		return this.http
			.get(
				`${environment.backendUrl}/api/meters/${params.meterId}/classifications`
			)
			.toPromise()
			.then((res: any) => {
				return res?.classifications;
			});
	}

	getClassificationsConsumptions(params) {
		return this.http
			.get(
				`${environment.backendUrl}/api/meters/${params.meterId}/classifications/${params.classificationId}/consumptions/`,
				{ params: params }
			)
			.toPromise()
			.then((res: any) => {
				return res?.consumptions;
			});
	}
}

export const ng2ClassificationService = {
	name: ClassificationService.name,
	def: downgradeInjectable(ClassificationService),
};
