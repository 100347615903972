import mixpanel from 'mixpanel-browser';
MainController.$inject = ['UserService', '$location'];

/* @ngInject */
function MainController(UserService, $location) {
	this.$onInit = () => {
		UserService.getCurrentUser().then((user) => {
			this.currentUser = user;
			this.currentUser?.isPersonificated = UserService.isPersonificated();

			if ($location.absUrl().indexOf('dashboard.greenant.com.br') < 0) {
				mixpanel.init('a36951d1f36671229954283708f69e9f');
				return;
			}
			mixpanel.init('a0b46297b7b068d15ddba2ed99f524b7');
			(function (i, s, o, g, r, a, m) {
				i['GoogleAnalyticsObject'] = r;
				(i[r] =
					i[r] ||
					function () {
						(i[r].q = i[r].q || []).push(arguments);
					}),
				(i[r].l = 1 * new Date());
				(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
				a.async = 1;
				a.src = g;
				m.parentNode.insertBefore(a, m);
			})(
				window,
				document,
				'script',
				'https://www.google-analytics.com/analytics.js',
				'ga'
			);

			ga('create', 'UA-83607245-1', { userId: this.currentUser.id });
			ga('send', 'pageview', {
				dimension1: this.currentUser.email,
				dimension2: new Date(),
			});

			/*hotjar*/
			(function (h, o, t, j, a, r) {
				h.hj =
					h.hj ||
					function () {
						(h.hj.q = h.hj.q || []).push(arguments);
					};
				h._hjSettings = { hjid: 278923, hjsv: 5 };
				a = o.getElementsByTagName('head')[0];
				r = o.createElement('script');
				r.async = 1;
				r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
				a.appendChild(r);
			})(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
		});
	};
}

export const ngMainController = {
	name: 'MainController',
	def: MainController,
};
