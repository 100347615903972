import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TopBarSearchService } from './top-bar-search.service';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { MixPanelService } from '../../services/mixpanel.service';

declare let $: JQueryStatic;

@Component({
	selector: 'app-top-bar-search',
	templateUrl: './top-bar-search.component.html',
	styleUrls: ['./top-bar-search.component.scss'],
})
export class TopBarSearchComponent implements AfterViewInit, OnDestroy {
	query = new FormControl();
	onDestroy$ = new Subject<void>();
	previousLength = 0;

	constructor(
		@Inject(NG_ROUTER) private $state,
		@Inject(NG_STATE_PARAMS) private $stateParams,
		@Inject(DOCUMENT) private document: Document,
		private topBarSearch: TopBarSearchService,
		private mixpanel: MixPanelService
	) {}

	ngAfterViewInit() {
		this.query.setValue(this.$stateParams.query);
		this.query.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe({
			next: this.doPageFiltering.bind(this),
		});
	}

	doPageFiltering(value: string) {
		const hiddenInputFilter = $(this.document).find('input[type="search"]');
		if (hiddenInputFilter) {
			hiddenInputFilter.val(value).trigger('keyup');
		}
		this.topBarSearch.setQuery(value);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
	}

	mixPanelEvent(eventName) {
		if (this.query.value.length === 1 && this.previousLength === 0) {
			this.mixpanel.mixPanelEvent({ type: eventName });
		}
		this.previousLength = this.query.value.length;
	}
}
