<div class="content-wrapper view">
	<div class="container-fluid">
		<!-- ------------------------- Navbar Module ------------------------- -->
		<nav class="ga-navbar navbar-default">
			<div class="navbar-content">
				<h3>
					<a (click)="backTo()">{{ 'main.menu.registrations' | transloco }}</a>
					<i class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"></i>
					<a (click)="backTo()">{{ 'main.menu.points' | transloco }}</a>
					<i class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"></i>
					<span *ngIf="!meterEditor.meterId" class="navbar-content__breadcrumb-page">{{
						'global.meter.new' | transloco
					}}</span>
					<span *ngIf="meterEditor.meter?.id" class="navbar-content__breadcrumb-page">{{
						meterEditor.meter.label
					}}</span>
				</h3>
			</div>
		</nav>

		<!-- ------------------------- Title description of page ------------------------- -->
		<div class="description">
			<div class="description__texts">
				<span class="description__texts__title">{{
					!meterEditor.meterId
						? ('meter-page.description.new-meter-title' | transloco)
						: ('meter-page.description.edit-title' | transloco)
				}}</span>
				<span class="description__texts__subtitle">{{
					!meterEditor.meterId
						? ('meter-page.description.new-meter-subtitle' | transloco)
						: ('meter-page.description.edit-subtitle' | transloco)
				}}</span>
			</div>
		</div>

		<!-- ------------------------- Form Module ------------------------- -->
		<form id="meterForm" [formGroup]="meterForm" class="flex-col" (ngSubmit)="onSubmit()" focusInvalidInput>
			<!-- ------------------------- Basic Data Module ------------------------- -->
			<div id="basicData" class="row col-lg-12 module-group">
				<h3 class="basic-data__title col-lg-12">
					{{ 'global.basic-info' | transloco }}
				</h3>
				<div class="row col-lg-8">
					<!-- Organização -->
					<div class="form-group col-lg-6">
						<autocomplete-orgs
							[idSelected]="organization"
							(orgEmitter)="orgSelected($event)"
							[isRequired]="true"
						></autocomplete-orgs>
					</div>

					<!-- Empresa -->
					<div class="form-group col-lg-6">
						<autocomplete-companies
							[idSelected]="meterForm.controls['companyId'].value"
							[organization]="organization"
							(companyEmitter)="companySelected($event)"
						></autocomplete-companies>
					</div>

					<!-- Nome -->
					<div class="form-group col-lg-6">
						<ga-input
							[title]="'meter-page.label.meter-name' | transloco"
							[placeholder]="'meter-page.placeholder.name' | transloco"
							[disabled]="meterForm.controls['label'].disabled"
							[required]="true"
							[tooltip]="'meter-page.tooltip.max-char-35' | transloco"
							[maxLength]="35"
							[showError]="!meterForm.controls['label'].valid && meterForm.controls['label'].touched"
							formControlName="label"
							name="label"
						></ga-input>
					</div>
					<!-- Tipo de Medição -->
					<div class="form-group col-lg-6">
						<ga-selector
							[title]="'meter-page.label.type' | transloco"
							[placeholder]="'meter-page.placeholder.select' | transloco"
							[options]="meterEditor.meterTypesOptions"
							[disabled]="meterForm.controls['meterTypeId'].disabled"
							[required]="true"
							[showError]="!meterForm.controls['meterTypeId'].valid && meterForm.controls['meterTypeId'].touched"
							formControlName="meterTypeId"
							name="meterTypeId"
						></ga-selector>
					</div>
				</div>
				<!-- Imagem -->
				<div class="form-group left-divider col-lg-4">
					<ga-upload
						[icon]="'upload'"
						[title]="'meter-page.label.attachment-label' | transloco"
						[iconType]="'default'"
						[iconSize]="18"
						[fileTypes]="fileTypes"
						[maxFileSizeMB]="6"
						[tooltip]="'meter-page.tooltip.upload' | transloco"
						[disabled]="meterForm.controls['image'].disabled"
						[uploadedFilesURL]="meterEditor.uploadedFilesURL"
						formControlName="image"
						name="image"
					></ga-upload>
				</div>
			</div>

			<!-- ------------------------- Config Module ------------------------- -->
			<div *ngIf="meterEditor.meterId || configs.showMoreFields" class="module-group settings">
				<h3 class="settings__title">
					{{ 'meter-page.label.config-label' | transloco }}
				</h3>
				<div class="row code-group">
					<!-- Codigo UID -->
					<div class="form-group col-lg-5 col-md-6 col-sm-12 col-xs-12">
						<ga-input
							[title]="'meter-page.label.uid' | transloco"
							[placeholder]="'meter-page.placeholder.uid' | transloco"
							[disabled]="meterForm.controls['uid'].disabled"
							[required]="true"
							[showError]="!meterForm.controls['uid'].valid && meterForm.controls['uid'].touched"
							formControlName="uid"
							name="uid"
						></ga-input>
					</div>
					<!-- Fases -->
					<div class="form-group col-lg-3 col-md-6 col-sm-12 col-xs-12" *ngIf="configs.showPhases">
						<ga-selector
							[title]="'meter-page.label.phases' | transloco"
							[placeholder]="'meter-page.placeholder.select' | transloco"
							[options]="consumptionPhases"
							[isMultiple]="true"
							[disabled]="meterForm.controls['consumptionPhases'].disabled"
							[required]="true"
							[showError]="
								!meterForm.controls['consumptionPhases'].valid && meterForm.controls['consumptionPhases'].touched
							"
							formControlName="consumptionPhases"
							name="consumptionPhases"
						></ga-selector>
					</div>
					<!-- Bidirecional -->
					<div class="form-group col-lg-3 col-md-6 col-sm-12 col-xs-12">
						<ga-checkbox
							[title]="'meter-page.label.bidirectional-meter' | transloco"
							[isMultiple]="false"
							[disabled]="meterForm.controls['bidirectional'].disabled"
							formControlName="bidirectional"
							name="bidirectional"
						></ga-checkbox>
					</div>
				</div>

				<div class="row col-lg-12">
					<!-- Data de Início da Medição -->
					<div *ngIf="!meterEditor.meterId" class="form-group col-lg-5 col-md-6 col-sm-12 col-xs-12">
						<ga-date-range-picker
							[title]="'meter-page.label.start-measure-from' | transloco"
							[placeholder]="'00/00/00 - 00:00h'"
							[showTime]="true"
							[required]="true"
							[disabled]="meterForm.controls['startDatePtBr'].disabled"
							[showOptions]="false"
							[showError]="!meterForm.controls['startDatePtBr'].valid && meterForm.controls['startDatePtBr'].touched"
							[startWithoutCurrentDate]="true"
							[position]="'above'"
							formControlName="startDatePtBr"
							name="startDatePtBr"
						>
						</ga-date-range-picker>
					</div>
					<!-- Medidor de Consumo Associado -->
					<div *ngIf="configs.showAttachedMeter" class="form-group col-lg-3 col-md-6 col-sm-12 col-xs-12">
						<ga-selector
							[title]="'meter-page.label.attached-meter' | transloco"
							[placeholder]="'meter-page.placeholder.select' | transloco"
							[options]="meterEditor.meters"
							[isMultiple]="false"
							[disabled]="meterForm.controls['attachedMeterId'].disabled"
							formControlName="attachedMeterId"
							name="attachedMeterId"
						></ga-selector>
					</div>
				</div>

				<!-- Endereço da Instalação -->
				<div class="row">
					<div class="form-group col-lg-7">
						<ga-typeahead
							[title]="'meter-page.label.address' | transloco"
							[placeholder]="'meter-page.label.address-placeholder' | transloco"
							[list]="configs.locations"
							(keyPress)="getLocation($event)"
							(selectionChange)="setAddressSelected()"
							[externalget]="true"
							[disabled]="meterForm.controls['formatedAddress'].disabled"
							[required]="true"
							[showError]="
								!meterForm.controls['formatedAddress'].valid && meterForm.controls['formatedAddress'].touched
							"
							formControlName="formatedAddress"
							name="formatedAddress"
						></ga-typeahead>
					</div>
					<!-- TimeZone -->
					<div class="form-group col-lg-3 col-md-6">
						<ga-typeahead
							[title]="'meter-page.timezone' | transloco"
							[placeholder]="'meter-page.timezone' | transloco"
							[list]="configs.timezones"
							[limitTo]="8"
							[disabled]="meterForm.controls['timezone'].disabled"
							[required]="true"
							[showError]="!meterForm.controls['timezone'].valid && meterForm.controls['timezone'].touched"
							formControlName="timezone"
							name="timezone"
						></ga-typeahead>
					</div>
				</div>

				<div *ngIf="configs.showCCEEFields">
					<hr class="separator" />
					<h3 class="settings__title">
						{{ 'meter-page.label.ccee-fields' | transloco }}
					</h3>
					<div class="row" id="sectionTechSpecs">
						<!-- Código de Agente -->
						<div class="form-group col-lg-3 col-md-6">
							<ga-input
								[title]="'meter-page.label.ccee-agent-code' | transloco"
								[placeholder]="'meter-page.placeholder.enter-code' | transloco"
								[disabled]="meterForm.controls['cceeAgentCode'].disabled"
								[required]="true"
								[showError]="!meterForm.controls['cceeAgentCode'].valid && meterForm.controls['cceeAgentCode'].touched"
								formControlName="cceeAgentCode"
								name="cceeAgentCode"
							></ga-input>
						</div>
						<!-- Perfil na CEE -->
						<div class="form-group col-lg-3 col-md-6">
							<ga-selector
								[title]="'meter-page.label.ccee-profile-code' | transloco"
								[placeholder]="'meter-page.placeholder.select' | transloco"
								[options]="agents"
								[isMultiple]="false"
								[disabled]="meterForm.controls['CCEEProfileId'].disabled"
								formControlName="CCEEProfileId"
								name="CCEEProfileId"
							></ga-selector>
						</div>

						<!-- Código de representante -->
						<div class="form-group col-lg-3 col-md-6">
							<ga-input
								[title]="'meter-page.label.ccee-representative-code' | transloco"
								[placeholder]="'meter-page.placeholder.enter-code' | transloco"
								[disabled]="meterForm.controls['cceeRepresentativeCode'].disabled"
								formControlName="cceeRepresentativeCode"
								name="cceeRepresentativeCode"
							></ga-input>
						</div>

						<!-- Número do Ativo -->
						<div class="form-group col-lg-3 col-md-6">
							<ga-input
								[title]="'meter-page.label.ccee-asset-number' | transloco"
								[placeholder]="'meter-page.placeholder.enter-code' | transloco"
								[disabled]="meterForm.controls['assetNumber'].disabled"
								formControlName="assetNumber"
								name="assetNumber"
							></ga-input>
						</div>
					</div>
				</div>

				<!-- Permissão de Acesso -->
				<section *ngIf="configs.showMoreFields">
					<hr class="separator" />
					<h3 class="settings__title">
						<span class="mr-5">{{ 'meter-page.label.access-permission' | transloco }}</span>
						<ga-helper
							[text]="'meter-page.tooltip.access-permission' | transloco"
							[icon]="'fa-solid fa-circle-info'"
							[iconSize]="14"
							[iconColor]="'#A3A3A3'"
							[tooltipLocale]="'above'"
						></ga-helper>
					</h3>
					<div class="row" id="sectionAccess">
						<div class="form-group col-lg-12">
							<ga-autocomplete-chips
								class="grow-field"
								[title]="'meter-page.label.user' | transloco"
								[placeholder]="'meter-page.placeholder.new' | transloco"
								[options]="organizationUsers"
								[disabled]="meterForm.controls['users'].disabled"
								formControlName="users"
								name="users"
							>
							</ga-autocomplete-chips>
						</div>
					</div>
				</section>

				<div *ngIf="configs.showTechSpecs">
					<hr class="separator" />
					<h3 class="settings__title">
						{{ 'meter-page.label.technical-data' | transloco }}
					</h3>
					<div class="row" id="sectionTechSpecs">
						<!-- Capacidade Nominal da Instalação -->
						<div class="form-group col-lg-3 col-md-6">
							<ga-input
								[title]="'meter-page.label.nominal-capacity' | transloco"
								[placeholder]="'meter-page.placeholder.value' | transloco"
								[rAddon]="'meter-page.label.va-label' | transloco"
								[disabled]="meterForm.controls['label'].disabled"
								formControlName="nominalCapacity"
								name="nominalCapacity"
							></ga-input>
						</div>

						<!-- Tensao Nominal -->
						<div class="form-group col-lg-3 col-md-6">
							<ga-input
								[title]="'meter-page.label.voltage-label' | transloco"
								[placeholder]="'meter-page.placeholder.value' | transloco"
								[rAddon]="'meter-page.label.v-label' | transloco"
								[disabled]="meterForm.controls['nominalVoltage'].disabled"
								formControlName="nominalVoltage"
								name="nominalVoltage"
							></ga-input>
						</div>

						<!-- Corrente Nominal -->
						<div class="form-group col-lg-3 col-md-6">
							<ga-input
								[title]="'meter-page.label.nominal-current' | transloco"
								[placeholder]="'meter-page.placeholder.value' | transloco"
								[rAddon]="'meter-page.label.ampere' | transloco"
								[disabled]="meterForm.controls['label'].disabled"
								formControlName="nominalCurrent"
								name="nominalCurrent"
							></ga-input>
						</div>

						<!-- Seção do Condutor -->
						<div class="form-group col-lg-3 col-md-6">
							<ga-input
								[title]="'meter-page.label.conductor-diameter' | transloco"
								[placeholder]="'meter-page.placeholder.value' | transloco"
								[rAddon]="'meter-page.label.mm-label' | transloco"
								[disabled]="meterForm.controls['conductorSection'].disabled"
								formControlName="conductorSection"
								name="conductorSection"
							></ga-input>
						</div>
					</div>
				</div>

				<div *ngIf="configs.showExtras">
					<hr class="separator" />
					<h3 class="settings__title">
						<span class="mr-5">{{ 'meter-page.label.extra-info' | transloco }}</span>
						<ga-helper
							[text]="'meter-page.tooltip.extra-info' | transloco"
							[icon]="'fa-solid fa-circle-info'"
							[iconSize]="14"
							[iconColor]="'#A3A3A3'"
							[tooltipLocale]="'above'"
						></ga-helper>
					</h3>
					<div class="row" id="sectionExtras">
						<!-- Dimensão -->
						<div class="form-group col-lg-3 col-md-6">
							<ga-input
								[title]="'meter-page.label.area' | transloco"
								[placeholder]="'meter-page.placeholder.value' | transloco"
								[rAddon]="'meter-page.label.size-unit' | transloco"
								[disabled]="meterForm.controls['size'].disabled"
								formControlName="size"
								name="size"
							></ga-input>
						</div>

						<!-- Pessoas -->
						<div class="form-group col-lg-3 col-md-6">
							<ga-input
								[title]="'meter-page.label.occupants' | transloco"
								[placeholder]="'meter-page.placeholder.value' | transloco"
								[disabled]="meterForm.controls['people_count'].disabled"
								formControlName="people_count"
								name="people_count"
							></ga-input>
						</div>
					</div>
				</div>

				<div *ngIf="configs.showMoreFields">
					<hr class="separator" />
					<h3 class="settings__title">
						{{ 'meter-page.label.notes-label' | transloco }}
					</h3>
					<div class="row" id="sectionNotes">
						<!-- Editor -->
						<div class="form-group col-lg-12">
							<ga-editor
								[placeholder]="'meter-page.placeholder.meter-notes' | transloco"
								[editorConfig]="editorConfig"
								[disabled]="meterForm.controls['notes'].disabled"
								formControlName="notes"
								name="notes"
							></ga-editor>
						</div>
					</div>
				</div>

				<!-- ------------------------- button group ------------------------- -->
				<hr class="separator" />
				<div class="button-group flex-row">
					<div class="btn-toolbar" role="toolbar" style="text-align: center">
						<div class="btn-group" role="group">
							<button class="btn btn-default" (click)="cancel()">
								{{ 'appliance.btn.cancel' | transloco }}
							</button>
						</div>
						<div class="btn-group" role="group">
							<button class="btn btn-primary btn-color-ga" type="submit" [disabled]="!meterForm.valid || isSaving">
								<span>
									{{ 'organization.btn.save' | transloco }}
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
