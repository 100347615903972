(function () {
  'use strict';

  angular
    .module('dashboard-directives')
    .directive('gaFileUploader', FileUploader);

  /* @ngInject */
  function FileUploader() {
    var fileUploader = {
      restrict: 'E',
      templateUrl: '/app/directives/file-uploader/file-uploader.html',
      scope: {
        progress: '=',
        url: '@',
        isUploading: '=',
      },
      controller: FileUploaderController,
      controllerAs: 'uploadVm',
      bindToController: true,
    };

    return fileUploader;
  }

  FileUploaderController.$inject = [
    'FileUploader',
    '$scope',
    'EnergyBillService',
  ];

  function FileUploaderController(FileUploader, $scope, EnergyBillService) {
    const vm = this;

    vm.energyBillService = EnergyBillService;
    vm.$onInit = onInit;

    function onInit() {
      vm.waitingForFiles = true;
      vm.uploading = false;
    }

    vm.uploader = new FileUploader({
      url: 'http://localhost:1337/api/energyBills',
      alias: 'invoice',
      // headers: 'organization: 79',
      progress: vm.progress,
      autoUpload: true,
    });

    vm.uploader.filters.push({
      name: 'pdfFilter',
      fn: function (item /*{File|FileLikeObject}*/, options) {
        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|pdf|'.indexOf(type) !== -1;
      },
    });

    // vm.uploader.onAfterAddingFile = function (item) {
    //     // $scope.croppedImage = '';
    //     item.croppedImage = '';
    //     var reader = new FileReader();
    //     reader.onload = function (event) {
    //         $scope.$apply(function () {
    //             item.image = event.target.result;
    //         });
    //         console.log(item)
    //     };
    //     reader.readAsDataURL(item._file);
    // };

    vm.uploader.onWhenAddingFileFailed = function (
      item /*{File|FileLikeObject}*/,
      filter,
      options
    ) {
      // console.info('onWhenAddingFileFailed', item, filter, options);
    };
    vm.uploader.onAfterAddingFile = function (fileItem) {
      // console.info('onAfterAddingFile', fileItem);
    };
    vm.uploader.onAfterAddingAll = function (addedFileItems) {
      const formData = new FormData();
      addedFileItems.forEach((fileItem) =>
        formData.append('invoice', fileItem.file)
      );
      // const formData = addedFileItems[0].file;
      const params = {
        organization: 79,
      };
      vm.energyBillService
        .uploadEnergyBill(params, {invoice: formData})
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    vm.uploader.onBeforeUploadItem = function (item) {
      // console.info('onBeforeUploadItem', item);
    };
    vm.uploader.onProgressItem = function (fileItem, progress) {
      // console.info('onProgressItem', fileItem, progress);
    };
    vm.uploader.onProgressAll = function (progress) {
      // console.info('onProgressAll', progress);
    };
    vm.uploader.onSuccessItem = function (fileItem, response, status, headers) {
      // console.info('onSuccessItem', fileItem, response, status, headers);
    };
    vm.uploader.onErrorItem = function (fileItem, response, status, headers) {
      // console.info('onErrorItem', fileItem, response, status, headers);
    };
    vm.uploader.onCancelItem = function (fileItem, response, status, headers) {
      // console.info('onCancelItem', fileItem, response, status, headers);
    };
    vm.uploader.onCompleteItem = function (
      fileItem,
      response,
      status,
      headers
    ) {
      // console.info('onCompleteItem', fileItem, response, status, headers);
    };
    vm.uploader.onCompleteAll = function () {
      console.info('onCompleteAll');
    };
  }
})();
