import { Component, Input } from '@angular/core';

@Component({
	selector: 'ga-loading',
	templateUrl: './ga-loading.component.html',
	styleUrls: ['./ga-loading.component.scss'],
})
export class GaLoadingComponent {
	@Input() text = 'file-uploader.loading';
	@Input() spinnerModel = false;
}
