import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
	NgxMatDatetimePickerModule,
	NgxMatNativeDateModule,
	NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
	imports: [
		MatTableModule,
		MatSortModule,
		MatExpansionModule,
		MatDatepickerModule,
		MatIconModule,
		MatAutocompleteModule,
		MatNativeDateModule,
		MatButtonToggleModule,
		MatPaginatorModule,
		MatDialogModule,
		MatChipsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatTooltipModule,
		MatRadioModule,
		MatMenuModule,
		OverlayModule,
		MatSnackBarModule,
		MatLuxonDateModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		NgxMatDatetimePickerModule,
		NgxMatNativeDateModule,
		NgxMatTimepickerModule,
		MatProgressSpinnerModule,
		MatTabsModule,
	],
	exports: [
		MatTableModule,
		MatSortModule,
		MatExpansionModule,
		MatDatepickerModule,
		MatIconModule,
		MatAutocompleteModule,
		MatNativeDateModule,
		MatButtonToggleModule,
		MatPaginatorModule,
		MatDialogModule,
		MatChipsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatTooltipModule,
		MatRadioModule,
		MatMenuModule,
		OverlayModule,
		MatSnackBarModule,
		MatLuxonDateModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		NgxMatDatetimePickerModule,
		NgxMatNativeDateModule,
		NgxMatTimepickerModule,
		MatProgressSpinnerModule,
		MatTabsModule,
	],
})
export class MaterialModule {}
