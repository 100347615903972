import { AfterContentInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { StateParams, StateService } from 'angular-ui-router';
import * as moment from 'moment';
import { EditorConfig } from 'webapp/app/shared/ga-editor/ga-editor.model';
import { SelectorOption } from 'webapp/app/shared/models/selector.model';
import { User } from 'webapp/app/shared/models/user.model';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { GroupService } from 'webapp/app/shared/services/group.service';
import { MeterService } from 'webapp/app/shared/services/meter.service';
import { OrganizationService } from 'webapp/app/shared/services/organization.service';
import { UserService } from 'webapp/app/shared/services/user.service';
import {
	NG_ROUTER,
	NG_STATE_PARAMS,
} from 'webapp/hybrid-helpers/ajs-upgraded-providers';

@Component({
	selector: 'group-details',
	templateUrl: './group-details.component.html',
	styleUrls: ['./group-details.component.scss'],
})
export class GroupDetailsComponent implements OnInit, AfterContentInit {
	organization: number | undefined;
	groupId: number | undefined;
	organizationUsers: SelectorOption[] = [];
	dataSources = [];
	user: User = {} as User;
	groupName = '';
	isSaving = false;
	groupForm!: FormGroup;

	timezones: Array<string> = [];

	editorConfig: EditorConfig = {
		showToolbar: true,
		toolbarButtons: [
			'justifyLeft',
			'justifyCenter',
			'justifyRight',
			'justifyFull',
			'bold',
			'italic',
			'underline',
			'strikeThrough',
			'insertUnorderedList',
			'insertOrderedList',
		],
	} as EditorConfig;

	constructor(
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(TranslocoService) private i18n,
		private meterService: MeterService,
		private userService: UserService,
		private organizationService: OrganizationService,
		private notification: NotificationService,
		private groupService: GroupService
	) {
		this.organization = this.$stateParams.organizationId;
		this.groupId = this.$stateParams.id;
		this.initFormGroup();
	}

	ngOnInit(): void {
		this.formDisable(!this.organization);
		this.loadCurrentUser();
		this.loadTimeZones();
	}

	ngAfterContentInit(): void {
		this.loadGroup();
	}

	initFormGroup() {
		const group = {
			company: new FormControl(),
			dataSources: new FormControl([], Validators.required),
			description: new FormControl(''),
			id: new FormControl(),
			label: new FormControl('', Validators.required),
			organization: new FormControl('', Validators.required),
			timezone: new FormControl('', Validators.required),
			users: new FormControl([]),
		};

		this.groupForm = new FormGroup(group);
	}

	// ============================= load sectors

	orgSelected(event: { id: number; name: string }) {
		this.organization = event?.id;
		this.groupForm.controls['organization'].setValue(this.organization);
		this.formDisable(!this.organization);

		if (!this.organization) return;

		this.loadUsers();
		this.loadDataSources();
	}

	formDisable(value: boolean) {
		if (value) {
			this.groupForm.disable();
		} else {
			this.groupForm.enable();
		}
	}

	companySelected(event: { id: number; name: string }) {
		this.groupForm.controls['company'].setValue(event.id);
	}

	// ============================= request variables

	loadCurrentUser() {
		try {
			this.userService.getCurrentUser().then((user) => {
				this.user = user;
			});
		} catch (error) {
			this.notification.error({ msg: 'Erro ao carregar usuário' });
		}
	}

	loadUsers() {
		try {
			const params = { organizationId: this.organization };
			this.organizationService.getUsersByOrganization(params).then((users) => {
				if (!users || !users.length) throw new Error();
				const foundUsers = users.map(this.toPresentationUser) || [];
				this.organizationUsers = this.optionsFactory(
					foundUsers,
					'id',
					'name',
					false,
					'subtext'
				);
			});
		} catch (error) {
			this.notification.error({ msg: 'Erro ao carregar usuários' });
		}
	}

	loadGroup() {
		if (!this.groupId) return;

		this.groupService.getGroup({ groupId: this.groupId }).then((group) => {
			Object.keys(group).forEach((key) => {
				if (this.groupForm.controls[key]) {
					this.groupForm.controls[key].setValue(group[key]);
				}
			});

			this.groupForm.controls['dataSources'].setValue(
				group.dataSources.map((datasource) => datasource.id)
			);

			this.groupForm.controls['users'].setValue(
				group.users.map((user) => user.id)
			);

			this.groupName = group['label'];
		});
	}

	loadDataSources() {
		try {
			const params = { organizationId: this.organization };
			this.meterService.getDataSources(params).then((datasources) => {
				if (!datasources) throw new Error();
				this.dataSources = datasources;
			});
		} catch (error) {
			this.notification.error({ msg: 'Erro ao carregar pontos de medição' });
		}
	}

	loadTimeZones() {
		const localTimezone = moment.tz.guess();
		if (
			!this.groupForm.controls['timezone'].value ||
			!this.groupForm.controls['timezone'].value[0]
		) {
			this.groupForm.controls['timezone'].setValue(localTimezone);
		}
		this.timezones = moment.tz.names();
		let lang = this.user.language ? this.user.language : 'pt-BR';
		moment.locale(lang);
		if (lang == 'pt-Br' || lang == 'pt_BR') {
			lang = 'br';
		} else {
			lang = 'en';
		}
	}

	// ============================= save group

	onSubmit() {
		this.isSaving = true;

		this.groupService
			.saveGroup(this.groupForm.value)
			.then(() => {
				this.$state.transitionTo('registrations', {
					tab: 'dataSources',
					organizationId: this.organization,
				});

				this.notification.success({
					msg: this.i18n.translate('group-page.validation.success'),
				});
			})
			.catch((error) => {
				this.isSaving = false;
				this.notification.showErrorMessages(error);
			});
	}

	//======================== helpers

	toPresentationUser(user) {
		return {
			id: user.id,
			name: `${user.firstName} ${user.lastName}`,
			subtext: user.email,
		};
	}

	backTo() {
		this.$state.transitionTo(
			'registrations',
			{ tab: 'dataSources', organizationId: this.organization },
			{
				inherit: false,
				reload: true,
				notify: false,
			}
		);
	}

	cancel() {
		this.$state.transitionTo('registrations', {
			tab: 'dataSources',
			organizationId: this.organization,
		});
	}

	optionsFactory(
		arr: Array<any>,
		fieldId: string,
		fieldName: string,
		meterPagePrefix = false,
		fieldSubtext = ''
	) {
		const optionsArr = arr.map((element) => ({
			id: element[fieldId],
			label: meterPagePrefix
				? this.i18n.translate(`meter-page.label.type-${element[fieldName]}`)
				: element[fieldName],
			subtext: element[fieldSubtext] || '',
		}));

		return meterPagePrefix ? optionsArr.sort(this.compareDesc) : optionsArr;
	}

	compareDesc(a, b) {
		if (a.label > b.label) {
			return -1;
		}
		if (a.label < b.label) {
			return 1;
		}
		return 0;
	}
}

export const ng2GroupDetailsComponent = {
	name: 'groupDetails',
	def: downgradeComponent({
		component: GroupDetailsComponent,
		propagateDigest: true,
	}),
};
