<div
	class="ga-datalist-content table-responsive table-responsive-ga"
	[ngClass]="{
		'table-error':
			(!selectedValues.valid && selectedValues.touched) ||
			(!mainOrg && selectedValues.touched)
	}"
>
	<div class="top-search-bar">
		<section class="top-search-bar__section">
			<span class="top-search-bar__section__title">
				<span>{{ "user-invite.permissions" | transloco }}</span>
				<span class="required">*</span>
			</span>
			<span class="top-search-bar__section__subtitle">
				{{ "user-invite.permissions-desc" | transloco }}
			</span>
		</section>
		<section
			class="top-search-bar__section"
			class="col-lg-4 col-md-4 col-sm-4 p-uns"
		>
			<ga-selector
				[placeholder]="'user-invite.placeholder.orgs' | transloco"
				[options]="options"
				[isMultiple]="true"
				[isTrigger]="true"
				[formControl]="selectedValues"
				[isSearch]="true"
				[required]="true"
				[showError]="!selectedValues.valid && selectedValues.touched"
				[error]="' '"
				name="organization"
			>
				<p>
					{{ selectedValues.value.length + " " + "organizações selecionadas" }}
				</p>
			</ga-selector>
		</section>
	</div>

	<ng-container *ngIf="dataTable.length; else emptyTable">
		<table mat-table [dataSource]="dataTable" class="main">
			<ng-container matColumnDef="organization">
				<th mat-header-cell *matHeaderCellDef style="width: 30%">
					{{ "user-invite.organization" | transloco }}
				</th>
				<td mat-cell *matCellDef="let element" style="width: 30%">
					{{ element.organization.name }}
				</td>
			</ng-container>

			<!-- Main Organization Column -->
			<ng-container matColumnDef="mainOrg">
				<th mat-header-cell *matHeaderCellDef class="textCenter">
					{{ "user-invite.main.organization" | transloco }}
				</th>
				<td mat-cell *matCellDef="let element" class="textCenter">
					<mat-radio-group
						[(ngModel)]="mainOrg"
						(ngModelChange)="emitMainOrg($event)"
					>
						<mat-radio-button
							[disableRipple]="true"
							[value]="element.organization.id"
						></mat-radio-button>
					</mat-radio-group>
				</td>
			</ng-container>

			<!-- Profile Admininistrator Column -->
			<ng-container matColumnDef="profileAdmin">
				<th mat-header-cell *matHeaderCellDef class="textCenter">
					{{ "user-invite.profile.admin" | transloco }}
				</th>
				<td mat-cell *matCellDef="let element" class="textCenter">
					<mat-radio-group [(ngModel)]="element.role">
						<mat-radio-button
							[value]="1"
							[disableRipple]="true"
						></mat-radio-button>
					</mat-radio-group>
				</td>
			</ng-container>

			<!-- Profile Basic Column -->
			<ng-container matColumnDef="profileBasic">
				<th mat-header-cell *matHeaderCellDef class="textCenter">
					{{ "user-invite.profile.basic" | transloco }}
				</th>
				<td mat-cell *matCellDef="let element" class="textCenter">
					<mat-radio-group [(ngModel)]="element.role">
						<mat-radio-button
							[value]="2"
							[disableRipple]="true"
						></mat-radio-button>
					</mat-radio-group>
				</td>
			</ng-container>

			<!-- Profile Remove Column -->
			<ng-container matColumnDef="profileRemove">
				<th mat-header-cell *matHeaderCellDef class="textCenter">
					{{ "user-invite.profile.remove" | transloco }}
				</th>
				<td mat-cell *matCellDef="let element" class="textCenter">
					<i
						class="fa-solid fa-circle-x icon"
						(click)="unselectOrg(element.organization.id)"
					></i>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
	</ng-container>

	<ng-template #emptyTable>
		<div class="empty-table">
			<ga-tag-icon [size]="22" [icon]="'error'"></ga-tag-icon>
			<div class="empty">
				<h3>
					{{ "user-invite.empty-table" | transloco }}
				</h3>
			</div>
		</div>
	</ng-template>
</div>
