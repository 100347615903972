<form [formGroup]="form">
	<div class="row">
		<div class="form-group col-lg-4 col-md-4" *ngIf="currentUrl.nome_campo_1">
			<ga-access-password
				[title]="currentUrl.nome_campo_1"
				[placeholder]="''"
				[showError]="
					form.controls['fieldOne'].invalid && form.controls['fieldOne'].touched
				"
				[accessId]="accessId"
				formControlName="fieldOne"
				name="fieldOne"
				(accessPassword)="showPassword($event, 'fieldOne')"
				*ngIf="
					renderEditPasswordInput(currentUrl.nome_campo_1);
					else showInputOne
				"
			></ga-access-password>
			<ng-template #showInputOne>
				<ga-input
					[title]="currentUrl.nome_campo_1"
					[placeholder]="' '"
					[required]="true"
					[showError]="
						form.controls['fieldOne'].invalid &&
						form.controls['fieldOne'].touched
					"
					formControlName="fieldOne"
					name="fieldOne"
				></ga-input>
			</ng-template>
		</div>

		<div class="form-group col-lg-4 col-md-4" *ngIf="currentUrl.nome_campo_2">
			<ga-access-password
				[title]="currentUrl.nome_campo_2"
				[placeholder]="''"
				[showError]="
					form.controls['fieldTwo'].invalid && form.controls['fieldTwo'].touched
				"
				[accessId]="accessId"
				formControlName="fieldTwo"
				name="fieldTwo"
				(accessPassword)="showPassword($event, 'fieldTwo')"
				*ngIf="
					renderEditPasswordInput(currentUrl.nome_campo_2);
					else showInputTwo
				"
			></ga-access-password>
			<ng-template #showInputTwo>
				<ga-input
					[title]="currentUrl.nome_campo_2"
					[placeholder]="''"
					[required]="true"
					[showError]="
						form.controls['fieldTwo'].invalid &&
						form.controls['fieldTwo'].touched
					"
					formControlName="fieldTwo"
					name="fieldTwo"
				></ga-input>
			</ng-template>
		</div>

		<div class="form-group col-lg-4 col-md-4" *ngIf="currentUrl.nome_campo_3">
			<ga-access-password
				[title]="currentUrl.nome_campo_3"
				[placeholder]="''"
				[showError]="
					form.controls['fieldThree'].invalid &&
					form.controls['fieldThree'].touched
				"
				[accessId]="accessId"
				formControlName="fieldThree"
				name="fieldThree"
				(accessPassword)="showPassword($event, 'fieldThree')"
				*ngIf="
					renderEditPasswordInput(currentUrl.nome_campo_3);
					else showInputThree
				"
			></ga-access-password>
			<ng-template #showInputThree>
				<ga-input
					[title]="currentUrl.nome_campo_3"
					[placeholder]="''"
					[required]="ID_LIGHT !== currentUrl.id_distribuidora"
					[showError]="
						form.controls['fieldThree'].invalid &&
						form.controls['fieldThree'].touched
					"
					formControlName="fieldThree"
					name="fieldThree"
				></ga-input>
			</ng-template>
		</div>
	</div>
</form>
