<div class="row" style="margin-top: 4px; width: 200%" *ngIf="loaded">
	<div class="col-sm-3">
		<span class="search-box">
			<i class="fa fa-search" style="position: absolute"></i>
			<input
				type="text"
				[formControl]="query"
				class="form-control search-input"
				id="search-input"
				placeholder="{{ 'main.search' | transloco }}"
				style="width: 200px"
			/>
		</span>
	</div>
	<div class="filter-selector col-sm-9">
		<mat-form-field class="example-chip-list" appearance="standard">
			<mat-label>{{ "tags.filter" | transloco }}</mat-label>
			<i class="fa fa-search" matPrefix></i>
			<mat-chip-list #chipList>
				<mat-chip
					*ngFor="let tag of selectedTags"
					style="color: #fff"
					[style.backgroundColor]="tag.color ? tag.color : '#e0e0e0'"
					(removed)="remove(tag)"
				>
					{{ tag.label }}
					<button matChipRemove>
						<i class="fa-solid fa-circle-xmark"></i>
					</button>
				</mat-chip>
				<input
					placeholder="{{ 'main.search' | transloco }}"
					#tagInput
					[formControl]="tagCtrl"
					[matAutocomplete]="auto"
					[matChipInputFor]="chipList"
					[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
				/>
			</mat-chip-list>
			<mat-autocomplete
				#auto="matAutocomplete"
				(optionSelected)="selected($event)"
			>
				<mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
					{{ tag.label }}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
</div>
<!--<div class="row" style="margin-top: 8px">
  <div class="col-sm-6">
    <span class="search-box">
      <i class="fa fa-search" ></i>
      <input
        type="text"
        [formControl]="query"
        class="form-control search-input"
        id="search-input"
        placeholder="{{ 'main.search' | transloco }}"
        style="width: 200px"
      />
    </span>
  </div>
</div>-->
