//@ts-nocheck
import * as moment from 'moment';
import * as Highcharts from 'highcharts';

function isPeriodInItems(items, columnPeriod) {
    const selectedPeriod = moment(columnPeriod).format('YYYY-MM-DD');
    return !items.some(item => moment(item.date).format('YYYY-MM-DD') === selectedPeriod);
}

export const chartStyle = ({
    marginTop,
    resolution,
    timezone,
    vm,
    items,
    labels,
    tickAmount,
    injectedValues,
    consumptionSeriesData
}) => {
    return {
        chart: {
            spacingLeft: 0,
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: marginTop,
            marginLeft: 50,
            height: 280,
            marginBottom: resolution === 'day' ? 40 : 25,
        },
        time: {
            timezone: timezone
        },
        boost: {
            useGPUTranslations: true,
        },
        drilldown: {
            series: [],
        },
        tooltip: {
            useHTML: true,
            shared: false,
            formatter: function () {
                if (this.x % 1 != 0) return;
                return vm.consumptionTooltipFormatter(
                    this,
                    items,
                    labels,
                    resolution,
                    timezone,
                    vm.viewSelected == vm.viewBalance
                );
            },
        },

        xAxis: {
            type: 'datetime',
            gridLineWidth: 0,
            tickColor: '#FFF',
            categories: labels,
            plotBands: [],
            labels: {
                useHTML: true,
                formatter: function () {
                    if (resolution === 'day') {
                        var fontStyle = 'font-weight:initial;';
                        if (moment(this.value).tz(timezone).format('d') === '0') {
                            fontStyle = 'font-weight:bold;';
                        }

                        return (
                            "<span style='" +
                            fontStyle +
                            " '>" +
                            Highcharts.dateFormat('%d', this.value, true) +
                            '</span><br/>' +
                            "<span style='margin-left: 3px;" +
                            fontStyle +
                            "'>" +
                            Highcharts.dateFormat('%a', this.value, true).substring(
                                0,
                                1
                            ) +
                            '</span>'
                        );
                    } else if (resolution === 'invoice') {
                        return Highcharts.dateFormat('%b', this.value, true);
                    } else if (resolution === 'hour') {
                        var hour = +moment.tz(this.value, vm.timezone).format('H');

                        if (hour == 12) {
                            return `<span class="hour-label two-digits twelve" >${hour}</span>`;
                        } else if (hour == 23) {
                            return `<span class="hour-label two-digits">
                                ${hour}
                            <span class="twenty-four">
                            ${hour + 1}
                            </span>
                          </span>
                          `;
                        } else if (hour >= 10) {
                            return `<span class="hour-label two-digits">${hour}</span>`;
                        } else {
                            return `<span class="hour-label">${hour}</span>`;
                        }
                        //  return `<span style="margin-right:12px;">${hour}</span>`;
                    }
                },
            },
        },
        yAxis: {
            title: {
                text: null,
            },
            gridLineWidth: 0.5,
            tickAmount: tickAmount,
            labels: {
                formatter: function () {
                    return vm.$filter('formatWatt')(this.value, true, 'Wh', '').value + vm.$filter('formatWatt')(this.value, true, 'Wh', '').symbol;
                },
                align: 'right',
                x: -10,
            },
        },
        legend: {
            enabled: false,
        },
        title: {
            text: null,
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                minPointLength: injectedValues == [] ? undefined : 2,
            },
            line: {
                step: 'center',
            },
            series: {
                pointPadding: 0,
                groupPadding: 0,
                cursor: resolution === 'hour' ? undefined : 'pointer',
                point: {
                    events: {
                        click: function () {

							const isForecast = isPeriodInItems(items, this.category)
                            if(vm.dates.period !== 'hour'){
                                vm.mixPanelEvent('consumption_chart_bar_clicked');
                            }
                            vm.deepCalendar({ date: moment(this.category).toDate(), isForecast });
                        },
                    },
                },
                states: {
                    inactive: {
                        opacity: 1,
                    },
                },
            },
        },

        series: consumptionSeriesData,
    }
}

export const seriesConsumption = ({
    mainSerieType,
    values,
    intermediatePeakValues,
    peakValues,
    injectedValues,
    injectedData
}) => {
    return [
        {
            name: 'consumption',
            type: mainSerieType,
            color: 'var(--chartColor)',
            data: values,
            typeName: 'consumption',
            zIndex: 1,
            marker: {
                enabled: true,
            },
            fillOpacity: 0.2,
            step: 'right',
            stack: 1,
            stacking: 'normal',
        },
        {
            name: 'consumptionIntermediatePeak',
            type: mainSerieType,
            color: 'var(--chartColor)',
            data: intermediatePeakValues,
            typeName: 'consumptionIntermediatePeak',
            zIndex: 1,
            marker: {
                enabled: true,
            },
            fillOpacity: 0.2,
            step: 'right',
            stack: 1,
            stacking: 'normal',
        },
        {
            name: 'consumptionPeak',
            type: mainSerieType,
            color: 'var(--chartColordark)',
            data: peakValues,
            typeName: 'consumptionPeak',
            zIndex: 1,
            marker: {
                enabled: true,
            },
            fillOpacity: 0.2,
            step: 'right',
            stack: 1,
            stacking: 'normal',
        },
        {
            name: 'stacked',
            //type: 'column',
            type: mainSerieType,
            color: 'var(--darkGreyColor)',
            data: [],
            typeName: 'stacked',
            zIndex: 2,
        },
        {
            name: 'generation',
            type: 'spline',
            color: 'var(--alert---alert-500)',
            data: [],
            marker: {
                enabled: true,
            },
            typeName: 'generation',
            zIndex: 3,
        },
        {
            name: 'stacked-forecast',
            type: mainSerieType,
            color: 'rgba(200, 200, 200, 1.0)',
            data: [],
            typeName: 'forecast',
            zIndex: 0,
            marker: {
                enabled: false,
            },
            fillOpacity: 0.2,
        },
        {
            name: 'fits',
            type: 'spline',
            color: 'var(--dataviz---dk--blue)',
            data: [],
            marker: {
                enabled: false,
                fillColor: 'var(--dataviz---dk--blue)',
            },
            typeName: 'fits',
            zIndex: 3,
            dashStyle: 'longdash',
        },
        {
            name: 'consumptionMeters',
            type: mainSerieType,
            color: 'var(--chartColor)',
            data: [],
            typeName: 'consumptionMeters',
            zIndex: 1,
            marker: {
                enabled: true,
            },
            fillOpacity: 0.2,
            step: 'right',
        },
        {
            name: 'consumptionInjected',
            type: 'column',
            color: 'var(--alert---alert-500)',
            data: injectedValues,
            typeName: 'consumptionInjected',
            consumptionItems: injectedData.newItems,
        },
    ];
}

export const styleConsumptionAcumulated = ({
    marginTop,
    resolution,
    vm,
    items,
    labels,
    timezone,
    tickAmount,
    mainSerieType,
    cssVal1,
    dataTaking,
    cssVal2,
    cssOverTaking,
    dataOverTaking,
    cssOverTakingLine,
    dataContractedEnergy,
    maxValue
}) => {
    return {
        chart: {
            spacingLeft: 0,
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: marginTop,
            height: 280,
            marginBottom: resolution === 'day' ? 40 : 25
        },
        boost: {
            useGPUTranslations: true
        },
        drilldown: {
            series: []
        },
        tooltip: {
            enabled: true,
            shared: false,
            formatter: function () { return vm.consumptionTooltipFormatter(this, items, labels, resolution, timezone) }
        },

        xAxis: {
            type: "datetime",
            gridLineWidth: 0,
            tickColor: "#FFF",
            endOnTick: false,
            categories: labels,
            plotBands: [],
            labels: {
                useHTML: true,
                formatter: function () {

                    if (resolution === 'day') {
                        var fontStyle = "font-weight:initial;";
                        if (moment(this.value).tz(timezone).format("d") === '0') {
                            fontStyle = "font-weight:bold;";
                        }

                        return "<span style='" + fontStyle + " '>" + Highcharts.dateFormat('%d', this.value, true) + "</span><br/>" +
                            "<span style='margin-left: 3px;" + fontStyle + "'>" + Highcharts.dateFormat('%a', this.value, true).substring(0, 1) + "</span>";
                    } else if (resolution === 'invoice') {
                        return Highcharts.dateFormat('%b', this.value, true);
                    } else if (resolution === 'hour') {
                        var hour = +moment.tz(this.value, vm.timezone).format("H");

                        if (hour == 12) {
                            return `<span class="hour-label two-digits twelve" >${hour}</span>`
                        } else if (hour == 23) {
                            return `<span class="hour-label two-digits">
                                  ${hour}
                              <span class="twenty-four">
                              ${hour + 1}
                              </span>
                            </span>
                            `;
                        } else if (hour >= 10) {

                            return `<span class="hour-label two-digits">${hour}</span>`;
                        } else {
                            return `<span class="hour-label">${hour}</span>`;
                        }
                        //  return `<span style="margin-right:12px;">${hour}</span>`;
                    }

                }
            }

        },
        yAxis: {
            title: {
                text: null
            },
            gridLineWidth: 0.5,
            max: maxValue * 1.01,
            tickAmount: tickAmount,
            labels: {
                formatter: function () {
                    return vm.$filter('formatWatt')(this.value, true, 'Wh', '').value + vm.$filter('formatWatt')(this.value, true, 'Wh', '').symbol;
                },
            }
        },
        legend: {
            enabled: false
        },
        title: {
            text: null
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            area: {
                stacking: 'normal',
                marker: {
                    enabled: false
                }
            },
            series: {
                cursor: resolution === 'hour' ? undefined : 'pointer',

                point: {
                    events: {
                        click: function () {
                            if(vm.dates.period !== 'hour'){
                                vm.mixPanelEvent('accumulated_chart_bar_clicked');
                            }
                            vm.deepCalendar({ date: moment(this.category).toDate() });
                        }
                    }
                },
                states: {
                    inactive: {
                        opacity: 1
                    }
                }
            }
        },

        series: [{
            name: "accumulatedConsumption",
            type: mainSerieType,
            color: cssVal1,
            fillOpacity: 0.3,
            data: dataTaking.values,
            typeName: "accumulatedConsumption",
            connectNulls: false,
            lineColor: cssVal2,
            zIndex: 1,

        },
        {
            name: "overTakingConsumption",
            type: mainSerieType,
            color: cssOverTaking,
            fillOpacity: 0.3,
            data: dataOverTaking.values,
            name: "overTakingConsumption",
            typeName: "accumulatedConsumption",
            connectNulls: false,
            lineColor: cssOverTaking,
            zIndex: 1,

        },
        {
            name: "contractedLine",
            color: cssOverTakingLine,
            data: dataContractedEnergy.contracted,
            name: "contractedLine",
            typeName: "accumulatedConsumption",
            connectNulls: true,
            lineWidth: 1.7,
            zIndex: 1,
            marker: {
                enabled: false
            }

        },
        {
            name: "contractedLineMax",
            color: cssOverTakingLine,
            data: dataContractedEnergy.max,
            name: "contractedLineMax",
            typeName: "accumulatedConsumption",
            connectNulls: true,
            zIndex: 1,
            marker: {
                enabled: false
            }

        },
        {
            name: "contractedLineMin",
            color: cssOverTakingLine,
            data: dataContractedEnergy.min,
            name: "contractedLineMin",
            typeName: "accumulatedConsumption",
            connectNulls: true,
            lineWidth: 1.7,
            zIndex: 1,
            marker: {
                enabled: false
            }

        }
        ]
    }
}

export const stylePush1 = ({
    totalContracted
}) => {
    return [{
        name: "contractedLine",
        type: 'line',
        color: "var(--grayscale---gray-500)",
        data: totalContracted.contractedVolumeWh,
        typeName: "contracted",
        dashStyle: 'shortdash',
        marker: {
            enabled: false
        },
        pointStart: 0,
        zIndex: 5,
        crisp: false
    },
    {
        name: "contractedMaxLine",
        type: 'line',
        color: "var(--grayscale---gray-500)",
        data: totalContracted.maxFlexWh,
        typeName: "contracted",
        marker: {
            enabled: false
        },
        pointStart: 0,
        zIndex: 5,
        crisp: false
    },
    {
        name: "contractedMinLine",
        type: 'line',
        color: "var(--grayscale---gray-500)",
        data: totalContracted.minFlexWh,
        typeName: "contracted",
        marker: {
            enabled: false
        },
        pointStart: 0,
        zIndex: 5,
        crisp: false
    }]
}

export const stylePush2 = ({ overSerie }) => {
    return {
        name: "overtakingContracted",
        type: 'columnrange',
        color: "var(--error---error-500)",
        typeName: "overtaking",
        data: overSerie,
        marker: {
            enabled: false
        },
        zIndex: 4
    }
}
