import * as moment from "moment";
import { AbstractChart } from "../common/abstract-chart.component";
import * as Highcharts from 'highcharts';
import { hideLoading,showLoading } from "ng-app/util/loader";

const colorElement = document.querySelector(':root');
const cssStyles = getComputedStyle(colorElement);

const powerFactorChartComponent = {
  bindings: {
    sourceObject: "<?",
    sourceType: '<?',
    timezone: '<?'
  },
  templateUrl: '/app/directives/power-factor-chart/power-factor-chart.html',
  controllerAs: 'vm',
  controller: class PowerFactorChart extends AbstractChart {
    constructor(CalendarService, MeasurementService, $element, $filter, Auxiliar, MixPanelService) {
      super("powerFactorChart", CalendarService);
      this.measurementService = MeasurementService;
      this.auxiliar = Auxiliar;
      this.$element = $element;
      this.$filter = $filter;
      this.mixpanel = MixPanelService;
      this.statistics = {};

      this.observerId = null;
      this.isHour30 = false;

      this.pfColors = [];
      this.pfColors.push(String(cssStyles.getPropertyValue('--dataviz---dk--purple')).trim());
      this.pfColors.push(String(cssStyles.getPropertyValue('--dataviz---extra--purple')).trim());
      this.pfColors.push(String(cssStyles.getPropertyValue('--info---info-500')).trim());
      this.pfColors.push(String(cssStyles.getPropertyValue('--info---info-400')).trim());
      this.pfColors.push(String(cssStyles.getPropertyValue('--grayscale---gray-100')).trim());
      this.pfColors.push(String(cssStyles.getPropertyValue('--success---success-500')).trim());
      this.pfColors.push(String(cssStyles.getPropertyValue('--success---success-600')).trim());
      this.pfColors.push(String(cssStyles.getPropertyValue('--error---error-400')).trim());
      this.pfColors.push(String(cssStyles.getPropertyValue('--error---error-500')).trim());
      this.pfColors.push(String(cssStyles.getPropertyValue('--grayscale---gray-400')).trim());
      this.excedentReactiveEnergy = String(cssStyles.getPropertyValue('--grayscale---gray-500')).trim();

    }

    mixPanelEvent(eventName){
      const objectLog = {
        activeInstallationId: this.sourceObject.activeInstallationId,
        activeInstallationStartDate: this.sourceObject.activeInstallationStartDate,
        meterName: this.sourceObject.label,
        meterId: this.sourceObject.id,
        meterType: this.sourceObject.meterType.description,
        periodStart: this.dates.startDate,
        periodEnd: this.dates.endDate,
        period: this.dates.period,
      };
      this.mixpanel.mixPanelEvent({
        type: eventName,
        object: objectLog,
      });
    }

    getData() {
      if (this.dates && this.sourceObject) {
        const vm = this;

        if (this.dates.resolution == 'day' || this.dates.resolution == 'invoice') {
          this.showInfo = false;
          this.toggleOverOn = false;
          if ($('input#cbx-toggleOver')[0]) {
            $('input#cbx-toggleOver')[0].checked = false;
          }
          this.displayToggleOver = true;
        } else if (this.dates.resolution == 'hour') {
          this.showInfo = true;
          this.displayToggleOver = false;
        }
        if (this.chart) this.chart.destroy();

        if (this.sourceType == "group") {
          this.measurementService.powersGroup({
            dataSourceId: this.sourceObject.id,
            endDate: moment(this.dates.endDate).format("YYYY-MM-DD"),
            startDate: moment(this.dates.startDate).format("YYYY-MM-DD")
          }).then(function (data) {
            vm.drawChart(data.powers);
            vm.fillMinMaxValues(data.powers);
            hideLoading("powerFactorChart");
          });
        } else if (this.sourceType == "meter") {


          this.measurementService.powers({
            meterId: this.sourceObject.id,
            endDate: moment(this.dates.endDate).format("YYYY-MM-DD"),
            startDate: moment(this.dates.startDate).format("YYYY-MM-DD")
          }).then(function (data) {
            let contracts = data.contracts?.[0];
            if (contracts.length > 0) {
              let capacitiveStartHour = contracts[contracts.length - 1].fields?.capacitiveStartHour;
              vm.isHour30 = capacitiveStartHour == "00:30" ? true : false;
            }
            
            vm.drawChart(data.powers);
            vm.fillMinMaxValues(data.powers);
            hideLoading("powerFactorChart");
            //todo just for example
            let contract = data.contracts ? data.contracts.find(contract => (contract.subType == 'regulated')) : null;
            let startPeakTime = null;
            let endPeakTime = null;
            var instance = contract && contract.instances && Object.keys(contract.instances)[0] ? contract.instances[Object.keys(contract.instances)[0]] : null;

            if (instance && instance.fields.startPeakTime && instance.fields.endPeakTime) {
              startPeakTime = instance.fields.startPeakTime;
              endPeakTime = instance.fields.endPeakTime
            }


          });
        }
      }
    }

    toggleOver() {
      let data = this.chart.series[0].data;
      const newData = [];
      data.forEach(point => {
        let newPoint = {
          x: point.x,
          y: point.y,
          value: point.value,
          color: point.color
        };
        let aux = this.undoPowerFactorTransform(point.value);
        if (!this.toggleOverOn) {
          if (point.y >= 0 && point.y < 6) {
            if (aux.value < 0.92 && aux.type === 'Cap.') {//this values are based on colorAxis scale
              newPoint.color = this.excedentReactiveEnergy;
            }
          }
          else {
            if (aux.value < 0.92 && aux.type === 'Ind.') {//this values are based on colorAxis scale
              newPoint.color = this.excedentReactiveEnergy;
            }
          }
        } else {
          newPoint.color = point.originalColor;
        }
        newData.push(newPoint);
      });
      this.chart.series[0].setData(newData);
      this.toggleOverOn = !this.toggleOverOn;
    }

    getColor(hour, value) {
      //capacitive hour
      if (hour >= 0 && hour < 6) {
        if (value < 0 && Math.abs(value) >= 0.92) {
          return this.pfColors[3];
        } else if (value < 0 && Math.abs(value) < 0.92) {
          return this.pfColors[0];
        } else {
          //inductive
          return this.pfColors[5];
        }
      } else { //inductive hour
        if (value >= 0 && value >= 0.92) {
          return this.pfColors[5];
        } else if (value > 0 && value < 0.92) {
          return this.pfColors[8];
        }
        else {
          //capacitive
          return this.pfColors[3];
        }
      }
    }

    fillMinMaxValues(powers) {
      this.capacitive = {
        min: null,
        minDate: null,
        max: null,
        maxDate: null
      }
      this.inductive = {
        min: null,
        minDate: null,
        max: null,
        maxDate: null
      }
      if(powers){
        powers.forEach(power => {
          let hour = moment(power.date).hour();
          let absolutePower = Math.abs(power.PF);
          
          const dateFormat = "DD MMM YYYY | HH:mm";
          //capacitive
          if (hour >= 0 && hour < 6 && (power.PF < 0 || power.PF === 1)) {
            if (!this.capacitive.max || absolutePower > this.capacitive.max) {
              this.capacitive.max = absolutePower;
              this.capacitive.maxDate = moment(power.date).tz(this.timezone).format(dateFormat);
            }
  
            if (!this.capacitive.min || absolutePower < this.capacitive.min) {
              this.capacitive.min = absolutePower;
              this.capacitive.minDate = moment(power.date).tz(this.timezone).format(dateFormat);
            }
          } if (hour >= 6 && hour <= 23 && power.PF > 0) { //inductive
            if (!this.inductive.max || absolutePower > this.inductive.max) {
              this.inductive.max = absolutePower;
              this.inductive.maxDate = moment(power.date).tz(this.timezone).format(dateFormat);
            }
  
            if (!this.inductive.min || absolutePower < this.inductive.min) {
              this.inductive.min = absolutePower;
              this.inductive.minDate = moment(power.date).tz(this.timezone).format(dateFormat);
            }
          }
        });
      }
      
    }

    labelFormatter(item, vm) {
      if (vm.dates.resolution === 'day') {
        var fontStyle = "font-weight:initial;";
        if (moment.utc(item.value).format("d") === '0') {
          fontStyle = "font-weight:bold;";
        }
        return "<span style='" + fontStyle + " '>" + Highcharts.dateFormat('%d', item.value, true) + "</span><br/>"
          + "<span style='margin-left: 3px;" + fontStyle + "'>" + Highcharts.dateFormat('%a', item.value, true).substring(0, 1) + "</span>";
      } else if (vm.dates.resolution === 'invoice') {
        return Highcharts.dateFormat('%b', item.value, true);
      }
      else if (vm.dates.resolution === 'hour') {
        if(item.value == 24) return `<span>${item.value}</span>`;

        var hour = +moment.tz(item.value, vm.timezone).format("H");
        return `<span>${hour}</span>`;
      }
    }

    getPowerFactor(item) {
      var hour = moment(item.date).hour();

      return item.PF;
    }

    drawChart(powers) {
      if (powers && (this.dates.resolution == 'day' || this.dates.resolution == 'invoice')) {
        this.drawDayChart(powers);
      } else if (powers && this.dates.resolution == 'hour') {
        this.drawHourChart(powers);
      }
    }

    powerFactorTransform(pf) {
      let sign = pf < 0 ? -1 : 1;
      let factor = 0;
      pf = pf * sign; //abs
      if (pf < 0.92) {
        factor = 0.3 / 0.92 * pf;
      } else {
        factor = (1 - 0.3) / (1 - 0.92) * (pf - 0.92) + 0.3;
      }

      if (sign === 1) {
        return (1 - factor / 2);
      }
      else {
        return factor / 2;
      }
    }

    undoPowerFactorTransform(chartFactor) {
      let powerFactorType = chartFactor >= 0.5 ? "Ind." : "Cap.";
      let powerFactor = null;

      if (powerFactorType === 'Ind.') {
        powerFactor = (1 - chartFactor) * 2;
      }
      else {
        powerFactor = (chartFactor) * 2;
      }

      if (powerFactor < 0.3 / 0.92) {
        let pf = (0.92 / 0.3 * powerFactor);
        pf = Math.floor(pf * 1000) / 1000;
        return { value: pf, type: powerFactorType };
      } else {
        let pf = (powerFactor - 0.3) * (1 - 0.92) / (1 - 0.3) + 0.92;
        pf = Math.floor(pf * 1000) / 1000;
        powerFactorType = pf == 1.00 ? '' : powerFactorType;

        return { value: pf, type: powerFactorType };
      }
    }

    drawDayChart(powers) {
      const vm = this;

      let series = [];
      let labels = [];
      
      for (let i = 0; i < powers.length; i++) {
        let value = powers[i].PF;

        if (value != null && value != undefined) {
          let date = powers[i].date;
          let hour = moment(date).tz(this.timezone).hour();
          let day = moment(date).tz(this.timezone).set({ 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }).format('x');

          value = vm.powerFactorTransform(value);
          series.push({ x: +day, y: hour, value: value });
        }
      }

      let xAxisMin = +moment.tz(moment(vm.dates.startDate).format("YYYY-MM-DD"), vm.timezone).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).format('x');
      let xAxisMax = +moment.tz(moment(vm.dates.endDate).format("YYYY-MM-DD"), vm.timezone).subtract(1, 'day').set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).format('x');

      this.chart = Highcharts.chart(this.$element.find('.chart')[0], {
        chart: {
          type: 'heatmap',
          height: 295,
          marginLeft: 50,
          backgroundColor: 'none',
          marginBottom: 40
        },
        boost: {
          useGPUTranslations: true
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click: function () {
                  vm.mixPanelEvent('power_factor_chart_bar_clicked');
                  vm.deepCalendar({ date: moment.tz(this.x, vm.timezone) }, "hour", vm.timezone);
                }
              }
            },
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        },
        title: {
          text: null
        },
        xAxis: {
          type: "datetime",
          gridLineWidth: 0,
          startOnTick: true,
          endOnTick: true,
          min: xAxisMin,
          max: xAxisMax,
          title: {
            text: null
          },
          labels: {
            useHTML: true,
            formatter: function () {
              return vm.labelFormatter(this, vm);
            },
          },
          tickInterval: vm.dates.resolution === 'invoice' ? 24 * 3600 * 1000 * 30 : 24 * 3600 * 1000  // one day interval
        },
        yAxis: {
          title: {
            text: null
          },
          labels: {
            format: vm.isHour30 ? '{value}:30' : '{value}:00',
            align: 'right',
            x: -10,
          },
          minPadding: 0,
          maxPadding: 0,
          startOnTick: false,
          endOnTick: true,
          tickPositions: [0, 6, 12, 18, 24],
          tickWidth: 0,
          gridLineWidth: 0,
          min: 0,
          max: 23,
          plotLines: [{
            color: 'var(--grayscale---gray-400)',
            value: 5.6,
            width: 1.0,
            label: {
              style: {
                color: 'var(--grayscale---gray-600)'
              }
            },
            zIndex: 5,
            label: {
              useHTML: true,
              text: `<div class="plot-line-tooltip">
                             <span class="plot-line-tooltip-header">${vm.$filter('translate')('main.power-factor.period-change')}</span>
                             <span class="plot-line-tooltip-content">${vm.$filter('translate')('main.power-factor.cap/ind')}</span>
                             </div>`,
              rotation: 0,
              style: {
                display: 'none'
              }
            },
            events: {
              mouseover: function (e) {
                if (this.label && this.label.element) {
                  this.label.element.style.display = 'block';
                }
              },
              mouseout: function (e) {
                if (this.label && this.label.element) {
                  this.label.element.style.display = 'none';
                }
              }
            }
          }
          ]
        },
        colorAxis: {
          tickPositions: [0, vm.powerFactorTransform(-0.92), 0.5, vm.powerFactorTransform(0.92), 1],
          reversed: false,
          stops: [
            [0, this.pfColors[0]],
            [vm.powerFactorTransform(-0.92) - 0.005, this.pfColors[1]],
            [vm.powerFactorTransform(-0.92), this.pfColors[2]], /*0.92 C*/
            [vm.powerFactorTransform(-0.96), this.pfColors[3]],
            [vm.powerFactorTransform(1), this.pfColors[4]],
            [vm.powerFactorTransform(0.96), this.pfColors[5]],
            [vm.powerFactorTransform(0.92), this.pfColors[6]], /*0.92 I*/
            [vm.powerFactorTransform(0.92) + 0.005, this.pfColors[7]],
            [1, this.pfColors[8]]
          ],
          min: 0,
          max: 1,
          labels: {
            x: 5,
            formatter: function () {
              let value = "";
              switch (this.value) {
                case 0:
                  value = "0";
                  break;
                case vm.powerFactorTransform(-0.92):
                  value = "0,92 Cap.";
                  break;
                case 0.5:
                  value = "1";
                  break;
                case vm.powerFactorTransform(0.92):
                  value = "0,92 Ind.";
                  break;
                case 1:
                  value = "0";
                  break;
                default:
                  value = "0";
              }
              return value;
            }
          }
        },
        legend: {
          verticalAlign: 'top',
          align: 'right',
          itemMarginBottom: -15,
          itemMarginTop: -10,
          symbolWidth: 250,
        },
        credits: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          html: true,
          formatter: function () {
            if (this.point.value) {
              //moment.locale('pt-br');
              const date = moment.tz(this.point.x, vm.timezone).format("ddd - DD/MMM");
              const hours = vm.isHour30 ? 
                          `${this.y}:30 - ${this.y + 1}:30` :
                          `${this.y}:00 - ${this.y + 1}:00`;
              let powerFactor = 0;
              let powerFactorType = "";
              let aux = vm.undoPowerFactorTransform(this.point.value);
              powerFactor = aux.value;
              powerFactorType = aux.type;
              powerFactor = powerFactor;
              return `
                      ${date} <br/>
                      ${hours} <br/>
                      <strong>${powerFactor} ${powerFactorType}</strong>
                    `;
            } else {
              return null;
            }
          }
        },
        series: [{
          borderWidth: 0,
          nullColor: '#FFF',
          data: series,
          colsize: 24 * 36e5, // one day
          turboThreshold: 0
        }]
      });
    }

    drawHourChart(powers) {
      powers.forEach(power => {
        power.index = moment(power.date).hour();
      })
      const vm = this;
      let data = this.auxiliar.buildDateSeries(this.dates.startDate, this.dates.endDate, this.dates.resolution, powers, this.getPowerFactor, this.timezone);
      let startPeak = null;
      let endPeak = null;

      if (moment(data.labels[0]).format("d") !== '0' && moment(data.labels[0]).format("d") !== '6') {
        startPeak = data.labels.find(d => {
          return moment(d).format("HH") == "18";
        });
        endPeak = data.labels.find(d => {
          return moment(d).format("HH") == "21";
        });
      }

      let minPoint = 0.7;
      let inductiveValues = [];
      let capacitiveValues = [];

      if (vm.isHour30){
        let startDate = (moment(this.dates.startDate).date())
        let previousDate = (moment(powers[0].date).date())
        let value = powers[0].PF;

        if (previousDate == startDate || !value) {
          inductiveValues.push({
            y: null
          });
          capacitiveValues.push({
            y: null
          });
        }
        else if (value < 0) {
          capacitiveValues.push({
            color: vm.pfColors[9],
            y: (value + 1) * -1
          });
          inductiveValues.push({
            y: null
          });
        } else {
          inductiveValues.push({
            color: vm.pfColors[9],
            y: 1 - value
          });
          capacitiveValues.push({
            y: null
          });
        }
      }

      //splice the serie in other two series (capacitive and inductive)
      for (let i = 0; i < data.values.length; i++) {
        let power = powers.find(p => p.index == i)

        if (power == null) {
          inductiveValues.push({
            y: null
          });
          capacitiveValues.push({
            y: null
          });
        } else {
          let date = power.date;

          let value = data.values[i].y;
          let label = data.labels[i];
          let hour = moment(date).tz(this.timezone).hour();

          //get min absolute value to use at chart  y scale
          let absoluteValue = Math.abs(value).toFixed(2);
          if (absoluteValue < minPoint && absoluteValue != 0) {
            minPoint = absoluteValue;
          }

          if (!value) {
            inductiveValues.push({
              y: null
            });
            capacitiveValues.push({
              y: null
            });
          }
          else if (value < 0) {
            capacitiveValues.push({
              color: this.getColor(hour, value),
              y: (value + 1) * -1
            });
            inductiveValues.push({
              y: null
            });
          } else {
            inductiveValues.push({
              color: this.getColor(hour, value),
              y: 1 - value
            });
            capacitiveValues.push({
              y: null
            });
          }
        }

      }

      data.capacitiveValues = capacitiveValues;
      data.inductiveValues = inductiveValues;
      
      this.chart = Highcharts.chart(this.$element.find('.chart')[0], {
        chart: {
          type: 'column',
          marginTop: 15,
          height: 295,
          backgroundColor: 'none',
          marginLeft: 50,
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: true,
          formatter: function () {
            //moment.locale('pt-br');
            const date = moment(this.x).tz(vm.timezone);
            let hours = '';

            if (vm.isHour30) {
              if (this.x == 24){
                hours = '23:30 - 24:30'
              } else {
                hours = date.subtract(30, 'minutes').format("HH:mm") + " - " + date.add(60, 'minutes').format("HH:mm");
              }
            } else {            
              hours = date.format("HH:00") + " - " + date.add(1, 'h').format("HH:00");
            }
            let powerFactor = this.y < 0 ? this.y + 1 : 1 - this.y;
            let powerFactorType = '';
            if (this.y < 0) {
              powerFactorType = vm.$filter('translate')('main.power-factor.cap');
            }
            else if (this.y > 0 && this.y != 1) {
              powerFactorType = vm.$filter('translate')('main.power-factor.ind');
            }
            powerFactor = Math.floor(powerFactor * 1000) / 1000;


            return `
                    ${hours} <br/>
                    <strong>${powerFactor} ${powerFactorType}</strong>
                  `;
          }
        },
        yAxis: [{
          reversed: false,
          inverted: true,
          title: {
            text: null
          },
          labels: {
            formatter: function () {
              if (this.value > 0) {
                return (1 - this.value).toFixed(2);
              } else {
                return (1 + this.value).toFixed(2);
              }
            },
            align: 'right',
            x: -10,
          },
          plotLines: [{
            color: 'red',
            value: 0.08,
            width: 1.0,
            label: {
              /* text: '0,92 Ind.',*/
              style: {
                color: 'var(--grayscale---gray-600)'
              }
            },
            zIndex: 5
          }, {
            color: 'red',
            value: -0.08,
            width: 1.0,
            label: {
              /*text: '0,92 Cap.',*/
              style: {
                color: 'var(--grayscale---gray-600)'
              },
              verticalAlign: 'middle'
            },
            zIndex: 5
          }],
          tickPositions: [(1 - minPoint) * -1, -0.08, 0, 0.08, (1 - minPoint)],
        }, {
          title: {
            text: null
          },
          reversed: true,
          formatter: function () {
            return 1 - this.value
          }
        }],
        legend: {
          enabled: false
        },
        xAxis: {
          type: "datetime",
          gridLineWidth: 0,
          tickColor: "#FFF",
          categories: data.labels,
          min: 0.5,
          max: 23.5,
          plotLines: [{
            color: 'var(--grayscale---gray-400)',
            value: vm.isHour30 ? 6.5 : 6,
            width: 2,
            label: {
              useHTML: true,
              text: `<div class="plot-line-tooltip">
                            <span class="plot-line-tooltip-header">${vm.$filter('translate')('main.power-factor.period-change')}</span>
                            <span class="plot-line-tooltip-content">${vm.$filter('translate')('main.power-factor.cap/ind')}</span>
                           </div>`,
              rotation: 0,
              style: {
                display: 'none'
              }
            },
            events: {
              mouseover: function (e) {
                this.label.element.style.display = 'block';
              },
              mouseout: function (e) {
                this.label.element.style.display = 'none';
              }
            }
          },
          ...(vm.isHour30 ? [{
            color: 'var(--grayscale---gray-400)',
            value: 0.5,
            width: 2,
            label: {
                useHTML: true,
                text: `<div class="plot-line-tooltip">
                            <span class="plot-line-tooltip-header">${vm.$filter('translate')('main.power-factor.period-change')}</span>
                            <span class="plot-line-tooltip-content">${vm.$filter('translate')('main.power-factor.cap/ind')}</span>
                        </div>`,
                rotation: 0,
                style: {
                    display: 'none'
                }
            },
            events: {
                mouseover: function (e) {
                    this.label.element.style.display = 'block';
                },
                mouseout: function (e) {
                    this.label.element.style.display = 'none';
                }
            }
            }] : [])
          ],
          plotBands: [
            {
              color: 'var(--grayscale---gray-200)',
              from: startPeak,
              to: endPeak
            }
          ],
          title: {
            text: null
          },
          labels: {
            useHTML: true,
            formatter: function () {
              return vm.labelFormatter(this, vm);
            }
          },
          //tickInterval: vm.dates.resolution === 'invoice' ?  24 * 3600 * 1000 * 30 : 24 * 3600 * 1000  // one day interval
        },
        plotOptions: {
          series: {
            pointPadding: 0,
            groupPadding: 0,
            cursor: 'pointer',
            stacking: 'normal',
            states: {
              inactive: {
                opacity: 1
              }
            }
          },
          column: {
            minPointLength: 2
          }
        },
        credits: {
          enabled: false
        },
        series: [{
          data: data.capacitiveValues,
          pointPlacement: vm.isHour30 ? 0 : 0.5
        }, {
          data: data.inductiveValues,
          pointPlacement: vm.isHour30 ? 0 : 0.5
        }]
      });

    }

  }//end PowerFactorChart
};//end powerFactorChartComponent

export const powerFactorChart = {
  name: 'powerFactorChart',
  def: powerFactorChartComponent
}

