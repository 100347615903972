import { Component, Inject } from '@angular/core';
import {
	MAT_SNACK_BAR_DATA,
	MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
	selector: 'response-notification',
	styleUrls: ['../notification.scss'],
	templateUrl: './response-notification.html',
})
export class ResponseNotificationComponent {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data: any,
		public ref: MatSnackBarRef<ResponseNotificationComponent>
	) {}
}
