/* @ngInject */
function sourceSelector() {
    var directive = {
        restrict: 'E',
        templateUrl: '/app/directives/source-selector/source-selector.html',
        controller: SourceSelectorController,
        controllerAs: 'sourceVm',
        bindToController: true,
        scope: {
            changeListener: "&?",
            selectedId: "=?",
            $transition$: "=?",
            selectFirstElement: "=?",
            update: "&?"
        }
    };

    return directive;
}

SourceSelectorController.$inject = ['MeterService', 'DataSourceService', '$q', 'AuthService', 'GroupService', '$state', '$element'];

/* @ngInject */
function SourceSelectorController(MeterService, DataSourceService, $q, AuthService, GroupService, $state, $element) {
    const vm = this;

    vm.selectMeter = selectMeter;
    vm.selectGroup = selectGroup;
    vm.filterGroupFunc = filterGroupFunc;//TODO change this name

    initSources();
    loadSources();

    function filterGroupFunc(item) {
        if(vm.filterGroup){
            return item.groups.filter( group => group.label.toLowerCase().includes(vm.filterGroup.toLowerCase())).length > 0;
        } else {
            return true;
        }
    }

    function initSources() {

        vm.sources = {
            "meter": "meter",
            "group": "group"
        };

        vm.selectFirstElement = vm.selectFirstElement || true;

        vm.meter = {
            items: [],
            contracts: [],
            selected: null,
            queueCBs: [],
            current: {
                inspectionDay: null
            },
            realtimeChartOptions: {
                tickAmount: 3,
                showYTicks: false,
                serieColor: "var(--primary-color)",
                energyStyle: {
                    'font-size': '15px',
                    'color': 'var(--secondary-color)'
                },
                chart: {
                    type: 'area'
                }
            }
        };

        vm.groups = {
            items: [],
            selectedGroup: {}
        };
        vm.showMeters = true;
        vm.filteredBroupsByBranch = [];

        $element.find(".dropdown-menu-item").on("click", function(e){
            //prevent meter dropdownd from closing
            $element.find(".dropdown-menu-item").each(function(){
                $(this).removeClass("active");
            })
            $(this).addClass("active");

            e.stopPropagation();
        });
    }

    //Load meters
    function loadSources() {
        DataSourceService.getDataSources().then(function(values) {
            vm.meter.items = values;
            vm.showOrganizationTitle = true;

            if(!vm.meter.items || vm.meter.items?.length == 0){
                if (vm.changeListener) {

                    vm.changeListener({
                        source: null
                    });
                }
            }else{
                let countOrgs = 1;
                let tempOrgId = vm.meter.items[0].organizationId;
                vm.meter.items.forEach( ds => {
                    if(ds.organizationId !== tempOrgId ){
                        countOrgs++;
                    }
                });
                vm.showOrganizationTitle = countOrgs > 1;
            }

            let url = $state.current.url;
            let sourceType = vm.$transition$ ? vm.$transition$.params().sourceType : "";

            if (url.match(/^\/group.*/g) || vm.sources.group === sourceType ) {
                vm.selectedSourceType = vm.sources.group;
            } else if (url.match(/^\/meter.*/g) || vm.sources.meter === sourceType) {
                vm.selectedSourceType = vm.sources.meter;
            } else {
                vm.selectedSourceType = vm.sources.meter;
            }



            //Sets a default meter
            if (vm.meter.items && vm.meter.items.length > 0) {

                const bySourceType = dataSource => dataSource.type == vm.selectedSourceType;

                if (vm.selectedSourceType == vm.sources.meter) {
                    let selectedMeter = null;

                    const paramUID = (vm.$transition$ && vm.$transition$.params().uid) || vm.selectedId;
                    if (paramUID) {
                        const foundMeter = vm.meter.items.find(meter => meter.uid == paramUID || meter.id == paramUID);
                        selectedMeter = paramUID ? foundMeter : null;
                    }

                    //if the route doesnt fit any meters at the list the first meter will be selected
                    if(selectedMeter == null && vm.selectFirstElement == true) {
                        selectedMeter = vm.meter.items.find(bySourceType);
                    }

                    selectMeter(selectedMeter);

                } else if (vm.selectedSourceType == vm.sources.group) {
                    let selectedGroup = null;
                    if(vm.$transition$ || vm.selectedId) {
                        const paramGroupId = vm.selectedId || vm.$transition$.params().id;
                        selectedGroup = vm.meter.items.find(dataSource => dataSource.id == paramGroupId);
                    }

                    if(!selectedGroup) {
                        selectedGroup = vm.meter.items.find(bySourceType);
                    }

                    //Set Selected Group
                    if (selectedGroup) {
                        selectGroup(selectedGroup);
                    }

                }
            }

            $('.scrollbox').each(function(){

                if($(this).hasClass("scrollbox-active") == false){
                    $(this).enscroll({
                        showOnHover: true,
                        verticalTrackClass: 'track3',
                        verticalHandleClass: 'handle3'
                    });

                    $(this).addClass("scrollbox-active")
                }
            });
        });
    };

    function selectMeter(meter) {
        if (meter) {
            
            vm.filterMeter = "";
            if (vm.meter.selected == undefined || vm.meter.selected.id != meter.id) {
                vm.meter.selected = meter;
                vm.groups.selectedGroup = null;

                if(meter.contracts && meter.contracts.length > 0) {
                    setSource(meter, vm.sources.meter);
                } else {
                    MeterService.getMeter({meterId :meter.id }).then( completeMeter => {
                        Object.assign(meter, completeMeter);
                        return MeterService.getContracts(meter.id);

                    })
                    .then( contracts => {
                        meter.contracts = contracts;
                        if(contracts && contracts.length > 0){
                            meter.inspectionDay = meter.contracts[0].inspectionDay;
                        } else {
                            meter.inspectionDay = 0;
                        }
                        setSource(meter, vm.sources.meter);
                    });
                }
            }
        }
    }

    function selectGroup(group) {
        if (group) {
            vm.filterMeter = "";
            if (vm.groups.selectedGroup == undefined || vm.groups.selectedGroup.id != group.id) {
                vm.meter.selected = null;
                vm.groups.selectedGroup = group;

                if(group.contracts && group.contracts.length > 0) {
                    setSource(group, vm.sources.group);
                } else {
                    GroupService.getGroup({groupId : group.id }).then( completeGroup => {
                        Object.assign(group, completeGroup);
                        return GroupService.getContracts(group.id);
                    })
                    .then( contracts => {
                        group.contracts = contracts;
                        if(contracts && contracts.length > 0){
                            group.inspectionDay = group.contracts[0].inspectionDay;
                        } else {
                            group.inspectionDay = 0;
                        }
                        setSource(group, vm.sources.group);
                    });
                }
            }
        }
    }

    function setSource(selectedSource, selectedSourceType) {
        vm.selectedSourceType = selectedSourceType;
        vm.selectedSource = selectedSource;
        if (vm.changeListener) {
            let source = {
                selectedSource: vm.selectedSource,
                selectedSourceType: vm.selectedSourceType
            };
            vm.changeListener({
                source: source
            });
        }
    }
} //End directive

export const ngSourceSelector = {
    name: 'sourceSelector',
    def: sourceSelector
  }