import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isEmptyObject } from 'webapp/app/utils/transformUtil';
import { EmitFiles } from 'webapp/app/shared/ga-upload-image/upload-image.model';
import { GetTheme, Theme } from '../theme.model';
import { convertImage } from 'webapp/app/utils/files';
import { OrganizationThemeService } from '../organization-theme.service';
@Component({
	selector: 'dialog-organization-theme',
	templateUrl: './dialog-organization-theme.html',
	styleUrls: ['../../organization-form.component.scss'],
})
export class DialogOrganizationTheme {
	theme: GetTheme = {} as GetTheme;
	tooltipLogoTranslation = '';
	tooltipSymbolTranslation = '';

	constructor(
		public dialogRef: MatDialogRef<any>,
		private dialogService: OrganizationThemeService,
		@Inject(MAT_DIALOG_DATA) public themeData: Theme
	) {
		this.translations();
		this.initialTheme = themeData;
	}

	set initialTheme(theme: Theme) {
		if (!theme.logoImage) {
			console.info('parametros precisam ser passados');
			return;
		}
		this.theme = {
			...theme,
			isBackgroundWhite: new FormControl(theme.isBackgroundWhite),
			logoImage: convertImage(theme.logoImage),
			textImage: convertImage(theme.textImage),
		};
	}

	translations(): void {
		this.tooltipLogoTranslation = this.dialogService.tooltipLogoTranslation();
		this.tooltipSymbolTranslation =
			this.dialogService.tooltipSymbolTranslation();
	}

	changedPrimaryColor(color: string) {
		this.theme.defaultStyle = false;
		this.theme.primaryColor = color;
	}

	changedSecondaryColor(color: string) {
		this.theme.defaultStyle = false;
		this.theme.secondaryColor = color;
	}

	changedTertiaryColor(color: string) {
		this.theme.defaultStyle = false;
		this.theme.tertiaryColor = color;
	}

	restoreDefault() {
		this.theme = this.dialogService.defaultTheme();
	}

	send() {
		const sendTheme: Theme = this.dialogService.transformToTheme(this.theme);
		this.dialogService.sendToCookie(sendTheme);
		this.close();
	}

	emitLogo(event: EmitFiles) {
		if (isEmptyObject(event)) {
			this.theme.textImage = this.dialogService.defaultTheme().textImage;
			return;
		}
		this.theme.defaultStyle = false;
		this.theme.textImage = convertImage(event.base64[0]);
	}

	emitSymbol(event: EmitFiles) {
		if (isEmptyObject(event)) {
			this.theme.logoImage = this.dialogService.defaultTheme().logoImage;
			return;
		}
		this.theme.defaultStyle = false;
		this.theme.logoImage = convertImage(event.base64[0]);
	}

	close() {
		this.dialogRef.close();
	}
}
