import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { AuthService } from './auth.service';
import { LoginComponent } from './login/login.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ComponentContainerModule } from '../shared/component-container/component-container.module';
import { httpInterceptorProviders } from '../shared/middleware';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalLoadingModule } from '../shared/global-loading/global-loader.module';

import { InviteComponent } from './invite/invite.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { LogoutComponent } from './logout.component';
import { RequestCache } from '../shared/middleware/request-cache.service';
import { LoaderInterceptor } from '../shared/middleware/global-loading.interceptor';

@NgModule({
	declarations: [
		LoginComponent,
		ForgotComponent,
		InviteComponent,
		NewPasswordComponent,
		LogoutComponent,
	],
	imports: [
		CommonModule,
		TranslocoModule,
		ReactiveFormsModule,
		HttpClientModule,
		ComponentContainerModule,
		GlobalLoadingModule,
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
	],
})
export class AuthModule {
	static forRoot(): ModuleWithProviders<AuthModule> {
		return {
			ngModule: AuthModule,
			providers: [httpInterceptorProviders, AuthService, RequestCache],
		};
	}
}
