<label *ngIf="title">
	<span>{{ title }}</span>
	<span *ngIf="required" class="required">*</span>
	<ga-helper
		*ngIf="tooltip"
		[text]="tooltip"
		[icon]="'fa-solid fa-circle-info'"
		[iconSize]="12"
		[iconColor]="'#A3A3A3'"
		[tooltipLocale]="'above'"
	></ga-helper>
</label>
<div
	class="datePicker items-center"
	[ngClass]="{ 'ga-input-error': showError }"
	*ngIf="!showTime"
>
	<mat-datepicker-toggle
		matSuffix
		[tabIndex]="-1"
		[for]="$any(picker)"
		(click)="onTouch()"
	></mat-datepicker-toggle>
	<mat-datepicker #picker>
		<mat-datepicker-actions>
			<button mat-button (click)="clearPicker()">
				{{ "global.clear" | transloco }}
			</button>
			<button mat-raised-button color="primary" matDatepickerApply>
				{{ "global.apply" | transloco }}
			</button>
		</mat-datepicker-actions>
	</mat-datepicker>
	<input
		matInput
		class="form-control"
		id="date-{{ id }}"
		name="date-{{ id }}"
		[formControl]="myDate"
		[placeholder]="placeholder"
		(dateChange)="dateChange($event)"
		[matDatepicker]="picker"
		(blur)="onTouch()"
		[disabled]="disabled"
		[min]="minDate"
		[max]="maxDate"
		[ngClass]="{ 'has-error': showError }"
	/>
</div>
<!-- [ngClass]="{ 'ga-input-error': showError }" -->
<div class="datePicker items-center" *ngIf="showTime">
	<mat-datepicker-toggle
		matSuffix
		[tabIndex]="-1"
		[for]="$any(picker)"
		(click)="onTouch(); addCancelButton()"
	></mat-datepicker-toggle>
	<ngx-mat-datetime-picker
		#picker
		[showSpinners]="true"
		[showSeconds]="showSeconds"
		[stepHour]="stepHour"
		[stepMinute]="stepMinute"
		[stepSecond]="stepSecond"
		[touchUi]="false"
		[color]="color"
		[hideTime]="false"
		(monthSelected)="addCancelButton()"
	>
		<ng-template #confirmButton>
			<span>{{ "global.apply" | transloco }}</span>
		</ng-template>
	</ngx-mat-datetime-picker>
	<input
		matInput
		class="form-control"
		id="date-{{ id }}"
		name="date-{{ id }}"
		[formControl]="myDate"
		[placeholder]="placeholder"
		(dateChange)="dateChange($event)"
		[ngxMatDatetimePicker]="picker"
		(blur)="onTouch()"
		[disabled]="disabled"
		[min]="minDate"
		[max]="maxDate"
		[ngClass]="{ 'has-error': showError }"
	/>
</div>
<mat-error *ngIf="showError">{{
	error || ("global.field-required" | transloco)
}}</mat-error>
