import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'webapp/environments/environment';
import { Distribuitor } from '../models/distributors.model';

@Injectable({ providedIn: 'root' })
export class DistributorService {
	constructor(private http: HttpClient) {}

	distributors(): Promise<Distribuitor[]> {
		return this.http
			.get<Distribuitor[]>(`${environment.backendUrl}/api/distributors`)
			.toPromise()
			.then((res: any) => res);
	}

	getDistributor(id) {
		return this.http
			.get(`${environment.backendUrl}/api/distributors/`, {
				params: {
					distributorId: id,
				},
			})
			.toPromise();
	}
}
