class LoadingController {
  constructor($element, $scope) {
    this.$scope = $scope;
    this.$element = $element;
  }
  
  async $onInit() {
    const vm = this;

    vm.$scope.$watch('lVm.enabled', function (enabled) {
      if (enabled == null) return;

      if (enabled) {
        vm.$element.find(`#${vm.gaId}`).show();
      } else {
        vm.$element.find(`#${vm.gaId}`).hide();
      }
    });
  }

  isComplete() {
    return this.complete == 'true';
  }

  showCompleteText() {
    return this.isComplete() && this.completeText;
  }
}

const loadingComponent = {
  templateUrl: "/app/directives/loading/loading.html",
  controller: LoadingController,
  controllerAs: "lVm",
  bindings: {
    gaId: "@",
    enabled: "=",
    complete: "=",
    completeText: "@"
  },
};

export const ngGaLoading = {
  name: "gaLoading",
  def: loadingComponent
}