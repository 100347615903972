import { Directive, HostBinding, Inject, OnInit } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { fromEvent } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Directive({
	selector: '[appKitResponsive]',
})
export class AppKitResponsiveDirective implements OnInit {
	@HostBinding('class.compact') compact = false;
	@HostBinding('class.mobile') mobile = false;

	constructor(@Inject(WINDOW) private window: Window) {}

	ngOnInit() {
		fromEvent(this.window, 'resize')
			.pipe(startWith(null))
			.subscribe({
				next: () => {
					const width = this.window.screen.width;
					this.compact = width <= 1200;
					this.mobile = width <= 768;
				},
			});
	}
}
