import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class SnackService {
	constructor(private snackBar: MatSnackBar) {}

	showSnackBar(
		message: string,
		typeClass: string,
		action?: string | undefined
	) {
		this.snackBar.open(message, action, {
			duration: 3500,
			panelClass: [typeClass],
			horizontalPosition: 'right',
			verticalPosition: 'top',
		});
	}
}
