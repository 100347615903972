<div
	class="content"
	[ngClass]="'tag-' + type"
	[attr.style]="'width: ' + size * 2.55 + 'px; height: ' + size * 2.55 + 'px;'"
>
	<i
		class="fa-regular"
		[ngClass]="[selectIcon, 'tag-' + type]"
		[attr.style]="
			'width: ' +
			size * 1.8 +
			'px; height: ' +
			size * 1.8 +
			'px; font-size: ' +
			size +
			'px'
		"
	></i>
</div>
