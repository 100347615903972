import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
	forwardRef,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, ThemePalette } from '@angular/material/core';
import { TranslocoService } from '@ngneat/transloco';
import { FormFieldBaseDirective } from '../directive/form-field-base';
import {
	NGX_MAT_DATE_FORMATS,
	NgxMatDateAdapter,
	NgxMatDateFormats,
	NgxMatDatetimePicker,
} from '@angular-material-components/datetime-picker';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

const INTL_DATE_INPUT_FORMAT = {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hourCycle: 'h23',
	hour: '2-digit',
	minute: '2-digit',
};

const MAT_DATE_FORMATS: NgxMatDateFormats = {
	parse: {
		dateInput: INTL_DATE_INPUT_FORMAT,
	},
	display: {
		dateInput: INTL_DATE_INPUT_FORMAT,
		monthYearLabel: { year: 'numeric', month: 'short' },
		dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
		monthYearA11yLabel: { year: 'numeric', month: 'long' },
	},
};

@Component({
	selector: 'ga-datepicker',
	templateUrl: './ga-datepicker.component.html',
	styleUrls: ['./ga-datepicker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GaDatepickerComponent),
			multi: true,
		},
		{ provide: NGX_MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS },
	],
})
export class GaDatepickerComponent
	extends FormFieldBaseDirective
	implements OnInit, OnDestroy
{
	@Input() showTime = false;
	@Input() showSeconds = false;
	@Input() minDate = false;
	@Input() maxDate = false;
	@Input() color: ThemePalette = 'primary';
	@ViewChild('picker') picker!: NgxMatDatetimePicker<Date>;
	subscription!: Subscription;
	myDate = new FormControl(new Date());

	stepHour = 1;
	stepMinute = 1;
	stepSecond = 1;

	constructor(
		private dateAdapter: DateAdapter<Date>,
		private ngxMatDateAdapter: NgxMatDateAdapter<Date>,
		private i18n: TranslocoService,
		private renderer: Renderer2
	) {
		super();
		const locale = this.i18n.getActiveLang().split('_').join('-');
		this.dateAdapter.setLocale(locale);
		this.ngxMatDateAdapter.setLocale(locale);
	}

	ngOnInit(): void {
		this.subscription = this.valSubject
			.pipe(
				tap((date) => {
					this.myDate.setValue(date);
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	dateChange(event) {
		if (event.value.c) {
			this.value = new Date(
				event.value.c.year,
				event.value.c.month - 1,
				event.value.c.day,
				event.value.c.hour,
				event.value.c.minute,
				event.value.c.second,
				event.value.c.millisecond
			);
			return;
		}
		this.value = new Date(event.value);
	}

	clearPicker() {
		this.myDate.setValue(null);
		this.value = null;
	}

	addCancelButton() {
		const elements = document.querySelector('ngx-mat-datetime-content');

		if (!elements) return;

		setTimeout(() => {
			const actionsDiv = elements.querySelector('.actions');

			if (!actionsDiv) return;

			const button = this.renderer.createElement('button');
			const text = this.renderer.createText(
				this.i18n.translate('global.cancel')
			);
			this.renderer.appendChild(button, text);
			this.renderer.addClass(button, 'cancel');
			this.renderer.addClass(button, 'btn-default');

			this.renderer.listen(button, 'click', () => {
				this.picker.close();
			});

			this.renderer.insertBefore(actionsDiv, button, actionsDiv.firstChild);
		}, 0);
	}
}
