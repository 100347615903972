/* @ngInject */
function ComponentContainer() {
    var directive = {
        restrict: 'E',
        scope: { ref: "@" },
        terminal: true,
        transclude: {
            'title': 'gaComponentContainerTitle',
            'bodySlot': 'gaComponentContainerBody',
            'footerSlot': '?gaComponentContainerFooter',
            'menuSlot': '?gaComponentContainerMenu'
        },
        templateUrl: '/app/directives/component-container/component-container.html'
    };

    return directive;
}

export const ngComponentContainer = {
    name: 'gaComponentContainer',
    def: ComponentContainer
}
