import {
	Component,
	ElementRef,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
	forwardRef,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormFieldBaseDirective } from '../directive/form-field-base';
import { SelectorOption } from '../models/selector.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { startWith, tap } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
	selector: 'ga-autocomplete-chips',
	templateUrl: './ga-autocomplete-chips.component.html',
	styleUrls: ['./ga-autocomplete-chips.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GaAutocompleteChipsComponent),
			multi: true,
		},
	],
})
export class GaAutocompleteChipsComponent
	extends FormFieldBaseDirective
	implements OnInit, OnDestroy, OnChanges
{
	separatorKeysCodes: number[] = [ENTER, COMMA];
	optionCtrl = new FormControl('');
	filteredOptions: SelectorOption[] = [];
	selectedOptions: SelectorOption[] = [];
	subscriptions: Subscription[] = [];

	@ViewChild('chipsInput') chipsInput!: ElementRef<HTMLInputElement>;

	constructor() {
		super();

		this.subscriptions.push(
			this.optionCtrl.valueChanges
				.pipe(
					startWith(''),
					tap((value: string) => this.setFilteredOptions(value))
				)
				.subscribe()
		);
	}

	setFilteredOptions(value) {
		this.filteredOptions =
			value && typeof value === 'string'
				? this._filter(value)
				: this.options.filter(
						(option) =>
							!this.selectedOptions.find(
								(selected) => selected.id === option.id
							)
				  );
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.options) {
			this.selectedOptions = changes.options.currentValue.filter((option) =>
				this.val?.includes(option?.id)
			);
			this.setFilteredOptions('');
		}
	}

	ngOnInit(): void {
		this.subscriptions.push(
			this.valSubject
				.pipe(
					tap((value) => {
						this.selectedOptions = this.options.filter((option) =>
							value.includes(option.id)
						);
						this.setFilteredOptions('');
					})
				)
				.subscribe()
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	add(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();

		// Add our option
		if (value) {
			const foundOption = this.options.find(
				(option) => option.label === event.value
			);
			if (!foundOption) return;
			this.selectedOptions.push(foundOption);
			this.value = this.selectedOptions.map((option) => option.id);
		}

		// Clear the input value
		event.chipInput?.clear();

		this.optionCtrl.setValue('');
	}

	remove(event): void {
		const index = this.selectedOptions.indexOf(event);

		if (index >= 0) {
			this.selectedOptions.splice(index, 1);
			this.value = this.selectedOptions.map((option) => option.id);
		}
	}

	clearSelection() {
		this.selectedOptions = [];
		this.value = [];

		this.optionCtrl.setValue('');
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		const foundOption = this.options.find(
			(option) => option.id === event.option.value
		);
		if (!foundOption) return;

		this.selectedOptions.push(foundOption);
		this.value = this.selectedOptions.map((option) => option.id);
		this.chipsInput.nativeElement.value = '';
		this.optionCtrl.setValue(null);
	}

	private _filter(value: string): SelectorOption[] {
		const filterValue = value.toLowerCase();

		return this.options.filter(
			(option) =>
				option.label.toLowerCase().includes(filterValue) &&
				!this.selectedOptions.find((selected) => selected.id === option.id)
		);
	}
}
